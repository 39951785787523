import { createSlice } from "@reduxjs/toolkit";
import {
  REQUEST_FAILURE,
  REQUEST_PENDING,
  REQUEST_SUCCESS,
  REQUEST_UNDEFINED,
} from "../../constants/statuses";

export const INITIAL_STATE = {
  pool: {},
  project: {},
  projectsCheckState: {},
  projectTitleStatus: {},
  newProject: "",
  getProjectsStatus: REQUEST_UNDEFINED,
  getProjectStatus: REQUEST_UNDEFINED,
  postProjectsStatus: REQUEST_UNDEFINED,
  postProjectsErrorMessage: null,
  postToggleProjectsStatus: REQUEST_UNDEFINED,
  postUpdateProjectsStatus: REQUEST_UNDEFINED,
  addItemToProjectStatus: REQUEST_UNDEFINED,
  postDeleteCompanyStatus: REQUEST_UNDEFINED,
  postEnrichCompanyStatus: REQUEST_UNDEFINED,
  checkProjectTitleStatus: REQUEST_UNDEFINED,
  postCheckProjectsStatus: REQUEST_UNDEFINED,
  deleteProjectStatus: REQUEST_UNDEFINED,
  getProjectCompaniesChartStatus: REQUEST_UNDEFINED,
  getProjectCompaniesTeamSizeChartStatus: REQUEST_UNDEFINED,
  projectCompaniesChart: null,
  projectCompaniesTeamSizeChart: null,
  unspecifiedAll: false,
  filterStats: null,
  lastFilteredField: null,
  totalCount: null,
  currentPage: 1,
  totalPages: 0,
};

export const REDUCER_PREFIX = "projects";

const { reducer, actions } = createSlice({
  name: REDUCER_PREFIX,
  initialState: INITIAL_STATE,
  reducers: {
    getProjectsPending: state => {
      state.getProjectsStatus = REQUEST_PENDING;
    },
    getProjectsSuccess: state => {
      state.newProject = "";
      state.getProjectsStatus = REQUEST_SUCCESS;
    },
    getProjectsFailure: state => {
      state.getProjectsStatus = REQUEST_FAILURE;
    },
    getProjectPending: state => {
      state.getProjectStatus = REQUEST_PENDING;
    },
    getProjectSuccess: state => {
      state.getProjectStatus = REQUEST_SUCCESS;
    },
    getProjectFailure: state => {
      state.getProjectStatus = REQUEST_FAILURE;
    },
    postProjectsPending: state => {
      state.newProject = "";
      state.postProjectsStatus = REQUEST_PENDING;
    },
    postProjectsSuccess: (state, { payload }) => {
      state.newProject = payload.slug;
      state.postProjectsStatus = REQUEST_SUCCESS;
    },
    postProjectsFailure: (state, { payload }) => {
      state.newProject = "";
      state.postProjectsStatus = REQUEST_FAILURE;
      state.postProjectsErrorMessage = payload;
    },
    postProjectsUndefined: state => {
      state.postProjectsStatus = REQUEST_UNDEFINED;
      state.postProjectsErrorMessage = null;
    },
    updateProjectPending: state => {
      state.postUpdateProjectsStatus = REQUEST_PENDING;
    },
    updateProjectSuccess: state => {
      state.postUpdateProjectsStatus = REQUEST_SUCCESS;
    },
    updateProjectFailure: state => {
      state.postUpdateProjectsStatus = REQUEST_FAILURE;
    },
    postToggleProjectsPending: state => {
      state.postToggleProjectsStatus = REQUEST_PENDING;
    },
    postToggleProjectsSuccess: state => {
      state.postToggleProjectsStatus = REQUEST_SUCCESS;
    },
    postToggleProjectsFailure: state => {
      state.postToggleProjectsStatus = REQUEST_FAILURE;
    },
    addItemToProjectPending: state => {
      state.addItemToProjectStatus = REQUEST_PENDING;
    },
    addItemToProjectSuccess: state => {
      state.addItemToProjectStatus = REQUEST_SUCCESS;
    },
    addItemToProjectFailure: state => {
      state.addItemToProjectStatus = REQUEST_FAILURE;
    },
    addItemToProjectUndefined: state => {
      state.addItemToProjectStatus = REQUEST_UNDEFINED;
    },
    deleteCompanyPending: state => {
      state.postDeleteCompanyStatus = REQUEST_PENDING;
    },
    deleteCompanySuccess: state => {
      state.postDeleteCompanyStatus = REQUEST_SUCCESS;
    },
    deleteCompanyUndefined: state => {
      state.postDeleteCompanyStatus = REQUEST_UNDEFINED;
    },
    deleteCompanyFailure: state => {
      state.postDeleteCompanyStatus = REQUEST_FAILURE;
    },
    enrichCompanyPending: state => {
      state.postEnrichCompanyStatus = REQUEST_PENDING;
    },
    enrichCompanySuccess: state => {
      state.postEnrichCompanyStatus = REQUEST_SUCCESS;
    },
    enrichCompanyFailure: state => {
      state.postEnrichCompanyStatus = REQUEST_FAILURE;
    },
    checkProjectTitlePending: state => {
      state.checkProjectTitleStatus = REQUEST_PENDING;
    },
    checkProjectTitleSuccess: state => {
      state.checkProjectTitleStatus = REQUEST_SUCCESS;
    },
    checkProjectTitleFailure: state => {
      state.checkProjectTitleStatus = REQUEST_FAILURE;
    },
    checkProjectsPending: state => {
      state.postCheckProjectsStatus = REQUEST_PENDING;
    },
    checkProjectsSuccess: state => {
      state.postCheckProjectsStatus = REQUEST_SUCCESS;
    },
    checkProjectsFailure: state => {
      state.postCheckProjectsStatus = REQUEST_FAILURE;
    },
    setProjectsCheckState: (state, { payload }) => {
      state.projectsCheckState = payload;
    },
    setProject: (state, { payload }) => {
      const uniqueProject = {
        ...payload,
        items: payload.items ? payload.items : [],
      };
      state.project = uniqueProject;
      state.postDeleteCompanyStatus = REQUEST_UNDEFINED;
      state.postEnrichCompanyStatus = REQUEST_UNDEFINED;
      state.totalCount = payload.items.length;
      state.currentPage = 1;
      state.totalPages = 1;
      state.filterStats = payload.filter_stats;
    },
    setProjects: (state, { payload }) => {
      state.pool =
        payload &&
        payload.sort((a, b) => {
          return b.active - a.active;
        });
      state.postToggleProjectsStatus = REQUEST_UNDEFINED;
      state.postProjectsStatus = REQUEST_UNDEFINED;
      state.postUpdateProjectsStatus = REQUEST_UNDEFINED;
      state.addItemToProjectStatus = REQUEST_UNDEFINED;
    },
    setProjectTitle: (state, { payload }) => {
      state.projectTitleStatus = payload;
    },
    deleteProjectPending: state => {
      state.deleteProjectStatus = REQUEST_PENDING;
    },
    deleteProjectSuccess: state => {
      state.deleteProjectStatus = REQUEST_SUCCESS;
    },
    deleteProjectFailure: state => {
      state.deleteProjectStatus = REQUEST_FAILURE;
    },
    getProjectCompaniesChartPending: state => {
      state.getProjectCompaniesChartStatus = REQUEST_PENDING;
    },
    getProjectCompaniesChartSuccess: state => {
      state.getProjectCompaniesChartStatus = REQUEST_SUCCESS;
    },
    getProjectCompaniesChartFailure: state => {
      state.getProjectCompaniesChartStatus = REQUEST_FAILURE;
    },
    setProjectCompaniesChart: (state, { payload }) => {
      state.projectCompaniesChart = payload;
    },
    getProjectCompaniesTeamSizeChartPending: state => {
      state.getProjectCompaniesTeamSizeChartStatus = REQUEST_PENDING;
    },
    getProjectCompaniesTeamSizeChartSuccess: state => {
      state.getProjectCompaniesTeamSizeChartStatus = REQUEST_SUCCESS;
    },
    getProjectCompaniesTeamSizeChartFailure: state => {
      state.getProjectCompaniesTeamSizeChartStatus = REQUEST_FAILURE;
    },
    setProjectCompaniesTeamSizeChart: (state, { payload }) => {
      state.unspecifiedAll = payload.Unspecified.percentage === 100;
      delete payload.Unspecified;
      state.projectCompaniesTeamSizeChart = payload;
    },
  },
});
export { reducer, actions };
