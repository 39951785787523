import React, { createRef, useEffect, useState } from "react";
import classnames from "classnames";
import { useStyles } from "./style.js";
// Components
import Typography from "../../../../components/Typography/Typography";
import { default as UICard } from "../../../../components/UICard/UICard";
import Button from "../../../../components/Button/Button";
import Tooltip from "@material-ui/core/Tooltip";
import SeeSectorsModal from "../../../../components/SeeSectorsModal/SeeSectorsModal.js";
import { AddToProjectMenu } from "../../../../components/AddToProjectMenu/AddToProjectMenu.js";
// Assets
import NoImagePlaceholder from "../../../../assets/images/no-image-placeholder.png";
import CommentIcon from "../../../../assets/icons/CommentIcon.svg";
import Like from "../../../../assets/icons/Like.svg";
import Liked from "../../../../assets/icons/Liked.svg";
import CheckEnrichedCompany from "../../../../assets/icons/CheckEnrichedCompany.svg";
// Hooks
import { useHistory } from "react-router-dom";
//Listeners
import { useAuthHook } from "../../../../common/useAuthHook";
//Redux
import { useDispatch, useSelector } from "react-redux";
import { voteMlResultRequest } from "../../../../store/results/requests";
import { getUserSelector } from "../../../../store/auth/selectors";
import { Shared } from "../../../../utils/shared";

//hooks
import { useAppEventTracking } from "../../../../common/useAppEventTracking";

const Card = ({
  isTechnology,
  result,
  className,
  t,
  openPage,
  searchResultsSid,
  isAuthenticated,
  isProjectCompany,
  projectSlug,
  cardIndex,
  source,
  searchTypeLook,
  isIndustry,
  isIndustryProfile,
  isSuggestedCompany = false,
  isLikedCompany,
  searchType,
  parentId,
  view,
}) => {
  const {
    name,
    deck,
    sectors,
    description,
    founded_date,
    hq_country,
    investment_stage,
    number_of_employees,
    total_funding_amount_dollars,
    slug,
    ready_for_release,
    id,
    user_vote,
    degree_of_fit,
    voters,
    comments_cnt,
  } = result;
  const { user } = useAuthHook();
  const { intercomTrackEvent, mixpanelTrackEvent } = useAppEventTracking();
  const cardRef = createRef(null);
  const sectorsTagsWrapperRef = createRef(null);
  const classes = useStyles({ isSuggestedCompany });
  const [imageSrc, setImageSrc] = useState(NoImagePlaceholder);
  const [imageIsValid, setImageIsValid] = useState(false);
  const [shouldShowMatchScore, setShouldShowMatchScore] = useState(false);
  //votes
  const [userLike, setUserLike] = useState(false);
  const [userDislike, setUserDislike] = useState(false);
  const [votersUp, setVotersUp] = useState({ cnt: 0, users: [] });
  const [votersDown, setVotersDown] = useState({ cnt: 0, users: [] });
  const [unspecifiedFunding, setUnspecifiedFunding] = useState(false);
  const [newTabUrl, setNewTabUrl] = useState();
  const history = useHistory();

  useEffect(() => {
    setShouldShowMatchScore(
      source !== "project" && !isIndustryProfile && !isSuggestedCompany,
    );
  }, [source, isIndustryProfile, isSuggestedCompany]);

  useEffect(() => {
    voters && voters.up && setVotersUp(voters.up);
    voters && voters.down && setVotersDown(voters.down);
  }, [voters]);

  useEffect(() => {
    if (ready_for_release && deck && deck.icon) {
      setImageSrc(deck.icon);
      setImageIsValid(true);
    } else {
      setImageSrc(NoImagePlaceholder);
      setImageIsValid(false);
    }
  }, [ready_for_release, deck]);

  const onImgError = () => {
    setImageSrc(NoImagePlaceholder);
    setImageIsValid(false);
  };
  const dispatch = useDispatch();

  const getUser = useSelector(getUserSelector);
  const { formatNumber } = Shared({ getUser });

  const openCompany = e => {
    const queryParams = new URLSearchParams(window.location.search);
    const pageParam = queryParams.get("page");
    sessionStorage.setItem(
      `${source}-${
        isTechnology || isIndustry
          ? projectSlug
          : isLikedCompany
          ? ""
          : searchResultsSid
      }ScrollPosition`,
      window.scrollY,
    );
    sessionStorage.setItem(
      `${source}-${
        isTechnology || isIndustry
          ? projectSlug
          : isLikedCompany
          ? ""
          : searchResultsSid
      }-FilterPage`,
      pageParam,
    );
    if (isTechnology) {
      localStorage.setItem("project_slug", projectSlug);
      mixpanelTrackEvent("Company profile under technology opened", {
        "Company name": name,
      });
    } else if (isIndustry) {
      mixpanelTrackEvent("Company profiles viewed under industry", {
        "Company name": name,
      });
      localStorage.setItem(
        "industryCompanyFromRoute",
        history.location.pathname,
      );
      localStorage.setItem("project_slug", projectSlug);
    } else if (isSuggestedCompany) {
      mixpanelTrackEvent("Suggested company profile viewed", {
        "Company name": name,
      });
      if (source !== "dashboard") {
        localStorage.setItem("historySimilar", "similarCompany");
        localStorage.setItem("visitedCid", parentId);
        sessionStorage.setItem("history", "company");
        localStorage.setItem("project_slug", projectSlug);
      } else {
        sessionStorage.setItem("history", "dashboard");
      }
    } else {
      if (searchType === 1) {
        mixpanelTrackEvent(
          "Company profiles viewed in search for companies using a specific technology",
          {
            "Company name": name,
          },
        );
      } else if (searchType === 2) {
        mixpanelTrackEvent(
          "Company profiles viewed in search for look-alikes",
          {
            "Company name": name,
          },
        );
      }
      localStorage.setItem("project_slug", projectSlug);
      source === "project" && localStorage.setItem("is_from_project", true);
      sessionStorage.setItem(
        "history",
        isProjectCompany
          ? "project"
          : isSuggestedCompany
          ? "company"
          : isLikedCompany
          ? "likedCompanies"
          : "results",
      );
    }
    mixpanelTrackEvent("Company profiles viewed in total", {
      "Company name": name,
    });
    intercomTrackEvent("Company profiles viewed", {
      "Company name": name,
      "Company slug": slug,
    });
    let url = `/companies/${slug}`;

    if (result && result.id) {
      if (isTechnology || isIndustry) {
        url = `${url}?cid=${result.id}`;
      } else {
        isAuthenticated ? (url = `${url}?cid=${result.id}`) : (url = ``);
      }
    }
    if (view && isAuthenticated) {
      url = `${url}&view=true`;
    }
    if (!e.metaKey) {
      if (source === "dashboard") {
        sessionStorage.setItem("history", "dashboard");
      }

      !e.ctrlKey && openPage(url);
    } else {
      if (source === "dashboard") {
        sessionStorage.setItem("history", "dashboard");
      }

      !e.ctrlKey && openPage(url, true);
    }
    if (searchTypeLook) {
      sessionStorage.setItem("history", "companies-look-alike");
    }
  };

  useEffect(() => {
    let url = `/companies/${slug}`;

    if (result && result.id) {
      if (isTechnology || isIndustry) {
        url = `${url}?cid=${result.id}`;
      } else {
        isAuthenticated ? (url = `${url}?cid=${result.id}`) : (url = ``);
      }
    }
    if (view && isAuthenticated) {
      url = `${url}&view=true`;
    }

    setNewTabUrl(url);
  }, []);

  //Votes
  const voteMatch = (vote, e) => {
    e.preventDefault();
    e.stopPropagation();
    if (isAuthenticated) {
      if (vote === 1) {
        if (userLike) {
          let vUp = votersUp;
          let users = vUp.users ? vUp.users.filter(f => f !== user.name) : [];
          vUp = { cnt: vUp.cnt - 1, users: users };
          setVotersUp(vUp);
        } else if (userDislike) {
          let vDown = votersDown;
          let users = vDown.users
            ? vDown.users.filter(f => f !== user.name)
            : [];
          vDown = { cnt: vDown.cnt - 1, users: users };
          setVotersDown(vDown);
        }
        if (!userLike) {
          let vUp = votersUp;
          let users = [...vUp.users, user.name];
          vUp = { cnt: vUp.cnt + 1, users: users };
          setVotersUp(vUp);
        }
        setUserLike(!userLike);
        setUserDislike(false);
      } else {
        if (userDislike) {
          let vDown = votersDown;
          let users = vDown.users
            ? vDown.users.filter(f => f !== user.name)
            : [];
          vDown = { cnt: vDown.cnt - 1, users: users };
          setVotersDown(vDown);
        } else if (userLike) {
          let vUp = votersUp;
          let users = vUp.users ? vUp.users.filter(f => f !== user.name) : [];
          vUp = { cnt: vUp.cnt - 1, users: users };
          setVotersUp(vUp);
        }
        if (!userDislike) {
          let vDown = votersDown;
          let users = [...vDown.users, user.name];
          vDown = { cnt: vDown.cnt + 1, users: users };
          setVotersDown(vDown);
        }
        setUserLike(false);
        setUserDislike(!userDislike);
      }
      let data = new FormData();
      data.append("ml_result_id", result.id);
      data.append("vote", vote);
      isTechnology && data.append("page", "technology");
      isIndustry && data.append("page", "industry");
      dispatch(voteMlResultRequest({ data }));
    }
  };
  const openCompanyComments = e => {
    e.preventDefault();
    e.stopPropagation();
    localStorage.setItem("companyInfo", JSON.stringify(result));

    if (isTechnology) {
      localStorage.setItem("project_slug", projectSlug);
      sessionStorage.setItem("history", "technologies");
      openPage(`/companies/${slug}?cid=${result.id}&openTab=comments`);
    } else if (isIndustry) {
      localStorage.setItem("project_slug", projectSlug);
      sessionStorage.setItem("history", "industryProfile");
      openPage(`/companies/${slug}?cid=${result.id}&openTab=comments`);
    } else if (searchTypeLook) {
      sessionStorage.setItem("history", "companies-look-alike");
      openPage(`/companies/${slug}?cid=${result.id}&openTab=comments`);
      // openPage(`/companies/${slug}/?sid=${searchResultsSid ? searchResultsSid : result.search_id}&openTab=comments`);
    } else {
      localStorage.setItem("project_slug", projectSlug);
      sessionStorage.setItem(
        "history",
        isProjectCompany ? "project" : "results",
      );
      openPage(`/companies/${slug}?cid=${result.id}&openTab=comments`);
    }
  };
  useEffect(() => {
    if (user_vote === 1) {
      setUserLike(true);
      setUserDislike(false);
    } else if (user_vote === 0) {
      setUserLike(false);
      setUserDislike(true);
    }
  }, [result]);

  //Set when to show unspecified value for funding amount
  useEffect(() => {
    if (
      (ready_for_release && !deck) ||
      (!ready_for_release && !total_funding_amount_dollars)
    ) {
      setUnspecifiedFunding(true);
    }
  }, [total_funding_amount_dollars, deck, ready_for_release]);

  const [scoreDegree, setScoreDegree] = useState();

  useEffect(() => {
    if (degree_of_fit * 100 < 40) {
      setScoreDegree("weak");
    } else if (degree_of_fit * 100 >= 40 && degree_of_fit * 100 < 60) {
      setScoreDegree("good");
    } else {
      setScoreDegree("strong");
    }
  }, [degree_of_fit]);

  return (
    <>
      <a
        className={classnames([
          classes.root,
          {
            [classes.totalMatch]:
              (degree_of_fit * 100).toFixed() === "100" &&
              !isLikedCompany &&
              !isProjectCompany &&
              !isIndustry &&
              !isTechnology,
          },
          className,
        ])}
        onClick={e => !e.ctrlKey && !e.metaKey && e.preventDefault()}
        href={newTabUrl}>
        <UICard
          data-intercom-target={`Company results ${cardIndex + 1}`}
          testId={`${id}-company-card`}
          id={`results-card-${cardIndex}`}
          ref={cardRef}
          onClick={e => openCompany(e)}
          isSuggestedCompany={isSuggestedCompany}>
          <div className={classes.headerWrapper}>
            <div className={classes.header}>
              <div
                className={classnames([
                  classes.headerLeft,
                  {
                    [classes.headerLeftProject]: source === "project",
                  },
                ])}>
                <Tooltip
                  disableHoverListener={imageIsValid}
                  title={
                    <Typography component="div" color="white" variant="body2">
                      {t("results.logoDescription")}
                    </Typography>
                  }
                  arrow>
                  <img
                    width="48"
                    height="48"
                    src={imageSrc}
                    alt={name}
                    className={classes.avatar}
                    onError={onImgError}
                  />
                </Tooltip>
                <div className={classes.titleWrap}>
                  <div>
                    <Tooltip
                      title={
                        <Typography
                          color="white"
                          variant="body2"
                          className={classes.mono}>
                          {name}
                        </Typography>
                      }
                      arrow>
                      <Typography
                        component="div"
                        variant="subtitle1"
                        className={classnames([
                          classes.name,
                          {
                            [classes.projectSourceName]:
                              source === "project" || isIndustryProfile,
                          },
                        ])}>
                        {name}
                      </Typography>
                    </Tooltip>
                    <div
                      className={classnames([
                        classes.sectorTagsWrapper,
                        "non-desktop-flex",
                        {
                          [classes.displayNone]: isSuggestedCompany,
                        },
                      ])}
                      ref={sectorsTagsWrapperRef}>
                      <SeeSectorsModal
                        sectors={sectors}
                        isIndustryProfile={isIndustryProfile}
                      />
                    </div>
                  </div>
                  {!!ready_for_release && (
                    <div className={classes.enrichedCompanyHeader}>
                      <img
                        src={CheckEnrichedCompany}
                        alt={t("results.enrichedProfile")}
                        className={classes.checkEnrichedCompanyImgHeader}
                      />
                      <Typography
                        variant="bodyButton"
                        component="div"
                        className={classnames([
                          classes.infoTitle,
                          classes.enrichedCompanyInfoTitle,
                        ])}>
                        {t("results.enrichedProfile")}
                      </Typography>
                    </div>
                  )}
                </div>
              </div>
              {shouldShowMatchScore && (
                <div className={classes.matchScoreBlock}>
                  <Typography
                    variant="bodyXs"
                    color="textLighter"
                    component="div">
                    {t("results.matchingScore")}
                  </Typography>
                  <Typography
                    variant="bodyXl"
                    className={classnames([
                      classes.percentageMatch,
                      classes[`${scoreDegree}Text`],
                    ])}
                    color={
                      (degree_of_fit * 100).toFixed() === "100"
                        ? "primaryDark"
                        : "black"
                    }>
                    {t("results.match", {
                      number: (degree_of_fit * 100).toFixed(),
                    })}
                    <div className={classes.scoreBar}>
                      <div
                        className={classnames([
                          classes.scoreBarInside,
                          classes[`${scoreDegree}Score`],
                        ])}
                        style={{
                          width: `${degree_of_fit * 100}%`,
                        }}></div>
                    </div>
                  </Typography>
                </div>
              )}
            </div>
            {!!ready_for_release &&
              deck &&
              deck.sdgs &&
              deck.sdgs.length > 0 && (
                <div className={classnames([classes.info, classes.sdgs])}>
                  <div>
                    {deck.sdgs.map((sdg, i) => (
                      <Tooltip
                        key={`sdg-${i}`}
                        arrow
                        classes={{
                          arrow: classes.tooltipArrow,
                          tooltip: classes.tooltipContent,
                        }}
                        placement="top"
                        title={
                          <Typography
                            variant="bodySmall"
                            color="white"
                            component="div"
                            className={classes.sdgTooltip}>
                            <div className={classes.sdgTooltipTitle}>
                              {t("companyProfile.thisCompanyWorksTowards")}
                            </div>
                            {t("companyProfile.sustainableDevelopmentGoals")}{" "}
                            {sdg.number}: {sdg.name}
                          </Typography>
                        }>
                        <img
                          className={classes.sdgTag}
                          src={require(
                            `../../../../assets/icons/sdgs/${sdg.icon_name}.png`,
                          )}
                          alt={sdg.name}></img>
                      </Tooltip>
                    ))}
                  </div>
                </div>
              )}
          </div>
          <div
            className={classnames([
              !!ready_for_release && deck && deck.sdgs && deck.sdgs.length > 0
                ? classes.mt0
                : classes.mt24,
              classes.infoItems,
            ])}>
            <div className={classes.descriptionWrapper}>
              <Typography
                variant="body"
                color="textSecondary"
                className={classes.description}
                dangerouslySetInnerHTML={{
                  __html:
                    deck && deck.description ? deck.description : description,
                }}></Typography>
              <div className={classes.bgGradient}></div>
            </div>
            <div
              className={classnames([
                classes.info,
                "non-desktop",
                { [classes.displayNone]: isSuggestedCompany },
              ])}>
              <Typography
                variant="bodyButton"
                component="div"
                className={classes.infoTitle}>
                {t("results.inceptionYear")}
              </Typography>
              <Typography variant="bodySmall" color="textLighter">
                {deck && deck.founded_date ? deck.founded_date : founded_date}
              </Typography>
            </div>
            <div className={classes.info}>
              <Typography
                variant="bodyButton"
                component="div"
                className={classes.infoTitle}>
                {t("results.country")}
              </Typography>
              <Typography
                variant="bodySmall"
                color="textLighter"
                className={classes.infoItemMobile}>
                {deck && deck.hq_country ? deck.hq_country : hq_country}
              </Typography>
            </div>
            <div
              className={classnames([
                classes.info,
                "non-desktop",
                { [classes.displayNone]: isSuggestedCompany },
              ])}>
              <Typography
                variant="bodyButton"
                component="div"
                className={classes.infoTitle}>
                {t("results.currentStage")}
              </Typography>
              <Typography variant="bodySmall" color="textLighter">
                {deck && deck.investment_stage
                  ? deck.investment_stage
                  : investment_stage}
              </Typography>
            </div>
            <div className={classnames([classes.info, classes.infoFunding])}>
              <Typography
                variant="bodyButton"
                component="div"
                className={classes.infoTitle}>
                {t("results.fundings")}
              </Typography>
              {unspecifiedFunding ? (
                <>
                  <Typography
                    variant="bodySmall"
                    color="textLighter"
                    className={classes.infoItemMobile}>
                    {t("results.unspecified")}
                  </Typography>
                </>
              ) : (
                <>
                  <Typography
                    variant="bodySmall"
                    color="textLighter"
                    className={classnames([
                      classes.match,
                      classes.infoItemMobile,
                    ])}>
                    {deck && deck.funding
                      ? isNaN(deck.funding)
                        ? deck.funding
                        : formatNumber(deck.funding)
                      : total_funding_amount_dollars
                      ? parseInt(total_funding_amount_dollars)
                          .toFixed()
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " USD"
                      : t("results.unspecified")}
                  </Typography>
                </>
              )}
            </div>
            <div
              className={classnames([
                classes.info,
                "non-desktop",
                { [classes.displayNone]: isSuggestedCompany },
              ])}>
              <Typography
                variant="bodyButton"
                component="div"
                className={classes.infoTitle}>
                {t("results.team")}
              </Typography>
              <Typography
                variant="bodySmall"
                color="textLighter"
                component="div"
                className={classes.numberOfEmployees}>
                {deck && deck.number_of_employees
                  ? deck.number_of_employees
                  : number_of_employees}
              </Typography>
            </div>
            {!!ready_for_release && (
              <div
                className={classnames([
                  classes.info,
                  classes.enrichedCompanyInfo,
                ])}>
                <div className={classes.enrichedCompany}>
                  <img
                    src={CheckEnrichedCompany}
                    alt={t("results.enrichedProfile")}
                    className={classes.checkEnrichedCompanyImg}
                  />
                  <Typography
                    variant="bodyButton"
                    component="div"
                    className={classnames([
                      classes.infoTitle,
                      classes.enrichedCompanyInfoTitle,
                    ])}>
                    {t("results.enrichedProfile")}
                  </Typography>
                </div>
              </div>
            )}
          </div>
          {!!isAuthenticated && (
            <div
              className={classnames([
                classes.actionItems,
                "non-desktop-flex",
                { [classes.displayNone]: isSuggestedCompany },
              ])}>
              <Button
                variant="quaternary"
                iconMargin
                className={classnames([
                  classes.rssButton,
                  user &&
                    user.subscription &&
                    user.subscription.name === "free" &&
                    classes.rssButtonFreeUser,
                  "non-desktop",
                  {
                    [classes.displayNone]: isSuggestedCompany,
                  },
                ])}>
                {t("results.companyDetails")}
              </Button>
              <AddToProjectMenu
                buttonType="checkbox"
                company={result}
                isProject={isProjectCompany}
                projectSlug={projectSlug}
                type="company"
              />
            </div>
          )}
          {!isAuthenticated && isTechnology && (
            <div className={classnames([classes.actionItems, "non-laptop"])}>
              <Button
                variant="tertiary"
                iconMargin
                className={classnames(
                  classes.rssButton,
                  user &&
                    user.subscription &&
                    user.subscription.name === "free" &&
                    classes.rssButtonFreeUser,
                )}>
                {t("results.companyDetails")}
              </Button>
            </div>
          )}
          {isAuthenticated && (
            <div
              className={classes.commentActionItems}
              data-intercom-target="Feedback">
              <div className={classes.commentActionItem}>
                <Typography variant="body2" color="textSecondary">
                  {t("results.isThisRecommendationUseful")}
                </Typography>
                <div className={classes.likeDislikeIcons}>
                  <img
                    src={userLike ? Liked : Like}
                    alt={t("results.like")}
                    className={classes.likeIcon}
                    onClick={e => {
                      voteMatch(1, e);
                    }}
                  />

                  <Tooltip
                    disableHoverListener={
                      votersUp.users && votersUp.users.length === 0
                    }
                    title={
                      votersUp.users &&
                      votersUp.users.map(user => (
                        <Typography
                          color="white"
                          variant="body2"
                          component="div">
                          {user}
                        </Typography>
                      ))
                    }
                    arrow>
                    <span
                      className={classnames([
                        classes.votersCount,
                        {
                          [classes.userVoted]: userLike,
                        },
                      ])}>
                      {votersUp && votersUp.cnt}
                    </span>
                  </Tooltip>
                  <img
                    src={userDislike ? Liked : Like}
                    alt={t("results.dislike")}
                    className={classnames([classes.dislikeImg])}
                    onClick={e => {
                      voteMatch(0, e);
                    }}
                  />
                  <Tooltip
                    disableHoverListener={
                      votersDown.users && votersDown.users.length === 0
                    }
                    title={
                      votersDown.users &&
                      votersDown.users.map(user => (
                        <Typography
                          color="white"
                          variant="body2"
                          component="div">
                          {user}
                        </Typography>
                      ))
                    }
                    arrow>
                    <span
                      className={classnames([
                        classes.votersCount,
                        {
                          [classes.userVoted]: userDislike,
                        },
                      ])}>
                      {votersDown && votersDown.cnt}
                    </span>
                  </Tooltip>
                </div>
              </div>
              <Tooltip
                title={
                  <Typography color="white" variant="body2">
                    {t("results.leaveComment")}
                  </Typography>
                }
                arrow>
                <div className={classes.commentCountWrap}>
                  <img
                    src={CommentIcon}
                    alt={t("results.companyComments")}
                    className={classes.communicationIcon}
                    onClick={e => {
                      openCompanyComments(e);
                    }}
                  />
                  <span className={classes.commentsCount}>{comments_cnt}</span>
                </div>
              </Tooltip>
            </div>
          )}
        </UICard>
      </a>
    </>
  );
};

export default Card;
