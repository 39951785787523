import { call, put } from "redux-saga/effects";
import { actions } from "../slice";
import { actions as resultsActions } from "../../results/slice";
import {
  fetchSuggestedCompanies,
  getComments,
  getCompanyTags,
  getDeckClientPreview,
  getSimilarCompaniesCall,
  postAddComment,
  postAddCompanyTag,
  postDeleteComment,
  postDeleteCompanyTag,
  postSelectCompanyTag,
  postSharedLink,
  postUpdateComment,
  postVoteComment,
} from "./helpers";

function* fetchComments(cid) {
  try {
    yield put(actions.getCommentsPending());
    yield put(resultsActions.clearResults());
    yield put(actions.clearComments());
    const response = yield call(getComments, cid);
    yield put(actions.getCommentsSuccess());
    yield put(actions.setComments(response.data));
  } catch (err) {
    yield put(actions.getCommentsFailure());
  }
}

function* addComment(data) {
  try {
    yield put(actions.addCommentPending());
    yield call(postAddComment, data);
    yield put(actions.addCommentSuccess());
  } catch (err) {
    yield put(actions.addCommentFailure());
  }
}

function* updateComment(data) {
  try {
    yield put(actions.updateCommentPending());
    yield call(postUpdateComment, data);
    yield put(actions.updateCommentSuccess());
  } catch (err) {
    yield put(actions.updateCommentFailure());
  }
}

function* deleteComment(data) {
  try {
    yield put(actions.deleteCommentPending());
    yield call(postDeleteComment, data);
    yield put(actions.deleteCommentSuccess());
  } catch (err) {
    yield put(actions.deleteCommentFailure());
  }
}

function* getSharedModel(token) {
  try {
    yield put(actions.getSharedModelPending());
    const response = yield call(postSharedLink, token);
    yield put(actions.setSharedModel(response.data));
    yield put(actions.getSharedModelSuccess());
  } catch (err) {
    yield put(actions.getSharedModelFailure());
  }
}

function* voteComment(data) {
  try {
    yield put(actions.voteCommentPending());
    yield call(postVoteComment, data);
    yield put(actions.voteCommentSuccess());
  } catch (err) {
    yield put(actions.voteCommentFailure());
  }
}

function* fetchCompanyTags(slug) {
  try {
    yield put(actions.getTagsPending());
    const response = yield call(getCompanyTags, slug);
    yield put(actions.setTags(response.data));
    yield put(actions.getTagsSuccess());
  } catch (err) {
    yield put(actions.getTagsFailure());
  }
}

function* addCompanyTag(data) {
  try {
    yield put(actions.addTagPending());
    yield call(postAddCompanyTag, data);
    yield put(actions.addTagSuccess());
  } catch (err) {
    yield put(actions.addTagFailure());
  }
}

function* deleteCompanyTag(data) {
  try {
    yield put(actions.deleteTagPending());
    yield call(postDeleteCompanyTag, data);
    yield put(actions.deleteTagSuccess());
  } catch (err) {
    yield put(actions.deleteTagFailure());
  }
}

function* selectCompanyTag(data) {
  try {
    yield put(actions.selectTagPending());
    yield call(postSelectCompanyTag, data);
    yield put(actions.selectTagSuccess());
  } catch (err) {
    yield put(actions.selectTagFailure());
  }
}

function* getSimilarCompanies(data) {
  try {
    yield put(actions.getSimilarCompaniesPending());
    const response = yield call(getSimilarCompaniesCall, data);
    yield put(actions.setSimilarCompanies(response.data));
    yield put(actions.getSimilarCompaniesSuccess());
  } catch (err) {
    yield put(actions.getSimilarCompaniesFailure());
  }
}

function* getSuggestedCompanies(data) {
  try {
    yield put(actions.getSuggestedCompaniesPending());
    const response = yield call(fetchSuggestedCompanies, data);
    yield put(actions.setSuggestedCompanies(response.data));
    yield put(actions.getSuggestedCompaniesSuccess());
  } catch (err) {
    yield put(actions.getSuggestedCompaniesFailure());
  }
}

function* fetchDeckClientPreview(slug) {
  try {
    yield put(actions.fetchDeckClientPreviewPending());
    const response = yield call(getDeckClientPreview, slug);
    yield put(actions.setDeckClientPreview(response.data));
    yield put(actions.fetchDeckClientPreviewSuccess());
  } catch (err) {
    yield put(actions.fetchDeckClientPreviewFailure());
  }
}

export {
  fetchComments,
  addComment,
  updateComment,
  deleteComment,
  getSharedModel,
  voteComment,
  fetchCompanyTags,
  addCompanyTag,
  deleteCompanyTag,
  selectCompanyTag,
  getSimilarCompanies,
  getSuggestedCompanies,
  fetchDeckClientPreview,
};
