import { createSelector } from "reselect";

const getProductSelector = state => state.search.product;
const getIndustryDescSelector = state => state.search.industryDesc;
const getEditSearchDirtySelector = state => state.search.editSearchDirty;
const getSearchSectorsSelector = state => state.search.sectors;
const getSearchLocationSelector = state => state.search.location;
const getSearchSubsectorsSelector = state => state.search.subsectors;
const getPostSearchStatusSelector = state => state.search.postSearchStatus;
const getSearchQuery = state => state.search.searchQuery;
const getCompanyName = state => state.search.companyName;
const getCompanyWebsite = state => state.search.companyWebsite;
const getCompanyDescription = state => state.search.companyDescription;
const getPostSearchResponseSelector = state => state.search.postSearchResponse;
const getRemoveSavedSearchesStatusSelector = state =>
  state.search.removeSavedSearchesStatus;
const getSearchQuerySectorsSelector = () =>
  createSelector(getSearchQuery, searchQuery => searchQuery.sectors);
const getSearchQuerySubsectorsSelector = () =>
  createSelector(
    state => state.search.searchQuery,
    searchQuery => searchQuery.subsectors,
  );

const getSearchSectorSelector = sectorSlug =>
  createSelector(
    state => state.search.sectors,
    sectors => sectors && sectors.find(({ slug }) => slug === sectorSlug),
  );
const getSearchCountStateSelector = state => state.search.checkSearchCountState;
const getSearchCountStatusSelector = state =>
  state.search.checkSearchCountStatus;
const getSearchCountResetStatusSelector = state =>
  state.search.resetSearchCountStatus;
const getSetSearchCountStatusSelector = state =>
  state.search.setSearchCountStatus;
const getMlSummaryStatusSelector = state => state.search.getMlSummaryStatus;
const getSummaryShortSelector = state => state.search.summaryShort;
const getSummaryLongSelector = state => state.search.summaryLong;

export {
  getProductSelector,
  getIndustryDescSelector,
  getSearchSectorsSelector,
  getSearchSubsectorsSelector,
  getSearchSectorSelector,
  getSearchLocationSelector,
  getSearchQuerySectorsSelector,
  getSearchQuerySubsectorsSelector,
  getPostSearchStatusSelector,
  getPostSearchResponseSelector,
  getSearchCountStateSelector,
  getSearchCountStatusSelector,
  getSearchCountResetStatusSelector,
  getSetSearchCountStatusSelector,
  getCompanyName,
  getCompanyWebsite,
  getCompanyDescription,
  getRemoveSavedSearchesStatusSelector,
  getEditSearchDirtySelector,
  getMlSummaryStatusSelector,
  getSummaryShortSelector,
  getSummaryLongSelector,
};
