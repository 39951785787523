import { createSelector } from "reselect";

const getSectorsSelector = state => state.sectors.pool;
const getSubsectorsSelector = state => state.sectors.subsectors;
const getSubsectorsStatusSelector = state =>
  state.sectors.fetchSubsectorsStatus;

const sectorSelector = sectorSlug =>
  createSelector(
    state => state.sectors.pool,
    pool => pool.find(({ slug }) => slug === sectorSlug),
  );

export {
  getSectorsSelector,
  getSubsectorsSelector,
  sectorSelector,
  getSubsectorsStatusSelector,
};
