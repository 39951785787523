import useStyles from "./style.js";
import classnames from "classnames";

const Loader = ({
  loaderWrapperClassName,
  loaderClassName,
  size,
  large,
  strokeWidth,
  color,
}) => {
  const classes = useStyles({ size });
  return (
    <div
      className={classnames([classes.loaderWrapper, loaderWrapperClassName])}
      style={{ width: size, height: size }}>
      <svg
        className={classnames([classes.loader, loaderClassName])}
        viewBox={`${size / 2} ${size / 2} ${size} ${size}`}>
        <circle
          cx={size}
          cy={size}
          r={(size - strokeWidth) / 2}
          fill="none"
          strokeWidth={strokeWidth}
          stroke={color}
        />
      </svg>
    </div>
  );
};

Loader.defaultProps = {
  size: 18,
  strokeWidth: 2,
  large: false,
};

export default Loader;
