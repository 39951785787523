import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { useLocation } from "react-router-dom";
// UI components
// Hooks
import { useTranslation } from "react-i18next";
import { useStyles } from "./style.js";
import { useRouterHook } from "../../common/useRouterHook";
import { useDispatch } from "react-redux";
// Redux
import { mixpanelTrackRequest } from "../../store/mixpanel/requests";
import NewSearch from "./NewSearch/NewSearch";

const SearchType = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { openPage } = useRouterHook();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    dispatch(mixpanelTrackRequest({ eventName: "Search type" }));
  }, [dispatch]);

  const [activeTab, setActiveTab] = useState(
    pathname === "/search-type/lookalike" ? 2 : 1,
  );

  const testConn = payload => {
    console.log(payload);
  };

  useEffect(() => {
    activeTab === 2
      ? openPage("/search-type/lookalike")
      : openPage("/search-type");
  }, [activeTab]);

  const changeTab = tabNumber => {
    setActiveTab(tabNumber);
  };

  return (
    <div className={classnames([classes.root, classes.searchTypeRoot])}>
      <div className={classes.mainSearchBoxWrapper}>
        <NewSearch />
        {/* <div className={classes.mainSearchBoxTabs}>
                    {activeTab === 1 && (
                        <div className={classes.tab}>
                            <div
                                className={classnames([
                                    classes.mainSearchBoxTabOne,
                                    classes.searchTypeTab,
                                    activeTab === 1
                                        ? classes.mainSearchBoxTabActive
                                        : "",
                                ])}>
                                {t(`dashboard.aiSearch`)}
                            </div>
                            <Button
                                onClick={() => changeTab(2)}
                                className={classes.tabBtn}>
                                Go to Look-alike search
                            </Button>
                        </div>
                    )}
                    {activeTab === 2 && (
                        <div className={classes.tab}>
                            <Button
                                onClick={() => changeTab(1)}
                                className={classes.tabBtn}>
                                Go to AI search
                            </Button>
                            <div
                                className={classnames([
                                    classes.mainSearchBoxTabTwo,
                                    classes.searchTypeTab,
                                    activeTab === 1
                                        ? classes.mainSearchBoxTabActive
                                        : "",
                                ])}>
                                Look-alike search
                            </div>
                        </div>
                    )}
                </div> */}
        {/* <div
                    className={classnames([
                        classes.mainSearchBox,
                        activeTab === 2
                            ? classes.mainSearchBoxTabTwoVariant
                            : "",
                    ])}>
                    {activeTab === 1 ? <Description /> : <SearchLookAlike />}
                </div> */}
      </div>
      {/* <div>
                <div>
                    <Box
                        className={classes.industriesAndTechnologiesWrapper}
                        display="flex"
                        flexDirection="column"
                        alignItems="center">
                        <div>
                            <Typography
                                component="div"
                                variant="h3"
                                color="black"
                                className={classes.outcomeTitle}>
                                {t("outcome.title")}
                            </Typography>
                        </div>
                        <SelectProducts />
                    </Box>
                </div>
            </div> */}
    </div>
  );
};

export default SearchType;
