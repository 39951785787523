const getAddCommentStatusSelector = state =>
  state.companyProfile.addCommentStatus;
const getProfileCommentsSelector = state => state.companyProfile.comments;
const getUpdateCommentStatusSelector = state =>
  state.companyProfile.updateCommentStatus;
const getDeleteCommentStatusSelector = state =>
  state.companyProfile.deleteCommentStatus;
const getGetCommentsStatusSelector = state =>
  state.companyProfile.getCommentsStatus;
const getSharedModelSelector = state => state.companyProfile.sharedModel;
const getPostSharedLinkStatusSelector = state =>
  state.companyProfile.postSharedLinkStatus;
const getVoteCommentSelector = state => state.companyProfile.voteCommentStatus;
const getTagSelector = state => state.companyProfile.getTagsStatus;
const getAddTagSelector = state => state.companyProfile.addTagStatus;
const getDeleteTagSelector = state => state.companyProfile.deleteTagStatus;
const getSelectTagSelector = state => state.companyProfile.selectTagStatus;
const getSimilarCompaniesStateSelector = state =>
  state.companyProfile.similarCompanies;
const getSimilarCompaniesStatusSelector = state =>
  state.companyProfile.similarCompaniesStatus;
const getSuggestedCompaniesStatusSelector = state =>
  state.companyProfile.suggestedCompaniesStatus;
const getSuggestedCompaniesStateSelector = state =>
  state.companyProfile.suggestedCompanies;
const getDeckClientPreviewStatusSelector = state =>
  state.companyProfile.fetchDeckClientPreviewStatus;
const getDeckClientPreviewSelector = state =>
  state.companyProfile.deckClientPreview;

export {
  getAddCommentStatusSelector,
  getProfileCommentsSelector,
  getUpdateCommentStatusSelector,
  getGetCommentsStatusSelector,
  getDeleteCommentStatusSelector,
  getPostSharedLinkStatusSelector,
  getSharedModelSelector,
  getVoteCommentSelector,
  getTagSelector,
  getAddTagSelector,
  getDeleteTagSelector,
  getSelectTagSelector,
  getSimilarCompaniesStateSelector,
  getSimilarCompaniesStatusSelector,
  getSuggestedCompaniesStatusSelector,
  getSuggestedCompaniesStateSelector,
  getDeckClientPreviewStatusSelector,
  getDeckClientPreviewSelector,
};
