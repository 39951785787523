import { createSlice } from "@reduxjs/toolkit";
import findIndex from "lodash/findIndex";
import {
  REQUEST_FAILURE,
  REQUEST_PENDING,
  REQUEST_SUCCESS,
  REQUEST_UNDEFINED,
} from "../../constants/statuses";

export const INITIAL_STATE = {
  pool: [],
  subsectors: [],
  fetchSectorsStatus: REQUEST_UNDEFINED,
  fetchSubsectorsStatus: REQUEST_UNDEFINED,
};

export const REDUCER_PREFIX = "sectors";

const { reducer, actions } = createSlice({
  name: REDUCER_PREFIX,
  initialState: INITIAL_STATE,
  reducers: {
    setSectors: (state, { payload }) => {
      state.pool = payload.data;
    },
    fetchSectorsPending: state => {
      state.fetchSectorsStatus = REQUEST_PENDING;
    },
    fetchSectorsSuccess: state => {
      state.fetchSectorsStatus = REQUEST_SUCCESS;
    },
    fetchSectorsFailure: state => {
      state.fetchSectorsStatus = REQUEST_FAILURE;
    },
    getAllSubsectors: (state, { payload }) => {
      state.subsectors = payload.data[0] && payload.data[0].subsectors;
    },
    setSubsectors: (state, { payload }) => {
      const { slug, subsectors } = payload;
      const sectorsState = state.pool;
      const sectorIndex = findIndex(
        sectorsState,
        sector => sector.slug === slug,
      );
      if (sectorIndex !== -1) {
        const selectedSector = sectorsState[sectorIndex];
        const updatedSector = {
          ...selectedSector,
          subsectors: subsectors,
        };
        state.pool[sectorIndex] = updatedSector;
      } else {
        state.pool[0] = payload;
      }
    },
    addSubsector: (state, { payload }) => {
      const { sectorOpened, subsector } = payload;
      const sectorsState = state.pool;
      const sectorIndex = findIndex(
        sectorsState,
        sector => sector.slug === sectorOpened.slug,
      );
      if (sectorIndex !== -1) {
        const selectedSector = sectorsState[sectorIndex];
        if (selectedSector["subsectors"]) {
          if (
            !selectedSector.subsectors.some(
              existing => existing.slug === subsector.slug,
            )
          ) {
            selectedSector.subsectors.push(subsector);
          }
        } else {
          selectedSector["subsectors"] = [];
          selectedSector["subsectors"].push(subsector);
        }
      }
    },
    fetchSubsectorsPending: state => {
      state.fetchSubsectorsStatus = REQUEST_PENDING;
    },
    fetchSubsectorsSuccess: state => {
      state.fetchSubsectorsStatus = REQUEST_SUCCESS;
    },
    fetchSubsectorsFailure: state => {
      state.fetchSubsectorsStatus = REQUEST_FAILURE;
    },
  },
});
export { reducer, actions };
