import { createStyles, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(({ palette, spacing }) =>
  createStyles({
    root: {
      padding: spacing(4),
      margin: "0 auto",
    },
    technologiesCards: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      gap: spacing(2),
      paddingTop: spacing(3),
    },
    suggestCard: {
      background: "transparent",
      border: `1px solid ${palette.text.lighter}`,
      borderRadius: spacing(3),
      width: "calc(100% /  2 - 12px)",
      display: "flex",
      flexDirection: "column",
      padding: spacing(4),
      marginTop: spacing(3),
    },
    mt24: {
      marginTop: spacing(3),
    },
    suggestButton: {
      marginTop: spacing(1),
      width: 284,
    },
  }),
);
