import { call, put } from "redux-saga/effects";
import { actions } from "../slice";

import {
  emailValidation,
  emailVerification,
  startFreeTrial,
  updateFreeTrialPut,
} from "./helpers";

function* postStartFreeTrial(data) {
  try {
    yield put(
      data.isEmailChange
        ? actions.emailChangePending()
        : actions.startFreeTrialPending(),
    );
    if (data.isEmailChange) {
      yield put(actions.setEmailChange(true));
    }
    const response = yield call(startFreeTrial, data);
    yield put(actions.setStartFreeTrialResponse(response.data));
    yield put(
      data.isEmailChange
        ? actions.emailChangeSuccess()
        : actions.startFreeTrialSuccess(),
    );
  } catch (err) {
    yield put(
      data.isEmailChange
        ? actions.emailChangeFailure()
        : actions.startFreeTrialFailure(),
    );
  }
}

function* updateFreeTrial(data) {
  try {
    yield put(actions.updateFreeTrialPending());
    yield call(updateFreeTrialPut, data);
    yield put(actions.updateFreeTrialSuccess());
  } catch (err) {
    yield put(actions.updateFreeTrialFailure());
  }
}

function* sendEmailVerification() {
  try {
    yield put(actions.sendEmailVerificationPending());
    yield call(emailVerification);
    yield put(actions.sendEmailVerificationSuccess());
  } catch (err) {
    yield put(actions.sendEmailVerificationFailure());
  }
}

function* sendEmailValidation(data) {
  try {
    yield put(actions.sendEmailValidationPending());
    const response = yield call(emailValidation, data);
    yield put(actions.setEmailValidationState(response.data));
    yield put(actions.sendEmailValidationSuccess());
  } catch (err) {
    yield put(actions.sendEmailValidationFailure());
  }
}

export {
  postStartFreeTrial,
  updateFreeTrial,
  sendEmailVerification,
  sendEmailValidation,
};
