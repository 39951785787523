import React, { useEffect, useState } from "react";
import classnames from "classnames";
import moment from "moment";
// import capitalize from "lodash/capitalize";
// Hooks
import { useStyles } from "./style.js";
import { useProfileSettingsHook } from "./hooks/useProfileSettingsHook";
import { useFreeTrialHook } from "../Layout/hooks/useFreeTrialHook";
//Redux
import { useDispatch, useSelector } from "react-redux";
import { mixpanelTrackRequest } from "../../store/mixpanel/requests";
//UI Components
import Typography from "../../components/Typography/Typography.js";
import TextField from "../../components/TextField/TextField";
import Button from "../../components/Button/Button";
import Layout from "../Layout/Layout";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import DeleteUserModal from "./DeleteUserModal";
import CostBreakdownModal from "./CostBreakdownModal";
import Menu from "../../components/Menu/Menu";
import ConfirmLeaveTheTeamModal from "./modals/ConfirmLeaveTheTeamModal/ConfirmLeaveTheTeamModal";
// Assets
import { ReactComponent as EyeIcon } from "../../assets/icons/EyeIcon.svg";
import NoAvatarUser from "../../assets/icons/AvatarDefault.png";
// import { ReactComponent as AddUserIcon } from "../../assets/icons/AddUserIcon.svg";
import CompanyProfileIllustration from "../../assets/icons/CompanyProfileIllustration.svg";
import ProfileLockImage from "../../assets/icons/ProfileLockImage.png";
import Warning from "../../assets/icons/Warning.svg";
import MoreIcon from "../../assets/icons/MoreIcon.svg";
import ContentLoader from "../../components/ContentLoader/ContentLoader.js";
import { ReactComponent as ArrowDownEmpty } from "../../assets/icons/ArrowDownEmpty.svg";
import { ReactComponent as TickSmall } from "../../assets/icons/TickSmall.svg";
// Redux
import {
  getTeamRequest,
  leaveTeamRequest,
  postLogoutRequest,
} from "../../store/auth/requests";
import { getLeaveTeamStatusSelector } from "../../store/auth/selectors";
// Constants
// import { getPricingPage } from "../../constants/endpoints";
import { REQUEST_SUCCESS } from "../../constants/statuses.js";
// import { fetchTeam } from "../../store/auth/saga/workers.js";
import { useSectorsHook } from "../SearchCompanies/hooks/useSectorsHook";
// import SectorsDialog from "../SearchCompanies/components/SectorsDialog";
// import Box from "@material-ui/core/Box";
// import { ReactComponent as CloseIconEmpty } from "../../assets/icons/CloseIconEmpty.svg";
import { fetchSectorsRequest } from "../../store/sectors/requests";
// import InviteUsersModal from "../../components/InviteUsersModal/InviteUsersModal";
import OnboardingFreeTrialModal from "../FreeTrial/OnboardingFreeTrialModal/OnboardingFreeTrialModal";
import { getSearchesSelector } from "../../store/results/selectors";
import { actions as resultsActions } from "../../store/results/slice";
import useLocalStorage from "../../common/useLocalStorage";
import { useQueryClient } from "@tanstack/react-query";
import TopUpCreditsModal from "../Company/LockedPage/TopUpCreditsModal/TopUpCreditsModal";

const MoreComponent = ({ t, classes, row }) => {
  const [menuOpened, setMenuOpened] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const openDialog = () => {
    setShowModal(true);
    setMenuOpened(false);
  };

  return (
    <>
      <ClickAwayListener onClickAway={() => setMenuOpened(false)}>
        <div className={classes.moreIconWrapper}>
          <img
            src={MoreIcon}
            onClick={() => setMenuOpened(true)}
            className={classes.moreIcon}
            alt={t("profileSettings.more")}
          />
          {menuOpened && (
            <div
              className={classes.moreIconMenu}
              onClick={() => openDialog(row)}>
              <Typography
                variant="bodyXs"
                className={classes.moreIconMenuItem}
                component="div"
                color="light">
                {t("profileSettings.deleteUser")}
              </Typography>
            </div>
          )}
        </div>
      </ClickAwayListener>
      {showModal && (
        <DeleteUserModal
          dialogOpened={showModal}
          user={row}
          onChange={() => setShowModal(!showModal)}></DeleteUserModal>
      )}
    </>
  );
};

const ProfileSettings = () => {
  const classes = useStyles();
  const getLeaveTeamStatus = useSelector(getLeaveTeamStatusSelector);
  const searchesSelector = useSelector(getSearchesSelector);
  const dispatch = useDispatch();
  const {
    tabView,
    openTab,
    user,
    changeName,
    changeEmail,
    changePassword,
    changeCompanyName,
    changeCompanyCountry,
    changeCompanyCity,
    changePhoneNumber,
    setChangePhoneNumber,
    editData,
    profileName,
    handleProfileName,
    profileEmail,
    handleProfileEmail,
    currentPassword,
    handleCurrentPassword,
    newPassword,
    handleNewPassword,
    newPasswordAgain,
    handleNewPasswordAgain,
    passwordFieldType,
    handlePasswordFieldTypeChange,
    companyProfileName,
    handleCompanyProfileName,
    companyProfileCountry,
    handleCompanyProfileCountry,
    companyProfileCity,
    phoneNumber,
    handleCompanyProfileCity,
    handlePhoneNumber,
    updateProfileData,
    setChangeName,
    setChangeEmail,
    setChangePassword,
    setChangeCompanyName,
    setChangeCompanyCountry,
    setChangeCompanyCity,
    t,
    billings,
    team,
    inputFile,
    subscription,
    showBreakdownModal,
    setShowBreakdownModal,
    handleEditImage,
    openPage,
    credits,
    fileUploadError,
    onFileUploadChange,
    removeImage,
    // openBilling,
    // openTeam,
    handleUpgradePlan,
    handleGetNewSubscription,
    shouldBeBuying,
    isLoading,
    seatsAvailable,
  } = useProfileSettingsHook();

  const {
    // selectedSearchSector,
    // sector,
    // newSubsector,
    // handleSubsectorSelect,
    // handleAddSubsector,
    // handleSubsectorRemove,
    // handleAddNewSubsector,
    // openSubsectorsDialog,
    // handleToggleDialog,
    // dialogOpened,
    selectedSectors,
    allSectors,
    handleSectorSelect,
    removeAllSectors,
  } = useSectorsHook();

  const {
    showOnboardingTrialModal,
    // setShowOnboardingTrialModal,
    // handleStartTrial,
    handleClosingOnboardingFreeTrialModal,
    // talkToSales,
  } = useFreeTrialHook({ user: user });

  const [sectorsDialogOpened, setSectorsDialogOpened] = useState(false);
  const [shouldRenderFavoriteIndustries, setShouldRenderFavoriteIndustries] =
    useState(false);
  const [isHandleInviteUsersTriggered, setIsHandleInviteUsersTriggered] =
    useState(false);
  const [showInviteUsersModal, setShowInviteUsersModal] = useState(false);
  const [startProductTour, setStartProductTour] = useState(false);
  const [topUpCreditsModalShow, setTopUpCreditsModalShow] = useState(false);

  const sessionSlugResults = sessionStorage.getItem(`results-slug`);
  const sessionSlugIndustry = sessionStorage.getItem(`industry-slug`);
  const sessionSlugTechnology = sessionStorage.getItem(`technology-slug`);
  const { clearStorage: clearResultsStorage } = useLocalStorage(
    "results",
    sessionSlugResults,
  );
  const { clearStorage: clearResultsPageStorage } = useLocalStorage(
    "results",
    "page",
  );
  const { clearStorage: clearIndustryStorage } = useLocalStorage(
    "industry",
    sessionSlugIndustry,
  );
  const { clearStorage: clearTechnologyStorage } = useLocalStorage(
    "technology",
    sessionSlugTechnology,
  );

  const handleSectorsDialogToggle = () => {
    setSectorsDialogOpened(!sectorsDialogOpened);
  };

  const queryClient = useQueryClient();

  const signOut = () => {
    dispatch(resultsActions.getSearchesStatusUndefined());
    dispatch(postLogoutRequest());
    clearResultsStorage();
    clearIndustryStorage();
    clearTechnologyStorage();
    clearResultsPageStorage();
    localStorage.removeItem("isSharedResultsPageToken");
    localStorage.removeItem("search_id");
    dispatch(resultsActions.setCurrentSearchSid(null));
    dispatch(resultsActions.setCurrentSearch(null));
    dispatch(resultsActions.getRecentSearchesStatusUndefined());

    queryClient.removeQueries();
  };

  useEffect(() => {
    if (!isLoading()) {
      dispatch(fetchSectorsRequest());
    }
  }, [isLoading()]);

  // const removeSector = sector => {
  //     dispatch(searchActions.removeSector(sector));
  //     updateProfileData(
  //         "favoriteIndustries",
  //         JSON.parse(user.favorite_industries).filter(
  //             item => item.slug !== sector.slug,
  //         ),
  //     );
  // };
  // const onSave = selectedSectors => {
  //     updateProfileData("favoriteIndustries", selectedSectors);
  // };

  useEffect(() => {
    const hasFavoriteIndustries =
      user &&
      user.favorite_industries !== "null" &&
      user.favorite_industries !== null &&
      user.favorite_industries.length > 0;
    const isReady = hasFavoriteIndustries && allSectors.length > 0;

    // sync selected sectors with users favorite industries
    if (!hasFavoriteIndustries) {
      removeAllSectors();
    }
    if (isReady && selectedSectors.length < 1) {
      JSON.parse(user.favorite_industries).forEach(industry => {
        handleSectorSelect(
          industry,
          selectedSectors.some(i => i.slug === industry.slug),
        );
      });
    }

    setShouldRenderFavoriteIndustries(!!isReady);
  }, [user, allSectors, sectorsDialogOpened]);

  // reset selected sectors on component unmount
  useEffect(() => {
    return () => {
      removeAllSectors();
    };
  }, []);

  const [confirmLeaveTheTeamModalShow, setConfirmLeaveTheTeamModalShow] =
    useState(false);

  useEffect(() => {
    dispatch(mixpanelTrackRequest({ eventName: "Profile settings" }));
  }, [dispatch]);

  useEffect(() => {
    getLeaveTeamStatus === REQUEST_SUCCESS &&
      dispatch(getTeamRequest({ team: user.team.slug }));
  }, [getLeaveTeamStatus]);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(window.innerWidth <= 575);
  }, []);

  const [menuItems, setMenuItems] = useState([]);
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  let teamsMenuItems = [];

  const [currentTeam, setCurrentTeam] = useState();

  const handleMenuClick = team => {
    setIsMenuOpened(prevIsMenuOpened => !prevIsMenuOpened);
  };

  const [currentTeamName, setCurrentTeamName] = useState();

  const handleTeamSelect = team => {
    setCurrentTeam(team);
    dispatch(getTeamRequest({ team: team.slug }));
  };

  useEffect(() => {
    currentTeam && setCurrentTeamName(currentTeam.name);
  }, [currentTeam]);
  useEffect(() => {
    if (user && user.team && user.teams_other && user.teams_other.length > 0) {
      setCurrentTeam(user.team);
      teamsMenuItems[0] = {
        team: user.team.name,
        label: (
          <Typography className={classes.menuItem}>
            {user.team.name}
            {currentTeam && currentTeam.slug === user.team.slug && (
              <TickSmall />
            )}
          </Typography>
        ),
        onClick: () => handleTeamSelect(user.team),
      };
      user.teams_other.map((team, i) => {
        teamsMenuItems[i + 1] = {
          team: team.name,
          label: (
            <Typography key={i} className={classes.menuItem}>
              {team.name}
              {currentTeam && currentTeam.slug === team.slug && <TickSmall />}
            </Typography>
          ),
          onClick: () => handleTeamSelect(team),
        };
      });
      setMenuItems(teamsMenuItems);
    }
  }, [user]);

  useEffect(() => {
    if (user && user.team && user.teams_other && user.teams_other.length > 0) {
      teamsMenuItems[0] = {
        team: user.team.name,
        label: (
          <Typography className={classes.menuItem}>
            {user.team.name}
            {currentTeam && currentTeam.slug === user.team.slug && (
              <TickSmall />
            )}
          </Typography>
        ),
        onClick: () => handleTeamSelect(user.team),
      };
      user.teams_other.map((team, i) => {
        teamsMenuItems[i + 1] = {
          team: team.name,
          label: (
            <Typography key={i} className={classes.menuItem}>
              {team.name}
              {currentTeam && currentTeam.slug === team.slug && <TickSmall />}
            </Typography>
          ),
          onClick: () => handleTeamSelect(team),
        };
      });
      setMenuItems(teamsMenuItems);
    }
  }, [currentTeam, user]);

  const handleLeaveTheTeamConfirm = () => {
    dispatch(leaveTeamRequest({ team: user.team.slug }));
    setConfirmLeaveTheTeamModalShow(false);
  };

  const [leaveTooltipOpen, setLeaveTooltipOpen] = useState(false);

  // const handleTooltipClose = () => {
  //     setLeaveTooltipOpen(false);
  // };

  // const handleTooltipOpen = () => {
  //     setLeaveTooltipOpen(true);
  // };

  const openInviteUserModal = startProductTour => {
    if (startProductTour) {
      setStartProductTour(startProductTour);
    }
    setShowInviteUsersModal(true);
  };

  // const getMoreCredits = () => {
  //     setTopUpCreditsModalShow(true);
  // };

  const handleBuyingTopUpCreditsDialog = () => {
    setTopUpCreditsModalShow(false);
  };

  const handleClosingTopUpCreditsDialog = () => {
    setTopUpCreditsModalShow(false);
  };

  // const handleClosingInviteModal = () => {
  //     setShowInviteUsersModal(false);
  //     if (startProductTour) {
  //         setStartProductTour(false);
  //     }
  // };

  // const handleInviteUsers = () => {
  //     if (
  //         user &&
  //         !user.company &&
  //         subscription &&
  //         (subscription.name === "free" || subscription.free_trial) &&
  //         searchesSelector.length > 1
  //     ) {
  //         dispatch(authActions.completeProfileModalShowTrue());
  //         setIsHandleInviteUsersTriggered(true);
  //     } else {
  //         if (startProductTour) {
  //             setStartProductTour(startProductTour);
  //         }
  //         setShowInviteUsersModal(true);
  //     }
  // };

  return (
    <Layout match="profile-settings">
      <TopUpCreditsModal
        dialogOpened={topUpCreditsModalShow}
        onBuy={handleBuyingTopUpCreditsDialog}
        onChange={handleClosingTopUpCreditsDialog}
      />
      <div className={classes.root}>
        <Typography variant="subtitle1">
          {t("profileSettings.title")}
        </Typography>
        <div>
          <div className={classes.links}>
            <div
              className={classnames([
                classes.link,
                tabView === "profile" ? classes.active : "",
              ])}
              onClick={() => openTab("profile")}>
              {/* <div className={classes.imgWrapper}>
                                <img src={ProfileSettingsIllustration} alt={t("profileSettings.accountTitle")}/>
                            </div> */}
              <Typography
                variant="bodyXs"
                color="primary"
                component="div"
                className={classes.subtitle}>
                {t("profileSettings.accountSubtitle")}
              </Typography>
              <Typography
                variant="bodySmall"
                color="black"
                component="div"
                className={classes.title}>
                {t("profileSettings.accountTitle")}
              </Typography>
              <Typography
                variant="bodyXs"
                component="div"
                color="light"
                className={classes.description}>
                {t("profileSettings.accountDescription")}
              </Typography>
            </div>
            {/* <div
                            className={classnames([classes.link, tabView === 'notifications' ? classes.active : ""])}
                            onClick={() => openTab('notifications')}>
                                <div className={classes.imgWrapper}>
                                    <img src={NotificationsIllustration} alt={t("profileSettings.notificationsTitle")}/>
                                </div>
                                <Typography variant="bodyXs" color="primary" component="div" className={classes.subtitle}>{t("profileSettings.notificationsSubtitle")}</Typography>
                                <Typography variant="bodySmall" color="black" component="div" className={classes.title}>{t("profileSettings.notificationsTitle")}</Typography>
                                <Typography variant="bodyXs" component="div" color="light">{t("profileSettings.notificationsDescription")}</Typography>
                        </div> */}
            {/* {user &&
                            user.subscription &&
                            user.subscription.name !== "free" && (
                                <div
                                    className={classnames([
                                        classes.link,
                                        tabView === "billing"
                                            ? classes.active
                                            : "",
                                    ])}
                                    onClick={openBilling}> */}
            {/* <div className={classes.imgWrapper}>
                                    <img src={BillingIllustration} alt={t("profileSettings.billingTitle")}/>
                                </div> */}
            {/* <Typography
                                        variant="bodyXs"
                                        color="primary"
                                        component="div"
                                        className={classes.subtitle}>
                                        {t("profileSettings.billingSubtitle")}
                                    </Typography>
                                    <Typography
                                        variant="bodySmall"
                                        color="black"
                                        component="div"
                                        className={classes.title}>
                                        {t("profileSettings.billingTitle")}
                                    </Typography>
                                    <Typography
                                        variant="bodyXs"
                                        component="div"
                                        color="light"
                                        className={classes.description}>
                                        {t(
                                            "profileSettings.billingDescription",
                                        )}
                                    </Typography>
                                </div>
                            )} */}
            {/* {(team ||
                            (user &&
                                user.teams_other &&
                                user.teams_other.length > 0)) && (
                            <div
                                className={classnames([
                                    classes.link,
                                    tabView === "team" ? classes.active : "",
                                ])}
                                onClick={openTeam}> */}
            {/* <div className={classes.imgWrapper}>
                                    <img src={TeamIllustration} alt={t("profileSettings.teamTitle")}/>
                                </div> */}
            {/* <Typography
                                    variant="bodyXs"
                                    color="primary"
                                    component="div"
                                    className={classes.subtitle}>
                                    {t("profileSettings.teamSubtitle")}
                                </Typography>
                                <Typography
                                    variant="bodySmall"
                                    color="black"
                                    component="div"
                                    className={classes.title}>
                                    {t("profileSettings.teamTitle")}
                                </Typography>
                                <Typography
                                    variant="bodyXs"
                                    component="div"
                                    color="light"
                                    className={classes.description}>
                                    {t("profileSettings.teamDescription")}
                                </Typography>
                            </div>
                        )} */}
            <div className={classes.userActionBtns}>
              {user && <Button onClick={signOut}>{t("sidebar.logOut")}</Button>}
              {/* {
								user && user.subscription &&
			                    <Button variant="tertiary" className={classes.uploadBtn} onClick={handleInviteUsers}>
				                    <AddUserIcon />
				                    Invite Users
			                    </Button>
		                    }
		                    {showInviteUsersModal && <InviteUsersModal dialogOpened={showInviteUsersModal} onChange={handleClosingInviteModal} startProductTour={startProductTour}/>} */}
            </div>
          </div>
          {isLoading() ? (
            <ContentLoader />
          ) : tabView === "profile" ? (
            <>
              <div className={classes.profile}>
                {/* show only on small desktop and below */}
                {/* <div
                                    className={classnames([
                                        classes.creditsInfoWrap,
                                        "desktop",
                                    ])}>
                                    {user &&
                                        user.subscription &&
                                        user.subscription.name !== "free" &&
                                        !user.is_invited && (
                                            <div className={classes.credits}>
                                                <div
                                                    className={
                                                        classes.creditsHeader
                                                    }>
                                                    <Coins />
                                                    <div
                                                        className={
                                                            classes.availableCredits
                                                        }>
                                                        <Typography variant="h3">
                                                            <strong>
                                                                {credits}
                                                            </strong>
                                                        </Typography>
                                                        <Typography
                                                            variant="bodyXs"
                                                            color="secondaryDark">
                                                            {t(
                                                                "profileSettings.availableCredits",
                                                            )}
                                                        </Typography>
                                                    </div>
                                                </div>
                                                <Typography
                                                    variant="body"
                                                    color="black"
                                                    component="div">
                                                    {t(
                                                        "profileSettings.credits",
                                                    )}
                                                </Typography>
                                                <Typography
                                                    variant="bodyXs"
                                                    color="textLight"
                                                    component="div">
                                                    {t(
                                                        "profileSettings.creditsDesc1",
                                                    )}
                                                </Typography>
                                                <Typography
                                                    variant="bodyXs"
                                                    color="textLight"
                                                    component="div">
                                                    {t(
                                                        "profileSettings.creditsDesc2",
                                                    )}
                                                </Typography>
                                                <Typography
                                                    variant="bodyXs"
                                                    color="textLight"
                                                    component="div">
                                                    {t(
                                                        "profileSettings.creditsDesc3",
                                                    )}
                                                </Typography>
                                                <Typography
                                                    variant="bodyXs"
                                                    className={
                                                        classes.seeCreditSummary
                                                    }
                                                    onClick={() =>
                                                        openPage(
                                                            "/credit-summary",
                                                        )
                                                    }
                                                    component="div">
                                                    {t(
                                                        "profileSettings.seeCreditSummary",
                                                    )}
                                                </Typography>
                                            </div>
                                        )}
                                </div> */}
                {/* show only on small desktop and below */}
                <div className={classes.profileWrap}>
                  {/* profile page */}
                  <div className={classes.editLogo}>
                    <div className={classes.logoWrapper}>
                      <input
                        type="file"
                        id="file"
                        ref={inputFile}
                        style={{ display: "none" }}
                        onChange={onFileUploadChange}
                      />
                      {user && user.avatar ? (
                        <div className={classes.avatarWrapper}>
                          <div className={classes.avatar}>
                            <img
                              className={classes.avatarImg}
                              src={user.avatar}
                              alt={t("companyName")}
                            />
                          </div>
                        </div>
                      ) : (
                        <div className={classes.avatarWrapper}>
                          <img
                            className={classes.avatarImg}
                            src={NoAvatarUser}
                            alt={t("companyName")}
                          />
                        </div>
                      )}
                    </div>
                    <Button
                      variant="tertiary"
                      onClick={handleEditImage}
                      className={classes.uploadBtn}>
                      {t("profileSettings.uploadPicture")}
                    </Button>
                    <Button
                      onClick={removeImage}
                      className={classes.edit}
                      variant="textSecondary">
                      {t("profileSettings.delete")}
                    </Button>
                    {/* <Typography className={classes.maximumSize} variant="caption">{t("profileSettings.maximumFileSize")}</Typography> */}
                    {fileUploadError && (
                      <Typography>
                        {t("profileSettings.uploadError")}
                      </Typography>
                    )}
                  </div>
                  <div className={classes.flexColumn}>
                    <div className={classes.infoRow}>
                      <Typography
                        className={classes.profileTitle}
                        variant="bodyXs"
                        color="textLight"
                        component="div">
                        {t("profileSettings.name")}
                      </Typography>
                      {changeName ? (
                        <div>
                          <TextField
                            fullWidth={isMobile}
                            placeholder="Enter profile name"
                            value={profileName}
                            component="div"
                            className={classes.textInput}
                            onChange={handleProfileName}></TextField>
                          <div className={classes.buttons}>
                            <Button
                              onClick={() => setChangeName(false)}
                              variant="textSecondary">
                              {t("profileSettings.cancel")}
                            </Button>
                            <Button
                              onClick={() => updateProfileData("name")}
                              variant="primary">
                              {t("profileSettings.saveChanges")}
                            </Button>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <Typography
                            className={classes.profileValue}
                            variant="subtitle1"
                            component="div">
                            {user && user.name}
                          </Typography>
                          <Typography
                            onClick={() => editData("profileName")}
                            variant="bodyXs"
                            color="primary"
                            className={classes.changeLink}>
                            {t("profileSettings.edit")}
                          </Typography>
                        </div>
                      )}
                    </div>
                    <div className={classes.infoRow}>
                      <Typography
                        className={classes.profileTitle}
                        variant="bodyXs"
                        color="textLight">
                        {t("profileSettings.email")}
                      </Typography>
                      {changeEmail ? (
                        <div>
                          <TextField
                            fullWidth={isMobile}
                            placeholder="Enter profile email"
                            value={profileEmail}
                            className={classes.textInput}
                            onChange={handleProfileEmail}></TextField>
                          <div className={classes.buttons}>
                            <Button
                              onClick={() => setChangeEmail(false)}
                              variant="textSecondary">
                              {t("profileSettings.cancel")}
                            </Button>
                            <Button
                              onClick={() => updateProfileData("email")}
                              variant="primary">
                              {t("profileSettings.saveChanges")}
                            </Button>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <Typography
                            className={classes.profileValue}
                            variant="subtitle1"
                            component="div">
                            {user && user.email}
                          </Typography>
                        </div>
                      )}
                    </div>
                    <div className={classes.infoRow}>
                      <Typography
                        className={classes.profileTitle}
                        variant="bodyXs"
                        color="textLight">
                        {t("profileSettings.password")}
                      </Typography>
                      {changePassword ? (
                        <div>
                          <TextField
                            fullWidth={isMobile}
                            endAdornment={
                              <EyeIcon
                                className={classes.eye}
                                onClick={() =>
                                  handlePasswordFieldTypeChange(
                                    "currentPassword",
                                  )
                                }
                              />
                            }
                            type={passwordFieldType[0] ? "password" : "text"}
                            placeholder="Enter current password"
                            value={currentPassword}
                            className={classes.textInput}
                            onChange={handleCurrentPassword}></TextField>
                          <TextField
                            fullWidth={isMobile}
                            endAdornment={
                              <EyeIcon
                                className={classes.eye}
                                onClick={() =>
                                  handlePasswordFieldTypeChange("newPassword")
                                }
                              />
                            }
                            type={passwordFieldType[1] ? "password" : "text"}
                            placeholder="Enter new password"
                            value={newPassword}
                            className={classes.textInput}
                            onChange={handleNewPassword}></TextField>
                          <TextField
                            fullWidth={isMobile}
                            endAdornment={
                              <EyeIcon
                                className={classes.eye}
                                onClick={() =>
                                  handlePasswordFieldTypeChange(
                                    "newPasswordAgain",
                                  )
                                }
                              />
                            }
                            type={passwordFieldType[2] ? "password" : "text"}
                            placeholder="Enter new password again"
                            value={newPasswordAgain}
                            className={classes.textInput}
                            onChange={handleNewPasswordAgain}></TextField>
                          <div className={classes.buttons}>
                            <Button
                              onClick={() => setChangePassword(false)}
                              variant="textSecondary">
                              {t("profileSettings.cancel")}
                            </Button>
                            <Button
                              onClick={() => updateProfileData("password")}
                              variant="primary">
                              {t("profileSettings.saveChanges")}
                            </Button>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <Typography
                            onClick={() => editData("profilePassword")}
                            variant="bodyXs"
                            color="primary"
                            className={classes.changeLink}>
                            {t("profileSettings.edit")}
                          </Typography>
                        </div>
                      )}
                    </div>
                    {/* Favorite industries */}
                    {/* <div className={classes.infoRow}>
                                            <Typography
                                                component="div"
                                                className={classes.profileTitle}
                                                variant="bodyXs"
                                                color="textLight">
                                                {t(
                                                    "profileSettings.favoriteIndustries.title",
                                                )}
                                            </Typography>
                                            <Typography
                                                component="div"
                                                className={classes.profileTitle}
                                                variant="bodyXs"
                                                color="textLight">
                                                {t(
                                                    "profileSettings.favoriteIndustries.subtitle",
                                                )}
                                            </Typography>
                                            <Box
                                                flexDirection="row"
                                                display="flex"
                                                flexWrap="wrap"
                                                maxWidth="500px"
                                                className={
                                                    classes.subsectorSection
                                                }>
                                                {user &&
                                                    JSON.parse(
                                                        user.favorite_industries,
                                                    ) &&
                                                    shouldRenderFavoriteIndustries &&
                                                    JSON.parse(
                                                        user.favorite_industries,
                                                    ).map(favIndustry => (
                                                        <div
                                                            key={`selected-sector-${favIndustry.slug}`}
                                                            className={
                                                                classes.subsectorSectionIndustry
                                                            }>
                                                            <Button
                                                                variant="pill"
                                                                iconMarginLeft
                                                                iconMargin={
                                                                    false
                                                                }
                                                                onClick={() =>
                                                                    removeSector(
                                                                        favIndustry,
                                                                    )
                                                                }
                                                                className={classnames(
                                                                    [
                                                                        classes.sector,
                                                                        classes.selectedSubsector,
                                                                    ],
                                                                )}>
                                                                {
                                                                    favIndustry.name
                                                                }{" "}
                                                                <CloseIconEmpty
                                                                    className={classnames(
                                                                        classes.removeIndustryIcon,
                                                                        "blackIcon",
                                                                    )}
                                                                />
                                                            </Button>
                                                        </div>
                                                    ))}
                                                <Typography
                                                    onClick={() =>
                                                        handleSectorsDialogToggle()
                                                    }
                                                    variant="bodyXs"
                                                    color="primary"
                                                    className={
                                                        classes.favoriteIndustriesEditButton
                                                    }>
                                                    {t(
                                                        "profileSettings.favoriteIndustries.button",
                                                    )}
                                                </Typography>
                                            </Box>
                                        </div>
                                        <SectorsDialog
                                            classes={classes}
                                            handleToggleDialog={
                                                handleSectorsDialogToggle
                                            }
                                            dialogOpened={sectorsDialogOpened}
                                            selectedSectorsState={
                                                selectedSectors
                                            }
                                            allSectors={allSectors}
                                            handleSectorSelect={
                                                handleSectorSelect
                                            }
                                            onSave={onSave}
                                            showResetButton={true}
                                        /> */}
                  </div>
                </div>
                {/* show only on desktop and above */}
                {/* <div
                                    className={classnames([
                                        classes.creditsInfoWrap,
                                        "non-desktop",
                                    ])}>
                                    {user &&
                                        user.subscription &&
                                        user.subscription.name !== "free" &&
                                        !user.is_invited && (
                                            <div className={classes.credits}>
                                                <div
                                                    className={
                                                        classes.creditsHeader
                                                    }>
                                                    <Coins />
                                                    <div
                                                        className={
                                                            classes.availableCredits
                                                        }>
                                                        <Typography variant="h3">
                                                            <strong>
                                                                {credits}
                                                            </strong>
                                                        </Typography>
                                                        <Typography
                                                            variant="bodyXs"
                                                            color="secondaryDark">
                                                            {t(
                                                                "profileSettings.availableCredits",
                                                            )}
                                                        </Typography>
                                                    </div>
                                                </div>
                                                <Typography
                                                    variant="body"
                                                    color="black"
                                                    component="div">
                                                    {t(
                                                        "profileSettings.credits",
                                                    )}
                                                </Typography>
                                                <Typography
                                                    variant="bodyXs"
                                                    color="textLight"
                                                    component="div">
                                                    {t(
                                                        "profileSettings.creditsDesc1",
                                                    )}
                                                </Typography>
                                                <Typography
                                                    variant="bodyXs"
                                                    color="textLight"
                                                    component="div">
                                                    {t(
                                                        "profileSettings.creditsDesc2",
                                                    )}
                                                </Typography>
                                                <Typography
                                                    variant="bodyXs"
                                                    color="textLight"
                                                    component="div">
                                                    {t(
                                                        "profileSettings.creditsDesc3",
                                                    )}
                                                </Typography>
                                                <Typography
                                                    variant="bodyXs"
                                                    className={
                                                        classes.seeCreditSummary
                                                    }
                                                    onClick={() =>
                                                        openPage(
                                                            "/credit-summary",
                                                        )
                                                    }
                                                    component="div">
                                                    {t(
                                                        "profileSettings.seeCreditSummary",
                                                    )}
                                                </Typography>

                                                <Button
                                                    variant="tertiary"
                                                    onClick={getMoreCredits}
                                                    className={
                                                        classes.uploadBtn
                                                    }>
                                                    {t(
                                                        "profileSettings.topUpCredits",
                                                    )}
                                                </Button>
                                            </div>
                                        )}
                                </div> */}
                {/* show only on desktop and above */}
              </div>
              {/* Company profile */}
              <div className={classes.companyWrap}>
                <Typography variant="subtitle1" component="div">
                  {t("profileSettings.companyProfileSettingsTitle")}
                </Typography>
                <div display="flex" className={classes.profileWrap}>
                  <div
                    className={classnames([classes.editLogo, classes.hideImg])}>
                    <div className={classes.logoWrapper}>
                      <div className={classes.companyIllustration}>
                        <img
                          src={CompanyProfileIllustration}
                          alt={t("companyName")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={classes.flexColumn}>
                    <div className={classes.infoRow}>
                      <Typography
                        className={classes.profileTitle}
                        variant="bodyXs"
                        color="textLight">
                        {t("profileSettings.companyName")}
                      </Typography>
                      {changeCompanyName ? (
                        <div>
                          <TextField
                            fullWidth={isMobile}
                            placeholder="Enter company name"
                            value={companyProfileName}
                            className={classes.textInput}
                            onChange={handleCompanyProfileName}></TextField>
                          <div className={classes.buttons}>
                            <Button
                              onClick={() => setChangeCompanyName(false)}
                              variant="textSecondary">
                              {t("profileSettings.cancel")}
                            </Button>
                            <Button
                              onClick={() => updateProfileData("companyName")}
                              variant="primary">
                              {t("profileSettings.saveChanges")}
                            </Button>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <Typography
                            className={classes.profileValue}
                            variant="subtitle1"
                            component="div">
                            {companyProfileName}
                          </Typography>
                          <Typography
                            onClick={() => editData("companyName")}
                            variant="bodyXs"
                            color="primary"
                            className={classes.changeLink}>
                            {t("profileSettings.edit")}
                          </Typography>
                        </div>
                      )}
                    </div>
                    <div className={classes.infoRow}>
                      <Typography
                        className={classes.profileTitle}
                        variant="bodyXs"
                        color="textLight">
                        {t("profileSettings.country")}
                      </Typography>

                      {changeCompanyCountry ? (
                        <div>
                          <TextField
                            fullWidth={isMobile}
                            placeholder="Enter company country"
                            value={companyProfileCountry}
                            className={classes.textInput}
                            onChange={handleCompanyProfileCountry}></TextField>
                          <div className={classes.buttons}>
                            <Button
                              onClick={() => setChangeCompanyCountry(false)}
                              variant="textSecondary">
                              {t("profileSettings.cancel")}
                            </Button>
                            <Button
                              onClick={() =>
                                updateProfileData("companyCountry")
                              }
                              variant="primary">
                              {t("profileSettings.saveChanges")}
                            </Button>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <Typography
                            className={classes.profileValue}
                            variant="subtitle1"
                            component="div">
                            {companyProfileCountry}
                          </Typography>
                          <Typography
                            onClick={() => editData("companyCountry")}
                            variant="bodyXs"
                            color="primary"
                            className={classes.changeLink}>
                            {t("profileSettings.edit")}
                          </Typography>
                        </div>
                      )}
                    </div>
                    <div className={classes.infoRow}>
                      <Typography
                        className={classes.profileTitle}
                        variant="bodyXs"
                        color="textLight">
                        {t("profileSettings.city")}
                      </Typography>
                      {changeCompanyCity ? (
                        <div>
                          <TextField
                            fullWidth={isMobile}
                            placeholder="Enter company country"
                            value={companyProfileCity}
                            className={classes.textInput}
                            onChange={handleCompanyProfileCity}></TextField>
                          <div className={classes.buttons}>
                            <Button
                              onClick={() => setChangeCompanyCity(false)}
                              variant="textSecondary">
                              {t("profileSettings.cancel")}
                            </Button>
                            <Button
                              onClick={() => updateProfileData("companyCity")}
                              variant="primary">
                              {t("profileSettings.saveChanges")}
                            </Button>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <Typography
                            className={classes.profileValue}
                            variant="subtitle1"
                            component="div">
                            {companyProfileCity}
                          </Typography>
                          <Typography
                            onClick={() => editData("companyCity")}
                            variant="bodyXs"
                            color="primary"
                            className={classes.changeLink}>
                            {t("profileSettings.edit")}
                          </Typography>
                        </div>
                      )}
                    </div>
                    <div className={classes.infoRow}>
                      <Typography
                        className={classes.profileTitle}
                        variant="bodyXs"
                        color="textLight">
                        {t("profileSettings.phoneNumber")}
                      </Typography>
                      {changePhoneNumber ? (
                        <div>
                          <TextField
                            fullWidth={isMobile}
                            placeholder="Enter company country"
                            value={phoneNumber}
                            className={classes.textInput}
                            onChange={handlePhoneNumber}></TextField>
                          <div className={classes.buttons}>
                            <Button
                              onClick={() => setChangePhoneNumber(false)}
                              variant="textSecondary">
                              {t("profileSettings.cancel")}
                            </Button>
                            <Button
                              onClick={() => updateProfileData("phone")}
                              variant="primary">
                              {t("profileSettings.saveChanges")}
                            </Button>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <Typography
                            className={classes.profileValue}
                            variant="subtitle1"
                            component="div">
                            {phoneNumber}
                          </Typography>
                          <Typography
                            onClick={() => editData("phone")}
                            variant="bodyXs"
                            color="primary"
                            className={classes.changeLink}>
                            {t("profileSettings.edit")}
                          </Typography>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : tabView === "notifications" ? (
            <>
              <img src={ProfileLockImage} alt={t("profileSettings.noData")} />
            </>
          ) : tabView === "billing" && user ? (
            <>
              {billings.invoices ? (
                <div>
                  <div className={classes.billingHeaderBoxes}>
                    <div className={classes.billingHeaderBox}>
                      {billings &&
                        billings.meta &&
                        billings.meta.next_billing_date && (
                          <Typography
                            variant="bodyButton"
                            color="secondaryDark"
                            component="div">
                            {t("profileSettings.nextInvoiceDate")}
                          </Typography>
                        )}
                      <Typography
                        className={classes.nextIssueDate}
                        variant="paragraph"
                        color="secondaryDark"
                        component="div">
                        {billings &&
                          billings.meta &&
                          billings.meta.next_billing_date &&
                          moment(
                            billings &&
                              billings.meta &&
                              billings.meta.next_billing_date,
                          ).format("MMM D, YYYY")}
                      </Typography>
                      {shouldBeBuying && (
                        <>
                          {user.subscription &&
                            user.subscription.plan_type ===
                              "custom_subscription" && (
                              <Typography
                                variant="bodyButton"
                                color="secondaryDark"
                                component="div">
                                {t("profileSettings.expireDate")}:{" "}
                                {moment(user.subscription.expire_date).format(
                                  "MMM D, YYYY",
                                )}
                              </Typography>
                            )}
                          {user &&
                            user.team &&
                            user.team.role &&
                            user.team.role === "owner" &&
                            user.subscription &&
                            user.subscription.name !== "pay as you go" && (
                              <Typography
                                onClick={handleUpgradePlan}
                                className={classnames([
                                  classes.upgradePlan,
                                  classes.mb32,
                                ])}
                                variant="bodyButton"
                                color="primary"
                                component="div">
                                {t("profileSettings.upgrade")}
                              </Typography>
                            )}
                          {user.team.role !== "owner" &&
                            user.subscription.name !== "pay as you go" && (
                              <Typography
                                variant="bodyButton"
                                color="secondaryDark"
                                component="div"
                                className={classes.mb32}>
                                {t("profileSettings.invitedUserUpgradeLabel")}
                              </Typography>
                            )}
                          <Typography
                            onClick={handleGetNewSubscription}
                            className={classes.upgradePlan}
                            variant="bodyButton"
                            color="primary"
                            component="div">
                            {t("profileSettings.getNewSubscription")}
                          </Typography>
                        </>
                      )}
                    </div>
                    {billings &&
                      billings.meta &&
                      billings.meta.next_billing_total && (
                        <div className={classes.billingHeaderBox}>
                          <Typography
                            variant="bodyButton"
                            color="secondaryDark"
                            component="div">
                            {t("profileSettings.invoiceTotal")}
                          </Typography>
                          <Typography
                            className={classes.nextIssueDate}
                            variant="paragraph"
                            color="secondaryDark"
                            component="div">
                            {billings &&
                              billings.meta &&
                              billings.meta.next_billing_total}
                          </Typography>
                          <Typography
                            className={classes.seeCost}
                            variant="bodyButton"
                            color="primary"
                            onClick={() => setShowBreakdownModal(true)}
                            component="div">
                            {t("profileSettings.seeCost")}
                          </Typography>
                        </div>
                      )}
                    <div className={classes.billingHeaderBox}>
                      <Typography
                        variant="bodyButton"
                        color="secondaryDark"
                        component="div">
                        {t("profileSettings.monthlyUsers")}
                      </Typography>
                      <Typography
                        className={classes.nextIssueDate}
                        variant="paragraph"
                        color="secondaryDark"
                        component="div">
                        {seatsAvailable}
                      </Typography>
                    </div>
                  </div>
                  {billings.invoices.length > 0 && (
                    <TableContainer>
                      <Table
                        className={classes.table}
                        aria-label="simple table">
                        <div className={classes.invoicesTitle}>
                          <Typography variant="bodySmall">
                            {t("profileSettings.invoices")}
                          </Typography>
                        </div>
                        <TableHead className={classes.hideOnSmallScreens}>
                          <TableRow>
                            <TableCell align="left">
                              <Typography
                                variant="bodyButton"
                                color="secondaryDark"
                                component="div">
                                {t("profileSettings.date")}
                              </Typography>
                            </TableCell>
                            <TableCell align="left">
                              <Typography
                                variant="bodyButton"
                                color="secondaryDark"
                                component="div">
                                {t("profileSettings.description")}
                              </Typography>
                            </TableCell>
                            {/* <TableCell align="left"><Typography variant="bodyButton" color="secondaryDark" component="div">{t('profileSettings.userSeats')}</Typography></TableCell> */}
                            <TableCell align="left">
                              <Typography
                                variant="bodyButton"
                                color="secondaryDark"
                                component="div">
                                {t("profileSettings.invoiceTotal")}
                              </Typography>
                            </TableCell>
                            <TableCell align="left">
                              <Typography
                                variant="bodyButton"
                                color="secondaryDark"
                                component="div">
                                {t("profileSettings.status")}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {billings.invoices.map(row => (
                            <>
                              <TableRow
                                key={row.id}
                                className={classnames([
                                  classes.tableRow,
                                  classes.regularRow,
                                ])}>
                                <TableCell
                                  align="left"
                                  component="th"
                                  scope="row">
                                  <Typography
                                    variant="bodyButton"
                                    color="secondaryDark"
                                    component="div">
                                    {moment(row.date).format("MMM D, YYYY")}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography
                                    variant="bodyButton"
                                    color="secondaryDark"
                                    component="div">
                                    {row.description}
                                  </Typography>
                                </TableCell>
                                {/* <TableCell align="left">
                                                    <Typography variant="bodyButton" color="secondaryDark" component="div">{row.userSeats}</Typography>
                                                </TableCell> */}
                                <TableCell align="left">
                                  <Typography
                                    variant="bodyButton"
                                    color="secondaryDark"
                                    component="div">
                                    {row.total}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography
                                    variant="bodyButton"
                                    color={
                                      row.status === "failed"
                                        ? "danger"
                                        : row.status === "unpaid"
                                        ? "secondaryDark"
                                        : "success"
                                    }
                                    component="div">
                                    {row.status === "failed" ? (
                                      <div className={classes.statusWrapper}>
                                        <img
                                          className={classes.warningIcon}
                                          src={Warning}
                                          alt={t("profileSettings.warning")}
                                        />
                                        {t(
                                          `profileSettings.status${row.status}`,
                                        )}
                                      </div>
                                    ) : row.status === "unpaid" ? (
                                      <i>
                                        {t(
                                          `profileSettings.status${row.status}`,
                                        )}
                                      </i>
                                    ) : (
                                      t(`profileSettings.status${row.status}`)
                                    )}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                              <TableRow
                                key={row.id}
                                className={classnames([
                                  classes.tableRow,
                                  classes.mobileRow,
                                ])}>
                                <div className={classes.columnTitles}>
                                  <TableCell
                                    align="left"
                                    className={classes.removeBorder}>
                                    <Typography
                                      variant="bodyButton"
                                      color="secondaryDark"
                                      component="div">
                                      {row.description}
                                    </Typography>
                                    <Typography
                                      variant="bodyButton"
                                      color="secondaryDark"
                                      component="div">
                                      {moment(row.date).format("MMM D, YYYY")}
                                    </Typography>
                                  </TableCell>
                                </div>
                                {/* <TableCell align="left">
                                                    <Typography variant="bodyButton" color="secondaryDark" component="div">{row.userSeats}</Typography>
                                                </TableCell> */}
                                <div className={classes.columnTitles}>
                                  <TableCell
                                    align="left"
                                    className={classes.removeBorder}>
                                    <Typography
                                      variant="bodyButton"
                                      color="secondaryDark"
                                      component="div">
                                      {row.total}
                                    </Typography>
                                    <Typography
                                      variant="bodyButton"
                                      color={
                                        row.status === "failed"
                                          ? "danger"
                                          : row.status === "unpaid"
                                          ? "secondaryDark"
                                          : "success"
                                      }
                                      component="div">
                                      {row.status === "failed" ? (
                                        <div className={classes.statusWrapper}>
                                          <img
                                            className={classes.warningIcon}
                                            src={Warning}
                                            alt={t("profileSettings.warning")}
                                          />
                                          {t(
                                            `profileSettings.status${row.status}`,
                                          )}
                                        </div>
                                      ) : row.status === "unpaid" ? (
                                        <i>
                                          {t(
                                            `profileSettings.status${row.status}`,
                                          )}
                                        </i>
                                      ) : (
                                        t(`profileSettings.status${row.status}`)
                                      )}
                                    </Typography>
                                  </TableCell>
                                </div>
                              </TableRow>
                            </>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </div>
              ) : user.subscription && user.subscription.free_trial ? (
                <>
                  <div className={classes.billingHeaderBoxes}>
                    <div className={classes.billingHeaderBox}>
                      {user.subscription.is_active ? (
                        <Typography
                          variant="bodyButton"
                          color="secondaryDark"
                          component="div">
                          {t("profileSettings.youAreOnFreeTrial")}
                        </Typography>
                      ) : (
                        <Typography
                          variant="bodyButton"
                          color="secondaryDark"
                          component="div">
                          {t("profileSettings.yourFreeTrialHasExpired")}
                        </Typography>
                      )}
                      <Typography
                        variant="bodyButton"
                        color="secondaryDark"
                        component="div">
                        {t("profileSettings.expireDate")}:{" "}
                        {user.subscription.free_trial.expire_date_formated}
                      </Typography>

                      <Typography
                        onClick={handleUpgradePlan}
                        className={classes.upgradePlan}
                        variant="bodyButton"
                        color="primary"
                        component="div">
                        {t("profileSettings.upgradeYourPlan")}
                      </Typography>
                    </div>
                    <div className={classes.billingHeaderBox}>
                      <Typography
                        variant="bodyButton"
                        color="secondaryDark"
                        component="div">
                        {t("profileSettings.monthlyUsers")}
                      </Typography>
                      <Typography
                        className={classes.nextIssueDate}
                        variant="paragraph"
                        color="secondaryDark"
                        component="div">
                        {seatsAvailable}
                      </Typography>
                    </div>
                  </div>
                </>
              ) : (
                <div className={classes.noData}>
                  <img
                    src={ProfileLockImage}
                    alt={t("profileSettings.noData")}
                  />
                  <Typography
                    className={classes.noDataLabel}
                    variant="bodyButton"
                    color="secondaryDark">
                    {t("profileSettings.noData")}
                  </Typography>
                </div>
              )}
            </>
          ) : (
            tabView === "team" && (
              <>
                {user &&
                  user.team &&
                  user.teams_other &&
                  user.teams_other.length > 0 && (
                    <div className={classes.teamsButton}>
                      <Typography color="text" variant="body">
                        Managing the team of:
                      </Typography>
                      <Menu
                        onChange={handleMenuClick}
                        btnClassName={classes.teamsMenu}
                        menuItems={menuItems}>
                        <Tooltip
                          title={
                            <Typography
                              color="white"
                              component="div"
                              key={`invisible-tag-`}
                              variant="body2">
                              {t("profileSettings.switchTeam")}
                            </Typography>
                          }
                          arrow>
                          <Typography color="successDark" variant="body">
                            {currentTeamName}
                            <ArrowDownEmpty
                              className={classes.arrowDownBlack}
                            />
                          </Typography>
                        </Tooltip>
                      </Menu>
                    </div>
                  )}
                {user && team && team.length > 0 ? (
                  <TableContainer className={classes.tableContainerRoot}>
                    <Table
                      className={classes.teamTable}
                      aria-label="simple table">
                      <TableHead className={classes.hideOnMobile}>
                        <TableRow>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">
                            <Typography
                              variant="bodyButton"
                              color="secondaryDark"
                              component="div">
                              {t("profileSettings.name")}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography
                              variant="bodyButton"
                              color="secondaryDark"
                              component="div">
                              {t("profileSettings.lastActive")}
                            </Typography>
                          </TableCell>
                          {/* <TableCell align="left"><Typography variant="bodyButton" color="secondaryDark" component="div">{t('profileSettings.teamPermissions')}</Typography></TableCell> */}
                          <TableCell align="left">
                            <Typography
                              variant="bodyButton"
                              color="secondaryDark"
                              component="div">
                              {t("profileSettings.role")}
                            </Typography>
                          </TableCell>
                          <TableCell align="left"></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {team &&
                          team.map(row => (
                            <TableRow key={row.id} className={classes.tableRow}>
                              <TableCell
                                align="left"
                                component="th"
                                scope="row"
                                className={classes.cellAvatarWrap}>
                                <div className={classes.teamUserAvatar}>
                                  <img src={NoAvatarUser} alt={row.name} />
                                </div>
                              </TableCell>
                              <TableCell align="left">
                                <Typography
                                  variant="bodyButton"
                                  color="secondaryDark"
                                  component="div">
                                  {row.name}
                                </Typography>
                                <Typography
                                  className={classes.email}
                                  variant="bodyButton"
                                  color="textLight"
                                  component="div">
                                  {row.email}
                                </Typography>
                              </TableCell>
                              <TableCell
                                align="left"
                                className={classes.hideOnMobile}>
                                <Typography
                                  variant="bodyButton"
                                  color="secondaryDark"
                                  component="div">
                                  {!!row.last_active
                                    ? moment(row.last_active).fromNow()
                                    : t("profileSettings.na")}
                                </Typography>
                              </TableCell>
                              {/* <TableCell align="left">
                                                    <Typography variant="bodyButton" color="secondaryDark" component="div">{row.permission}</Typography>
                                                </TableCell> */}
                              <TableCell
                                align="left"
                                className={classes.hideOnMobile}>
                                <Typography
                                  variant="bodyButton"
                                  color="secondaryDark"
                                  component="div">
                                  {row.role}
                                </Typography>
                              </TableCell>
                              <TableCell
                                align="left"
                                className={classes.cellMoreWrap}>
                                {row.email === user.email ? (
                                  <Typography
                                    color="danger"
                                    variant="bodyXs"
                                    className={classes.leaveTeamLink}
                                    onClick={() =>
                                      setConfirmLeaveTheTeamModalShow(true)
                                    }>
                                    {t("profileSettings.leaveThisTeam")}
                                  </Typography>
                                ) : (
                                  row.email !== (user && user.email) &&
                                  user &&
                                  !user.is_invited && (
                                    <MoreComponent
                                      t={t}
                                      classes={classes}
                                      row={row}
                                    />
                                  )
                                )}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <div className={classes.noData}>
                    <img
                      src={ProfileLockImage}
                      alt={t("profileSettings.noData")}
                    />
                    <Typography
                      className={classes.noDataLabel}
                      variant="bodyButton"
                      color="secondaryDark">
                      {t("profileSettings.noData")}
                    </Typography>
                  </div>
                )}
              </>
            )
          )}
        </div>
        <CostBreakdownModal
          billings={billings}
          dialogOpened={showBreakdownModal}
          user={user}
          onChange={() =>
            setShowBreakdownModal(!showBreakdownModal)
          }></CostBreakdownModal>
        <ConfirmLeaveTheTeamModal
          user={user}
          onClose={() => setConfirmLeaveTheTeamModalShow(false)}
          onConfirm={handleLeaveTheTeamConfirm}
          dialogOpened={confirmLeaveTheTeamModalShow}
        />
        <OnboardingFreeTrialModal
          user={user}
          dialogOpened={showOnboardingTrialModal}
          onClose={handleClosingOnboardingFreeTrialModal}
          onInvite={openInviteUserModal}
        />
      </div>
    </Layout>
  );
};
export default ProfileSettings;
