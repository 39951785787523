import React, { useEffect, useLayoutEffect, useState } from "react";
// Hooks
import { useTranslation } from "react-i18next";
import { useStyles } from "./style.js";
import { useRouterHook } from "../../common/useRouterHook.js";
import { useAuthHook } from "../../common/useAuthHook";
//Components
import Header from "./Components/Header/Header";
import Pagination from "../../components/Pagination/Pagination";
import Card from "../../components/Card/Card";
import ContentLoader from "../../components/ContentLoader/ContentLoader";
import AddNewModal from "../../components/AddNewModal/AddNewModal";
import NoResults from "../Results/components/NoResults/NoResults.js";
//Redux
import {
  fetchTechnologiesRequest,
  suggestTechnologyRequest,
} from "../../store/technologies/requests.js";
import {
  getAllTechnologiesStateSelector,
  getAllTechnologiesStatusSelector,
  getAllTechnologiesTotalCountSelector,
  getAllTechnologiesTotalPagesSelector,
  getSuggestTechnologyStatusSelector,
} from "../../store/technologies/selectors.js";
import { actions as technologiesAction } from "../../store/technologies/slice";
import { mixpanelTrackRequest } from "../../store/mixpanel/requests";
// Mock
import { useDispatch, useSelector } from "react-redux";
import {
  REQUEST_FAILURE,
  REQUEST_PENDING,
  REQUEST_SUCCESS,
} from "../../constants/statuses.js";
//Shared
import { Shared } from "../../utils/shared.js";

const Technologies = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { openPage, getUrlParam } = useRouterHook();
  const dispatch = useDispatch();
  const [showAddNewModal, setShowAddNewModal] = useState(false);
  const [succeeded, setSucceeded] = useState(false);
  const { isAuthenticated } = useAuthHook();
  const { scrollTo } = Shared({});

  const queryParams = new URLSearchParams(window.location.search);
  const pageParam = queryParams.get("page");
  let pageNumber = JSON.parse(sessionStorage.getItem("technologiesPageNumber"));
  const [page, setPage] = useState(pageParam || pageNumber || 1);

  //Selectors
  const getAllTechnologiesStatus = useSelector(
    getAllTechnologiesStatusSelector,
  );
  const getAllTechnologiesState = useSelector(getAllTechnologiesStateSelector);
  const getAllTechnologiesTotalPages = useSelector(
    getAllTechnologiesTotalPagesSelector,
  );
  const getAllTechnologiesTotalCount = useSelector(
    getAllTechnologiesTotalCountSelector,
  );
  const getSuggestTechnologyStatus = useSelector(
    getSuggestTechnologyStatusSelector,
  );
  //State
  const [technologies, setAllTechnologies] = useState(getAllTechnologiesState);

  useEffect(() => {
    let searchValue = sessionStorage.getItem("technologies-FilterSearch");
    dispatch(
      fetchTechnologiesRequest(
        searchValue ? { page, search: searchValue } : { page },
      ),
    );
    dispatch(mixpanelTrackRequest({ eventName: "Technologies page" }));
    dispatch(technologiesAction.clearCompaniesResults());
  }, [dispatch]);

  useEffect(() => {
    setAllTechnologies(getAllTechnologiesState);
  }, [getAllTechnologiesState]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const moreData = newPage => {
    let searchValue = sessionStorage.getItem("technologies-FilterSearch");
    dispatch(fetchTechnologiesRequest({ page: newPage, search: searchValue }));
    setPage(newPage);
  };

  useEffect(() => {
    getUrlParam("page") &&
      getUrlParam("page").value !== page &&
      openPage(`${window.location.pathname}?page=${page}`);
  }, [page]);

  useLayoutEffect(() => {
    let scrollPosition = sessionStorage.getItem("technologiesScrollPosition");
    setTimeout(() => {
      scrollPosition &&
        technologies.length &&
        scrollTo(Number(scrollPosition), () =>
          sessionStorage.removeItem("technologiesScrollPosition"),
        );
    }, 100);
  }, [technologies]);

  const handleActionClick = (titleValue, description) => {
    dispatch(
      suggestTechnologyRequest({
        data: {
          "technology-name": titleValue,
          "technology-description": description,
        },
      }),
    );
  };

  useEffect(() => {
    getSuggestTechnologyStatus === REQUEST_SUCCESS
      ? setSucceeded(true)
      : setSucceeded(false);
  }, [getSuggestTechnologyStatus]);

  const handleSuggestTechnologyModalChange = () => {
    setShowAddNewModal(false);
    setSucceeded(false);
  };

  return (
    <div className={classes.root}>
      <Header
        totalCount={getAllTechnologiesTotalCount}
        page={page}
        totalPages={getAllTechnologiesTotalPages}
        onSuggestTechnologyClick={() => setShowAddNewModal(true)}
        setPage={setPage}
      />
      {getAllTechnologiesStatus === REQUEST_SUCCESS ||
      getAllTechnologiesStatus === REQUEST_FAILURE ? (
        <>
          <div className={classes.technologiesCards}>
            {technologies && technologies.length > 0 ? (
              technologies.map((technology, i) => {
                return (
                  <Card
                    key={technology.id}
                    index={i}
                    result={technology}
                    isTechnology={true}
                    isIndustryProfile={false}
                    cardIndex={i}
                    openPage={openPage}
                    t={t}
                    isAuthenticated={isAuthenticated}
                    source="technology"
                    type="technology"
                  />
                );
              })
            ) : (
              <NoResults showButton={false} />
            )}

            {/* this might come back */}
            {/* <div className={classes.suggestCard}>
                                <Typography variant="subtitle1" color="secondaryDark">{t("technologies.cantFind")}</Typography>
                                <Typography className={classes.mt24} variant="body" color="textMain">{t("technologies.youCanSuggest")}</Typography>
                                <Button className={classes.suggestButton} variant="tertiary">{t("technologies.suggestTechnology")}</Button>
                            </div> */}
          </div>
          {getAllTechnologiesTotalPages > 1 && (
            <Pagination
              source="technologies"
              count={getAllTechnologiesTotalPages}
              onChangePage={moreData}
              page={Number(page)}
            />
          )}
        </>
      ) : (
        <ContentLoader />
      )}

      <AddNewModal
        dialogOpened={showAddNewModal}
        onChange={handleSuggestTechnologyModalChange}
        title={t("technologies.suggestTechnologyTitle")}
        titleInputLabel={t("technologies.technologyName")}
        descriptionInputLabel={t("technologies.technologyDescription")}
        actionButtonText={t("technologies.send")}
        cancelButtonText={t("technologies.cancel")}
        onActionClick={(titleValue, description) =>
          handleActionClick(titleValue, description)
        }
        succeeded={succeeded}
        requestPending={getSuggestTechnologyStatus === REQUEST_PENDING}
      />
    </div>
  );
};
export default Technologies;
