import { createStyles, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(
  ({ palette, spacing, shape, typography, breakpoint }) =>
    createStyles({
      editSearch: {
        backgroundColor: palette.secondary.backgroundLight,
        padding: `${spacing(1)}px ${spacing(2)}px ${spacing(1)}px ${spacing(
          2,
        )}px`,
        marginLeft: spacing(3),
        borderTopLeftRadius: "12px",
        borderBottomLeftRadius: "12px",
        marginBottom: spacing(1),
        // height: "20%",
        // minHeight: "100%",
      },
      withoutPuma: {
        marginRight: spacing(3),
        borderTopRightRadius: "12px",
        borderBottomRightRadius: "12px",
      },
      header: {
        display: "flex",
        justifyContent: "center",
        borderBottom: `1px solid ${palette.secondary.backgroundDark}`,
      },
      imgWrapper: {
        position: "relative",
      },
      pumaLogo: {
        position: "absolute",
        bottom: "-26px",
        left: "-40px",
        height: "90px",
      },
      headerTitle: {
        fontSize: "18px",
        alignSelf: "center",
        fontWeight: 300,
        color: palette.secondary.main,
        "&:b": {
          fontWeight: 700,
        },
      },
      description: {
        marginTop: spacing(2),
        marginBottom: spacing(1),
      },
      textareaField: {
        fontFamily: "GT Flexa",
        fontSize: "14px",
        fontWeight: 300,
        width: "100%",
        borderRadius: "8px",
        borderColor: palette.secondary.backgroundDark,
        paddingLeft: "8px",
        paddingTop: "5px",
        "&::placeholder": {
          color: palette.secondary.light,
        },
        "&:focus": {
          outline: "none !important",
          borderColor: palette.text.main,
        },
      },
      options: {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        // marginBottom: spacing(3),
        justifyContent: "space-between",
      },
      optionsLeft: {
        display: "flex",
      },
      optionsRight: {
        display: "flex",
        flexWrap: "wrap",
      },
      filter: {
        display: "flex",
        cursor: "pointer",
        marginRight: "12px",
      },
      plusIconWrapper: {},
      plusIcon: {
        height: 20,
        width: 20,
        color: palette.text.lighter,
      },
      plusIconActive: {
        color: palette.primary.dark,
      },
      filterTitle: {
        marginLeft: "12px",
        fontSize: "18px",
        fontWeight: "700",
        color: palette.text.main,
      },
      filterOptions: {
        overflow: "hidden",
      },
      filterOptionsOverflow: {
        overflow: "unset",
      },
      expandSection: {
        height: 0,
        transition: "height 300ms ease",
      },
      expanded: {
        marginTop: 20,
        height: "auto",
      },
      selectedFilters: {
        color: palette.text.main,
        fontSize: "12px",
        fontWeight: 300,
        alignSelf: "center",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        marginRight: "12px",
      },
      saveWorkspace: {
        marginRight: spacing(1),
      },
      saveSearchBtn: {
        backgroundColor: "unset",
        border: `1px solid ${palette.text.light}`,
        borderRadius: "38px",
        color: palette.text.light,
        fontSize: "16px",
        fontWeight: 500,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        padding: `3px 12px`,
        "&:hover": {
          backgroundColor: palette.primary.dark,
          borderColor: palette.primary.dark,
          color: palette.common.white,
        },
        "& span": {
          fontSize: "16px",
          fontFamily: "GT Flexa",
        },
        "&:hover span": {
          color: palette.common.white,
        },
      },
      updateSearchBtn: {
        backgroundColor: palette.text.backgroundDark,
        borderColor: palette.text.backgroundDark,
        color: palette.common.white,
        fontFamily: "GT Flexa",
        fontSize: "16px",
        fontWeight: 500,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        padding: `${spacing(1)}px 12px`,
        cursor: "unset",
        "&:hover": {
          backgroundColor: palette.text.backgroundDark,
          borderColor: palette.text.backgroundDark + "!important",
        },
      },
      updateSearchBtnActive: {
        backgroundColor: palette.primary.dark,
        borderColor: palette.primary.dark,
        cursor: "pointer",
        "&:hover": {
          backgroundColor: palette.secondary.dark,
          color: palette.common.white,
        },
      },
      filterOptionsRow: {
        display: "flex",
        gap: spacing(2),
        marginBottom: spacing(2),
      },
    }),
);
