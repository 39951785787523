import React, { useRef, useState } from "react";
import { useFrame } from "@react-three/fiber";
import {
  Bounds,
  Html,
  Line,
  OrbitControls,
  useBounds,
} from "@react-three/drei";
import "./style.css";

const Cluster = ({ coordinates, toggleRotation, handleClick, openModal }) => {
  const groupRef = useRef();
  const dotRef = useRef();
  const controls = useRef(null);
  const [hovered, setHovered] = useState(false);
  const [selected, setSelected] = useState(false);

  useFrame(() => {
    if (dotRef.current && toggleRotation) {
      if (dotRef.current.rotation) {
        dotRef.current.rotation.y += 0.0003;
      } else {
        groupRef.current.rotation.y += 0.0003;
      }
    }
  });

  if (coordinates) {
    return (
      <>
        <group ref={groupRef} scale={[75, 75, 75]}>
          <>
            <Line points={[0, 0, 0, 3, 0, 0]} color="red" />
            <Line points={[0, 0, 0, 0, 3, 0]} color="green" />
            <Line points={[0, 0, 0, 0, 0, 3]} color="blue" />
            <Bounds margin={20} damping={6}>
              <SelectToZoom handleClick={handleClick}>
                {coordinates.map(({ x, y, z, color, company }, index) => {
                  const obj = {
                    company,
                    x: x / 2,
                    y: y * 2,
                    z: z * 1.5,
                  };
                  const isHovered =
                    hovered && hovered.company.id === company.id;
                  const isSelected =
                    selected && selected.company.id === company.id;

                  return (
                    <group
                      ref={dotRef}
                      class="tooltip"
                      stopPropagation={true}
                      onPointerEnter={() => {
                        setHovered(obj);
                      }}
                      onPointerLeave={() => setHovered(false)}
                      onDoubleClick={e => {}}
                      key={index}
                      position={[x / 4, y * 1.8, z * 1.2]}
                      onClick={() => {
                        setSelected(obj);
                      }}>
                      <mesh stopPropagation={true}>
                        <sphereGeometry
                          stopPropagation={true}
                          args={[
                            isHovered || isSelected ? 0.01 : 0.005,
                            100,
                            100,
                          ]}
                        />

                        {(isHovered || isSelected) && (
                          <Html
                            style={{
                              width: "140px",
                              minWidth: "100%",
                              position: "absolute",
                              top: "-100px",
                              right: "-190px",
                            }}
                            zIndexRange={[isHovered ? 16777271 : 150000, 0]}
                            // distanceFactor={100}
                            stopPropagation={false}>
                            <div class={`tooltiptext`}>
                              <span
                                class="close"
                                onClick={() => setSelected(false)}>
                                <b>X</b>
                              </span>
                              Match:
                              {Math.round(company?.match, 4)}
                              % <br />
                              <b
                                style={{
                                  wordBreak: "break-all",
                                }}>
                                {company?.name}
                              </b>
                              <br />
                              {company?.sectors[0]?.name || "Other"}
                              {isSelected && (
                                <div
                                  class={`preview`}
                                  onClick={() => {
                                    setSelected(false);
                                    openModal({
                                      isOpen: true,
                                      company,
                                    });
                                  }}>
                                  Preview
                                </div>
                              )}
                            </div>
                            <span class="arrow" />
                          </Html>
                        )}
                        <meshStandardMaterial
                          topPropagation={true}
                          lightMapIntensity={50}
                          color={isHovered || isSelected ? "black" : color}
                        />
                      </mesh>
                    </group>
                  );
                })}
              </SelectToZoom>
              <OrbitControls
                dampingFactor={1}
                ref={controls}
                zoomSpeed={1.2}
                makeDefault
                minPolarAngle={0}
                maxDistance={400}
                autoRotate={toggleRotation}
                autoRotateSpeed={0.5}
                // maxPolarAngle={Math.PI / 1.75} hm
              />
            </Bounds>
          </>
        </group>
      </>
    );
  }
};

function SelectToZoom({ children, handleClick }) {
  const api = useBounds();

  return (
    <group
      onClick={e => (
        e.stopPropagation(),
        e.delta <= 2 && api.refresh(e.object).fit() && handleClick(false)
      )}
      onPointerMissed={e =>
        e.button === 0 && api.refresh().fit() && handleClick(false)
      }>
      {children}
    </group>
  );
}

export default Cluster;
