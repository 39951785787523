import { call, put } from "redux-saga/effects";
import { actions } from "../slice";
import {
  getAllTechnologies,
  getFilterTechnologyCompanies,
  getTechnologiesCompaniesPage,
  getTechnology,
  getTechnologyCompany,
  postEnrichTechnology,
  postEnrichTechnologyCompany,
  postSuggestTechnology,
} from "./helpers";
import {
  getCompaniesChart,
  getTeamSizeChart,
} from "../../results/saga/helpers";

function* fetchAllTechnologies(page, search) {
  try {
    yield put(actions.fetchAllTechnologiesPending());
    const response = yield call(getAllTechnologies, page, search);
    yield put(actions.setAllTechnologies(response.data));
    yield put(actions.fetchAllTechnologiesSuccess());
  } catch (err) {
    yield put(actions.fetchAllTechnologiesFailure());
  }
}

function* fetchTechnology(slug) {
  try {
    yield put(actions.fetchTechnologyPending());
    const response = yield call(getTechnology, slug);
    yield put(actions.setTechnology(response.data));
    yield put(actions.fetchTechnologySuccess());
  } catch (err) {
    yield put(actions.fetchTechnologyFailure());
  }
}

function* fetchFilterTechnologyCompanies(slug, data, clearResults, isGuest) {
  try {
    if (clearResults) yield put(actions.clearCompaniesResults());
    yield put(actions.fetchTechnologyPending());
    const response = yield call(getFilterTechnologyCompanies, slug, data);
    isGuest
      ? yield put(actions.setIsGuest(true))
      : yield put(actions.setIsGuest(false));
    yield put(actions.setTechnologyResults(response.data));
    yield put(actions.fetchTechnologySuccess());
  } catch (err) {
    yield put(actions.fetchTechnologyFailure());
  }
}

function* fetchFilterTechnologyCompaniesCount(
  slug,
  data,
  clearResults,
  isGuest,
  lastFilteredField,
  lastFilteredFieldValue,
) {
  try {
    yield put(actions.fetchTechnologyCompaniesCountPending());
    const response = yield call(getFilterTechnologyCompanies, slug, data);
    isGuest
      ? yield put(actions.setIsGuest(true))
      : yield put(actions.setIsGuest(false));
    yield put(
      actions.setTechnologyCompaniesCount({
        sliceData: response.data,
        lastFilteredField,
        lastFilteredFieldValue,
      }),
    );
    yield put(actions.setLastFilteredField(lastFilteredField));
    yield put(actions.fetchTechnologyCompaniesCountSuccess());
  } catch (err) {
    yield put(actions.fetchTechnologyCompaniesCountFailure());
  }
}

function* fetchTechnologyCompaniesPage(slug, data) {
  try {
    yield put(actions.clearCompaniesResults());
    yield put(actions.getResultsPaginationPending());
    const searchResults = yield call(getTechnologiesCompaniesPage, slug, data);
    yield put(actions.getResultsPaginationSuccess());
    const sliceData = { data: searchResults.data, page: data.page };
    yield put(actions.setResults(sliceData));
  } catch (err) {
    yield put(actions.getResultsPaginationFailure());
  }
}

function* fetchTechnologyCompany(slug, cId) {
  try {
    yield put(actions.getTechnologyCompanyPending());
    const response = yield call(getTechnologyCompany, slug, cId);
    yield put(actions.setCompany(response.data));
    yield put(actions.getTechnologyCompanySuccess());
  } catch (err) {
    yield put(actions.getTechnologyCompanyFailure());
  }
}

function* fetchEnrichTechnologyCompany(slug, projectSlug, cId) {
  try {
    yield put(actions.getEnrichTechnologyCompanyPending());
    yield call(postEnrichTechnologyCompany, slug, projectSlug, cId);
    yield put(actions.getEnrichTechnologyCompanySuccess());
  } catch (err) {
    yield put(actions.getEnrichTechnologyCompanyFailure());
  }
}

function* fetchEnrichTechnology(slug) {
  try {
    yield put(actions.enrichTechnologyPending());
    yield call(postEnrichTechnology, slug);
    yield put(actions.enrichTechnologySuccess());
  } catch (err) {
    yield put(actions.eenrichTechnologyFailure());
  }
}

function* fetchTechnologyCompaniesChart(id, type, grouped, filterNameValue) {
  try {
    yield put(actions.getTechnologyCompaniesChartPending());
    const response = yield call(
      getCompaniesChart,
      id,
      type,
      grouped,
      filterNameValue,
    );
    yield put(actions.getTechnologyCompaniesChartSuccess());
    yield put(actions.setTechnologyCompaniesChart(response.data));
  } catch (err) {
    yield put(actions.getTechnologyCompaniesChartFailure());
  }
}

function* fetchTechnologyCompaniesTeamSizeChart(
  id,
  type,
  grouped,
  filterNameValue,
) {
  try {
    yield put(actions.getTechnologyCompaniesTeamSizeChartPending());
    const response = yield call(getTeamSizeChart, id, type, filterNameValue);
    yield put(actions.getTechnologyCompaniesTeamSizeChartSuccess());
    yield put(actions.setTechnologyCompaniesTeamSizeChart(response));
  } catch (err) {
    yield put(actions.getTechnologyCompaniesTeamSizeChartFailure());
  }
}

function* suggestTechnology(data) {
  try {
    yield put(actions.suggestTechnologyPending());
    yield call(postSuggestTechnology, data);
    yield put(actions.suggestTechnologySuccess());
  } catch (err) {
    yield put(actions.suggestTechnologyFailure());
  }
}

export {
  fetchAllTechnologies,
  fetchTechnology,
  fetchFilterTechnologyCompanies,
  fetchTechnologyCompaniesPage,
  fetchTechnologyCompany,
  fetchEnrichTechnologyCompany,
  fetchEnrichTechnology,
  fetchFilterTechnologyCompaniesCount,
  fetchTechnologyCompaniesChart,
  fetchTechnologyCompaniesTeamSizeChart,
  suggestTechnology,
};
