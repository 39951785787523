import { createStyles, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(({ palette, spacing, breakpoint }) =>
  createStyles({
    likedCompaniesPageWrapper: {
      maxWidth: 480,
      flex: 2,
      margin: "0 auto",
      [breakpoint.down("xl")]: {
        margin: `${spacing(2)}px 0 0 0`,
        maxWidth: "none",
        width: "100%",
      },
      "& $dashboardCardBigPage": {
        padding: spacing(4),
        minHeight: 0,
      },
    },
    root: {
      padding: spacing(4),
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
      justifyContent: "space-between",
      [breakpoint.down("xl")]: {
        flexDirection: "column",
        padding: spacing(3),
      },
    },
    goBack: {
      display: "inline-flex",
      alignItems: "center",
      flex: 1,
      "& img": {
        marginRight: spacing(1),
      },
      cursor: "pointer",
    },
    flexOne: {
      flex: 1,
      [breakpoint.down("xl")]: {
        flex: 0,
      },
    },
  }),
);
