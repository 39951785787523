import React, { useEffect, useState } from "react";
// Hooks
import { useDispatch, useSelector } from "react-redux";
import { useLockedPageHook } from "../Company/LockedPage/hooks/useLockedPageHook.js";
import { useLocation } from "react-router";
// UI components
import CompanyProfile from "../Company/CompanyProfile.js";
//Redux
import { fetchDeckClientPreviewRequest } from "../../store/companyProfile/requests";
import { getDeckClientPreviewSelector } from "../../store/companyProfile/selectors";
import { getMeRequest } from "../../store/auth/requests";

const ContentDeckClientView = () => {
  const dispatch = useDispatch();
  const companyResultSelector = useSelector(getDeckClientPreviewSelector);
  const [companyResult, setCompanyResult] = useState(companyResultSelector);
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const openTab = query.get("openTab");
  const { pathname } = useLockedPageHook({
    company: companyResult,
    type: "company",
  });

  useEffect(() => {
    dispatch(getMeRequest());
  }, []);
  useEffect(() => {
    setCompanyResult(companyResultSelector);
  }, [companyResultSelector]);

  useEffect(() => {
    window.scrollTo(0, 0);
    pathname &&
      dispatch(fetchDeckClientPreviewRequest({ slug: pathname.split("/")[2] }));
  }, [pathname]);

  useEffect(() => {
    if (openTab) {
      let cmp = localStorage.getItem("companyInfo");
      cmp = cmp && JSON.parse(cmp);
      cmp && setCompanyResult(cmp);
    }
  }, [openTab]);

  return <CompanyProfile source="deckClientPreviewCompany" isPreview={true} />;
};
export default ContentDeckClientView;
