import { call } from "redux-saga/effects";
import axios from "axios";
import { MIXPANEL_TRACK_URL } from "../../../constants/endpoints";
import { mixpanelUtils } from "../utils";

function* postTrackUser(eventName, data) {
  if (process.env.REACT_APP_ENV !== "local") {
    let _data = mixpanelUtils().properties();
    Object.assign(_data, data);
    const response = yield call(axios, {
      method: "POST",
      url: `${MIXPANEL_TRACK_URL}`,
      data: {
        event: eventName,
        data: _data,
      },
      withCredentials: true,
    });
    return response;
  }
  return null;
}

export { postTrackUser };
