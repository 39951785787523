import { all, takeEvery } from "redux-saga/effects";
import {
  fetchActivitiesRequest,
  fetchDashboardRequest,
  markActivitySeenRequest,
} from "../requests";
import { fetchActivities, fetchDashboard, markActivitySeen } from "./workers";

const convertTypeFromAction = actionCreator => actionCreator.toString();

function* watchFetchDashboard() {
  yield takeEvery(convertTypeFromAction(fetchDashboardRequest), fetchDashboard);
}

function* watchMarkActivitySeen() {
  yield takeEvery(
    convertTypeFromAction(markActivitySeenRequest),
    ({ payload: { id } }) => markActivitySeen(id),
  );
}

function* watchGetActivities() {
  yield takeEvery(
    convertTypeFromAction(fetchActivitiesRequest),
    fetchActivities,
  );
}

function* saga() {
  yield all([
    watchFetchDashboard(),
    watchMarkActivitySeen(),
    watchGetActivities(),
  ]);
}

export { saga };
