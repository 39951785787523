import { createAction } from "@reduxjs/toolkit";
import { REDUCER_PREFIX } from "./slice";

export const fetchIndustriesRequest = createAction(
  `${REDUCER_PREFIX}/fetchIndustriesRequest`,
);

export const fetchIndustryRequest = createAction(
  `${REDUCER_PREFIX}/fetchIndustryRequest`,
);

export const fetchIndustryCompanyRequest = createAction(
  `${REDUCER_PREFIX}/fetchIndustryCompanyRequest`,
);
export const fetchIndustryCompaniesRequest = createAction(
  `${REDUCER_PREFIX}/fetchIndustryCompaniesRequest`,
);
export const fetchIndustryCompaniesCountRequest = createAction(
  `${REDUCER_PREFIX}/fetchIndustryCompaniesCountRequest`,
);
export const fetchIndustryCompaniesChartRequest = createAction(
  `${REDUCER_PREFIX}/fetchIndustryCompaniesChartRequest`,
);
export const fetchIndustryCompaniesTeamSizeChartRequest = createAction(
  `${REDUCER_PREFIX}/fetchIndustryCompaniesTeamSizeChartRequest`,
);
export const enrichIndustryRequest = createAction(
  `${REDUCER_PREFIX}/enrichIndustryRequest`,
);
