import React from "react";
import { useStyles } from "../NewSearch/style.js";
import { Box, Tooltip, Typography } from "@material-ui/core";
import { Shovel } from "../../../assets/icons/Shovel.js";
import { Ruler } from "../../../assets/icons/Ruler.js";
import { Hammer } from "../../../assets/icons/Hammer.js";
import { ViceGrip } from "../../../assets/icons/ViceGrip.js";
import { SearchText } from "../../../assets/icons/SearchText.js";
import { Done } from "../../../assets/icons/Done.js";
import { ReactComponent as Next } from "../../../assets/icons/Next.svg";

const StepperComponent = ({
  activeStep,
  savedUserInputs,
  onClick,
  stepChanged,
}) => {
  const classes = useStyles();

  const steps = [
    {
      icon: <Ruler />,
      activeIcon: <Ruler strokeColor="#0B295F" />,
      text: "Role",
    },
    {
      icon: <Shovel />,
      activeIcon: <Shovel strokeColor="#0B295F" />,
      text: "Landscape",
    },
    {
      icon: <Hammer />,
      activeIcon: <Hammer strokeColor="#0B295F" />,
      text: "Challenges",
    },
    {
      icon: <ViceGrip />,
      activeIcon: <ViceGrip strokeColor="#0B295F" />,
      text: "Needs",
    },
    {
      icon: <SearchText />,
      activeIcon: <SearchText strokeColor="#0B295F" />,
      text: "Use cases",
    },
    {
      icon: <Done />,
      activeIcon: <Done strokeColor="#0B295F" />,
      text: "Scope generation",
    },
  ];

  return (
    <Box display="flex" alignItems="center" style={{ cursor: "pointer" }}>
      {steps.map((step, index) => (
        <React.Fragment key={index}>
          {savedUserInputs[index] ? (
            <Tooltip
              title={
                <Typography variant="body2" className={classes.infoTooltipText}>
                  {savedUserInputs[index]}
                </Typography>
              }>
              {RenderStepperBox(index, step)}
            </Tooltip>
          ) : (
            RenderStepperBox(index, step)
          )}
          {index < steps.length - 1 && <Next />}
        </React.Fragment>
      ))}
    </Box>
  );

  function RenderStepperBox(index, step) {
    return (
      <Box
        onClick={() => onClick(index)}
        display="flex"
        flexDirection="row"
        alignItems="center"
        style={index === 5 && stepChanged ? { border: "1px solid red" } : {}}
        className={
          index === activeStep ? classes.stepperBoxActive : classes.stepperBox
        }>
        <Typography
          style={{ fontWeight: "700" }}
          className={
            index === activeStep
              ? classes.scopeGuidanceStepperNumberActive
              : classes.scopeGuidanceStepperNumber
          }>
          {index + 1}
        </Typography>
        <div className={classes.scopeGuidanceIconBox}>
          {index === activeStep ? step.activeIcon : step.icon}
        </div>
        {index === activeStep && (
          <Typography style={{ marginRight: "5px", fontWeight: "700" }}>
            {step.text}
          </Typography>
        )}
        {index === 5 && stepChanged && (
          <div className={classes.changeExplanationMark}>!</div>
        )}
      </Box>
    );
  }
};

export default StepperComponent;
