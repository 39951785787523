const getOrdersStatusSelector = state => state.orders.getOrdersStatus;
const getOrdersCompaniesSelector = state => state.orders.companies;
const getOrdersTechnologiesSelector = state => state.orders.technologies;
const getExportCsvStatusSelector = state => state.orders.exportCsvStatus;

export {
  getOrdersStatusSelector,
  getOrdersCompaniesSelector,
  getOrdersTechnologiesSelector,
  getExportCsvStatusSelector,
};
