import { call } from "redux-saga/effects";
import axios from "axios";
import {
  COMPANIES_CHART_URL,
  COMPANY_GENERATE_LINK_URL,
  ENRICHED_EXAMPLE_COMPANY_URL,
  getCompanyUrl,
  LIKED_COMPANIES_URL,
  RESULTS_URL,
  SEARCHES_URL,
  SHARE_LINK_DATA_URL,
  SHARE_LINK_URL,
  TEAM_SIZE_CHART_URL,
  VOTE_ML_RESULT_URL,
} from "../../../constants/endpoints";

function* postResults(data) {
  const response = yield call(axios, {
    method: "POST",
    url: `${RESULTS_URL}`,
    data,
    withCredentials: true,
  });
  return response.data;
}

function* getResults(
  filterNameValue,
  orderBy,
  orderDirection,
  page,
  sid,
  isSharedPageToken,
  perPage,
  searchTypeLook,
  filterStats,
) {
  let url;
  if (isSharedPageToken) {
    url = `${SHARE_LINK_DATA_URL}` + isSharedPageToken;
    url = `${url}?${filterNameValue}`;
  } else {
    url = `${RESULTS_URL}${filterNameValue}`;
  }

  url = searchTypeLook ? url + "&search-type=company-look-a-likes" : url;
  url = filterStats ? url + "&filterStats=true" : url;
  const response = yield call(axios, {
    method: "GET",
    url: url,
    withCredentials: true,
  });

  return response.data;
}

function* getCompaniesChart(id, type, grouped, filterNameValue) {
  let typeParam;
  switch (type) {
    case "results":
      typeParam = `sid=${id}`;
      break;
    case "technologies":
      typeParam = `tid=${id}`;
      break;
    case "industries":
      typeParam = `iid=${id}`;
      break;
    default:
      typeParam = `sid=${id}`;
  }
  let url = !!grouped
    ? `${COMPANIES_CHART_URL}?${typeParam}&grouped=true${
        filterNameValue ? `&${filterNameValue}` : ""
      }`
    : `${COMPANIES_CHART_URL}?${typeParam}${
        filterNameValue ? `&${filterNameValue}` : ""
      }`;
  const response = yield call(axios, {
    method: "GET",
    url,
    withCredentials: true,
  });
  return response.data;
}

function* getTeamSizeChart(id, type, filterNameValue) {
  let typeParam;
  switch (type) {
    case "results":
      typeParam = `sid=${id}`;
      break;
    case "technologies":
      typeParam = `tid=${id}`;
      break;
    case "industries":
      typeParam = `iid=${id}`;
      break;
    default:
      typeParam = `sid=${id}`;
  }
  let url = `${TEAM_SIZE_CHART_URL}?${typeParam}${
    filterNameValue ? `&${filterNameValue}` : ""
  }`;
  const response = yield call(axios, {
    method: "GET",
    url,
    withCredentials: true,
  });
  return response.data.data;
}

function* getSearches(data) {
  const { page, orderBy, orderDirection, searchValue, savedSearches, sid } =
    data;
  let url = "";
  if (data && Object.values(data).length === 0) {
    url = `${SEARCHES_URL}`;
  } else {
    url = `${SEARCHES_URL}?sort-direction=${orderDirection}&sort-by=${orderBy}&page=${page}${
      searchValue ? "&search=" + searchValue : ""
    }${savedSearches ? "&ss=true" : ""}${sid ? "&sid=" + sid : ""}`;
  }
  const response = yield call(axios, {
    method: "GET",
    url: url,
    withCredentials: true,
  });
  return response.data;
}

function* postSearches(data) {
  const response = yield call(axios, {
    method: "POST",
    url: SEARCHES_URL,
    withCredentials: true,
    data,
  });
  return response.data;
}

function* getCompany(data) {
  const { slug, cid } = data;
  let url;
  if (!slug) {
    url = ENRICHED_EXAMPLE_COMPANY_URL;
  } else {
    url = cid ? `${getCompanyUrl(slug)}?cid=${cid}` : `${getCompanyUrl(slug)}`;
  }
  const response = yield call(axios, {
    method: "GET",
    url: url,
    withCredentials: true,
  });
  return response.data;
}

function* generateLink(data) {
  const response = yield call(axios, {
    method: "POST",
    url: `${COMPANY_GENERATE_LINK_URL}`,
    withCredentials: true,
    data,
  });
  return response.data;
}

function* postVoteMlResult(data) {
  const response = yield call(axios, {
    method: "POST",
    url: `${VOTE_ML_RESULT_URL}`,
    withCredentials: true,
    data,
  });
  return response.data;
}

function* getGenerateShareLink(data) {
  let url = data.sid
    ? `${SHARE_LINK_URL}?sid=${data.sid}`
    : `${SHARE_LINK_URL}`;
  const response = yield call(axios, {
    method: "GET",
    url: url,
    withCredentials: true,
  });
  return response.data;
}

function* getLikedCompanies(filterNameValue, orderBy, orderDirection, page) {
  const filterNameValueEnd = filterNameValue ? filterNameValue.slice(-1) : "";
  let url;
  if (filterNameValue) {
    url = `${LIKED_COMPANIES_URL}?${filterNameValue}${
      filterNameValueEnd === "&" ? "" : "&"
    }sort-direction=${orderDirection}&sort-by=${orderBy}${
      !!page ? `&page=${page}` : ""
    }`;
  } else {
    url = `${LIKED_COMPANIES_URL}?sort-direction=${orderDirection}&sort-by=${orderBy}&page=${page}`;
  }
  const response = yield call(axios, {
    method: "GET",
    url: url,
    withCredentials: true,
  });
  return response.data;
}

export {
  postResults,
  getResults,
  getSearches,
  postSearches,
  getCompaniesChart,
  getCompany,
  generateLink,
  postVoteMlResult,
  getGenerateShareLink,
  getTeamSizeChart,
  getLikedCompanies,
};
