import { createStyles, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(({ palette, spacing, breakpoint }) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    main: {
      margin: `0 auto`,
      padding: spacing(4),
      [breakpoint.down("md")]: {
        padding: spacing(3),
      },
    },
    blocks: {
      gap: 16,
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "row",
    },
    shTitle: {
      marginTop: spacing(9),
    },
    shSubtitle: {
      marginTop: spacing(2),
      marginBottom: spacing(4),
      maxWidth: 571,
      [breakpoint.down("sm")]: {
        maxWidth: 288,
      },
    },
    shImage: {
      [breakpoint.down("sm")]: {
        maxWidth: 200,
      },
    },
    shButtonSet: {
      background: palette.primary.dark,
      [breakpoint.down("sm")]: {
        marginBottom: 45,
      },
    },
    shButton: {
      background: palette.primary.dark,
      width: 130,
      [breakpoint.down("sm")]: {
        marginBottom: 45,
      },
    },
    shButtonSetDisabled: {
      borderColor: palette.secondary.backgroundDark,
    },
    addNew: {
      background: palette.success.lighter,
      borderRadius: 20,
      flex: "calc(33% - 8px)",
      maxWidth: "calc(33% - 8px)",
      minHeight: 308,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      cursor: "pointer",
      filter:
        "drop-shadow(0px 100px 80px rgba(0, 0, 0, 0.03)) drop-shadow(0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0215656)) drop-shadow(0px 22.3363px 17.869px rgba(0, 0, 0, 0.0178832)) drop-shadow(0px 12.5216px 10.0172px rgba(0, 0, 0, 0.015)) drop-shadow(0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0121168)) drop-shadow(0px 2.76726px 2.21381px rgba(0, 0, 0, 0.00843437))",
      [breakpoint.down("xxl")]: {
        flex: "calc(50% - 8px)",
        maxWidth: "calc(50% - 8px)",
      },
      [breakpoint.down("sm")]: {
        flex: "100%",
        maxWidth: "100%",
      },
    },
    addLabel: {
      marginBottom: 4,
      fontWeight: 300,
    },
  }),
);
