import { all, takeEvery } from "redux-saga/effects";
import { getCountriesRequest } from "../requests";
import { getAllCountries } from "./workers";

const convertTypeFromAction = actionCreator => actionCreator.toString();

function* watchGetAllCountries() {
  yield takeEvery(
    convertTypeFromAction(getCountriesRequest),
    ({ payload: { regions } }) => getAllCountries(regions),
  );
}

function* saga() {
  yield all([watchGetAllCountries()]);
}

export { saga };
