import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import debounce from "lodash/debounce";
// Hooks
import { useTranslation } from "react-i18next";
import { useStyles } from "./style.js";
import { useRouterHook } from "../../../../common/useRouterHook";
import { mixpanelTrackRequest } from "../../../../store/mixpanel/requests";
import { useAuthHook } from "../../../../common/useAuthHook";
//Components
import Typography from "../../../../components/Typography/Typography";
import Button from "../../../../components/Button/Button";
import TextField from "../../../../components/TextField/TextField";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
//Assets
import { ReactComponent as SearchPlaceholderIcon } from "../../../../assets/icons/SearchPlaceholderIcon.svg";
import MoreIcon from "../../../../assets/icons/MoreIcon.svg";
// Redux
import { fetchTechnologiesRequest } from "../../../../store/technologies/requests.js";

const MoreComponent = ({ t, classes, onItemClicked }) => {
  const [menuOpened, setMenuOpened] = useState(false);

  const openDialog = () => {
    setMenuOpened(false);
    onItemClicked();
  };

  return (
    <>
      <ClickAwayListener onClickAway={() => setMenuOpened(false)}>
        <div className={classes.moreIconWrapper}>
          <img
            src={MoreIcon}
            onClick={() => setMenuOpened(true)}
            className={classes.moreIcon}
            alt={t("savedSearch.more")}
          />
          {menuOpened && (
            <div className={classes.moreIconMenu} onClick={() => openDialog()}>
              <Typography
                variant="bodyXs"
                className={classes.moreIconMenuItem}
                component="div"
                color="light">
                {t("technologies.suggestTechnology")}
              </Typography>
            </div>
          )}
        </div>
      </ClickAwayListener>
    </>
  );
};

const Header = ({
  totalCount,
  page,
  setPage,
  totalPages,
  onSuggestTechnologyClick,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [searchValue, setSearchValue] = useState(
    sessionStorage.getItem("technologies-FilterSearch"),
  );
  const { openPage } = useRouterHook();
  const dispatch = useDispatch();
  const { isAuthenticated } = useAuthHook();
  const [isLess768, setIsLess768] = useState(false);
  useEffect(() => {
    setIsLess768(window.innerWidth < 768);
  }, []);

  const handleSuggestTechnology = () => {
    onSuggestTechnologyClick();
    dispatch(
      mixpanelTrackRequest({ eventName: "Suggest a technology profile" }),
    );
  };

  const debouncedSearch = event => {
    sessionStorage.setItem("technologies-FilterSearch", event.target.value);
    dispatch(fetchTechnologiesRequest({ page: 1, search: event.target.value }));
    openPage(`${window.location.pathname}?page=1`);
    setPage(1);
  };

  const setServicesValueDebounced = useRef(debounce(debouncedSearch, 300));
  const handleSearch = event => {
    setSearchValue(event.target.value);
    setServicesValueDebounced.current(event);
  };

  return (
    <div className={classes.root}>
      <div className={classes.breadCrumb}>
        <div className={classes.imgTitleWrap}>
          <Typography component="div" variant="subtitle1">
            {t("technologies.technologies")}
            {totalCount > 0 && page && totalPages && (
              <Typography
                className={classes.ml8}
                color="light"
                variant="bodySmall">
                {totalPages > 1
                  ? t("resultsHeader.showingResultsPaginationLabel", {
                      count: totalCount,
                      page,
                      totalPages,
                    })
                  : t("resultsHeader.showingResultsLabel", {
                      count: totalCount,
                    })}
              </Typography>
            )}
          </Typography>
        </div>

        {isAuthenticated && (
          <div className={classes.flex}>
            {isLess768 ? (
              <MoreComponent
                t={t}
                classes={classes}
                onItemClicked={handleSuggestTechnology}
              />
            ) : (
              <Button
                onClick={handleSuggestTechnology}
                className={classes.request100Button}
                variant="quaternary">
                {t("technologies.suggestTechnology")}
              </Button>
            )}
          </div>
        )}
      </div>
      <TextField
        testId="header-search-field"
        endAdornment={
          <SearchPlaceholderIcon className={classes.startEndornmentSearch} />
        }
        value={searchValue || ""}
        className={classes.headerInput}
        fullWidth
        placeholder={t("technologies.searchPlaceholder")}
        onChange={handleSearch}></TextField>

      {/* <div className={classes.searchFilters}>
                    <div className={classes.header}>
                        <div className={classes.refineAndSearch}>
                            <TextField placeholder={t("technologies.searchForTechnologies")} endAdornment={<SearchPlaceholderIcon className={classes.startEndornment} />} value={searchValue} className={classes.headerInput} onChange={handleSearch}></TextField>
                        </div>
                        <div className={classes.filterWrap}>
                            <Typography color="secondaryDark" className={classes.sortedBy} variant="bodyButton">{t("technologies.industry")}</Typography>
                            <Multiselect customClass={classes.multiselectAdjust} removeLabel iconArrow={<ArrowIcon onClick={() => changeSortDirection()} className={classnames([classes.arrowIcon, { [classes.rotateIcon]: sortDirection === "asc" }])} />} options={sortOptions} value={sortValue.name} onChange={handleSelectChange}></Multiselect>
                        </div>
                        <div className={classes.filterWrap}>
                            <Typography color="secondaryDark" className={classes.sortedBy} variant="bodyButton">{t("technologies.subIndustry")}</Typography>
                            <Multiselect customClass={classes.multiselectAdjust} removeLabel iconArrow={<ArrowIcon onClick={() => changeSortDirection()} className={classnames([classes.arrowIcon, { [classes.rotateIcon]: sortDirection === "asc" }])} />} options={sortOptions} value={sortValue.name} onChange={handleSelectChange}></Multiselect>
                        </div>
                    </div>
                </div> */}
    </div>
  );
};
export default Header;
