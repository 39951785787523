import React, { useEffect, useState } from "react";
import { useStyles } from "./style.js";
// Hooks
import { useRouterHook } from "../../../../common/useRouterHook";
//UI components
import Link from "../../../../components/Link/Link.js";
//Assets
import { ReactComponent as ArrowRight } from "../../../../assets/icons/Arrow.svg";
import Button from "../../../../components/Button/Button";
import RemoveIcon from "../../../../assets/icons/RemoveW.svg";
import Typography from "../../../../components/Typography/Typography";
import RemoveWarningModal from "../RemoveWarningModal/RemoveWarningModal";
import { useProjectProfileHook } from "../../hooks/useProjectProfileHook";
import CreateProjectModal from "../../../Results/components/modals/CreateProjectModal";
import TopUpCreditsModal from "../../../Company/LockedPage/TopUpCreditsModal/TopUpCreditsModal";

const ProjectHeader = ({ currentProject }) => {
  // const { t } = useTranslation();
  const classes = useStyles();
  // const dispatch = useDispatch();
  const { openPage } = useRouterHook();
  const {
    t,
    createProjectModalShow,
    handleClosingDialog,
    topUpCreditsModalShow,
    handleBuyingTopUpCreditsDialog,
    handleClosingTopUpCreditsDialog,
    editProject,
    project,
  } = useProjectProfileHook({ currentProject });

  const [slug, setSlug] = useState(project.slug);
  const [removeWarningModalOpened, setRemoveWarningModalOpened] =
    useState(false);

  const deleteProject = () => {
    setRemoveWarningModalOpened(true);
  };

  useEffect(() => {
    setSlug(project.slug);
  }, [project]);

  return (
    <div className={classes.headerWrap}>
      <div className={classes.goBack}>
        <ArrowRight className={classes.arrowRight} />
        <Link
          onClick={() => openPage("/workspace")}
          className={classes.link}
          variant="caption"
          color="textPrimary">
          {t("projectProfile.goBack")}
        </Link>
      </div>
      <div className={classes.buttons}>
        <div
          className={classes.actionIconWrapperRemove}
          onClick={deleteProject}>
          <div className={classes.actionIconContent}>
            <img
              // width="16"
              // height="16"
              src={RemoveIcon}
              alt={t("projectProfile.deleteProject")}
              className={classes.actionIcon}
            />
          </div>
          <Typography
            variant="bodyXs"
            color="textLight"
            className={classes.actionText}>
            {t("projectProfile.deleteProject")}
          </Typography>
        </div>
        <Button className={classes.editProjectBtn} onClick={editProject}>
          {t("projectProfile.editProject")}
        </Button>
      </div>

      {removeWarningModalOpened && (
        <RemoveWarningModal
          projectSlug={slug}
          project={project}
          dialogOpened={removeWarningModalOpened}
          onChange={() => setRemoveWarningModalOpened(false)}
          isDeleteProject={true}
          isProjectPage={true}
        />
      )}

      {createProjectModalShow && (
        <CreateProjectModal
          data={project}
          source="project"
          dialogOpened={createProjectModalShow}
          onChange={handleClosingDialog}
        />
      )}
      {topUpCreditsModalShow && (
        <TopUpCreditsModal
          dialogOpened={topUpCreditsModalShow}
          onBuy={handleBuyingTopUpCreditsDialog}
          onChange={handleClosingTopUpCreditsDialog}
        />
      )}
    </div>
  );
};
export default ProjectHeader;
