import { createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(({ palette, spacing, shape }) =>
  createStyles({
    dialogRoot: {
      display: "flex",
      justifyContent: "center",
      paddingLeft: 0,
      paddingRight: 0,
    },
    dialog: {
      padding: spacing(4),
      background: palette.common.white,
    },
    dialogFilter: {
      padding: spacing(3),
      background: palette.common.white,
      borderRadius: shape.borderRadius,
    },
    dialogScrollPaper: {
      maxWidth: 800,
    },
    closeButton: {
      position: "absolute !important",
      right: spacing(4),
      top: spacing(3),
      color: palette.grey[500],
    },
    dialogTitle: {
      color: palette.secondary.main,
    },
    dialogSubtitle: {
      color: palette.grey.main,
      marginTop: spacing(3),
    },
    dialogContent: {
      marginTop: spacing(4),
    },
    goBackButton: {
      background: palette.error.main,
      borderColor: palette.error.main,
      marginLeft: spacing(2),
      "&:hover": {
        background: palette.error.light,
        borderColor: palette.error.light,
      },
    },
  }),
);
