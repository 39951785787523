import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(({ palette, spacing, typography, breakpoint }) => ({
  relative: {
    position: "relative",
    // marginLeft: spacing(2)
  },
  downloadButton: {
    fontFamily: "GT-Flexa",
    display: "flex",
    alignItems: "center",
    borderRadius: 100,
    border: `1px solid #666666`,
    padding: `4px ${spacing(2)}px 4px ${spacing(3)}px`,
    cursor: "pointer",
    marginRight: "10px",
    "&:hover": {
      background: palette.primary.dark,
      border: `1px solid transparent`,

      "& $downloadIcon path": {
        stroke: "#fff",
      },
      "& span": {
        color: "#fff",
      },
    },
  },
  downloadButtonDisabled: {
    fontFamily: "GT-Flexa",
    pointerEvents: "none",
    display: "flex",
    color: "#fff",
    alignItems: "center",
    background: "#DBDBDA",
    borderRadius: 100,
    padding: `4px ${spacing(2)}px 4px ${spacing(3)}px`,
    cursor: "default",
    border: `1px solid transparent`,

    "&:active": {
      display: "flex",
      alignItems: "center",
      background: palette.success.backgroundLight,
      borderRadius: 100,
      padding: `4px ${spacing(2)}px 4px ${spacing(3)}px`,
      cursor: "pointer",
    },
    "& $downloadIcon path": {
      stroke: "#fff",
    },
    "& span": {
      color: "#fff",
    },
  },
  downloadIcon: {
    marginLeft: "-15px",
    marginRight: "5px",
    "& path": {
      stroke: palette.common.black,
    },
  },
  mono: {
    fontFamily: "GT Flexa",
  },
  addPopup: {
    zIndex: 4,
    width: 235,
    maxHeight: 202,
    background: palette.common.white,
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    borderRadius: spacing(2),
    border: "1px solid",
    borderColor: palette.text.backgroundDark,
    top: 40,
    right: 0,
    overflow: "auto",
    paddingTop: spacing(1),
    paddingBottom: spacing(1),
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    "&::-webkit-scrollbar": {
      width: 2,
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 5px transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      background: palette.text.main,
      borderRadius: 0,
    },
  },
  projectNameWrapper: {
    width: "100%",
    display: "flex",
    padding: `${spacing(2)}px ${spacing(3)}px`,
    borderBottom: `1px solid ${palette.text.backgroundLight}`,
    color: palette.text.light,
    "&:hover": {
      background: palette.text.backgroundLight,
    },
    "&:last-child": {
      borderBottom: "none",
    },
  },
  clickable: {
    "&:hover": {
      cursor: "pointer",
      background: palette.success.backgroundDark,
    },
  },
  createNewProject: {
    display: "flex",
  },
  projectName: {
    display: "flex",
    alignItems: "center",
    color: palette.text.light,
  },
}));
