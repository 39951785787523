import React from "react";
// UI components
import Box from "@material-ui/core/Box";
import Typography from "../../../../components/Typography/Typography";
import IconButton from "@material-ui/core/IconButton";
import Button from "../../../../components/Button/Button";
import Grow from "@material-ui/core/Grow";
import { useIntercom } from "react-use-intercom";
// Hooks
import { useTranslation } from "react-i18next";
import { useStyles } from "./style";
//UI Components
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
//Assets
import CloseIcon from "../../../../assets/icons/CloseIcon.svg";
import EnrichmentSuccessIcon from "../../../../assets/images/EnrichmentSuccess.png";

const OrderReceivedModal = ({ dialogOpened, onChange, source = "company" }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { show } = useIntercom();

  const openIntercom = e => {
    e.preventDefault();

    if (process.env.REACT_APP_ENV === "prod") {
      show();
    }
  };

  const onOrdersLinkClick = () => {
    let url = window.location.origin + "/orders";
    if (source === "technology") {
      url += "/technologies";
    }
    window.open(url, "_blank");
  };

  return (
    <Dialog
      onClose={onChange}
      maxWidth="lg"
      TransitionComponent={Grow}
      classes={{
        root: classes.dialogRoot,
        paper: classes.dialog,
        scrollPaper: classes.dialogScrollPaper,
      }}
      open={dialogOpened}>
      <IconButton className={classes.closeButton} onClick={onChange}>
        <img
          src={CloseIcon}
          alt={t("scope.step2")}
          className={classes.stepIcon}
        />
      </IconButton>
      <MuiDialogContent className={classes.dialogContent}>
        <img src={EnrichmentSuccessIcon} />
        <Typography
          component="div"
          variant="h6"
          color="black"
          className={classes.title}>
          {t(`${source}Profile.weReceivedOrder`)}
        </Typography>
        <Typography
          component="div"
          variant="bodySmall"
          align="center"
          dangerouslySetInnerHTML={{
            __html: t("companyProfile.weSentConfirmation", {
              href: window.location.origin + "/orders",
            }),
          }}
          onClick={onOrdersLinkClick}
        />
      </MuiDialogContent>
      <Box display="flex" className={classes.buttons} justifyContent="center">
        <Button
          className={classes.orderButton}
          onClick={onChange}
          variant="primary">
          {t("companyProfile.done")}
        </Button>
      </Box>
      <div className={classes.footer}>
        <Typography
          component="div"
          variant="bodySmall"
          color="textSecondary"
          align="center">
          {t("companyProfile.enrichRequestModalFooter.text")}
          <a onClick={openIntercom}>
            {t("companyProfile.enrichRequestModalFooter.link")}
          </a>
        </Typography>
      </div>
    </Dialog>
  );
};
export default OrderReceivedModal;
