import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { ReactComponent as ScopeIcon } from "../../../../assets/icons/ScopeIcon.svg";
import { ReactComponent as LookAlikeIcon } from "../../../../assets/icons/LookalikeSearchIcon.svg";
import { ReactComponent as TrashIcon } from "../../../../assets/icons/Trash.svg";
import { Tooltip } from "@material-ui/core";
import { useRouterHook } from "../../../../common/useRouterHook";
import { actions as resultsActions } from "../../../../store/results/slice";
import { actions as searchActions } from "../../../../store/search/slice";
import { useAppEventTracking } from "../../../../common/useAppEventTracking";
import { useSearches } from "../../../../common/useSearches";
import { useStyles } from "./style.js";
import DeleteSearchDialog from "./components/DeleteSearchDialog";
import LoadScopeDialog from "./components/LoadScopeDialog";
import Pagination from "../../../../components/Pagination/Pagination";

const PreviousScopes = ({
  currentSearch,
  currentSearchId,
  searches,
  user,
  activeView,
  filterNameValue,
  sortValue,
  sortDirection,
  page,
  perPage,
  scopesPage,
  slug,
  token,
  searchTypeLook,
  setCurrentSearchId,
}) => {
  const [renderSearches, setRenderSearches] = useState([]);
  const { openPage, getUrlParam } = useRouterHook();
  const dispatch = useDispatch();
  const [updateRenderSearches, setUpdateRenderSearches] = useState(true);
  const { mixpanelTrackEvent } = useAppEventTracking();
  const [usedSearches, setUsedSearches] = useState([]);
  const [isPopupOpened, setIsPopupOpened] = useState(false);
  const [invisibleSearches, setInvisibleSearches] = useState([]);
  const [showAreYouSureModal, setShowAreYouSureModal] = useState(false);
  const [selectedScope, setSelectedScope] = useState(null);
  const [searchToDelete, setSearchToDelete] = useState();
  const [openDeleteSearchModal, setOpenDeleteSearchModal] = useState(false);
  const [openRemoveSavedSearchModal, setOpenRemoveSavedSearchModal] =
    useState(false);
  const urlParam = getUrlParam("sid") ? getUrlParam("sid").value : null;
  const {
    getRecentSearches: { data: scopes, refetch: refetchScopes },
    deleteSearch,
  } = useSearches(urlParam, selectedScope?.id || null);

  const handleScopeClick = search => {
    setSelectedScope(search);
    setShowAreYouSureModal(true);
  };

  const loadScope = () => {
    setCurrentSearchId(selectedScope.id);
    if (renderSearches) {
      return handleDesktopSearchTabClick(selectedScope);
    }
  };

  const handleRemoveSearch = () => {
    setSelectedScope(selectedScope);
    if (currentSearch.id === selectedScope.id) {
      openPage("/results");
      refetchScopes();
    }
    deleteSearch.mutate(selectedScope.id);
    // showCurrentSearchLabel && setShowCurrentSearchLabel(false);
    //setSelectedScope(search);
    //removeSearch.mutate(search.id);
  };

  // let data;
  // if (!!filterNameValue) {
  //     data = {
  //         sid: usedSearches[1] && usedSearches[1].id,
  //         filterNameValue: filterNameValue,
  //         orderBy: sortValue,
  //         orderDirection: sortDirection,
  //         page,
  //         perPage,
  //     };
  // } else {
  //     data = {
  //         sid: usedSearches[1] && usedSearches[1].id,
  //         orderBy: sortValue,
  //         orderDirection: sortDirection,
  //         page,
  //         perPage,
  //     };
  // }

  const handleRemoveSearchClick = (search, e) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedScope(search);
    setOpenDeleteSearchModal(true);
    /* if(!search.saved) {
  setSearchToDelete(search);
  setOpenRemoveSavedSearchModal(true);
} else {
  handleRemoveSearch(e, search);
} */
  };

  const handleDesktopSearchTabClick = visibleSearch => {
    if (visibleSearch.id !== currentSearch.id) {
      return handleSearchClick(visibleSearch);
    }
  };
  const handleSearchClick = (search, isInvisible) => {
    openPage(`/results?sid=${search.id}&page=1`);
    dispatch(resultsActions.setCurrentSearch(currentSearch));
    dispatch(resultsActions.setCurrentSearchSid(Number(search.id)));
    let data;
    if (!!filterNameValue) {
      data = {
        sid: search.id,
        filterNameValue: filterNameValue,
        orderBy: sortValue,
        orderDirection: sortDirection,
        page,
        perPage,
      };
    } else {
      data = {
        sid: search.id,
        orderBy: sortValue,
        orderDirection: sortDirection,
        page,
        perPage,
      };
    }
    dispatch(searchActions.clearSearch());
    search.sectors && dispatch(searchActions.addSectors(search.sectors));
    search.location &&
      dispatch(searchActions.updateLocation(JSON.parse(search.location)));
    dispatch(searchActions.setIndustryDesc(search.description));
    dispatch(searchActions.setProduct(1));
    if (isInvisible) {
      setUpdateRenderSearches(true);
    }

    mixpanelTrackEvent("Recent search selected");
  };

  const classes = useStyles();

  useEffect(() => {
    if (usedSearches && usedSearches.length) {
      if (!!currentSearch) {
        const searchesWithoutCurrentSearch = usedSearches.filter(
          temp => temp.id != currentSearchId,
        );
        const visibleSearches = searchesWithoutCurrentSearch.filter(
          (temp, i) => i < 4,
        );
        isPopupOpened && setIsPopupOpened(false);
        if (!!updateRenderSearches) {
          setInvisibleSearches(
            searchesWithoutCurrentSearch.filter((temp, i) => i > 3),
          );
          setRenderSearches([currentSearch, ...visibleSearches]);
          setUpdateRenderSearches(false);
        } else {
          let prevRenderSearches = renderSearches;
          const newRenderSearches = prevRenderSearches.map(rs => {
            const newRs = rs && usedSearches.find(us => us.id === rs.id);
            return newRs;
          });
          setRenderSearches(newRenderSearches);
        }
      }
      if (usedSearches.length === 1) {
        setInvisibleSearches([]);
      }
    }
  }, [usedSearches, currentSearch]);

  if (searches.length === 0) return <p>No previous searches...</p>;
  return (
    <>
      <TableContainer
        className={classes.root}
        component={Paper}
        style={{ background: "95%", maxHeight: "95%", overflowY: "auto" }}>
        <Table stickyHeader className={classes.table} aria-label="simple table">
          <TableHead className={classes.head}>
            <TableRow>
              <TableCell className={classes.header}>Date</TableCell>
              <TableCell className={classes.header} align="right">
                Type
              </TableCell>
              <TableCell className={classes.header} align="left">
                Scope
              </TableCell>
              <TableCell className={classes.header} align="right">
                Delete
              </TableCell>
            </TableRow>
          </TableHead>
          <LoadScopeDialog
            open={showAreYouSureModal}
            setOpen={setShowAreYouSureModal}
            handleYesClick={loadScope}
          />
          <DeleteSearchDialog
            open={openDeleteSearchModal}
            setOpen={setOpenDeleteSearchModal}
            handleYesClick={handleRemoveSearch}
            selectedSearch={selectedScope}
          />
          <TableBody className={classes.tbody}>
            {searches &&
              searches.length &&
              searches.map(search => (
                <TableRow key={search.id} className={classes.card}>
                  <TableCell
                    className={`date ${classes.date}`}
                    component="th"
                    scope="row"
                    onClick={() => {
                      handleScopeClick(search);
                    }}>
                    <div style={{ whiteSpace: "nowrap" }}>
                      {search.created_date}
                    </div>
                    <div style={{ whiteSpace: "nowrap" }}>
                      {currentSearch && currentSearch.id === search.id ? (
                        <>
                          <div>Active</div>
                        </>
                      ) : (
                        <>{search.created_ago}</>
                      )}
                    </div>
                  </TableCell>
                  <TableCell
                    className={`type ${
                      search?.scope === "Find look-alike"
                        ? classes.lookalike
                        : classes.searchScope
                    } ${classes.type}`}
                    align="right">
                    {search?.scope === "Find look-alike" ? (
                      <LookAlikeIcon />
                    ) : (
                      <ScopeIcon />
                    )}
                  </TableCell>

                  <Tooltip
                    title={
                      <div className={classes.tooltipTitle}>
                        {search?.description}
                      </div>
                    }
                    arrow>
                    {/* <TableCell className={`scope ${classes.scope}`} align="left" onClick={() => handleClick(search)}>{search.description.length > 120 ? `${search.description.substring(0, 120)} ...` : search.description}</TableCell> */}

                    <TableCell
                      className={`scope ${classes.scope}`}
                      align="left"
                      onClick={() => {
                        handleScopeClick(search);
                      }}>
                      {search.description?.length > 120
                        ? `${search.description?.substring(0, 120)} ...`
                        : search.description}
                    </TableCell>
                  </Tooltip>
                  <TableCell align="right">
                    <TrashIcon
                      className={`delete ${classes.delete}`}
                      onClick={e => handleRemoveSearchClick(search, e)}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
      // count={}
      />
    </>
  );
};

export default PreviousScopes;
