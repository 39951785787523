import { createAction } from "@reduxjs/toolkit";
import { REDUCER_PREFIX } from "./slice";

export const getUserConversationRequest = createAction(
  `${REDUCER_PREFIX}/getUserConversationRequest`,
);

/* export const setConversationIdRequest = createAction(
    `${REDUCER_PREFIX}/setConversationIdRequest`,
); */

export const createUserConversationRequest = createAction(
  `${REDUCER_PREFIX}/createUserConversationRequest`,
);

export const updateUserConversationRequest = createAction(
  `${REDUCER_PREFIX}/updateUserConversationRequest`,
);

export const saveUserConversationIdRequest = createAction(
  `${REDUCER_PREFIX}/saveUserConversationIdRequest`,
);

export const emptyStorageConversationRequest = createAction(
  `${REDUCER_PREFIX}/emptyStorageConversationRequest`,
);

export const fetchPumaSummaryRequest = createAction(
  `${REDUCER_PREFIX}/fetchPumaSummaryRequest`,
);

export const getConversationHistoryRequest = createAction(
  `${REDUCER_PREFIX}/getConversationHistoryRequest`,
);
