import { createSlice } from "@reduxjs/toolkit";
import {
  REQUEST_FAILURE,
  REQUEST_PENDING,
  REQUEST_SUCCESS,
  REQUEST_UNDEFINED,
} from "../../constants/statuses";

export const INITIAL_STATE = {
  companies: [],
  technologies: [],
  getOrdersStatus: REQUEST_UNDEFINED,
  exportCsvStatus: REQUEST_UNDEFINED,
};

export const REDUCER_PREFIX = "orders";

const { reducer, actions } = createSlice({
  name: REDUCER_PREFIX,
  initialState: INITIAL_STATE,
  reducers: {
    getOrdersPending: state => {
      state.getOrdersStatus = REQUEST_PENDING;
    },
    getOrdersSuccess: state => {
      state.getOrdersStatus = REQUEST_SUCCESS;
    },
    getOrdersFailure: state => {
      state.getOrdersStatus = REQUEST_FAILURE;
    },
    setOrders: (state, { payload }) => {
      state[`${payload.type}`] = payload.payload;
    },
    exportCsvPending: state => {
      state.exportCsvStatus = REQUEST_PENDING;
    },
    exportCsvSuccess: state => {
      state.exportCsvStatus = REQUEST_SUCCESS;
    },
    exportCsvFailure: state => {
      state.exportCsvStatus = REQUEST_FAILURE;
    },
    exportCsvUndefined: state => {
      state.exportCsvStatus = REQUEST_UNDEFINED;
    },
  },
});
export { reducer, actions };
