import { createStyles, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(
  ({ palette, spacing, typography, breakpoint }) =>
    createStyles({
      root: {
        height: "100vh",
        width: "100%",
        position: "relative",
        flexWrap: "wrap",
      },
      signUpRoot: {
        height: "100vh",
        width: "100%",
        position: "relative",
        display: "flex",
        flexDirection: "row",
        backgroundColor: palette.common.white,
        [breakpoint.down("xxl")]: {
          flexDirection: "column",
        },
      },
      firstColumWrapper: {
        background: palette.secondary.backgroundLight,
        display: "flex",
        flexDirection: "column",
        width: "50%",
        [breakpoint.down("xxl")]: {
          order: 2,
          width: "100%",
        },
      },
      illustrationWrapper: {
        color: palette.common.white,
        padding: `${spacing(5)}px ${spacing(8)}px`,
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        flex: "1 1 auto",
      },
      pumaText: {
        color: palette.secondary.main,
        fontSize: 130,
        fontWeight: 300,
        lineHeight: "normal",
        width: "400px",
        textAlign: "center",
      },
      pumaDescription: {
        color: palette.secondary.main,
        fontSize: 20,
        fontWeight: 500,
        lineHeight: "normal",
        width: 372,
        marginTop: spacing(2),
      },
      logoWrapper: {
        marginTop: "auto",
        paddingBottom: spacing(4),
        maxWidth: 468,
        alignSelf: "center",
        "& > span": {
          fontSize: 8,
          color: palette.secondary.light,
        },
        "& > img": {
          height: 10,
        },
      },
      illustrationTitle: {
        maxWidth: 468,
        margin: `${spacing(5)}px 0 ${spacing(7)}px 0`,
        alignSelf: "center",
      },
      illustration: {
        maxWidth: 463,
        alignSelf: "center",
      },
      signInIllustration: {
        height: 400,
        maxWidth: 463,
        alignSelf: "center",
      },
      logo: {
        maxWidth: 144,
      },
      formWrapper: {
        padding: `${spacing(5)}px ${spacing(9)}px`,
        alignSelf: "center",
        background: palette.common.white,
        [breakpoint.down("xxl")]: {
          padding: `${spacing(5)}px ${spacing(2)}px`,
          maxWidth: 512,
          margin: "0 auto",
          marginTop: "7%",
        },
        [breakpoint.down("xl")]: {
          marginTop: "12%",
        },
        [breakpoint.down("lg")]: {
          marginTop: "20%",
        },
        [breakpoint.down("md")]: {
          padding: `${spacing(7)}px ${spacing(2)}px`,
          marginTop: 0,
        },
      },
      registerFormWrapper: {
        padding: `34px 53px`,
        background: palette.common.white,
        maxWidth: 535,
        justifySelf: "center",
        border: `1px solid ${palette.text.backgroundMain}`,
        borderRadius: spacing(2),
        [breakpoint.down("xxl")]: {
          maxWidth: 5122,
          margin: "0 auto",
          border: 0,
          borderRadius: 0,
          padding: spacing(3),
          borderBottom: `1px solid ${palette.text.lightest}`,
        },
      },
      formWrapperRoot: {
        width: "50%",
        display: "flex",
        alignItems: "center",
        background: palette.common.white,
        justifyContent: "center",
        [breakpoint.down("xxl")]: {
          width: "100%",
        },
        [breakpoint.down("md")]: {
          display: "block",
        },
      },
      registerFormWrapperRoot: {
        width: "50%",
        display: "flex",
        alignItems: "baseline",
        paddingTop: 87,
        [breakpoint.down("xxl")]: {
          width: "100%",
          order: 2,
          paddingTop: 0,
        },
        [breakpoint.down("md")]: {
          display: "block",
        },
      },
      registerFormWrapperRootLeft: {
        width: "50%",
        display: "flex",
        paddingTop: 121,
        paddingLeft: 64,
        paddingRight: spacing(2),
        alignItems: "center",
        [breakpoint.down("xxl")]: {
          width: "100%",
          order: 1,
          padding: spacing(3),
          borderBottom: `1px solid ${palette.text.backgroundMain}`,
        },
        [breakpoint.down("md")]: {
          display: "block",
        },
      },
      signUpTitleDesc: {
        color: palette.text.main,
        fontSize: "35px",
        fontWeight: 300,
        marginBottom: spacing(1),
      },
      signUpMainTitle: {
        marginBottom: spacing(2),
        [breakpoint.down("xxl")]: {
          marginTop: spacing(6),
        },
      },
      registerFormWrapperLeft: {
        maxWidth: 515,
        [breakpoint.down("xxl")]: {
          maxWidth: "none",
          display: "flex",
          flexDirection: "column",
        },
      },
      benefit: {
        display: "flex",
        alignItems: "center",
        marginTop: spacing(3),
        maxWidth: 520,
        width: "100%",
        alignSelf: "center",
      },
      benefitIcon: {
        marginRight: 25,
        display: "block",
        [breakpoint.down("xxl")]: {
          display: "none",
        },
      },
      benefitIconMobile: {
        marginRight: 25,
        display: "none",
        [breakpoint.down("xxl")]: {
          display: "block",
        },
      },
      inputWrapper: {
        marginBottom: spacing(3),
        width: "100%",
      },
      passwordInputWrapper: {
        width: "100%",
      },
      mono: {
        fontFamily: "GT Flexa Mono, sans-serif",
      },
      passwordHint: {
        color: "rgb(0 0 0 / 30%)",
        "& svg": {
          fill: "rgb(0 0 0 / 30%)",
        },
        display: "inline-flex",
        alignItems: "center",
        marginRight: 20,
        [breakpoint.down("md")]: {
          marginBottom: spacing(1),
          display: "flex",
        },
      },
      passwordHintGreen: {
        color: `${palette.success.dark} !important`,
        "& svg": {
          fill: palette.success.dark,
        },
        display: "inline-flex",
        alignItems: "center",
        marginRight: 20,
      },
      passwordHintTick: {
        marginRight: 7,
      },
      passwordHints: {
        marginTop: 12,
      },
      confirmPasswordInputWrapper: {
        marginBottom: spacing(3),
        width: "100%",
      },
      divider: {
        width: "100%",
        height: 1,
        background: "#E3E5E9",
        marginBottom: spacing(3),
      },
      form: {
        marginTop: spacing(2),
        [breakpoint.down("xxl")]: {
          marginTop: spacing(3),
        },
        [breakpoint.down("xs")]: {
          marginTop: spacing(4),
        },
      },
      checkboxRoot: {
        padding: `0 9px 0`,
        "& svg": {
          fill: palette.checkboxBorder,
          width: 19,
          height: 19,
        },
      },
      formControlLabelRoot: {
        color: palette.grey.main,
        width: "100%",
        marginTop: spacing(1),
        marginRight: 0,
      },
      formControlLabelSignInRoot: {
        color: palette.grey.main,
      },
      createAccountButton: {
        margin: `${spacing(1)}px 0 ${spacing(5)}px 0`,
        [breakpoint.down("md")]: {
          textAlign: "center",
        },
      },
      signInCopy: {
        marginBottom: spacing(1),
      },
      signInCopyText: {
        fontFamily: "GT Flexa, sans-serif",
        color: palette.primary.dark,
      },
      signInCopyLink: {
        textDecoration: "underline",
        color: palette.primary.dark,
        fontSize: 16,
        "&:hover": {
          color: palette.secondary.dark,
        },
      },
      additionalCtasWrapper: {
        borderTop: `1px solid ${palette.text.lightest}`,
        paddingTop: 17,
      },
      greyCircle: {
        width: 5,
        height: 5,
        borderRadius: "100%",
        background: palette.text.light,
        margin: `2px 10px`,
        display: "inline-flex",
      },
      signUpBtn: {
        fontSize: 16,
        lineHeight: "18px",
      },
      createAccountButtonInfo: {
        marginLeft: spacing(3),
        marginTop: spacing(1),
      },
      goBack: {
        position: "absolute",
        left: 64,
        top: 40,
        zIndex: 2,
        fontFamily: "GT Flexa Mono, sans-serif",
        color: palette.secondary.dark,
        [breakpoint.down("md")]: {
          left: 16,
          top: 16,
        },
      },
      goBackSignUp: {
        position: "absolute",
        left: 64,
        top: 40,
        zIndex: 2,
        fontFamily: "GT Flexa Mono, sans-serif",
        color: palette.secondary.dark,
        [breakpoint.down("xxl")]: {
          display: "none",
        },
        [breakpoint.down("md")]: {
          left: 16,
          top: 16,
        },
        "&:hover": {
          textDecoration: "underline",
          color: palette.secondary.light,
          cursor: "pointer",
          "& svg": {
            stroke: palette.secondary.light,
          },
        },
      },
      exploreValuer: {
        position: "absolute",
        left: 24,
        top: 40,
        zIndex: 2,
        fontFamily: "GT Flexa Mono, sans-serif",
        color: palette.secondary.dark,
        textDecoration: "underline",
        "&:hover": {
          color: palette.secondary.light,
          cursor: "pointer",
          "& svg": {
            stroke: palette.secondary.light,
          },
        },
      },
      goBackIcon: {
        stroke: palette.secondary.dark,
        transform: "rotate(180deg)",
        marginRight: spacing(1),
      },
      rememberMeForgotWrapper: {
        marginBottom: spacing(1),
      },
      eye: {
        position: "absolute",
        right: spacing(1),
        cursor: "pointer",
      },
      formError: {
        marginBottom: spacing(1),
      },
      signInButton: {
        margin: `0 0 ${spacing(1)}px 0`,
      },
      labelShrinked: {
        ...typography.bodyXs,
        fontFamily: "GT Flexa Mono, sans-serif",
        transform: "none",
        marginTop: -4,
        color: palette.text.light,
      },
      focusedLabel: {
        color: `${palette.text.light} !important`,
      },
      forgotPassword: {
        fontFamily: "GT Flexa, sans-serif",
        textDecoration: "underline",
        fontSize: 16,
        color: palette.primary.dark,
        "&:hover": {
          color: palette.secondary.dark,
        },
      },
      formFieldWrapper: {
        marginBottom: spacing(2),
      },
      field: {
        display: "flex",
        flexDirection: "column",
        position: "relative",
      },
      inputLabel: {
        fontSize: 14,
        color: palette.text.main,
        fontWeight: 300,
        marginBottom: 4,
      },
      input: {
        paddingLeft: 14,
        height: 42,
        borderRadius: 8,
        border: `1px solid ${palette.text.backgroundDark}`,
        paddingRight: 30,
        "&:focus": {
          outline: "none",
          border: `1px solid ${palette.primary.dark} !important`,
        },
      },
      showPasswordIcon: {
        position: "absolute",
        bottom: 31,
        right: 0,
      },
      signInBtn: {
        fontFamily: "GT Flexa",
        fontWeight: 500,
        fontSize: 16,
      },
      /* input: {
          "&.Mui-focused": {
              "&:after": {
                  borderBottom: `2px solid ${palette.primary.dark}`,
              },
              "&:before": {
                  borderBottom: `2px solid ${palette.primary.dark}`,
              },
          },
          "&:after": {
              borderBottom: `2px solid ${palette.text.lighter}`,
          },
          "&:before": {
              borderBottom: `2px solid ${palette.text.lighter}`,
          },
      }, */
      errorMsg: {
        color: palette.error.main,
      },
      errorField: {
        border: `1px solid ${palette.error.dark}`,
      },
      signUpSubtitle: {
        color: palette.text.lighter,
        fontFamily: "GT Flexa Mono, sans-serif",
        marginBottom: spacing(1),
      },
      signUpIllustrationTitle: {
        marginBottom: spacing(6),
      },
      logoWrapperFrogorPasswordPage: {
        marginTop: "0 !important",
      },
    }),
);
