import { all, takeEvery } from "redux-saga/effects";
import {
  buyCreditsRequest,
  getCreditsSummaryRequest,
  getPlanRequest,
} from "../requests";
import { buyCredits, getCreditsSummary, getPlan } from "./workers";

const convertTypeFromAction = actionCreator => actionCreator.toString();

function* watchBuyCredits() {
  yield takeEvery(
    convertTypeFromAction(buyCreditsRequest),
    ({ payload: { data } }) => buyCredits(data),
  );
}

function* watchGetCreditsSummary() {
  yield takeEvery(convertTypeFromAction(getCreditsSummaryRequest), () =>
    getCreditsSummary(),
  );
}

function* watchGetPlan() {
  yield takeEvery(
    convertTypeFromAction(getPlanRequest),
    ({ payload: { plan, period, amount, currency } }) =>
      getPlan(plan, period, amount, currency),
  );
}

function* saga() {
  yield all([watchBuyCredits(), watchGetCreditsSummary(), watchGetPlan()]);
}

export { saga };
