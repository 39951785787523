export const createCompanyData = (
  name,
  description,
  funding,
  year,
  team,
  stage,
  location,
  match,
  slug,
  id,
  enrichment_request,
  ready_for_release,
  website,
  deck,
  sectors,
  comments_cnt,
  subsectors,
  projects,
) => {
  return {
    name,
    description,
    funding,
    year,
    team,
    stage,
    location,
    match,
    slug,
    id,
    enrichment_request,
    ready_for_release,
    website,
    deck,
    sectors,
    comments_cnt,
    subsectors,
    projects,
  };
};
