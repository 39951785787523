import {useEffect, useRef, useState} from "react";
import {
  filterParamMapping,
  fundingOptionsMaxData,
  industryFilterParamMapping,
  selectedFiltersEmptyData,
  sortOptions,
  teamsizeOptions,
  valuerScoreOptionsMaxData,
  yearOptionsMaxData,
} from "../constants/filters";
import {useLocation} from "react-router-dom";
import _ from "lodash";
import {useDispatch, useSelector} from "react-redux";
import {fetchSubsectorsRequest} from "../store/sectors/requests";
import {objectHasEmptyValues} from "../utils/objectHasEmptyValues";
import usePrevious from "./usePrevious";
import {useRouterHook} from "./useRouterHook";
import {getPostSearchStatusSelector} from "../store/search/selectors";
import {actions as searchActions} from "../store/search/slice";
import {actions as resultsActions} from "../store/results/slice";
import {
  getCompaniesChartSelector,
  getDisplayFiltersBySidSelector,
  getTeamSizeChartSelector,
} from "../store/results/selectors";
import {getCountriesRequest} from "../store/countries/requests";

// Key points:
// - selectedFilters state are filters used in filters modal
// - selectedFilters state are creating modalFilterNameValue
// - modalFilterNameValue state is used in dispatch(countRequest) for returning the number of companies by modals filters
// - displayedFilters state are filters used in displayed results
// - displayedFilters state are creating filterNameValue
// - filterNameValue state is used in dispatch(request) for returning the companies that we will show on the page

const useFilters = ({
  allSectors,
  setTotalPages,
  getFilterResultsTotalPages,
  getSearchResultsTotalPages,
  isAuthenticated,
  userLoading,
  searchTypeLook,
  currentSearchSid,
  request,
  countRequest,
  yearChartRequest,
  teamSizeChartRequest,
  token,
  isSharedPageToken,
  slug,
  companiesId,
  isIndustry,
  isTechnology,
  activeView,
  isProject = false,
  isLikedCompanies = false,
}) => {
  const dispatch = useDispatch();
  const isInitialMount = useRef(true);
  const isInitialMount2 = useRef(true);
  const { getUrlParam } = useRouterHook();

  let { search } = useLocation();

  useEffect(() => {
    return () => {
      isInitialMount.current = null;
      isInitialMount2.current = null;
    };
  }, []);

  const getDisplayFilters =
    useSelector(
      getDisplayFiltersBySidSelector(
        isIndustry || isTechnology || isProject ? slug : currentSearchSid,
      ),
    ) || {};
  const prevGetDisplayFilters = usePrevious(getDisplayFilters);
  const prevCurrentSearchSid = usePrevious(currentSearchSid);
  const [filters, setFilters] = useState({});
  const [yearOptionsMax, setYearOptionsMax] = useState(yearOptionsMaxData);
  const [fundingOptionsMax, setFundingOptionsMax] = useState(
    fundingOptionsMaxData,
  );
  const [valuerScoreOptionsMax, setValuerScoreOptionsMax] = useState(
    valuerScoreOptionsMaxData,
  );
  const [teamSizeOptionsMax, setTeamSizeOptionsMax] = useState(teamsizeOptions);
  const [isFilterFetching, setIsFilterFetching] = useState(false);
  const [lastFilteredField, setLastFilteredField] = useState();
  const [lastFilteredFieldValue, setLastFilteredFieldValue] = useState();
  const [perPage, setPerPage] = useState(50);
  const prevPerPage = usePrevious(perPage);
  const chartYears = useSelector(getCompaniesChartSelector);
  const teamSizes = useSelector(getTeamSizeChartSelector);

  const [filterMapping, setFilterMapping] = useState(filterParamMapping);

  useEffect(() => {
    isIndustry
      ? setFilterMapping(industryFilterParamMapping)
      : setFilterMapping(filterParamMapping);
  }, [isIndustry]);

  const postSearchStatus = useSelector(getPostSearchStatusSelector);
  // selectedFilters are filters used for the filters modal (the count on the Show results button) and are not used to show results on the page
  const [selectedFilters, setSelectedFilters] = useState(
    !objectHasEmptyValues({
      sector: getDisplayFilters.sector,
      subsector: getDisplayFilters.subsector,
      subsectors: getDisplayFilters.subsectors,
      stage: getDisplayFilters.stage,
      fundingMin: getDisplayFilters.fundingMin,
      fundingMax: getDisplayFilters.fundingMax,
      teamsizeMin: getDisplayFilters.teamsizeMin,
      teamsizeMax: getDisplayFilters.teamsizeMax,
      location: getDisplayFilters.location,
      yearMin: getDisplayFilters.yearMin,
      yearMax: getDisplayFilters.yearMax,
      matchingScoreMin: getDisplayFilters.matchingScoreMin,
      matchingScoreMax: getDisplayFilters.matchingScoreMax,
      regions: getDisplayFilters.regions,
      search: getDisplayFilters.search,
      excludeKeywords: getDisplayFilters.excludeKeywords,
      includeKeywords: getDisplayFilters.includeKeywords,
    })
      ? {
          sector: getDisplayFilters.sector || "",
          subsector: getDisplayFilters.subsector || "",
          subsectors: getDisplayFilters.subsectors || "",
          stage: getDisplayFilters.stage || "",
          fundingMin: getDisplayFilters.fundingMin || "",
          fundingMax: getDisplayFilters.fundingMax || "",
          teamsizeMin: getDisplayFilters.teamsizeMin || "",
          teamsizeMax: getDisplayFilters.teamsizeMax || "",
          location: getDisplayFilters.location || "",
          yearMin: getDisplayFilters.yearMin || "",
          yearMax: getDisplayFilters.yearMax || "",
          matchingScoreMin: getDisplayFilters.matchingScoreMin || "",
          matchingScoreMax: getDisplayFilters.matchingScoreMax || "",
          regions: getDisplayFilters.regions || "",
          search: getDisplayFilters.search || "",
          excludeKeywords: getDisplayFilters.excludeKeywords || "",
          includeKeywords: getDisplayFilters.includeKeywords || "",
        }
      : selectedFiltersEmptyData,
  );
  const prevSelectedFilters = usePrevious(selectedFilters);
  const [preselectedSectors, setPreselectedSectors] = useState(
    !objectHasEmptyValues(getDisplayFilters.sector)
      ? getDisplayFilters.sector
      : selectedFiltersEmptyData.sector
      ? selectedFiltersEmptyData.sector
      : [],
  );
  const [preselectedSubsectors, setPreselectedSubsectors] = useState(
    selectedFilters ? selectedFilters.subsector : [],
  );
  // displayedFilters are filters that are used to display results on the page
  const [displayedFilters, setDisplayedFilters] = useState(
    !objectHasEmptyValues({
      sector: getDisplayFilters.sector,
      subsector: getDisplayFilters.subsector,
      subsectors: getDisplayFilters.subsectors,
      stage: getDisplayFilters.stage,
      fundingMin: getDisplayFilters.fundingMin,
      fundingMax: getDisplayFilters.fundingMax,
      teamsizeMin: getDisplayFilters.teamsizeMin,
      teamsizeMax: getDisplayFilters.teamsizeMax,
      location: getDisplayFilters.location,
      yearMin: getDisplayFilters.yearMin,
      yearMax: getDisplayFilters.yearMax,
      matchingScoreMin: getDisplayFilters.matchingScoreMin,
      matchingScoreMax: getDisplayFilters.matchingScoreMax,
      regions: getDisplayFilters.regions,
      search: getDisplayFilters.search,
      excludeKeywords: getDisplayFilters.excludeKeywords,
      includeKeywords: getDisplayFilters.includeKeywords,
    })
      ? {
          sector: getDisplayFilters.sector || "",
          subsector: getDisplayFilters.subsector || "",
          subsectors: getDisplayFilters.subsectors || "",
          stage: getDisplayFilters.stage || "",
          fundingMin: getDisplayFilters.fundingMin || "",
          fundingMax: getDisplayFilters.fundingMax || "",
          teamsizeMin: getDisplayFilters.teamsizeMin || "",
          teamsizeMax: getDisplayFilters.teamsizeMax || "",
          location: getDisplayFilters.location || "",
          yearMin: getDisplayFilters.yearMin || "",
          yearMax: getDisplayFilters.yearMax || "",
          matchingScoreMin: getDisplayFilters.matchingScoreMin || "",
          matchingScoreMax: getDisplayFilters.matchingScoreMax || "",
          regions: getDisplayFilters.regions || "",
          search: getDisplayFilters.search || "",
          excludeKeywords: getDisplayFilters.excludeKeywords || "",
          includeKeywords: getDisplayFilters.includeKeywords || "",
        }
      : selectedFiltersEmptyData,
  );
  const sortOption = sortOptions().find(
    result => result.slug === getDisplayFilters.sortValue,
  );
  let sortingOption;
  if (!!getDisplayFilters.sortValue) {
    sortingOption =
      sortOption && sortOption.slug
        ? sortOption.slug
        : isIndustry || isTechnology || isProject
        ? "founded_date"
        : "degree_of_fit";
  } else {
    sortingOption =
      isIndustry || isTechnology || isProject
        ? "founded_date"
        : "degree_of_fit";
  }
  const [sortValue, setSortValue] = useState(sortingOption);
  const [sortDirection, setSortDirection] = useState(
    getDisplayFilters && getDisplayFilters.sortDirection
      ? getDisplayFilters.sortDirection
      : "desc",
  );
  const [page, setPage] = useState(
    getUrlParam("page")
      ? getUrlParam("page").value
      : getDisplayFilters && getDisplayFilters.storageResultsFilterPage
      ? getDisplayFilters.storageResultsFilterPage
      : 1,
  );
  const prevPage = usePrevious(page);
  const [filterNameValue, setFilterNameValue] = useState();
  const [modalFilterNameValue, setModalFilterNameValue] = useState();
  const prevModalFilterNameValue = usePrevious(modalFilterNameValue) || "";
  const prevFilterNameValue = usePrevious(filterNameValue);
  useEffect(() => {
    if (postSearchStatus === "REQUEST_SUCCESS") {
      const sid =
        currentSearchSid || (getUrlParam("sid") && getUrlParam("sid").value);

      !chartYears && fetchYearsChartData(sid);
      !teamSizes && fetchTeamSizeChartData(sid);

      let timeout = setTimeout(() => {
        dispatch(searchActions.postMLSearchUndefined());
      }, 300);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [postSearchStatus]);

  useEffect(() => {
    setSortValue(
      isIndustry || isTechnology || isProject
        ? "founded_date"
        : "degree_of_fit",
    );
  }, [isIndustry, isTechnology, isProject]);

  // Empties filters and selectedFilters
  const resetFilters = () => {
    setSelectedFilters(selectedFiltersEmptyData);
    setPreselectedSectors([]);
    setPreselectedSubsectors([]);
  };

  let fundingMaxOptionsOriginal = _.cloneDeep(fundingOptionsMaxData);
  let yearInceptionMaxOptionsOriginal = _.cloneDeep(yearOptionsMaxData);
  let matchingScoreMaxOptionsOriginal = _.cloneDeep(valuerScoreOptionsMaxData);
  let teamSizeMaxOptionsOriginal = _.cloneDeep(teamsizeOptions);

  const fetchYearsChartData = sid => {
    let companiesChartParams =
      (document.body.offsetWidth > 576 && document.body.offsetWidth < 768) ||
      document.body.offsetWidth > 1281
        ? {
            id: isIndustry || isTechnology ? companiesId : sid,
            type: isTechnology
              ? "technologies"
              : isIndustry
              ? "industries"
              : "results",
            filterNameValue,
          }
        : {
            id: isIndustry || isTechnology ? companiesId : sid,
            type: isTechnology
              ? "technologies"
              : isIndustry
              ? "industries"
              : "results",
            grouped: true,
            filterNameValue,
          };
    !isProject &&
      !isLikedCompanies &&
      !!isAuthenticated &&
      dispatch(yearChartRequest(companiesChartParams));
  };
  const fetchTeamSizeChartData = sid => {
    let companiesChartParams =
      (document.body.offsetWidth > 576 && document.body.offsetWidth < 768) ||
      document.body.offsetWidth > 1281
        ? {
            id: isIndustry || isTechnology ? companiesId : sid,
            type: isTechnology
              ? "technologies"
              : isIndustry
              ? "industries"
              : "results",
            filterNameValue,
          }
        : {
            id: isIndustry || isTechnology ? companiesId : sid,
            type: isTechnology
              ? "technologies"
              : isIndustry
              ? "industries"
              : "results",
            grouped: true,
            filterNameValue,
          };
    !isProject &&
      !isLikedCompanies &&
      !!isAuthenticated &&
      dispatch(teamSizeChartRequest(companiesChartParams));
  };

  // When we select sector, we fetch subsectors
  useEffect(() => {
    selectedFilters.sector &&
      selectedFilters.sector.length &&
      fetchAllSubsectors();
  }, [selectedFilters.sector]);

  // When we select region, we fetch countries
  useEffect(() => {
    selectedFilters.regions &&
      dispatch(getCountriesRequest({ regions: selectedFilters.regions }));
  }, [dispatch, selectedFilters.regions]);

  const fetchAllSubsectors = () => {
    dispatch(
      fetchSubsectorsRequest(
        Array.isArray(selectedFilters.sector)
          ? selectedFilters.sector.map(
              s =>
                allSectors.find(sector => sector.name === s) &&
                allSectors.find(sector => sector.name === s).slug,
            )
          : selectedFilters.sector,
      ),
    );
  };

  // START: filters fields changes
  const handleFieldChange = (event, field, value) => {
    setPage(
      getUrlParam("page")
        ? getUrlParam("page").value
        : getDisplayFilters.storageResultsFilterPage
        ? getDisplayFilters.storageResultsFilterPage
        : 1,
    );
    let stagesArray = [...selectedFilters.stage];
    let locationsArray = [...selectedFilters.location];
    let regionsArray = [...selectedFilters.regions];
    if (field === "sector") {
      setPreselectedSectors(
        typeof value === "string" ? value.split(",") : value,
      );
      setLastFilteredField("sector");
    } else if (field === "stage") {
      if (stagesArray.includes(value)) {
        stagesArray = stagesArray.filter(e => e != value);
      } else {
        stagesArray = value;
      }
      setSelectedFilters(prevSelectedFilters => {
        return {
          ...prevSelectedFilters,
          [field]: stagesArray && stagesArray.length ? stagesArray : "",
        };
      });
      setLastFilteredField("investment_stages");
    } else if (field === "regions") {
      if (regionsArray.includes(value)) {
        regionsArray = regionsArray.filter(e => e != value);
      } else {
        regionsArray = value;
      }
      setSelectedFilters(prevSelectedFilters => {
        return {
          ...prevSelectedFilters,
          [field]: regionsArray && regionsArray.length ? regionsArray : "",
        };
      });
      setLastFilteredField("regions");
    } else if (field === "location") {
      if (locationsArray.includes(value)) {
        locationsArray = locationsArray.filter(e => e != value);
      } else {
        locationsArray = value;
      }
      setSelectedFilters(prevSelectedFilters => {
        return {
          ...prevSelectedFilters,
          [field]:
            locationsArray && locationsArray.length ? locationsArray : "",
        };
      });
      setLastFilteredField("countries");
    } else if (field === "subsector") {
      setPreselectedSubsectors(
        typeof value === "string" ? value.split(",") : value,
      );
      setLastFilteredField("subsector");
    } else {
      setSelectedFilters(prevSelectedFilters => {
        return {
          ...prevSelectedFilters,
          [field]: event.target.value || "",
        };
      });
      setLastFilteredField(field);
    }
    setLastFilteredFieldValue(value);
  };
  const handleSectorsClose = () => {
    setSelectedFilters(prevSelectedFilters => {
      return {
        ...prevSelectedFilters,
        sector:
          preselectedSectors && preselectedSectors.length
            ? preselectedSectors
            : "",
      };
    });
  };
  const handleSubsectorsClose = () => {
    setSelectedFilters(prevSelectedFilters => {
      return {
        ...prevSelectedFilters,
        subsector:
          preselectedSubsectors && preselectedSubsectors.length
            ? preselectedSubsectors
            : "",
      };
    });
  };
  const handleSearchChange = event => {
    handleFieldChange(event, "search", event.target.value);
  };
  const handleIncludeKeywordsChange = event => {
    handleFieldChange(event, "includeKeywords", event.target.value);
  };
  const handleExcludeKeywordsChange = event => {
    handleFieldChange(event, "excludeKeywords", event.target.value);
  };
  const handleSectorChange = event => {
    if (event.target.value !== "Load full view") {
      handleFieldChange(event, "sector", event.target.value);
    }
  };
  const handleRegionChange = event => {
    if (event.target.value !== "Load full view") {
      handleFieldChange(event, "regions", event.target.value);
    }
  };
  const handleSubsectorChange = event => {
    if (event.target.value !== "Load full view") {
      handleFieldChange(event, "subsector", event.target.value);
    }
  };
  const handleStageChange = event => {
    if (event.target.value !== "Load full view") {
      handleFieldChange(event, "stage", event.target.value);
    }
  };
  const handleLocationChange = event => {
    if (event.target.value !== "Load full view") {
      handleFieldChange(event, "location", event.target.value);
    }
  };
  const handleFundingMinChange = event => {
    handleFieldChange(event, "fundingMin");
    let data = fundingMaxOptionsOriginal.filter(
      f => f.name > event.target.value,
    );
    setFundingOptionsMax(data);
  };
  const handleYearMinChange = event => {
    handleFieldChange(event, "yearMin");
    let data = yearInceptionMaxOptionsOriginal.filter(
      f => f.name > event.target.value,
    );
    setYearOptionsMax(data);
  };
  const handleMatchingScoreMinChange = event => {
    handleFieldChange(event, "matchingScoreMin");
    let data = matchingScoreMaxOptionsOriginal.filter(
      f => f.name > event.target.value,
    );
    setValuerScoreOptionsMax(data);
  };
  const handleTeamSizeMinChange = event => {
    handleFieldChange(event, "teamsizeMin");
    let data = teamSizeMaxOptionsOriginal.filter(
      (f, i) =>
        i >
        teamsizeOptions.findIndex(
          element => element.name === event.target.value,
        ),
    );
    setTeamSizeOptionsMax(data);
  };
  // END: filters fields changes

  const resetFilter = filterName => {
    setSelectedFilters(prevSelectedFilters => {
      return {
        ...prevSelectedFilters,
        [filterName]: "",
        ...(filterName === "sector" && { subsector: "" }),
        ...(filterName === "regions" && { location: "" }),
      };
    });
    if (filterName === "stage") {
      setLastFilteredField("investment_stages");
    } else if (filterName === "location") {
      setLastFilteredField("countries");
    } else {
      setLastFilteredField(filterName);
    }
    setLastFilteredFieldValue("");
  };

  const setModalFilters = () => {
    Object.keys(displayedFilters).forEach(filter => {
      setSelectedFilters(prevSelectedFilters => {
        return {
          ...prevSelectedFilters,
          [filter]: displayedFilters[filter] || "",
        };
      });
    });
  };

  const handleFilterRemove = filter => {
    setSelectedFilters(prevSelectedFilters => {
      return {
        ...prevSelectedFilters,
        [filter]: "",
      };
    });
    setDisplayedFilters(prevDisplayedFilters => {
      return {
        ...prevDisplayedFilters,
        [filter]: "",
      };
    });
    let val = filters.filter(f => f !== filter);
    resetFilter(filter);
    setFilters(val);
  };

  // Setting modalFilterNameValue while selecting filters in the filters modal
  useEffect(() => {
    let hasModalFilter = false;
    let value = "";
    if (
      JSON.stringify(prevSelectedFilters) !== JSON.stringify(selectedFilters)
    ) {
      Object.keys(selectedFilters).forEach(filter => {
        if (
          selectedFilters[filter] !== "" &&
          selectedFilters[filter] &&
          selectedFilters[filter].length !== 0 &&
          filter !== "search"
        ) {
          hasModalFilter = true;
          value = !!filterMapping[filter]
            ? value.concat(
                `${filterMapping[filter]}=${
                  selectedFilters[filter] === "10001+"
                    ? "10001%2B"
                    : selectedFilters[filter]
                }&`,
              )
            : value;
        }
      });
      const hasCurrentSearch =
        ((isIndustry || isTechnology) && !!companiesId) ||
        isProject ||
        (!!currentSearchSid && currentSearchSid !== "null");
      value = `${value}sort-direction=${sortDirection}&sort-by=${sortValue}&page=${page}&per-page=${perPage}${
        isIndustry || isTechnology || isProject
          ? ""
          : `&sid=${currentSearchSid}`
      }`;
      hasCurrentSearch && setModalFilterNameValue(value);
      hasModalFilter = false;
    }
  }, [selectedFilters, currentSearchSid, companiesId]);

  // Hook responsible for setting the filterNameValue state
  // - filterNameValue state is the url parameters string used for filtering request
  // - triggers if any of the following changes: displayedFilters, sortValue, sortDirection, page, perPage
  useEffect(() => {
    const displayedFiltersUpdated =
      JSON.stringify(getDisplayFilters) !==
      JSON.stringify(prevGetDisplayFilters);
    const urlParams = new URLSearchParams(search);
    let sid;
    if (token) {
      sid = urlParams.get("sid") || currentSearchSid;
    } else {
      sid = currentSearchSid || urlParams.get("sid");
    }
    if (
      !!displayedFiltersUpdated ||
      page !== prevPage ||
      perPage !== prevPerPage
    ) {
      // TODO@lex: check why getDisplayFilters.sector is undefined here
      if (getDisplayFilters.sector !== undefined) {
        setPreselectedSectors(getDisplayFilters.sector);
        setPreselectedSubsectors(getDisplayFilters.subsector);
      }
      if (!objectHasEmptyValues(selectedFilters)) {
        getFilterResultsTotalPages !== null &&
          setTotalPages(getFilterResultsTotalPages);
      } else {
        getSearchResultsTotalPages !== null &&
          setTotalPages(getSearchResultsTotalPages);
      }
      let value = "";
      Object.keys(getDisplayFilters).forEach(filter => {
        if (
          getDisplayFilters[filter] !== "" &&
          getDisplayFilters[filter] &&
          getDisplayFilters[filter].length !== 0
        ) {
          value = !!filterMapping[filter]
            ? value.concat(
                `${filterMapping[filter]}=${
                  getDisplayFilters[filter] === "10001+"
                    ? "10001%2B"
                    : getDisplayFilters[filter]
                }&`,
              )
            : value;
        }
      });
      const hasCurrentSearch =
        isIndustry || isTechnology || isProject || (!!sid && sid !== "null");
      value = `${value}sort-direction=${sortDirection}&sort-by=${sortValue}&page=${page}&per-page=${perPage}${
        isIndustry || isTechnology || isProject ? "" : `&sid=${sid}`
      }`;
      if (
        (hasCurrentSearch &&
          (prevCurrentSearchSid !== sid ||
            !!displayedFiltersUpdated ||
            !prevGetDisplayFilters)) ||
        page !== prevPage ||
        perPage !== prevPerPage
      ) {
        setFilterNameValue(
          value ||
            (getDisplayFilters.storageResultsFilterNameValue !== 1 &&
              getDisplayFilters.storageResultsFilterNameValue),
        );
      }
    }
  }, [
    getDisplayFilters,
    search,
    prevGetDisplayFilters,
    currentSearchSid,
    sortValue,
    sortDirection,
    page,
    prevPage,
    perPage,
    prevPerPage,
    slug,
    isIndustry,
    isTechnology,
    isProject,
  ]);

  // Hook responsible for triggering count request used in the filters modal
  // - triggers if modalFilterNameValue changes
  useEffect(() => {
    const keywordsTimeout = setTimeout(() => {
      const data = {
        sid: currentSearchSid,
        filterNameValue: modalFilterNameValue,
        orderBy:
          getDisplayFilters &&
          getDisplayFilters.sortValue &&
          getDisplayFilters.sortValue.slug
            ? getDisplayFilters.sortValue.slug
            : isIndustry || isTechnology || isProject
            ? "founded_date"
            : "degree_of_fit",
        orderDirection:
          getDisplayFilters && getDisplayFilters.sortDirection
            ? getDisplayFilters.sortDirection
            : "desc",
        perPage,
      };
      const hasCurrentSearch =
        isIndustry ||
        isTechnology ||
        isProject ||
        (!!currentSearchSid && currentSearchSid !== "null");
      if (isInitialMount.current) {
        isInitialMount.current = false;
      } else {
        if (
          hasCurrentSearch &&
          isAuthenticated &&
          postSearchStatus !== "REQUEST_SUCCESS" &&
          modalFilterNameValue &&
          prevModalFilterNameValue !== modalFilterNameValue &&
          filterNameValue !== modalFilterNameValue
        ) {
          dispatch(
            countRequest({
              slug,
              data,
              isSharedPageToken: token,
              searchTypeLook,
              lastFilteredField,
              lastFilteredFieldValue,
              filterStats: true,
            }),
          );
        }
      }
    }, 400);
    return () => {
      clearTimeout(keywordsTimeout);
    };
  }, [
    modalFilterNameValue,
    filterNameValue,
    prevModalFilterNameValue,
    currentSearchSid,
    isSharedPageToken,
  ]);

  // Hook responsible for triggering filtered request
  // - triggers if filterNameValue or isSharedPageToken changes
  useEffect(() => {
    if (!userLoading) {
      const urlParams = new URLSearchParams(filterNameValue);
      const orderBy = urlParams.get("sort-by");
      const orderDirection = urlParams.get("sort-direction");
      // const page = getUrlParam('page') ? getUrlParam('page').value : 1;
      // TODO consider if is ok to take this value from redux or we must use it only from urlParams
      const sid = urlParams.get("sid") || currentSearchSid;
      let data = {
        sid,
        filterNameValue,
        orderBy,
        orderDirection,
        page,
        perPage,
      };
      // const currentSearchUpdated = prevCurrentSearchSid !== currentSearchSid;
      const filterNameValueUpdated = prevFilterNameValue !== filterNameValue;
      // const perPageUpdated = prevPerPage !== perPage;
      const hasCurrentSearch =
        isIndustry || isTechnology || isProject || (!!sid && sid !== "null");
      const isNotNewSearch = postSearchStatus !== "REQUEST_SUCCESS";
      if (
        (token && !isAuthenticated) ||
        (!!filterNameValue &&
          !!isNotNewSearch &&
          !!hasCurrentSearch &&
          (!!filterNameValueUpdated || !prevFilterNameValue) &&
          (isIndustry || isTechnology
            ? !!companiesId
            : !isProject
            ? !!sid
            : true) &&
          !!sid)
      ) {
        !isProject && !isLikedCompanies && fetchYearsChartData(sid);
        !isProject && !isLikedCompanies && fetchTeamSizeChartData(sid);
        dispatch(
          request({
            slug,
            data,
            isSharedPageToken: token,
            searchTypeLook: searchTypeLook,
            filterStats: true,
          }),
        );
      }
    }
  }, [
    dispatch,
    request,
    page,
    filterNameValue,
    prevFilterNameValue,
    isSharedPageToken,
  ]);

  useEffect(() => {
    const displayedFiltersUpdated =
      JSON.stringify(getDisplayFilters) !==
      JSON.stringify(prevGetDisplayFilters);
    if (!!displayedFiltersUpdated) {
      setDisplayedFilters(
        !objectHasEmptyValues({
          sector: getDisplayFilters.sector,
          subsector: getDisplayFilters.subsector,
          subsectors: getDisplayFilters.subsectors,
          stage: getDisplayFilters.stage,
          fundingMin: getDisplayFilters.fundingMin,
          fundingMax: getDisplayFilters.fundingMax,
          teamsizeMin: getDisplayFilters.teamsizeMin,
          teamsizeMax: getDisplayFilters.teamsizeMax,
          location: getDisplayFilters.location,
          yearMin: getDisplayFilters.yearMin,
          yearMax: getDisplayFilters.yearMax,
          matchingScoreMin: getDisplayFilters.matchingScoreMin,
          matchingScoreMax: getDisplayFilters.matchingScoreMax,
          regions: getDisplayFilters.regions,
          search: getDisplayFilters.search,
          excludeKeywords: getDisplayFilters.excludeKeywords,
          includeKeywords: getDisplayFilters.includeKeywords,
        })
          ? {
              sector: getDisplayFilters.sector || "",
              subsector: getDisplayFilters.subsector || "",
              subsectors: getDisplayFilters.subsectors || "",
              stage: getDisplayFilters.stage || "",
              fundingMin: getDisplayFilters.fundingMin || "",
              fundingMax: getDisplayFilters.fundingMax || "",
              teamsizeMin: getDisplayFilters.teamsizeMin || "",
              teamsizeMax: getDisplayFilters.teamsizeMax || "",
              location: getDisplayFilters.location || "",
              yearMin: getDisplayFilters.yearMin || "",
              yearMax: getDisplayFilters.yearMax || "",
              matchingScoreMin: getDisplayFilters.matchingScoreMin || "",
              matchingScoreMax: getDisplayFilters.matchingScoreMax || "",
              regions: getDisplayFilters.regions || "",
              search: getDisplayFilters.search || "",
              excludeKeywords: getDisplayFilters.excludeKeywords || "",
              includeKeywords: getDisplayFilters.includeKeywords || "",
            }
          : selectedFiltersEmptyData,
      );
    }
  }, [getDisplayFilters, prevGetDisplayFilters]);

  const handleShowResults = () => {
    let value = "";
    Object.keys(selectedFilters).forEach(filter => {
      if (
        selectedFilters[filter] !== "" &&
        selectedFilters[filter] &&
        selectedFilters[filter].length !== 0
      ) {
        value = !!filterMapping[filter]
          ? value.concat(
              `${filterMapping[filter]}=${
                selectedFilters[filter] === "10001+"
                  ? "10001%2B"
                  : selectedFilters[filter]
              }&`,
            )
          : value;
      }
    });
    dispatch(
      resultsActions.setResultsDisplayFilters({
        sid: isIndustry || isTechnology || isProject ? slug : currentSearchSid,
        displayedFilters: {
          filterSid:
            isIndustry || isTechnology || isProject ? slug : currentSearchSid,
          sortValue: sortValue || [],
          sortDirection: sortDirection || [],
          sector: selectedFilters.sector || [],
          subsector: selectedFilters.subsector || [],
          subsectors: selectedFilters.subsectors || [],
          stage: selectedFilters.stage || [],
          fundingMin: selectedFilters.fundingMin || "",
          fundingMax: selectedFilters.fundingMax || "",
          teamsizeMin: selectedFilters.teamsizeMin || "",
          teamsizeMax: selectedFilters.teamsizeMax || "",
          location: selectedFilters.location || [],
          yearMin: selectedFilters.yearMin || "",
          yearMax: selectedFilters.yearMax || "",
          matchingScoreMin: selectedFilters.matchingScoreMin,
          matchingScoreMax: selectedFilters.matchingScoreMax || "",
          regions: selectedFilters.regions || [],
          search: selectedFilters.search || "",
          excludeKeywords: selectedFilters.excludeKeywords || "",
          includeKeywords: selectedFilters.includeKeywords || "",
          storageResultsFilterNameValue: value || "",
          storageResultsFilterPage: Number(page) || 1,
        },
      }),
    );
  };

  // on click of show results button it sets filters
  const handleShowFilters = value => {
    let temp = [];
    for (const key in value) {
      if (value[key] && Object.keys(value[key]).length > 0) {
        temp.push(value[key]);
      }
    }
    value.teamsizeMin.length === 0 && resetFilter("teamsizeMin");
    value.teamsizeMax.length === 0 && resetFilter("teamsizeMax");
    setFilters(temp);
  };

  const handleSortChange = (sortValue, sortDirection) => {
    setSortValue(sortValue);
    setSortDirection(sortDirection);
    setPage(
      getUrlParam("page")
        ? getUrlParam("page").value
        : getDisplayFilters.storageResultsFilterPage
        ? getDisplayFilters.storageResultsFilterPage
        : 1,
    );
    dispatch(
      resultsActions.setResultsDisplayFilter({
        sid: isIndustry || isTechnology || isProject ? slug : currentSearchSid,
        displayedFilterName: "sortDirection",
        displayedFilterValue: sortDirection,
      }),
    );
    dispatch(
      resultsActions.setResultsDisplayFilter({
        sid: isIndustry || isTechnology || isProject ? slug : currentSearchSid,
        displayedFilterName: "sortValue",
        displayedFilterValue: sortValue,
      }),
    );
  };

  return {
    filters,
    setFilters,
    selectedFilters,
    setDisplayedFilters,
    filterNameValue,
    setFilterNameValue,
    modalFilterNameValue,
    setModalFilterNameValue,
    resetFilters,
    handleSearchChange,
    handleExcludeKeywordsChange,
    handleIncludeKeywordsChange,
    handleSectorChange,
    handleRegionChange,
    handleSubsectorChange,
    handleLocationChange,
    handleFundingMinChange,
    handleYearMinChange,
    handleMatchingScoreMinChange,
    handleTeamSizeMinChange,
    resetFilter,
    setModalFilters,
    setSelectedFilters,
    displayedFilters,
    fundingOptionsMax,
    valuerScoreOptionsMax,
    teamSizeOptionsMax,
    yearOptionsMax,
    handleFieldChange,
    handleFilterRemove,
    handleShowResults,
    handleShowFilters,
    isFilterFetching,
    setIsFilterFetching,
    handleSortChange,
    setPage,
    page,
    sortValue,
    sortDirection,
    handleStageChange,
    handleSectorsClose,
    handleSubsectorsClose,
    preselectedSectors,
    setPreselectedSectors,
    preselectedSubsectors,
    setPreselectedSubsectors,
    filterMapping,
  };
};

export default useFilters;
