import React, { createRef, useEffect, useState } from "react";
import { useStyles } from "./style.js";
import { useHistory, useLocation } from "react-router-dom";
import { useLockedPageHook } from "./LockedPage/hooks/useLockedPageHook.js";
// UI components
import Header from "./Header/Header.js";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "../../components/Typography/Typography";
import Profile from "./ProfilePages/Profile/Profile";
import ExecutiveTeam from "./ProfilePages/ExecutiveTeam/ExecutiveTeam";
import Success from "./ProfilePages/Success/Success.js";
import Comments from "./ProfilePages/Comments/Comments.js";
import LockAlert from "../../components/LockAlert/LockAlert.js";
import EnrichRequestModal from "./LockedPage/EnrichRequestModal/EnrichRequestModal.js";
import TopUpCreditsModal from "./LockedPage/TopUpCreditsModal/TopUpCreditsModal.js";
import NotEnoughCreditsModal from "./LockedPage/NotEnoughCreditsModal/NotEnoughCreditsModal.js";
import OrderReceivedModal from "./LockedPage/OrderReceivedModal/OrderReceivedModal.js";
import CreateProjectModal from "../Results/components/modals/CreateProjectModal.js";
import ContentLoader from "../../components/ContentLoader/ContentLoader";
import { UserProvider } from "../Layout/UserProvider";
//Assest
import Link from "../../components/Link/Link.js";
import Tag from "../../components/Tag/Tag.js";
import classnames from "classnames";
import PageNotFound from "../../assets/icons/PageNotFound.png";
import { ReactComponent as NoImagePlaceholder } from "../../assets/icons/Company.svg";
// Hooks
import { useAppEventTracking } from "../../common/useAppEventTracking";
import { useRouterHook } from "../../common/useRouterHook";
import usePrevious from "../../common/usePrevious";
// Constants
import { useCompanyProfileHook } from "./hooks/useCompanyProfileHook.js";
import SuggestedCompanies from "./SuggestedCompanies/SuggestedCompanies.js";
import {
  REQUEST_FAILURE,
  REQUEST_PENDING,
  REQUEST_SUCCESS,
  REQUEST_UNDEFINED,
} from "../../constants/statuses.js";
import { getPricingPage } from "../../constants/endpoints";
//Redux
import {
  fetchCompanyRequest,
  fetchResultsRequest,
} from "../../store/results/requests";
import { getUpdateProfileStatusSelector } from "../../store/auth/selectors";
import { checkProjectsRequest } from "../../store/projects/requests";
import {
  getSharedModelRequest,
  getSuggestedCompaniesRequest,
} from "../../store/companyProfile/requests";
import { useSelector } from "react-redux";
import {
  getSuggestedCompaniesStateSelector,
  getSuggestedCompaniesStatusSelector,
} from "../../store/companyProfile/selectors.js";
import { actions as resultsActions } from "../../store/results/slice";
import Button from "../../components/Button/Button";
import { ReactComponent as DeepDive } from "../../assets/icons/deepDive.svg";

const CompanyProfile = ({
  isPreview = window.location.pathname === "/companies/enriched/company",
  source = "result",
}) => {
  const history = useHistory();
  const [pendingSearchResults, setPendingSearchResults] = useState(false);
  const isSharedResultsPageToken = localStorage.getItem(
    "isSharedResultsPageToken",
  );
  const { openPage } = useRouterHook();
  // const [tabView, setTabView] = useState('profile');
  const classes = useStyles();
  const descRef = createRef(null);
  const [descHeight, setDescHeight] = useState(0);
  const [descExpanded, setDescExpanded] = useState(false);
  // const [showMatchTooltip, setShowMatchTooltip] = useState(false);
  // const [isFromProject, setIsFromProject] = useState(false);
  const [imageSrc, setImageSrc] = useState(NoImagePlaceholder);
  const [totalFunding, setTotalFunding] = useState(null);
  const [isGetPricingPageTriggered, setIsGetPricingPageTriggered] =
    useState(false);
  const [
    handleShowEnrichRequestModalTriggered,
    setHandleShowEnrichRequestModalTriggered,
  ] = useState(false);
  let historyFrom = sessionStorage.getItem("history");
  // const searchesSelector = useSelector(getSearchesSelector);
  // const subscription = useSelector(getSubscriptionSelector());
  const getUpdateProfileStatus = useSelector(getUpdateProfileStatusSelector);
  const { mixpanelTrackEvent } = useAppEventTracking();
  let { search } = useLocation();
  const prevSearch = usePrevious(search);
  const {
    t,
    company,
    dispatch,
    getMeStatus,
    isAuthenticated,
    user,
    // isGuestUser,
    isExpiredUser,
    isPayingUser,
    postSearchResponse,
    searchResultsStatus,
    getCompanyStatus,
    allResults,
    sharedModel,
    postSharedLinkStatus,
    addTagStatus,
    selectTagStatus,
    deleteTagStatus,
    setCompanyResult,
    degree_of_fit,
    website,
    total_funding_amount_currency,
    total_funding_amount_dollars,
    hq_country,
    description,
    sectors,
    subsectors,
    enrichment_request,
    founded_date,
    hq_city,
    formatNumber,
    activeTab,
    handleTabSelected,
    openTab,
    companyResult,
    setUserLike,
    setUserDislike,
    setActiveTab,
    ready_for_release,
    voters,
    setVotersUp,
    setVotersDown,
    setTotalComments,
    setCompany,
    handleClickSeeLookALike,
    postProjectStatus,
    slug: hookSlug,
  } = useCompanyProfileHook({
    source: search.lastIndexOf("?sharedLink=") === 0 ? "shared" : source,
    search,
  });

  const [slug, setSlug] = useState(hookSlug);

  useEffect(() => {
    setSlug(hookSlug);
  }, [hookSlug]);

  // if trying to use route for company preview
  if (!slug) {
    setSlug("clearblade");
  }
  const {
    getCredits,
    getUser,
    getCheckedProjectsStatusSelector,
    setCredits,
    setCheckedProjects,
    createProjectModalShow,
    enrichRequestModalShow,
    notEnoughCreditsModalShow,
    topUpCreditsModalShow,
    showEnrichSuccess,
    handleClosingCreateProjectModal,
    isEnrichRequestSent,
    handleClosingTopUpCreditsDialog,
    handleClosingNotEnoughCreditsDialog,
    handleTopUp,
    handleBuyingTopUpCreditsDialog,
    handleClosingEnrichDialog,
    showEnrichRequestModal,
    handleCreateProjectDialogOpened,
    handleNewProjectCreated,
    handleEnrichSuccess,
    handleClosingSuccess,
    pathname,
    credits,
    getCheckedProjects,
    checkedProjects,
    location,
  } = useLockedPageHook({ company, type: "company" });
  const query = new URLSearchParams(search);
  const cid = query.get("cid");

  const [success, setSuccess] = useState(
    searchResultsStatus === REQUEST_SUCCESS ||
      getCompanyStatus === REQUEST_SUCCESS ||
      postSharedLinkStatus === REQUEST_SUCCESS,
  );
  useEffect(() => {
    getCredits
      ? setCredits(getCredits)
      : getUser &&
        getUser.team &&
        getUser.team.credits &&
        setCredits(getUser.team.credits);
  }, [getCredits, getUser, setCredits]);

  useEffect(() => {
    if (
      !enrichRequestModalShow &&
      (pathname.includes("success=true") || search.includes("success=true")) &&
      credits > 0 &&
      company &&
      company.id &&
      !isEnrichRequestSent()
    ) {
      showEnrichRequestModal(null, company);
    }
  }, [
    location,
    company,
    showEnrichRequestModal,
    enrichRequestModalShow,
    credits,
    isEnrichRequestSent,
    pathname,
    search,
  ]);

  useEffect(() => {
    postProjectStatus === REQUEST_SUCCESS &&
      dispatch(
        checkProjectsRequest({
          companyId: company.id,
          type: "company",
        }),
      );
  }, [postProjectStatus]);

  useEffect(() => {
    getCheckedProjectsStatusSelector === REQUEST_SUCCESS &&
      setCheckedProjects(getCheckedProjects);
  }, [getCheckedProjectsStatusSelector, getCheckedProjects]);

  useEffect(() => {
    const data =
      window.location.pathname === "/companies/enriched/company"
        ? {}
        : {
            slug,
            cid: cid,
          };
    if (
      (getMeStatus === REQUEST_SUCCESS || getMeStatus === REQUEST_FAILURE) &&
      search.lastIndexOf("?sharedLink=") !== 0 &&
      !isAuthenticated
    ) {
      openPage("/sign-in");
    }
    if (
      (getMeStatus === REQUEST_SUCCESS || getMeStatus === REQUEST_FAILURE) &&
      (source === "result" || slug === "enriched") &&
      (search !== prevSearch ||
        (!companyResult &&
          getCompanyStatus !== REQUEST_PENDING &&
          getCompanyStatus !== REQUEST_FAILURE))
    ) {
      if (search.lastIndexOf("?sharedLink=") === 0) {
        const sharedLinkToken = search.substring(12, search.length);
        dispatch(getSharedModelRequest({ token: sharedLinkToken }));
      } else {
        if (isAuthenticated) {
          dispatch(fetchCompanyRequest({ data }));
        } else {
          if (
            isSharedResultsPageToken === "undefined" ||
            isSharedResultsPageToken === null
          ) {
            if (
              historyFrom === "results" ||
              historyFrom === "companies-look-alike"
            ) {
              if (slug === "enriched") {
                dispatch(fetchCompanyRequest({ data }));
              } else {
                fetchResultsRequest({
                  data: { postSearchResponse },
                });
              }
            } else {
              let dataParam;
              if (slug !== "enriched") {
                dataParam = { slug, cid, ...data };
              } else {
                dataParam = data;
              }
              dispatch(fetchCompanyRequest({ data: dataParam }));
            }
          } else {
            if (
              historyFrom === "results" ||
              historyFrom === "companies-look-alike"
            ) {
              dispatch(
                fetchResultsRequest({
                  data: { postSearchResponse },
                  isSharedPageToken: isSharedResultsPageToken,
                }),
              );
            } else {
              let dataParam;
              if (slug !== "enriched") {
                dataParam = { slug, cid, postSearchResponse };
              } else {
                dataParam = { postSearchResponse };
              }
              dispatch(
                fetchCompanyRequest({
                  data: dataParam,
                  isSharedPageToken: isSharedResultsPageToken,
                }),
              );
            }
          }
        }
      }
    }
  }, [
    dispatch,
    search,
    prevSearch,
    slug,
    isAuthenticated,
    getMeStatus,
    postSearchResponse,
    cid,
    companyResult,
    getCompanyStatus,
    source,
    isSharedResultsPageToken,
    historyFrom,
  ]);

  useEffect(() => {
    if (
      postSharedLinkStatus === REQUEST_SUCCESS &&
      sharedModel.slug === company.slug
    ) {
      setCompanyResult(sharedModel);
    }
    if (
      (getMeStatus === REQUEST_SUCCESS || getMeStatus === REQUEST_FAILURE) &&
      (getCompanyStatus === REQUEST_SUCCESS ||
        searchResultsStatus === REQUEST_SUCCESS)
    ) {
      if (Object.keys(allResults).length !== 0) {
        setCompanyResult(
          Object.values(allResults).find(
            result => result.slug === company.slug,
          ),
        );
      } else if (company && Object.keys(company).length !== 0) {
        setCompanyResult(company);
      }
    }
  }, [
    setCompanyResult,
    isAuthenticated,
    company,
    allResults,
    searchResultsStatus,
    getCompanyStatus,
    getMeStatus,
    sharedModel,
    postSharedLinkStatus,
  ]);

  //Update company tags
  useEffect(() => {
    const data =
      window.location.pathname === "/companies/enriched/company"
        ? {}
        : {
            slug,
            cid: cid,
          };
    if (
      addTagStatus === REQUEST_SUCCESS ||
      deleteTagStatus === REQUEST_SUCCESS ||
      selectTagStatus === REQUEST_SUCCESS
    ) {
      dispatch(fetchCompanyRequest({ data }));
    }
  }, [addTagStatus, deleteTagStatus, selectTagStatus, dispatch, search, slug]);

  useEffect(() => {
    if (companyResult && companyResult.user_vote === 1) {
      setUserLike(true);
      setUserDislike(false);
    } else if (companyResult && companyResult.user_vote === 0) {
      setUserLike(false);
      setUserDislike(true);
    }
  }, [companyResult, setUserLike, setUserDislike]);

  useEffect(() => {
    if (
      companyResult &&
      companyResult.slug !== null &&
      companyResult &&
      companyResult.slug !== undefined
    ) {
      //Open comments tab from results page
      if (openTab) {
        setActiveTab(openTab);
      }
    }
  }, [companyResult, setActiveTab, openTab]);
  useEffect(() => {
    if (openTab) {
      let cmp = localStorage.getItem("companyInfo");
      cmp = cmp && JSON.parse(cmp);
      cmp && setCompany(cmp);
    }
  }, [openTab]);

  useEffect(() => {
    const isGuestAndNoAccess =
      !user && (!companyResult || (companyResult && !companyResult.name));
    if (searchResultsStatus === REQUEST_PENDING) {
      setPendingSearchResults(true);
    }
    if (
      pendingSearchResults &&
      isGuestAndNoAccess &&
      (searchResultsStatus === REQUEST_SUCCESS ||
        getCompanyStatus === REQUEST_SUCCESS ||
        postSharedLinkStatus === REQUEST_SUCCESS) &&
      !isSharedResultsPageToken
    ) {
      history.replace("/sign-in", {
        from: pathname + search,
        someOtherProp: "",
      });
    }
  }, [
    user,
    companyResult,
    searchResultsStatus,
    getCompanyStatus,
    postSharedLinkStatus,
  ]);

  // const [success, setSuccess] = useState(searchResultsStatus === REQUEST_SUCCESS || getCompanyStatus === REQUEST_SUCCESS || postSharedLinkStatus === REQUEST_SUCCESS);

  useEffect(() => {
    setSuccess(
      searchResultsStatus === REQUEST_SUCCESS ||
        getCompanyStatus === REQUEST_SUCCESS ||
        postSharedLinkStatus === REQUEST_SUCCESS,
    );
  }, [searchResultsStatus, getCompanyStatus, postSharedLinkStatus]);

  useEffect(() => {
    if (voters) {
      voters.up && setVotersUp(voters.up);
      voters.down && setVotersDown(voters.down);
    } else if (Object.keys(sharedModel).length > 0) {
      sharedModel.voters.up && setVotersUp(sharedModel.voters.up);
      sharedModel.voters.down && setVotersDown(sharedModel.voters.down);
    }
  }, [voters, sharedModel]);

  const handleOnCommentsCount = value => {
    setTotalComments(value);
  };

  ////////////////////////

  useEffect(() => {
    success &&
      descRef &&
      descRef.current &&
      setDescHeight(descRef.current.offsetHeight);
  }, [success, descRef]);

  const handleShowMore = () => {
    setDescExpanded(!descExpanded);
  };

  // const handleMatchTooltipClick = () => {
  //     document.body.offsetWidth < 1024 && setShowMatchTooltip(true);
  // }

  // const handleMatchTooltipClose = () => {
  //     document.body.offsetWidth < 1024 && setShowMatchTooltip(false);
  // }

  // const handleMatchTooltipMouseEnter = () => {
  //     document.body.offsetWidth > 1025 && setShowMatchTooltip(true);
  // }

  // const handleMatchTooltipMouseLeave = () => {
  //     document.body.offsetWidth > 1025 && setShowMatchTooltip(false);
  // };

  const handleSeeLookALike = () => {
    let data = {
      name: company && company.name,
      description:
        company && company.description ? company.description : description,
      website: company && company.website ? company.website : website,
    };
    handleClickSeeLookALike(data);
  };

  // const [showInfoDescriptionTooltip, setShowInfoDescriptionTooltip] = useState(false);
  // const handleInfoDescriptionTooltipClick = () => document.body.offsetWidth < 1024 && setShowInfoDescriptionTooltip(true);
  // const handleInfoDescriptionTooltipClose = () => document.body.offsetWidth < 1024 && setShowInfoDescriptionTooltip(false);
  // const handleInfoDescriptionTooltipMouseEnter = () => document.body.offsetWidth > 1025 && setShowInfoDescriptionTooltip(true);
  // const handleInfoDescriptionTooltipMouseLeave = () => document.body.offsetWidth > 1025 && setShowInfoDescriptionTooltip(false);

  // const openCompanyComments = () => {
  //     handleOpenComments();
  // };
  // const handleOnCommentsCount = value => {
  //     onCommentsCount(value)
  // }
  const [oldLocationKey, setOldLocationKey] = useState(null);

  useEffect(() => {
    // TODO@jkica: figure out if this can be removed
    // setIsFromProject(localStorage.getItem("is_from_project"), localStorage.removeItem("is_from_project"));
    // dispatch(similarCompanieActions.clearSimilarCompanies());
    let s = sessionStorage.getItem("closedBannerCP");
    setSessionCloseAlert(s === "companyProfile");
    setOldLocationKey(location && location.key);
    window.scrollTo(0, 0);
    return () => {
      dispatch(resultsActions.clearCompany());
    };
    // dispatch(similarCompanieActions.getSimilarCompaniesUndefined());
  }, []);

  // const handleOnClose = () => {
  //     sessionStorage.setItem("closedBannerCP", "companyProfile");
  //     let s = sessionStorage.getItem("closedBannerCP");
  //     setSessionCloseAlert(s === 'companyProfile');
  // };
  // const [showLimitAlert, setShowLimitAlert] = useState(false);
  const [sessionCloseAlert, setSessionCloseAlert] = useState(false);
  // useEffect(() => {
  //     setShowLimitAlert(!ready_for_release && company && !sessionCloseAlert)
  // }, [ready_for_release, company, sessionCloseAlert]);

  useEffect(() => {
    // TODO@jkica: figure out if this can be removed
    if (oldLocationKey && oldLocationKey !== location.key) {
      // dispatch(similarCompanieActions.clearSimilarCompanies());
      window.location.reload();
    }
  }, [location, oldLocationKey]);

  const suggestedCompaniesStatus = useSelector(
    getSuggestedCompaniesStatusSelector,
  );
  const suggestedCompanies = useSelector(getSuggestedCompaniesStateSelector);

  useEffect(() => {
    dispatch(getSuggestedCompaniesRequest({ data: {} }));
  }, [dispatch]);

  //Determine from where to show funding amount, ml or company
  useEffect(() => {
    if (
      company &&
      (ready_for_release || isPreview) &&
      company.rounds &&
      company.rounds.length
    ) {
      setTotalFunding(company.total_funding_amount_dollars);
    } else if (total_funding_amount_dollars) {
      setTotalFunding(total_funding_amount_dollars);
    }
  }, [total_funding_amount_dollars, company]);

  useEffect(() => {
    if (getUpdateProfileStatus === REQUEST_SUCCESS) {
      if (isGetPricingPageTriggered) {
        window.location.href = getPricingPage(!!user && "free");
      } else if (handleShowEnrichRequestModalTriggered) {
        showEnrichRequestModal(null, company);
      }
    }
  }, [getUpdateProfileStatus]);

  // const goToPricingPage = () => {
  //     if (user && !user.company && (subscription && (subscription.name === "free" || subscription.free_trial)) && searchesSelector.length > 1) {
  //         dispatch(authActions.completeProfileModalShowTrue());
  //         setIsGetPricingPageTriggered(true);
  //     } else {
  //         window.location.href = getPricingPage(!!user && "free");
  //     }
  // }

  // const handleShowEnrichRequestModal = () => {
  //     if (user && !user.company && (subscription && (subscription.name === "free" || subscription.free_trial)) && searchesSelector.length > 1) {
  //         dispatch(authActions.completeProfileModalShowTrue());
  //         setHandleShowEnrichRequestModalTriggered(true);
  //     } else {
  //         showEnrichRequestModal();
  //     }
  // }

  const [scoreDegree, setScoreDegree] = useState();

  useEffect(() => {
    if (degree_of_fit * 100 < 40) {
      setScoreDegree("weak");
    } else if (degree_of_fit * 100 >= 40 && degree_of_fit * 100 < 60) {
      setScoreDegree("good");
    } else {
      setScoreDegree("strong");
    }
  }, [degree_of_fit]);

  const handleClickOnPreviewExample = () => {
    mixpanelTrackEvent("Full company profile preview", {
      "From company": company.name,
    });
  };

  return (
    <UserProvider>
      {success && !!companyResult ? (
        <div className={classes.root}>
          {companyResult && (
            <div className={classes.headerWrapper}>
              <Header
                isPreview={isPreview}
                companyState={company}
                getUser={user}
                company={companyResult}
              />
            </div>
          )}
          {company !== null ? (
            <div>
              <div
                className={classnames([
                  classes.flexCenter,
                  classes.flexColumn,
                ])}>
                <div className={classes.imgWrap}>
                  {(ready_for_release || isPreview) && company?.icon ? (
                    <img
                      alt={company.name}
                      className={classes.profileImage}
                      src={company.icon}></img>
                  ) : (
                    <Tooltip
                      title={
                        <Typography color="white" variant="body2">
                          {t("companyProfile.companyLogoInfo")}
                        </Typography>
                      }
                      arrow>
                      <NoImagePlaceholder
                        className={classes.imagePlaceholder}
                      />
                    </Tooltip>
                  )}
                </div>
                <Typography variant="subtitle1" component="div">
                  {company?.name}
                </Typography>
              </div>
              <div className={classes.tabLinksWrapper}>
                <div
                  className={classes.tabLinks}
                  data-intercom-target="Company navigation tabs">
                  <div
                    className={classnames([
                      classes.tabLink,
                      activeTab === "profile" ? classes.activeTabLink : "",
                    ])}
                    onClick={() => handleTabSelected("profile")}
                    data-intercom-target="Profile section tab">
                    <Typography
                      variant="bodyXs"
                      component="div"
                      className={classes.tabLinkText}>
                      Profile
                    </Typography>
                  </div>
                  <div
                    className={classnames([
                      classes.tabLink,
                      activeTab === "executive" ? classes.activeTabLink : "",
                    ])}
                    onClick={() => handleTabSelected("executive")}
                    data-intercom-target="Executive team section tab">
                    <Typography
                      variant="bodyXs"
                      component="div"
                      className={classes.tabLinkText}>
                      Executive team
                    </Typography>
                  </div>
                  <div
                    className={classnames([
                      classes.tabLink,
                      activeTab === "success" ? classes.activeTabLink : "",
                    ])}
                    onClick={() => handleTabSelected("success")}
                    data-intercom-target="Success section tab">
                    <Typography
                      variant="bodyXs"
                      component="div"
                      className={classes.tabLinkText}>
                      Success
                    </Typography>
                  </div>
                  {!isPreview && (
                    <div
                      className={classnames([
                        classes.tabLink,
                        activeTab === "comments" && !isPreview
                          ? classes.activeTabLink
                          : "",
                      ])}
                      onClick={() =>
                        !isPreview && handleTabSelected("comments")
                      }
                      data-intercom-target="Comments section tab">
                      <Typography
                        variant="bodyXs"
                        component="div"
                        className={classes.tabLinkText}>
                        Comments
                      </Typography>
                    </div>
                  )}
                </div>
              </div>
              {activeTab === "profile" && companyResult && (
                <div
                  className={classes.main}
                  data-intercom-target="Company summary">
                  <div className={classes.wrap}>
                    <div
                      className={classnames([
                        classes.nameImageWrap,
                        {
                          [classes.nameImageWrapPartialWidth]: !!degree_of_fit,
                          [classes.nameImageWrapFullWidth]: !degree_of_fit,
                        },
                      ])}>
                      <div className={classes.flexSpaceStart}>
                        <div className={classes.flexColumn}>
                          <div className={classes.technologies}>
                            <div className={classes.flexRow}>
                              <Tag
                                className={classes.tag}
                                variant="lightGreen"
                                clickable={false}>
                                <Typography
                                  variant="caption"
                                  component="span"
                                  className={classes.mono}>
                                  Company
                                </Typography>
                              </Tag>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* TODO: implement when rdy */}
                    {!isPreview && company?.projects?.length ? (
                      <div className={classes.savedIn}>
                        <div className={classes.savedInBlock}>
                          <Typography
                            variant="bodyButton"
                            component="div"
                            align="right">
                            Saved in:{" "}
                            {company?.projects?.map((project, i) => (
                              <>
                                <Typography
                                  variant="bodyButton"
                                  color="primaryDark"
                                  className={classnames([
                                    classes.link,
                                    classes.savedInLink,
                                  ])}
                                  component="span"
                                  onClick={() =>
                                    openPage(`/projects/${project.slug}`)
                                  }>
                                  {project.title}
                                </Typography>
                                {company?.projects?.length > 1 &&
                                  i < company?.projects?.length - 1 &&
                                  ", "}
                              </>
                            ))}
                          </Typography>
                        </div>
                      </div>
                    ) : null}

                    <div
                      className={classnames([
                        classes.descriptionBox,
                        {
                          [classes.descriptionBoxProject]:
                            historyFrom !== "results" &&
                            historyFrom !== "companies-look-alike",
                        },
                      ])}
                      ref={descRef}
                      data-intercom-target="Company description section">
                      <Typography variant="body" component="div">
                        <div
                          className={classnames([
                            {
                              [classes.descriptionExpanded]: descExpanded,
                              [classes.description]: !descExpanded,
                            },
                          ])}
                          dangerouslySetInnerHTML={{
                            __html:
                              (ready_for_release || isPreview) &&
                              company &&
                              company.description
                                ? company.description
                                : description,
                          }}></div>
                      </Typography>
                      {descHeight > 48 && (
                        <div
                          className={classnames([classes.showMore, "laptop"])}>
                          <Typography
                            variant="bodySmall"
                            color="textLighter"
                            onClick={handleShowMore}>
                            {descExpanded
                              ? t("companyProfile.showLess")
                              : t("companyProfile.showMore")}
                          </Typography>
                        </div>
                      )}
                    </div>
                    <div className={classes.descriptionScores}>
                      <div className={classes.scores}>
                        <div
                          className={classnames([
                            classes.scoresRows,
                            classes.flexSpaceBetween,
                            classes.flexRow,
                          ])}>
                          <div className={classnames([classes.scoreRow])}>
                            <Typography
                              className={classes.infoValue}
                              variant="body2">
                              {t("companyProfile.sectors")}:
                            </Typography>
                            <div>
                              {(ready_for_release || isPreview) &&
                              company &&
                              company.sectors ? (
                                <>
                                  {company.sectors.map((sector, index) => {
                                    return (
                                      <Typography
                                        key={`sector-${index}`}
                                        className={classnames([
                                          classes.infoTitle,
                                          classes.mono,
                                        ])}
                                        variant="body2">
                                        {sector}
                                        {index + 1 < company.sectors.length &&
                                          ", "}
                                      </Typography>
                                    );
                                  })}
                                </>
                              ) : (
                                <>
                                  {sectors &&
                                    sectors.map((sector, index) => {
                                      return (
                                        <Typography
                                          key={`company-sector-${index}`}
                                          className={classnames([
                                            classes.infoTitle,
                                            classes.mono,
                                          ])}
                                          variant="body2">
                                          {typeof sector === "object" ? (
                                            <>{sector.name}</>
                                          ) : (
                                            <>{sector}</>
                                          )}
                                          {index + 1 < sectors.length && ", "}
                                        </Typography>
                                      );
                                    })}
                                </>
                              )}
                            </div>
                          </div>
                          <div className={classnames([classes.scoreRow])}>
                            <Typography
                              className={classes.infoValue}
                              variant="body2">
                              {t("companyProfile.fundingRaised")}:
                            </Typography>
                            <Typography
                              className={classnames([
                                classnames([classes.infoTitle, classes.mono]),
                                classes.money,
                              ])}
                              variant="body2">
                              {(ready_for_release || isPreview) &&
                              company &&
                              company.funding
                                ? isNaN(company.funding)
                                  ? company.funding
                                  : formatNumber(company.funding)
                                : total_funding_amount_dollars
                                ? parseInt(total_funding_amount_dollars)
                                    .toFixed()
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                                  " USD"
                                : "N/a"}
                            </Typography>
                          </div>
                        </div>
                        <div
                          className={classnames([
                            classes.scoresRows,
                            classes.basicInfo,
                            classes.flexSpaceBetween,
                            classes.flexRow,
                          ])}>
                          <div className={classnames([classes.scoreRow])}>
                            <Typography
                              className={classes.infoValue}
                              variant="body2">
                              {t("companyProfile.subsectors")}:
                            </Typography>
                            <div>
                              {(ready_for_release || isPreview) &&
                              company &&
                              company.subsectors ? (
                                <>
                                  {company.subsectors &&
                                  company.subsectors.length ? (
                                    company.subsectors.map(
                                      (subsector, index) => {
                                        return (
                                          <Typography
                                            key={`${subsector}-${index}`}
                                            className={classnames([
                                              classes.infoTitle,
                                              classes.mono,
                                            ])}
                                            variant="body2">
                                            {subsector}
                                            {index + 1 <
                                              company.subsectors.length && ", "}
                                          </Typography>
                                        );
                                      },
                                    )
                                  ) : (
                                    <Typography
                                      className={classnames([
                                        classes.infoTitle,
                                        classes.mono,
                                      ])}
                                      variant="body2">
                                      Unspecified
                                    </Typography>
                                  )}
                                </>
                              ) : (
                                <>
                                  {subsectors && subsectors.length ? (
                                    subsectors.map((subsector, index) => {
                                      return (
                                        <Typography
                                          key={`${subsector}-${index}`}
                                          className={classnames([
                                            classes.infoTitle,
                                            classes.mono,
                                          ])}
                                          variant="body2">
                                          {typeof subsector === "object" ? (
                                            <>{subsector.name}</>
                                          ) : (
                                            <>{subsector}</>
                                          )}
                                          {index + 1 < subsectors.length &&
                                            ", "}
                                        </Typography>
                                      );
                                    })
                                  ) : (
                                    <Typography
                                      className={classnames([
                                        classes.infoTitle,
                                        classes.mono,
                                      ])}
                                      variant="body2">
                                      Unspecified
                                    </Typography>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                          <div className={classnames([classes.scoreRow])}>
                            <Typography
                              className={classes.infoValue}
                              variant="body2">
                              {t("companyProfile.location")}:
                            </Typography>
                            <Typography
                              className={classnames([
                                classes.infoTitle,
                                classes.mono,
                              ])}
                              variant="body2">
                              {company?.hq_city && company?.hq_country
                                ? company.hq_city + ", " + company.hq_country
                                : hq_city && hq_country
                                ? hq_city + ", " + hq_country
                                : "Unspecified"}
                            </Typography>
                          </div>
                        </div>
                        <div
                          className={classnames([
                            classes.scoresRows,
                            classes.basicInfo,
                            classes.flexSpaceBetween,
                            classes.flexRow,
                          ])}>
                          <div className={classnames([classes.scoreRow])}>
                            <Typography
                              className={classes.infoValue}
                              variant="body2">
                              {t("companyProfile.yearOfInception")}:
                            </Typography>
                            <Typography
                              className={classnames([
                                classes.infoTitle,
                                classes.mono,
                              ])}
                              variant="body2">
                              {founded_date ?? "Unspecified"}
                            </Typography>
                          </div>
                          <div className={classnames([classes.scoreRow])}>
                            <Typography
                              className={classes.infoValue}
                              variant="body2">
                              {t("companyProfile.companySize")}:
                            </Typography>
                            <Typography
                              className={classnames([
                                classes.infoTitle,
                                classes.mono,
                              ])}
                              variant="body2">
                              {company?.number_of_employees ?? "Unspecified"}
                            </Typography>
                          </div>
                        </div>
                        <div
                          className={classnames([
                            classes.scoresRows,
                            classes.basicInfo,
                            classes.flexSpaceBetween,
                            classes.flexRow,
                          ])}>
                          <div className={classnames([classes.scoreRow])}>
                            <Typography
                              className={classes.infoValue}
                              variant="body2">
                              {t("companyProfile.companyStage")}:
                            </Typography>
                            <Typography
                              className={classnames([
                                classes.infoTitle,
                                classes.mono,
                              ])}
                              variant="body2">
                              {company?.investment_stage ?? "Unspecified"}
                            </Typography>
                          </div>
                          <div className={classnames([classes.scoreRow])}>
                            <Typography
                              className={classes.infoValue}
                              variant="body2">
                              {t("companyProfile.website")}:
                            </Typography>
                            {website && (
                              <Typography
                                className={classnames([
                                  classes.infoTitle,
                                  classes.mono,
                                ])}
                                variant="body2">
                                <Link
                                  target="_blank"
                                  className={classes.link}
                                  href={website ?? ""}>
                                  {website
                                    ? website.replace(
                                        /http(s)?(:)?(\/\/)?|(\/\/)?(www\.)?/g,
                                        "",
                                      )
                                    : "Unspecified"}
                                </Link>
                              </Typography>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={classes.sdgWrap}>
                    <Typography className={classes.infoTitle} variant="body1">
                      {t("companyProfile.comments")}:
                    </Typography>
                    <Typography
                      className={classnames([
                        classes.infoValue,
                        classes.commentsText,
                      ])}
                      variant="body2">
                      {company.comments_cnt || 0}{" "}
                      {t("companyProfile.comments").toLowerCase()}
                    </Typography>
                  </div>
                  {(function () {
                    if (
                      (ready_for_release || isPreview) &&
                      company &&
                      company.sdgs &&
                      company.sdgs.length > 0
                    ) {
                      return (
                        <div className={classes.sdgWrap}>
                          <Typography
                            className={classes.infoTitle}
                            variant="body1">
                            {t("companyProfile.sdg")}:
                          </Typography>
                          <div className={classes.flexWrap}>
                            {company &&
                              company.sdgs &&
                              company.sdgs.map((sdg, i) => (
                                <Tooltip
                                  key={`sdg-${i}`}
                                  arrow
                                  classes={{
                                    arrow: classes.tooltipArrow,
                                    tooltip: classes.tooltipContent,
                                  }}
                                  placement="top"
                                  title={
                                    <Typography
                                      color="white"
                                      variant="body2"
                                      className={classes.sdgTooltip}>
                                      <div className={classes.sdgTooltipTitle}>
                                        {t(
                                          "companyProfile.thisCompanyWorksTowards",
                                        )}
                                      </div>
                                      {t(
                                        "companyProfile.sustainableDevelopmentGoals",
                                      )}{" "}
                                      {sdg.number}: {sdg.name}
                                    </Typography>
                                  }>
                                  <img
                                    className={classes.sdg}
                                    src={require(
                                      `../../assets/icons/sdgs/${sdg.icon_name}.png`,
                                    )}
                                    alt={sdg.name}></img>
                                </Tooltip>
                              ))}
                          </div>
                        </div>
                      );
                    } else if (!ready_for_release && !isPreview) {
                      return (
                        <div className={classes.sdgWrap}>
                          <Typography
                            className={classes.infoTitle}
                            variant="body1">
                            {t("companyProfile.sustainableDevelopmentGoals")}:
                          </Typography>
                          <Typography variant="body2">
                            {enrichment_request ? (
                              <Typography
                                className={classnames([
                                  classes.infoValue,
                                  classes.commentsText,
                                ])}
                                variant="body2">
                                {t("companyProfile.enrichmentInProgress")}
                              </Typography>
                            ) : (
                              <Link
                                className={classes.link}
                                onClick={() =>
                                  showEnrichRequestModal(null, company)
                                }>
                                {t("companyProfile.unlockItToAccessSdg")}
                              </Link>
                            )}
                          </Typography>
                        </div>
                      );
                    }
                  })()}
                </div>
              )}
              {!ready_for_release && !isPreview && (
                <>
                  <div className={classes.enrichmentBanner}>
                    {/* { isGuestUser && !enrichment_request &&
                                    <>
                                        <div>
                                            <Typography align="center" variant="h6" className={classes.enrichmentBannerTitle}>{t("companyProfile.enrichmentBanner.title.guest")}</Typography>
                                            <Typography
                                                className={classes.enrichmentBannerDescription}
                                                dangerouslySetInnerHTML={{ __html: t("companyProfile.enrichmentBanner.description.guest") }} />
                                            <div
                                                className={classes.enrichmentBannerLink}
                                                dangerouslySetInnerHTML={{ __html: t("companyProfile.enrichmentBanner.link.guest", { href: window.location.origin + '/companies/enriched/company' }) }}
                                                onClick={handleClickOnPreviewExample} />
                                        </div>
                                    </>
                                } */}
                    {isAuthenticated &&
                      isExpiredUser &&
                      !enrichment_request && (
                        <>
                          <div>
                            <Typography
                              variant="h6"
                              className={classes.enrichmentBannerTitle}>
                              {t(
                                "companyProfile.enrichmentBanner.title.expired",
                              )}
                            </Typography>
                            <Typography
                              className={classes.enrichmentBannerDescription}
                              dangerouslySetInnerHTML={{
                                __html: t(
                                  "companyProfile.enrichmentBanner.description.expired",
                                ),
                              }}
                            />
                            <div
                              className={classes.enrichmentBannerLink}
                              dangerouslySetInnerHTML={{
                                __html: t(
                                  "companyProfile.enrichmentBanner.link.expired",
                                  {
                                    href:
                                      window.location.origin +
                                      "/demo/report/clearblade",
                                  },
                                ),
                              }}
                              onClick={handleClickOnPreviewExample}
                            />
                          </div>
                        </>
                      )}
                    {isAuthenticated && isPayingUser && !enrichment_request && (
                      <>
                        <div>
                          <Typography
                            variant="h6"
                            className={classes.enrichmentBannerTitle}>
                            {t("companyProfile.enrichmentBanner.title.paying")}
                          </Typography>

                          <Button
                            onClick={e => showEnrichRequestModal(e, company)}
                            className={classes.enrichmentBannerButton}
                            variant="primary"
                            data-intercom-target="Enrich company button">
                            {t("companyProfile.enrichmentBanner.button.paying")}
                            <DeepDive className={classes.deepDiveIcon} />
                          </Button>

                          <Typography
                            className={classes.enrichmentBannerDescription}
                            dangerouslySetInnerHTML={{
                              __html: t(
                                "companyProfile.enrichmentBanner.description.paying",
                              ),
                            }}
                          />
                          <div
                            className={classes.enrichmentBannerLink}
                            data-intercom-target="Preview enriched company example button"
                            dangerouslySetInnerHTML={{
                              __html: t(
                                "companyProfile.enrichmentBanner.link.paying",
                                {
                                  href:
                                    window.location.origin +
                                    "/demo/report/clearblade",
                                },
                              ),
                            }}
                            onClick={handleClickOnPreviewExample}
                          />
                        </div>
                      </>
                    )}
                    {!!(
                      isAuthenticated &&
                      enrichment_request &&
                      !ready_for_release
                    ) && (
                      <>
                        <div>
                          <LockAlert removeImg removeCloseButton removePadding>
                            <Typography
                              className={classes.enrichmentBannerDescription}
                              dangerouslySetInnerHTML={{
                                __html: t(
                                  "companyProfile.enrichmentBanner.description.underEnrichment",
                                  {
                                    href: window.location.origin + "/orders",
                                  },
                                ),
                              }}
                            />
                          </LockAlert>
                        </div>
                      </>
                    )}
                  </div>
                </>
              )}
              <div>
                {activeTab === "profile" && companyResult && (
                  <Profile
                    isPreview={isPreview}
                    showEnrichRequestModal={e =>
                      showEnrichRequestModal(e, company)
                    }
                    company={
                      window.location.pathname === "/companies/enriched/company"
                        ? {
                            ready_for_release: true,
                            ...companyResult,
                          }
                        : companyResult
                    }
                    user={user}
                  />
                )}
                {activeTab === "executive" && companyResult && (
                  <ExecutiveTeam
                    isPreview={isPreview}
                    company={
                      window.location.pathname === "/companies/enriched/company"
                        ? {
                            ready_for_release: true,
                            ...companyResult,
                          }
                        : companyResult
                    }
                    user={user}
                  />
                )}
                {activeTab === "success" && companyResult && (
                  <Success
                    isPreview={isPreview}
                    currency={total_funding_amount_currency}
                    funding={totalFunding}
                    showEnrichRequestModal={e =>
                      showEnrichRequestModal(e, company)
                    }
                    company={
                      window.location.pathname === "/companies/enriched/company"
                        ? {
                            ready_for_release: true,
                            ...companyResult,
                          }
                        : companyResult
                    }
                    user={user}
                  />
                )}
                {activeTab === "comments" && companyResult && (
                  <Comments
                    source={source}
                    showEnrichRequestModal={e =>
                      showEnrichRequestModal(e, company)
                    }
                    company={
                      window.location.pathname === "/companies/enriched/company"
                        ? {
                            ready_for_release: true,
                            ...companyResult,
                          }
                        : companyResult
                    }
                    user={user}
                    onCommentsCount={handleOnCommentsCount}
                  />
                )}
                {user && (
                  <>
                    {suggestedCompaniesStatus === REQUEST_PENDING ? (
                      <ContentLoader />
                    ) : (
                      suggestedCompaniesStatus !== REQUEST_UNDEFINED && (
                        <SuggestedCompanies
                          onSeeLookALike={handleSeeLookALike}
                          companies={suggestedCompanies}
                          parentSlug={companyResult.slug}
                          parentId={cid}
                        />
                      )
                    )}
                  </>
                )}
              </div>
            </div>
          ) : (
            <img src={PageNotFound} className={classes.notFoundImg} />
          )}
          <EnrichRequestModal
            source="company"
            credits={credits}
            projects={checkedProjects}
            company={company}
            dialogOpened={enrichRequestModalShow}
            onChange={handleClosingEnrichDialog}
            createProjectDialogOpened={handleCreateProjectDialogOpened}
            enrichSuccess={handleEnrichSuccess}
            cId={company && company.id}
          />
          <NotEnoughCreditsModal
            credits={credits}
            dialogOpened={notEnoughCreditsModalShow}
            onChange={handleClosingNotEnoughCreditsDialog}
            onTopUp={handleTopUp}
          />
          {showEnrichSuccess && (
            <OrderReceivedModal
              dialogOpened={showEnrichSuccess}
              onChange={handleClosingSuccess}
            />
          )}
          <TopUpCreditsModal
            dialogOpened={topUpCreditsModalShow}
            onBuy={handleBuyingTopUpCreditsDialog}
            onChange={handleClosingTopUpCreditsDialog}
          />
          <CreateProjectModal
            dialogOpened={createProjectModalShow}
            onChange={handleClosingCreateProjectModal}
            newProjectCreated={handleNewProjectCreated}
          />
        </div>
      ) : (
        <>
          {getCompanyStatus === REQUEST_PENDING && <ContentLoader />}
          {getCompanyStatus === REQUEST_FAILURE && (
            <div className={classes.companyNotFound}>
              The company profile is currently unavailable
            </div>
          )}
        </>
      )}
    </UserProvider>
  );
};
export default CompanyProfile;
