import { createStyles, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(({ palette, spacing, breakpoint }) =>
  createStyles({
    // main
    main: {
      background: palette.grey.light,
      margin: `0 auto`,
      display: "flex",
      flexDirection: "column",
      padding: spacing(4),
      [breakpoint.up("xxxl")]: {
        width: "100%",
        maxWidth: 1900,
        margin: "0 auto",
        alignItems: "center",
      },
      [breakpoint.down("xxl")]: {
        maxWidth: "1136px",
      },
      [breakpoint.down("md")]: {
        padding: `${spacing(3)}px 0`,
      },
    },
    mainTitle: {},
    bigCardsWrapperHolder: {},
    // small cards
    smallCardsWrapper: {
      display: "flex",
      flexWrap: "wrap",
      marginBottom: spacing(2),
      borderRadius: spacing(3),
      gap: spacing(2),
      // [breakpoint.up('xxxl')]: {
      //     flexDirection: 'column',
      //     marginLeft: spacing(4),
      //     background: palette.success.lighter,
      //     borderRadius: 24,
      //     padding: `${spacing(5)}px ${spacing(4)}px`,
      //     marginBottom: 0,
      //     width: 610
      // },
      // [breakpoint.down('xxl')]: {
      flexDirection: "row",
      justifyContent: "space-between",
      // },
      [breakpoint.down("md")]: {
        background: palette.common.white,
        flexDirection: "column",
        height: 450,
        paddingBottom: spacing(4),
        transition: "height 70ms ease-out",
      },
    },
    quickStatSearch: {
      background: `${palette.success.backgroundLight} !important`,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      [breakpoint.down("md")]: {
        display: "none !important",
      },
    },
    mt4: {
      marginTop: "4px",
    },
    smallCardsWrapperCollapsed: {
      height: 72,
    },
    dashboardCardSmall: {
      flexGrow: 1,
      flexBasis: 200,
      textDecoration: "none",
      background: palette.common.white,
      borderRadius: spacing(3),
      border: `1px solid ${palette.common.white}`,
      padding: `${spacing(3)}px ${spacing(3)}px ${spacing(4)}px ${spacing(
        3,
      )}px`,
      position: "relative",
      // [breakpoint.up("xxxl")]: {
      //     flex: "0 45%",
      //     marginRight: 30,
      //     marginBottom: 35,
      //     "&:nth-child(2n)": {
      //         marginRight: 0
      //     }
      // },
      // [breakpoint.down("xxl")]: {
      flex: "0 calc(20% - 16px)",
      marginRight: 0,
      marginBottom: 0,
      // },
      "&:hover": {
        borderColor: palette.primary.dark,
        cursor: "pointer",
        textDecoration: "none",
      },
      [breakpoint.down("md")]: {
        flex: 1,
        flexDirection: "row",
        display: "flex",
        alignItems: "center",
        padding: `${spacing(2)}px ${spacing(3)}px ${spacing(2)}px ${spacing(
          3,
        )}px`,
      },
    },
    dashboardCardSmallHeader: {
      [breakpoint.down("md")]: {
        flex: 1,
      },
    },
    dashboardCardSmallBody: {
      display: "flex",
      position: "relative",
      flexDirection: "row",
      marginTop: spacing(1),
      alignItems: "baseline",
      justifyContent: "space-between",
      [breakpoint.down("md")]: {
        flex: 5,
        marginTop: 0,
        alignItems: "center",
      },
    },
    dashboardCardSmallFooter: {
      position: "absolute",
      top: "37px",
      fontFamily: "GT Flexa Mono, sans-serif",
    },
    mono: {
      fontFamily: "GT Flexa Mono, sans-serif",
    },
    quickOverviewTitle: {
      display: "none",
      [breakpoint.down("md")]: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: `${spacing(3)}px ${spacing(3)}px ${spacing(2)}px ${spacing(
          3,
        )}px`,
        cursor: "pointer",
      },
    },
    qoTitleBiggestDesktop: {
      fontSize: 32,
      lineHeight: "38px",
      display: "none",
      // [breakpoint.up("xxxl")]: {
      //     display: "block"
      // }
    },
    qoSubtitleBiggestDesktop: {
      display: "none",
      marginBottom: spacing(4),
      "& a": {
        color: palette.common.black,
      },
      // [breakpoint.up("xxxl")]: {
      //     display: "block"
      // }
    },
    quickStatsWrapper: {
      display: "flex",
      flexWrap: "wrap",
      marginBottom: spacing(2),
      borderRadius: spacing(3),
      gap: spacing(2),
      flexDirection: "row",
      justifyContent: "space-between",
      width: "100%",
      [breakpoint.down("md")]: {
        flexDirection: "column",
      },
      // [breakpoint.up("xxxl")]: {
      //     display: "flex",
      //     flexDirection: "row",
      //     flexWrap: "wrap",
      //     justifyContent: "space-between"
      // }
    },
    collapseArrow: {
      cursor: "pointer",
    },
    collapseArrowReversed: {
      transform: "rotate(180deg)",
      marginLeft: spacing(1),
    },
    // big cards
    bigCardsWrapper: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      flexWrap: "wrap",
      marginBottom: spacing(2),
      gap: spacing(2),
      // [breakpoint.up("xxxl")]: {
      //     minWidth: 976
      // },
      [breakpoint.down("lg")]: {
        justifyContent: "center",
        flexDirection: "row",
      },
      [breakpoint.down("md")]: {
        justifyContent: "center",
        flexDirection: "column",
      },
    },
    bigCardsWrapperCompanies: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      flexWrap: "wrap",
      marginBottom: spacing(2),
      gap: spacing(2),
      // [breakpoint.up("xxxl")]: {
      //     marginLeft: spacing(4)
      // },
      [breakpoint.down("xxxl")]: {
        flexBasis: `calc(50% - ${spacing(2)}px)`,
        maxWidth: `calc(50% - ${spacing(2)}px)`,
      },
      [breakpoint.down("md")]: {
        justifyContent: "center",
        flexDirection: "column",
        width: "100%",
        maxWidth: "none",
      },
    },
    dashboardCardBig: {
      flexBasis: `calc(50% - ${spacing(2)}px)`,
      marginBottom: spacing(2),
      background: palette.common.white,
      borderRadius: spacing(3),
      padding: spacing(3),
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "column",
      // height: '420px',
      [breakpoint.down("xl")]: {
        minHeight: "450px",
        height: "auto",
      },
      [breakpoint.down("lg")]: {
        // height: '420px'
      },
      [breakpoint.down("md")]: {
        height: "auto",
        maxWidth: "none",
      },
    },
    dashboardCardBigPage: {
      flex: 1,
      background: palette.common.white,
      borderRadius: spacing(3),
      padding: spacing(4),
      height: "auto",
      [breakpoint.down("lg")]: {
        height: "auto",
      },
      "& $dashboardCardBigBody": {
        height: "auto",
        maxHeight: "none",
      },
      "& $dashboardCardBigBodyActivity span": {
        marginLeft: spacing(1),
      },
      "& $latestActivityAction": {
        display: "inline-block",
        margin: 0,
      },
      "& $dashboardCardBigBodyActivityTimestamp": {
        margin: 0,
      },
    },
    searchAndStats: {
      width: "100%",
      // [breakpoint.up("xxxl")]: {
      //     display: "flex",
      //     flexDirection: "row",
      //     justifyContent: "center",
      //     marginBottom: spacing(4)
      // }
    },
    noDataWrapper: {
      display: "flex",
      alignItems: "center",
    },
    noDataImg: {
      maxWidth: "60%",
      margin: "auto",
      marginTop: spacing(6),
      maxHeight: 186,
      marginBottom: spacing(2),
    },
    dashboardCardBigBody: {
      display: "flex",
      flexDirection: "column",
      marginTop: spacing(3),
      marginBottom: spacing(3),
    },
    latestActivityAction: {
      margin: 0,
    },
    savedSearch: {
      maxHeight: "48px",
      overflowY: "hidden",
      "-webkit-box-orient": "vertical",
      "-webkit-line-clamp": 2,
      display: "-webkit-box",
    },
    unseenNumber: {
      background: palette.primary.dark,
      borderRadius: spacing(3),
      width: 16,
      height: 16,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginLeft: spacing(1),
    },
    bigCardTitle: {
      display: "flex",
      alignItems: "center",
    },
    dashboardCardBigHeaderSubtitle: {
      fontWeight: 275,
    },
    dashboardCardBigBodyItem: {
      height: "48px",
      display: "flex",
      width: "100%",
      alignItems: "center",
      justifyContent: "space-between",
      borderBottom: `1px solid ${palette.text.backgroundLight}`,
      position: "relative",
      "& span": {
        margin: `0px ${spacing(2)}px 0 0`,
        verticalAlign: "middle",
        display: "flex",
        alignItems: "center",
      },
    },
    dashboardCardBigBodyActivity: {
      // height: '48px',
      display: "flex",
      width: "100%",
      alignItems: "center",
      justifyContent: "space-between",
      borderBottom: `1px solid ${palette.text.backgroundLight}`,
      position: "relative",
      padding: `${spacing(1)}px 0`,
      [breakpoint.down("sm")]: {
        flexDirection: "column",
        alignItems: "baseline",
        padding: "4px 0",
      },
      "& span": {
        maxWidth: 314,
        margin: `0px ${spacing(2)}px`,
        verticalAlign: "middle",
        display: "flex",
        alignItems: "center",
      },
      "& $dashboardCardBigBodyActivityTimestamp": {
        [breakpoint.down("md")]: {
          marginLeft: 54,
        },
      },
    },
    dashboardCardBigBodyItemTextLink: {
      flex: 5,
      cursor: "pointer",
      "&:hover": {
        textDecoration: "underline",
      },
    },
    latestActivityActionLink: {
      paddingLeft: 4,
      cursor: "pointer",
    },
    latestActivityActionLinkLeft: {
      paddingRight: 4,
      cursor: "pointer",
    },
    dashboardCardBigBodyItemText: {
      flex: 10,
      maxWidth: "none !important",
    },
    actionWrap: {
      display: "flex",
    },
    dashboardCardBigBodyItemIcon: {
      marginLeft: "-20px",
      marginBottom: "-4px",
      [breakpoint.down("md")]: {
        marginBottom: "-22px",
      },
    },
    dashboardCardBigBodyItemTimestamp: {},
    latestItemRightSide: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      flex: 4,
    },
    dashboardCardBigBodyActivityTimestamp: {
      width: 100,
      justifyContent: "flex-end",
    },
    dashboardCardBigFooter: {
      background: palette.success.lighter,
      padding: spacing(3),
      borderRadius: spacing(1),
    },
    dashboardCardBigFooterLink: {
      "&:hover": {
        cursor: "pointer",
        textDecoration: "underline",
      },
    },
    unseenDot: {
      width: 8,
      height: 8,
      borderRadius: spacing(5),
      backgroundColor: palette.primary.dark,
      padding: 0,
      position: "absolute",
      content: "''",
      left: -10,
      top: 18,
    },
    fade: {
      //TODO@jkica:make fader
    },

    // stepper card
    dashboardStepperCard: {
      flex: 1,
      borderRadius: spacing(2),
      padding: spacing(3),
      height: "380px",
      background: palette.success.lighter,
      width: "100%",
      [breakpoint.down("md")]: {
        flex: "1 1 48%",
      },
    },
    dashboardStepperCardBody: {
      maxWidth: "unset",
      flex: "unset",
      position: "unset",
      width: "100%",
      marginTop: spacing(3),
      border: "none",
    },
    slideWrap: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    slideTitle: {
      marginTop: spacing(3),
      minHeight: 64,
      display: "flex",
      alignItems: "center",
    },
    slideDescription: {
      marginTop: spacing(3),
      minHeight: 110,
    },
    nextBtnWrapper: {
      marginLeft: spacing(6),
      width: 87,

      "& a": {
        marginTop: "10px",
      },

      [breakpoint.down("sm")]: {
        width: "auto",
        marginLeft: 0,
        marginTop: spacing(2),
      },
    },
    backBtnWrapper: {
      marginRight: spacing(6),
      width: 105,

      "& a": {
        marginTop: "10px",
        float: "right",
      },

      [breakpoint.down("md")]: {
        width: "auto",
        marginRight: 0,
        marginTop: spacing(3),
      },
    },
    dotsWrapp: {
      display: "flex",
      alignItems: "center",
      height: 40,
      [breakpoint.down("sm")]: {
        marginTop: spacing(2),
      },
    },
    dot: {
      width: 6,
      height: 6,
      background: palette.success.light,
      borderRadius: "50%",
      marginLeft: 6,
    },
    activeDot: {
      width: 8,
      height: 8,
      background: palette.success.dark,
    },
    stepper: {
      display: "flex",
      justifyContent: "center",
      height: "50px",
      marginTop: spacing(2),
      marginRight: "5%",

      [breakpoint.down("md")]: {
        flexDirection: "row",
        justifyContent: "space-evenly",
        marginTop: "-5px",

        "&>*": {
          margin: "auto 0",
        },
      },
    },
    arrowNext: {
      marginRight: "0 !important",
    },
    arrowBack: {
      marginRight: "0 !important",
    },
    buttonBack: {
      padding: "12px 20px 12px 16px",
    },
    buttonNext: {
      padding: "12px 16px 12px 20px",
    },
    noSearchHistory: {
      marginTop: spacing(6),
    },

    // tooltip
    averageDataTooltip: {
      background: `${palette.common.white} !important`,
      color: `${palette.text.main} !important`,
    },
    cursorPointer: {
      "&:hover": {
        cursor: "pointer",
      },
    },
    infoButton: {
      backgroundColor: palette.success.backgroundDark,
      width: spacing(3),
      height: spacing(3),
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: palette.text.dark,
      marginLeft: spacing(2),
      cursor: "pointer",
    },
    titleInfoWrap: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      paddingLeft: "10px",
      paddingRight: "10px",
    },

    // new search
    dashboardMainSearchBoxWrapper: {
      [breakpoint.up("sm")]: {
        padding: spacing(5),
        background: palette.success.lighter,
        marginBottom: spacing(5),
        borderRadius: "24px",
      },
      [breakpoint.down("xxxl")]: {
        marginBottom: spacing(4),
        marginTop: 0,
      },
      // [breakpoint.up("xxxl")]: {
      //     width: 976,
      //     marginBottom: 0
      // },
    },
    helpButton: {
      display: "inline-flex",
      height: "30px",
      lineHeight: "7px",
      marginTop: "13px",
      borderRight: `1px solid ${palette.common.black}`,
      fontFamily: "GT Flexa Mono, sans-serif",
      padding: "14px",
      [breakpoint.down("md")]: {
        borderRight: 0,
      },
      [breakpoint.down("sm")]: {
        whiteSpace: "nowrap",
      },
    },
    helpButtonWrapper: {
      display: "flex",
      flexDirection: "row",
      width: "182px",
      borderTop: `1px solid ${palette.common.black}`,
      borderLeft: `1px solid ${palette.common.black}`,
      borderBottom: `1px solid ${palette.common.black}`,
      borderTopLeftRadius: "10px",
      borderBottomLeftRadius: "10px",
      [breakpoint.down("md")]: {
        borderTopLeftRadius: 0,
        position: "absolute",
        bottom: "-40px",
        left: "calc(50% - 86px)",
        border: 0,
      },
      "&:hover": {
        cursor: "pointer",
      },
    },
    mainSearchBoxWrapper: {
      width: "100%",
      maxWidth: "1500px",
      margin: "0 auto",
      paddingTop: "7vh",
      [breakpoint.down("lg")]: {
        width: "100%",
        marginBottom: "50px",
        padding: spacing(2),
      },
    },
    mainSearchBox: {
      position: "relative",
      height: "100%",
      width: "100%",
      borderTopRightRadius: "20px",
      borderBottomRightRadius: "20px",
      borderBottomLeftRadius: "20px",
      border: `1px solid ${palette.common.black}`,
      background: palette.common.white,
      [breakpoint.down("md")]: {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
        borderRight: 0,
        borderLeft: 0,
        borderBottom: 0,
        background: "transparent",
      },
    },
    mainSearchBoxTabs: {
      height: "50px",
      width: "400px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-evenly",
      borderLeft: "1px solid #000000",
      borderTop: "1px solid #000000",
      borderRight: "1px solid #000000",
      borderTopRightRadius: "20px",
      borderTopLeftRadius: "20px",
      background: palette.common.black,
      color: palette.common.white,
      "& :hover": {
        cursor: "pointer",
      },
      [breakpoint.down("sm")]: {
        width: "100%",
      },
      [breakpoint.down("md")]: {
        width: "100%",
        borderLeft: 0,
        borderRight: 0,
        borderBottom: 0,
      },
    },
    mainSearchBoxTabActive: {
      color: palette.common.black,
      background: palette.common.white,
    },
    mainSearchBoxTabOne: {
      height: "100%",
      width: "100%",
      borderTopLeftRadius: "20px",
      textAlign: "center",
      paddingTop: spacing(2),
    },
    mainSearchBoxTabTwo: {
      height: "100%",
      width: "100%",
      borderTopRightRadius: "20px",
      textAlign: "center",
      paddingTop: spacing(2),
      borderRight: `1px solid ${palette.common.black}`,
    },
    industriesAndTechnologiesWrapper: {
      marginTop: spacing(9),
    },
    industrySearchExample: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
      paddingTop: spacing(3),
      paddingBottom: spacing(3),
    },
    industrySearchExampleBtn: {
      margin: "auto 0",
    },
    technologySearchExample: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
      paddingTop: spacing(3),
      paddingBottom: spacing(3),
    },
    technologySearchExampleBtn: {
      margin: "auto 0",
    },
    helpArrowDown: {
      width: 10,
      marginLeft: spacing(1),
      cursor: "pointer",
    },
    arrowDown: {
      position: "absolute",
      right: spacing(2),
      zIndex: 2,
      cursor: "pointer",
    },
    locationTitles: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      width: "100%",
      marginTop: spacing(3),
      marginBottom: spacing(1),
    },
    locationTitle1: {
      width: "50%",
    },
    locationTitle2: {
      width: "50%",
      paddingLeft: spacing(2),
    },
    locationsWrapper: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "space-between",
      marginBottom: spacing(1),
      [breakpoint.down("xl")]: {
        flexDirection: "column",
        justifyContent: "space-around",
        height: "150px",
      },
    },
    regionsWrapper: {
      display: "flex",
      flexDirection: "row",
      border: `1px solid ${palette.common.black}`,
      borderRadius: "10px",
      background: palette.common.white,
    },
    countriesWrapper: {
      display: "flex",
      flexDirection: "row",
      border: `1px solid ${palette.common.black}`,
      borderRadius: "10px",
      background: palette.common.white,
    },
    rowWrap: {
      flex: 1,
      maxWidth: "33%",
      [breakpoint.down("xl")]: {
        flex: 1,
        maxWidth: "50%",
      },
    },
    multiSelect: {
      border: "none !important",
      minWidth: "100px",
      background: `${palette.common.white} !important`,
      position: "relative",
      flex: 1,
      "& .Mui-disabled": {
        background: `${palette.common.white} !important`,
        height: "40%",
      },
      "& .Mui-disabled:hover": {
        cursor: "not-allowed",
      },
      "& svg": {
        marginLeft: spacing(2),
      },
      "& *": {
        border: "none !important",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      },
    },
    multiSelectRoot: {
      paddingLeft: `${spacing(2)}px !important`,
    },
    verticalDivider: {
      width: "1px",
      height: "70%",
      background: palette.common.black,
      position: "absolute",
      right: 0,
      zIndex: 2,
    },
    arrowDivider: {
      position: "absolute",
      left: -35,
      top: 32,
      [breakpoint.down("xl")]: {
        display: "none",
      },
    },
    locations: {
      display: "flex",
      marginTop: spacing(3),
      paddingBottom: spacing(4),
      gap: spacing(6),
      flexDirection: "row",
      flexWrap: "wrap",
      [breakpoint.down("md")]: {
        flexDirection: "column",
        gap: spacing(2),
        marginTop: spacing(6),
      },
    },
    location: {
      display: "flex",
      flexDirection: "column",
      maxWidth: "calc(50% - 28px)",
      position: "relative",
      flex: 1,
      [breakpoint.down("md")]: {
        maxWidth: "100%",
      },
    },
    simpleInput: {
      border: "none",
      outline: "none",
      borderRadius: "10px",
      background: `${palette.common.white}`,
      paddingLeft: spacing(1),
      flex: 2,
      maxWidth: "66%",
      [breakpoint.down("xl")]: {
        flex: 1,
        maxWidth: "50%",
      },
    },
    industrySearchExampleText: {
      maxWidth: "80%",
    },
    errorsWrapper: {
      height: "20px",
      color: palette.error.main,
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      marginBottom: spacing(2),
    },
    error1: {
      width: "50%",
      textAlign: "right",
      paddingRight: spacing(3),
    },
    error2: {
      width: "50%",
      textAlign: "right",
    },
  }),
);
