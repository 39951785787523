import { call } from "redux-saga/effects";
import axios from "axios";
import {
  ENRICHED_EXAMPLE_TECHNOLOGY_URL,
  filterTechnologyCompaniesURL,
  GET_ALL_TECHNOLOGIES_URL,
  GET_TECHNOLOGY_COMPANY_PROFILE,
  getEnrichTechnologyCompanyURL,
  getTechnologyURL,
  postEnrichTechnologyCompanyURL,
  SUGGEST_TECHNOLOGY,
} from "../../../constants/endpoints";

function* getAllTechnologies(page, search) {
  const response = yield call(axios, {
    method: "GET",
    url: `${GET_ALL_TECHNOLOGIES_URL}${
      search ? `?search=${search}&page=${page}` : `?page=${page}`
    }`,
    withCredentials: true,
  });

  return response.data;
}

function* getTechnology(slug) {
  const response = yield call(axios, {
    method: "GET",
    url: `${getTechnologyURL(slug)}`,
    withCredentials: true,
  });

  return response.data;
}

function* getFilterTechnologyCompanies(slug, data) {
  let url = "";
  if (!slug) {
    url = ENRICHED_EXAMPLE_TECHNOLOGY_URL;
  } else {
    if (data) {
      const { filterNameValue } = data;
      url = `${filterTechnologyCompaniesURL(slug)}${filterNameValue}`;
    } else {
      url = `${filterTechnologyCompaniesURL(slug)}`;
    }
  }
  const response = yield call(axios, {
    method: "GET",
    url: url,
    withCredentials: true,
  });

  return response.data;
}

function* getTechnologiesCompaniesPage(slug, data) {
  const { filterNameValue } = data;
  const response = yield call(axios, {
    method: "GET",
    url: `${filterTechnologyCompaniesURL(slug)}${filterNameValue}`,
    withCredentials: true,
  });
  return response.data;
}

function* getTechnologyCompany(slug, cId) {
  const response = yield call(axios, {
    method: "GET",
    url: `${GET_TECHNOLOGY_COMPANY_PROFILE}/${slug}/?cId=${cId}`,
    withCredentials: true,
  });
  return response.data;
}

function* postEnrichTechnologyCompany(slug, projectSlug, cId) {
  const response = yield call(axios, {
    method: "POST",
    url: `${postEnrichTechnologyCompanyURL(slug)}`,
    withCredentials: true,
    data: {
      project: projectSlug,
      cId: cId,
    },
  });
  return response.data;
}

function* postEnrichTechnology(slug) {
  const response = yield call(axios, {
    method: "GET",
    url: `${getEnrichTechnologyCompanyURL(slug)}`,
    withCredentials: true,
  });
  return response.data;
}

function* postSuggestTechnology(data) {
  const response = yield call(axios, {
    method: "POST",
    url: `${SUGGEST_TECHNOLOGY}`,
    withCredentials: true,
    data,
  });
  return response.data;
}

export {
  getAllTechnologies,
  getTechnology,
  getFilterTechnologyCompanies,
  getTechnologiesCompaniesPage,
  getTechnologyCompany,
  postEnrichTechnologyCompany,
  postEnrichTechnology,
  postSuggestTechnology,
};
