import { createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(({ palette, spacing, breakpoint }) =>
  createStyles({
    root: {
      padding: `${spacing(3)}px ${spacing(3)}px ${spacing(3)}px ${spacing(
        3,
      )}px`,
      display: "flex",
      justifyContent: "center",
      [breakpoint.down("md")]: {
        padding: `${spacing(3)}px ${spacing(0)}px ${spacing(3)}px ${spacing(
          0,
        )}px`,
      },
    },
    main: {
      display: "flex",
      flexDirection: "column",
      textAlign: "center",
      alignItems: "center",
      alignSelf: "center",
    },
    slideWrap: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    slideTitle: {
      marginTop: spacing(3),
      minHeight: 64,
      display: "flex",
      alignItems: "center",
    },
    slideDescription: {
      marginTop: spacing(3),
      minHeight: 110,
    },
    nextBtnWrapper: {
      marginTop: spacing(5),
      marginLeft: spacing(6),
      width: 87,
      [breakpoint.down("sm")]: {
        width: "auto",
        marginLeft: 0,
        marginTop: spacing(2),
      },
    },
    backBtnWrapper: {
      marginTop: spacing(5),
      marginRight: spacing(6),
      width: 105,
      [breakpoint.down("sm")]: {
        width: "auto",
        marginRight: 0,
        marginTop: spacing(3),
      },
    },
    dotsWrapp: {
      display: "flex",
      alignItems: "center",
      marginTop: spacing(5),
      height: 40,
      [breakpoint.down("sm")]: {
        marginTop: spacing(2),
      },
    },
    dot: {
      width: 6,
      height: 6,
      background: palette.primary.light,
      borderRadius: "50%",
      marginLeft: 6,
    },
    activeDot: {
      width: 8,
      height: 8,
      background: palette.primary.dark,
    },
    stepper: {
      display: "flex",
      [breakpoint.down("sm")]: {
        flexDirection: "column",
      },
    },
    arrowNext: {
      marginRight: "0 !important",
    },
    arrowBack: {
      marginRight: "0 !important",
    },
    buttonBack: {
      padding: "12px 20px 12px 16px",
    },
    buttonNext: {
      padding: "12px 16px 12px 20px",
    },
  }),
);
