import { useEffect } from "react";
import { useLocation } from "react-router-dom";
// Components
import { UserProvider } from "../Layout/UserProvider";
// Hooks
import { useRouterHook } from "../../common/useRouterHook";
import { useAuthHook } from "../../common/useAuthHook";
// Constants
import { REQUEST_FAILURE, REQUEST_SUCCESS } from "../../constants/statuses";

const Subscribe = () => {
  const { isAuthenticated, getMeStatus } = useAuthHook();
  const { openPage } = useRouterHook();
  const { search } = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(search);
    const plan = query.get("plan");
    const yearly = query.get("yearly");
    const monthly = query.get("monthly");
    const currency = query.get("currency") || "";
    if (getMeStatus === REQUEST_SUCCESS || getMeStatus === REQUEST_FAILURE) {
      const subscribeUrl = `?subscribe=${plan}&${
        !!yearly ? `yearly=${yearly}` : `monthly=${monthly}`
      }&currency=${currency}`;
      isAuthenticated
        ? openPage(`/search-type${subscribeUrl}`)
        : openPage(`/sign-up${subscribeUrl}`);
    }
  }, [search, getMeStatus, isAuthenticated, openPage]);

  return (
    <UserProvider>
      <div></div>
    </UserProvider>
  );
};

export default Subscribe;
