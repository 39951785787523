// Static data
export const stageOptions = [
  { id: 6, name: "Unspecified" },
  { id: 7, name: "Private Equity" },
  { id: 8, name: "Early" },
  { id: 9, name: "Growth" },
  { id: 10, name: "Mezzanine" },
  { id: 11, name: "Start-up" },
  { id: 12, name: "Late" },
];

export const fundingOptionsMin = [
  { id: 13, name: "0" },
  { id: 14, name: "100.000" },
  { id: 15, name: "1.000.000" },
  { id: 16, name: "5.000.000" },
  { id: 17, name: "10.000.000" },
  { id: 18, name: "50.000.000" },
  { id: 19, name: "100.000.000" },
  { id: 20, name: "250.000.000" },
  { id: 21, name: "1.000.000.000" },
];

export const fundingOptionsMaxData = [
  { id: 22, name: "100.000" },
  { id: 23, name: "1.000.000" },
  { id: 24, name: "5.000.000" },
  { id: 25, name: "10.000.000" },
  { id: 26, name: "50.000.000" },
  { id: 27, name: "100.000.000" },
  { id: 28, name: "250.000.000" },
  { id: 29, name: "1.000.000.000" },
  { id: 30, name: "5.000.000.000" },
  { id: 31, name: "max" },
];

export const regionsOptions = [
  {
    name: "Africa",
    slug: "africa",
  },
  {
    name: "Central and West Asia",
    slug: "central-and-west-asia",
  },
  {
    name: "East and South Asia",
    slug: "east-and-south-asia",
  },
  {
    name: "Europe",
    slug: "europe",
  },
  {
    name: "Oceania",
    slug: "oceania",
  },
  {
    name: "South America",
    slug: "south-america",
  },
  {
    name: "Central America",
    slug: "central-america",
  },
  {
    name: "North America",
    slug: "north-america",
  },
];

export function* range(start, end, step, limit = 30) {
  let i = 0;
  while (start > end && limit !== i) {
    yield start;
    start -= step;
    i++;
  }
}

export const yearsChartArray = [
  "2002 - 2006",
  "2007 - 2011",
  "2012 - 2016",
  "2017",
  "2018",
  "2019",
  "2020",
  "2022",
  "2023",
  "2024",
];
export const yearOptionsMin = yearsChartArray.map(year => {
  return { name: year };
});

export const yearOptionsMaxData = yearsChartArray.map(year => {
  return { name: year };
});

export const teamsizeOptions = [
  { name: "0 to 10" },
  { name: "11 to 50" },
  { name: "51 to 100" },
  { name: "101 to 250" },
  { name: "251 to 500" },
  { name: "501 to 1000" },
  { name: "1001 to 5000" },
  { name: "5001 to 10000" },
  { name: "10001+" },
];

export const valuerScoreOptionsMin = [
  { id: 71, name: 50 },
  { id: 72, name: 60 },
  { id: 73, name: 70 },
  { id: 74, name: 80 },
  { id: 75, name: 90 },
  { id: 76, name: 95 },
];

export const valuerScoreOptionsMaxData = [
  { id: 77, name: 50 },
  { id: 78, name: 60 },
  { id: 79, name: 70 },
  { id: 80, name: 80 },
  { id: 81, name: 90 },
  { id: 82, name: 95 },
  { id: 83, name: 100 },
];

export const selectedFiltersEmptyData = {
  sector: "",
  subsector: "",
  subsectors: "",
  stage: "",
  fundingMin: "",
  fundingMax: "",
  teamsizeMin: "",
  teamsizeMax: "",
  location: "",
  yearMin: "",
  yearMax: "",
  matchingScoreMin: "",
  matchingScoreMax: "",
  regions: "",
  search: "",
  excludeKeywords: "",
  includeKeywords: "",
};

export const filterParamMapping = {
  sector: "sector",
  subsector: "subsector",
  subsectors: "subsectors",
  stage: "stage",
  fundingMin: "funding-from",
  fundingMax: "funding-to",
  teamsizeMin: "teamsize-min",
  teamsizeMax: "teamsize-max",
  location: "country",
  yearMin: "founded-from",
  yearMax: "founded-to",
  matchingScoreMin: "matching-score-from",
  matchingScoreMax: "matching-score-to",
  regions: "regions",
  search: "search",
  excludeKeywords: "exclude-keywords",
  includeKeywords: "include-keywords",
};

export const industryFilterParamMapping = {
  sector: "sector",
  subsectors: "subsectors",
  stage: "stage",
  fundingMin: "funding-from",
  fundingMax: "funding-to",
  teamsizeMin: "teamsize-min",
  teamsizeMax: "teamsize-max",
  location: "country",
  yearMin: "founded-from",
  yearMax: "founded-to",
  matchingScoreMin: "matching-score-from",
  matchingScoreMax: "matching-score-to",
  regions: "regions",
  search: "search",
  excludeKeywords: "exclude-keywords",
  includeKeywords: "include-keywords",
};

export const sortOptions = (
  isIndustry = false,
  isTechnology = false,
  isProject = false,
) =>
  !!(isIndustry || isTechnology || isProject)
    ? [
        // { id: 0, name: "Success", slug: "success_potential_score" },
        { id: 1, name: "Recently Founded", slug: "founded_date" },
        // { id: 2, name: "Growth", slug: "growth_score" },
        // { id: 3, name: "Maturity", slug: "market_maturity_score" },
        // { id: 4, name: "Innovation", slug: "innovation_score" },
        // { id: 5, name: "Sustainability", slug: "sustainability_score" },
        // { id: 6, name: "Data completeness", slug: "completeness_score" },
      ]
    : [
        { id: 0, name: "Match score", slug: "degree_of_fit" },
        { id: 1, name: "Recently Founded", slug: "founded_date" },
        // { id: 2, name: "Success", slug: "success_potential_score" },
        // { id: 3, name: "Growth", slug: "growth_score" },
        // { id: 4, name: "Maturity", slug: "market_maturity_score" },
        // { id: 5, name: "Innovation", slug: "innovation_score" },
        // { id: 6, name: "Sustainability", slug: "sustainability_score" },
        // { id: 7, name: "Data completeness", slug: "completeness_score" },
      ];
