import { createStyles, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(({ palette, spacing, breakpoint }) =>
  createStyles({
    root: {
      width: "100%",
      margin: `${spacing(9)}px auto`,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    productsWrapper: {
      [breakpoint.down("lg")]: {
        flexDirection: "column",
        marginTop: spacing(2),
      },
    },
    box: {
      margin: `12px ${spacing(1)}px`,
      background: palette.common.white,
      cursor: "pointer",
      display: "flex",
      alignItems: "flex-start",
      padding: `${spacing(4)}px ${spacing(3)}px`,
      borderRadius: 24,
      maxWidth: "calc(50% - 16px)",
      flex: "calc(50% - 16px)",
      [breakpoint.down("lg")]: {
        justifyContent: "flex-start",
        padding: `${spacing(3)}px ${spacing(2)}px`,
        minHeight: "auto",
        flex: "100%",
        maxWidth: "100%",
        margin: `12px 0`,
      },
    },
    product1Box: {
      [breakpoint.down("lg")]: {
        flexDirection: "column",
      },
    },
    product2Box: {
      [breakpoint.down("lg")]: {
        flexDirection: "column",
      },
    },
    productImageWrap: {
      height: 150,
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyItems: "center",
      justifyContent: "center",
      [breakpoint.up("md")]: {
        maxHeight: "none",
      },
      [breakpoint.down("sm")]: {
        display: "flex",
        height: "auto",
        maxHeight: 119,
        marginBottom: spacing(4),
      },
      "& > img": {
        marginTop: spacing(1),
        marginLeft: "auto",
        marginRight: spacing(4),
        [breakpoint.down("lg")]: {
          margin: "0",
        },
      },
    },
    productButton: {
      marginTop: spacing(2),
      [breakpoint.down("md")]: {
        width: "100%",
        marginBottom: spacing(2),
      },
    },
    product1ImageWrap: {
      [breakpoint.up("xl")]: {
        justifyContent: "flex-start",
      },
    },
    product2ImageWrap: {
      [breakpoint.up("xl")]: {
        justifyContent: "flex-start",
      },
      [breakpoint.down("xl")]: {
        justifyContent: "center",
      },
      [breakpoint.down("lg")]: {
        justifyContent: "center",
      },
    },
    outcomeCardSubtitle: {
      fontWeight: "300",
    },
    outcomeCardTitle: {
      fontWeight: "500",
      marginBottom: 12,
    },
  }),
);
