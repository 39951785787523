export const mixpanelUtils = () => {
  const properties = () => {
    return {
      $os: info().os(),
      $browser: info().browser(
        navigator.userAgent,
        navigator.vendor,
        window.opera,
      ),
      $referrer: document.referrer,
      $referring_domain: info().referringDomain(document.referrer),
      $device: info().device(navigator.userAgent),
      $current_url: window.location.href,
      $browser_version: info().browserVersion(
        navigator.userAgent,
        navigator.vendor,
        window.opera,
      ),
      $screen_height: window.screen.height,
      $screen_width: window.screen.width,
      /*$insert_id:
              Math.random().toString(36).substring(2, 10) +
              Math.random().toString(36).substring(2, 10),*/
      time: _timestamp() / 1000, // epoch time in seconds
    };
  };

  const info = () => {
    return {
      searchEngine(referrer) {
        if (referrer.search("https?://(.*)google.([^/?]*)") === 0) {
          return "google";
        } else if (referrer.search("https?://(.*)bing.com") === 0) {
          return "bing";
        } else if (referrer.search("https?://(.*)yahoo.com") === 0) {
          return "yahoo";
        } else if (referrer.search("https?://(.*)duckduckgo.com") === 0) {
          return "duckduckgo";
        } else {
          return null;
        }
      },

      /**
       * This function detects which browser is running this script.
       * The order of the checks are important since many user agents
       * include key words used in later checks.
       */
      browser(user_agent, vendor, opera) {
        vendor = vendor || ""; // vendor is undefined for at least IE9
        if (opera || _includes(user_agent, " OPR/")) {
          if (_includes(user_agent, "Mini")) {
            return "Opera Mini";
          }
          return "Opera";
        } else if (/(BlackBerry|PlayBook|BB10)/i.test(user_agent)) {
          return "BlackBerry";
        } else if (
          _includes(user_agent, "IEMobile") ||
          _includes(user_agent, "WPDesktop")
        ) {
          return "Internet Explorer Mobile";
        } else if (_includes(user_agent, "SamsungBrowser/")) {
          // https://developer.samsung.com/internet/user-agent-string-format
          return "Samsung Internet";
        } else if (
          _includes(user_agent, "Edge") ||
          _includes(user_agent, "Edg/")
        ) {
          return "Microsoft Edge";
        } else if (_includes(user_agent, "FBIOS")) {
          return "Facebook Mobile";
        } else if (_includes(user_agent, "Chrome")) {
          return "Chrome";
        } else if (_includes(user_agent, "CriOS")) {
          return "Chrome iOS";
        } else if (
          _includes(user_agent, "UCWEB") ||
          _includes(user_agent, "UCBrowser")
        ) {
          return "UC Browser";
        } else if (_includes(user_agent, "FxiOS")) {
          return "Firefox iOS";
        } else if (_includes(vendor, "Apple")) {
          if (_includes(user_agent, "Mobile")) {
            return "Mobile Safari";
          }
          return "Safari";
        } else if (_includes(user_agent, "Android")) {
          return "Android Mobile";
        } else if (_includes(user_agent, "Konqueror")) {
          return "Konqueror";
        } else if (_includes(user_agent, "Firefox")) {
          return "Firefox";
        } else if (
          _includes(user_agent, "MSIE") ||
          _includes(user_agent, "Trident/")
        ) {
          return "Internet Explorer";
        } else if (_includes(user_agent, "Gecko")) {
          return "Mozilla";
        } else {
          return "";
        }
      },

      /**
       * This function detects which browser version is running this script,
       * parsing major and minor version (e.g., 42.1). User agent strings from:
       * http://www.useragentstring.com/pages/useragentstring.php
       */
      browserVersion(userAgent, vendor, opera) {
        var browser = this.browser(userAgent, vendor, opera);
        var versionRegexs = {
          "Internet Explorer Mobile": /rv:(\d+(\.\d+)?)/,
          "Microsoft Edge": /Edge?\/(\d+(\.\d+)?)/,
          Chrome: /Chrome\/(\d+(\.\d+)?)/,
          "Chrome iOS": /CriOS\/(\d+(\.\d+)?)/,
          "UC Browser": /(UCBrowser|UCWEB)\/(\d+(\.\d+)?)/,
          Safari: /Version\/(\d+(\.\d+)?)/,
          "Mobile Safari": /Version\/(\d+(\.\d+)?)/,
          Opera: /(Opera|OPR)\/(\d+(\.\d+)?)/,
          Firefox: /Firefox\/(\d+(\.\d+)?)/,
          "Firefox iOS": /FxiOS\/(\d+(\.\d+)?)/,
          Konqueror: /Konqueror:(\d+(\.\d+)?)/,
          BlackBerry: /BlackBerry (\d+(\.\d+)?)/,
          "Android Mobile": /android\s(\d+(\.\d+)?)/,
          "Samsung Internet": /SamsungBrowser\/(\d+(\.\d+)?)/,
          "Internet Explorer": /(rv:|MSIE )(\d+(\.\d+)?)/,
          Mozilla: /rv:(\d+(\.\d+)?)/,
        };
        var regex = versionRegexs[browser];
        if (regex === undefined) {
          return null;
        }
        var matches = userAgent.match(regex);
        if (!matches) {
          return null;
        }
        return parseFloat(matches[matches.length - 2]);
      },

      os() {
        var a = navigator.userAgent;
        if (/Windows/i.test(a)) {
          if (/Phone/.test(a) || /WPDesktop/.test(a)) {
            return "Windows Phone";
          }
          return "Windows";
        } else if (/(iPhone|iPad|iPod)/.test(a)) {
          return "iOS";
        } else if (/Android/.test(a)) {
          return "Android";
        } else if (/(BlackBerry|PlayBook|BB10)/i.test(a)) {
          return "BlackBerry";
        } else if (/Mac/i.test(a)) {
          return "Mac OS X";
        } else if (/Linux/.test(a)) {
          return "Linux";
        } else if (/CrOS/.test(a)) {
          return "Chrome OS";
        } else {
          return "";
        }
      },

      device(user_agent) {
        if (/Windows Phone/i.test(user_agent) || /WPDesktop/.test(user_agent)) {
          return "Windows Phone";
        } else if (/iPad/.test(user_agent)) {
          return "iPad";
        } else if (/iPod/.test(user_agent)) {
          return "iPod Touch";
        } else if (/iPhone/.test(user_agent)) {
          return "iPhone";
        } else if (/(BlackBerry|PlayBook|BB10)/i.test(user_agent)) {
          return "BlackBerry";
        } else if (/Android/.test(user_agent)) {
          return "Android";
        } else {
          return "";
        }
      },

      referringDomain(referrer) {
        var split = referrer.split("/");
        if (split.length >= 3) {
          return split[2];
        }
        return "";
      },
    };
  };

  const _includes = (str, needle) => {
    return str.indexOf(needle) !== -1;
  };

  const _timestamp = () => {
    Date.now =
      Date.now ||
      function () {
        return +new Date();
      };
    return Date.now();
  };

  const removeHTMLTags = str => {
    return str && str.replace(/(<([^>]+)>)/gi, "");
  };

  return { properties, removeHTMLTags };
};
