import useStyles from "./style.js";
import Lock from "../../assets/icons/ProfileLockImage.png";
import CloseIcon from "../../assets/icons/CloseIcon.svg";

const LockAlert = ({
  lockImg,
  removePadding,
  children,
  onClose,
  removeImg,
  removeCloseButton,
  closeIconPosition,
  bottomMarginAdjust,
}) => {
  const classes = useStyles({
    lockImg,
    removePadding,
    closeIconPosition,
    bottomMarginAdjust,
  });
  const imgToShow = lockImg || Lock;
  return (
    <div className={classes.root} test-id="lock-alert">
      <div className={classes.lockAlertWrap}>
        <div className={classes.lockAlert}>
          {!removeImg && (
            <div>
              <img src={imgToShow} className={classes.img} />
            </div>
          )}
          <div className={classes.content}>{children}</div>
        </div>
        {!removeCloseButton && (
          <div>
            <img
              src={CloseIcon}
              className={classes.closeIcon}
              onClick={onClose}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default LockAlert;
