import { call, put } from "redux-saga/effects";
import { actions } from "../slice";
import {
  fetchCreditsSummary,
  fetchPlan,
  postBuyCredits,
} from "../saga/helpers";
import { actions as resultsActions } from "../../results/slice";

function* buyCredits(data) {
  try {
    yield put(resultsActions.clearResults());
    yield put(actions.buyCreditsStatusPending());
    const response = yield call(postBuyCredits, data);
    yield put(actions.setBuyCreditsResponse(response.data));
    yield put(actions.buyCreditsStatusSuccess());
  } catch (err) {
    yield put(actions.buyCreditsStatusFailure());
  }
}

function* getCreditsSummary() {
  try {
    yield put(resultsActions.clearResults());
    yield put(actions.getCreditsSummaryStatusPending());
    const response = yield call(fetchCreditsSummary);
    yield put(actions.setCreditsSummary(response.data));
    yield put(actions.getCreditsSummaryStatusSuccess());
  } catch (err) {
    yield put(actions.getCreditsSummaryStatusFailure());
  }
}

function* getPlan(plan, period, amount, currency) {
  try {
    yield put(actions.getPlanStatusPending());
    const response = yield call(fetchPlan, plan, period, amount, currency);
    yield put(actions.setPlan(response.data));
    yield put(actions.getPlanStatusSuccess());
  } catch (err) {
    yield put(actions.getPlanStatusFailure());
  }
}

export { buyCredits, getCreditsSummary, getPlan };
