import { createAction } from "@reduxjs/toolkit";
import { REDUCER_PREFIX } from "./slice";

export const startFreeTrialRequest = createAction(
  `${REDUCER_PREFIX}/startFreeTrialRequest`,
);
export const sendEmailVerificationRequest = createAction(
  `${REDUCER_PREFIX}/sendEmailVerificationRequest`,
);

export const updateFreeTrialRequest = createAction(
  `${REDUCER_PREFIX}/updateFreeTrialRequest`,
);

export const emailValidationRequest = createAction(
  `${REDUCER_PREFIX}/emailValidationRequest`,
);
