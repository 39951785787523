import { call } from "redux-saga/effects";
import axios from "axios";
import {
  ACTIVITIES_URL,
  DASHBOARD_URL,
  getActivitiesSeenUrl,
} from "../../../constants/endpoints";

function* getDashboard() {
  const response = yield call(axios, {
    method: "GET",
    url: `${DASHBOARD_URL}`,
    withCredentials: true,
  });

  return response.data;
}

function* putActivitySeen(id) {
  const response = yield call(axios, {
    method: "PUT",
    url: `${getActivitiesSeenUrl(id)}`,
    withCredentials: true,
  });

  return response.data;
}

function* getActivities() {
  const response = yield call(axios, {
    method: "GET",
    url: `${ACTIVITIES_URL}?per-page=100`,
    withCredentials: true,
  });

  return response.data;
}

export { getDashboard, putActivitySeen, getActivities };
