import React from "react";
import classnames from "classnames";
import { useStyles } from "./style.js";
import ScopeGuidance from "./NewSearch/ScopeGuidance.js";

const AssistedScope = () => {
  const classes = useStyles();

  return (
    <div className={classnames([classes.root, classes.searchTypeRoot])}>
      <div className={classes.mainSearchBoxWrapper}>
        <ScopeGuidance />
      </div>
    </div>
  );
};

export default AssistedScope;
