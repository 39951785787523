import { createSlice } from "@reduxjs/toolkit";
import {
  REQUEST_FAILURE,
  REQUEST_PENDING,
  REQUEST_SUCCESS,
  REQUEST_UNDEFINED,
} from "../../constants/statuses";

export const INITIAL_STATE = {
  pool: [],
  allTechologiesPagination: {},
  fetchAllTechnologiesStatus: REQUEST_UNDEFINED,
  fetchTechnologyStatus: REQUEST_UNDEFINED,
  allTechologies: [],
  allTechologiesTotalPages: 0,
  allTechologiesTotalCount: 0,
  allTechologiesCurrentPage: null,
  technology: null,
  technologyCompanies: [],
  totalCount: null,
  currentPage: 1,
  totalPages: 0,
  getResultsPaginationStatus: REQUEST_UNDEFINED,
  getTechnologyCompanyStatus: REQUEST_UNDEFINED,
  technologyCompany: {},
  isGuest: false,
  enrichTechnologyCompanyStatus: REQUEST_UNDEFINED,
  getTechnologyCompaniesCountStatus: REQUEST_UNDEFINED,
  companiesCount: null,
  enrichTechnologyStatus: REQUEST_UNDEFINED,
  getTechnologyCompaniesChartStatus: REQUEST_UNDEFINED,
  getTechnologyCompaniesTeamSizeChartStatus: REQUEST_UNDEFINED,
  suggestTechnologyStatus: REQUEST_UNDEFINED,
  technologyCompaniesChart: null,
  technologyCompaniesTeamSizeChart: null,
  unspecifiedAll: false,
  filterStats: null,
  lastFilteredField: null,
};

export const REDUCER_PREFIX = "technologies";

const { reducer, actions } = createSlice({
  name: REDUCER_PREFIX,
  initialState: INITIAL_STATE,
  reducers: {
    fetchAllTechnologiesPending: state => {
      state.fetchAllTechnologiesStatus = REQUEST_PENDING;
    },
    fetchAllTechnologiesSuccess: state => {
      state.fetchAllTechnologiesStatus = REQUEST_SUCCESS;
    },
    fetchAllTechnologiesFailure: state => {
      state.fetchAllTechnologiesStatus = REQUEST_FAILURE;
    },
    setAllTechnologies: (state, { payload }) => {
      state.allTechologies = payload.results;
      state.allTechologiesPagination = payload.pagination;
      state.allTechologiesTotalPages = payload.pagination.total_pages;
      state.allTechologiesTotalCount = payload.pagination.total;
      state.allTechologiesCurrentPage = payload.pagination.current_page;
    },
    fetchTechnologyPending: state => {
      state.fetchTechnologyStatus = REQUEST_PENDING;
    },
    fetchTechnologySuccess: state => {
      state.fetchTechnologyStatus = REQUEST_SUCCESS;
    },
    fetchTechnologyFailure: state => {
      state.fetchTechnologyStatus = REQUEST_FAILURE;
    },
    getResultsPaginationPending: state => {
      state.getResultsPaginationStatus = REQUEST_PENDING;
    },
    getResultsPaginationSuccess: state => {
      state.getResultsPaginationStatus = REQUEST_SUCCESS;
    },
    getResultsPaginationFailure: state => {
      state.getResultsPaginationStatus = REQUEST_FAILURE;
    },
    fetchTechnologyCompaniesCountPending: state => {
      state.getTechnologyCompaniesCountStatus = REQUEST_PENDING;
    },
    fetchTechnologyCompaniesCountSuccess: state => {
      state.getTechnologyCompaniesCountStatus = REQUEST_SUCCESS;
    },
    fetchTechnologyCompaniesCountFailure: state => {
      state.getTechnologyCompaniesCountStatus = REQUEST_FAILURE;
    },
    setTechnologyCompaniesCount: (
      state,
      { payload: { sliceData, lastFilteredField, lastFilteredFieldValue } },
    ) => {
      state.companiesCount = sliceData.companies.pagination.total;
      let lastFilteredState = { ...state.filterStats[lastFilteredField] };
      if (
        (lastFilteredField === "investment_stages" ||
          lastFilteredField === "countries" ||
          lastFilteredField === "regions") &&
        lastFilteredFieldValue &&
        lastFilteredFieldValue.length > 0
      ) {
        state.filterStats = {
          ...sliceData.filter_stats,
          [lastFilteredField]: lastFilteredState,
        };
      } else {
        state.filterStats = sliceData.filter_stats;
      }
    },
    setLastFilteredField: (state, { payload }) => {
      state.lastFilteredField = payload;
    },
    setTechnologyResults: (state, { payload }) => {
      state.technology = payload.technology;
      state.technologyCompanies = [];
      payload.companies.results.forEach(company => {
        const {
          name,
          degree_of_fit,
          deck,
          sectors,
          subsectors,
          description,
          founded_date,
          hq_city,
          hq_country,
          investment_stage,
          number_of_employees,
          total_funding_amount_dollars,
          id,
          slug,
          success_potential_score,
          growth_score,
          maturity_score,
          innovation_score,
          completeness_score,
          website,
          sustainability_score,
          enrichment_request,
          ready_for_release,
          priority,
          search_id,
          valuer_score,
          user_vote,
          voters,
          comments_cnt,
          projects,
        } = company;

        const uniqueResult = {
          id,
          name,
          degree_of_fit,
          deck,
          sectors,
          subsectors,
          description,
          founded_date,
          hq_city,
          hq_country,
          investment_stage,
          number_of_employees,
          total_funding_amount_dollars: total_funding_amount_dollars
            ? Math.round(parseInt(total_funding_amount_dollars))
            : "",
          success_potential_score,
          growth_score,
          maturity_score,
          innovation_score,
          slug,
          website,
          sustainability_score,
          completeness_score,
          enrichment_request,
          ready_for_release,
          priority,
          search_id,
          valuer_score,
          user_vote,
          voters,
          comments_cnt,
          projects,
        };
        if (!state.technologyCompanies.filter(e => e.id === id).length > 0) {
          state.technologyCompanies.push(uniqueResult);
        }
      });
      state.companiesCount = payload.companies.pagination
        ? payload.companies.pagination.total
        : payload.companies.results.length;
      state.totalCount = payload.companies.pagination
        ? payload.companies.pagination.total
        : payload.companies.results.length;
      state.currentPage = payload.companies.pagination
        ? payload.companies.pagination.current_page
        : 1;
      state.totalPages = payload.companies.pagination
        ? payload.companies.pagination.total_pages
        : 1;
      state.filterStats = payload.filter_stats;
      state.getResultsPaginationStatus = REQUEST_UNDEFINED;
    },
    setTechnology: (state, { payload }) => {
      state.technology = payload.technology;
    },
    clearTechnology: state => {
      state.technology = null;
      state.totalCount = null;
      state.filterStats = null;
      state.getResultsPaginationStatus = REQUEST_UNDEFINED;
      state.technologyCompanies = [];
      state.currentPage = 1;
      state.searchType = null;
      state.totalPages = null;
    },
    clearCompaniesResults: state => {
      state.technologyCompanies = [];
      state.currentPage = 1;
      state.searchType = null;
      state.totalPages = null;
    },
    getTechnologyCompanyPending: state => {
      state.getTechnologyCompanyStatus = REQUEST_PENDING;
    },
    getTechnologyCompanySuccess: state => {
      state.getTechnologyCompanyStatus = REQUEST_SUCCESS;
    },
    getTechnologyCompanyFailure: state => {
      state.getTechnologyCompanyStatus = REQUEST_FAILURE;
    },
    setCompany: (state, { payload }) => {
      state.technologyCompany = payload;
    },
    setIsGuest: (state, { payload }) => {
      state.isGuest = payload;
    },
    getEnrichTechnologyCompanyPending: state => {
      state.enrichTechnologyCompanyStatus = REQUEST_PENDING;
    },
    getEnrichTechnologyCompanySuccess: state => {
      state.enrichTechnologyCompanyStatus = REQUEST_SUCCESS;
    },
    getEnrichTechnologyCompanyFailure: state => {
      state.enrichTechnologyCompanyStatus = REQUEST_FAILURE;
    },
    enrichTechnologyPending: state => {
      state.enrichTechnologyStatus = REQUEST_PENDING;
    },
    enrichTechnologySuccess: state => {
      state.enrichTechnologyStatus = REQUEST_SUCCESS;
    },
    enrichTechnologyFailure: state => {
      state.enrichTechnologyStatus = REQUEST_FAILURE;
    },
    getTechnologyCompaniesChartPending: state => {
      state.getTechnologyCompaniesChartStatus = REQUEST_PENDING;
    },
    getTechnologyCompaniesChartSuccess: state => {
      state.getTechnologyCompaniesChartStatus = REQUEST_SUCCESS;
    },
    getTechnologyCompaniesChartFailure: state => {
      state.getTechnologyCompaniesChartStatus = REQUEST_FAILURE;
    },
    setTechnologyCompaniesChart: (state, { payload }) => {
      state.technologyCompaniesChart = payload;
    },
    getTechnologyCompaniesTeamSizeChartPending: state => {
      state.getTechnologyCompaniesTeamSizeChartStatus = REQUEST_PENDING;
    },
    getTechnologyCompaniesTeamSizeChartSuccess: state => {
      state.getTechnologyCompaniesTeamSizeChartStatus = REQUEST_SUCCESS;
    },
    getTechnologyCompaniesTeamSizeChartFailure: state => {
      state.getTechnologyCompaniesTeamSizeChartStatus = REQUEST_FAILURE;
    },
    setTechnologyCompaniesTeamSizeChart: (state, { payload }) => {
      state.unspecifiedAll = payload.Unspecified.percentage === 100;
      delete payload.Unspecified;
      state.technologyCompaniesTeamSizeChart = payload;
    },
    suggestTechnologyPending: state => {
      state.suggestTechnologyStatus = REQUEST_PENDING;
    },
    suggestTechnologySuccess: state => {
      state.suggestTechnologyStatus = REQUEST_SUCCESS;
    },
    suggestTechnologyFailure: state => {
      state.suggestTechnologyStatus = REQUEST_FAILURE;
    },
  },
});
export { reducer, actions };
