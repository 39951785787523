import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions as searchActions } from "../../../../store/search/slice";
import { getSubscriptionSelector } from "../../../../store/auth/selectors";
// Hooks
import { useTranslation } from "react-i18next";
import { useStyles } from "./style.js";
import { useRouterHook } from "../../../../common/useRouterHook";
import { useAuthHook } from "../../../../common/useAuthHook";
// UI components
import Box from "@material-ui/core/Box";
import Typography from "../../../../components/Typography/Typography";
import Button from "../../../../components/Button/Button";
import SelectProducts from "../../../../components/SelectProducts/SelectProducts";
//Assets
import NoResultsIcon from "../../../../assets/images/DiggingHoleIllustration.png";
import NoSearchesIcon from "../../../../assets/icons/NoSearchesIcon.png";
import ProjectNoResultsIcon from "../../../../assets/icons/ProjectNoResults.svg";
import { getSearchesSelector } from "../../../../store/results/selectors";
import { actions as authActions } from "../../../../store/auth/slice";
import { mixpanelTrackRequest } from "../../../../store/mixpanel/requests";
import useLocalStorage from "../../../../common/useLocalStorage";

const NoResults = ({
  isProjectPage = window.location.pathname.includes("project"),
  showButton = true,
}) => {
  const { t } = useTranslation();
  const { user } = useAuthHook();
  const classes = useStyles();
  const { openPage } = useRouterHook();
  const dispatch = useDispatch();
  const getSearches = useSelector(getSearchesSelector);
  const [noSearches, setNoSearches] = useState(false);
  const subscription = useSelector(getSubscriptionSelector());
  const { clearStorage: clearStagePage } = useLocalStorage("results", "page");
  const { clearStorage } = useLocalStorage("results", null);

  useEffect(() => {
    return () => {
      setNoSearches(false);
    };
  }, []);

  const startSearchBtnClick = () => {
    if (
      user &&
      !user.company &&
      subscription &&
      (subscription.name === "free" || subscription.free_trial) &&
      getSearches.length > 1
    ) {
      dispatch(authActions.completeProfileModalShowTrue());
    } else {
      dispatch(mixpanelTrackRequest({ eventName: "Start new search" }));
      dispatch(searchActions.clearSearch());
      dispatch(searchActions.clearLookalikeSearch());
      clearStorage();
      clearStagePage();
      openPage("/search-type");
    }
  };

  const goToSearch = () => {
    dispatch(searchActions.clearSearch());
    openPage("/search-type");
  };

  useEffect(() => {
    if (
      (getSearches && getSearches.length === 0) ||
      (typeof getSearches === "object" && !Object(getSearches).values)
    ) {
      setNoSearches(true);
    } else {
      setNoSearches(false);
    }
  }, [getSearches]);

  return !isProjectPage ? (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      className={classes.root}>
      <Typography className={classes.title} variant="h6" color="black">
        <strong>
          {noSearches
            ? t("results.noSearchesTitle")
            : t("results.noResultsTitle")}
        </strong>
      </Typography>
      <Typography className={classes.subtitle} variant="subtitle2" color="text">
        {noSearches
          ? t("results.noSearchesSubtitle")
          : t("results.noResultsSubtitle")}
      </Typography>
      {noSearches ? (
        <img
          alt="No Searches Icon"
          src={NoSearchesIcon}
          className={classes.image}
        />
      ) : (
        <img
          alt="No Results Icon"
          src={NoResultsIcon}
          className={classes.image}
        />
      )}
      {showButton && (
        <Button
          size="large"
          onClick={goToSearch}
          variant="primary"
          className={classes.button}>
          {noSearches ? t("results.startSearch") : t("results.searchAgain")}
        </Button>
      )}
    </Box>
  ) : (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      className={classes.projectRoot}>
      <Box display="flex">
        <div className={classes.projectNoResultsBodyWrapper}>
          <div className={classes.projectNoResultsBody}>
            <Button
              size="large"
              className={classes.startSearchButton}
              onClick={startSearchBtnClick}>
              {t("noResults.project.startSearchButton")}
            </Button>
            <div className={classes.projectNoResultsBodyText}>
              <Typography
                className={classes.projectNoResultsBodyTextTitle}
                component="div"
                variant="subtitle1"
                color="black">
                {t("noResults.project.projectNoResultsBodyTextTitle")}
              </Typography>
              <Typography
                className={classes.projectNoResultsBodyTextSubtitle}
                component="div"
                variant="bodyButton"
                color="textLighter">
                {t("noResults.project.projectNoResultsBodyTextSubtitle")}
              </Typography>
            </div>
          </div>
        </div>
        <div className={classes.projectNoResultsImageWrapper}>
          <img alt="No Project Results" src={ProjectNoResultsIcon} />
        </div>
      </Box>
      <div className={classes.selectProducts}>
        <SelectProducts />
      </div>
    </Box>
  );
};
export default NoResults;
