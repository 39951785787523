import React, { useEffect, useState } from "react";
import classnames from "classnames";
// UI components
import Typography from "../../components/Typography/Typography";
import Button from "../../components/Button/Button";
import Link from "../../components/Link/Link";
import Box from "@material-ui/core/Box";
import { UserProvider } from "../Layout/UserProvider";
// Hooks
import { useTranslation } from "react-i18next";
import { useStyles } from "./style.js";
import { useRouterHook } from "../../common/useRouterHook";
import { useAppEventTracking } from "../../common/useAppEventTracking";
// Assets
import SignUpIllustration from "../../assets/icons/SignInManIllustration.svg";
import LogoNavbar from "../../assets/icons/LogoNavbar.svg";
import { ReactComponent as ArrowRight } from "../../assets/icons/ArrowRight.svg";
// Redux
import { sendResetLinkRequest } from "../../store/auth/requests";
import { useDispatch, useSelector } from "react-redux";
import {
  getSendResetLinkMessageSelector,
  getSendResetLinkStatusSelector,
} from "../../store/auth/selectors";
// Constants
import { REQUEST_SUCCESS } from "../../constants/statuses";

const ForgotPassword = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { goBack } = useRouterHook();
  const [emailValue, setEmailValue] = useState("");
  const [emailValid, setEmailValid] = useState(true);
  const dispatch = useDispatch();
  const sendResetLinkStatus = useSelector(getSendResetLinkStatusSelector);
  const sendResetLinkMessage = useSelector(getSendResetLinkMessageSelector);
  const { mixpanelTrackEvent } = useAppEventTracking();
  const validateEmail = email => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const resetPassword = e => {
    e.preventDefault();
    dispatch(sendResetLinkRequest({ data: { email: emailValue } }));
  };

  const handleEmailChange = e => {
    setEmailValue(e.target.value);
    setEmailValid(validateEmail(e.target.value));
  };

  useEffect(() => {
    mixpanelTrackEvent("Forgot password");
  }, []);

  return (
    <UserProvider>
      <Box display="flex" flexDirection="row" className={classes.root}>
        <Link
          href="#"
          variant="body2"
          rotateIcon
          color="textSecondary"
          onClick={goBack}
          className={classes.goBack}>
          {t("signUp.goBack")}
          <ArrowRight />
        </Link>
        <Box
          display="flex"
          flex={1}
          flexDirection="column"
          className={classes.illustrationWrapper}>
          <img
            src={SignUpIllustration}
            className={classes.signInIllustration}
            alt={t("signUp.illustrationTitle")}
          />
          <div className={classes.logoWrapperFrogorPasswordPage}>
            <img
              src={LogoNavbar}
              className={classes.logo}
              alt={t("companyName")}
            />
          </div>
        </Box>
        <div className={classes.formWrapperRoot}>
          <Box
            display="flex"
            flex={1}
            flexDirection="column"
            className={classes.formWrapper}>
            <Typography className={classes.signUpTitle} variant="h4">
              <strong>{t("forgotPassword.title")}</strong>
            </Typography>
            <form onSubmit={resetPassword} className={classes.form}>
              <div className={classes.formFieldWrapper}>
                <div className={classes.field}>
                  <label className={classes.inputLabel} for="email">
                    Email
                  </label>
                  <input
                    className={classnames([
                      classes.input,
                      {
                        [classes.errorField]: !emailValid,
                      },
                    ])}
                    id="email"
                    onChange={handleEmailChange}
                    value={emailValue}
                  />
                </div>
                {!emailValid && (
                  <div className={classes.errorMsg}>
                    {t("signIn.emailError")}
                  </div>
                )}
              </div>
              {/* <ReCAPTCHA
                sitekey={RECAPTCHA_SITE_KEY}
              /> */}
              <div className={classes.createAccountButton}>
                {sendResetLinkMessage && (
                  <Typography
                    component="div"
                    className={classes.formError}
                    variant="caption"
                    color={
                      sendResetLinkStatus === REQUEST_SUCCESS
                        ? "primary"
                        : "error"
                    }>
                    {sendResetLinkMessage}
                  </Typography>
                )}
                <Button
                  variant="primary"
                  disabled={!emailValid || emailValue.length === 0}>
                  {t("forgotPassword.cta")}
                </Button>
              </div>
            </form>
          </Box>
        </div>
      </Box>
    </UserProvider>
  );
};

export default ForgotPassword;
