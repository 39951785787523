import { all, takeEvery } from "redux-saga/effects";
import {
  addCommentRequest,
  addCompanyTagRequest,
  deleteCommentRequest,
  deleteCompanyTagRequest,
  fetchCommentsRequest,
  fetchDeckClientPreviewRequest,
  getCompanyTagRequest,
  getSharedModelRequest,
  getSimilarCompaniesRequest,
  getSuggestedCompaniesRequest,
  selectCompanyTagRequest,
  updateCommentRequest,
  voteCommentRequest,
} from "../requests";
import {
  addComment,
  addCompanyTag,
  deleteComment,
  deleteCompanyTag,
  fetchComments,
  fetchCompanyTags,
  fetchDeckClientPreview,
  getSharedModel,
  getSimilarCompanies,
  getSuggestedCompanies,
  selectCompanyTag,
  updateComment,
  voteComment,
} from "./workers";

const convertTypeFromAction = actionCreator => actionCreator.toString();

function* watchAddComment() {
  yield takeEvery(
    convertTypeFromAction(addCommentRequest),
    ({ payload: { data } }) => addComment(data),
  );
}

function* watchFetchComments() {
  yield takeEvery(
    convertTypeFromAction(fetchCommentsRequest),
    ({ payload: { cid } }) => fetchComments(cid),
  );
}

function* watchUpdateComment() {
  yield takeEvery(
    convertTypeFromAction(updateCommentRequest),
    ({ payload: { data } }) => updateComment(data),
  );
}

function* watchDeleteComment() {
  yield takeEvery(
    convertTypeFromAction(deleteCommentRequest),
    ({ payload: { data } }) => deleteComment(data),
  );
}

function* watchGetSharedModel() {
  yield takeEvery(
    convertTypeFromAction(getSharedModelRequest),
    ({ payload: { token } }) => getSharedModel(token),
  );
}

function* watchVoteComment() {
  yield takeEvery(
    convertTypeFromAction(voteCommentRequest),
    ({ payload: { data } }) => voteComment(data),
  );
}

function* watchAddCompanyTag() {
  yield takeEvery(
    convertTypeFromAction(addCompanyTagRequest),
    ({ payload: { data } }) => addCompanyTag(data),
  );
}

function* watchGetCompanyTag() {
  yield takeEvery(
    convertTypeFromAction(getCompanyTagRequest),
    ({ payload: { slug } }) => fetchCompanyTags(slug),
  );
}

function* watchDeleteCompanyTag() {
  yield takeEvery(
    convertTypeFromAction(deleteCompanyTagRequest),
    ({ payload: { data } }) => deleteCompanyTag(data),
  );
}

function* watchSelectCompanyTag() {
  yield takeEvery(
    convertTypeFromAction(selectCompanyTagRequest),
    ({ payload: { data } }) => selectCompanyTag(data),
  );
}

function* watchGetSimilarCompanies() {
  yield takeEvery(
    convertTypeFromAction(getSimilarCompaniesRequest),
    ({ payload: { data } }) => getSimilarCompanies(data),
  );
}

function* watchGetSuggestedCompanies() {
  yield takeEvery(
    convertTypeFromAction(getSuggestedCompaniesRequest),
    ({ payload: { data } }) => getSuggestedCompanies(data),
  );
}

function* watchFetchDeckClientPreview() {
  yield takeEvery(
    convertTypeFromAction(fetchDeckClientPreviewRequest),
    ({ payload: { slug } }) => fetchDeckClientPreview(slug),
  );
}

function* saga() {
  yield all([
    watchFetchComments(),
    watchAddComment(),
    watchUpdateComment(),
    watchDeleteComment(),
    watchGetSharedModel(),
    watchVoteComment(),
    watchAddCompanyTag(),
    watchGetCompanyTag(),
    watchDeleteCompanyTag(),
    watchSelectCompanyTag(),
    watchGetSimilarCompanies(),
    watchGetSuggestedCompanies(),
    watchFetchDeckClientPreview(),
  ]);
}

export { saga };
