import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCookieRequest } from "../store/auth/requests";
import { getCookieStatusSelector } from "../store/auth/selectors";

export const useCookiesHook = () => {
  const dispatch = useDispatch();
  const getCookieStatus = useSelector(getCookieStatusSelector);

  useEffect(() => {
    dispatch(fetchCookieRequest());
  }, [dispatch]);

  const getCookie = cname => {
    let name = cname + "=";
    let ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  };

  return { getCookieStatus, getCookie };
};
