import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import usePrevious from "../../common/usePrevious";
// UI components
import Multiselect from "../Multiselect/Multiselect";
import FiltersModal from "../FiltersModal/FiltersModal";
import WarningModal from "../../pages/Results/components/modals/WarningModal/WarningModal";
import Typography from "../../components/Typography/Typography";
import AreYouSureModal from "../../components/AreYouSureModal/AreYouSureModal";
// Hooks
import { useTranslation } from "react-i18next";
import useStyles from "./styles";
import { useAppEventTracking } from "../../common/useAppEventTracking";
//Assets
import { ReactComponent as ArrowIcon } from "../../assets/icons/ArrowDownBlue.svg";
import { ReactComponent as SortDown } from "../../assets/icons/SortDown.svg";
import { ReactComponent as SortUp } from "../../assets/icons/SortUp.svg";
import { ReactComponent as ResultsOverview } from "../../assets/icons/ResultsOverview.svg";
import { ReactComponent as OpenIcon } from "../../assets/icons/OpenIcon.svg";
import { ReactComponent as ListViewIcon } from "../../assets/icons/ListViewIcon.svg";
import { ReactComponent as CardViewIcon } from "../../assets/icons/CardViewIcon.svg";
import { ReactComponent as ClusterIcon } from "../../assets/icons/ClusterIcon.svg";
import { ReactComponent as ClusterIconActive } from "../../assets/icons/ClusterIconActive.svg";
// Constants
import { sortOptions } from "../../constants/filters";
import { getPricingPage } from "../../constants/endpoints";
// Redux
import { getCompaniesChartStatusSelector } from "../../store/results/selectors";
import { getIndustryCompaniesChartStatusSelector } from "../../store/industries/selectors";
import { getTechnologyCompaniesChartStatusSelector } from "../../store/technologies/selectors";
import { exportCsvRequest } from "../../store/orders/requests";
import { actions as resultsActions } from "../../store/results/slice";

const HeaderFilters = ({
  isAuthenticated,
  searchResults,
  onFilterSelected,
  onSortChange,
  onSearch,
  filters,
  total,
  resetFilters,
  stageOptions,
  regionsOptions,
  handleRegionChange,
  fundingOptionsMin,
  fundingOptionsMax,
  yearOptionsMin,
  yearOptionsMax,
  teamsizeOptions,
  valuerScoreOptionsMin,
  valuerScoreOptionsMax,
  teamSizeOptionsMax,
  matchingScoreMin,
  matchingScoreMax,
  handleFieldChange,
  handleSearchChange,
  handleExcludeKeywordsChange,
  handleIncludeKeywordsChange,
  handleSectorChange,
  handleSubsectorChange,
  handleStageChange,
  handleLocationChange,
  resetFilter,
  handleFundingMinChange,
  handleYearMinChange,
  handleMatchingScoreMinChange,
  handleTeamSizeMinChange,
  allSectors,
  allSubsectors,
  selectedFilters,
  allCountries,
  setSelectedFilters,
  setDisplayedFilters,
  displayedFilters,
  setModalFilters,
  isLookAlikeResults,
  searchType,
  user,
  isIndustry,
  isTechnology,
  isProject,
  countSelector,
  localStorageSlugPrefix,
  slug,
  request,
  countRequest,
  page,
  token,
  searchTypeLook,
  overviewVisibility,
  setOverviewVisibility,
  clusterVisibility,
  setClusterVisibility,
  isLikedCompanies,
  name,
  filterStats,
  handleSectorsClose,
  handleSubsectorsClose,
  preselectedSectors,
  setPreselectedSectors,
  preselectedSubsectors,
  setPreselectedSubsectors,
  getCountStatusSelector,
  activeView,
  setActiveView,
  companiesPageId,
  modalFilterNameValue,
  filterNameValue,
  filterMapping,
  currentSearchSid,
  isWorkspace,
  showClusterTab,
  selected,
  setSelected,
}) => {
  const prevActiveView = usePrevious(activeView);
  const [activeResultView, setActiveResultView] = useState(activeView);
  const { t } = useTranslation();
  const classes = useStyles();
  let { state: locationState, search } = useLocation();
  const { mixpanelTrackEvent } = useAppEventTracking();
  const { appTrackEvent } = useAppEventTracking();
  const companiesChartStatus = useSelector(getCompaniesChartStatusSelector);
  const industryCompaniesChartStatus = useSelector(
    getIndustryCompaniesChartStatusSelector,
  );
  const technologyCompaniesChartStatus = useSelector(
    getTechnologyCompaniesChartStatusSelector,
  );
  const [searchValue, setSearchValue] = useState(
    sessionStorage.getItem(`${localStorageSlugPrefix}-${slug}-FilterSearch`),
  );
  const [displayedFiltersCount, setDisplayedFiltersCount] = useState(0);
  const [downloadProps, setDownloadProps] = useState();
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [showAreYouSureModal, setShowAreYouSureModal] = useState(false);
  const [showDownloadsExceededModal, setShowDownloadsExceededModal] =
    useState(false);
  const [isProjectsPage, setIsProjectsPage] = useState(false);
  const [clusterIconActive, setClusterIconActive] = useState(false);
  const [clusterTabHover, setClusterTabHover] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    prevActiveView !== activeView && setActiveResultView(activeView);
  }, [activeView]);

  useEffect(() => {
    let count = 0;
    Object.entries(displayedFilters).map(filter => {
      filter[0] !== "subsectors" &&
        filter[1] !== "" &&
        filter[1] &&
        filter[1].length !== 0 &&
        filter[1] !== undefined &&
        count++;
    });
    setDisplayedFiltersCount(count);
  }, [displayedFilters]);

  const storageResultsFilterSortValue = sessionStorage.getItem(
    `${localStorageSlugPrefix}-${slug}-FilterSortValue`,
  );
  const sortOption = sortOptions(isIndustry, isTechnology, isProject).find(
    result => result.slug === storageResultsFilterSortValue,
  );
  const storageResultsFilterSortDirection = sessionStorage.getItem(
    `${localStorageSlugPrefix}-${slug}-FilterSortDirection`,
  );
  const [sortValue, setSortValue] = useState(
    storageResultsFilterSortValue
      ? sortOption
      : sortOptions(isIndustry, isTechnology, isProject)[0],
  );
  const [sortDirection, setSortDirection] = useState(
    storageResultsFilterSortDirection
      ? storageResultsFilterSortDirection
      : "desc",
  );
  const [filtersModalOpened, setfiltersModalOpened] = useState(false);

  useEffect(() => {
    setIsProjectsPage(window.location.pathname.includes("projects"));
    return () => {
      setfiltersModalOpened(false);
      setSortDirection(
        storageResultsFilterSortDirection
          ? storageResultsFilterSortDirection
          : "desc",
      );
      setSortValue(
        storageResultsFilterSortValue
          ? sortOption
          : sortOptions(isIndustry, isTechnology, isProject)[0],
      );
      setIsProjectsPage(false);
      setShowDownloadsExceededModal(false);
      setShowAreYouSureModal(false);
      setShowWarningModal(false);
      setDownloadProps();
      setDisplayedFiltersCount(0);
      setActiveResultView(activeView);
      setSearchValue();
    };
  }, []);

  const handleSelectChange = event => {
    setSortValue(
      sortOptions(isIndustry, isTechnology, isProject).find(
        option => option.name === event.target.value,
      ),
    );
    onSortChange(
      sortOptions(isIndustry, isTechnology, isProject).find(
        option => option.name === event.target.value,
      ).slug,
      sortDirection,
    );
  };

  const handleSearch = event => {
    setSearchValue(event.target.value);
    setDisplayedFilters(prevDisplayedFilters => {
      return {
        ...prevDisplayedFilters,
        search: event.target.value,
      };
    });
    setSelectedFilters(prevSelectedFilters => {
      return {
        ...prevSelectedFilters,
        search: event.target.value || "",
      };
    });
    onSearch(event.target.value);
    sessionStorage.setItem(
      `${localStorageSlugPrefix}-${slug}-FilterSearch`,
      event.target.value,
    );
    const tempDisplayedFilters = {
      ...displayedFilters,
      search: event.target.value,
    };
    if (event.target.value === "") {
      const urlParams = new URLSearchParams(search);
      let sid = urlParams.get("sid");
      let data;
      if (!!filterNameValue) {
        data = {
          sid,
          filterNameValue,
          orderBy: sortValue.slug,
          orderDirection: sortDirection,
          page,
          perPage: activeView === "card" ? 10 : 50,
        };
      } else {
        data = {
          sid,
          orderBy: sortValue && sortValue.slug,
          orderDirection: sortDirection,
          page,
          perPage: activeView === "card" ? 10 : 50,
        };
      }
      dispatch(
        request({
          slug,
          data,
          isSharedPageToken: token,
          searchTypeLook: searchTypeLook,
        }),
      );
    }
  };

  const changeSortDirection = () => {
    setSortDirection(prevSortDirection =>
      prevSortDirection === "asc" ? "desc" : "asc",
    );
  };

  const { search: searchResultsValue } = selectedFilters;

  useEffect(() => {
    handleShowResults(1);
  }, [searchResultsValue]);

  useEffect(() => {
    onSortChange(
      sortValue
        ? sortValue.slug
        : isIndustry || isTechnology || isProject
        ? "founded_date"
        : "degree_of_fit",
      sortDirection,
    );
  }, [sortDirection]);

  let timeoutId;
  const openFiltersModal = () => {
    const urlParams = new URLSearchParams(search);
    let sid = urlParams.get("sid");
    let data;
    if (!!filterNameValue) {
      data = {
        sid,
        filterNameValue,
        orderBy: sortValue && sortValue.slug,
        orderDirection: sortDirection,
        page,
        perPage: activeView === "card" ? 10 : 50,
      };
    } else {
      data = {
        sid,
        filterNameValue,
        orderBy: sortValue && sortValue.slug,
        orderDirection: sortDirection,
        page,
        perPage: activeView === "card" ? 10 : 50,
      };
    }
    setModalFilters();
    setSelectedFilters(displayedFilters);
    setfiltersModalOpened(true);
  };
  useEffect(() => {
    return () => clearTimeout(timeoutId);
  }, []);

  const handleFiltersModalClose = () => {
    setfiltersModalOpened(!filtersModalOpened);
  };
  const handleShowResults = value => {
    dispatch(
      resultsActions.setResultsDisplayFilters({
        sid: isIndustry || isTechnology ? slug : currentSearchSid,
        displayedFilters: {
          filterSid: isIndustry || isTechnology ? slug : currentSearchSid,
          sortValue: sortValue || [],
          sortDirection: sortDirection || [],
          sector: selectedFilters.sector || [],
          subsector: selectedFilters.subsector || [],
          subsectors: selectedFilters.subsectors || [],
          stage: selectedFilters.stage || [],
          fundingMin: selectedFilters.fundingMin || "",
          fundingMax: selectedFilters.fundingMax || "",
          teamsizeMin: selectedFilters.teamsizeMin || "",
          teamsizeMax: selectedFilters.teamsizeMax || "",
          location: selectedFilters.location || [],
          yearMin: selectedFilters.yearMin || "",
          yearMax: selectedFilters.yearMax || "",
          matchingScoreMin: selectedFilters.matchingScoreMin,
          matchingScoreMax: selectedFilters.matchingScoreMax || "",
          regions: selectedFilters.regions || [],
          search: selectedFilters.search || "",
          excludeKeywords: selectedFilters.excludeKeywords || "",
          includeKeywords: selectedFilters.includeKeywords || "",
          storageResultsFilterNameValue: value || "",
          storageResultsFilterPage: Number(page) || 1,
        },
      }),
    );
  };
  const handleShowFilters = value => {
    onFilterSelected(value);
  };

  const handleClickOnSearch = () => {
    // show error modal
    if (!isAuthenticated) {
      setShowWarningModal(true);
    }
  };

  const handleOverviewTabClick = () => {
    setClusterVisibility(false);
    setOverviewVisibility(!overviewVisibility);
    if (!overviewVisibility) {
      appTrackEvent("Data overview opened");
    }
  };

  const handleClusterTabClick = () => {
    setClusterIconActive(true);
    setOverviewVisibility(false);
    setClusterVisibility(!clusterVisibility);
  };

  const getDownloadProps = (
    ids,
    type,
    page,
    pageType,
    doc,
    name,
    idType,
    idValue,
  ) => {
    setDownloadProps({
      ids,
      type,
      page,
      pageType,
      doc,
      name: "valuer_company_reports",
      idType,
      idValue,
    });
  };

  const download = () => {
    dispatch(exportCsvRequest(downloadProps));
    mixpanelTrackEvent(`Downloaded document`, {
      type: downloadProps.doc,
      page: isIndustry
        ? "industry"
        : isTechnology
        ? "technology"
        : isProject
        ? "project"
        : "results",
    });
  };

  const hanldeViewIconClick = function () {
    if (activeView === "list") {
      setActiveView("card");
      mixpanelTrackEvent("Companies view switched", { Type: "card" });
    } else if (activeView === "card") {
      setActiveView("list");
      mixpanelTrackEvent("Companies view switched", { Type: "list" });
    }
  };
  useEffect(() => {
    if (!clusterVisibility) {
      setClusterIconActive(false);
    }
  }, [clusterVisibility]);

  return (
    <div className={classes.headerFiltersMain}>
      <div
        className={classes.headerWrapper}
        data-intercom-target="Search, sort and filter bar">
        <div className={classes.header}>
          <div
            className={classnames([
              classes.headerLeftPart,
              { [classes.headerLeftPartLiked]: isLikedCompanies },
            ])}>
            {/* {!isWorkspace && <div className={classnames([classes.refineAndSearch, { [classes.refineAndSearchWide]: isLikedCompanies }])}>
                            <TextField testId="header-search-field" onClick={handleClickOnSearch} endAdornment={<SearchPlaceholderIcon className={classes.startEndornment} />} value={searchValue || ""} className={classes.headerInput} fullWidth placeholder={t("resultsHeader.searchInputPlaceholder")} onChange={handleSearch}></TextField>
                        </div>} */}
            {isAuthenticated && !isWorkspace && (
              <div
                className={classnames([
                  classes.headerSortAndFilter,
                  {
                    [classes.headerSortAndFilterLiked]: isLikedCompanies,
                  },
                ])}>
                {activeView === "card" &&
                  searchType &&
                  !isLookAlikeResults &&
                  !isLikedCompanies && (
                    <div className={classes.filterWrap}>
                      <div
                        test-id={
                          sortDirection === "asc"
                            ? "sort-icon-asc"
                            : "sort-icon-desc"
                        }
                        onClick={() => changeSortDirection()}
                        className={classnames([
                          classes.sortingButton,
                          {
                            [classes.rotateSortingButton]:
                              sortDirection === "asc",
                          },
                        ])}>
                        {sortDirection === "asc" ? (
                          <SortUp className={classes.sortingIcon} />
                        ) : (
                          <SortDown className={classes.sortingIcon} />
                        )}
                      </div>
                      <Multiselect
                        noLeftRadius
                        customClass={classes.multiselectAdjust}
                        testId={
                          sortDirection === "asc" ? "sort-asc" : "sort-desc"
                        }
                        removeLabel
                        iconArrow={<ArrowIcon className={classes.arrowDown} />}
                        options={sortOptions(
                          isIndustry,
                          isTechnology,
                          isProject,
                        )}
                        value={sortValue && sortValue.name}
                        onChange={handleSelectChange}></Multiselect>
                    </div>
                  )}
              </div>
            )}
          </div>
          <div className={classes.downloadChartWrapper}>
            <div className={classes.viewSwitcherWrapper}>
              <ListViewIcon
                data-intercom-target="List view icon"
                className={classnames([
                  {
                    [classes.inactiveViewIcon]: activeResultView !== "list",
                    [classes.activeViewIcon]: activeResultView === "list",
                  },
                ])}
                onClick={hanldeViewIconClick}
              />
              <CardViewIcon
                data-intercom-target="Card view icon"
                className={classnames([
                  {
                    [classes.inactiveViewIcon]: activeResultView !== "card",
                    [classes.activeViewIcon]: activeResultView === "card",
                  },
                ])}
                onClick={hanldeViewIconClick}
              />
            </div>
            {isAuthenticated && (
              <>
                {!isProjectsPage && !isLikedCompanies && (
                  <div className={classes.headerRightPart}>
                    <div
                      data-intercom-target="Data Overview button"
                      className={classnames([
                        classes.headerTab,
                        {
                          [classes.headerTabActive]: overviewVisibility,
                        },
                      ])}
                      onClick={handleOverviewTabClick}>
                      <ResultsOverview className={classes.headerTabIcon} />
                      {t("results.overview")}
                      <OpenIcon className={classes.headerTabIconRight} />
                    </div>
                  </div>
                )}
                {showClusterTab && !isIndustry && !isTechnology && (
                  <div
                    onMouseEnter={() => {
                      !clusterVisibility && setClusterTabHover(true);
                    }}
                    onMouseLeave={() => {
                      !clusterVisibility && setClusterTabHover(false);
                    }}
                    className={classnames([
                      classes.clusterTab,
                      {
                        [classes.clasterTabActive]: clusterIconActive,
                      },
                      {
                        [classes.clusterTabHover]: clusterTabHover,
                      },
                    ])}
                    onClick={handleClusterTabClick}>
                    {clusterIconActive ? (
                      <div>
                        <ClusterIconActive />
                        {clusterVisibility && (
                          <div className={classes.greenConnector}></div>
                        )}
                      </div>
                    ) : (
                      <ClusterIcon />
                    )}
                  </div>
                )}
              </>
            )}
            {/*{!isWorkspace ? <DownloadButton*/}
            {/*    user={user}*/}
            {/*    isIndustry={isIndustry}*/}
            {/*    isTechnology={isTechnology}*/}
            {/*    isProject={isProject}*/}
            {/*    isLikedCompanies={isLikedCompanies}*/}
            {/*    selected={selected}*/}
            {/*    setSelected={setSelected}*/}
            {/*    dispatch={dispatch}*/}
            {/*    id={companiesPageId}*/}
            {/*    setShowAreYouSureModal={setShowAreYouSureModal}*/}
            {/*    setShowDownloadsExceededModal={setShowDownloadsExceededModal}*/}
            {/*    getDownloadProps={getDownloadProps}*/}
            {/*    name="valuer_company_reports"*/}
            {/*    sortBy={sortValue && sortValue.slug}*/}
            {/*    sortDirection={sortDirection}*/}
            {/*    page={isIndustry ? "industry" : isTechnology ? "technology" : isProject ? "project" : slug === "likedCompanies" ? "liked companies" : "results"}*/}
            {/*/> : null}*/}
          </div>
        </div>
      </div>
      {filtersModalOpened && (
        <FiltersModal
          results={searchResults}
          dialogOpened={filtersModalOpened}
          onClose={handleFiltersModalClose}
          onShowResults={handleShowResults}
          onShowFilters={handleShowFilters}
          totalCount={total}
          filters={filters}
          resetFilters={resetFilters}
          stageOptions={stageOptions}
          handleRegionChange={handleRegionChange}
          regionsOptions={regionsOptions}
          fundingOptionsMin={fundingOptionsMin}
          fundingOptionsMax={fundingOptionsMax}
          yearOptionsMin={yearOptionsMin}
          yearOptionsMax={yearOptionsMax}
          teamsizeOptions={teamsizeOptions}
          valuerScoreOptionsMin={valuerScoreOptionsMin}
          valuerScoreOptionsMax={valuerScoreOptionsMax}
          teamSizeOptionsMax={teamSizeOptionsMax}
          matchingScoreMin={matchingScoreMin}
          matchingScoreMax={matchingScoreMax}
          handleFieldChange={handleFieldChange}
          handleSearchChange={handleSearchChange}
          handleExcludeKeywordsChange={handleExcludeKeywordsChange}
          handleIncludeKeywordsChange={handleIncludeKeywordsChange}
          handleSectorChange={handleSectorChange}
          handleSubsectorChange={handleSubsectorChange}
          handleStageChange={handleStageChange}
          handleLocationChange={handleLocationChange}
          resetFilter={resetFilter}
          handleFundingMinChange={handleFundingMinChange}
          handleYearMinChange={handleYearMinChange}
          handleMatchingScoreMinChange={handleMatchingScoreMinChange}
          handleTeamSizeMinChange={handleTeamSizeMinChange}
          allSectors={allSectors}
          allSubsectors={allSubsectors}
          selectedFilters={selectedFilters}
          preselectedSectors={preselectedSectors}
          setPreselectedSectors={setPreselectedSectors}
          preselectedSubsectors={preselectedSubsectors}
          setPreselectedSubsectors={setPreselectedSubsectors}
          allCountries={allCountries}
          displayedFilters={displayedFilters}
          user={user}
          isIndustry={isIndustry}
          countSelector={countSelector}
          filterStats={filterStats}
          setSelectedFilters={setSelectedFilters}
          handleSectorsClose={handleSectorsClose}
          handleSubsectorsClose={handleSubsectorsClose}
          getCountStatusSelector={getCountStatusSelector}
          filterMapping={filterMapping}
        />
      )}
      <WarningModal
        dialogOpened={showWarningModal}
        onClose={() => setShowWarningModal(false)}
        backToSearchPage="true"
      />
      <AreYouSureModal
        dialogOpened={showAreYouSureModal}
        onChange={() => setShowAreYouSureModal(false)}
        title={t("download.areYouSureTitle")}
        subtitle={
          <>
            {t("download.subtitleFreeTrial1")}
            <Typography
              component="div"
              className={classes.actionText}
              variant="body"
              color="secondaryDark">
              {t("download.subtitleFreeTrial2")}
            </Typography>
          </>
        }
        actionButtonText={t("download.iWantToDownload")}
        cancelButtonText={t("download.letsTryLater")}
        onActionClick={download}
      />
      <AreYouSureModal
        dialogOpened={showDownloadsExceededModal}
        onChange={() => setShowDownloadsExceededModal(false)}
        title={t("download.downloadsExceededTitle")}
        subtitle={t("download.downloadsExceededSubtitle")}
        actionButtonText={t("download.upgradePlan")}
        cancelButtonText={t("download.upgradeLater")}
        onActionClick={() => (window.location.href = getPricingPage(!!user))}
      />
    </div>
  );
};
export default HeaderFilters;
