const getBuyCreditsStatusSelector = state => state.credits.buyCreditsStatus;
const getBuyCreditsResponseSelector = state => state.credits.data;
const getCreditsSummaryStatusSelector = state =>
  state.credits.getCreditsSummaryStatus;
const getPlanStatusSelector = state => state.credits.getPlanStatus;
const getCreditsSummarySelector = state => state.credits.summary;
const getPlanSelector = state => state.credits.plan;

export {
  getBuyCreditsStatusSelector,
  getBuyCreditsResponseSelector,
  getCreditsSummaryStatusSelector,
  getCreditsSummarySelector,
  getPlanSelector,
  getPlanStatusSelector,
};
