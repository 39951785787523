import { createStyles, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(({ palette, spacing, shape, typography }) =>
  createStyles({
    root: {
      alignItems: "center",
      background: palette.primary.backgroundLight,
      // height: 20,
      color: palette.text.main,
      padding: "4px 12px",
      borderRadius: "8px",
      display: "inline-flex",
      fontFamily: "GT Flexa Mono, sans-serif",
      ...typography.caption,
    },
    sub: {
      background: palette.success.backgroundLight,
    },
    small: {},
    large: {
      height: spacing(3),
      padding: "0 11px",
    },
    info: {
      color: palette.info.dark,
      background: palette.info.light,
    },
    success: {
      background: palette.success.main,
      color: palette.common.white,
      fontFamily: "GT Flexa Mono, sans-serif",
    },
    lightGreen: {
      background: `${palette.success.backgroundLight} !important`,
    },
    darkGreen: {
      background: `${palette.success.backgroundDark} !important`,
    },
    lightOrange: {
      background: `${palette.primary.backgroundLight} !important`,
    },
    beta: {
      border: `1px solid ${palette.common.black}`,
      borderRadius: spacing(1),
      background: palette.primary.backgroundMedium,
      height: 25,
      textTransform: "uppercase",
      color: palette.common.black,
      ...typography.bodyButton,
      marginLeft: spacing(1),
    },
    link: {
      cursor: "pointer",
    },
    notClickable: {
      cursor: "text !important",
      background: palette.text.backgroundMain + " !important",
    },
  }),
);
