import axiosInstance from "../axiosInstance";
import {
  getCompanies,
  getCompanyUrl,
  SAVE_CONVERSATION_ID_URL,
} from "../../constants/endpoints";

class UserConversationService {
  async getCompany(slug, cid) {
    try {
      const res = await axiosInstance.get(getCompanyUrl(`${slug}?cid=${cid}`));
      return res.data.data;
    } catch (error) {
      if (process.env.REACT_APP_ENV !== "prod") {
        console.log(error);
      }
    }
  }

  async getCompanies(ids) {
    try {
      const res = await axiosInstance.get(getCompanies(ids));
      return res.data.data;
    } catch (error) {
      if (process.env.REACT_APP_ENV !== "prod") {
        console.log(error);
      }
    }
  }

  async saveUserConversationId({ conversation_id, resource_id }) {
    try {
      const res = await axiosInstance.post(SAVE_CONVERSATION_ID_URL, {
        conversation_id,
        resource_id,
      });
      return res.data;
    } catch (error) {
      if (process.env.REACT_APP_ENV !== "prod") {
        console.log(error);
      }
    }
  }
}

export default UserConversationService;
