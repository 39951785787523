import { createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(({ palette, spacing, breakpoint }) =>
  createStyles({
    root: {
      padding: `${spacing(3)}px ${spacing(3)}px ${spacing(3)}px ${spacing(
        3,
      )}px`,
    },
    main: {
      display: "flex",
      flexDirection: "column",
      textAlign: "center",
      alignItems: "center",
    },
    title: {
      marginBottom: spacing(3),
      marginTop: spacing(1),
    },
    headerInput: {
      [breakpoint.down("md")]: {
        width: "100%",
      },
      "& input": {
        width: 288,

        [breakpoint.down("md")]: {
          width: "100%",
        },
      },
      marginTop: spacing(2),
    },
    changeEmailText: {
      color: palette.grey.main,
      marginTop: spacing(2),
      marginBottom: spacing(2),
    },
    haveQuestionWrapText: {
      display: "flex",
      justifyContent: "center",
      marginTop: spacing(2),
      fontFamily: "GT Flexa Mono, sans-serif",
    },
    haveQuestion: {
      color: palette.grey.main,
    },
    reachOut: {
      color: palette.primary.main,
      textDecoration: "underline",
      marginLeft: spacing(1),
      cursor: "pointer",
    },
    changeButton: {
      marginTop: spacing(5),
      marginBottom: spacing(1),
      width: 267,
    },
    emailErrorText: {
      display: "flex",
      justifyContent: "flex-start",
      width: "100%",
    },
    invalidEmail: {
      "& div": {
        borderColor: palette.error.dark,
      },
    },
    errorText: {
      color: palette.error.dark,
    },
  }),
);
