import { createAction } from "@reduxjs/toolkit";
import { REDUCER_PREFIX } from "./slice";

export const fetchResultsRequest = createAction(
  `${REDUCER_PREFIX}/fetchResultsRequest`,
);

export const fetchResultsCountRequest = createAction(
  `${REDUCER_PREFIX}/fetchResultsCountRequest`,
);

export const fetchResultsPageRequest = createAction(
  `${REDUCER_PREFIX}/fetchResultsPageRequest`,
);

export const fetchResultsBySidRequest = createAction(
  `${REDUCER_PREFIX}/fetchResultsBySidRequest`,
);

export const fetchResultsPageBySidRequest = createAction(
  `${REDUCER_PREFIX}/fetchResultsPageBySidRequest`,
);

export const fetchSearchesRequest = createAction(
  `${REDUCER_PREFIX}/fetchSearchesRequest`,
);

export const postSearchesRequest = createAction(
  `${REDUCER_PREFIX}/postSearchesRequest`,
);

export const fetchCompanyRequest = createAction(
  `${REDUCER_PREFIX}/fetchCompanyRequest`,
);

export const postGenerateLinkRequest = createAction(
  `${REDUCER_PREFIX}/postGenerateLinkRequest`,
);
export const voteMlResultRequest = createAction(
  `${REDUCER_PREFIX}/voteMlResultRequest`,
);
export const generateShareLinkRequest = createAction(
  `${REDUCER_PREFIX}/generateShareLinkRequest`,
);
export const fetchCompaniesChartRequest = createAction(
  `${REDUCER_PREFIX}/fetchCompaniesChartRequest`,
);
export const fetchTeamSizeChartRequest = createAction(
  `${REDUCER_PREFIX}/fetchTeamSizeChartRequest`,
);

export const fetchLikedCompaniesRequest = createAction(
  `${REDUCER_PREFIX}/fetchLikedCompaniesRequest`,
);
