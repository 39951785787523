import { createStyles, makeStyles } from "@material-ui/core/styles";

export default makeStyles(
  ({ palette, spacing, shape, typography, breakpoint }) =>
    createStyles({
      pagination: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: `${spacing(2)}px auto 0 auto`,
        width: "100%",
        flexWrap: "inherit",
        "-webkit-tap-highlight-color": "transparent",
        maxWidth: 700,
      },
      paginationItem: {
        marginLeft: spacing(1),
        marginBottom: spacing(2),
        "-webkit-tap-highlight-color": "transparent",
        pointerEvents: "all !important",
        "& $icon": {
          marginBottom: 0,
        },
      },
      icon: {
        padding: 0,
        borderRadius: 0,
        marginBottom: spacing(2),
        "-webkit-tap-highlight-color": "transparent",
      },
      forwardIcon: {
        padding: 0,
        borderRadius: 0,
        marginLeft: spacing(1),
        marginBottom: spacing(2),
        "-webkit-tap-highlight-color": "transparent",
      },
      pageNumber: {
        border: `1px solid ${palette.text.backgroundMain}`,
        borderRadius: spacing(1),
        padding: `4px ${spacing(1)}px`,
        minWidth: 32,
        color: palette.secondary.dark,
        background: palette.common.white,
        ...typography.bodySmall,
        "&[aria-current='true']": {
          borderColor: palette.primary.dark,
          color: palette.primary.dark,
        },
      },
      goToPageLabel: {
        margin: `0 ${spacing(1)}px 0 12px`,
        marginBottom: spacing(2),
      },
      input: {
        width: 32,
        height: 32,
        background: palette.common.white,
        border: `1px solid ${palette.text.backgroundMain}`,
        borderRadius: spacing(1),
        marginBottom: spacing(2),
        "-moz-appearance": "textfield",
        "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
          "-webkit-appearance": "none",
          margin: 0,
        },
      },
    }),
);
