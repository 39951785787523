import { createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(({ palette, spacing, shape, breakpoint }) =>
  createStyles({
    dialogRoot: {
      display: "flex",
      justifyContent: "center",
      paddingLeft: 0,
      paddingRight: 0,
      userSelect: "none",
    },
    dialog: {
      padding: spacing(6),
      borderRadius: spacing(2),
      width: "100%",
      maxWidth: 816,
      position: "relative",
    },
    dialogFilter: {
      padding: spacing(3),
      background: palette.common.white,
      borderRadius: shape.borderRadius,
    },
    dialogScrollPaper: {
      maxWidth: 816,
      width: "100%",
    },
    dialogContent: {
      overflow: "auto",
      margin: "auto",
      padding: 0,
      width: "100%",
    },
    closeButton: {
      position: "absolute !important",
      right: spacing(4),
      top: spacing(3),
      color: palette.grey[500],
    },
    title: {
      margin: `0 0 ${spacing(3)}px 0`,
    },
    analystsCanPrepare: {
      marginTop: spacing(3),
    },
    subtitle: {
      color: palette.text.main,
      marginTop: spacing(2),
    },
    projectRow: {
      paddingBottom: spacing(1),
      borderBottom: "1px solid",
      borderBottomColor: palette.secondary.main,
      marginTop: spacing(3),
      cursor: "pointer",
    },
    selectedProject: {
      color: palette.primary.main,
    },
    buttons: {
      marginTop: spacing(6),
      marginBottom: spacing(3),
      display: "flex",
    },
    requestIcon: {
      marginRight: spacing(1),
    },
    eur: {
      fontSize: 24,
      lineHeight: "22px",
      marginLeft: 5,
    },
    priceWrapper: {
      display: "flex",
      marginTop: spacing(3),
      userSelect: "none",
    },
    topUpDownNumber: {
      margin: `0 ${spacing(3)}px`,
      userSelect: "none",
    },
    topUpDownIcon: {
      cursor: "pointer",
    },
    createButton: {
      marginRight: spacing(1),
      background: palette.primary.dark,
    },
    noCreatedProjects: {
      color: palette.grey.main,
    },
    orderButton: {
      marginLeft: spacing(2),
    },
    upgradePlan: {
      color: palette.primary.dark,
      textDecoration: "underline",
      cursor: "pointer",
    },
    sector: {
      margin: `0 ${spacing(1)}px ${spacing(1)}px 0`,
    },
    subsectorsExplanationsWrapper: {
      marginTop: spacing(3),
      marginBottom: spacing(7),
    },
    subsectorTitleWrapper: {
      marginBottom: spacing(3),
    },
    subsectorTitle: {
      alignItems: "center",
      display: "flex",
      marginBottom: spacing(1),
    },
    color: {
      width: spacing(1),
      height: spacing(1),
      marginRight: 10,
      borderRadius: "50%",
      display: "inline-block",
    },
    mono: {
      fontFamily: "GT Flexa Mono, sans-serif",
    },
    clusterTitle: {
      marginBottom: spacing(2),
    },
    label: {
      marginBottom: spacing(1),
    },
    clusterImage: {
      maxWidth: "80%",
      margin: `${spacing(5)}px auto 0 auto`,
      display: "block",
      [breakpoint.down("sm")]: {
        maxWidth: "90%",
      },
    },
  }),
);
