// Static data
export const stageOptions = [
  { value: 6, label: "Unspecified" },
  { value: 7, label: "Private Equity" },
  { value: 8, label: "Early" },
  { value: 9, label: "Growth" },
  { value: 10, label: "Mezzanine" },
  { value: 11, label: "Start-up" },
  { value: 12, label: "Late" },
];

export const fundingOptionsMin = [
  { value: 13, label: "0" },
  { value: 14, label: "100.000" },
  { value: 15, label: "1.000.000" },
  { value: 16, label: "5.000.000" },
  { value: 17, label: "10.000.000" },
  { value: 18, label: "50.000.000" },
  { value: 19, label: "100.000.000" },
  { value: 20, label: "250.000.000" },
  { value: 21, label: "1.000.000.000" },
];

export const fundingOptionsMaxData = [
  { value: 22, label: "100.000" },
  { value: 23, label: "1.000.000" },
  { value: 24, label: "5.000.000" },
  { value: 25, label: "10.000.000" },
  { value: 26, label: "50.000.000" },
  { value: 27, label: "100.000.000" },
  { value: 28, label: "250.000.000" },
  { value: 29, label: "1.000.000.000" },
  { value: 30, label: "5.000.000.000" },
  { value: 31, label: "max" },
];

export const regionsOptions = [
  {
    label: "Africa",
    value: "africa",
  },
  {
    label: "Central and West Asia",
    value: "central-and-west-asia",
  },
  {
    label: "East and South Asia",
    value: "east-and-south-asia",
  },
  {
    label: "Europe",
    value: "europe",
  },
  {
    label: "Oceania",
    value: "oceania",
  },
  {
    label: "South America",
    value: "south-america",
  },
  {
    label: "Central America",
    slvalueug: "central-america",
  },
  {
    label: "North America",
    value: "north-america",
  },
];

export function* range(start, end, step, limit = 30) {
  let i = 0;
  while (start > end && limit !== i) {
    yield start;
    start -= step;
    i++;
  }
}

export const yearsChartArray = [
  "2002 - 2006",
  "2007 - 2011",
  "2012 - 2016",
  "2017",
  "2018",
  "2019",
  "2020",
  "2021",
  "2022",
  "2023",
  "2024",
];
export const yearOptionsMin = yearsChartArray.map(year => {
  return { label: year };
});

export const yearOptionsMaxData = yearsChartArray.map(year => {
  return { label: year };
});

export const teamsizeOptions = [
  { label: "0 to 10" },
  { label: "11 to 50" },
  { label: "51 to 100" },
  { label: "101 to 250" },
  { label: "251 to 500" },
  { label: "501 to 1000" },
  { label: "1001 to 5000" },
  { label: "5001 to 10000" },
  { label: "10001+" },
];

export const valuerScoreOptionsMin = [
  { value: 71, label: 50 },
  { value: 72, label: 60 },
  { value: 73, label: 70 },
  { value: 74, label: 80 },
  { value: 75, label: 90 },
  { value: 76, label: 95 },
];

export const valuerScoreOptionsMaxData = [
  { value: 77, label: 50 },
  { value: 78, label: 60 },
  { value: 79, label: 70 },
  { value: 80, label: 80 },
  { value: 81, label: 90 },
  { value: 82, label: 95 },
  { value: 83, label: 100 },
];

export const selectedFiltersEmptyData = {
  sector: "",
  subsector: "",
  subsectors: "",
  stage: "",
  fundingMin: "",
  fundingMax: "",
  teamsizeMin: "",
  teamsizeMax: "",
  location: "",
  yearMin: "",
  yearMax: "",
  matchingScoreMin: "",
  matchingScoreMax: "",
  regions: "",
  search: "",
  excludeKeywords: "",
  includeKeywords: "",
};

export const filterParamMapping = {
  sector: "sector",
  subsector: "subsector",
  subsectors: "subsectors",
  stage: "stage",
  fundingMin: "funding-from",
  fundingMax: "funding-to",
  teamsizeMin: "teamsize-min",
  teamsizeMax: "teamsize-max",
  location: "country",
  yearMin: "founded-from",
  yearMax: "founded-to",
  matchingScoreMin: "matching-score-from",
  matchingScoreMax: "matching-score-to",
  regions: "regions",
  search: "search",
  excludeKeywords: "exclude-keywords",
  includeKeywords: "include-keywords",
};

export const industryFilterParamMapping = {
  sector: "sector",
  subsectors: "subsectors",
  stage: "stage",
  fundingMin: "funding-from",
  fundingMax: "funding-to",
  teamsizeMin: "teamsize-min",
  teamsizeMax: "teamsize-max",
  location: "country",
  yearMin: "founded-from",
  yearMax: "founded-to",
  matchingScoreMin: "matching-score-from",
  matchingScoreMax: "matching-score-to",
  regions: "regions",
  search: "search",
  excludeKeywords: "exclude-keywords",
  includeKeywords: "include-keywords",
};

export const sortOptions = (
  isIndustry = false,
  isTechnology = false,
  isProject = false,
) =>
  !!(isIndustry || isTechnology || isProject)
    ? [
        // { value: 0, label: "Success", slug: "success_potential_score" },
        { value: 1, label: "Recently Founded", slug: "founded_date" },
        // { value: 2, label: "Growth", slug: "growth_score" },
        // { value: 3, label: "Maturity", slug: "market_maturity_score" },
        // { value: 4, label: "Innovation", slug: "innovation_score" },
        // { value: 5, label: "Sustainability", slug: "sustainability_score" },
        // { value: 6, label: "Data completeness", slug: "completeness_score" },
      ]
    : [
        { value: 0, label: "Match score", slug: "degree_of_fit" },
        { value: 1, label: "Recently Founded", slug: "founded_date" },
        // { value: 2, label: "Success", slug: "success_potential_score" },
        // { value: 3, label: "Growth", slug: "growth_score" },
        // { value: 4, label: "Maturity", slug: "market_maturity_score" },
        // { value: 5, label: "Innovation", slug: "innovation_score" },
        // { value: 6, label: "Sustainability", slug: "sustainability_score" },
        // { value: 7, label: "Data completeness", slug: "completeness_score" },
      ];

export const industriesOptions = [
  { label: "Accommodation & Food", value: "food-and-beverage" },
  { label: "Administrative Services", value: "administrative-services" },
  { label: "Agriculture", value: "agriculture-and-farming" },
  { label: "Biotechnology", value: "biotechnology" },
  { label: "Business Consultancy", value: "professional-services" },
  { label: "Clothing & Apparel", value: "clothing-and-apparel" },
  { label: "Consumer Goods", value: "commerce-and-shopping" },
  { label: "Data & Analytics", value: "data-and-analytics" },
  { label: "Education", value: "education" },
  { label: "Energy", value: "energy" },
  { label: "Financial Services", value: "financial-services" },
  { label: "Hardware", value: "hardware" },
  { label: "Healthcare", value: "health-care" },
  { label: "Information Technology", value: "information-technology" },
  { label: "Manufacturing", value: "manufacturing" },
  { label: "Media & Entertainment", value: "media-and-entertainment" },
  { label: "Military & Defence", value: "government-and-military" },
  { label: "Natural Resources", value: "natural-resources" },
  { label: "Privacy & Security", value: "privacy-and-security" },
  { label: "Real Estate", value: "real-estate" },
  { label: "Retail & Marketing", value: "sales-and-marketing" },
  { label: "Science & Engineering", value: "science-and-engineering" },
  { label: "Software", value: "software" },
  { label: "Sustainability", value: "sustainability" },
  { label: "Transportation", value: "transportation" },
];
