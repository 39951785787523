import React, {useEffect, useState} from "react";
import classnames from "classnames";
import moment from "moment";
import _ from "lodash";
import {useRouterHook} from "../../../../common/useRouterHook";
// UI components
import Typography from "../../../../components/Typography/Typography";
// import Button from "../../../../components/Button/Button";
import Grow from "@material-ui/core/Grow";
// Hooks
import {useTranslation} from "react-i18next";
import {useStyles} from "./style";
import {useAppEventTracking} from "../../../../common/useAppEventTracking";
//UI Components
import Modal from "../../../../components/Modal/Modal";
import Link from "../../../../components/Link/Link.js";
import {Link as LinkRouter} from "react-router-dom";
import {AddToProjectMenu} from "../../../../components/AddToProjectMenu/AddToProjectMenu";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";
//Assets
import CloseIcon from "../../../../assets/icons/CloseGreen.svg";
import NoImagePlaceholder from "../../../../assets/icons/Company.svg";
// import OrderReportIcon from "../../../../assets/icons/OrderReportW.svg";
// import FullProfileIcon from "../../../../assets/icons/FullProfileW.svg";
// import PreviewIcon from "../../../../assets/icons/PreviewW.svg";
// import SaveIcon from "../../../../assets/icons/SaveW.svg";
import RemoveIcon from "../../../../assets/icons/RemoveW.svg";
import SeeReport from "../../../../assets/icons/SeeReport.svg";
import DefaultIndustryAvatar from "../../../../assets/icons/Industry.svg";
import ProjectDefaultIcon from "../../../../assets/icons/ProjectDefaultIcon.svg";
import TechnologyIcon from "../../../../assets/icons/Technology.svg";
import LookAlikeIcon from "../../../../assets/icons/LookAlikeIcon.svg";
//Shared
import {Shared} from "../../../../utils/shared.js";
// import LinkBtn from "../../../../components/LinkBtn/LinkBtn";
import RemoveWarningModal from "../../../Projects/ProjectProfile/RemoveWarningModal/RemoveWarningModal";
// import {
//     deleteCompanyRequest,
//     deleteProjectRequest,
// } from "../../../../store/projects/requests";
import {useDispatch} from "react-redux";
// import { useCompanyProfileHook } from "../../../Company/hooks/useCompanyProfileHook";

const PreviewModal = ({
  dialogOpened,
  onChange,
  isProject,
  project,
  isIndustry,
  isTechnology,
  isSearch,
  data,
  type,
  showEnrichRequestModal,
  onOpenProfile,
  user,
  projectSlug,
  // onOpen,
  shouldShowMatchScore,
  isWorkspace,
  // source = "result",
  loading = false,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [scoreDegree, setScoreDegree] = useState();
  const regex = /http(s)?(:)?(\/\/)?|(\/\/)?(www\.)?(\/)?/g;
  // let { search } = useLocation();
  const {
    match = null,
    name = null,
    description = null,
    // enrichment_request = null,
    year = null,
    stage = null,
    deck = null,
    location = null,
    team = null,
    website = null,
    funding = null,
    ready_for_release = null,
    sectors = null,
    comments_cnt = null,
    subsectors = null,
    industries = null,
    sub_industries = null,
    updated_at = null,
    created_date = null,
    companies_count = null,
    total_published_companies_count = null,
    projects = null,
  } = data;

  const dispatch = useDispatch();
  const { mixpanelTrackEvent } = useAppEventTracking();
  const [imageSrc, setImageSrc] = useState(
    isIndustry ? DefaultIndustryAvatar : NoImagePlaceholder,
  );
  const [imageIsValid, setImageIsValid] = useState(false);
  const [currentSearch, setCurrentSearch] = useState(null);
  const [removeWarningModalOpened, setRemoveWarningModalOpened] =
    useState(false);

  const { openPage } = useRouterHook();

  useEffect(() => {
    if (isProject) {
      setImageSrc(ProjectDefaultIcon);
      setImageIsValid(true);
    } else if (isIndustry && data.image) {
      setImageSrc(data.image);
      setImageIsValid(true);
    } else if (isTechnology) {
      setImageSrc(TechnologyIcon);
      setImageIsValid(true);
    } else {
      setImageSrc(NoImagePlaceholder);
      setImageIsValid(false);
    }
  }, [ready_for_release]);

  useEffect(() => {
    if (isSearch) {
      setCurrentSearch(data);
    }
  }, []);

  const onImgError = () => {
    setImageSrc(NoImagePlaceholder);
    setImageIsValid(false);
  };

  useEffect(() => {
    if (match && shouldShowMatchScore) {
      if (parseInt(match) < 40) {
        setScoreDegree("weak");
      } else if (parseInt(match) >= 40 && parseInt(match) < 60) {
        setScoreDegree("good");
      } else {
        setScoreDegree("strong");
      }
    }
  }, [match]);

  const { formatNumber } = Shared({ getUser: user });

  // const trackEvent = e => {
  //     mixpanelTrackEvent("Preview - Company profile opened");
  // };

  // const handleOpenProfile = e => {
  //     onOpenProfile();
  //     trackEvent();
  // };

  // const handleEnrichmentButtonClick = (e, data) => {
  //     showEnrichRequestModal(e, data);
  //     mixpanelTrackEvent("Preview - Company report requested");
  // };

  // const stopPropagation = e => {
  //     e.preventDefault();
  //     e.stopPropagation();
  // };

  const saveCard = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  const removeItemFromProject = () => {
    setRemoveWarningModalOpened(true);
  };

  // const openProject = e => {
  //     e.preventDefault();
  //     e.stopPropagation();
  //     console.log("Project opened!");
  // };

  const handleOnSeeLookAlikes = () => {
    let lookAlikeData = { name: data && data.name };
    localStorage.setItem("company-look-a-likes", JSON.stringify(lookAlikeData));

    return "/search-type/lookalike";
  };

  // const openFullProfile = () => {
  //     const url = isTechnology
  //         ? `/technologies/${data.slug}`
  //         : isIndustry
  //         ? `/industries/${data.slug}`
  //         : isSearch
  //         ? `/results?sid=${data.id}&page=1`
  //         : `/companies/${data.slug}?cid=${data.id}`;
  //     openPage(url, true);
  // };

  const openLookAlikeSearch = () => {
    let lookAlikeData = { name: data && data.name };
    localStorage.setItem("company-look-a-likes", JSON.stringify(lookAlikeData));
    openPage("/search-type/lookalike", true);
  };

  const onPreviewModalClose = () => {
    localStorage.removeItem("company-look-a-likes");
    onChange();
  };

  return (
    <Modal
      onClose={onPreviewModalClose}
      closeButtonIcon={CloseIcon}
      disableEscapeKeyDown={true}
      fullWidth={true}
      TransitionComponent={Grow}
      dialogOpened={dialogOpened}
      paddingSpace="0px"
      borderRadius="24px"
      dialogContentClassName={classes.companyPreviewInner}
      classnames={{ paper: classes.companyPreviewPaper }}>
      <div className={classes.companyPreviewBox}>
        {!loading ? (
          <>
            <div className={classes.companyPreviewLeft}>
              <div className={classes.header}>
                <Tooltip
                  disableHoverListener={imageIsValid}
                  title={
                    <Typography component="div" color="white" variant="body2">
                      {t("results.logoDescription")}
                    </Typography>
                  }
                  arrow>
                  <div
                    className={classnames([
                      classes.avatar,
                      { [classes[`${type}Color`]]: true },
                    ])}>
                    <img
                      width="24"
                      height="24"
                      src={imageSrc}
                      alt={name}
                      onError={onImgError}
                    />
                    {!!ready_for_release ? (
                      <img
                        src={SeeReport}
                        className={classes.enrichedIcon}
                        alt={t("card.seeReport")}
                      />
                    ) : null}
                  </div>
                </Tooltip>
                <div className={classes.titleWrap}>
                  <Typography
                    component="div"
                    variant="subtitle1"
                    className={classnames([classes.name, classes.titleName])}>
                    <strong>{name}</strong>
                  </Typography>
                </div>
                <div className={classes.typeWrapper}>
                  <Typography
                    variant="bodyXs"
                    color="black"
                    component="div"
                    className={classnames([
                      classes.typeTag,
                      { [classes[`${type}Color`]]: true },
                    ])}>
                    {_.startCase(type)}
                  </Typography>
                </div>
                {shouldShowMatchScore && (
                  <div className={classes.matchScoreBlock}>
                    <div className={classes.matchScore}>
                      <Typography
                        variant="label"
                        color="textLight"
                        component="div"
                        className={classes.matchScoreLabel}>
                        {t("results.matchingScore")}
                      </Typography>
                      <Typography
                        variant="bodyButton"
                        className={classnames([
                          classes.percentageMatch,
                          classes[`${scoreDegree}Text`],
                        ])}
                        color={match === "100" ? "primaryDark" : "black"}>
                        {t("results.match", { number: match })}
                        <div className={classes.scoreBar}>
                          <div
                            className={classnames([
                              classes.scoreBarInside,
                              classes[`${scoreDegree}Score`],
                            ])}
                            style={{
                              width: `${match}%`,
                            }}></div>
                        </div>
                      </Typography>
                    </div>
                  </div>
                )}
              </div>
              <div className={classes.centralContent}>
                <Typography
                  variant="body"
                  component="div"
                  className={classes.companyPreviewDescription}
                  dangerouslySetInnerHTML={{ __html: description }}
                />
                {type === "company" ? (
                  <div className={classes.sections}>
                    <div className={classes.section}>
                      <Typography
                        variant="body"
                        component="div"
                        color="black"
                        gutterBottom>
                        <strong>{t("previewModal.industries")}</strong>
                      </Typography>
                      <Typography
                        variant="body"
                        component="div"
                        className={classes.sectionValue}>
                        {(sectors &&
                          sectors
                            .map(sector => (sector.name ? sector.name : sector))
                            .join(", ")) ||
                          "-"}
                      </Typography>
                    </div>
                    <div className={classes.section}>
                      <Typography
                        variant="body"
                        component="div"
                        color="black"
                        gutterBottom>
                        <strong>{t("previewModal.sdgs")}</strong>
                      </Typography>
                      <div>
                        {!!ready_for_release && deck && deck.sdgs ? (
                          deck.sdgs.map((sdg, i) => (
                            <Tooltip
                              key={`sdg-${i}`}
                              arrow
                              classes={{
                                arrow: classes.tooltipArrow,
                                tooltip: classes.tooltipContent,
                              }}
                              placement="top"
                              title={
                                <Typography
                                  variant="bodySmall"
                                  color="white"
                                  component="div"
                                  className={classes.sdgTooltip}>
                                  <div className={classes.sdgTooltipTitle}>
                                    {t(
                                      "companyProfile.thisCompanyWorksTowards",
                                    )}
                                  </div>
                                  {t(
                                    "companyProfile.sustainableDevelopmentGoals",
                                  )}{" "}
                                  {sdg.number}: {sdg.name}
                                </Typography>
                              }>
                              <img
                                className={classes.sdgTag}
                                src={require(
                                  `../../../../assets/icons/sdgs/${sdg.icon_name}.png`,
                                )}
                                alt={sdg.name}></img>
                            </Tooltip>
                          ))
                        ) : (
                          <Typography
                            variant="body"
                            component="div"
                            className={classes.sectionValue}>
                            {t("previewModal.sdgsHide")}
                          </Typography>
                        )}
                      </div>
                    </div>
                    <div className={classes.section}>
                      <Typography
                        variant="body"
                        component="div"
                        color="black"
                        gutterBottom>
                        <strong>{t("previewModal.subIndustries")}</strong>
                      </Typography>
                      <Typography
                        variant="body"
                        component="div"
                        className={classes.sectionValue}>
                        {(subsectors &&
                          subsectors
                            .map(subsector =>
                              subsector.name ? subsector.name : subsector,
                            )
                            .join(", ")) ||
                          "-"}
                      </Typography>
                    </div>
                    <div className={classes.section}>
                      <Typography
                        variant="body"
                        component="div"
                        color="black"
                        gutterBottom>
                        <strong>{t("previewModal.comments")}</strong>
                      </Typography>
                      <Typography
                        variant="body"
                        component="div"
                        className={classes.sectionValue}>
                        {comments_cnt}
                      </Typography>
                    </div>
                  </div>
                ) : type === "industry" ? (
                  <div className={classes.sections}>
                    <div className={classes.section}>
                      <Typography
                        variant="body"
                        component="div"
                        color="black"
                        gutterBottom>
                        <strong>
                          {t("previewModal.relevantSubindustries")}
                        </strong>
                      </Typography>
                      <Typography
                        variant="body"
                        component="div"
                        className={classes.sectionValue}>
                        {(sub_industries &&
                          sub_industries
                            .map(subsector => subsector.name)
                            .join(", ")) ||
                          "-"}
                      </Typography>
                    </div>
                  </div>
                ) : type === "technology" ? (
                  <div className={classes.sectionsColumns}>
                    <div className={classes.sectionFullWidth}>
                      <Typography
                        variant="body"
                        component="div"
                        color="black"
                        gutterBottom>
                        <strong>{t("previewModal.relevantIndustries")}</strong>
                      </Typography>
                      <Typography
                        variant="body"
                        component="div"
                        className={classes.sectionValue}>
                        {(industries && industries.join(", ")) || "-"}
                      </Typography>
                    </div>
                    <div className={classes.sectionFullWidth}>
                      <Typography
                        variant="body"
                        component="div"
                        color="black"
                        gutterBottom>
                        <strong>
                          {t("previewModal.relevantSubindustries")}
                        </strong>
                      </Typography>
                      <Typography
                        variant="body"
                        component="div"
                        className={classes.sectionValue}>
                        {sub_industries?.join(", ") || "-"}
                      </Typography>
                    </div>
                  </div>
                ) : (
                  <div className={classes.sections}>
                    {/*<div className={classes.section}>*/}
                    {/*    <Typography variant="body" component="div" color="black" gutterBottom><strong>{t('previewModal.industries')}</strong></Typography>*/}
                    {/*    <Typography variant="body" component="div" className={classes.sectionValue}>{industries && industries.join(', ') || "-"}</Typography>*/}
                    {/*</div>*/}
                    {/*<div className={classes.section}>*/}
                    {/*    <Typography variant="body" component="div" color="black" gutterBottom><strong>{t('previewModal.industries')}</strong></Typography>*/}
                    {/*    <Typography variant="body" component="div" className={classes.sectionValue}>{industries && industries.join(', ') || "-"}</Typography>*/}
                    {/*</div>*/}
                    {/*<div className={classes.section}>*/}
                    {/*    <Typography variant="body" component="div" color="black" gutterBottom><strong>{t('previewModal.subIndustries')}</strong></Typography>*/}
                    {/*    <Typography variant="body" component="div" className={classes.sectionValue}>{sub_industries && sub_industries.map(subsector => subsector.name).join(', ') || "-"}</Typography>*/}
                    {/*</div>*/}
                    {/*<div className={classes.section}>*/}
                    {/*    <Typography variant="body" component="div" color="black" gutterBottom><strong>{t('previewModal.industries')}</strong></Typography>*/}
                    {/*    <Typography variant="body" component="div" className={classes.sectionValue}>{industries && industries.join(', ') || "-"}</Typography>*/}
                    {/*</div>*/}
                  </div>
                )}
              </div>
              <div className={classes.previewModalFooter}>
                <div className={classes.actionItems}>
                  {/* {!enrichment_request &&
                                            type !== "industry" &&
                                            type !== "search" && (
                                                <div
                                                    className={classes.actionIconWrapper}
                                                    onClick={e =>
                                                        handleEnrichmentButtonClick(e, data)
                                                    }>
                                                    <div
                                                        className={
                                                            classes.actionIconContent
                                                        }>
                                                        <img
                                                            width="24"
                                                            height="24"
                                                            src={OrderReportIcon}
                                                            alt={t("card.orderReport")}
                                                            className={classes.actionIcon}
                                                        />
                                                    </div>
                                                    <Typography
                                                        variant="bodyXs"
                                                        color="textLight"
                                                        className={classes.actionText}
                                                        component="div">
                                                        {t("card.orderReport")}
                                                    </Typography>
                                                </div>
                                            )} */}
                  {/* <LinkRouter
                                            to={
                                                isTechnology
                                                    ? `/technologies/${data.slug}`
                                                    : isIndustry
                                                    ? `/industries/${data.slug}`
                                                    : isSearch
                                                    ? `/results?sid=${data.id}&page=1`
                                                    : `/companies/${data.slug}?cid=${data.id}`
                                            }
                                            target="_blank"
                                            className={classes.actionIconWrapper}
                                            onClick={openFullProfile}>
                                            <div className={classes.actionIconContent}>
                                                <img
                                                    width="24"
                                                    height="24"
                                                    src={FullProfileIcon}
                                                    alt={t("card.fullProfile")}
                                                    className={classes.actionIcon}
                                                />
                                            </div>
                                            <Typography
                                                variant="bodyXs"
                                                color="textLight"
                                                className={classes.actionText}
                                                component="div">
                                                {type === "search"
                                                    ? t("card.fullList")
                                                    : t("card.fullProfile")}
                                            </Typography>
                                        </LinkRouter> */}
                  <div className={classes.actionIconWrapper} onClick={saveCard}>
                    <AddToProjectMenu
                      currentSearch={currentSearch}
                      buttonType="savecard"
                      company={data}
                      type={type && type.toLowerCase()}
                    />
                  </div>
                  {
                    type === "company" && (
                      <LinkRouter
                        to={handleOnSeeLookAlikes}
                        onClick={openLookAlikeSearch}
                        target="_blank"
                        className={classes.actionIconWrapper}>
                        <div className={classes.actionIconContent}>
                          <img
                            alt={t("companyProfile.seeLookALike")}
                            width="24"
                            height="24"
                            className={classes.lookAlikeIcon}
                            src={LookAlikeIcon}
                          />
                        </div>
                        <Typography
                          data-intercom-target={t(
                            "companyProfile.seeLookALike",
                          )}
                          component="div"
                          color="textLight"
                          className={classes.actionText}
                          variant="bodyXs">
                          {t("companyProfile.seeLookALike")}
                        </Typography>
                      </LinkRouter>
                    )

                    // <div onClick={handleSeeLookALike} className={classes.actionIconContent}>
                    //     <img
                    //         alt={t('companyProfile.seeLookALike')}
                    //         width="24"
                    //         height="24"
                    //         className={classes.actionIcon}
                    //         src={SeeLookALikeBtn} />
                    //     <Typography
                    //         data-intercom-target={t('companyProfile.seeLookALike')}
                    //         className={classes.actionText}
                    //         variant="bodyXs">
                    //         {t('companyProfile.seeLookALike')}
                    //     </Typography>
                    // </div>
                  }
                  {!!isWorkspace ? (
                    <div
                      className={classes.actionIconWrapper}
                      onClick={removeItemFromProject}>
                      <div className={classes.actionIconContent}>
                        <img
                          width="24"
                          height="24"
                          src={RemoveIcon}
                          alt={t("card.remove")}
                          className={classes.actionIcon}
                        />
                      </div>
                      <Typography
                        variant="bodyXs"
                        color="textLight"
                        className={classes.actionText}>
                        {t("card.remove")}
                      </Typography>
                    </div>
                  ) : null}
                </div>
                {projects?.length ? (
                  <Typography
                    variant="bodyXs"
                    color="textSecondary"
                    component="div"
                    className={classes.savedInLabel}>
                    {t("card.savedInLabel")}
                    <Typography
                      variant="bodyXs"
                      color="primaryDark"
                      className={classes.savedInLink}
                      component="span">
                      {projects?.map((project, i) => (
                        <>
                          <Typography
                            key={i}
                            variant="bodyButton"
                            color="primaryDark"
                            className={classnames([
                              classes.link,
                              classes.savedInLink,
                            ])}
                            component="span"
                            onClick={() =>
                              openPage(`/projects/${project.slug}`)
                            }>
                            {project.title}
                          </Typography>
                          {projects?.length > 1 &&
                            i < projects?.length - 1 &&
                            ", "}
                        </>
                      ))}
                    </Typography>
                  </Typography>
                ) : null}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={classes.loading}>
              <CircularProgress size="1.3rem" style={{ color: "#8594AF" }} />
            </div>
          </>
        )}
        <div className={classes.companyPreviewRight}>
          {type === "company" ? (
            <>
              <div className={classes.companyInfoBox}>
                <Typography
                  variant="bodySmall"
                  className={classes.companyInfoLabel}>
                  {t("previewModal.year")}
                </Typography>
                <Typography
                  variant="bodySmall"
                  className={classes.companyInfoValue}>
                  {year || "N/A"}
                </Typography>
              </div>
              <div className={classes.companyInfoBox}>
                <Typography
                  variant="bodySmall"
                  className={classes.companyInfoLabel}>
                  {t("previewModal.companyStage")}
                </Typography>
                <Typography
                  variant="bodySmall"
                  className={classes.companyInfoValue}>
                  {stage && stage !== "Unspecified" ? stage : "N/A"}
                </Typography>
              </div>
              <div className={classes.companyInfoBox}>
                <Typography
                  variant="bodySmall"
                  className={classes.companyInfoLabel}>
                  {t("previewModal.fundings")}
                </Typography>
                <Typography
                  variant="bodySmall"
                  className={classnames([
                    classes.companyInfoValue,
                    classes.companyFundings,
                  ])}>
                  {deck && deck.funding
                    ? isNaN(deck.funding)
                      ? deck.funding
                      : formatNumber(deck.funding)
                    : funding
                    ? parseInt(funding)
                        .toFixed()
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " USD"
                    : "N/A"}
                </Typography>
              </div>
              <div className={classes.companyInfoBox}>
                <Typography
                  variant="bodySmall"
                  className={classes.companyInfoLabel}>
                  {t("previewModal.location")}
                </Typography>
                <Typography
                  variant="bodySmall"
                  className={classes.companyInfoValue}>
                  {location || "N/A"}
                </Typography>
              </div>
              <div className={classes.companyInfoBox}>
                <Typography
                  variant="bodySmall"
                  className={classes.companyInfoLabel}>
                  {t("previewModal.teamSize")}
                </Typography>
                <Typography
                  variant="bodySmall"
                  className={classes.companyInfoValue}>
                  {team || "N/A"}
                </Typography>
              </div>
              <div className={classes.companyInfoBox}>
                <Typography
                  variant="bodySmall"
                  className={classes.companyInfoLabel}>
                  {t("previewModal.website")}
                </Typography>
                <Typography
                  variant="bodySmall"
                  className={classes.companyInfoValue}>
                  {website ? (
                    <Link
                      className={classnames([
                        classes.link,
                        classes.companyWebsite,
                      ])}
                      target="_blank"
                      onClick={() => website && openPage(website, true)}>
                      {website.replace(regex, "")}
                    </Link>
                  ) : (
                    "N/A"
                  )}
                </Typography>
              </div>
            </>
          ) : type === "industry" ? (
            <>
              <div className={classes.companyInfoBox}>
                <Typography
                  variant="bodySmall"
                  className={classes.companyInfoLabel}>
                  {t("previewModal.updated")}
                </Typography>
                <Typography
                  variant="bodySmall"
                  className={classes.companyInfoValue}>
                  {updated_at
                    ? isWorkspace
                      ? updated_at
                      : moment(updated_at).format("MM. DD. YYYY")
                    : "N/A"}
                </Typography>
              </div>
              <div className={classes.companyInfoBox}>
                <Typography
                  variant="bodySmall"
                  className={classes.companyInfoLabel}>
                  {t("previewModal.selectedCompanies")}
                </Typography>
                <Typography
                  variant="bodySmall"
                  className={classes.companyInfoValue}>
                  {companies_count ? companies_count : "N/A"}
                </Typography>
              </div>
            </>
          ) : type === "technology" ? (
            <>
              <div className={classes.companyInfoBox}>
                <Typography
                  variant="bodySmall"
                  className={classes.companyInfoLabel}>
                  {t("previewModal.updated")}
                </Typography>
                <Typography
                  variant="bodySmall"
                  className={classes.companyInfoValue}>
                  {updated_at
                    ? isTechnology
                      ? moment(updated_at).format("MM. DD. YYYY")
                      : updated_at
                    : "N/A"}
                </Typography>
              </div>
              <div className={classes.companyInfoBox}>
                <Typography
                  variant="bodySmall"
                  className={classes.companyInfoLabel}>
                  {t("previewModal.selectedCompanies")}
                </Typography>
                <Typography
                  variant="bodySmall"
                  className={classes.companyInfoValue}>
                  {total_published_companies_count
                    ? total_published_companies_count
                    : "N/A"}
                </Typography>
              </div>
            </>
          ) : (
            <>
              <div className={classes.companyInfoBox}>
                <Typography
                  variant="bodySmall"
                  className={classes.companyInfoLabel}>
                  {t("previewModal.queryCreated")}
                </Typography>
                <Typography
                  variant="bodySmall"
                  className={classes.companyInfoValue}>
                  {created_date ? created_date : "N/a"}
                </Typography>
              </div>
              {/* type === "search" &&
                                    <LinkBtn
                                        path={`/results?sid=${data.id}`}
                                        variant="primary"
                                        className={classes.seeResults}
                                    >
                                        {t("previewModal.seeResults")}
                                    </LinkBtn> */}
            </>
          )}
          {/* <Typography 
                            component="a"
                            href={`/companies/${slug}?cid=${id}`}
                            onClick={e => handleOpenProfile(e)}
                            variant="bodySmall" 
                            className={classes.companyRedirect}
                        >
                            {t('previewModal.seeFullProfile')} →
                        </Typography> */}
          {removeWarningModalOpened && (
            <RemoveWarningModal
              projectSlug={projectSlug}
              project={project}
              result={data}
              dialogOpened={removeWarningModalOpened}
              onChange={() => setRemoveWarningModalOpened(false)}
              onClose={() => setRemoveWarningModalOpened(false)}
              isDeleteProject={false}
              type={type}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};
export default PreviewModal;
