import { all, takeEvery } from "redux-saga/effects";
import { mixpanelTrackRequest } from "../requests";
import { mixpanelTrack } from "./workers";

const convertTypeFromAction = actionCreator => actionCreator.toString();

function* watchMixpanelTrack() {
  yield takeEvery(
    convertTypeFromAction(mixpanelTrackRequest),
    ({ payload: { eventName, data } }) => mixpanelTrack(eventName, data),
  );
}

function* saga() {
  yield all([watchMixpanelTrack()]);
}

export { saga };
