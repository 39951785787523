import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
// Components
import AreYouSureModal from "../../../../components/AreYouSureModal/AreYouSureModal";
import Checkbox from "../../../../components/Checkbox/Checkbox";
import Typography from "../../../../components/Typography/Typography";
// Hooks
import { useAppEventTracking } from "../../../../common/useAppEventTracking";
// Redux
import { exportCsvRequest } from "../../../../store/orders/requests";
// Constants
import { getPricingPage } from "../../../../constants/endpoints";

const TableToolbar = React.forwardRef(
  (
    {
      numSelected,
      rowCount,
      onSelectAllClick,
      className,
      classes,
      dispatch,
      isTechnology,
      isIndustry,
      isLikedCompany,
      searchResults,
      selected,
      selectedNames,
      isAuthenticated,
      user,
      isProject,
      projectSlug,
      id,
      sortValue,
      sortDirection,
      isWorkspace = false,
      ...props
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const [showAreYouSureModal, setShowAreYouSureModal] = useState(false);
    const [showDownloadsExceededModal, setShowDownloadsExceededModal] =
      useState(false);
    const [downloadProps, setDownloadProps] = useState();

    useEffect(() => {
      return () => {
        setShowAreYouSureModal(false);
        setShowDownloadsExceededModal(false);
        setDownloadProps();
      };
    }, []);

    const { mixpanelTrackEvent } = useAppEventTracking();
    const getDownloadProps = (ids, type, page, pageType, doc) => {
      setDownloadProps({
        ids,
        type,
        page,
        pageType,
        doc,
        name:
          ids.length > 1
            ? "valuer_company_reports"
            : `valuer_${selectedNames[0]}_report`,
        sortBy: sortValue,
        sortDirection,
      });
    };

    const download = () => {
      dispatch(exportCsvRequest(downloadProps));
      mixpanelTrackEvent(`Downloaded document`, {
        type: downloadProps.doc,
        page: isIndustry
          ? "industry"
          : isTechnology
          ? "technology"
          : isProject
          ? "project"
          : "results",
      });
    };

    return (
      <div className={classnames([classes.tableToolbar, className])} ref={ref}>
        <div padding="checkbox" className={classes.tableCellCheckbox}>
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all companies",
            }}
            className={classes.checkbox}
          />
        </div>
        <div scope="row" padding="none" className={classes.tableCellName}>
          <Typography
            sx={{ flex: "1 1 100%" }}
            color="textLight"
            variant="bodySmall"
            component="div"
            className={classes.selectedLabel}>
            {numSelected} selected
          </Typography>
        </div>
        {/* {!isWorkspace && <div
        className={classes.tableCellButtonsToolbar}
      >
        <div className={classes.relative}>
          <AddToProjectMenu 
            companies={selected} 
            companiesNames={selectedNames} 
            buttonType='greenbutton'
            isProject={isProject}
            projectSlug={projectSlug}
          />
        </div>
        {isAuthenticated && <>
        {!isLikedCompany ?
          <DownloadButton
            user={user}
            isIndustry={isIndustry}
            isTechnology={isTechnology}
            isProject={isProject}
            selected={selected}
            dispatch={dispatch}
            id={id}
            setShowAreYouSureModal={setShowAreYouSureModal}
            setShowDownloadsExceededModal={setShowDownloadsExceededModal}
            getDownloadProps={getDownloadProps}
            name={selected.length > 1 ? "valuer_company_reports" : `valuer_${selectedNames[0]}_report`}
            className={classes.downloadButton}
            sortBy={(sortValue && sortValue.slug) ? sortValue.slug : sortValue}
            sortDirection={sortDirection}
            page={isIndustry ? "industry" : isTechnology ? "technology" : isProject ? "project" : "results"}
          />
        : null}
        </>}
      </div>} */}
        {!isWorkspace && (
          <>
            <div className={classes.tableCell2}></div>
            <div className={classes.tableCell2}></div>
            <div className={classes.tableCell2}></div>
            <div className={classes.tableCell2}></div>
            <div className={classes.tableCell1}></div>
            <div className={classes.tableCell1}></div>
          </>
        )}
        <AreYouSureModal
          dialogOpened={showAreYouSureModal}
          onChange={() => setShowAreYouSureModal(false)}
          title={t("download.areYouSureTitle")}
          subtitle={
            <>
              {t("download.subtitleFreeTrial1")}
              <Typography
                component="div"
                className={classes.actionText}
                variant="body"
                color="secondaryDark">
                {t("download.subtitleFreeTrial2")}
              </Typography>
            </>
          }
          actionButtonText={t("download.iWantToDownload")}
          cancelButtonText={t("download.letsTryLater")}
          onActionClick={download}
        />
        <AreYouSureModal
          dialogOpened={showDownloadsExceededModal}
          onChange={() => setShowDownloadsExceededModal(false)}
          title={t("download.downloadsExceededTitle")}
          subtitle={t("download.downloadsExceededSubtitle")}
          actionButtonText={t("download.upgradePlan")}
          cancelButtonText={t("download.upgradeLater")}
          onActionClick={() => (window.location.href = getPricingPage(!!user))}
        />
      </div>
    );
  },
);

export default TableToolbar;
