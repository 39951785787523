import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// UI components
import Typography from "../../../../components/Typography/Typography";
import IconButton from "@material-ui/core/IconButton";
import Button from "../../../../components/Button/Button";
import Grow from "@material-ui/core/Grow";
// Hooks
import { useTranslation } from "react-i18next";
import { useStyles } from "./style";
import { useFreeTrialHook } from "../../../Layout/hooks/useFreeTrialHook";
import { useAuthHook } from "../../../../common/useAuthHook";
//UI Components
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
//Assets
import CloseIcon from "../../../../assets/icons/CloseIcon.svg";
import { ReactComponent as TopUpCredits } from "../../../../assets/icons/TopUpCredits.svg";
import { ReactComponent as TopUp } from "../../../../assets/icons/TopUp.svg";
import { ReactComponent as TopDown } from "../../../../assets/icons/TopDown.svg";
// Redux
import { buyCreditsRequest } from "../../../../store/credits/requests";
import {
  getBuyCreditsResponseSelector,
  getBuyCreditsStatusSelector,
} from "../../../../store/credits/selectors";
import {
  getSubscriptionSelector,
  getUpdateProfileStatusSelector,
} from "../../../../store/auth/selectors";
import { actions as authActions } from "../../../../store/auth/slice";
import { getSearchesSelector } from "../../../../store/results/selectors";
// Constants
import { REQUEST_SUCCESS } from "../../../../constants/statuses";
import { getPricingPage } from "../../../../constants/endpoints";

const TopUpCreditsModal = ({ dialogOpened, onChange, onBuy, credits }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [creditsSelected, setCreditsSelected] = useState(1);
  const dispatch = useDispatch();
  const getBuyCreditsStatus = useSelector(getBuyCreditsStatusSelector);
  const getBuyCreditsResponse = useSelector(getBuyCreditsResponseSelector);
  const subscription = useSelector(getSubscriptionSelector());
  const { user } = useAuthHook();
  const searchesSelector = useSelector(getSearchesSelector);
  const getUpdateProfileStatus = useSelector(getUpdateProfileStatusSelector);
  const [isHandleUpgradePlanTriggered, setIsHandleUpgradePlanTriggered] =
    useState(false);

  useEffect(() => {
    if (getBuyCreditsStatus === REQUEST_SUCCESS) {
      window.location.href = getBuyCreditsResponse.checkout_url;
    }
  }, [getBuyCreditsStatus]);

  const buy = () => {
    const data = { amount: creditsSelected, pageUrl: window.location.href };
    dispatch(buyCreditsRequest({ data }));
    // onBuy();
  };

  const topUp = () => {
    setCreditsSelected(prev => prev + 1);
  };

  const topDown = () => {
    creditsSelected > 1 && setCreditsSelected(prev => prev - 1);
  };

  useEffect(() => {
    if (
      getUpdateProfileStatus === REQUEST_SUCCESS &&
      isHandleUpgradePlanTriggered
    ) {
      window.location.href = getPricingPage(subscription && subscription.name);
    }
  }, [getUpdateProfileStatus]);

  const handleUpgradePlan = () => {
    if (
      user &&
      !user.company &&
      subscription &&
      (subscription.name === "free" || subscription.free_trial) &&
      searchesSelector.length > 1
    ) {
      dispatch(authActions.completeProfileModalShowTrue());
      setIsHandleUpgradePlanTriggered(true);
    } else {
      window.location.href = getPricingPage(subscription && subscription.name);
    }
  };

  const { talkToSales } = useFreeTrialHook({ user: user });

  return (
    <Dialog
      onClose={onChange}
      maxWidth="lg"
      TransitionComponent={Grow}
      classes={{
        root: classes.dialogRoot,
        paper:
          subscription &&
          subscription.free_trial &&
          subscription.free_trial &&
          subscription.free_trial
            ? classes.dialogFreeTrial
            : classes.dialog,
        scrollPaper: classes.dialogScrollPaper,
      }}
      onClose={(event, reason) => {
        if (reason === "backdropClick") {
          onChange(event, reason);
        }
      }}
      open={dialogOpened}>
      <IconButton className={classes.closeButton} onClick={onChange}>
        <img
          src={CloseIcon}
          alt={t("scope.step2")}
          className={classes.stepIcon}
        />
      </IconButton>
      {subscription &&
      subscription.free_trial &&
      subscription.free_trial &&
      subscription.free_trial ? (
        <div className={classes.freeTrial}>
          <Typography
            component="div"
            variant="subtitle1"
            color="black"
            align="center"
            className={classes.cannotBuyCreditsTitle}>
            {t("freeTrial.cannotBuyCreditsTitle")}
          </Typography>
          <Typography
            component="div"
            variant="bodySmall"
            color="black"
            align="center"
            className={classes.cannotBuyCreditsDescription}>
            {t("freeTrial.cannotBuyCreditsDescription")}
          </Typography>
          <div className={classes.buttons}>
            <Button
              className={classes.orderButton}
              onClick={handleUpgradePlan}
              variant="primary">
              {t("freeTrial.upgradeAccount")}
            </Button>
          </div>
          <div className={classes.haveQuestionWrapText}>
            <Typography
              className={classes.haveQuestion}
              color="textLighter"
              variant="bodySmall"
              component="div">
              {t("confirmFreeTrialStartModal.noCreditCard")}
            </Typography>
            <Typography color="textLighter" component="div" variant="bodySmall">
              {t("confirmFreeTrialStartModal.forCustomTrials")}
              <Typography
                onClick={() => talkToSales()}
                className={classes.reachOut}
                variant="bodySmall">
                {t("confirmFreeTrialStartModal.talkToSales")}
              </Typography>
            </Typography>
          </div>
        </div>
      ) : (
        <>
          <MuiDialogContent className={classes.dialogContent}>
            <TopUpCredits className={classes.requestIcon} />
            <Typography
              component="div"
              variant="h6"
              color="black"
              className={classes.title}>
              {t("companyProfile.topUpCredits")}
            </Typography>
            <Typography component="div" variant="bodySmall" align="center">
              {t("companyProfile.numberOfCredits")}
            </Typography>
          </MuiDialogContent>
          <div>
            <TopDown className={classes.topUpDownIcon} onClick={topDown} />
            <Typography
              component="span"
              inline
              variant="h3"
              className={classes.topUpDownNumber}
              align="center">
              <strong>{creditsSelected}</strong>
            </Typography>
            <TopUp className={classes.topUpDownIcon} onClick={topUp} />
          </div>
          <div className={classes.priceWrapper}>
            <Typography
              component="span"
              color="black"
              inline
              variant="bodySmall"
              align="center">
              {t("companyProfile.price")}
            </Typography>
            <Typography
              component="span"
              color="black"
              inline
              variant="bodySmall"
              align="center"
              className={classes.eur}>
              {t("companyProfile.eur", {
                count: creditsSelected * 1000,
              })}
            </Typography>
          </div>
          <div className={classes.buttons}>
            <Button onClick={onChange} variant="quaternary">
              {t("companyProfile.cancel")}
            </Button>
            <Button
              className={classes.orderButton}
              onClick={buy}
              variant="primary">
              {t("companyProfile.buyNow")}
            </Button>
          </div>
          {/* <Typography component="div" variant="bodySmall" align="center">{t('companyProfile.vatAdded')}</Typography> */}
          <div>
            <Typography
              component="span"
              inline
              variant="bodySmall"
              align="center">
              {t("companyProfile.alwaysRunning")}
            </Typography>
            <Typography
              component="span"
              inline
              variant="bodySmall"
              align="center"
              onClick={handleUpgradePlan}
              className={classes.upgradePlan}>
              {t("companyProfile.upgradeYourPlan")}
            </Typography>
          </div>
        </>
      )}
    </Dialog>
  );
};
export default TopUpCreditsModal;
