import axiosInstance from "../axiosInstance";
import axios from "axios";
import {
  CHAT_INTRO_URL,
  CHAT_INTRO_URL_STREAM,
  EXISTING_INTRO_TEXT_URL,
  SAVE_INTRO_ID_URL,
} from "../../constants/endpoints";

const basicAuth =
  "Basic " +
  btoa(process.env.REACT_APP_ML_USER + ":" + process.env.REACT_APP_ML_PASSWORD);

axios.interceptors.request.use(
  function (config) {
    config.headers.Authorization = basicAuth;
    config.headers.common["Content-Type"] = "application/json";
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

class ChatIntroService {
  saveIntroId = (sid, intro_id) => {
    return axiosInstance.post(SAVE_INTRO_ID_URL, {
      sid,
      intro_id,
    });
  };
  getExistingIntro = scopeID => {
    return axios
      .get(EXISTING_INTRO_TEXT_URL(scopeID))
      .then(res => res.data.intro);
  };
  getIntroText = (llm_id, scope_guide_steps, scope_description) => {
    return axios
      .post(CHAT_INTRO_URL, {
        llm_id,
        scope_guide_steps,
        scope_description,
      })
      .then(res => res.data.intro);
  };

  async *streamIntroText(llm_id, scope_guide_steps, scope_description) {
    const response = await fetch(CHAT_INTRO_URL_STREAM, {
      method: "POST",
      body: JSON.stringify({
        llm_id,
        scope_guide_steps,
        scope_description,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: basicAuth,
      },
    });

    const reader = response.body.getReader();
    const decoder = new TextDecoder("utf-8");

    while (true) {
      const { done, value } = await reader.read();
      if (done) break;
      const parts = decoder.decode(value, { stream: true }).split("}{");
      for (let i = 0; i < parts.length; i++) {
        let part = parts[i];
        if (i !== parts.length - 1) {
          part += "}";
        }
        if (i !== 0) {
          part = "{" + part;
        }
        try {
          const message = JSON.parse(part);
          yield message;
        } catch (error) {}
      }
    }
  }
}

export default ChatIntroService;
