import { createStyles, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(
  ({ palette, spacing, shape, typography, breakpoint }) =>
    createStyles({
      rightColumnContainer: {
        // width: "50%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      },
      summaryBoxContainer: {
        border: `1px solid ${palette.text.backgroundMain}`,
        borderRadius: "18px",
        minHeight: 310,
        overflow: "hidden",
        marginBottom: spacing(2),
      },
      summaryBox: {
        background: "#fff",
        height: "308px",
        minHeight: 310,
        padding: spacing(2),
        overflowX: "auto",
      },
      summaryBoxWithError: {
        display: "flex",
        flexDirection: "column",
      },
      summaryBoxHeader: {
        display: "flex",
        justifyContent: "space-between",
      },
      boxTitle: {
        fontWeight: 500,
        marginBottom: spacing(1),
      },
      summaryBoxFullTextBtn: {
        marginBottom: spacing(1),
        color: palette.primary.dark,
        cursor: "pointer",
        fontFamily: "GT FLexa Mono, sans-serif",
      },
      summaryBoxContent: {
        height: "100%",
      },
      chatBox: {
        height: "100%",
        flex: 1,
        minHeight: "280px",
        paddingBottom: "32px",
      },
      summaryModal: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        maxHeight: "98vh",
        "& div": {
          flex: 1,
        },
      },
      summaryModalLeft: {
        padding: spacing(4),
        display: "flex",
        flexDirection: "column",
        flex: 1,
      },
      summaryModalRight: {
        position: "relative",
        maxWidth: "65px",
        background: palette.success.lighter,
        padding: "30px",
      },
      summaryContent: {
        fontSize: "14px",
        fontWeight: 300,
      },
      summaryCenteredConted: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
      },
      tryAgainBtn: {
        height: "100%",
        alignSelf: "center",
        display: "flex",
        alignItems: "center",
      },
      fullDescBtn: {
        fontFamily: "GT Flexa",
        fontSize: "14px",
        padding: `${spacing(1)}px ${spacing(2)}px`,
        fontWeight: 500,
      },
    }),
);
