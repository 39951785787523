import { createStyles, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(({ palette, spacing, typography }) =>
  createStyles({
    infoToolTip: {
      display: "inline-block",
    },
    icon: {
      width: "22px",
      height: "22px",
      borderRadius: "100px",
      color: palette.success.dark,
      backgroundColor: palette.success.medium,
      textAlign: "center",
      lineHeight: 1.5,
      marginLeft: spacing(1),
    },
  }),
);
