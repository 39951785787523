import { call } from "redux-saga/effects";
import axios from "axios";
import {
  CREDITS_SUMMARY_URL,
  getBuyCreditsUrl,
  getPlanUrl,
} from "../../../constants/endpoints";

function* postBuyCredits(data) {
  const { amount, pageUrl } = data;
  const response = yield call(axios, {
    method: "POST",
    url: `${getBuyCreditsUrl(amount, pageUrl)}`,
    withCredentials: true,
  });
  return response.data;
}

function* fetchCreditsSummary() {
  const response = yield call(axios, {
    method: "GET",
    url: `${CREDITS_SUMMARY_URL}`,
    withCredentials: true,
  });
  return response.data;
}

function* fetchPlan(plan, period, amount, currency) {
  const response = yield call(axios, {
    method: "GET",
    url: `${getPlanUrl(plan, period, amount, currency)}`,
    withCredentials: true,
  });
  return response.data;
}

export { postBuyCredits, fetchCreditsSummary, fetchPlan };
