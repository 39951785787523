import { spawn } from "redux-saga/effects";

import { saga as search } from "./search";
import { saga as sectors } from "./sectors";
import { saga as results } from "./results";
import { saga as auth } from "./auth";
import { saga as projects } from "./projects";
import { saga as mixpanel } from "./mixpanel";
import { saga as companyProfile } from "./companyProfile";
import { saga as invites } from "./invites";
import { saga as countries } from "./countries";
import { saga as credits } from "./credits";
import { saga as billing } from "./billing";
import { saga as technologies } from "./technologies";
import { saga as industries } from "./industries";
import { saga as freeTrial } from "./freeTrial";
import { saga as orders } from "./orders";
import { saga as dashboard } from "./dashboard";
import { saga as cluster } from "./cluster";
import { saga as userConversation } from "./userConversation";

export default function* rootSaga() {
  yield spawn(search);
  yield spawn(sectors);
  yield spawn(results);
  yield spawn(auth);
  yield spawn(projects);
  yield spawn(mixpanel);
  yield spawn(companyProfile);
  yield spawn(invites);
  yield spawn(countries);
  yield spawn(credits);
  yield spawn(billing);
  yield spawn(technologies);
  yield spawn(industries);
  yield spawn(freeTrial);
  yield spawn(orders);
  yield spawn(dashboard);
  yield spawn(cluster);
  yield spawn(userConversation);
}
