import { call } from "redux-saga/effects";
import axios from "axios";
import {
  CHECK_RADAR_TITLE_URL,
  COMPANY_DELETE_URL,
  getCheckProjectsURL,
  getEnrichCompanyUrl,
  getProjectStatusUrl,
  getProjectUrl,
  PROJECT_UPDATE_ITEMS_URL,
  PROJECT_UPDATE_URL,
  PROJECTS_URL,
  removeProjectURL,
} from "../../../constants/endpoints";

function* getProjects() {
  const response = yield call(axios, {
    method: "GET",
    url: `${PROJECTS_URL}`,
    withCredentials: true,
  });
  return response.data;
}

function* getProject(slug, data) {
  let url = "";
  if (data) {
    const { filterNameValue } = data;
    url = `${getProjectUrl(slug)}?${filterNameValue}`;
  } else {
    url = `${getProjectUrl(slug)}`;
  }
  const response = yield call(axios, {
    method: "GET",
    url,
    withCredentials: true,
  });
  return response.data;
}

function* postProjects(items, projectTitle, projectDescription, type) {
  const response = yield call(axios, {
    method: "POST",
    url: `${PROJECTS_URL}`,
    withCredentials: true,
    data: {
      items: items,
      title: projectTitle,
      description: projectDescription,
      type: type,
    },
  });
  return response.data;
}

function* postUpdateProjects(projectSlug, projectTitle, projectDescription) {
  const response = yield call(axios, {
    method: "POST",
    url: `${PROJECT_UPDATE_URL}`,
    withCredentials: true,
    data: {
      slug: projectSlug,
      title: projectTitle,
      description: projectDescription,
    },
  });
  return response.data;
}

function* postAddItemToProject(project, items, type) {
  let data = new FormData();
  // data.append("project", project);
  // data.append("items", items);
  // data.append("type", type);
  const response = yield call(axios, {
    method: "POST",
    url: `${PROJECT_UPDATE_ITEMS_URL}?project=${project}&items=${items}&type=${type}`,
    // data,
    withCredentials: true,
  });
  return response.data;
}

function* postStatusProject(projectSlug) {
  const response = yield call(axios, {
    method: "POST",
    url: `${getProjectStatusUrl(projectSlug)}`,
    withCredentials: true,
  });
  return response.data;
}

function* postDeleteCompany(companyId, projectSlug) {
  const response = yield call(axios, {
    method: "POST",
    url: `${COMPANY_DELETE_URL}?project=${projectSlug}&items=${companyId}`,
    withCredentials: true,
  });
  return response.data;
}

function* postEnrichCompany(cId, slug) {
  let data = new FormData();
  data.append("cId", cId);
  const response = yield call(axios, {
    method: "POST",
    url: `${getEnrichCompanyUrl(slug)}`,
    withCredentials: true,
    data,
  });
  return response.data;
}

function* checkProjectTitle() {
  const response = yield call(axios, {
    method: "GET",
    url: `${CHECK_RADAR_TITLE_URL}`,
    withCredentials: true,
  });
  return response.data;
}

function* checkProjectsAPI(companyId, type) {
  const response = yield call(axios, {
    method: "GET",
    url: `${getCheckProjectsURL(companyId, type)}`,
    withCredentials: true,
  });
  return response.data;
}

function* removeProject(slug) {
  const response = yield call(axios, {
    method: "POST",
    url: `${removeProjectURL(slug)}`,
    withCredentials: true,
  });
  return response.data;
}

export {
  getProjects,
  postProjects,
  postStatusProject,
  postUpdateProjects,
  postAddItemToProject,
  getProject,
  postDeleteCompany,
  postEnrichCompany,
  checkProjectTitle,
  checkProjectsAPI,
  removeProject,
};
