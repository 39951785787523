import { createStyles, makeStyles } from "@material-ui/core/styles";

export default makeStyles(({ palette, spacing, typography }) =>
  createStyles({
    select: {
      ...typography.body2,
      background: palette.common.white,
      borderRadius: spacing(3),
      padding: 0,
      border: "1px solid",
      borderColor: `${palette.text.lightest} !important`,
      fontFamily: "GT Flexa, sans-serif",
      color: palette.secondary.dark,
      width: ({ width }) => (width ? width : "auto"),
      height: 40,
    },
    root: {
      zIndex: 2,
      position: "relative",
      padding: "10px 22px 10px 24px",
      borderRadius: 24,
      border: "1px solid transparent",
      "&:focus": {
        borderRadius: 24,
        backgroundColor: "transparent",
        borderColor: `${palette.primary.dark} !important`,
      },
    },
    menuList: {
      padding: 0,
    },
    focused: {
      borderColor: `${palette.primary.dark} !important`,
    },
    headerLabel: {
      color: palette.text.main,
      fontSize: 14,
      fontFamily: "GT Flexa Mono, sans-serif",
      lineHeight: "16px",
      marginBottom: `${spacing(1)}px`,
      minHeight: 16,
      transform: "none",
      display: ({ removeLabel }) => (removeLabel ? "none" : "block"),
    },
    nativeInput: {
      padding: "12px 24px",
    },
    disabled: {
      background: palette.text.lightest,
      borderRadius: spacing(3),
    },
    filled: {
      color: palette.common.black,
      // borderColor: `${palette.secondary.main} !important`
    },
    menuPaper: {
      borderRadius: 16,
      minWidth: "185px !important",
      marginTop: 6,
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    },
    menuItem: {
      fontFamily: "GT Flexa Mono, sans-serif",
      padding: "14px 24px",
      color: palette.text.light,
      ...typography.bodyXs,
      backgroundColor: `${palette.common.white} !important`,
      "&:hover": {
        backgroundColor: `${palette.grey[200]} !important`,
      },
    },
    menuItemWithCheckbox: {
      padding: "2px 16px",
    },
    menuItemSelected: {
      color: palette.text.main,
      backgroundColor: `${palette.grey[300]} !important`,
    },
    blackText: {
      color: palette.common.black,
    },
    loadFullView: {
      padding: "8px 16px !important",
    },
    noLeftRadius: {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      paddingLeft: 0,
      "& > $root": {
        paddingLeft: spacing(2),
        paddingRight: spacing(4),
        "&:focus": {
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
        },
      },
      "&$focused": {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
      },
    },
    checkbox: {
      flex: 1,
    },
    checkboxWrapper: {
      display: "flex",
      width: "100%",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    menuItemWrapper: {
      display: "flex",
      justifyContent: "space-between",
      flex: 7,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    expandWrapper: {
      justifyContent: "center",
      textDecoration: "underline",
    },
  }),
);
