import React, {
  createRef,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import ClickAwayListener from "react-click-away-listener";
import classnames from "classnames";
// Hooks
import { useTranslation } from "react-i18next";
import { useFreeTrialHook } from "../../../Layout/hooks/useFreeTrialHook";
//assets
import CloseButton from "../../../../assets/icons/CloseIcon.svg";
import Typography from "../../../../components/Typography/Typography";
import Button from "../../../../components/Button/Button";
import InfoLetter from "../../../../assets/icons/InfoLetter.svg";
import { ReactComponent as SignUpIndustryIllustration } from "../../../../assets/icons/SignUpIndustryIllustration.svg";
import { ReactComponent as ArrowDown } from "../../../../assets/icons/ArrowDownGreyL.svg";
import WhatsClusterIllustration from "../../../../assets/images/WhatsClusterIllustration.png";
// Components
import Modal from "../../../../components/Modal/Modal";
import Tooltip from "@material-ui/core/Tooltip";
import SeeSectorsModal from "../../../../components/SeeSectorsModal/SeeSectorsModal.js";
// Constants
import { AddToProjectMenu } from "../../../../components/AddToProjectMenu/AddToProjectMenu";

const ClusterMapModal = forwardRef(
  (
    {
      onChange,
      dialogOpened,
      user,
      mappedSubsectors,
      onClickAway,
      selectedCompany,
      selectedSubsector,
      pageWidth,
      handleCompanyClick,
      clusersMapWidth,
      border,
      backgroundColor,
      cardRef,
      companyAvatarSrc,
      onCompanyAvatarError,
      sectorsTagsWrapperRef,
      subindustriesTagsWrapperRef,
      technologiesTagsWrapperRef,
      descRef,
      descReadMore,
      descHeight,
      toggleDesc,
      subscriptionStatus,
      getExpiredPlanMsg,
      setShowAddToProjectDialog,
      handleOpenCompany,
      openSignUpPage,
      readMore,
      setReadMore,
      classes,
      onImgError,
      industry,
      imageSrc,
      handleSubsectorSelect,
      setShowSubindustryDefinitionsModal,
      setSelectedSubsector,
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const wrapperRef = createRef(null);
    const openCompanyButtonRef = useRef();

    const definitionsRef = createRef(null);

    const seeDefinitions = () => {
      let defPosition =
        definitionsRef.current && definitionsRef.current.offsetTop;
      wrapperRef.current &&
        wrapperRef.current.scrollTo({
          top: defPosition - 60,
          behavior: "smooth",
        });
    };

    useImperativeHandle(ref, () => ({
      seeDefinition() {
        let defPosition =
          definitionsRef.current && definitionsRef.current.offsetTop;
        wrapperRef.current &&
          wrapperRef.current.scrollTo({
            top: defPosition - 60,
            behavior: "smooth",
          });
      },
    }));

    const { talkToSales } = useFreeTrialHook({ user: user });

    const handleTalkToSales = e => {
      e.preventDefault();
      e.stopPropagation();
      talkToSales();
    };

    // useEffect(() => {
    //     document.addEventListener('mousedown', (e) => handleOnClickAway(e), true);
    //     return () => {
    //         document.addEventListener('mousedown', (e) => handleOnClickAway(e), true);
    //     }
    // }, []);

    const handleOnClickAway = e => {
      e.preventDefault();
      e.stopPropagation();
      if (
        e.target &&
        e.target.innerText !== "See Full Profile" &&
        e.target.innerText !== "Add to project" &&
        e.target.innerText !== "See industries" &&
        e.target.innerText !== "See sub-industries" &&
        e.target.innerText !== "+ Create new project" &&
        e.target.alt &&
        e.target.alt !== "scope.step2" &&
        e.target.alt &&
        e.target.alt !== "Industry" &&
        e.target.className &&
        !e.target.className.includes("projectNameWrapper")
      ) {
        setShowAddToProjectDialog(false);
        onClickAway();
      }
    };

    return (
      <Modal
        dialogOpened={dialogOpened}
        borderRadius="16px"
        closeButtonIcon={CloseButton}
        classnames={{
          scrollPaper: classes.dialogScrollPaper,
          paper: classes.modalPaper,
        }}
        onClose={onChange}
        dialogContentRef={wrapperRef}>
        <div>
          <div className={classes.info}>
            <div className={classes.avatar}>
              <img
                width="56"
                height="56"
                src={imageSrc}
                alt={industry && industry.name}
                className={classes.avatar}
                onError={onImgError}
              />
            </div>
            <div className={classes.industryTitle}>
              <Typography variant="subtitle1" gutterBottom component="div">
                {industry && industry.name}
              </Typography>
            </div>
          </div>
          <div data-intercom-target="Subindustries">
            <div className={classes.subsectorsHeader}>
              <div>
                <Typography
                  variant="body"
                  gutterBottom
                  color="black"
                  component="span"
                  className={classes.subindustriesTitle}>
                  {t("industryProfile.subindustries")}
                  <img
                    src={InfoLetter}
                    className={classes.infoLetterMobile}
                    onClick={() => setShowSubindustryDefinitionsModal(true)}
                  />
                </Typography>
                {selectedSubsector.length > 1 && (
                  <Typography
                    variant="body"
                    onClick={() => setSelectedSubsector([])}
                    gutterBottom
                    color="textLight"
                    component="span"
                    className={classes.unselectAll}>
                    {t("industryProfile.unselectAll")}
                  </Typography>
                )}
              </div>
              <Typography
                onClick={seeDefinitions}
                variant="bodyXs"
                color="textLight"
                gutterBottom
                component="div"
                className={classnames([classes.mono, classes.readDefinitions])}>
                {t("industryProfile.readDefinitions")}
                <img src={InfoLetter} className={classes.infoLetter} />
              </Typography>
            </div>
            <div className={classes.subsectorsWrapper}>
              {mappedSubsectors &&
                mappedSubsectors.map((subsector, i) => {
                  return subsector && subsector.companies.length > 0 ? (
                    <Button
                      variant="pill"
                      key={`${i}-button`}
                      className={classnames([
                        classes.sector,
                        classes.selectedSubsector,
                        classes.subsectorColor,
                        {
                          [classes.activeSector]:
                            selectedSubsector &&
                            subsector &&
                            selectedSubsector.some(
                              s => s.name === subsector.name,
                            ),
                        },
                      ])}
                      onClick={() => handleSubsectorSelect(subsector)}
                      onTouchStart={() => handleSubsectorSelect(subsector)}
                      {...(selectedSubsector &&
                        selectedSubsector.some(
                          s => s.name === subsector.name,
                        ) && { active: true })}>
                      <div
                        className={classes.color}
                        style={{
                          background: subsector.color,
                        }}></div>
                      <Typography
                        variant="body"
                        color="secondaryDark"
                        component="span"
                        className={classes.subsectorPill}>
                        {subsector.name}
                      </Typography>
                      {/* {subsector.ready && <Ready className={classes.inProgressIcon} />} */}
                    </Button>
                  ) : (
                    <Tooltip
                      classes={{
                        tooltip: classes.tooltip,
                      }}
                      enterDelay={350}
                      key={subsector.slug}
                      title={
                        <Typography variant="body2" color="white">
                          {t(`industryProfile.noCompanies`)}
                        </Typography>
                      }
                      arrow>
                      <Button
                        variant="pill"
                        className={classnames([
                          classes.sector,
                          classes.disabledSector,
                          classes.selectedSubsector,
                          classes.subsectorColor,
                        ])}
                        {...(selectedSubsector &&
                          selectedSubsector.some(
                            s => s.name === subsector.name,
                          ) && { active: true })}>
                        <div
                          className={classes.color}
                          style={{
                            background: subsector.color,
                          }}></div>
                        <Typography
                          variant="body"
                          color="textLight"
                          gutterBottom
                          component="span">
                          {subsector.name}
                        </Typography>
                        {/* {subsector.ready && <Ready className={classes.inProgressIcon} />} */}
                      </Button>
                    </Tooltip>
                  );
                })}
            </div>
          </div>
          <div className={classes.clustersSectionWrapper}>
            <div
              className={classes.clustersWrapper}
              data-intercom-target="Cluster view">
              <div className={classes.clusters3DWrapper}>
                <div className={classes.clusters}>
                  {mappedSubsectors &&
                    mappedSubsectors.map((subsector, index) => {
                      let colors = [];
                      return (
                        subsector.companies &&
                        subsector.companies.length > 0 &&
                        subsector.companies.map((company, i) => {
                          colors.push(subsector.color);
                          if (user) {
                            return (
                              <ClickAwayListener
                                onClickAway={e => handleOnClickAway(e)}
                                key={`${index}-${i}-company`}>
                                <>
                                  <Tooltip
                                    className={classes.tooltip1}
                                    classes={{
                                      tooltip: classes.tooltip,
                                    }}
                                    enterDelay={350}
                                    key={company.id}
                                    title={
                                      <Typography variant="body2" color="white">
                                        {company.name}
                                      </Typography>
                                    }
                                    arrow>
                                    <div
                                      className={classnames(classes.company, {
                                        [classes.notSelectedCompany]:
                                          selectedCompany !== company &&
                                          selectedSubsector.length !== 0 &&
                                          !selectedSubsector.some(
                                            s => s.color === subsector.color,
                                          ),
                                        [classes.clickableCompany]:
                                          (selectedSubsector.length === 0 ||
                                            selectedSubsector.some(
                                              s => s.name === subsector.name,
                                            )) &&
                                          pageWidth > 1024,
                                      })}
                                      onClick={e =>
                                        handleCompanyClick(
                                          e,
                                          company,
                                          subsector,
                                        )
                                      }
                                      style={{
                                        transform: `translateZ(${
                                          company.z * 400
                                        }px)`,
                                        bottom: company.y * clusersMapWidth,
                                        left: company.x * clusersMapWidth,
                                        background: backgroundColor(
                                          company,
                                          colors,
                                          subsector,
                                        ),
                                        border: `1px solid ${border(
                                          company,
                                          colors,
                                          subsector,
                                        )}`,
                                      }}></div>
                                  </Tooltip>
                                </>
                              </ClickAwayListener>
                            );
                          } else {
                            return (
                              <ClickAwayListener
                                onClickAway={e => handleOnClickAway(e)}
                                key={`${index}-${i}-company`}>
                                <>
                                  <Tooltip
                                    className={classes.tooltip1}
                                    classes={{
                                      tooltip: classes.tooltip,
                                    }}
                                    enterDelay={350}
                                    key={company.id}
                                    title={
                                      <Typography variant="body2" color="white">
                                        {company.name}
                                      </Typography>
                                    }
                                    arrow>
                                    <div
                                      className={classnames(classes.company, {
                                        [classes.notSelectedCompany]:
                                          selectedCompany !== company &&
                                          selectedSubsector.length !== 0 &&
                                          !selectedSubsector.some(
                                            s => s.color === subsector.color,
                                          ),
                                        [classes.clickableCompany]:
                                          (selectedSubsector.length === 0 ||
                                            selectedSubsector.some(
                                              s => s.name === subsector.name,
                                            )) &&
                                          pageWidth > 1024,
                                      })}
                                      onClick={e =>
                                        handleCompanyClick(
                                          e,
                                          company,
                                          subsector,
                                        )
                                      }
                                      style={{
                                        transform: `translateZ(${
                                          company.z * 400
                                        }px)`,
                                        bottom: company.y * clusersMapWidth,
                                        left: company.x * clusersMapWidth,
                                        background: backgroundColor(
                                          company,
                                          colors,
                                          subsector,
                                        ),
                                        border: `1px solid ${border(
                                          company,
                                          colors,
                                          subsector,
                                        )}`,
                                      }}></div>
                                  </Tooltip>
                                </>
                              </ClickAwayListener>
                            );
                          }
                        })
                      );
                    })}
                </div>
              </div>
            </div>
            {Object.keys(selectedCompany).length > 0 ? (
              <div
                className={classnames([classes.companyInfo, "non-laptop"])}
                ref={cardRef}>
                {user ? (
                  <>
                    <div className={classes.companyHeader}>
                      <img
                        width="48"
                        height="48"
                        src={companyAvatarSrc}
                        alt={selectedCompany.name}
                        className={classes.companyLogo}
                        onError={onCompanyAvatarError}
                      />
                      <Typography
                        className={classes.companyName}
                        variant="paragraph"
                        color="secondaryDark"
                        component="div">
                        {selectedCompany.name}
                      </Typography>
                    </div>
                    {selectedCompany.industries && (
                      <div className={classes.sectors}>
                        <Typography
                          className={classnames([classes.mono, classes.label])}
                          variant="label"
                          color="text"
                          component="div">
                          {t(`industryProfile.industries`)}
                        </Typography>
                        <div
                          className={classes.sectorTagsWrapper}
                          ref={sectorsTagsWrapperRef}>
                          <SeeSectorsModal
                            sectors={selectedCompany.industries}
                            isIndustryProfile={true}
                          />
                        </div>
                      </div>
                    )}
                    {selectedCompany.sub_industries && (
                      <div className={classes.sectors}>
                        <Typography
                          className={classnames([classes.mono, classes.label])}
                          variant="label"
                          color="text"
                          component="div">
                          {t(`industryProfile.subindustries`)}
                        </Typography>
                        <div
                          className={classes.sectorTagsWrapper}
                          ref={subindustriesTagsWrapperRef}>
                          <SeeSectorsModal
                            subsectors={selectedCompany.sub_industries}
                            isIndustryProfile={true}
                          />
                        </div>
                      </div>
                    )}
                    {selectedCompany.technologies && (
                      <div className={classes.sectors}>
                        <Typography
                          className={classnames([classes.mono, classes.label])}
                          variant="label"
                          color="text"
                          component="div">
                          {t(`industryProfile.technologies`)}
                        </Typography>
                        <div
                          className={classes.sectorTagsWrapper}
                          ref={technologiesTagsWrapperRef}>
                          <SeeSectorsModal
                            technologies={selectedCompany.technologies}
                            isIndustryProfile={true}
                          />
                        </div>
                      </div>
                    )}
                    <Typography
                      className={classes.label}
                      variant="label"
                      color="text"
                      component="div">
                      {t(`industryProfile.description`)}
                    </Typography>
                    <div className={classes.descriptionBox} ref={descRef}>
                      <Typography
                        className={
                          descReadMore && descHeight > 178
                            ? classes.clusterDesc
                            : classes.clusterDescClosed
                        }
                        variant="bodyXs"
                        color="text"
                        component="div">
                        {selectedCompany.description}
                      </Typography>
                      {descHeight > 178 && (
                        <>
                          {descReadMore ? (
                            <Typography
                              align="right"
                              variant="body"
                              className={classes.readMore}
                              gutterBottom
                              color="primary"
                              component="div"
                              onClick={e => toggleDesc(e, false)}>
                              {t("industryProfile.showLess")}
                            </Typography>
                          ) : (
                            <Typography
                              variant="body"
                              className={classes.readMore}
                              gutterBottom
                              color="primary"
                              component="div"
                              onClick={e => toggleDesc(e, true)}>
                              {t("industryProfile.readMore")}
                            </Typography>
                          )}
                        </>
                      )}
                    </div>

                    <div className={classes.tagButtonWrap}>
                      <Typography
                        variant="bodyButton"
                        color="primaryDark"
                        className={classnames([
                          classes.openCompanyButton,
                          user &&
                            user.subscription &&
                            user.subscription.name === "free" &&
                            classes.openCompanyButtonFreeUser,
                          "non-desktop",
                        ])}
                        onClick={e => handleOpenCompany(e, selectedCompany)}
                        ref={openCompanyButtonRef}>
                        {t(`results.companyDetails`)}
                      </Typography>
                      {subscriptionStatus == "expired" ? (
                        <Tooltip
                          title={
                            <Typography color="white" variant="body2">
                              {getExpiredPlanMsg()}
                            </Typography>
                          }
                          arrow>
                          <div className={classes.addToProjectDisabled}>
                            <Typography
                              color="secondaryDark"
                              variant="bodyButton">
                              {t("companyProfile.addToProject")}
                            </Typography>
                            <ArrowDown className={classes.arrowImg} />
                          </div>
                        </Tooltip>
                      ) : (
                        <AddToProjectMenu
                          buttonType="checkbox"
                          company={{
                            id: selectedCompany.cId,
                            name: selectedCompany.name,
                          }}
                          type="industry"
                        />
                      )}
                    </div>
                  </>
                ) : (
                  <div className={classes.greenBox}>
                    <SignUpIndustryIllustration />
                    <Typography
                      className={classes.wantToSeeLabel}
                      variant="body"
                      color="black"
                      align="center"
                      component="div">
                      {t("industryProfile.wantToSee")}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      color="black"
                      align="center"
                      component="div"
                      className={classes.signUpToGetTitle}>
                      <strong>{t(`industryProfile.signUpToGet`)}</strong>
                    </Typography>
                    <div className={classes.greenBoxCtas}>
                      <Typography
                        variant="bodyButton"
                        className={classes.signUpBtn}
                        onClick={openSignUpPage}>
                        {t("industryProfile.startFreeTrial")}
                      </Typography>
                    </div>
                    <Typography
                      className={classes.haveQuestion}
                      color="textLighter"
                      variant="bodySmall">
                      {t("industryProfile.noCreditCard")}
                    </Typography>
                    <div className={classes.haveQuestionWrapText}>
                      <Typography
                        className={classes.haveQuestion}
                        color="textLighter"
                        variant="bodySmall">
                        {t("industryProfile.forCustomTrials")}
                      </Typography>
                      <Typography
                        onClick={handleTalkToSales}
                        className={classes.reachOut}
                        variant="bodySmall">
                        {t("freeTrial.talkToSales")}
                      </Typography>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className={classnames([classes.companyInfo, "non-laptop"])}>
                <img
                  src={WhatsClusterIllustration}
                  className={classes.whatsClusterIllustration}
                />
                <Typography
                  className={classes.clusterTitle}
                  variant="paragraph"
                  color="secondaryDark"
                  component="div">
                  {t("industryProfile.whatsaCluster")}
                </Typography>
                <Typography
                  className={classnames([classes.mono, classes.label])}
                  variant="label"
                  color="text"
                  component="div">
                  {t(`industryProfile.clusterDescription`)}
                </Typography>
                <Typography
                  className={
                    readMore ? classes.clusterDesc : classes.clusterDescClosed
                  }
                  variant="bodyXs"
                  color="text"
                  component="div">
                  {t(`industryProfile.clusterDesc`)}
                </Typography>
                {readMore ? (
                  <Typography
                    align="right"
                    variant="body"
                    className={classes.readMore}
                    gutterBottom
                    color="primary"
                    component="div"
                    onClick={() => setReadMore(false)}>
                    {t("industryProfile.showLess")}
                  </Typography>
                ) : (
                  <Typography
                    variant="body"
                    className={classes.readMore}
                    gutterBottom
                    color="primary"
                    component="div"
                    onClick={() => setReadMore(true)}>
                    {t("industryProfile.readMore")}
                  </Typography>
                )}
              </div>
            )}
          </div>
          <div ref={definitionsRef}>
            <Typography variant="paragraph" color="black" component="div">
              <strong>{t("industryProfile.subIndustryDefinitions")}</strong>
            </Typography>
            <Typography
              variant="bodyXs"
              className={classes.mono}
              color="text"
              component="div">
              {t("industryProfile.subIndustryDefinitionsLabel")}
            </Typography>
            <div className={classes.dialogContent}>
              <div className={classes.subsectorsExplanationsWrapper}>
                {mappedSubsectors &&
                  mappedSubsectors.map(subsector => (
                    <div
                      className={classes.subsectorTitleWrapper}
                      key={`${subsector.slug}-subsector`}>
                      <div
                        className={classes.subsectorTitle}
                        {...(selectedSubsector === subsector && {
                          active: true,
                        })}>
                        <div
                          className={classes.color}
                          style={{
                            background: subsector.color,
                          }}></div>
                        <Typography
                          variant="body"
                          color="secondaryDark"
                          component="div">
                          {subsector.name}
                        </Typography>
                      </div>
                      <Typography variant="bodyXs" color="text" component="div">
                        {subsector.description}
                      </Typography>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  },
);
export default ClusterMapModal;
