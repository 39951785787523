import { createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(({ palette, spacing, shape, breakpoint }) =>
  createStyles({
    root: {
      // padding: spacing(3)
      marginBottom: spacing(4),
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-start",
    },
    startEndornment: {
      marginRight: spacing(1),
    },
    inviteIcon: {
      marginRight: spacing(1),
      cursor: "pointer",
    },
    headerInput: {
      [breakpoint.down("md")]: {
        width: "100%",
      },
      marginTop: spacing(1),
      "& input": {
        [breakpoint.down("md")]: {
          width: "100%",
        },
        width: 167,
      },
    },
    createProjectButton: {
      background: palette.primary.dark,
    },
    flexColumn: {
      width: "100%",
    },
    flex: {
      display: "flex",
      justifyContent: "space-between",
    },
    moreIcon: {
      cursor: "pointer",
    },
    moreIconWrapper: {
      position: "relative",
      width: 24,
    },
    moreIconMenu: {
      position: "absolute",
      top: 20,
      right: 0,
      background: palette.common.white,
      border: `1px solid ${palette.text.backgroundDark}`,
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
      borderRadius: spacing(2),
      zIndex: 2,
      minWidth: 190,
      fontFamily: "GT Flexa Mono, sans-serif",
    },
    moreIconMenuItem: {
      padding: `${spacing(2)}px ${spacing(3)}px`,
      "&:hover": {
        cursor: "pointer",
        color: palette.error.main,
      },
    },
    numProjects: {
      display: "flex",
      alignItems: "baseline",
    },
    title: {
      marginRight: spacing(1),
    },
    upgradeCta: {
      marginTop: spacing(2),
      textDecoration: "underline",
      cursor: "pointer",
      textAlign: "center",
      fontFamily: "GT Flexa Mono, sans-serif",
      [breakpoint.down("lg")]: {
        textAlign: "left",
        marginLeft: 50,
        marginTop: 0,
      },
      [breakpoint.down("sm")]: {
        marginTop: spacing(2),
      },
    },
    bannerLimit: {
      marginTop: spacing(3),
      marginBottom: spacing(3),
    },
    createProjectButtonDisabled: {
      pointer: "default",
      backgroundColor: palette.secondary.backgroundDark,
      borderColor: palette.secondary.backgroundDark,
    },
    buttonsWrap: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      textAlign: "center",
      marginTop: spacing(3),
      marginBottom: spacing(3),
    },
    planEndedTitle: {
      marginTop: spacing(3),
    },
    ml16: {
      marginLeft: spacing(2),
    },
  }),
);
