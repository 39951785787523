import { createSlice } from "@reduxjs/toolkit";
import {
  REQUEST_FAILURE,
  REQUEST_PENDING,
  REQUEST_SUCCESS,
  REQUEST_UNDEFINED,
} from "../../constants/statuses";

export const INITIAL_STATE = {
  user: null,
  getMeStatus: REQUEST_UNDEFINED,
  postLoginStatus: REQUEST_UNDEFINED,
  postLogoutStatus: REQUEST_UNDEFINED,
  postUpdateProfileStatus: REQUEST_UNDEFINED,
  registerStatus: REQUEST_UNDEFINED,
  accessToken: "",
  loginMessage: "",
  registerMessage: "",
  registerFieldMessage: "",
  resetPasswordStatus: REQUEST_UNDEFINED,
  sendResetLinkStatus: REQUEST_UNDEFINED,
  checkResetLinkStatus: REQUEST_UNDEFINED,
  getCookieStatus: REQUEST_UNDEFINED,
  resetPasswordMessage: "",
  sendResetLinkMessage: "",
  checkResetLinkMessage: "",
  subscribeStatus: REQUEST_UNDEFINED,
  subscribeErrorMessage: "",
  getTeamStatus: REQUEST_UNDEFINED,
  removeMemberStatus: REQUEST_UNDEFINED,
  removeInvitationStatus: REQUEST_UNDEFINED,
  fetchInvitationStatus: REQUEST_UNDEFINED,
  handleInvitationStatus: REQUEST_UNDEFINED,
  getAccountStatus: REQUEST_UNDEFINED,
  leaveTeamStatus: REQUEST_UNDEFINED,
  openClusterMapStatus: REQUEST_UNDEFINED,
  invitation: {},
  checkOutUrl: "",
  team: [],
  seats_available: null,
  complete_profile_modal_show: false,
  registerFromResults: false,
};

export const REDUCER_PREFIX = "auth";

const { reducer, actions } = createSlice({
  name: REDUCER_PREFIX,
  initialState: INITIAL_STATE,
  reducers: {
    getMePending: state => {
      state.getMeStatus = REQUEST_PENDING;
    },
    getMeSuccess: state => {
      state.getMeStatus = REQUEST_SUCCESS;
    },
    getMeFailure: state => {
      state.getMeStatus = REQUEST_FAILURE;
    },
    getMeStealthPending: state => {
      state.getMeStealthStatus = REQUEST_PENDING;
    },
    getMeStealthSuccess: state => {
      state.getMeStealthStatus = REQUEST_SUCCESS;
    },
    getMeStealthFailure: state => {
      state.getMeStealthStatus = REQUEST_FAILURE;
    },
    getCookiePending: state => {
      state.getCookieStatus = REQUEST_PENDING;
    },
    getCookieSuccess: state => {
      state.getCookieStatus = REQUEST_SUCCESS;
    },
    getCookieFailure: state => {
      state.getCookieStatus = REQUEST_FAILURE;
    },
    resetPasswordPending: state => {
      state.resetPasswordStatus = REQUEST_PENDING;
    },
    resetPasswordSuccess: (state, { payload }) => {
      state.resetPasswordStatus = REQUEST_SUCCESS;
      state.resetPasswordMessage = payload;
    },
    resetPasswordFailure: state => {
      state.resetPasswordStatus = REQUEST_FAILURE;
      state.resetPasswordMessage =
        "Something went wrong. Please try again later.";
    },
    sendResetLinkPending: state => {
      state.sendResetLinkStatus = REQUEST_PENDING;
    },
    sendResetLinkSuccess: (state, { payload }) => {
      state.sendResetLinkStatus = REQUEST_SUCCESS;
      state.sendResetLinkMessage = payload;
    },
    sendResetLinkFailure: state => {
      state.sendResetLinkStatus = REQUEST_FAILURE;
      state.sendResetLinkMessage =
        "Something went wrong. Please try again later.";
    },
    checkResetLinkPending: state => {
      state.checkResetLinkStatus = REQUEST_PENDING;
    },
    checkResetLinkSuccess: (state, { payload }) => {
      state.checkResetLinkStatus = REQUEST_SUCCESS;
      state.checkResetLinkMessage = payload;
    },
    checkResetLinkFailure: state => {
      state.checkResetLinkStatus = REQUEST_FAILURE;
      state.checkResetLinkMessage =
        "Something went wrong. Please try again later.";
    },
    postLoginPending: state => {
      state.postLogoutStatus = REQUEST_UNDEFINED;
      state.getMeStatus = REQUEST_UNDEFINED;
      state.postLoginStatus = REQUEST_PENDING;
      state.loginMessage = "";
    },
    postLoginSuccess: state => {
      state.postLoginStatus = REQUEST_SUCCESS;
      state.loginMessage = "";
    },
    postLoginUndefined: state => {
      state.postLoginStatus = REQUEST_UNDEFINED;
    },
    completeProfileModalShow: state => {
      state.postLoginStatus = REQUEST_SUCCESS;
      state.loginMessage = "";
    },
    completeProfileModalShowTrue: state => {
      state.complete_profile_modal_show = true;
    },
    completeProfileModalShowFalse: state => {
      state.complete_profile_modal_show = false;
    },
    postLoginFailure: (state, { payload }) => {
      state.loginMessage = payload;
      state.postLoginStatus = REQUEST_FAILURE;
    },
    postLogoutUndefined: state => {
      state.postLogoutStatus = REQUEST_UNDEFINED;
    },
    postLogoutPending: state => {
      state.postLogoutStatus = REQUEST_PENDING;
    },
    postLogoutSuccess: state => {
      state.postLogoutStatus = REQUEST_SUCCESS;
      state.postLoginStatus = REQUEST_UNDEFINED;
      state.user = null;
    },
    postLogoutFailure: state => {
      state.postLogoutStatus = REQUEST_FAILURE;
    },
    registerPending: state => {
      state.registerStatus = REQUEST_PENDING;
      state.registerMessage = "";
    },
    registerUndefined: state => {
      state.registerStatus = REQUEST_UNDEFINED;
    },
    registerSuccess: state => {
      state.registerStatus = REQUEST_SUCCESS;
      state.registerMessage = "";
      state.registerFieldMessage = "";
    },
    registerFromResults: state => {
      state.registerFromResults = true;
    },
    registerFailure: (state, { payload }) => {
      state.registerStatus = REQUEST_FAILURE;
      state.registerMessage = Object.values(payload);
      state.registerFieldMessage = payload;
    },
    postUpdateProfilePending: state => {
      state.postUpdateProfileStatus = REQUEST_PENDING;
    },
    postUpdateProfileSuccess: state => {
      state.postUpdateProfileStatus = REQUEST_SUCCESS;
    },
    postUpdateProfileFailure: state => {
      state.postUpdateProfileStatus = REQUEST_FAILURE;
    },
    setUser: (state, { payload }) => {
      state.user = payload;
      state.postUpdateProfileStatus = REQUEST_UNDEFINED;
    },
    setAccessToken: (state, { payload }) => {
      state.accessToken = payload;
    },
    subscribePending: state => {
      state.subscribeStatus = REQUEST_PENDING;
    },
    subscribeSuccess: state => {
      state.subscribeStatus = REQUEST_SUCCESS;
    },
    subscribeFailure: (state, { payload }) => {
      state.subscribeStatus = REQUEST_FAILURE;
      state.subscribeErrorMessage = payload;
    },
    setCheckOutUrl: (state, { payload }) => {
      const checkOutUrl =
        payload && payload.checkout_url ? payload.checkout_url : "";
      state.checkOutUrl = checkOutUrl;
    },
    getTeamPending: state => {
      state.getTeamStatus = REQUEST_PENDING;
    },
    getTeamSuccess: state => {
      state.getTeamStatus = REQUEST_SUCCESS;
    },
    getTeamFailure: state => {
      state.getTeamStatus = REQUEST_FAILURE;
    },
    setTeam: (state, { payload }) => {
      state.team = payload.members;
      state.seats_available = payload.plan.seats_available;
    },
    removeMemberPending: state => {
      state.removeMemberStatus = REQUEST_PENDING;
    },
    removeMemberUndefined: state => {
      state.removeMemberStatus = REQUEST_UNDEFINED;
    },
    removeMemberSuccess: state => {
      state.removeMemberStatus = REQUEST_SUCCESS;
    },
    removeMemberFailure: state => {
      state.removeMemberStatus = REQUEST_FAILURE;
    },
    removeInvitationPending: state => {
      state.removeInvitationStatus = REQUEST_PENDING;
    },
    removeInvitationUndefined: state => {
      state.removeInvitationStatus = REQUEST_UNDEFINED;
    },
    removeInvitationSuccess: state => {
      state.removeInvitationStatus = REQUEST_SUCCESS;
    },
    removeInvitationFailure: state => {
      state.removeInvitationStatus = REQUEST_FAILURE;
    },
    setInvitation: (state, { payload }) => {
      state.invitation = payload;
    },
    fetchInvitationPending: state => {
      state.fetchInvitationStatus = REQUEST_PENDING;
    },
    fetchInvitationSuccess: state => {
      state.fetchInvitationStatus = REQUEST_SUCCESS;
    },
    fetchInvitationFailure: state => {
      state.fetchInvitationStatus = REQUEST_FAILURE;
    },
    handleInvitationStatusPending: state => {
      state.handleInvitationStatus = REQUEST_PENDING;
    },
    handleInvitationStatusSuccess: state => {
      state.handleInvitationStatus = REQUEST_SUCCESS;
    },
    handleInvitationStatusFailure: state => {
      state.handleInvitationStatus = REQUEST_FAILURE;
    },
    getAccountPending: state => {
      state.getAccountStatus = REQUEST_PENDING;
    },
    getAccountSuccess: state => {
      state.getAccountStatus = REQUEST_SUCCESS;
    },
    getAccountFailure: state => {
      state.getAccountStatus = REQUEST_FAILURE;
    },
    leaveTeamPending: state => {
      state.leaveTeamStatus = REQUEST_PENDING;
    },
    leaveTeamSuccess: state => {
      state.leaveTeamStatus = REQUEST_SUCCESS;
    },
    leaveTeamFailure: state => {
      state.leaveTeamStatus = REQUEST_FAILURE;
    },
    openClusterMapPending: state => {
      state.openClusterMapStatus = REQUEST_PENDING;
    },
    openClusterMapSuccess: state => {
      state.openClusterMapStatus = REQUEST_SUCCESS;
    },
    openClusterMapFailure: state => {
      state.openClusterMapStatus = REQUEST_FAILURE;
    },
  },
});
export { reducer, actions };
