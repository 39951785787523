import axios from "axios";

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api`,
  withCredentials: true,
});

axiosInstance.defaults.headers.common["Content-Type"] = "application/json";

axiosInstance.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
      if (
        window.location.pathname !== "/sign-in" &&
        window.location.pathname !== "/"
      ) {
        window.location.href = "/sign-in";
      }
    }
    return Promise.reject(error);
  },
);

export default axiosInstance;
