const getIndustriesSelector = state => state.industries.pool;
const getIndustriesTotalPagesSelector = state =>
  state.industries.industriesTotalPages;
const getIndustriesTotalNumberSelector = state =>
  state.industries.industriesTotalCount;
const getIndustrySelector = state => state.industries.industry;
const getIndustryCompanySelector = state => state.industries.company;
const getIndustriesStatusSelector = state =>
  state.industries.fetchIndustriesStatus;
const getIndustryStatusSelector = state => state.industries.fetchIndustryStatus;
const getIndustryCompanyStatusSelector = state =>
  state.industries.fetchIndustryCompanyStatus;
const getIndustryCompaniesSelector = state =>
  state.industries.industryCompanies;
const getIndustryCompaniesCountSelector = state =>
  state.industries.industryCompaniesCount;
const getIndustryCompaniesStatusSelector = state =>
  state.industries.getIndustryCompaniesStatus;
const getIndustryCompaniesTotalCount = state => state.industries.totalCount;
const getIndustryCompaniesCountStatusSelector = state =>
  state.industries.getIndustryCompaniesCountStatus;
const getIndustryCompaniesTotalPages = state => state.industries.totalPages;
const getIndustryCompaniesChart = state =>
  state.industries.industryCompaniesChart;
const getIndustryCompaniesChartStatusSelector = state =>
  state.industries.getIndustryCompaniesChartStatus;
const getIndustryCompaniesTeamSizeChart = state =>
  state.industries.industryCompaniesTeamSizeChart;
const getIndustryCompaniesTeamSizeChartStatusSelector = state =>
  state.industries.getIndustryCompaniesTeamSizeChartStatus;
const getIndustryCompaniesTeamSizeChartUnspecifiedAllSelector = state =>
  state.industries.unspecifiedAll;
const getIndustryCompaniesFilterStats = state => state.industries.filterStats;

export {
  getIndustriesSelector,
  getIndustrySelector,
  getIndustriesStatusSelector,
  getIndustryStatusSelector,
  getIndustryCompanySelector,
  getIndustryCompanyStatusSelector,
  getIndustryCompaniesSelector,
  getIndustryCompaniesStatusSelector,
  getIndustryCompaniesTotalCount,
  getIndustryCompaniesTotalPages,
  getIndustryCompaniesCountSelector,
  getIndustryCompaniesChart,
  getIndustryCompaniesChartStatusSelector,
  getIndustryCompaniesTeamSizeChart,
  getIndustryCompaniesTeamSizeChartStatusSelector,
  getIndustryCompaniesTeamSizeChartUnspecifiedAllSelector,
  getIndustriesTotalPagesSelector,
  getIndustriesTotalNumberSelector,
  getIndustryCompaniesFilterStats,
  getIndustryCompaniesCountStatusSelector,
};
