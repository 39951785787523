import React, { useEffect, useState } from "react";
import findIndex from "lodash/findIndex";
import { actions as resultsActions } from "../../store/results/slice";
import { useDispatch } from "react-redux";

export const useTeamSizeGraph = ({
  teamsizes,
  teamsizesArray,
  setTeamsizesArray,
  selectedFilters,
  displayedFilters,
  setDisplayedFilters,
  setSizeSliderMarks,
  theme,
  resetFilter,
  setSizeMarks,
  slug,
  t,
  localStorageSlugPrefix,
  setPage,
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    teamsizes &&
      Object.keys(teamsizes).length > 0 &&
      setTeamsizesArray(Object.keys(teamsizes));
  }, [teamsizes]);

  const [teamMinIndex, setTeamMinIndex] = useState(
    findIndex(
      teamsizesArray,
      teamsizesArray => teamsizesArray === selectedFilters.teamsizeMin,
    ),
  );
  const [teamMaxIndex, setTeamMaxIndex] = useState(
    findIndex(
      teamsizesArray,
      teamsizesArray => teamsizesArray === selectedFilters.teamsizeMax,
    ),
  );

  useEffect(() => {
    const teamsizeMin =
      teamsizesArray && teamsizesArray.length > 0
        ? teamsizesArray.filter(y => y.startsWith(selectedFilters.teamsizeMin))
        : selectedFilters.teamsizeMin;
    const teamsizeMax =
      teamsizesArray && teamsizesArray.length > 0
        ? teamsizesArray.filter(y => y.endsWith(selectedFilters.teamsizeMax))
        : selectedFilters.teamsizeMax;

    const teamMinValue = findIndex(
      teamsizesArray,
      teamsizesArray => String(teamsizesArray) === String(teamsizeMin),
    );
    const teamMaxValue = findIndex(
      teamsizesArray,
      teamsizesArray => String(teamsizesArray) === String(teamsizeMax),
    );
    setTeamMinIndex(teamMinValue >= 0 ? teamMinValue : 0);
    setTeamMaxIndex(
      teamMaxValue >= 0
        ? teamMaxValue
        : teamsizesArray && teamsizesArray.length - 1,
    );
  }, [selectedFilters, teamsizesArray]);
  const [rangeTeamValue, setTeamRangeValue] = useState([
    teamMinIndex,
    teamMaxIndex,
  ]);

  useEffect(() => {
    return () => {
      setTeamMinIndex();
      setTeamMaxIndex();
      setTeamRangeValue();
    };
  }, []);

  const handleResetTeamChart = () => {
    setTeamRangeValue([0, teamsizesArray.length - 1]);
    dispatch(
      resultsActions.setResultsDisplayFilters({
        sid: slug,
        displayedFilters: {
          ...displayedFilters,
          teamsizeMin: "",
          teamsizeMax: "",
        },
      }),
    );
    setDisplayedFilters(prevDisplayedFilters => {
      return {
        ...prevDisplayedFilters,
        teamsizeMin: "",
        teamsizeMax: "",
      };
    });
    resetFilter("teamsizeMin");
    resetFilter("teamsizeMax");
    setPage(1);
  };

  useEffect(() => {
    setTeamRangeValue([teamMinIndex, teamMaxIndex]);
  }, [teamMinIndex, teamMaxIndex]);

  useEffect(() => {
    if (
      teamsizes &&
      Object.values(teamsizes).length > 0 &&
      teamsizesArray.length > 0
    ) {
      setSizeMarks(
        teamsizesArray.map((size, i) => {
          return {
            name: size,
            percentage: teamsizes[size] ? teamsizes[size].percentage : 0,
            count: teamsizes[size] ? teamsizes[size].count : 0,
            allPercentage: teamsizes[size]
              ? teamsizes[size].all_companies_percentage
              : 0,
            allCount: teamsizes[size] ? teamsizes[size].all_companies_count : 0,
          };
        }),
      );
      setSizeSliderMarks(
        teamsizesArray.map((size, i) => {
          return {
            label: <span onClick={() => handleTeamBarClick(i)}>{size}</span>,
            percentage: teamsizes[size] ? teamsizes[size].percentage : 0,
            count: teamsizes[size] ? teamsizes[size].count : 0,
            style: {
              color: "#666666",
              fontFamily: "GT Flexa Mono",
              transformOrigin: "left",
              transform: "translate(-80%, 20%) rotate(-30deg)",
              opacity: 0.25,
              ...theme.typography.bodyXs,
            },
          };
        }),
      );
    }
  }, [teamsizes, teamsizesArray]);

  const handleTeamSliderChange = value => {
    setTeamRangeValue(value);
  };

  const handleTeamSliderAfterChange = value => {
    setPage(1);
    const teamMinValue =
      rangeTeamValue[0] === 0
        ? ""
        : teamsizesArray && teamsizesArray[rangeTeamValue[0]];
    const teamMaxValue =
      teamsizesArray && rangeTeamValue[1] === teamsizesArray.length - 1
        ? ""
        : teamsizesArray[rangeTeamValue[1]];
    dispatch(
      resultsActions.setResultsDisplayFilters({
        sid: slug,
        displayedFilters: {
          ...displayedFilters,
          teamsizeMin: teamMinValue,
          teamsizeMax: teamMaxValue,
        },
      }),
    );
    setDisplayedFilters(prevDisplayedFilters => {
      return {
        ...prevDisplayedFilters,
        teamsizeMin: teamMinValue,
        teamsizeMax: teamMaxValue,
      };
    });
  };

  const handleTeamBarClick = index => {
    setPage(1);
    setTeamRangeValue([index, index]);
    const teamMinValue =
      index === 0 ? "" : teamsizesArray && teamsizesArray[index];
    const teamMaxValue =
      teamsizesArray && index === teamsizesArray.length - 1
        ? ""
        : teamsizesArray[index];
    dispatch(
      resultsActions.setResultsDisplayFilters({
        sid: slug,
        displayedFilters: {
          ...displayedFilters,
          teamsizeMin: teamMinValue,
          teamsizeMax: teamMaxValue,
        },
      }),
    );
    setDisplayedFilters(prevDisplayedFilters => {
      return {
        ...prevDisplayedFilters,
        teamsizeMin: teamMinValue,
        teamsizeMax: teamMaxValue,
      };
    });
  };

  const TeamCustomTooltip = ({ payload }) => {
    if (payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              color: "#333333",
              fontSize: 12,
              lineHeight: "20px",
              margin: "0 0 16px 0",
              fontFamily: "GT Flexa Mono, sans-serif",
            }}>
            <div
              style={{
                color: "#FD750D",
                marginRight: "8px",
                fontSize: 24,
                lineHeight: "32px",
              }}>
              {payload[0].payload.percentage}%
            </div>
            <div>
              <div
                style={{
                  color: "#333333",
                  fontSize: 12,
                  lineHeight: "20px",
                }}>
                Your search
              </div>
              <div
                style={{
                  color: "#666666",
                  fontSize: 10,
                  lineHeight: "12px",
                  textAlign: "left",
                }}>
                ({payload[0].payload.count}{" "}
                {t(
                  `results.${
                    payload[0].payload.count > 1 ? "companies" : "company"
                  }`,
                )}
                )
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              color: "#333333",
              fontSize: 12,
              lineHeight: "20px",
              margin: 0,
              fontFamily: "GT Flexa Mono, sans-serif",
            }}>
            <div
              style={{
                color: "#999999",
                marginRight: "8px",
                fontSize: 24,
                lineHeight: "32px",
              }}>
              {payload[0].payload.allPercentage}%
            </div>
            <div>
              <div
                style={{
                  color: "#333333",
                  fontSize: 12,
                  lineHeight: "20px",
                }}>
                All in Valuer DB
              </div>
              <div
                style={{
                  color: "#666666",
                  fontSize: 10,
                  lineHeight: "12px",
                  textAlign: "left",
                }}>
                ({payload[0].payload.allCount}{" "}
                {t(
                  `results.${
                    payload[0].payload.count > 1 ? "companies" : "company"
                  }`,
                )}
                )
              </div>
            </div>
          </div>
          <p
            style={{
              color: "#007A5E",
              fontSize: 24,
              lineHeight: "32px",
              margin: "8px 0 0 0",
            }}>
            {payload[0].payload.name}
          </p>
        </div>
      );
    }

    return null;
  };
  return {
    handleResetTeamChart,
    TeamCustomTooltip,
    handleTeamSliderAfterChange,
    handleTeamSliderChange,
    handleTeamBarClick,
    rangeTeamValue,
    setTeamRangeValue,
  };
};
