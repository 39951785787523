// Hooks
import React, {useEffect, useState} from "react";
import moment from "moment";
import classnames from "classnames";
import {useStyles} from "./style.js";
// UI components
import Typography from "../../../../components/Typography/Typography.js";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import TextField from "../../../../components/TextField/TextField";
import ContentLoader from "../../../../components/ContentLoader/ContentLoader";
//Assets
import NoAvatar from "../../../../assets/icons/AvatarDefault.png";
import NoSearchesIcon from "../../../../assets/icons/NoSearchesIcon.png";
import Tag from "../../../../components/Tag/Tag.js";
import Button from "../../../../components/Button/Button.js";
import Like from "../../../../assets/icons/Like.svg";
import Liked from "../../../../assets/icons/Liked.svg";
// Redux
import {fetchCommentsRequest} from "../../../../store/companyProfile/requests";
// Constants
import {REQUEST_PENDING, REQUEST_SUCCESS,} from "../../../../constants/statuses";
import {useCommentsHook} from "./hooks/useCommentsHook.js";
import {Link, useLocation} from "react-router-dom";

const Comments = ({ company, onCommentsCount }) => {
  const classes = useStyles();
  const {
    dispatch,
    comments,
    newCommentText,
    replyCommentText,
    addCommentStatus,
    updateCommentStatus,
    deleteCommentStatus,
    voteCommentStatus,
    commentEdit,
    commentReply,
    handleNewCommentText,
    handleNewCommentAdd,
    handleNewCommentReply,
    editComment,
    setEditCommentText,
    replyComment,
    setReplyCommentText,
    deleteComment,
    cancelEditComment,
    cancelReplyComment,
    saveComment,
    handleCommentTextChange,
    handleReplyTextChange,
    handleLikeComment,
    setCommentEdit,
    setCommentReply,
    setNewCommentText,
    user,
    t,
    editCommentText,
    setComments,
    commentsSelector,
    getCommentsSelector,
  } = useCommentsHook({ company });

  const queryParamString = useLocation().search.substring(1);
  const searchParam = new URLSearchParams(queryParamString);

  // const [requestModalTitle, setRequestModalTitle] = useState("");
  // const [requestModalDesc, setRequestModalDesc] = useState("");
  // const [requestModalIcon, setRequestModalIcon] = useState(<></>);
  // const [showReadMoreModal, setShowReadMoreModal] = useState(false);
  // const [showRequestModal, setShowRequestModal] = useState(false);
  const [loading, setLoading] = useState(true);
  // const openRequestModal = (title, desc, icon) => {
  //     setRequestModalTitle(title);
  //     setRequestModalDesc(desc);
  //     setRequestModalIcon(icon);
  //     setShowRequestModal(true);
  // }

  useEffect(() => {
    let commentsTextClone = {};
    let commentReplyClone = {};
    if (!Array.isArray(comments)) {
      Object.assign({}, comments);
      Object.assign({}, comments);
      commentsTextClone = Object.values(commentsTextClone).map(comment => {
        Object.values(comment.replies).map(reply => {
          return {
            ...reply,
            textChange: "",
          };
        });
        return {
          ...comment,
          textChange: "",
        };
      });
      commentReplyClone = Object.values(commentReplyClone).map(comment => {
        Object.values(comment.replies).map(reply => {
          return {
            ...reply,
            reply: false,
          };
        });
        return {
          ...comment,
          reply: false,
        };
      });
      setCommentEdit(
        Object.values(comments).map(comment => ({
          [comment.id]: false,
        })),
      );
      setEditCommentText(commentsTextClone);
      setCommentReply(commentReplyClone);
      setReplyCommentText(commentsTextClone);
    }
  }, [comments]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
      const companyId = searchParam.get("cid");
      dispatch(fetchCommentsRequest({ cid: companyId }));
    }, 500);
  }, [dispatch]);

  useEffect(() => {
    if (
      addCommentStatus === REQUEST_SUCCESS ||
      updateCommentStatus === REQUEST_SUCCESS ||
      deleteCommentStatus === REQUEST_SUCCESS ||
      voteCommentStatus === REQUEST_SUCCESS
    ) {
      setLoading(false);
      dispatch(fetchCommentsRequest({ cId: company.id }));
      setNewCommentText("");
    }
  }, [
    addCommentStatus,
    updateCommentStatus,
    deleteCommentStatus,
    voteCommentStatus,
  ]);

  useEffect(() => {
    setComments(commentsSelector);
    let allReplies = [];
    commentsSelector &&
      Object.values(commentsSelector).forEach(comm => {
        comm.replies &&
          Object.values(comm.replies).forEach(f => allReplies.push(f));
      });
    let totalNumComments =
      allReplies.length + Object.values(commentsSelector).length;
    addCommentStatus === REQUEST_SUCCESS &&
      commentsSelector &&
      onCommentsCount(totalNumComments);
  }, [commentsSelector, addCommentStatus]);

  return (
    <div className={classes.root} data-intercom-target="Comments section">
      {user ? (
        <Typography className={classes.headTitle} variant="body">
          {t("companyComments.allComments")}
        </Typography>
      ) : (
        <Link to="/sign-in">Sign in to read and leave comments</Link>
      )}
      {loading ||
      getCommentsSelector === REQUEST_PENDING ||
      addCommentStatus === REQUEST_PENDING ? (
        <ContentLoader />
      ) : (
        <>
          {user && (
            <div className={classes.header}>
              <div className={classes.newCommAvatarBox}>
                {user && user.avatar ? (
                  <img
                    className={classes.avatar}
                    src={user.avatar}
                    alt="avatar"
                  />
                ) : (
                  <img className={classes.avatar} src={NoAvatar} alt="avatar" />
                )}
              </div>
              <TextareaAutosize
                className={classes.textarea}
                minRows={1}
                rowsMax={3}
                placeholder={t("companyComments.addYourComments")}
                value={newCommentText}
                onChange={handleNewCommentText}
                onKeyPress={handleNewCommentAdd}
              />
            </div>
          )}
          <div className={classes.sortingWrap}>
            {/* <div>Sort multiselect here</div> */}
          </div>
          <div>
            {getCommentsSelector === REQUEST_SUCCESS &&
            comments &&
            Object.values(comments).length === 0 ? (
              <div className={classes.flexColumnCenter}>
                <img src={NoSearchesIcon} className={classes.noComments} />
                <Typography
                  variant="subtitle1"
                  className={classes.noCommentsTitle}>
                  {t("companyComments.noComments")}
                </Typography>
                <Typography
                  component="div"
                  variant="body"
                  className={classes.noCommentsSubtitle}>
                  {t("companyComments.commentsSubtitle")}
                </Typography>
              </div>
            ) : (
              comments &&
              Object.values(comments).map(comment => {
                return (
                  <div
                    key={`${comment.id}-comment`}
                    className={classes.commentsWrap}>
                    <div className={classes.flexCenter}>
                      {comment && comment.user && comment.user.avatar ? (
                        <div className={classes.newCommAvatarBox}>
                          <img
                            className={classes.avatar}
                            src={comment && comment.user && comment.user.avatar}
                            alt="avatar"
                          />
                        </div>
                      ) : (
                        <div className={classes.newCommAvatarBox}>
                          <img
                            className={classes.avatar}
                            src={NoAvatar}
                            alt="avatar"
                          />
                        </div>
                      )}
                      <div className={classes.nameTimeWrap}>
                        <Typography component="div" variant="bodySmall">
                          {comment && comment.user && comment.user.name}
                        </Typography>
                        <Typography
                          component="div"
                          className={classes.commentTime}
                          variant="bodySmall">
                          {moment(comment.commented_at).fromNow()}
                        </Typography>
                      </div>
                    </div>
                    {commentEdit[comment.id] && commentEdit[comment.id].edit ? (
                      <TextField
                        inputClass={classes.adjustTextFieldWidth}
                        value={editCommentText[comment.id].text}
                        className={classes.commentTextInput}
                        onChange={e => handleCommentTextChange(e, comment.id)}
                      />
                    ) : (
                      <Typography
                        className={classes.commentText}
                        variant="bodySmall">
                        {comment.text}
                        {comment.edited && (
                          <Tag className={classes.editedTag}>(edited)</Tag>
                        )}
                      </Typography>
                    )}
                    <div className={classes.actionsWrap}>
                      {user &&
                      comment &&
                      comment.user &&
                      comment.user.name === user.name ? (
                        <div className={classes.editDeleteActions}>
                          {commentEdit[comment.id] &&
                          commentEdit[comment.id].edit ? (
                            <>
                              <Button
                                className={classes.action}
                                variant="textSecondary"
                                onClick={() => cancelEditComment(comment.id)}>
                                {t("companyComments.cancel")}
                              </Button>
                              <Button
                                className={classes.action}
                                variant="textSecondary"
                                onClick={() => saveComment(comment.id, null)}>
                                {t("companyComments.save")}
                              </Button>
                            </>
                          ) : (
                            <>
                              <Button
                                className={classes.action}
                                variant="textSecondary"
                                onClick={() =>
                                  editComment(comment.id, null, comment.text)
                                }>
                                {t("companyComments.edit")}
                              </Button>
                              <Button
                                className={classes.action}
                                variant="textSecondary"
                                onClick={() => deleteComment(comment.id)}>
                                {t("companyComments.delete")}
                              </Button>
                              <div className={classes.votes}>
                                <img
                                  src={Like}
                                  alt={t("results.like")}
                                  className={classnames([
                                    classes.voteIcon,
                                    classes.disabledClick,
                                  ])}
                                />
                                <Typography
                                  className={classes.voteCounter}
                                  variant="body2">
                                  {comment.likes_count || ""}
                                </Typography>
                                {/* <img
                                                        src={Like}
                                                        alt={t('results.disLike')}
                                                        className={classnames([classes.voteIcon, classes.dislikeImg, classes.disabledClick])}
                                                    />
                                                    <Typography className={classes.voteCounter} variant="body2">{comment.dislikes_count || ""}</Typography> */}
                              </div>
                            </>
                          )}
                        </div>
                      ) : (
                        <div className={classes.flexReply}>
                          <div className={classes.votes}>
                            <img
                              onClick={() => handleLikeComment(comment)}
                              src={comment.user_vote === 1 ? Liked : Like}
                              alt={t("results.valuerScore")}
                              className={classnames([
                                classes.voteIcon,
                                !user && classes.disabledClick,
                              ])}
                            />
                            <Typography
                              className={classnames([
                                classes.voteCounter,
                                comment.user_vote === 1
                                  ? classes.withVote
                                  : null,
                              ])}
                              variant="body2">
                              {comment.likes_count || ""}
                            </Typography>
                            {/* <img
                                                    onClick={() => handleDislikeComment(comment)}
                                                    src={comment.user_vote === 0 ? Liked : Like}
                                                    alt={t('results.valuerScore')}
                                                    className={classnames([classes.voteIcon, classes.dislikeImg, !user && classes.disabledClick])}
                                                />
                                                <Typography className={classnames([classes.voteCounter, comment.user_vote === 0 ? classes.withVote : null])} variant="body2">{comment.dislikes_count || ""}</Typography> */}
                          </div>
                          <div display="flex" className={classes.replyAction}>
                            {commentReply &&
                            commentReply[comment.id] &&
                            commentReply[comment.id].reply ? (
                              <>
                                <TextField
                                  value={
                                    replyCommentText[comment.id].textChange
                                  }
                                  onChange={e =>
                                    handleReplyTextChange(e, comment.id, null)
                                  }
                                />
                                <div>
                                  <Button
                                    className={classes.action}
                                    variant="textSecondary"
                                    onClick={() =>
                                      cancelReplyComment(comment.id)
                                    }>
                                    {t("companyComments.cancel")}
                                  </Button>
                                  <Button
                                    className={classes.action}
                                    variant="textSecondary"
                                    onClick={() =>
                                      handleNewCommentReply(
                                        comment.id,
                                        replyCommentText[comment.id].textChange,
                                      )
                                    }>
                                    {t("companyComments.save")}
                                  </Button>
                                </div>
                              </>
                            ) : (
                              <div>
                                {user && (
                                  <Button
                                    className={classes.action}
                                    variant="textSecondary"
                                    onClick={() =>
                                      replyComment(comment.id, null)
                                    }>
                                    {t("companyComments.reply")}
                                  </Button>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                    {comment &&
                      Object.values(comment.replies).length > 0 &&
                      Object.values(comment.replies).map((reply, i) => {
                        return (
                          <div
                            key={`${reply.id}-comment`}
                            className={classes.repliesWrap}>
                            <div className={classes.flex}>
                              <div className={classes.newCommAvatarBox}>
                                {reply && reply.user && reply.user.avatar ? (
                                  <img
                                    className={classes.avatar}
                                    src={reply.user.avatar}
                                    alt="avatar"
                                  />
                                ) : (
                                  <img
                                    className={classes.avatar}
                                    src={NoAvatar}
                                    alt="avatar"
                                  />
                                )}
                              </div>
                              <div className={classes.nameTimeWrap}>
                                <Typography
                                  component="div"
                                  className={classes.username}
                                  variant="body2">
                                  {reply && reply.user && reply.user.name}
                                </Typography>
                                <Typography
                                  component="div"
                                  className={classes.commentTime}
                                  variant="body2">
                                  {moment(reply.commented_at).fromNow()}
                                </Typography>
                              </div>
                            </div>
                            {commentEdit[comment.id] &&
                            commentEdit[comment.id].replies[reply.id].edit ? (
                              <TextField
                                value={
                                  editCommentText[comment.id] &&
                                  editCommentText[comment.id].replies[reply.id]
                                    .text
                                }
                                className={classes.commentText}
                                onChange={e =>
                                  handleCommentTextChange(
                                    e,
                                    comment.id,
                                    reply.id,
                                  )
                                }
                              />
                            ) : (
                              <Typography
                                className={classnames([
                                  classes.commentText,
                                  classes.replyText,
                                ])}
                                variant="body2">
                                {reply.text}
                                {reply.edited && (
                                  <Tag className={classes.editedTag}>
                                    (edited)
                                  </Tag>
                                )}
                              </Typography>
                            )}
                            <div className={classes.actionsWrap}>
                              {user &&
                              reply.user &&
                              reply.user.name === user.name ? (
                                <div className={classes.editDeleteActions}>
                                  {commentEdit[comment.id] &&
                                  commentEdit[comment.id].replies &&
                                  commentEdit[comment.id].replies[reply.id] &&
                                  commentEdit[comment.id].replies[reply.id]
                                    .edit ? (
                                    <>
                                      <Button
                                        className={classes.action}
                                        variant="textSecondary"
                                        onClick={() =>
                                          cancelEditComment(comment.id)
                                        }>
                                        {t("companyComments.cancel")}
                                      </Button>
                                      <Button
                                        className={classes.action}
                                        variant="textSecondary"
                                        onClick={() =>
                                          saveComment(comment.id, reply.id)
                                        }>
                                        {t("companyComments.save")}
                                      </Button>
                                    </>
                                  ) : (
                                    <>
                                      <div className={classes.votes}>
                                        <img
                                          src={Like}
                                          alt={t("results.like")}
                                          className={classnames([
                                            classes.voteIcon,
                                            classes.disabledClick,
                                          ])}
                                        />
                                        <Typography
                                          className={classes.voteCounter}
                                          variant="body2">
                                          {reply.likes_count || ""}
                                        </Typography>
                                        {/* <img
                                                                src={Like}
                                                                alt={t('results.disLike')}
                                                                className={classnames([classes.voteIcon, classes.dislikeImg, classes.disabledClick])}
                                                            />
                                                            <Typography className={classes.voteCounter} variant="body2">{reply.dislikes_count || ""}</Typography> */}
                                      </div>
                                      <Button
                                        className={classes.action}
                                        variant="textSecondary"
                                        onClick={() =>
                                          editComment(
                                            comment.id,
                                            reply.id,
                                            reply.text,
                                          )
                                        }>
                                        {t("companyComments.edit")}
                                      </Button>
                                      <Button
                                        className={classes.action}
                                        variant="textSecondary"
                                        onClick={() => deleteComment(reply.id)}>
                                        {t("companyComments.delete")}
                                      </Button>
                                    </>
                                  )}
                                </div>
                              ) : (
                                <div className={classes.replyAction}>
                                  {commentReply &&
                                  commentReply[comment.id] &&
                                  commentReply[comment.id].replies &&
                                  commentReply[comment.id].replies[reply.id] &&
                                  commentReply[comment.id].replies[reply.id]
                                    .reply ? (
                                    <>
                                      <TextField
                                        value={
                                          commentReply &&
                                          commentReply[comment.id] &&
                                          commentReply[comment.id].replies &&
                                          commentReply[comment.id].replies[
                                            reply.id
                                          ] &&
                                          replyCommentText[comment.id].replies[
                                            reply.id
                                          ].textChange
                                        }
                                        onChange={e =>
                                          handleReplyTextChange(
                                            e,
                                            comment.id,
                                            reply.id,
                                          )
                                        }
                                      />
                                      <div>
                                        <Button
                                          className={classes.action}
                                          variant="textSecondary"
                                          onClick={() =>
                                            cancelReplyComment(comment.id)
                                          }>
                                          {t("companyComments.cancel")}
                                        </Button>
                                        <Button
                                          className={classes.action}
                                          variant="textSecondary"
                                          onClick={() =>
                                            handleNewCommentReply(
                                              comment.id,
                                              replyCommentText[comment.id]
                                                .replies &&
                                                replyCommentText[comment.id]
                                                  .replies[reply.id].textChange,
                                            )
                                          }>
                                          {t("companyComments.reply")}
                                        </Button>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div className={classes.votes}>
                                        <img
                                          onClick={() =>
                                            handleLikeComment(reply)
                                          }
                                          src={
                                            reply.user_vote === 1 ? Liked : Like
                                          }
                                          alt={t("results.like")}
                                          className={classnames([
                                            classes.voteIcon,
                                            !user && classes.disabledClick,
                                          ])}
                                        />
                                        <Typography
                                          className={classnames([
                                            classes.voteCounter,
                                            reply.user_vote === 1
                                              ? classes.withVote
                                              : null,
                                          ])}
                                          variant="body2">
                                          {reply.likes_count || ""}
                                        </Typography>
                                        {/* <img
                                                                    onClick={() => handleDislikeComment(reply)}
                                                                    src={reply.user_vote === 0 ? Liked : Like}
                                                                    alt={t('results.disLike')}
                                                                    className={classnames([classes.voteIcon, classes.dislikeImg, !user && classes.disabledClick])}
                                                                />
                                                                <Typography className={classnames([classes.voteCounter, comment.user_vote === 0 ? classes.withVote : null])} variant="body2">{reply.dislikes_count || ""}</Typography> */}
                                      </div>
                                      <div className={classes.votes}>
                                        {user && (
                                          <Button
                                            className={classes.action}
                                            variant="textPrimary"
                                            onClick={() =>
                                              replyComment(comment.id, reply.id)
                                            }>
                                            {t("companyComments.reply")}
                                          </Button>
                                        )}
                                      </div>
                                    </>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                );
              })
            )}
          </div>
          {/* <RequestModal enrichmentRequest={company && company.enrichment_request} showEnrichRequestModal={showEnrichRequestModal} dialogOpened={showRequestModal} onChange={closeRequestModal} icon={requestModalIcon} title={requestModalTitle} description={requestModalDesc} user={user} /> */}
        </>
      )}
    </div>
  );
};
export default Comments;
