import { createAction } from "@reduxjs/toolkit";
import { REDUCER_PREFIX } from "./slice";

export const fetchProjectsRequest = createAction(
  `${REDUCER_PREFIX}/fetchProjectsRequest`,
);

export const fetchProjectRequest = createAction(
  `${REDUCER_PREFIX}/fetchProjectRequest`,
);

export const createProjectRequest = createAction(
  `${REDUCER_PREFIX}/createProjectRequest`,
);

export const updateProjectRequest = createAction(
  `${REDUCER_PREFIX}/updateProjectRequest`,
);

export const toggleProjectRequest = createAction(
  `${REDUCER_PREFIX}/toggleProjectRequest`,
);

export const addItemToProjectRequest = createAction(
  `${REDUCER_PREFIX}/addItemToProjectRequest`,
);

export const deleteCompanyRequest = createAction(
  `${REDUCER_PREFIX}/deleteCompanyRequest`,
);

export const enrichCompanyRequest = createAction(
  `${REDUCER_PREFIX}/enrichCompanyRequest`,
);
export const checkProjectNameRequest = createAction(
  `${REDUCER_PREFIX}/checkProjectNameRequest`,
);
export const checkProjectsRequest = createAction(
  `${REDUCER_PREFIX}/checkProjectsRequest`,
);
export const deleteProjectRequest = createAction(
  `${REDUCER_PREFIX}/deleteProjectRequest`,
);
