import { createStyles, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(({ palette, spacing }) =>
  createStyles({
    root: {},
    imgArrowRight: {
      marginLeft: spacing(2),
      marginRight: spacing(2),
      marginBottom: 6,
    },
    request100Button: {
      background: "transparent",
      marginRight: spacing(2),
    },
    ml8: {
      marginLeft: spacing(1),
    },
    mr16: {
      marginRight: spacing(2),
    },
    header: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    startEndornment: {
      marginRight: `${spacing(1)}px`,
    },
    startEndornmentSearch: {
      position: "absolute",
      right: `${spacing(1)}px`,
    },
    headerInput: {
      width: "100%",
      maxWidth: 200,
      marginTop: spacing(2),
      "& input": {
        width: 167,
      },
    },
    filterWrap: {
      marginLeft: `${spacing(2)}px`,
      display: "flex",
      alignItems: "center",
    },
    sortedBy: {
      marginRight: spacing(1),
    },
    multiselectAdjust: {
      border: "none!important",
      background: "transparent",
      borderRadius: `${spacing(3)}px!important`,
      "&:hover": {
        background: palette.primary.backgroundLight,
        border: "none",
      },
    },
    arrowIcon: {
      marginRight: `${spacing(1)}px`,
      cursor: "pointer",
      transform: "rotate(0deg) scale(1.4)",
    },
    rotateIcon: {
      transform: "rotate(180deg) scale(1.4)",
    },
    breadCrumb: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    imgTitleWrap: {
      display: "flex",
      alignItems: "center",
    },
    img: {
      marginRight: spacing(3),
    },
    moreIcon: {
      cursor: "pointer",
    },
    moreIconWrapper: {
      position: "relative",
      width: 24,
    },
    moreIconMenu: {
      position: "absolute",
      top: 20,
      right: 0,
      background: palette.common.white,
      border: `1px solid ${palette.text.backgroundDark}`,
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
      borderRadius: spacing(2),
      zIndex: 2,
      minWidth: 245,
      fontFamily: "GT Flexa Mono, sans-serif",
    },
    moreIconMenuItem: {
      padding: `${spacing(2)}px ${spacing(3)}px`,
      "&:hover": {
        cursor: "pointer",
        color: palette.error.main,
      },
    },
  }),
);
