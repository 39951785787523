import { createStyles, makeStyles } from "@material-ui/core/styles";

export default makeStyles(({ palette, spacing, typography, breakpoint }) =>
  createStyles({
    button: {
      ...typography.bodyButton,
      textTransform: "none",
      backgroundColor: palette.primary.main,
      border: `2px solid ${palette.primary.main}`,
      color: palette.common.white,
      padding: `11px ${spacing(3)}px`,
      borderRadius: "5000px",
      verticalAlign: "top",
      fontFamily: "GT Flexa Mono, sans-serif",
      cursor: "pointer",
      maxHeight: 40,
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      "&:active": {
        backgroundColor: palette.primary.main,
        border: `2px solid ${palette.primary.light}`,
      },
      "&:disabled": {
        color: palette.text.lighter,
        backgroundColor: palette.text.backgroundDark,
        border: `2px solid ${palette.text.backgroundDark}`,
        cursor: "default",
      },
      "& > svg": {
        stroke: palette.text.lighter,
        marginRight: spacing(2),
      },
    },
    notClickable: {
      cursor: "default",
      "&:hover": {
        boxShadow:
          "0px 20px 25px -5px rgb(0 0 0 / 10%), 0px 10px 10px -5px rgb(0 0 0 / 4%)",
      },
      "&:active": {
        boxShadow:
          "0px 20px 25px -5px rgb(0 0 0 / 10%), 0px 10px 10px -5px rgb(0 0 0 / 4%)",
      },
    },
    primary: {
      backgroundColor: palette.primary.main,
      border: `2px solid ${palette.primary.main}`,
      color: palette.common.white,
      padding: `11px ${spacing(3)}px`,
      "&:active": {
        backgroundColor: palette.primary.main,
        border: `2px solid ${palette.primary.light}`,
      },
      "&:disabled": {
        color: palette.common.white,
        backgroundColor: palette.text.backgroundDark,
        border: `2px solid ${palette.text.backgroundDark}`,
        cursor: "default",
      },
    },
    primaryDarkContained: {
      backgroundColor: palette.primary.dark,
      border: `2px solid ${palette.primary.dark}`,
      color: palette.common.white,
      padding: `11px ${spacing(3)}px`,
      "&:active": {
        backgroundColor: palette.primary.dark,
        border: `2px solid ${palette.primary.light}`,
      },
      "&:disabled": {
        color: palette.common.white,
        backgroundColor: palette.text.backgroundDark,
        border: `2px solid ${palette.text.backgroundDark}`,
        cursor: "default",
      },
    },
    success: {
      backgroundColor: palette.success.dark,
      border: `2px solid ${palette.success.dark}`,
      color: palette.common.white,
      padding: `11px ${spacing(3)}px`,
      "&:active": {
        backgroundColor: palette.success.dark,
        border: `2px solid ${palette.success.dark}`,
      },
      "&:disabled": {
        color: palette.common.white,
        backgroundColor: palette.text.backgroundDark,
        border: `2px solid ${palette.text.backgroundDark}`,
        cursor: "default",
      },
    },
    tertiarySuccess: {
      backgroundColor: "transparent",
      border: `1px solid ${palette.success.dark}`,
      color: palette.success.dark,
      padding: `10px ${spacing(3)}px`,
      lineHeight: "18px",
      "&:hover": {
        border: `1px solid ${palette.success.dark}`,
        backgroundColor: palette.success.backgroundLight,
        boxShadow: "none",
      },
      "&:active": {
        backgroundColor: "transparent",
        border: `1px solid ${palette.success.main}`,
      },
      "&:disabled": {
        color: palette.success.main,
        backgroundColor: "transparent",
        border: `1px solid ${palette.success.main}`,
        cursor: "default",
      },
      "& svg": {
        stroke: "#0B295F",
      },
    },
    tertiarySuccessReversed: {
      backgroundColor: palette.success.medium,
      color: palette.common.black,
      padding: `10px ${spacing(3)}px`,
      lineHeight: "18px",
      border: "none",
      "&:hover": {
        backgroundColor: palette.success.light,
        boxShadow: "none",
        transform: "translate(-1%, 0)",
        transition: "all 150ms ease-out",
      },
    },
    secondary: {
      backgroundColor: palette.secondary.dark,
      border: `2px solid ${palette.secondary.dark}`,
      color: palette.common.white,
      padding: `${spacing(1)}px ${spacing(3)}px`,
      lineHeight: "18px",
      "&:active": {
        backgroundColor: palette.secondary.dark,
        border: `2px solid ${palette.secondary.light}`,
      },
      "&:disabled": {
        color: palette.common.white,
        backgroundColor: palette.secondary.backgroundDark,
        border: `2px solid ${palette.secondary.backgroundDark}`,
        cursor: "default",
      },
    },
    secondaryLight: {
      backgroundColor: palette.secondary.light,
      border: `2px solid ${palette.secondary.light}`,
      color: palette.common.white,
      padding: `${spacing(1)}px ${spacing(3)}px`,
      lineHeight: "18px",
      "&:active": {
        backgroundColor: palette.secondary.main,
        border: `2px solid ${palette.secondary.light}`,
      },
      "&:disabled": {
        color: palette.common.white,
        backgroundColor: palette.secondary.main,
        border: `2px solid ${palette.secondary.main}`,
        cursor: "default",
      },
    },
    tertiary: {
      backgroundColor: palette.common.white,
      border: `1px solid ${palette.secondary.dark}`,
      color: palette.secondary.dark,
      padding: `${spacing(1)}px ${spacing(3)}px`,
      lineHeight: "18px",
      "&:hover": {
        border: `1px solid ${palette.secondary.dark}`,
        backgroundColor: palette.secondary.backgroundLight,
        boxShadow: "none",
      },
      "&:active": {
        backgroundColor: palette.common.white,
        border: `1px solid ${palette.secondary.main}`,
      },
      "&:disabled": {
        color: palette.secondary.main,
        backgroundColor: palette.common.white,
        border: `1px solid ${palette.secondary.main}`,
        cursor: "default",
      },
      "& svg": {
        stroke: "#0B295F",
      },
    },
    tertiaryBlack: {
      backgroundColor: palette.common.white,
      border: `1px solid ${palette.common.black}`,
      color: palette.common.black,
      padding: `${spacing(1)}px ${spacing(3)}px`,
      lineHeight: "18px",
      "&:hover": {
        border: `1px solid ${palette.common.black}`,
        backgroundColor: palette.grey.light,
        boxShadow: "none",
      },
      "&:active": {
        backgroundColor: palette.common.white,
        border: `1px solid ${palette.secondary.main}`,
      },
      "&:disabled": {
        color: palette.secondary.main,
        backgroundColor: palette.common.white,
        border: `1px solid ${palette.secondary.main}`,
        cursor: "default",
      },
      "& svg": {
        stroke: palette.secondary.dark,
      },
    },
    quaternary: {
      backgroundColor: "transparent",
      border: `1px solid ${palette.primary.dark}`,
      color: palette.primary.dark,
      padding: `12px 34px`,
      lineHeight: "18px",
      maxHeight: 40,
      "&:hover": {
        border: `1px solid ${palette.primary.dark}`,
        backgroundColor: palette.primary.dark,
        color: palette.common.white,
        boxShadow: "none",
      },
      "&:active": {
        backgroundColor: palette.common.white,
        color: palette.primary.dark,
        border: `1px solid ${palette.primary.main}`,
      },
      "&:disabled": {
        color: palette.primary.dark,
        backgroundColor: palette.common.white,
        border: `1px solid ${palette.primary.dark}`,
        cursor: "default",
      },
      "& svg": {
        stroke: "#0B295F",
      },
    },
    pill: {
      backgroundColor: palette.common.white,
      color: palette.text.light,
      border: `1px solid ${palette.text.light}`,
      boxShadow: "0px 10px 10px -5px rgba(0, 0, 0, 0.04)",
      borderRadius: 8,
      padding: `${spacing(1)}px 14px`,
      lineHeight: "18px",
      position: "relative",
      maxHeight: 37,
      zIndex: 1,
      "&:hover": {
        border: `1px solid ${palette.primary.dark}`,
        color: palette.primary.dark,
        boxShadow: "0px 20px 25px -5px rgba(0, 0, 0, 0.1)",
        backgroundColor: palette.common.white,
      },
      "&$notClickable": {
        cursor: "default",
        "&:hover": {
          boxShadow: "0px 10px 10px -5px rgba(0, 0, 0, 0.04)",
        },
        "&:active": {
          boxShadow: "0px 20px 25px -5px rgba(0, 0, 0, 0.1)",
          backgroundColor: palette.common.white,
        },
      },
      "&:active": {
        border: `1px solid ${palette.primary.dark}`,
        color: palette.primary.dark,
        boxShadow: "0px 20px 25px -5px rgba(0, 0, 0, 0.1)",
        backgroundColor: palette.common.white,
      },
      "&:disabled": {
        cursor: "default",
        backgroundColor: palette.common.white,
        color: palette.text.light,
        border: `1px solid ${palette.text.light}`,
      },
    },
    pillAdd: {
      backgroundColor: palette.text.backgroundMain,
      color: palette.secondary.dark,
      border: `1px solid ${palette.secondary.dark}`,
      boxShadow: "0px 10px 10px -5px rgba(0, 0, 0, 0.04)",
      borderRadius: 8,
      padding: `${spacing(1)}px 14px`,
      lineHeight: "18px",
      position: "relative",
      maxHeight: 37,
      zIndex: 1,
      "&$notClickable": {
        cursor: "default",
        "&:hover": {
          boxShadow: "0px 10px 10px -5px rgba(0, 0, 0, 0.04)",
        },
        "&:active": {
          boxShadow: "0px 10px 10px -5px rgba(0, 0, 0, 0.04)",
          backgroundColor: palette.text.backgroundMain,
        },
      },
      "&:active": {
        border: `1px solid ${palette.primary.dark}`,
        color: palette.primary.dark,
        boxShadow: "0px 20px 25px -5px rgba(0, 0, 0, 0.1)",
        backgroundColor: palette.text.backgroundMain,
      },
      "&:disabled": {
        cursor: "default",
        backgroundColor: palette.text.backgroundMain,
        color: palette.secondary.dark,
        border: `1px solid ${palette.secondary.dark}`,
      },
    },
    active: {
      border: `1px solid ${palette.primary.dark}`,
      color: palette.primary.dark,
      boxShadow: "0px 20px 25px -5px rgba(0, 0, 0, 0.1)",
      "&:active": {
        boxShadow: "0px 10px 10px -5px rgba(0, 0, 0, 0.04)",
      },
    },
    sidebar: {
      backgroundColor: "transparent",
      color: palette.text.main,
      border: `none`,
      padding: `23px 32px`,
      borderLeft: `2px solid transparent`,
      lineHeight: "18px",
      height: 70,
      maxHeight: 70,
      borderRadius: 0,
      margin: 0,
      zIndex: 1,
      "&:hover": {
        color: palette.primary.dark,
        backgroundColor: "transparent",
        border: `none`,
        borderLeft: `2px solid transparent`,
        "& svg": {
          stroke: palette.primary.dark,
        },
      },
      "&.active": {
        color: palette.primary.dark,
        boxShadow:
          "0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04);",
        border: `1px solid ${palette.secondary.main}`,
        padding: `${spacing(1)}px ${spacing(5)}px ${spacing(1)}px ${spacing(
          2,
        )}`,
        "& svg": {
          stroke: palette.primary.dark,
        },
      },
      "&:disabled": {
        backgroundColor: "transparent",
        // color: palette.primary.dark,
        border: "none",
        cursor: "default",
        "&:hover": {
          color: palette.text.lighter,
        },
      },
    },
    activeSidebar: {
      borderLeft: `2px solid ${palette.primary.dark} !important`,
      color: `${palette.primary.dark} !important`,
      background: palette.text.backgroundLight,
      "&:hover": {
        borderLeft: `2px solid ${palette.primary.dark}`,
      },
      "& svg": {
        stroke: palette.primary.dark,
        strokeWidth: 0.5,
      },
      [breakpoint.down("md")]: {
        background: palette.common.white,
        borderLeft: "none !important",
      },
    },
    textPrimary: {
      backgroundColor: "transparent",
      color: palette.primary.dark,
      border: `none`,
      padding: `${spacing(1)}px ${spacing(3)}px`,
      lineHeight: "18px",
      zIndex: 1,
      "&:hover": {
        color: palette.primary.dark,
        backgroundColor: "transparent",
        border: `none`,
      },
      "&.active": {
        color: palette.primary.dark,
        boxShadow:
          "0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04);",
        border: `1px solid ${palette.secondary.main}`,
        padding: `${spacing(1)}px ${spacing(5)}px ${spacing(1)}px ${spacing(
          2,
        )}`,
      },
      "&:disabled": {
        backgroundColor: "transparent",
        color: palette.primary.dark,
        border: "none",
        cursor: "default",
      },
    },
    textPrimaryThin: {
      backgroundColor: "transparent",
      color: palette.primary.dark,
      border: `none`,
      padding: 0,
      lineHeight: "18px",
      zIndex: 1,
      "&:hover": {
        color: palette.primary.dark,
        backgroundColor: "transparent",
        border: `none`,
        textDecoration: "underline",
      },
      "&.active": {
        color: palette.primary.dark,
        boxShadow:
          "0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04);",
        border: `1px solid ${palette.secondary.main}`,
        padding: `${spacing(1)}px ${spacing(5)}px ${spacing(1)}px ${spacing(
          2,
        )}`,
      },
      "&:disabled": {
        backgroundColor: "transparent",
        color: palette.primary.dark,
        border: "none",
        cursor: "default",
      },
    },
    textSecondary: {
      backgroundColor: "transparent",
      color: palette.secondary.dark,
      border: `none`,
      lineHeight: "18px",
      zIndex: 1,
      padding: `12px 34px`,
      maxHeight: 40,
      "&:hover": {
        color: palette.secondary.light,
        backgroundColor: "transparent",
        border: `none`,
      },
      "&.active": {
        color: palette.secondary.main,
        boxShadow:
          "0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04);",
        border: `1px solid ${palette.secondary.main}`,
        padding: `${spacing(1)}px ${spacing(5)}px ${spacing(1)}px ${spacing(
          2,
        )}`,
      },
      "&:disabled": {
        backgroundColor: "transparent",
        color: palette.secondary.main,
        border: "none",
        cursor: "default",
      },
    },
    textSuccess: {
      backgroundColor: "transparent",
      color: palette.success.dark,
      border: `none`,
      padding: `12px 0`,
      lineHeight: "18px",
      zIndex: 1,
      "&:hover": {
        color: palette.success.dark,
        backgroundColor: "transparent",
        border: `none`,
        textDecoration: "underline",
      },
      "&.active": {
        color: palette.success.dark,
        boxShadow:
          "0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04);",
        border: `1px solid ${palette.secondary.main}`,
        padding: `${spacing(1)}px ${spacing(5)}px ${spacing(1)}px ${spacing(
          2,
        )}`,
      },
      "&:disabled": {
        backgroundColor: "transparent",
        color: palette.success.dark,
        border: "none",
        cursor: "default",
      },
    },
    textSuccessThin: {
      backgroundColor: "transparent",
      color: palette.success.dark,
      border: `none`,
      padding: 0,
      lineHeight: "18px",
      zIndex: 1,
      "&:hover": {
        color: palette.success.dark,
        backgroundColor: "transparent",
        textDecoration: "underline",
        border: `none`,
      },
      "&.active": {
        color: palette.success.dark,
        boxShadow:
          "0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04);",
        border: `1px solid ${palette.secondary.main}`,
        padding: `${spacing(1)}px ${spacing(5)}px ${spacing(1)}px ${spacing(
          2,
        )}`,
      },
      "&:disabled": {
        backgroundColor: "transparent",
        color: palette.success.dark,
        border: "none",
        cursor: "default",
      },
    },
    loading: {
      cursor: "default",
    },
    removeSector: {
      position: "absolute",
      right: spacing(1),
      zIndex: 2,
      width: 14,
      height: 14,
      display: "flex",
      "&:hover $removeSectorIcon": {
        display: "none",
      },
      "&:hover $removeSectorIconHover": {
        display: "block",
      },
    },
    removeSectorIcon: {},
    removeSectorIconHover: {
      display: "none",
    },
    large: {
      padding: `18px 60px`,
      maxHeight: 56,
    },
    small: {
      padding: `${spacing(1)}px ${spacing(2)}px`,
    },
    leftAligned: {
      justifyContent: "flex-start !important",
    },
    dark: {
      color: palette.common.white,
      borderColor: palette.common.white,
      backgroundColor: "transparent",
      "& svg": {
        marginRight: spacing(1),
      },
      "& svg, & svg circle": {
        stroke: palette.common.white,
      },
      "&:hover": {
        backgroundColor: "rgba(255, 255, 255, 0.1)",
        borderColor: palette.common.white,
      },
      "&:active": {
        backgroundColor: "rgba(255, 255, 255, 0.15)",
      },
    },
    primaryDark: {
      color: palette.primary.main,
      borderColor: palette.primary.main,
      borderWidth: 1,
      backgroundColor: "transparent",
      "& svg, & svg circle": {
        stroke: palette.primary.main,
      },
      "&:hover": {
        backgroundColor: "rgba(255, 255, 255, 0.1)",
        borderColor: palette.primary.main,
      },
      "&:active": {
        backgroundColor: "rgba(255, 255, 255, 0.15)",
      },
    },
    secondaryDark: {
      "&:hover": {
        backgroundColor: "transparent",
        color: palette.primary.main,
        "& svg, & svg circle": {
          stroke: palette.primary.main,
        },
      },
    },
    tertiaryDark: {
      "&:hover": {
        backgroundColor: "rgba(255, 255, 255, 0.1)",
        "& svg, & svg circle": {
          stroke: palette.primary.main,
        },
      },
    },
    textDark: {
      color: ({ active }) => active && palette.primary.main,
      "& svg, & svg circle": {
        stroke: ({ active }) => active && palette.primary.main,
      },
      "&:disabled": {
        "&:hover": {
          backgroundColor: "transparent",
          color: palette.secondary.main,
        },
      },
      "&:hover": {
        backgroundColor: "transparent",
        color: palette.primary.main,
        "& svg, & svg circle": {
          stroke: palette.primary.main,
        },
      },
    },
    pillDark: {
      color: palette.secondary.main,
      "&:hover": {
        backgroundColor: "transparent",
        "& svg, & svg circle": {
          stroke: palette.primary.main,
        },
      },
    },
    error: {
      background: palette.error.main,
      borderColor: palette.error.main,
    },
    fullWidth: {
      width: "100%",
      justifyContent: "center",
    },
    imgButton: {
      marginLeft: `${spacing(1)}px`,
      width: 9,
    },
    iconMargin: {
      "& svg": {
        marginRight: spacing(1),
      },
    },
    iconMarginLeft: {
      "& svg": {
        marginLeft: spacing(1),
      },
    },
    buttonLoader: {
      marginLeft: spacing(1),
    },
  }),
);
