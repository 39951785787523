import { useQuery } from "@tanstack/react-query";
import ScopeGuidanceService from "../api/scopeGuidance/scopeGuidanceService";

export const useScopeGuidance = (
  llm_id,
  query,
  system_message_group,
  STEP_1,
  STEP_2,
  STEP_3,
  STEP_4,
  STEP_5,
) => {
  const scopeGuidanceService = new ScopeGuidanceService();

  const sendPromptTest = useQuery({
    queryKey: ["promptTest"],
    queryFn: () =>
      scopeGuidanceService.sendPromptTest(llm_id, query, system_message_group),
    enabled: false,
    retry: 1,
  });

  const generateScopePrompt = useQuery({
    queryKey: ["generateScopePrompt"],
    queryFn: () =>
      scopeGuidanceService.generateScopePrompt(
        llm_id,
        STEP_1,
        STEP_2,
        STEP_3,
        STEP_4,
        STEP_5,
      ),
    enabled: false,
    retry: 1,
  });

  return {
    sendPromptTest,
    generateScopePrompt,
  };
};
