import { createStyles, makeStyles } from "@material-ui/core/styles";

export default makeStyles(({ spacing, palette }) =>
  createStyles({
    link: {
      cursor: ({ disabled }) => (disabled ? "default" : "pointer"),
      color: ({ color, textColor }) => (textColor ? textColor : color),
      fontFamily: "GT Flexa Mono, sans-serif",
      display: "inline-flex",
      alignItems: "center",
      flexDirection: ({ iconRight }) => (iconRight ? "row" : "row-reverse"),
      "& svg": {
        transform: ({ rotateIcon }) => (rotateIcon ? "rotate(180deg)" : "none"),
        stroke: ({ iconColor }) => iconColor,
        margin: ({ iconRight }) =>
          iconRight ? `0 0 0 ${spacing(1)}px` : `0 ${spacing(1)}px 0 0`,
      },
      "&:hover": {
        color: ({ color, hoverColor }) =>
          color === "textSecondary" && hoverColor,
        textDecoration: ({ disabled }) =>
          disabled ? "none !important" : "underline",
        "& svg": {
          stroke: ({ color, hoverColor }) =>
            color === "textSecondary" && hoverColor,
        },
      },
    },
    dark: {
      color: palette.common.white,
    },
  }),
);
