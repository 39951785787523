import { createAction } from "@reduxjs/toolkit";
import { REDUCER_PREFIX } from "./slice";

export const fetchDashboardRequest = createAction(
  `${REDUCER_PREFIX}/fetchDashboardRequest`,
);

export const markActivitySeenRequest = createAction(
  `${REDUCER_PREFIX}/markActivitySeenRequest`,
);

export const fetchActivitiesRequest = createAction(
  `${REDUCER_PREFIX}/fetchActivitiesRequest`,
);
