import { createStyles, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(({ palette, spacing, shape, breakpoint }) =>
  createStyles({
    root: {
      display: "flex",
      // justifyContent: "space-between",
      // alignItems: "center",
      flexDirection: "column",
      // [breakpoint.down("md")]: {
      //     alignItems: "flex-start"
      // }
    },
    startEndornment: {
      marginRight: `${spacing(1)}px`,
    },
    headerInput: {
      [breakpoint.down("md")]: {
        width: "100%",
      },
      "& input": {
        width: 167,
        [breakpoint.down("md")]: {
          width: "100%",
        },
      },
    },
    resultsTotal: {
      marginLeft: spacing(1),
    },
    sortBySelectorBox: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      paddingRight: spacing(3),
      paddingLeft: spacing(3),
      marginLeft: spacing(1),
      borderRadius: spacing(3),
      border: `1px solid ${palette.common.white}`,
      "&:hover": {
        background: palette.primary.backgroundLight,
      },
    },
    sortBySelectorBoxOpened: {
      border: `1px solid ${palette.primary.dark}`,
    },
    arrowIcon: {
      cursor: "pointer",
    },
    sortByLabel: {
      position: "relative",
      marginBottom: 0,
      transform: "none",
      fontSize: "12px !important",
      lineHeight: "20px !important",
      fontFamily: "GT Flexa, sans-serif !important",
      color: `${palette.common.black} !important`,
    },
    multiselectAdjust: {
      border: "none",
      background: "transparent",
      marginTop: "0 !important",
      paddingRight: "0 !important",
    },
    sortByRoot: {
      paddingLeft: "0 !important",
      paddingRight: `${spacing(1)}px !important`,
      border: "none",
      "&:focus": {
        border: "none",
      },
    },
    rotateIcon: {
      transform: "rotate(180deg)",
    },
    multiselect: {
      marginLeft: spacing(1),
    },
    inviteIcon: {
      marginRight: spacing(1),
      cursor: "pointer",
    },
    searchHistoryIcon: {
      marginRight: spacing(3),
    },
    startNewSearch: {
      background: palette.primary.dark,
    },
    flexCenter: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      // [breakpoint.down("md")]: {
      //     flexDirection: "column",
      //     alignItems: "flex-start"
      // }
    },
    flexColumn: {
      display: "flex",
      flexDirection: "column",
    },
    flex: {
      display: "flex",
    },
    sortWrap: {
      display: "flex",
      flexDirection: "column",
      alignContent: "flex-start",
    },
    sortedBy: {
      marginRight: spacing(1),
      marginLeft: spacing(3),
      [breakpoint.down("md")]: {
        flexDirection: "column",
        marginLeft: 0,
      },
    },
    sortWrapper: {
      display: "flex",
      alignItems: "center",
      [breakpoint.down("md")]: {
        display: "flex",
        alignItems: "center",
        marginTop: spacing(1),
      },
    },
    filterWrap: {
      display: "flex",
      alignItems: "center",
      marginTop: spacing(2),
      [breakpoint.down("md")]: {
        flexDirection: "column",
        alignItems: "flex-start",
      },
    },
    moreIcon: {
      cursor: "pointer",
    },
    moreIconWrapper: {
      position: "relative",
      width: 24,
    },
    moreIconMenu: {
      position: "absolute",
      top: 20,
      right: 0,
      background: palette.common.white,
      border: `1px solid ${palette.text.backgroundDark}`,
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
      borderRadius: spacing(2),
      zIndex: 2,
      minWidth: 184,
      fontFamily: "GT Flexa Mono, sans-serif",
    },
    moreIconMenuItem: {
      padding: `${spacing(2)}px ${spacing(3)}px`,
      "&:hover": {
        cursor: "pointer",
        color: palette.error.main,
      },
    },
    mb24: {
      marginBottom: spacing(3),
    },
    buttonsWrap: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      textAlign: "center",
      marginTop: spacing(3),
      marginBottom: spacing(3),
    },
    planEndedTitle: {
      marginTop: spacing(3),
    },
    ml16: {
      marginLeft: spacing(2),
    },
  }),
);
