import { createStyles, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(({ palette, spacing, breakpoint }) =>
  createStyles({
    mono: {
      fontFamily: "GT Flexa",
      fontWeight: 500,
    },
    lookAlikeLink: {
      color: palette.primary.main,
      textDecoration: "underline",
      cursor: "pointer",
    },
    charts: {
      height: "95%",
      display: "none",
      background: palette.common.white,
      borderRadius: spacing(3),
      padding: 5,
      margin: `0 0 0 ${spacing(4)}px`,
      [breakpoint.down("xl")]: {
        padding: spacing(2),
        margin: `0 ${spacing(2)}px 0 ${spacing(4)}px`,
      },
      [breakpoint.down("sm")]: {
        width: "calc(100% + 32px)",
        marginLeft: -16,
        borderRadius: 0,
        padding: spacing(4),
      },
    },
    chartsVisible: {
      display: "block",
      [breakpoint.down("lg")]: {
        // marginLeft: -24,
        // marginRight: -24,
        width: "auto",
      },
    },
    rut: {
      overflow: "scroll",
      height: "95%",
      paddingBottom: 60,
      display: "flex",
    },
    graph: {
      position: "relative",
      width: "100%",
      marginLeft: -40,
      "@media only screen and (max-width: 1750px)": {
        width: "100%",
        marginBottom: 40,
      },
    },
    industryGraph: {
      width: "100%",
    },
    pieChart: {
      height: "300px",
      position: "relative",
      "@media only screen and (max-width: 1750px)": {
        margin: "0",
      },
    },
    graphContent: {
      alignContent: "flex-end",
      alignItems: "flex-end",
      marginLeft: 87,
      marginTop: -57,
    },
    graphsWrapper: {
      display: "flex",
      flexWrap: "wrap",
      marginBottom: spacing(4),
      width: "100%",
      "& > div": {
        minHeight: "350px",
      },
      [breakpoint.down("lg")]: {
        justifyContent: "space-between",
      },
      [breakpoint.down("md")]: {
        justifyContent: "flex-start",
        flexDirection: "column",
      },
    },
    graphsWrapperRow1: {
      display: "flex",
      flexWrap: "wrap",
      width: "100%",
      "& > div": {
        width: "50%",
      },
      "@media only screen and (max-width: 1750px)": {
        "& > div": {
          width: ({ user }) => user?.csp && "100%",
        },
      },
      [breakpoint.down("lg")]: {
        flexDirection: "column",
        "& > div": {
          width: ({ user }) => !user?.csp && "100%",
        },
      },
      [breakpoint.down("md")]: {
        flexDirection: "column",
        "& > div": {
          marginLeft: "auto",
          marginRight: "auto",
        },
      },
    },
    graphsWrapperRow2: {
      display: "flex",
      flexWrap: "wrap",
      width: "100%",
      rowGap: 20,
      "& > div": {
        width: "50%",
      },
      "@media only screen and (max-width: 1750px)": {
        "& > div": {
          width: ({ user }) => user?.csp && "100%",
        },
      },
      [breakpoint.down("lg")]: {
        flexDirection: "column",
        "& > div": {
          width: ({ user }) => !user?.csp && "100%",
        },
      },
      [breakpoint.down("md")]: {
        flexDirection: "column",
        "& > div": {
          marginLeft: "auto",
          marginRight: "auto",
        },
      },
    },
    teamSizeGraphWrapper: {
      "& $graphContent": {
        "@media only screen and (max-width: 1750px)": {
          width: "auto",
        },
      },
      [breakpoint.down("md")]: {
        marginTop: spacing(6),
      },
    },
    industriesGraphWrapper: {
      paddingRight: 20,
      "@media only screen and (max-width: 1750px)": {
        display: "flex",
        flexDirection: "column",
        alignItem: "center",
        justifyContent: "center",
        paddingRight: ({ user }) => user?.csp && 0,
      },
      [breakpoint.down("xl")]: {
        marginLeft: 0,
      },
      [breakpoint.down("md")]: {
        marginTop: spacing(6),
      },
    },
    noIndustriesIconWrapper: {
      textAlign: "center",
    },
    noIndustriesIcon: {
      marginTop: "25%",
    },
    locationsGraphWrapper: {
      paddingLeft: 20,
      "@media only screen and (max-width: 1750px)": {
        paddingLeft: ({ user }) => user?.csp && 0,
      },
      [breakpoint.down("xxl")]: {
        paddingLeft: 0,
        marginLeft: 0,
      },
      [breakpoint.down("md")]: {
        paddingLeft: 0,
        marginTop: spacing(6),
      },
    },
    locationsGraph: {
      position: "relative",
      width: "100%",
      height: 300,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& div > div > svg": {
        width: "300px",
      },
      "@media only screen and (max-width: 1750px)": {
        "& div > div > svg": {
          width: "300px",
        },
        flexDirection: "column",
        alignItem: "center",
        justifyContent: "center",
      },
    },
    pieChartTitle: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
    pieChartTitleNumber: {
      textAlign: "center",
      fontSize: "35px",
      fontWeight: 700,
    },
    pieChartTooltip: {
      position: "fixed",
      backgroundColor: `${palette.common.white} !important`,
      fontFamily: "GT Flexa Mono, sans-serif",
      boxShadow:
        "0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04) !important",
      padding: `16px 24px !important`,
      borderRadius: "16px",
      fontSize: 10,
      lineHeight: "10px",
    },
    mapChartTooltip: {
      backgroundColor: `${palette.common.white} !important`,
      fontFamily: "GT Flexa Mono, sans-serif",
      boxShadow:
        "0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04) !important",
      padding: `16px 24px !important`,
      borderRadius: "16px !important",
      fontSize: "10px important",
      lineHeight: "10px !important",
      opacity: "1 !important",
    },
    pieChartTooltipNumber: {
      textAlign: "center",
      marginBottom: spacing(1),
      fontFamily: "GT Flexa, sans-serif",
      fontSize: "18px",
      fontWeight: 500,
    },
    industriesGraphContent: {},
    industriesGraphContentItem: {
      width: "100%",
      padding: "10px",
      display: "flex",
      justifyContent: "space-between",
      borderBottom: "1px dashed black",
    },
    topIndustriesSubtitle: {
      marginBottom: spacing(2),
      // marginTop: "39px",
    },
    "recharts-text": {
      display: "none",
    },
    averageDataLabel: {
      marginRight: "10%",
      display: "flex",
      // alignItems: "center"
    },
    averageDataIcon: {
      // marginLeft: spacing(1)
    },
    chartsHeader: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      marginTop: "10px",
      alignItems: "center",
      [breakpoint.down("md")]: {
        rowGap: 10,
        alignItems: "start",
      },
      [breakpoint.down("sm")]: {
        flexDirection: "column",
      },
    },
    chartsHeaderLegendSubwrapper: {
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
    },
    chartsHeaderLegend: {
      [breakpoint.down("sm")]: {
        marginLeft: 18,
      },
    },
    chartsHeaderLegendWrapper: {
      display: "flex",
      // flexDirection: "column",
      justifyContent: "flex-end",
      // alignItems: "flex-end"
    },
    resetAll: {
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
    },
    averageDataTooltip: {
      background: `${palette.common.white} !important`,
      color: `${palette.text.main} !important`,
    },
    averageDataTooltipArrow: {
      color: `${palette.common.white} !important`,
    },
    companiesNum: {
      [breakpoint.down("sm")]: {
        marginBottom: spacing(2),
      },
    },
    mb16: {
      marginBottom: spacing(2),
    },
    chartsLegend: {
      marginLeft: spacing(5),
      position: "relative",
      "&::before": {
        content: "''",
        width: 16,
        height: 16,
        borderRadius: "50%",
        background: palette.primary.dark,
        position: "absolute",
        left: -20,
        top: 0,
      },
    },
    chartsLegendAll: {
      position: "relative",
      "&::before": {
        content: "''",
        width: 16,
        height: 16,
        borderRadius: "50%",
        background: palette.text.backgroundDark,
        position: "absolute",
        left: -20,
        top: 0,
      },
    },
    chartTitle: {
      margin: `${spacing(4)}px 0 4px 0`,
      display: "flex",
      alignItems: "center",
    },
    noChartTitle: {
      margin: `${spacing(4)}px 0 4px ${spacing(5)}px`,
      display: "flex",
      alignItems: "center",
    },
    year: {
      marginRight: spacing(3),
    },
    chartYearsLegend: {
      marginBottom: spacing(3),
    },
    arrowDownBlack: {
      marginLeft: 18,
      transform: "rotate(180deg)",
    },
    collapseOverviewLabel: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
    },
    resetIcon: {
      cursor: "pointer",
      marginLeft: spacing(2),
    },
    resetAllIcon: {
      cursor: "pointer",
      marginLeft: spacing(1),
    },
    bgGradient: {
      height: 8,
      left: 0,
      right: 0,
      position: "absolute",
      bottom: 0,
      zIndex: 2,
      background: `linear-gradient(180.03deg, rgba(255, 248, 243, 0) 0.08%, #FFFFFF 99.92%)`,
    },
  }),
);
