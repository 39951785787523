import { all, takeEvery } from "redux-saga/effects";
import { sendInviteCoworkersRequest } from "../requests";
import { sendInviteCoworkers } from "./workers";

const convertTypeFromAction = actionCreator => actionCreator.toString();

function* watchSendInviteCoworkers() {
  yield takeEvery(
    convertTypeFromAction(sendInviteCoworkersRequest),
    ({ payload: { emailsPrepared } }) => sendInviteCoworkers(emailsPrepared),
  );
}

function* saga() {
  yield all([watchSendInviteCoworkers()]);
}

export { saga };
