const getSendInviteCoworkersStatusSelector = state =>
  state.invites.getInviteStatus;
const getInviteFailureMessageSelector = state =>
  state.invites.getInviteFailureMessage;
const getInviteFailureStatusCodeSelector = state => state.invites.statusCode;

export {
  getSendInviteCoworkersStatusSelector,
  getInviteFailureMessageSelector,
  getInviteFailureStatusCodeSelector,
};
