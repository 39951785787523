import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router";
// import classnames from "classnames";
// Hooks
import { useTranslation } from "react-i18next";
import { useStyles } from "./style.js";
import { useRouterHook } from "../../../common/useRouterHook.js";
import { useAuthHook } from "../../../common/useAuthHook.js";
import useFilters from "../../../common/useFilters.js";
import useResults from "../../../common/useResults.js";
import { useScrollTo } from "../../../common/useScrollTo";
import usePrevious from "../../../common/usePrevious";
//Components
import Header from "./components/Header/Header";
import Card from "../../../components/Card/Card";
import Typography from "../../../components/Typography/Typography";
import ContentLoader from "../../../components/ContentLoader/ContentLoader.js";
import SignUpForm from "../../Authentication/SignUpForm";
import SubscribeConfirmationModal from "../../Results/components/modals/SubscribeConfirmationModal";
import GraphsContainer from "../../../components/GraphsContainer/GraphsContainer";
import Pagination from "../../../components/Pagination/Pagination";
import NoResults from "../../Results/components/NoResults/NoResults.js";
//Constants
import {
  fundingOptionsMin,
  industryFilterParamMapping,
  regionsOptions,
  stageOptions,
  teamsizeOptions,
  valuerScoreOptionsMin,
} from "../../../constants/filters";
import { objectHasEmptyValues } from "../../../utils/objectHasEmptyValues";
//Redux
import { useDispatch, useSelector } from "react-redux";
import {
  getSectorsSelector,
  getSubsectorsSelector,
} from "../../../store/sectors/selectors";
import {
  fetchTechnologyCompaniesChartRequest,
  fetchTechnologyCompaniesTeamSizeChartRequest,
  filterTechnologyCompaniesCountRequest,
  filterTechnologyCompaniesRequest,
} from "../../../store/technologies/requests";
import {
  fetchIndustryCompaniesChartRequest,
  fetchIndustryCompaniesCountRequest,
  fetchIndustryCompaniesRequest,
  fetchIndustryCompaniesTeamSizeChartRequest,
} from "../../../store/industries/requests";
import { getDisplayFiltersBySidSelector } from "../../../store/results/selectors";
import {
  getTechnologyCompaniesChartSelector,
  getTechnologyCompaniesFilterStats,
  getTechnologyCompaniesSelector,
  getTechnologyCompaniesTeamSizeChartSelector,
  getTechnologyCompaniesTeamSizeChartUnspecifiedAllSelector,
  getTechnologyStatusSelector,
} from "../../../store/technologies/selectors";
import {
  getIndustryCompaniesChart,
  getIndustryCompaniesFilterStats,
  getIndustryCompaniesSelector,
  getIndustryCompaniesStatusSelector,
  getIndustryCompaniesTeamSizeChart,
  getIndustryCompaniesTeamSizeChartUnspecifiedAllSelector,
} from "../../../store/industries/selectors";
import { getCountriesSelector } from "../../../store/countries/selectors";
import {
  getCheckOutUrlSelector,
  getSubscribeStatusSelector,
  getUserStatusSelector,
} from "../../../store/auth/selectors";
//Assets
// import { ReactComponent as CloseIcon } from '../../../assets/icons/CloseIcon.svg';
import { ReactComponent as UpArrowResults } from "../../../assets/icons/UpArrowResults.svg";
//Shared
import { Shared } from "../../../utils/shared.js";
import ListView from "../../Results/components/ListView/ListView";
import { useProjectProfileHook } from "../../Projects/hooks/useProjectProfileHook";
import { REQUEST_PENDING } from "../../../constants/statuses.js";
// Utils
import { createCompanyData } from "../../../utils/companyUtils.js";

const ProfileCompanies = ({
  companies,
  onFilterNameValueChange,
  isIndustry,
  isTechnology,
  getTotalPages,
  getTotalCount,
  subsectorsQuery,
  selectedSubsectorArray,
  id,
  name,
  activeView,
  setActiveView,
  handleSetSelectedFilters,
  handleSetDisplayedFilters,
  handleSetSortValue,
  handleSetSortDirection,
  handleSetPage,
}) => {
  const [overviewVisibility, setOverviewVisibility] = useState(false);
  const prevSelectedSubsectorArray = usePrevious(selectedSubsectorArray);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  let { search } = useLocation();
  const { slug, token } = useParams();
  const { openPage } = useRouterHook();
  const { openRemoveWarningModal } = useProjectProfileHook({});
  const localStorageSlugPrefix = isIndustry ? "industry" : "technology";
  const urlParams = new URLSearchParams(search);
  const searchTypeLook = urlParams.get("searchTypeLook");
  // Selectors
  const technologyCompanies = useSelector(getTechnologyCompaniesSelector);
  const industryCompanies = useSelector(getIndustryCompaniesSelector);
  const industryCompaniesChart = useSelector(getIndustryCompaniesChart);
  const industryCompaniesTeamSizeChart = useSelector(
    getIndustryCompaniesTeamSizeChart,
  );
  const unspecifiedAllIndustry = useSelector(
    getIndustryCompaniesTeamSizeChartUnspecifiedAllSelector,
  );
  const technologyCompaniesChart = useSelector(
    getTechnologyCompaniesChartSelector,
  );
  const technologyCompaniesTeamSizeChart = useSelector(
    getTechnologyCompaniesTeamSizeChartSelector,
  );
  const unspecifiedAllTechnology = useSelector(
    getTechnologyCompaniesTeamSizeChartUnspecifiedAllSelector,
  );
  const technologyCompaniesFilterStats = useSelector(
    getTechnologyCompaniesFilterStats,
  );
  const industryCompaniesFilterStats = useSelector(
    getIndustryCompaniesFilterStats,
  );
  const allSectors = useSelector(getSectorsSelector);
  const getIndustryCompaniesStatus = useSelector(
    getIndustryCompaniesStatusSelector,
  );
  const getTechnologyStatus = useSelector(getTechnologyStatusSelector);
  const allResults = [];
  //State
  const { isAuthenticated, userLoading, user } = useAuthHook();
  const [timeout, setTimeoutVal] = useState(null);
  const getFilteringState = isIndustry
    ? industryCompanies
    : technologyCompanies;
  const [loading, setLoading] = useState(false);
  const [loadingCompanies, setLoadingCompanies] = useState(false);
  const [searchResults, setSearchResults] = useState(companies);
  const [totalCount, setTotalCount] = useState(getTotalCount);
  const allCountries = useSelector(getCountriesSelector);
  const allSubsectors = useSelector(getSubsectorsSelector);
  const getMeStatus = useSelector(getUserStatusSelector);
  const checkOutUrl = useSelector(getCheckOutUrlSelector);
  const subscribeStatus = useSelector(getSubscribeStatusSelector);
  const [isAuthed, setIsAuthed] = useState(true);
  const { scrollToTop, upArrowVisible } = useScrollTo();
  const getDisplayFilters =
    useSelector(getDisplayFiltersBySidSelector(slug)) || {};
  //check this
  const [totalPages, setTotalPages] = useState(getTotalPages);
  const [selected, setSelected] = useState([]);
  const [selectedNames, setSelectedNames] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [tableToolbarClassName, setTableToolbarClassName] = useState(
    classes.header,
  );

  const cardsWrapperRef = useRef(null);
  const tableToolbarRef = useRef(null);

  const { scrollTo } = Shared({});

  useEffect(() => {
    setLoadingCompanies(
      getIndustryCompaniesStatus === REQUEST_PENDING ||
        getTechnologyStatus === REQUEST_PENDING,
    );
  }, [getIndustryCompaniesStatus, getTechnologyStatus]);

  // Filtering
  const {
    filters,
    selectedFilters,
    setDisplayedFilters,
    filterNameValue,
    resetFilters,
    handleSectorChange,
    handleSubsectorChange,
    handleFundingMinChange,
    handleYearMinChange,
    handleMatchingScoreMinChange,
    resetFilter,
    setModalFilters,
    setSelectedFilters,
    displayedFilters,
    fundingOptionsMax,
    valuerScoreOptionsMax,
    handleTeamSizeMinChange,
    teamSizeOptionsMax,
    handleFieldChange,
    handleStageChange,
    handleLocationChange,
    handleShowResults,
    handleShowFilters,
    setIsFilterFetching,
    isFilterFetching,
    setPage,
    page,
    handleSortChange,
    sortValue,
    sortDirection,
    handleSectorsClose,
    handleSubsectorsClose,
    preselectedSectors,
    setPreselectedSectors,
    preselectedSubsectors,
    setPreselectedSubsectors,
    handleRegionChange,
    filterMapping,
    handleExcludeKeywordsChange,
    handleIncludeKeywordsChange,
  } = useFilters({
    isAuthenticated,
    userLoading,
    allSectors,
    setTotalPages,
    getFilterResultsTotalPages: getTotalPages,
    getSearchResultsTotalPages: getTotalPages,
    onFilterNameValueChange,
    slug,
    searchTypeLook,
    currentSearchSid: slug,
    request: isIndustry
      ? fetchIndustryCompaniesRequest
      : filterTechnologyCompaniesRequest,
    countRequest: isIndustry
      ? fetchIndustryCompaniesCountRequest
      : filterTechnologyCompaniesCountRequest,
    yearChartRequest: isTechnology
      ? fetchTechnologyCompaniesChartRequest
      : fetchIndustryCompaniesChartRequest,
    teamSizeChartRequest: isTechnology
      ? fetchTechnologyCompaniesTeamSizeChartRequest
      : fetchIndustryCompaniesTeamSizeChartRequest,
    companiesId: id,
    token,
    localStorageSlugPrefix,
    isIndustry,
    isTechnology,
    activeView,
  });

  useEffect(() => {
    handleSetSelectedFilters(selectedFilters);
  }, [selectedFilters]);

  useEffect(() => {
    handleSetDisplayedFilters(displayedFilters);
  }, [displayedFilters]);

  useEffect(() => {
    handleSetSortValue(sortValue);
  }, [sortValue]);

  useEffect(() => {
    handleSetSortDirection(sortDirection);
  }, [sortDirection]);

  useEffect(() => {
    handleSetPage(page);
  }, [page]);

  const prevDisplayedFilters = usePrevious(displayedFilters);
  const prevSearchResults = usePrevious(searchResults);

  const switchView = view => {
    setPage(1);
    setActiveView(view);
  };

  const {
    isFetching,
    pageLoading,
    isSubscribeDialogOpened,
    deselectAll,
    addItemToProjectModal,
    setIsSubscribeDialogOpened,
    confirmSubscription,
    handleSelectingCardIndex,
    selectedCardIndex,
    moreData,
  } = useResults({
    slug,
    totalCount,
    searchResults,
    isAuthenticated,
    getMeStatus,
    search,
    checkOutUrl,
    subscribeStatus,
    page,
    selectedCompanies,
    getFilteringState,
    setSelectedCompanies,
    totalPages,
    filterNameValue,
    setPage,
    setIsAuthed,
    sortValue,
    sortDirection,
    subsectorsQuery,
    isIndustry,
    displayedFilters,
    request: isIndustry
      ? fetchIndustryCompaniesRequest
      : filterTechnologyCompaniesRequest,
    localStorageSlugPrefix,
    perPage: activeView === "card" ? 10 : 50,
    activeView,
  });

  // const handleSortChange = (sortValue, sortDirection) => {
  //     onSortDirValueChange();
  //     setSortValue(sortValue);
  //     setSortDirection(sortDirection);
  //     setPage(1);
  // };
  const handleOnSearch = () => {
    setLoading(true);
    clearTimeout(timeout);
    setPage(1);
    setTimeoutVal(
      setTimeout(() => {
        setLoading(false);
      }, 500),
    );
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = searchResults.map(n => n.id);
      const newSelectedNames = searchResults.map(n => n.name);
      setSelected(newSelecteds);
      setSelectedCompanies(searchResults);
      setSelectedNames(newSelectedNames);
      return;
    }
    setSelected([]);
    setSelectedNames([]);
    setSelectedCompanies([]);
  };

  useEffect(() => {
    const subsectorsString =
      selectedSubsectorArray &&
      selectedSubsectorArray.map(e => e.slug).join(",");
    let value = "";
    Object.keys(selectedFilters).forEach((filter, key) => {
      if (
        selectedFilters[filter] !== "" &&
        selectedFilters[filter] &&
        selectedFilters[filter].length !== 0
      ) {
        value = !!industryFilterParamMapping[filter]
          ? value.concat(
              `${industryFilterParamMapping[filter]}=${
                selectedFilters[filter] === "10001+"
                  ? "10001%2B"
                  : key === "subsectors"
                  ? subsectorsString
                  : selectedFilters[filter]
              }&`,
            )
          : value;
      }
    });
    // handleFieldChange({ target: { value: subsectorsString }}, "subsector");
    if (
      JSON.stringify(prevSelectedSubsectorArray) !==
      JSON.stringify(selectedSubsectorArray)
    ) {
      setDisplayedFilters(prevDisplayedFilters => {
        return {
          ...prevDisplayedFilters,
          subsectors: subsectorsString,
        };
      });
    }
  }, [selectedSubsectorArray, prevSelectedSubsectorArray]);

  useEffect(() => {
    totalCount === 0 || page === totalPages || !totalPages
      ? setIsFilterFetching(false)
      : setIsFilterFetching(isFetching);
  }, [isFetching, totalCount, totalPages, page]);

  useEffect(() => {
    if (
      !objectHasEmptyValues(selectedFilters) ||
      getFilteringState.length > 0
    ) {
      setSearchResults(getFilteringState);
      setTotalCount(getTotalCount);
      setTotalPages(getTotalPages);
    } else {
      setSearchResults(companies);
      setTotalCount(getTotalCount);
      setTotalPages(getTotalPages);
    }
  }, [companies, getFilteringState]);

  const [isLess768, setIsLess768] = useState(false);
  useEffect(() => {
    setIsLess768(window.innerWidth < 768);
  }, []);

  const handleResetFilters = () => {
    resetFilters();
    // clearStorage();
  };

  useEffect(() => {
    sessionStorage.setItem(
      `${localStorageSlugPrefix}-${slug}-FilterSortValue`,
      sortValue,
    );
  }, [sortValue]);

  useEffect(() => {
    sessionStorage.setItem(
      `${localStorageSlugPrefix}-${slug}-FilterSortDirection`,
      sortDirection,
    );
  }, [sortDirection]);

  useLayoutEffect(() => {
    if (
      !urlParams.get("sid") &&
      searchResults[0] &&
      JSON.stringify(prevDisplayedFilters) !==
        JSON.stringify(displayedFilters) &&
      JSON.stringify(prevSearchResults) !== JSON.stringify(searchResults)
    ) {
      // dispatch(resultsActions.setResultsDisplayFilters({sid: searchResults[0].search_id, displayedFilters: {
      //     filterSid: searchResults[0].search_id,
      //     sortValue: sortValue || [],
      //     sortDirection: sortDirection || [],
      //     sector: displayedFilters.sector || [],
      //     subsector: displayedFilters.subsector || [],
      //     subsectors: displayedFilters.subsectors || [],
      //     stage: displayedFilters.stage || [],
      //     fundingMin: displayedFilters.fundingMin || "",
      //     fundingMax: displayedFilters.fundingMax || "",
      //     teamsizeMin: displayedFilters.teamsizeMin || "",
      //     teamsizeMax: displayedFilters.teamsizeMax || "",
      //     location: displayedFilters.location || [],
      //     yearMin: displayedFilters.yearMin || "",
      //     yearMax: displayedFilters.yearMax || "",
      //     matchingScoreMin: displayedFilters.matchingScoreMin,
      //     matchingScoreMax: displayedFilters.matchingScoreMax || "",
      //     regions: displayedFilters.regions || [],
      //     search: displayedFilters.search || "",
      //     storageResultsFilterNameValue: filterNameValue || "",
      //     storageResultsFilterPage: Number(page) || 1
      // }}));
    }
    let scrollPosition = sessionStorage.getItem(
      `${isIndustry ? "industry" : "technology"}-${slug}ScrollPosition`,
    );
    let timeout = setTimeout(() => {
      scrollPosition &&
        searchResults.length &&
        scrollTo(Number(scrollPosition), () =>
          sessionStorage.removeItem(
            `${isIndustry ? "industry" : "technology"}-${slug}ScrollPosition`,
          ),
        );
    }, 100);
    return () => {
      clearTimeout(timeout);
    };
  }, [searchResults, displayedFilters]);

  const searchResultsCompanies =
    searchResults &&
    searchResults.map(company =>
      createCompanyData(
        company.name,
        company.description,
        company.total_funding_amount_dollars,
        company.founded_date,
        company.number_of_employees,
        company.investment_stage,
        company.hq_country,
        (company.degree_of_fit * 100).toFixed(),
        company.slug,
        company.id,
        company.enrichment_request,
        company.ready_for_release,
        company.website,
        company.deck,
        company.sectors,
        company.comments_cnt,
        company.subsectors,
        company.projects,
      ),
    );

  return (
    <div ref={cardsWrapperRef}>
      <Header
        onSearch={handleOnSearch}
        onFilterSelected={handleShowFilters}
        searchResults={searchResults}
        searchResultsTotal={searchResults && searchResults.length}
        isAuthenticated={isAuthenticated}
        onSortChange={handleSortChange}
        filters={filters}
        allResults={allResults}
        total={totalCount}
        resetFilters={handleResetFilters}
        stageOptions={stageOptions}
        fundingOptionsMin={fundingOptionsMin}
        fundingOptionsMax={fundingOptionsMax}
        yearOptionsMin={
          (isIndustry ? industryCompaniesChart : technologyCompaniesChart) &&
          Object.keys(
            isIndustry ? industryCompaniesChart : technologyCompaniesChart,
          )
            .map(year => {
              return { name: year };
            })
            .sort()
        }
        yearOptionsMax={
          (isIndustry ? industryCompaniesChart : technologyCompaniesChart) &&
          Object.keys(
            isIndustry ? industryCompaniesChart : technologyCompaniesChart,
          )
            .map(year => {
              return { name: year };
            })
            .sort()
        }
        teamsizeOptions={teamsizeOptions}
        valuerScoreOptionsMin={valuerScoreOptionsMin}
        valuerScoreOptionsMax={valuerScoreOptionsMax}
        handleFieldChange={handleFieldChange}
        handleSectorChange={handleSectorChange}
        handleSubsectorChange={handleSubsectorChange}
        handleStageChange={handleStageChange}
        handleLocationChange={handleLocationChange}
        resetFilter={resetFilter}
        handleFundingMinChange={handleFundingMinChange}
        handleYearMinChange={handleYearMinChange}
        handleMatchingScoreMinChange={handleMatchingScoreMinChange}
        teamSizeOptionsMax={teamSizeOptionsMax}
        handleTeamSizeMinChange={handleTeamSizeMinChange}
        allSectors={allSectors}
        allSubsectors={allSubsectors}
        selectedFilters={selectedFilters}
        allCountries={allCountries}
        onShowResults={handleShowResults}
        displayedFilters={displayedFilters}
        setSelectedFilters={setSelectedFilters}
        setDisplayedFilters={setDisplayedFilters}
        setModalFilters={setModalFilters}
        isLess768={isLess768}
        isIndustry={isIndustry}
        isTechnology={isTechnology}
        slug={slug}
        isFilterFetching={isFilterFetching}
        request={
          isIndustry
            ? fetchIndustryCompaniesRequest
            : filterTechnologyCompaniesRequest
        }
        countRequest={
          isIndustry
            ? fetchIndustryCompaniesCountRequest
            : filterTechnologyCompaniesCountRequest
        }
        overviewVisibility={overviewVisibility}
        setOverviewVisibility={setOverviewVisibility}
        totalPages={totalPages}
        page={page}
        name={name}
        filterStats={
          isIndustry
            ? industryCompaniesFilterStats
            : technologyCompaniesFilterStats
        }
        handleSectorsClose={handleSectorsClose}
        handleSubsectorsClose={handleSubsectorsClose}
        preselectedSectors={preselectedSectors}
        setPreselectedSectors={setPreselectedSectors}
        preselectedSubsectors={preselectedSubsectors}
        setPreselectedSubsectors={setPreselectedSubsectors}
        activeView={activeView}
        setActiveView={switchView}
        companiesPageId={id}
        regionsOptions={regionsOptions}
        handleRegionChange={handleRegionChange}
        filterMapping={filterMapping}
        setSelected={setSelected}
        setSelectedCompanies={setSelectedCompanies}
        setSelectedCompany={setSelectedCompany}
        setSelectedNames={setSelectedNames}
        selected={selected}
        selectedNames={selectedNames}
        selectedCompany={selectedCompany}
        selectedCompanies={selectedCompanies}
        tableToolbarRef={tableToolbarRef}
        handleSelectAllClick={handleSelectAllClick}
        tableToolbarClassName={tableToolbarClassName}
        handleExcludeKeywordsChange={handleExcludeKeywordsChange}
        handleIncludeKeywordsChange={handleIncludeKeywordsChange}
      />
      {isAuthenticated &&
        ((isIndustry && industryCompaniesChart) ||
          (isTechnology && technologyCompaniesChart)) &&
        ((isIndustry && industryCompaniesTeamSizeChart) ||
          (isTechnology && technologyCompaniesTeamSizeChart)) &&
        Object.keys(
          isIndustry ? industryCompaniesChart : technologyCompaniesChart,
        ).length > 0 &&
        Object.keys(
          isIndustry
            ? industryCompaniesTeamSizeChart
            : technologyCompaniesTeamSizeChart,
        ).length > 0 && (
          <GraphsContainer
            t={t}
            chartYears={
              isIndustry ? industryCompaniesChart : technologyCompaniesChart
            }
            teamsizes={
              isIndustry
                ? industryCompaniesTeamSizeChart
                : technologyCompaniesTeamSizeChart
            }
            selectedFilters={selectedFilters}
            setDisplayedFilters={setDisplayedFilters}
            setSelectedFilters={setSelectedFilters}
            overviewVisibility={overviewVisibility}
            setOverviewVisibility={setOverviewVisibility}
            totalCount={totalCount}
            resetFilter={resetFilter}
            slug={slug}
            localStorageSlugPrefix={isIndustry ? "industry" : "technology"}
            unspecifiedAll={
              isIndustry ? unspecifiedAllIndustry : unspecifiedAllTechnology
            }
            setPage={setPage}
            filterStats={
              isTechnology
                ? technologyCompaniesFilterStats
                : industryCompaniesFilterStats
            }
          />
        )}
      {/* Displayed filters */}
      {/* {displayedFilters && !objectHasEmptyValues(displayedFilters) && !hasOnlySearchValue(displayedFilters) && <div className={classes.appliedFiltersWrap} >
                <Typography variant="body2">{t("results.appliedResults")}</Typography>
                <div className={classes.appliedFilters}>
                    {
                        Object.keys(displayedFilters).map((filter, i) => {
                            return displayedFilters[filter] && filter !== "search" && <div className={classes.filterTag} key={`filter-${i}`}><Typography variant="body2" test-id={`filter-${i}`}>{displayedFilters[filter]}<CloseIcon onClick={() => handleFilterRemove(filter)} className={classes.closeIcon} /></Typography></div>
                        })
                    }
                </div >

            </div >} */}
      <div>
        {searchResults.length === 0 && !loading && !loadingCompanies ? (
          <NoResults
            showButton={false}
            reason={"searchResults.length === 0 && !loading && noSearches "}
          />
        ) : (
          <div className={classes.resultsContent}>
            {activeView === "list" && searchResults ? (
              <ListView
                companies={searchResults}
                isTechnology={isTechnology}
                isIndustry={isIndustry}
                searchResults={searchResults}
                onSortChange={handleSortChange}
                sortDirection={sortDirection}
                sortValue={sortValue}
                page={page}
                matchRemoved={true}
                isProject={false}
                openRemoveWarningModal={openRemoveWarningModal}
                projectSlug={slug}
                source={isIndustry ? "industry" : "technology"}
                isLikedCompany={false}
                id={id}
                setSelected={setSelected}
                setSelectedCompanies={setSelectedCompanies}
                setSelectedCompany={setSelectedCompany}
                setSelectedNames={setSelectedNames}
                selected={selected}
                selectedNames={selectedNames}
                selectedCompany={selectedCompany}
                selectedCompanies={selectedCompanies}
                tableToolbar={tableToolbarRef}
                setTableToolbarClassName={setTableToolbarClassName}
              />
            ) : (
              searchResultsCompanies &&
              searchResultsCompanies.map((company, i) => (
                <Card
                  isTechnology={false}
                  isIndustry={false}
                  projectSlug={slug}
                  cardIndex={i}
                  openPage={openPage}
                  t={t}
                  onClick={() => addItemToProjectModal(company)}
                  result={company}
                  isAuthenticated={isAuthenticated}
                  deselectAll={() => deselectAll}
                  key={`card-${i}-${company.id}`}
                  setSelectedCompanyIndex={handleSelectingCardIndex}
                  showPopUpForIndex={selectedCardIndex === i}
                  source={isIndustry ? "industry" : "technology"}
                  isIndustryProfile={false}
                  type="company"
                  setSelected={setSelected}
                  setSelectedCompanies={setSelectedCompanies}
                  setSelectedNames={setSelectedNames}
                  selected={selected}
                  selectedNames={selectedNames}
                  selectedCompanies={selectedCompanies}
                />
              ))
            )}
          </div>
        )}
        {totalPages > 1 && user && (
          <Pagination
            count={totalPages}
            onChangePage={moreData}
            page={Number(page)}
            scrollTopPosition={
              cardsWrapperRef &&
              cardsWrapperRef.current &&
              cardsWrapperRef.current.offsetTop
            }
          />
        )}
        {(pageLoading || loading || loadingCompanies) && (
          <div className={classes.resultsLoader}>
            <ContentLoader />
          </div>
        )}
      </div>
      {upArrowVisible && (
        <UpArrowResults onClick={scrollToTop} className={classes.arrowUp} />
      )}
      {/* Guest block */}
      {!isAuthed && totalCount > 0 && (
        <div className={classes.blurryBg}>
          <div className={classes.guestBlock}>
            <div className={classes.guestBlockContent}>
              <Typography variant="subtitle1" className={classes.readyText}>
                {t("results.readyForMore")}
              </Typography>
              <Typography variant="h4" className={classes.startTrialText}>
                <strong>{t("results.signUpForOur")}</strong>
              </Typography>
              <Typography variant="h4" className={classes.startTrialText}>
                <strong>{t("results.14DaysFreeTrial")}</strong>
              </Typography>
              <SignUpForm
                classNames={classes}
                ctaButtonCopy="signUp.createAccount"
                signUpForFreeTrial={true}
                openPage={openPage}
              />
            </div>
          </div>
        </div>
      )}
      {
        <SubscribeConfirmationModal
          dialogOpened={isSubscribeDialogOpened}
          onChange={() => setIsSubscribeDialogOpened(false)}
          onConfirm={confirmSubscription && confirmSubscription}
        />
      }
    </div>
  );
};
export default ProfileCompanies;
