import { call } from "redux-saga/effects";
import axios from "axios";
import {
  getEnrichIndustryCompanyURL,
  INDUSTRIES_URL,
  industriesCompaniesURL,
} from "../../../constants/endpoints";

function* getIndustries(page) {
  const response = yield call(axios, {
    method: "GET",
    url: `${INDUSTRIES_URL}?page=${page}`,
    withCredentials: true,
  });

  return response.data;
}

function* getIndustry(slug) {
  const response = yield call(axios, {
    method: "GET",
    url: `${INDUSTRIES_URL}/${slug}`,
    withCredentials: true,
  });
  return response.data;
}

function* getIndustryCompany(slug) {
  const response = yield call(axios, {
    method: "GET",
    url: `${INDUSTRIES_URL}/company?cId=${slug}`,
    withCredentials: true,
  });
  return response.data;
}

function* getIndustryCompanies(slug, data) {
  let url = "";
  if (data) {
    const { filterNameValue } = data;
    url = `${industriesCompaniesURL(slug)}${filterNameValue}`;
  } else {
    url = `${industriesCompaniesURL(slug)}`;
  }

  const response = yield call(axios, {
    method: "GET",
    url: url,
    withCredentials: true,
  });
  return response.data;
}

function* postEnrichIndustry(slug) {
  const response = yield call(axios, {
    method: "GET",
    url: `${getEnrichIndustryCompanyURL(slug)}`,
    withCredentials: true,
  });
  return response.data;
}

export {
  getIndustries,
  getIndustry,
  getIndustryCompany,
  getIndustryCompanies,
  postEnrichIndustry,
};
