import React from "react";
import PropTypes from "prop-types";
import useStyles from "./style.js";
import classnames from "classnames";
import Loader from "../Loader/Loader";
import { useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import RemoveIcon from "../../assets/icons/RemoveIcon.svg";
import RemoveIconHover from "../../assets/icons/RemoveIconHover.svg";

const Button = React.forwardRef(
  (
    {
      className,
      active,
      variant,
      loading,
      error,
      children,
      deactivateButton,
      dark,
      size,
      fullWidth,
      leftAligned,
      imgSource,
      iconMargin,
      iconMarginLeft,
      withCloseIcon,
      notClickable,
      ...props
    },
    ref,
  ) => {
    const classes = useStyles({ active });
    const theme = useTheme();
    const { t } = useTranslation();
    let loadingContent;
    let buttonImgSource;
    switch (variant) {
      case "text":
        loadingContent = (
          <>
            {t("common.loadingButton")}
            <Loader
              loaderWrapperClassName={classes.buttonLoader}
              {...(variant === "tertiary" && {
                color: theme.palette.secondary.light,
              })}
            />
          </>
        );
        break;
      case "pill":
        loadingContent = children;
        break;
      case "imgButton":
        buttonImgSource = imgSource;
        break;
      default:
        loadingContent = (
          <>
            {t("common.loadingButton")}
            <Loader
              loaderWrapperClassName={classes.buttonLoader}
              {...(variant === "tertiary" && {
                color: theme.palette.secondary.light,
              })}
            />
          </>
        );
    }
    const buttonClasses = classnames(
      classes.button,
      classes[variant],
      active && variant === "pill" && classes.active,
      active && variant === "sidebar" && classes.activeSidebar,
      dark && (variant === "primary" ? classes.primaryDark : classes.dark),
      classes[size],
      error && classes.error,
      loading && classes.loading,
      fullWidth && classes.fullWidth,
      dark && classes[`${variant}Dark`],
      leftAligned && classes.leftAligned,
      iconMargin && classes.iconMargin,
      iconMarginLeft && classes.iconMarginLeft,
      notClickable && classes.notClickable,
      className,
    );
    const handleDeactivateButton = e => {
      e.preventDefault();
      e.stopPropagation();
      !props.disabled && deactivateButton();
    };

    return (
      <button ref={ref} className={buttonClasses} {...props}>
        {loading ? loadingContent : children}
        {active && variant === "pill" && withCloseIcon && (
          <div
            className={classes.removeSector}
            onClick={handleDeactivateButton}>
            <img
              src={RemoveIcon}
              alt={t("outcome.step2")}
              className={classes.removeSectorIcon}
            />
            <img
              src={RemoveIconHover}
              alt={t("outcome.step2")}
              className={classes.removeSectorIconHover}
            />
          </div>
        )}
        {variant === "imgButton" && (
          <img
            alt={t("resultsHeader.settingsFilter")}
            src={buttonImgSource}
            className={classes.imgButton}
          />
        )}
      </button>
    );
  },
);

Button.propTypes = {
  variant: PropTypes.oneOf([
    "primary",
    "primaryDarkContained",
    "secondary",
    "tertiary",
    "tertiaryBlack",
    "quaternary",
    "text",
    "pill",
    "pillAdd",
    "imgButton",
    "secondaryLight",
    "textPrimary",
    "textPrimaryThin",
    "textSecondary",
    "textSuccess",
    "textSuccessThin",
    "sidebar",
    "success",
    "tertiarySuccess",
    "error",
    "tertiarySuccessReversed",
  ]),
};

Button.defaultProps = {
  size: "normal",
  dark: false,
};

export default Button;

Button.displayName = "Button";
