import { unstable_createMuiStrictModeTheme as createMuiTheme } from "@material-ui/core";
import colors from "./constants/colors";

const GTFlexaRegular = {
  fontFamily: "GTFlexa",
  fontStyle: "normal",
  fontWeight: 400,
  src: `url("./assets/fonts/GT-Flexa-Standard-Regular.otf")
			format("embedded-opentype"),
		url("./assets/fonts/GT-Flexa-Standard-Regular.woff2")
			format("woff2"),
		url("./assets/fonts/GT-Flexa-Standard-Regular.woff")
			format("woff"),
		url("./assets/fonts/GT-Flexa-Standard-Regular.ttf")`,
};

const GTFlexaLight = {
  fontFamily: "GTFlexa Light",
  fontStyle: "normal",
  fontWeight: 300,
  src: `url("./assets/fonts/GT-Flexa-Standard-Light.otf")
			format("embedded-opentype"),
		url("./assets/fonts/GT-Flexa-Standard-Light.woff2")
			format("woff2"),
		url("./assets/fonts/GT-Flexa-Standard-Light.woff")
			format("woff"),
		url("./assets/fonts/GT-Flexa-Standard-Light.ttf")
			format("truetype")`,
};

const GTFlexaThin = {
  fontFamily: "GTFlexa Thin",
  fontStyle: "normal",
  fontWeight: 100,
  src: `url("./assets/fonts/GT-Flexa-Standard-Thin.otf")
			format("embedded-opentype"),
		url("./assets/fonts/GT-Flexa-Standard-Thin.woff2")
			format("woff2"),
		url("./assets/fonts/GT-Flexa-Standard-Thin.woff")
			format("woff"),
		url("./assets/fonts/GT-Flexa-Standard-Thin.ttf")
			format("truetype")`,
};

const GTFlexaBold = {
  fontFamily: "GTFlexa Bold",
  fontStyle: "normal",
  fontWeight: 700,
  src: `url("./assets/fonts/GT-Flexa-Standard-Bold.otf")
			format("embedded-opentype"),
		url("./assets/fonts/GT-Flexa-Standard-Bold.woff2")
			format("woff2"),
		url("./assets/fonts/GT-Flexa-Standard-Bold.woff")
			format("woff"),
		url("./assets/fonts/GT-Flexa-Standard-Bold.ttf")
			format("truetype")`,
};

const GTFlexaBlack = {
  fontFamily: "GTFlexa Black",
  fontStyle: "normal",
  fontWeight: 900,
  src: `url("./assets/fonts/GT-Flexa-Standard-Black.otf")
			format("embedded-opentype"),
		url("./assets/fonts/GT-Flexa-Standard-Black.woff2")
			format("woff2"),
		url("./assets/fonts/GT-Flexa-Standard-Black.woff")
			format("woff"),
		url("./assets/fonts/GT-Flexa-Standard-Black.ttf")
			format("truetype")`,
};

const GTFlexaMedium = {
  fontFamily: "GTFlexa Medium",
  fontStyle: "normal",
  fontWeight: 500,
  src: `url("./assets/fonts/GT-Flexa-Standard-Medium.otf")
			format("embedded-opentype"),
		url("./assets/fonts/GT-Flexa-Standard-Medium.woff2")
			format("woff2"),
		url("./assets/fonts/GT-Flexa-Standard-Medium.woff")
			format("woff"),
		url("./assets/fonts/GT-Flexa-Standard-Medium.ttf")
			format("truetype")`,
};

const GTFlexaMonoRegular = {
  fontFamily: "GTFlexa Mono Regular",
  fontStyle: "normal",
  fontWeight: 400,
  src: `url("./assets/fonts/GT-Flexa-Mono-Regular.otf")
			format("embedded-opentype"),
		url("./assets/fonts/GT-Flexa-Mono-Regular.woff2")
			format("woff2"),
		url("./assets/fonts/GT-Flexa-Mono-Regular.woff")
			format("woff"),
		url("./assets/fonts/GT-Flexa-Mono-Regular.ttf")`,
};

const GTFlexaMonoLight = {
  fontFamily: "GTFlexa Mono Light",
  fontStyle: "normal",
  fontWeight: 300,
  src: `url("./assets/fonts/GT-Flexa-Mono-Light.otf")
			format("embedded-opentype"),
		url("./assets/fonts/GT-Flexa-Mono-Light.woff2")
			format("woff2"),
		url("./assets/fonts/GT-Flexa-Mono-Light.woff")
			format("woff"),
		url("./assets/fonts/GT-Flexa-Mono-Light.ttf")
			format("truetype")`,
};

const GTFlexaMonoThin = {
  fontFamily: "GTFlexa Mono Thin",
  fontStyle: "normal",
  fontWeight: 100,
  src: `url("./assets/fonts/GT-Flexa-Mono-Thin.otf")
			format("embedded-opentype"),
		url("./assets/fonts/GT-Flexa-Mono-Thin.woff2")
			format("woff2"),
		url("./assets/fonts/GT-Flexa-Mono-Thin.woff")
			format("woff"),
		url("./assets/fonts/GT-Flexa-Mono-Thin.ttf")
			format("truetype")`,
};

const GTFlexaMonoBold = {
  fontFamily: "GTFlexa Mono Bold",
  fontStyle: "normal",
  fontWeight: 700,
  src: `url("./assets/fonts/GT-Flexa-Mono-Bold.otf")
			format("embedded-opentype"),
		url("./assets/fonts/GT-Flexa-Mono-Bold.woff2")
			format("woff2"),
		url("./assets/fonts/GT-Flexa-Mono-Bold.woff")
			format("woff"),
		url("./assets/fonts/GT-Flexa-Mono-Bold.ttf")
			format("truetype")`,
};

const GTFlexaMonoBlack = {
  fontFamily: "GTFlexa Mono Black",
  fontStyle: "normal",
  fontWeight: 900,
  src: `url("./assets/fonts/GT-Flexa-Mono-Black.otf")
			format("embedded-opentype"),
		url("./assets/fonts/GT-Flexa-Mono-Black.woff2")
			format("woff2"),
		url("./assets/fonts/GT-Flexa-Mono-Black.woff")
			format("woff"),
		url("./assets/fonts/GT-Flexa-Mono-Black.ttf")
			format("truetype")`,
};

const GTFlexaMonoMedium = {
  fontFamily: "GTFlexa Mono Medium",
  fontStyle: "normal",
  fontWeight: 500,
  src: `url("./assets/fonts/GT-Flexa-Mono-Medium.otf")
			format("embedded-opentype"),
		url("./assets/fonts/GT-Flexa-Mono-Medium.woff2")
			format("woff2"),
		url("./assets/fonts/GT-Flexa-Mono-Medium.woff")
			format("woff"),
		url("./assets/fonts/GT-Flexa-Mono-Medium.ttf")
			format("truetype")`,
};

// 320 - 575: Mobile devices
// 576 - 768: Tablet
// 769 - 1024 Small screens
// 1025 - 1280 Desktop
// 1281 - 1440 Big desktop
// 1441 - 1920 Biggest desktop
// 1920 - 1921 Biggest desktop 2

const BREAKPOINTS = {
  xs: 320,
  sm: 575,
  md: 768,
  lg: 1024,
  xl: 1280,
  xxl: 1440,
  xxxl: 1920,
  xxxxl: 1921,
};

const breakpointsFull = {
  breakpoint: {
    down(key) {
      return `@media (max-width:${this.values[key]}px)`;
    },
    up(key) {
      return `@media (min-width:${this.values[key]}px)`;
    },
    between(keyA, keyB) {
      return `@media (min-width:${this.values[keyA]}px) and (max-width:${this.values[keyB]}px)`;
    },
    values: BREAKPOINTS,
  },
};

const valuerTheme = {
  palette: {
    ...colors,
  },
  typography: {
    fontFamily: "GT Flexa, sans-serif",
    h1: {
      fontSize: 71,
      lineHeight: "80px",
    },
    h2: {
      fontSize: 64,
      lineHeight: "72px",
    },
    h3: {
      fontSize: 56,
      lineHeight: "64px",
    },
    h4: {
      fontSize: 48,
      lineHeight: "56px",
    },
    h5: {
      fontSize: 40,
      lineHeight: "48px",
    },
    h6: {
      fontSize: 32,
      lineHeight: "40px",
    },
    subtitle1: {
      fontSize: 24,
      lineHeight: "32px",
    },
    subtitle2: {
      fontSize: 16,
      lineHeight: "24px",
    },
    subtitle3: {
      fontSize: 28,
      lineHeight: "32px",
    },
    paragraph: {
      fontSize: 20,
      lineHeight: "28px",
    },
    bodyXl: {
      fontSize: 18,
      lineHeight: "26px",
    },
    body: {
      fontSize: 16,
      lineHeight: "24px",
    },
    bodySmall: {
      fontSize: 14,
      lineHeight: "22px",
    },
    bodyButton: {
      fontSize: 14,
      lineHeight: "18px",
    },
    bodyXs: {
      fontSize: 12,
      lineHeight: "20px",
    },
    label: {
      fontSize: 10,
      lineHeight: "10px",
    },
    alignLeft: {
      textAlign: "left",
    },
    alignCenter: {
      textAlign: "center",
    },
    alignRight: {
      textAlign: "right",
    },
    alignJustify: {
      textAlign: "justify",
    },
    noWrap: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    gutterBottom: {
      marginBottom: "0.35em",
    },
    colorInherit: {
      color: "inherit",
    },
    colorText: {
      color: colors.text.main,
    },
    colorTextLight: {
      color: colors.text.light,
    },
    colorTitle: {
      color: colors.common.black,
    },
    colorPrimary: {
      color: colors.primary.main,
    },
    colorPrimaryDark: {
      color: colors.primary.dark,
    },
    colorWarningDark: {
      color: colors.warning.dark,
    },
    colorBlack: {
      color: colors.common.black,
    },
    colorLight: {
      color: colors.text.light,
    },
    colorWhite: {
      color: colors.common.white,
    },
    colorSecondary: {
      color: colors.secondary.main,
    },
    colorTextPrimary: {
      color: colors.text.primary,
    },
    colorTextSecondary: {
      color: colors.text.secondary,
    },
    colorDisabled: {
      color: colors.text.disabled,
    },
    colorInfoDark: {
      color: colors.info.dark,
    },
    colorDanger: {
      color: colors.error.main,
    },
    colorSuccess: {
      color: colors.success.main,
    },
    colorSuccessDark: {
      color: colors.success.dark,
    },
    colorTextLighter: {
      color: colors.text.lighter,
    },
    colorSecondaryDark: {
      color: colors.secondary.dark,
    },
    colorHighlight: {
      color: colors.text.highlight,
    },
    colorGrey: {
      color: colors.grey.main,
    },
    textDecorationUnderline: {
      textDecoration: "underline",
    },
    textDecorationLineThrough: {
      textDecoration: "line-through",
    },
    weightLight: {
      fontWeight: "300",
    },
    weightRegular: {
      fontWeight: "normal",
    },
    weightMedium: {
      fontWeight: "500",
    },
    fontStyleItalic: {
      fontStyle: "italic",
    },
    inline: {
      display: "inline",
    },
    cursor: {
      cursor: "pointer",
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [
          GTFlexaRegular,
          GTFlexaLight,
          GTFlexaThin,
          GTFlexaBold,
          GTFlexaMedium,
          GTFlexaBlack,
          GTFlexaMonoRegular,
          GTFlexaMonoLight,
          GTFlexaMonoThin,
          GTFlexaMonoBold,
          GTFlexaMonoMedium,
          GTFlexaMonoBlack,
        ],
        body: {
          backgroundColor: "#FFFFFF",
        },
        a: {
          cursor: "pointer",
          color: colors.primary.dark,

          "&:hover": {
            textDecoration: "underline",
          },
        },
        ".rc-slider-mark": {
          left: -5,
        },
        ".rc-slider-mark-text": {
          transformOrigin: "right !important",
          transform: "translate(-90%, 20%) rotate(-30deg) !important",
          bottom: -10,
        },
        ".team-size-chart .rc-slider-mark-text": {
          minWidth: 110,
          textAlign: "right",
        },
        ".rc-slider-mark-text-active": {
          opacity: "1 !important",
        },
        ".custom-tooltip": {
          background: "#FFFFFF",
          boxShadow: "0px 2.77993px 6.94982px rgba(0, 0, 0, 0.1)",
          borderRadius: 16,
          padding: 16,
          marginBottom: 60,
          textAlign: "center",
        },
        "#lottie": {
          "@media only screen and (max-width: 575px)": {
            maxWidth: "100%",
            maxHeight: "250px",
          },
        },
        ".recharts-cartesian-axis-tick": {
          position: "relative",
        },
        ".recharts-cartesian-axis-tick line": {
          display: "none",
        },
        ".blackIcon path": {
          fill: colors.text.main,
        },
        ".mobile": {
          display: "none !important",
          "@media only screen and (max-width: 575px)": {
            display: "block !important",
          },
        },
        ".tablet": {
          display: "none !important",
          "@media only screen and (max-width: 768px)": {
            display: "block !important",
          },
        },
        ".laptop": {
          display: "none !important",
          "@media only screen and (max-width: 1024px)": {
            display: "block !important",
          },
        },
        ".desktop": {
          display: "none !important",
          "@media only screen and (max-width: 1280px)": {
            display: "block !important",
          },
        },
        ".big-desktop": {
          display: "none !important",
          "@media only screen and (max-width: 1440px)": {
            display: "block !important",
          },
        },
        ".biggest-desktop": {
          display: "none !important",
          "@media only screen and (max-width: 1920px)": {
            display: "block !important",
          },
        },
        ".non-mobile": {
          display: "none !important",
          "@media only screen and (min-width: 576px)": {
            display: "block !important",
          },
        },
        ".non-tablet": {
          display: "none !important",
          "@media only screen and (min-width: 769px)": {
            display: "block !important",
          },
        },
        ".non-laptop": {
          display: "none !important",
          "@media only screen and (min-width: 1025px)": {
            display: "block !important",
          },
        },
        ".non-desktop": {
          display: "none !important",
          "@media only screen and (min-width: 1281px)": {
            display: "block !important",
          },
        },
        ".non-big-desktop": {
          display: "none !important",
          "@media only screen and (min-width: 1441px)": {
            display: "block !important",
          },
        },
        ".non-biggest-desktop": {
          display: "none !important",
          "@media only screen and (min-width: 1921px)": {
            display: "block !important",
          },
        },
        ".mobile-flex": {
          display: "none !important",
          "@media only screen and (max-width: 575px)": {
            display: "flex !important",
          },
        },
        ".tablet-flex": {
          display: "none !important",
          "@media only screen and (max-width: 768px)": {
            display: "flex !important",
          },
        },
        ".laptop-flex": {
          display: "none !important",
          "@media only screen and (max-width: 1024px)": {
            display: "flex !important",
          },
        },
        ".desktop-flex": {
          display: "none !important",
          "@media only screen and (max-width: 1280px)": {
            display: "flex !important",
          },
        },
        ".big-desktop-flex": {
          display: "none !important",
          "@media only screen and (max-width: 1440px)": {
            display: "flex !important",
          },
        },
        ".biggest-desktop-flex": {
          display: "none !important",
          "@media only screen and (max-width: 1920px)": {
            display: "flex !important",
          },
        },
        ".non-mobile-flex": {
          display: "flex !important",
          "@media only screen and (max-width: 576px)": {
            display: "none !important",
          },
        },
        ".non-tablet-flex": {
          display: "flex !important",
          "@media only screen and (max-width: 769px)": {
            display: "none !important",
          },
        },
        ".non-laptop-flex": {
          display: "flex !important",
          "@media only screen and (max-width: 1025px)": {
            display: "none !important",
          },
        },
        ".non-desktop-flex": {
          display: "flex !important",
          "@media only screen and (max-width: 1281px)": {
            display: "none !important",
          },
        },
        ".non-big-desktop-flex": {
          display: "flex !important",
          "@media only screen and (max-width: 1441px)": {
            display: "none !important",
          },
        },
        ".non-biggest-desktop-flex": {
          display: "flex !important",
          "@media only screen and (max-width: 1921px)": {
            display: "none !important",
          },
        },
        ".non-mobile-inline-flex": {
          display: "inline-flex !important",
          "@media only screen and (max-width: 576px)": {
            display: "none !important",
          },
        },
        ".non-tablet-inline-flex": {
          display: "inline-flex !important",
          "@media only screen and (max-width: 769px)": {
            display: "none !important",
          },
        },
        ".non-laptop-inline-flex": {
          display: "inline-flex !important",
          "@media only screen and (max-width: 1025px)": {
            display: "none !important",
          },
        },
        ".non-desktop-inline-flex": {
          display: "inline-flex !important",
          "@media only screen and (max-width: 1281px)": {
            display: "none !important",
          },
        },
        ".non-big-desktop-inline-flex": {
          display: "inline-flex !important",
          "@media only screen and (max-width: 1441px)": {
            display: "none !important",
          },
        },
        ".non-biggest-desktop-inline-flex": {
          display: "inline-flex !important",
          "@media only screen and (max-width: 1921px)": {
            display: "none !important",
          },
        },
      },
    },
    MuiMobileStepper: {
      root: {
        padding: 0,
        background: colors.common.white,
      },
      dot: {
        width: 10,
        height: 10,
        margin: "0 4px",
      },
    },
    MuiInput: {
      underline: {
        "&:before": {
          borderBottom: `2px solid ${colors.primary.main}`,
        },
      },
    },
    MuiTooltip: {
      arrow: {
        color: `${colors.secondary.dark} !important`,
      },
      tooltip: {
        backgroundColor: `${colors.secondary.dark} !important`,
        fontFamily: "GT Flexa Mono, sans-serif",
        boxShadow:
          "0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04) !important",
        padding: `16px 24px !important`,
        borderRadius: "16px",
        fontSize: 10,
        lineHeight: "10px",
        color: `${colors.common.white} !important`,
      },
    },
    MuiDialog: {
      paperWidthLg: {
        "@media only screen and (max-width: 575px)": {
          margin: 0,
          maxHeight: "100%",
          height: "100%",
          borderRadius: "0 !important",
          padding: "40px 16px !important",
        },
      },
    },
    MuiChecked: {
      background: "orange",
    },
  },
  spacing: [0, 8, 16, 24, 32, 40, 56, 72, 80, 96, 120],
};

const theme = createMuiTheme({
  ...valuerTheme,
  ...breakpointsFull,
});
export default theme;
