import { call, put } from "redux-saga/effects";
import { actions } from "../slice";
import { getExportCsv, getOrders } from "./helpers";

function* fetchOrders(type, team) {
  try {
    yield put(actions.getOrdersPending());
    const projects = yield call(getOrders, type, team);
    yield put(actions.setOrders({ type, payload: projects.data }));
    yield put(actions.getOrdersSuccess());
  } catch (err) {
    yield put(actions.getOrdersFailure());
  }
}

function* exportCsv(
  ids,
  type,
  name,
  idType,
  idValue,
  doc,
  sortBy,
  sortDirection,
  pageName,
) {
  try {
    yield put(actions.exportCsvPending());
    yield call(
      getExportCsv,
      ids,
      type,
      name,
      idType,
      idValue,
      doc,
      sortBy,
      sortDirection,
      pageName,
    );
    yield put(actions.exportCsvSuccess());
  } catch (err) {
    yield put(actions.exportCsvFailure());
  }
}

export { fetchOrders, exportCsv };
