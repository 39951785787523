import { createSelector } from "reselect";

const getSearchResultsStatusSelector = state => state.results.getResultsStatus;
const getSearchResultsBySidStatusSelector = state =>
  state.results.getResultsBySidStatus;
const getSearchResultsPaginationStatusSelector = state =>
  state.results.getResultsPaginationStatus;
const getCurrentPageSelector = state => state.results.currentPage;
const getSearchResultsBySidPaginationStatusSelector = state =>
  state.results.getResultsBySidPaginationStatus;
const getSearchResultsSelector = state => state.results.pool;
const getResultsCountSelector = state => state.results.resultsCount;
const getResultsFilterStats = state => state.results.filterStats;
const getResultsSearchQuery = state => state.results.searchQuery;
const getLikedFilterStats = state => state.results.likedFilterStats;
const getSearchesStatusSelector = state => state.results.getSearchesStatus;
const getRecentSearchesStatusSelector = state =>
  state.results.getRecentSearchesStatus;
const getPostSearchesStatusSelector = state => state.results.postSearchesStatus;
const getPostSearchesMessageSelector = state =>
  state.results.postSearchesMessage;
const lastSearchNamedSelector = state => state.results.lastSearchNamed;
const getSearchesEditSearchStatusSelector = state =>
  state.results.getSearchesEditSearchStatus;
const getSearchesSelector = state => state.results.searches;
const getSearchResultsTotalCountSelector = state => state.results.totalCount;
const getResultsCountStatusSelector = state =>
  state.results.getResultsCountStatus;
const getTotalSearchesCountSelector = state => state.results.totalSearchesCount;
const getTotalSearchesPagesSelector = state => state.results.totalSearchesPages;
const getSearchResultsSidSelector = state => state.results.sid;
const getCurrentSearchSidSelector = state => state.results.currentSearchSid;
const getCurrentSearchSelector = state => state.results.currentSearch;
const getSearchTypeSelector = state => state.results.searchType;
const getActiveViewSelector = state => state.results.activeView;
const getCompanySelector = state => state.results.company;
const getGenerateLinkStatusSelector = state =>
  state.results.getGenerateLinkStatus;
const getGeneratedLink = state => state.results.generatedLink;
const getCompanyStatusSelector = state => state.results.getCompanyStatus;
const getSearchResultsTotalPagesSelector = state => state.results.totalPages;
const getvoteMlResultSelector = state =>
  state.companyProfile.voteMlResultStatus;
const getShareableLinkStatusSelector = state =>
  state.results.generateShareLinkStatus;
const getShareableLinkStateSelector = state =>
  state.results.generateShareLinkState;
const getLookalikeBannerHiddenSelector = state =>
  state.results.lookalikeBannerHidden;
const getCompaniesChartSelector = state => state.results.companiesChart;
const getCompaniesChartStatusSelector = state =>
  state.results.getCompaniesChartStatus;
const getTeamSizeChartStatusSelector = state =>
  state.results.getTeamSizeChartStatus;
const getTeamSizeChartSelector = state => state.results.teamsizeChart;
const getTeamsizeChartUnspecifiedAllSelector = state =>
  state.results.unspecifiedAll;
const getLikedCompaniesSelector = state => state.results.likedCompanies;
const getLikedCompaniesStatusSelector = state =>
  state.results.getLikedCompaniesStatus;
const getLikedCompaniesTotalCountSelector = state =>
  state.results.likedCompaniesTotalCount;
const getLikedCompaniesCurrentPageSelector = state =>
  state.results.likedCompaniesCurrentPage;
const getLikedCompaniesTotalPagesSelector = state =>
  state.results.likedCompaniesTotalPages;
const getDisplayFiltersSelector = state => state.results.displayFilters;
const getClusterSelector = state => state.results.cluster;
const getConversationContextSelector = state =>
  state.results.conversationContext;
const getDisplayFiltersBySidSelector = sid =>
  createSelector(
    getDisplayFiltersSelector,
    displayFilters => displayFilters && displayFilters[sid],
  );

export {
  getSearchResultsStatusSelector,
  getSearchResultsSelector,
  getSearchResultsTotalCountSelector,
  getSearchesStatusSelector,
  getRecentSearchesStatusSelector,
  getPostSearchesStatusSelector,
  getSearchesSelector,
  getSearchResultsBySidStatusSelector,
  getSearchResultsSidSelector,
  getCompanySelector,
  getGenerateLinkStatusSelector,
  getGeneratedLink,
  getCompanyStatusSelector,
  getSearchResultsTotalPagesSelector,
  getvoteMlResultSelector,
  getSearchResultsPaginationStatusSelector,
  getSearchResultsBySidPaginationStatusSelector,
  getCurrentPageSelector,
  getSearchTypeSelector,
  getShareableLinkStatusSelector,
  getShareableLinkStateSelector,
  getSearchesEditSearchStatusSelector,
  getLookalikeBannerHiddenSelector,
  getResultsCountSelector,
  getCompaniesChartSelector,
  getCompaniesChartStatusSelector,
  getTeamSizeChartStatusSelector,
  getTeamSizeChartSelector,
  getTeamsizeChartUnspecifiedAllSelector,
  getTotalSearchesCountSelector,
  getTotalSearchesPagesSelector,
  getLikedCompaniesSelector,
  getLikedCompaniesStatusSelector,
  getLikedCompaniesTotalCountSelector,
  getLikedCompaniesCurrentPageSelector,
  getLikedCompaniesTotalPagesSelector,
  getResultsFilterStats,
  getResultsSearchQuery,
  getLikedFilterStats,
  getResultsCountStatusSelector,
  getDisplayFiltersSelector,
  getDisplayFiltersBySidSelector,
  lastSearchNamedSelector,
  getPostSearchesMessageSelector,
  getActiveViewSelector,
  getCurrentSearchSidSelector,
  getCurrentSearchSelector,
  getClusterSelector,
  getConversationContextSelector,
};
