import { call } from "redux-saga/effects";
import axios from "axios";
import { GET_COUNTRIES_URL } from "../../../constants/endpoints";

function* getCountries(regions) {
  let url;
  url =
    regions && regions.length > 0
      ? `${GET_COUNTRIES_URL}?regions=${regions.join(",")}`
      : `${GET_COUNTRIES_URL}`;
  const response = yield call(axios, {
    method: "GET",
    url,
  });
  return response.data;
}

export { getCountries };
