import { createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(({ palette, spacing, shape, breakpoint }) =>
  createStyles({
    dialogRoot: {
      display: "flex",
      justifyContent: "center",
      paddingLeft: 0,
      paddingRight: 0,
      textAlign: "center",
    },
    dialog: {
      padding: spacing(4),
      background: palette.primary.backgroundLight,
      borderRadius: spacing(2),
      minHeight: 368,
      width: 640,
      [breakpoint.down("md")]: {
        width: 528,
      },
      [breakpoint.down("sm")]: {
        width: "100%!important",
        borderRadius: "unset",
        padding: spacing(2),
      },
    },
    dialogFilter: {
      padding: spacing(3),
      background: palette.primary.backgroundLight,
      borderRadius: shape.borderRadius,
    },
    dialogScrollPaper: {
      maxWidth: 800,
    },
    closeButton: {
      position: "absolute !important",
      right: spacing(4),
      top: spacing(3),
      color: palette.grey[500],
      [breakpoint.down("sm")]: {
        top: 11,
        right: 15,
      },
    },
    sureText: {
      color: palette.secondary.dark,
      marginTop: spacing(4),
    },
    actionText: {
      color: palette.secondary.dark,
      marginTop: spacing(4),
    },
    removeButton: {
      border: "none",
      background: palette.error.main,
      color: palette.common.white,
      width: 213,
      "&:hover": {
        border: "none",
        background: palette.error.main,
      },
      marginTop: spacing(5),
    },
    noButton: {
      border: "none",
      background: palette.secondary.dark,
      color: palette.common.white,
      marginLeft: spacing(2),
      width: 187,
      "&:hover": {
        border: "none",
        background: palette.secondary.dark,
      },
      marginTop: spacing(5),
    },
    flexCenter: {
      display: "flex",
      justifyContent: "center",
      [breakpoint.down("sm")]: {
        flexDirection: "column-reverse",
        alignItems: "center",
      },
    },
    flexColumn: {
      flexDirection: "column",
    },
  }),
);
