import { useEffect, useState } from "react";
//Redux
import { useDispatch, useSelector } from "react-redux";
import {
  sendEmailVerificationRequest,
  startFreeTrialRequest,
  updateFreeTrialRequest,
} from "../../../store/freeTrial/requests";
import {
  emailVerificationSelector,
  freeTrialStateSelector,
  freeTrialStatusSelector,
  isEmailChangeSelector,
} from "../../../store/freeTrial/selectors";
import { REQUEST_SUCCESS } from "../../../constants/statuses";
import { useIntercom } from "react-use-intercom";

export const useFreeTrialHook = ({ user }) => {
  const dispatch = useDispatch();
  const trialStateSelector = useSelector(freeTrialStateSelector);
  const trialStatusSelector = useSelector(freeTrialStatusSelector);
  const [freeTrialState, setFreeTrialState] = useState(trialStateSelector);
  const [showChangeEmailModal, setShowChangeEmailModal] = useState(false);
  const [showOnboardingTrialModal, setShowOnboardingTrialModal] =
    useState(false);
  const [freeEmailInputError, setFreeEmailInputError] = useState(false);
  const [showEmailValidateModal, setShowEmailValidateModal] = useState(false);
  const [showPlanEndedModal, setShowPlanEndedModal] = useState(false);
  const emailChangeSelector = useSelector(isEmailChangeSelector);
  const emailVerificationStatusSelector = useSelector(
    emailVerificationSelector,
  );
  const { show, hide } = useIntercom();
  const [showIntercom, setShowIntercom] = useState(false);
  useEffect(() => {
    setFreeTrialState(trialStateSelector);
  }, [trialStateSelector]);
  useEffect(() => {
    if (freeTrialState) {
      switch (freeTrialState.status_message) {
        case "FREE_EMAIL":
          setShowChangeEmailModal(true);
          setFreeEmailInputError(true);

          break;
        case "INVALID_EMAIL":
          setShowChangeEmailModal(true);
          break;
        case "EMAIL_NOT_VERIFIED":
          setTimeout(() => {
            setShowEmailValidateModal(true);
          }, 500);
          break;
        default:
        // setFreeEmailInputError(false);
      }
    }
  }, [freeTrialState]);
  useEffect(() => {
    user &&
      user.subscription &&
      user.subscription.free_trial &&
      user.subscription.free_trial.show_onboarding &&
      setShowOnboardingTrialModal(true);
    user &&
      user.subscription &&
      user.subscription.free_trial &&
      !user.subscription.free_trial.is_active &&
      !user.subscription.free_trial.continue_free &&
      setShowPlanEndedModal(true);
  }, [user]);
  useEffect(() => {
    emailVerificationStatusSelector === REQUEST_SUCCESS &&
      setShowEmailValidateModal(false);
  }, [emailVerificationStatusSelector]);

  const handleStartTrial = email => {
    setShowChangeEmailModal(false);
    dispatch(startFreeTrialRequest({ data: { email: email || user.email } }));
  };
  const changeEmailAction = email => {
    setShowChangeEmailModal(false);
    dispatch(
      startFreeTrialRequest({
        data: { email: email || user.email, isEmailChange: true },
      }),
    );
  };
  const handleSendVerificationEmail = () => {
    dispatch(sendEmailVerificationRequest());
  };
  const handleClosingOnboardingFreeTrialModal = () => {
    setShowOnboardingTrialModal(false);
    dispatch(updateFreeTrialRequest({ data: { show_onboarding: false } }));
  };
  const talkToSales = () => {
    setShowIntercom(!showIntercom);
    if (process.env.REACT_APP_ENV === "prod") {
      show();
    }
  };

  return {
    freeTrialState,
    trialStatusSelector,
    handleStartTrial,
    showChangeEmailModal,
    setShowChangeEmailModal,
    showOnboardingTrialModal,
    setShowOnboardingTrialModal,
    freeEmailInputError,
    showEmailValidateModal,
    setShowEmailValidateModal,
    handleClosingOnboardingFreeTrialModal,
    showPlanEndedModal,
    setShowPlanEndedModal,
    changeEmailAction,
    emailChangeSelector,
    handleSendVerificationEmail,
    talkToSales,
  };
};
