import { useEffect, useState } from "react";

export const useScrollTo = () => {
  const [upArrowVisible, setUpArrowVisible] = useState(false);
  const handleScroll = () => {
    window.innerHeight < window.scrollY
      ? setUpArrowVisible(true)
      : setUpArrowVisible(false);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: false });
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return { scrollToTop, scrollToBottom, upArrowVisible };
};
