import { createSelector } from "reselect";

const getUserSelector = state => state && state.auth && state.auth.user;
const getLoginMessageSelector = state => state.auth.loginMessage;
const getRegisterMessageSelector = state => state.auth.registerMessage;
const getResetPasswordMessageSelector = state =>
  state.auth.resetPasswordMessage;
const getResetPasswordStatusSelector = state => state.auth.resetPasswordStatus;
const getSendResetLinkMessageSelector = state =>
  state.auth.sendResetLinkMessage;
const getSendResetLinkStatusSelector = state => state.auth.sendResetLinkStatus;
const getCheckResetLinkMessageSelector = state =>
  state.auth.checkResetLinkMessage;
const getCheckResetLinkStatusSelector = state =>
  state.auth.checkResetLinkStatus;
const getAccessTokenSelector = state => state.auth.accessToken;
const getLoginStatusSelector = state => state.auth.postLoginStatus;
const getLogoutStatusSelector = state => state.auth.postLogoutStatus;
const getUserStatusSelector = state => state.auth.getMeStatus;
const getRegisterStatusSelector = state => state.auth.registerStatus;
const getUpdateProfileStatusSelector = state =>
  state.auth.postUpdateProfileStatus;
const getRegisterFieldMessageSelector = state =>
  state.auth.registerFieldMessage;
const getCookieStatusSelector = state => state.auth.getCookieStatus;
const getCheckOutUrlSelector = state => state.auth.checkOutUrl;
const getSubscribeStatusSelector = state => state.auth.subscribeStatus;
const getFetchTeamStatusSelector = state => state.auth.getTeamStatus;
const getTeamSelector = state => state.auth.team;
const getRemoveMemberStatusSelector = state => state.auth.removeMemberStatus;
const getRemoveInvitationStatusSelector = state =>
  state.auth.removeInvitationStatus;
const getFetchInvitationStatusSelector = state =>
  state.auth.fetchInvitationStatus;
const getInvitationSelector = state => state.auth.invitation;
const getRegisterFromResultsSelector = state => state.auth.registerFromResults;
const getHandleInvitationSelector = state => state.auth.handleInvitation;
const getSubscribeErrorMessageSelector = state =>
  state.auth.subscribeErrorMessage;
const getAccountStatusSelector = state => state.auth.getAccountStatus;
const getLeaveTeamStatusSelector = state => state.auth.leaveTeamStatus;
const getHandleInvitationStatusSelector = state =>
  state.auth.handleInvitationStatus;
const getCompleteProfileModalShowSelector = state =>
  state.auth.complete_profile_modal_show;
const getOpenClusterMapStatusSelector = state =>
  state.auth.openClusterMapStatus;
const getCreditsSelector = () =>
  createSelector(getUserSelector, user => user && user.credits);
const getSeatsAvailableSelector = state => state.auth.seats_available;
const getSubscriptionSelector = () =>
  createSelector(getUserSelector, user => user && user.subscription);
const getNextBillingDateSelector = () =>
  createSelector(
    getSubscriptionSelector,
    subscription => subscription && subscription.next_billing_date,
  );
const getSubscriptionPlanSelector = () =>
  createSelector(
    getSubscriptionSelector,
    subscription => subscription && subscription.name,
  );

export {
  getUserSelector,
  getAccessTokenSelector,
  getLoginStatusSelector,
  getLogoutStatusSelector,
  getUpdateProfileStatusSelector,
  getRegisterStatusSelector,
  getUserStatusSelector,
  getLoginMessageSelector,
  getRegisterMessageSelector,
  getResetPasswordStatusSelector,
  getResetPasswordMessageSelector,
  getSendResetLinkStatusSelector,
  getSendResetLinkMessageSelector,
  getCheckResetLinkMessageSelector,
  getCheckResetLinkStatusSelector,
  getRegisterFieldMessageSelector,
  getCookieStatusSelector,
  getCreditsSelector,
  getNextBillingDateSelector,
  getSubscriptionPlanSelector,
  getCheckOutUrlSelector,
  getSubscribeStatusSelector,
  getSubscriptionSelector,
  getSubscribeErrorMessageSelector,
  getTeamSelector,
  getFetchTeamStatusSelector,
  getRemoveMemberStatusSelector,
  getRemoveInvitationStatusSelector,
  getFetchInvitationStatusSelector,
  getInvitationSelector,
  getHandleInvitationSelector,
  getAccountStatusSelector,
  getLeaveTeamStatusSelector,
  getCompleteProfileModalShowSelector,
  getSeatsAvailableSelector,
  getRegisterFromResultsSelector,
  getHandleInvitationStatusSelector,
  getOpenClusterMapStatusSelector,
};
