// Library
import React, { useEffect } from "react";
import { Route, useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
// Hooks
import { useAuthHook } from "./common/useAuthHook";
// Constants
import { REQUEST_FAILURE, REQUEST_UNDEFINED } from "./constants/statuses";
// Redux
import { getMeRequest } from "./store/auth/requests";

const PrivateRoute = ({ component: Component, path, ...rest }) => {
  const { getMeStatus } = useAuthHook();
  const history = useHistory();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const cid = query.get("cid");
  const dispatch = useDispatch();

  useEffect(() => {
    const fn = async () => {
      if (getMeStatus === REQUEST_UNDEFINED) {
        await dispatch(getMeRequest());
      } else if (getMeStatus === REQUEST_FAILURE) {
        await history.replace("/sign-in", {
          from: "search-type",
          someOtherProp: "",
          cid: cid,
          guestSignIn: !cid,
        });
      }
    };
    fn();
  }, [getMeStatus]);

  return <Route path={path} {...rest} />;
};

export default PrivateRoute;
