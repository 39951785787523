import React from "react";

export const ViceGrip = ({ strokeColor = "#C2C9D7" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.7852 17.5472L15.6455 16.6868C16.0586 16.2719 16.0586 15.6011 15.6455 15.1862L9.69906 9.2317C9.28414 8.8186 8.61336 8.8186 8.19844 9.2317L4.95509 12.468C4.54199 12.883 4.54199 13.5538 4.95509 13.9687L8.86772 17.8823C9.28309 18.2943 9.95297 18.2943 10.3683 17.8823L11.6479 16.6018L13.4836 17.7022C13.9016 17.9558 14.4385 17.8919 14.7852 17.5472Z"
      stroke={strokeColor}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.1616 12.7023L16.7751 9.0878L19.1361 11.6959C19.5508 12.1095 20.222 12.1095 20.6367 11.6959L21.253 11.0796C21.5773 10.7568 21.658 10.2623 21.453 9.85312L19.9744 5.9515L17.8145 3.7906C17.354 3.33022 16.6074 3.33022 16.1468 3.7906L10.1934 9.74408"
      stroke={strokeColor}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.45941 14.4766L2.77929 17.1577C2.55796 17.3787 2.43359 17.6787 2.43359 17.9915C2.43359 18.3043 2.55796 18.6043 2.77929 18.8254L4.05883 20.1049C4.27988 20.3262 4.57986 20.4506 4.89267 20.4506C5.20549 20.4506 5.50547 20.3262 5.72652 20.1049H5.75553L8.42164 17.4388"
      stroke={strokeColor}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
