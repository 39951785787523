import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
// Hooks
import { useStyles } from "./style";
import { useTranslation } from "react-i18next";
import Modal from "../../../components/Modal/Modal";
import { useAppEventTracking } from "../../../common/useAppEventTracking";
//assets
import FirstSlide from "../../../assets/images/01-Visual-Free-Trial.gif";
import SecondSlide from "../../../assets/images/05-Visual-Free-Trial.gif";
import ThirdSlide from "../../../assets/images/06-Visual-Free-Trial.gif";
import FourthSlide from "../../../assets/images/07-Visual-Free-Trial.gif";
import { ReactComponent as ArrowNext } from "../../../assets/icons/arrow-next.svg";
import { ReactComponent as ArrowBack } from "../../../assets/icons/arrow-back.svg";
//Components
import Typography from "../../../components/Typography/Typography";
import Button from "../../../components/Button/Button";
import { useTheme } from "@material-ui/core";
// Redux
import { actions as authActions } from "../../../store/auth/slice";
import { getSearchesSelector } from "../../../store/results/selectors";
import {
  getSubscriptionSelector,
  getUpdateProfileStatusSelector,
} from "../../../store/auth/selectors";
// Constants
import { REQUEST_SUCCESS } from "../../../constants/statuses";

const OnboardingFreeTrialModal = ({
  onClose,
  onInvite,
  dialogOpened,
  user,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isOwner, setIsOwner] = useState();
  const { intercomTrackEvent } = useAppEventTracking();
  const dispatch = useDispatch();
  const searchesSelector = useSelector(getSearchesSelector);
  const subscription = useSelector(getSubscriptionSelector());
  const getUpdateProfileStatus = useSelector(getUpdateProfileStatusSelector);
  const [isHandleInviteTriggered, setIsHandleInviteTriggered] = useState(false);

  useEffect(() => {
    setIsOwner(user && user.team && user.team.role === "owner");
  });

  const theme = useTheme();

  const NUMBER_OF_SLIDES = isOwner ? 4 : 3;
  let items = [];

  for (let i = 1; i <= NUMBER_OF_SLIDES; i++) {
    const prefix = isOwner
      ? `onboardingFreeTrial.slides.${i}.owner`
      : `onboardingFreeTrial.slides.${i}.invitedUser`;
    const images = {
      1: FirstSlide,
      2: SecondSlide,
      3: ThirdSlide,
      4: FourthSlide,
    };

    items.push({
      img: images[i],
      title: t(`${prefix}.title`),
      desc1: {
        text: t(`${prefix}.description1.text`),
        link: t(`${prefix}.description1.link`),
      },
      desc2: {
        text: t(`${prefix}.description2.text`),
        link: t(`${prefix}.description2.link`),
      },
      desc3: {
        text: t(`${prefix}.description3.text`),
        link: t(`${prefix}.description3.link`),
      },
      btn1: t(`${prefix}.button1`),
      btn2: t(`${prefix}.button2`),
    });
  }

  const [slideIndex, setSlideIndex] = useState(0);

  const handleOnContinueClick = () => {
    let idx = slideIndex;
    slideIndex === items.length - 1 ? (idx = 0) : idx++;
    setSlideIndex(idx);
  };
  const handleOnBackClick = () => {
    let idx = slideIndex;
    idx--;
    setSlideIndex(idx);
  };
  const handleCloseOnboarding = () => {
    onClose();
    setSlideIndex(0);
    intercomTrackEvent("product tour ready");
  };
  const handleDescriptionLinkClick = (type, e) => {
    e.preventDefault();

    if (type === "invite") {
      handleInvite();
    }
  };

  const handleInvite = () => {
    if (
      user &&
      !user.company &&
      subscription &&
      (subscription.name === "free" || subscription.free_trial) &&
      searchesSelector.length > 1
    ) {
      onClose();
      dispatch(authActions.completeProfileModalShowTrue());
      setIsHandleInviteTriggered(true);
    } else {
      onClose();
      onInvite(true);
      setSlideIndex(0);
    }
  };

  useEffect(() => {
    if (getUpdateProfileStatus === REQUEST_SUCCESS && isHandleInviteTriggered) {
      onClose();
      onInvite();
      setSlideIndex(0);
    }
  }, [getUpdateProfileStatus]);

  return (
    <Modal
      background={theme.palette.primary.backgroundMedium}
      dialogOpened={dialogOpened}
      onClose={handleCloseOnboarding}
      borderRadius="16px"
      maxWidth="593px"
      minHeight="540px"
      maxHeight="540px">
      <div className={classes.root}>
        <div className={classes.main}>
          {items.map(
            (item, i) =>
              i === slideIndex && (
                <div className={classes.slideWrap} key={`${i}-slide`}>
                  {item.img ? (
                    <img
                      style={{
                        maxHeight: "118px",
                        minHeight: "118px",
                      }}
                      className={classes.slideImg}
                      src={item.img}
                    />
                  ) : (
                    <div
                      style={{
                        width: "100%",
                        height: "118px",
                      }}
                    />
                  )}

                  <Typography
                    className={classes.slideTitle}
                    variant="subtitle1">
                    {item.title}
                  </Typography>

                  <Typography
                    className={classes.slideDescription}
                    variant="bodySmall">
                    {item.desc1.link.length > 0 && (
                      <a onClick={e => handleDescriptionLinkClick("invite", e)}>
                        {item.desc1.link}
                      </a>
                    )}
                    {item.desc1.text}
                    <br />
                    {item.desc2.link.length > 0 && (
                      <a onClick={e => handleDescriptionLinkClick("", e)}>
                        {item.desc2.link}
                      </a>
                    )}
                    {item.desc2.text}
                    <br />
                    {item.desc3.link.length > 0 && (
                      <a onClick={e => handleDescriptionLinkClick("", e)}>
                        {item.desc3.link}
                      </a>
                    )}
                    {item.desc3.text}
                  </Typography>
                </div>
              ),
          )}

          <div className={classes.stepper}>
            <div className={classes.backBtnWrapper}>
              {slideIndex > 0 && (
                <>
                  <Button
                    className={classes.buttonBack}
                    onClick={handleOnBackClick}
                    variant="quaternary">
                    <ArrowBack className={classes.arrowBack} />
                    {items[slideIndex].btn1}
                  </Button>
                </>
              )}
            </div>
            <div className={classes.dotsWrapp}>
              {items.map((item, i) => (
                <span
                  key={`${i}-slide-index`}
                  className={classnames([
                    classes.dot,
                    {
                      [classes.activeDot]: i === slideIndex,
                    },
                  ])}></span>
              ))}
            </div>
            <div className={classes.nextBtnWrapper}>
              <Button
                className={classes.buttonNext}
                onClick={() => {
                  slideIndex === NUMBER_OF_SLIDES - 1
                    ? handleCloseOnboarding()
                    : handleOnContinueClick();
                }}
                variant="primary">
                {items[slideIndex].btn2}
                {slideIndex < NUMBER_OF_SLIDES - 1 && (
                  <>
                    <ArrowNext className={classes.arrowNext} />
                  </>
                )}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default OnboardingFreeTrialModal;
