import { createStyles, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(({ palette, spacing, shape }) =>
  createStyles({
    headerWrap: {
      display: "inline-flex",
      width: "100%",
      justifyContent: "space-between",
    },
    buttons: {
      display: "inline-flex",
    },
    actionIconWrapperRemove: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      flex: 0,
      "&:hover": {
        cursor: "pointer",
        "& $actionIcon": {
          // width: 24,
          // height: 24
        },
        "& $actionText": {
          fontWeight: "bold",
        },
      },
    },
    actionIconContent: {
      // height: 24,
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
    },
    actionIcon: {},
    actionText: {
      textAlign: "center",
      // lineHeight: "12px",
      // minHeight: 16,
      marginTop: 4,
    },
    editProjectBtn: {
      marginLeft: spacing(2),
    },
  }),
);
