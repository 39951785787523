import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
// Hooks
import { useLocation } from "react-router-dom";
import { useParams } from "react-router";
import { useStyles } from "./style.js";
import { useProjectProfileHook } from "../hooks/useProjectProfileHook";
import { useAppEventTracking } from "../../../common/useAppEventTracking";
import useFilters from "../../../common/useFilters.js";
import useResults from "../../../common/useResults.js";
import { useScrollTo } from "../../../common/useScrollTo";
import { objectHasEmptyValues } from "../../../utils/objectHasEmptyValues";
//UI components
import Typography from "../../../components/Typography/Typography";
import ProjectHeader from "./Header/ProjectHeader";
import Card from "../../../components/Card/Card";
import ContentLoader from "../../../components/ContentLoader/ContentLoader.js";
import AreYouSureModal from "../../../components/AreYouSureModal/AreYouSureModal";
import Header from "../../Technologies/ProfileCompanies/components/Header/Header";
import GraphsContainer from "../../../components/GraphsContainer/GraphsContainer";
import Pagination from "../../../components/Pagination/Pagination";
import { WorkspaceListView } from "../../../components/WorkspaceListView/WorkspaceListView";
import NoResults from "../../Results/components/NoResults/NoResults";
// Redux
import { useSelector } from "react-redux";
import { fetchProjectRequest } from "../../../store/projects/requests";
import { exportCsvRequest } from "../../../store/orders/requests";
import { actions as resultsActions } from "../../../store/results/slice";
import {
  getFilterProjectCompaniesTotalPagesSelector,
  getProjectCompaniesChartSelector,
  getProjectCompaniesFilterStats,
  getProjectCompaniesTeamSizeChartSelector,
  getProjectCompaniesTeamSizeChartUnspecifiedAllSelector,
  getProjectCompaniesTotalCountSelector,
} from "../../../store/projects/selectors";
import { getCountriesSelector } from "../../../store/countries/selectors";
import {
  getCheckOutUrlSelector,
  getSubscribeStatusSelector,
  getUserStatusSelector,
} from "../../../store/auth/selectors";
import {
  getSectorsSelector,
  getSubsectorsSelector,
} from "../../../store/sectors/selectors";
import { getActiveViewSelector } from "../../../store/results/selectors";
//Constants
import { REQUEST_PENDING, REQUEST_SUCCESS } from "../../../constants/statuses";
import { getPricingPage } from "../../../constants/endpoints";
import {
  filterParamMapping,
  fundingOptionsMin,
  regionsOptions,
  stageOptions,
  teamsizeOptions,
  valuerScoreOptionsMin,
} from "../../../constants/filters";
//Shared
import { Shared } from "../../../utils/shared.js";
// Assets
import ProjectInfoTitleIcon from "../../../assets/icons/ProjectInfoTitleIcon.svg";

const ProjectProfile = () => {
  const classes = useStyles();
  const { mixpanelTrackEvent } = useAppEventTracking();
  let { search } = useLocation();
  const { token } = useParams();
  const localStorageSlugPrefix = "project";
  const urlParams = new URLSearchParams(search);
  const searchTypeLook = urlParams.get("searchTypeLook");
  const tableToolbarRef = useRef(null);
  const [overviewVisibility, setOverviewVisibility] = useState(false);
  const projectCompaniesChart = useSelector(getProjectCompaniesChartSelector);
  const projectCompaniesTeamSizeChart = useSelector(
    getProjectCompaniesTeamSizeChartSelector,
  );
  const unspecifiedAll = useSelector(
    getProjectCompaniesTeamSizeChartUnspecifiedAllSelector,
  );
  const projectCompaniesFilterStats = useSelector(
    getProjectCompaniesFilterStats,
  );
  const allResults = [];
  const [timeout, setTimeoutVal] = useState(null);
  const getProjectCompaniesTotalCount = useSelector(
    getProjectCompaniesTotalCountSelector,
  );
  const getFilterProjectCompaniesTotalPages = useSelector(
    getFilterProjectCompaniesTotalPagesSelector,
  );
  const [totalCount, setTotalCount] = useState(getProjectCompaniesTotalCount);
  const allCountries = useSelector(getCountriesSelector);
  const allSubsectors = useSelector(getSubsectorsSelector);
  const getMeStatus = useSelector(getUserStatusSelector);
  const checkOutUrl = useSelector(getCheckOutUrlSelector);
  const subscribeStatus = useSelector(getSubscribeStatusSelector);
  const [isAuthed, setIsAuthed] = useState(true);
  const { scrollToTop, upArrowVisible } = useScrollTo();
  //check this
  const [totalPages, setTotalPages] = useState(
    getFilterProjectCompaniesTotalPages,
  );
  const allSectors = useSelector(getSectorsSelector);
  const [isLess768, setIsLess768] = useState(false);
  const [selected, setSelected] = useState([]);
  const [selectedNames, setSelectedNames] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [tableToolbarClassName, setTableToolbarClassName] = useState(
    classes.header,
  );

  const {
    isAuthenticated,
    userLoading,
    user,
    t,
    tagsWrapperRef,
    slug,
    deleteCompanyStatus,
    enrichCompanyStatus,
    toggleProjectsStatus,
    projectStatusSelector,
    loading,
    setLoading,
    project,
    setAllSectors,
    dispatch,
    setProjectActive,
    // allSectors,
    openPage,
    openRemoveWarningModal,
  } = useProjectProfileHook({});
  const [searchResults, setSearchResults] = useState(project?.items);
  const [isInitialization, setIsInitilaization] = useState(true);
  const activeView = useSelector(getActiveViewSelector);
  const setActiveView = () => {
    dispatch(resultsActions.setActiveView("card"));
  };
  const [loadingViewSwitch, setLoadingViewSwitch] = useState(false);

  useEffect(() => {
    !loading && setLoadingViewSwitch(false);
  }, [loading]);

  useEffect(() => {
    project &&
      project.items &&
      project.items &&
      setSearchResults(project.items);
  }, [project]);

  useLayoutEffect(() => {
    let scrollPosition = sessionStorage.getItem(
      `project-${slug}ScrollPosition`,
    );
    setTimeout(() => {
      scrollPosition &&
        searchResults &&
        searchResults.length &&
        scrollTo(Number(scrollPosition), () =>
          sessionStorage.removeItem(`project-${slug}ScrollPosition`),
        );
    }, 100);
  }, [searchResults]);

  const switchView = view => {
    setLoadingViewSwitch(true);
    setPage(1);
    dispatch(resultsActions.setActiveView(view));
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = searchResults.map(n => n.id);
      const newSelectedNames = searchResults.map(n => n.name);
      setSelected(newSelecteds);
      setSelectedCompanies(searchResults);
      setSelectedNames(newSelectedNames);
      return;
    }
    setSelected([]);
    setSelectedNames([]);
    setSelectedCompanies([]);
  };

  const handleOnSortFilterChange = data => {
    // const storageResultsFilterSlug = sessionStorage.getItem(`technology-${slug}-FilterSlug`);
    // const storageResultsFilterPage = sessionStorage.getItem(`technology-${slug}-FilterPage`);
    // const storageResultsFilterSortDirection = sessionStorage.getItem(`technology-${slug}-FilterSortDirection`);
    // const storageResultsFilterSortValue = sessionStorage.getItem(`technology-${slug}-FilterSortValue`);
    const storageResultsFilterSector = sessionStorage.getItem(
      `technology-${slug}-FilterSector`,
    );
    const storageResultsFilterSubsector = sessionStorage.getItem(
      `technology-${slug}-FilterSubsector`,
    );
    const storageResultsFilterSubsectors = sessionStorage.getItem(
      `technology-${slug}-FilterSubsectors`,
    );
    const storageResultsFilterStage = sessionStorage.getItem(
      `technology-${slug}-FilterStage`,
    );
    const storageResultsFilterFundingFrom = sessionStorage.getItem(
      `technology-${slug}-FilterFundingFrom`,
    );
    const storageResultsFilterFundingTo = sessionStorage.getItem(
      `technology-${slug}-FilterFundingTo`,
    );
    const storageResultsFilterTeamSizeMin = sessionStorage.getItem(
      `technology-${slug}-FilterTeamSizeMin`,
    );
    const storageResultsFilterTeamSizeMax = sessionStorage.getItem(
      `technology-${slug}-FilterTeamSizeMax`,
    );
    const storageResultsFilterCountry = sessionStorage.getItem(
      `technology-${slug}-FilterCountry`,
    );
    const storageResultsFilterFoundedFrom = sessionStorage.getItem(
      `technology-${slug}-FilterFoundedFrom`,
    );
    const storageResultsFilterFoundedTo = sessionStorage.getItem(
      `technology-${slug}-FilterFoundedTo`,
    );
    const storageResultsFilterValuerScoreFrom = sessionStorage.getItem(
      `technology-${slug}-FilterValuerScoreFrom`,
    );
    const storageResultsFilterValuerScoreTo = sessionStorage.getItem(
      `technology-${slug}-FilterValuerScoreTo`,
    );
    const storageResultsFilterValuerRegions = sessionStorage.getItem(
      `technology-${slug}-FilterValuerRegions`,
    );
    const storageResultsFilterSearch = sessionStorage.getItem(
      `technology-${slug}-FilterSearch`,
    );
    const storageResultsFilters = {
      sector: storageResultsFilterSector || "",
      subsector: storageResultsFilterSubsector || "",
      subsectors: storageResultsFilterSubsectors || "",
      stage: storageResultsFilterStage || "",
      fundingMin: storageResultsFilterFundingFrom || "",
      fundingMax: storageResultsFilterFundingTo || "",
      teamsizeMin: storageResultsFilterTeamSizeMin || "",
      teamsizeMax: storageResultsFilterTeamSizeMax || "",
      location: storageResultsFilterCountry || "",
      regions: storageResultsFilterValuerRegions || "",
      yearMin: storageResultsFilterFoundedFrom || "",
      yearMax: storageResultsFilterFoundedTo || "",
      matchingScoreMin: storageResultsFilterValuerScoreFrom || "",
      matchingScoreMax: storageResultsFilterValuerScoreTo || "",
      search: storageResultsFilterSearch || "",
    };
    let storageResultsFilterNameValue;

    if (!objectHasEmptyValues(storageResultsFilters)) {
      let hasFilter = false;
      let value = "";

      Object.keys(storageResultsFilters).forEach(filter => {
        if (storageResultsFilters[filter] !== "") {
          hasFilter = true;
          value = value.concat(
            `${filterParamMapping[filter]}=${storageResultsFilters[filter]}&`,
          );
          storageResultsFilterNameValue = value;
        }
      });
      if (!hasFilter) {
        storageResultsFilterNameValue = "";
      }
      hasFilter = false;
    }

    if (!isInitialization) {
      if (storageResultsFilterNameValue) {
        dispatch(
          fetchProjectRequest({
            slug,
            data: {
              ...data.data,
              filterNameValue: storageResultsFilterNameValue,
            },
            clearResults: true,
            isGuest: !isAuthenticated,
          }),
        );
      } else {
        dispatch(
          fetchProjectRequest({
            slug,
            data: data.data,
            clearResults: true,
            isGuest: !isAuthenticated,
          }),
        );
      }
    }
  };

  // Filtering
  const {
    filters,
    selectedFilters,
    setDisplayedFilters,
    filterNameValue,
    resetFilters,
    handleSectorChange,
    handleSubsectorChange,
    handleFundingMinChange,
    handleYearMinChange,
    handleMatchingScoreMinChange,
    resetFilter,
    setModalFilters,
    setSelectedFilters,
    displayedFilters,
    fundingOptionsMax,
    valuerScoreOptionsMax,
    handleTeamSizeMinChange,
    teamSizeOptionsMax,
    handleFieldChange,
    handleStageChange,
    handleLocationChange,
    handleShowResults,
    handleShowFilters,
    setIsFilterFetching,
    isFilterFetching,
    setPage,
    page,
    handleSortChange,
    sortValue,
    sortDirection,
    handleSectorsClose,
    handleSubsectorsClose,
    preselectedSectors,
    setPreselectedSectors,
    preselectedSubsectors,
    setPreselectedSubsectors,
    handleRegionChange,
    filterMapping,
  } = useFilters({
    isAuthenticated,
    userLoading,
    allSectors,
    setTotalPages,
    getFilterResultsTotalPages: getFilterProjectCompaniesTotalPages,
    getSearchResultsTotalPages: getFilterProjectCompaniesTotalPages,
    onFilterNameValueChange: handleOnSortFilterChange,
    slug,
    searchTypeLook,
    currentSearchSid: slug,
    request: fetchProjectRequest,
    token,
    localStorageSlugPrefix,
    isIndustry: false,
    countRequest: fetchProjectRequest,
    isTechnology: false,
    isProject: true,
    activeView,
  });

  const {
    isFetching,
    pageLoading,
    isSubscribeDialogOpened,
    deselectAll,
    addItemToProjectModal,
    setIsSubscribeDialogOpened,
    confirmSubscription,
    handleSelectingCardIndex,
    selectedCardIndex,
    moreData,
  } = useResults({
    slug,
    totalCount,
    searchResults,
    isAuthenticated,
    getMeStatus,
    search,
    checkOutUrl,
    subscribeStatus,
    page,
    selectedCompanies,
    getFilteringState: searchResults,
    setSelectedCompanies,
    totalPages,
    filterNameValue,
    setPage,
    setIsAuthed,
    sortValue,
    sortDirection,
    subsectorsQuery: "",
    isIndustry: false,
    displayedFilters,
    request: fetchProjectRequest,
    localStorageSlugPrefix: "project",
    perPage: activeView === "card" ? 10 : 50,
    activeView,
  });

  const loadingPage = page > 1 && loading;

  const handleOnSearch = () => {
    setLoading(true);
    clearTimeout(timeout);
    setPage(1);
    setTimeoutVal(
      setTimeout(() => {
        setLoading(false);
      }, 500),
    );
  };

  const [selectedSubsector, setSelectedSubsector] = useState([]);

  useEffect(() => {
    const subsectorsString =
      selectedSubsector && selectedSubsector.map(e => e.slug).join(",");
    // handleFieldChange({ target: { value: subsectorsString }}, "subsector");
    setDisplayedFilters(prevDisplayedFilters => {
      return {
        ...prevDisplayedFilters,
        subsectors: subsectorsString,
      };
    });
    setSelectedFilters(prevSelectedFilters => {
      return {
        ...prevSelectedFilters,
        subsectors: subsectorsString,
      };
    });
  }, [selectedSubsector]);

  const [showAreYouSureModal, setShowAreYouSureModal] = useState(false);
  const [showDownloadsExceededModal, setShowDownloadsExceededModal] =
    useState(false);

  useEffect(() => {
    setProjectActive(project.active);
  }, [project.active]);

  useEffect(() => {
    let data = {
      filterNameValue,
      orderBy: sortValue,
      orderDirection: sortDirection,
      page,
      perPage: activeView === "card" ? 10 : 50,
    };
    dispatch(fetchProjectRequest({ slug, data }));
  }, [dispatch, slug]);

  useEffect(() => {
    if (
      deleteCompanyStatus === REQUEST_SUCCESS ||
      enrichCompanyStatus === REQUEST_SUCCESS ||
      toggleProjectsStatus === REQUEST_SUCCESS
    ) {
      let data = {
        filterNameValue,
        orderBy: sortValue,
        orderDirection: sortDirection,
        page,
        perPage: activeView === "card" ? 10 : 50,
      };
      dispatch(fetchProjectRequest({ slug, data }));
    }
  }, [
    deleteCompanyStatus,
    enrichCompanyStatus,
    toggleProjectsStatus,
    dispatch,
    slug,
  ]);

  useEffect(() => {
    if (page > 1 && searchResults && searchResults.length === 0) {
      setPage(prevPage => prevPage - 1);
    }
  }, [page, dispatch, searchResults]);

  useEffect(() => {
    setLoading(projectStatusSelector === REQUEST_PENDING);
  }, [projectStatusSelector]);

  useEffect(() => {
    project.active && setProjectActive(project.active);
  }, [project.active, setProjectActive]);

  useEffect(() => {
    totalCount === 0 || page === totalPages || !totalPages
      ? setIsFilterFetching(false)
      : setIsFilterFetching(isFetching);
  }, [isFetching, totalCount, totalPages, page]);

  useEffect(() => {
    // if (!objectHasEmptyValues(selectedFilters) || (getFilteringState && getFilteringState.length > 0)) {
    //     setSearchResults(getFilteringState);
    // } else {
    //     setSearchResults(allResults);
    // };
    setTotalCount(getProjectCompaniesTotalCount);
    setTotalPages(getFilterProjectCompaniesTotalPages);
  }, [
    allResults,
    selectedFilters,
    getProjectCompaniesTotalCount,
    getFilterProjectCompaniesTotalPages,
  ]);

  const handleDownloadToCsv = () => {
    if (
      user.downloads === 1 &&
      (user.subscription.plan_type === "free_trial" ||
        user.subscription.plan_type === "free")
    ) {
      setShowDownloadsExceededModal(true);
    } else if (
      user.subscription.plan_type === "free_trial" &&
      user.subscription.is_active
    ) {
      setShowAreYouSureModal(true);
    } else if (
      user.subscription.plan_type === "free_trial" &&
      !user.subscription.is_active
    ) {
      window.location.href = getPricingPage(!!user);
    } else if (user.subscription.plan_type === "free") {
      setShowAreYouSureModal(true);
    } else {
      downloadToCsv();
    }
  };

  const downloadToCsv = () => {
    let payload = {
      idValue: project.rid,
      idType: "rid",
      name: project.name,
      doc: "csv",
      pageName: "project",
    };
    dispatch(exportCsvRequest(payload));
    mixpanelTrackEvent(`Downloaded document`, {
      type: payload.doc,
      page: "project",
    });
  };

  const handleResetFilters = () => {
    resetFilters();
    // clearStorage();
  };

  useEffect(() => {
    sessionStorage.setItem(
      `${localStorageSlugPrefix}-${slug}-FilterSortValue`,
      sortValue,
    );
  }, [sortValue]);

  useEffect(() => {
    sessionStorage.setItem(
      `${localStorageSlugPrefix}-${slug}-FilterSortDirection`,
      sortDirection,
    );
  }, [sortDirection]);

  const { scrollTo } = Shared({});

  useLayoutEffect(() => {
    if (searchResults && searchResults[0]) {
      dispatch(
        resultsActions.setResultsDisplayFilters({
          sid: slug,
          displayedFilters: {
            filterSid: slug,
            sortValue: sortValue || [],
            sortDirection: sortDirection || [],
            sector: selectedFilters.sector || [],
            subsector: selectedFilters.subsector || [],
            subsectors: selectedFilters.subsectors || [],
            stage: selectedFilters.stage || [],
            fundingMin: selectedFilters.fundingMin || "",
            fundingMax: selectedFilters.fundingMax || "",
            teamsizeMin: selectedFilters.teamsizeMin || "",
            teamsizeMax: selectedFilters.teamsizeMax || "",
            location: selectedFilters.location || [],
            yearMin: selectedFilters.yearMin || "",
            yearMax: selectedFilters.yearMax || "",
            matchingScoreMin: selectedFilters.matchingScoreMin,
            matchingScoreMax: selectedFilters.matchingScoreMax || "",
            regions: selectedFilters.regions || [],
            search: selectedFilters.search || "",
            storageResultsFilterNameValue: filterNameValue || "",
            storageResultsFilterPage: Number(page) || 1,
          },
        }),
      );
    }
    let scrollPosition = sessionStorage.getItem(
      `project-${slug}ScrollPosition`,
    );
    setTimeout(() => {
      scrollPosition &&
        searchResults &&
        searchResults.length &&
        scrollTo(Number(scrollPosition), () =>
          sessionStorage.removeItem(`project-${slug}ScrollPosition`),
        );
    }, 200);
  }, [searchResults, selectedFilters]);

  return (
    <div className={classes.main}>
      <ProjectHeader currentProject={project} />
      <div className={classes.infoWrap}>
        <div
          className={classes.projectInfoWrapper}
          data-intercom-target={
            project && project.items && project.items.length > 0
              ? "Project with saved items - summary"
              : "Empty project - summary"
          }>
          <div className={classes.projectInfo}>
            <div className={classes.projectInfoContent}>
              <div className={classes.projectInfoContentTitle}>
                <img src={ProjectInfoTitleIcon} alt="ProjectInfoTitleIcon" />
                <Typography
                  className={classes.projectInfoContentTitleText}
                  variant="subtitle1"
                  color="black">
                  {project?.name}
                </Typography>
              </div>
              <div className={classes.projectInfoContentDescription}>
                <Typography variant="bodyButton" color="textLighter">
                  {project?.description}
                </Typography>
              </div>
              <div className={classes.projectInfoContentTimestamp}>
                {t("projectProfile.emptyStatePage.updatedAt")}{" "}
                {project?.updated_date}
              </div>
            </div>
            <div className={classes.projectInfoComingSoon}>
              {/* <img src={ProjectShareComingSoon} alt="Project Share Coming Soon"/> */}
            </div>
          </div>
        </div>
        {project.items && project.items && !!project.items.length && (
          <Header
            onSearch={handleOnSearch}
            onFilterSelected={handleShowFilters}
            searchResults={searchResults}
            searchResultsTotal={totalCount}
            isAuthenticated={isAuthenticated}
            onSortChange={handleSortChange}
            filters={filters}
            allResults={allResults}
            total={totalCount}
            resetFilters={handleResetFilters}
            stageOptions={stageOptions}
            fundingOptionsMin={fundingOptionsMin}
            fundingOptionsMax={fundingOptionsMax}
            yearOptionsMin={
              projectCompaniesChart &&
              Object.keys(projectCompaniesChart)
                .map(year => {
                  return { name: year };
                })
                .sort()
            }
            yearOptionsMax={
              projectCompaniesChart &&
              Object.keys(projectCompaniesChart)
                .map(year => {
                  return { name: year };
                })
                .sort()
            }
            teamsizeOptions={teamsizeOptions}
            valuerScoreOptionsMin={valuerScoreOptionsMin}
            valuerScoreOptionsMax={valuerScoreOptionsMax}
            handleFieldChange={handleFieldChange}
            handleSectorChange={handleSectorChange}
            handleSubsectorChange={handleSubsectorChange}
            handleStageChange={handleStageChange}
            handleLocationChange={handleLocationChange}
            resetFilter={resetFilter}
            handleFundingMinChange={handleFundingMinChange}
            handleYearMinChange={handleYearMinChange}
            handleMatchingScoreMinChange={handleMatchingScoreMinChange}
            teamSizeOptionsMax={teamSizeOptionsMax}
            handleTeamSizeMinChange={handleTeamSizeMinChange}
            allSectors={allSectors}
            allSubsectors={allSubsectors}
            selectedFilters={selectedFilters}
            allCountries={allCountries}
            onShowResults={handleShowResults}
            displayedFilters={displayedFilters}
            setSelectedFilters={setSelectedFilters}
            setDisplayedFilters={setDisplayedFilters}
            setModalFilters={setModalFilters}
            isLess768={isLess768}
            isIndustry={false}
            isTechnology={false}
            slug={slug}
            isFilterFetching={isFilterFetching}
            request={fetchProjectRequest}
            countRequest={fetchProjectRequest}
            overviewVisibility={overviewVisibility}
            setOverviewVisibility={setOverviewVisibility}
            totalPages={totalPages}
            page={page}
            filterStats={projectCompaniesFilterStats}
            handleSectorsClose={handleSectorsClose}
            handleSubsectorsClose={handleSubsectorsClose}
            preselectedSectors={preselectedSectors}
            setPreselectedSectors={setPreselectedSectors}
            preselectedSubsectors={preselectedSubsectors}
            setPreselectedSubsectors={setPreselectedSubsectors}
            activeView={activeView}
            setActiveView={switchView}
            isProject={true}
            companiesPageId={project?.rid}
            regionsOptions={regionsOptions}
            handleRegionChange={handleRegionChange}
            filterMapping={filterMapping}
            setSelected={setSelected}
            setSelectedCompanies={setSelectedCompanies}
            setSelectedCompany={setSelectedCompany}
            setSelectedNames={setSelectedNames}
            selected={selected}
            selectedNames={selectedNames}
            selectedCompany={selectedCompany}
            selectedCompanies={selectedCompanies}
            tableToolbarRef={tableToolbarRef}
            handleSelectAllClick={handleSelectAllClick}
            tableToolbarClassName={tableToolbarClassName}
            isWorkspace={true}
          />
        )}
        {isAuthenticated &&
          projectCompaniesChart &&
          projectCompaniesTeamSizeChart &&
          Object.keys(projectCompaniesChart).length > 0 &&
          Object.keys(projectCompaniesTeamSizeChart).length > 0 && (
            <GraphsContainer
              t={t}
              chartYears={projectCompaniesChart}
              teamsizes={projectCompaniesTeamSizeChart}
              selectedFilters={selectedFilters}
              setDisplayedFilters={setDisplayedFilters}
              setSelectedFilters={setSelectedFilters}
              overviewVisibility={overviewVisibility}
              setOverviewVisibility={setOverviewVisibility}
              totalCount={totalCount}
              resetFilter={resetFilter}
              slug={slug}
              localStorageSlugPrefix="project"
              unspecifiedAll={unspecifiedAll}
              setPage={setPage}
              filterStats={projectCompaniesFilterStats}
              setSelected={setSelected}
              setSelectedCompanies={setSelectedCompanies}
              setSelectedCompany={setSelectedCompany}
              setSelectedNames={setSelectedNames}
              selected={selected}
              selectedNames={selectedNames}
              selectedCompany={selectedCompany}
              selectedCompanies={selectedCompanies}
              tableToolbarRef={tableToolbarRef}
              handleSelectAllClick={handleSelectAllClick}
              tableToolbarClassName={tableToolbarClassName}
            />
          )}
        {loading ? (
          <ContentLoader />
        ) : (
          <div className={classes.resultsContent}>
            {activeView === "list" && project.items && project.items ? (
              <WorkspaceListView
                activeView={activeView}
                setActiveView={setActiveView}
                items={project.items && project.items}
                isTechnology={false}
                isIndustry={false}
                searchResults={project.items && project.items}
                onSortChange={handleSortChange}
                sortDirection={sortDirection}
                sortValue={sortValue}
                page={page}
                matchRemoved={true}
                isProject={true}
                project={project}
                openRemoveWarningModal={openRemoveWarningModal}
                projectSlug={slug}
                searchResultsSid={
                  project &&
                  project.items &&
                  project.items &&
                  project.items[0] &&
                  project.items[0].search_id
                }
                source="project"
                isLikedCompany={false}
                id={project.rid}
                setSelected={setSelected}
                setSelectedCompanies={setSelectedCompanies}
                setSelectedCompany={setSelectedCompany}
                setSelectedNames={setSelectedNames}
                selected={selected}
                selectedNames={selectedNames}
                selectedCompany={selectedCompany}
                selectedCompanies={selectedCompanies}
                tableToolbar={tableToolbarRef}
                setTableToolbarClassName={setTableToolbarClassName}
                total={totalCount}
                totalPages={totalPages}
              />
            ) : project &&
              project.items &&
              project.items &&
              project.items.length ? (
              project.items &&
              project.items &&
              project.items.map((result, i) => (
                <Card
                  key={`result-${i}`}
                  openPage={openPage}
                  searchResultsSid={result.search_id}
                  t={t}
                  result={{ id: result.itd, ...result }}
                  isAuthenticated={isAuthenticated}
                  isProjectCompany="true"
                  isProject={false}
                  projectSlug={slug}
                  openRemoveWarningModal={openRemoveWarningModal}
                  source="project"
                  type={result.type}
                  cardIndex={i}
                  isWorkspace={true}
                  selected={selected}
                  selectedCompanies={selectedCompanies}
                  selectedNames={selectedNames}
                  setSelected={setSelected}
                  setSelectedCompanies={setSelectedCompanies}
                  setSelectedNames={setSelectedNames}
                />
              ))
            ) : (
              <NoResults
                isProjectPage={true}
                reason={"searchResults.length === 0 && !loading && noSearches "}
              />
            )}
          </div>
        )}
        {!pageLoading &&
        (!loading || loadingPage) &&
        project.items &&
        project.items &&
        project.items.length &&
        project.items.pagination &&
        project.items.pagination.total_pages > 1 ? (
          <Pagination
            count={project.items.pagination.total_pages}
            onChangePage={moreData}
            page={Number(page)}
          />
        ) : null}
      </div>
      <AreYouSureModal
        dialogOpened={showAreYouSureModal}
        onChange={() => setShowAreYouSureModal(false)}
        title={t("download.areYouSureTitle")}
        subtitle={
          <>
            {t("download.subtitleFreeTrial1")}
            <Typography
              component="div"
              className={classes.actionText}
              variant="body"
              color="secondaryDark">
              {t("download.subtitleFreeTrial2")}
            </Typography>
          </>
        }
        actionButtonText={t("download.iWantToDownload")}
        cancelButtonText={t("download.letsTryLater")}
        onActionClick={downloadToCsv}
      />
      <AreYouSureModal
        dialogOpened={showDownloadsExceededModal}
        onChange={() => setShowDownloadsExceededModal(false)}
        title={t("download.downloadsExceededTitle")}
        subtitle={t("download.downloadsExceededSubtitle")}
        actionButtonText={t("download.upgradePlan")}
        cancelButtonText={t("download.upgradeLater")}
        onActionClick={() => (window.location.href = getPricingPage(!!user))}
      />
    </div>
  );
};
export default ProjectProfile;
