import { Controls, Player } from "@lottiefiles/react-lottie-player";
import { useEffect, useState } from "react";
import LoaderJSON from "../../assets/lottie-jsons/loader.json";

const ContentLoader = ({ size, fullHeight = false }) => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    };
  }, []);

  return (
    isMounted && (
      <Player
        autoplay
        loop
        src={LoaderJSON}
        style={{
          height: size === "small" ? "200px" : fullHeight ? "95vh" : "500px",
          width:
            size === "small" ? "200px" : size === "auto" ? "100%" : "500px",
        }}>
        <Controls
          visible={false}
          buttons={["play", "repeat", "frame", "debug"]}
        />
      </Player>
    )
  );
};

export default ContentLoader;
