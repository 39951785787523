import { call, put } from "redux-saga/effects";
import { actions } from "../slice";
import { actions as resultsActions } from "../../results/slice";
import {
  checkProjectsAPI,
  checkProjectTitle,
  getProject,
  getProjects,
  postAddItemToProject,
  postDeleteCompany,
  postEnrichCompany,
  postProjects,
  postStatusProject,
  postUpdateProjects,
  removeProject,
} from "./helpers";
import {
  getCompaniesChart,
  getTeamSizeChart,
} from "../../results/saga/helpers";

function* fetchProjects() {
  try {
    yield put(actions.getProjectsPending());
    const projects = yield call(getProjects);
    yield put(actions.setProjects(projects.data));
    yield put(actions.getProjectsSuccess());
  } catch (err) {
    yield put(actions.getProjectsFailure());
  }
}

function* fetchProject(slug, data) {
  try {
    yield put(actions.getProjectPending());
    yield put(resultsActions.clearResults());
    const response = yield call(getProject, slug, data);
    yield put(actions.setProject(response.data));
    yield put(actions.getProjectSuccess());
  } catch (err) {
    yield put(actions.getProjectFailure());
  }
}

function* createProject(items, projectTitle, projectDescription, type) {
  try {
    yield put(actions.postProjectsPending());
    const response = yield call(
      postProjects,
      items,
      projectTitle,
      projectDescription,
      type,
    );
    if (response.status_code >= 400) {
      yield put(actions.postProjectsFailure(response.message));
    } else {
      yield put(actions.postProjectsSuccess(response.data));
    }
  } catch (err) {
    yield put(actions.postProjectsFailure(err.message));
  }
}

function* updateProject(oldProjectSlug, projectTitle, projectDescription) {
  try {
    yield put(actions.updateProjectPending());
    yield call(
      postUpdateProjects,
      oldProjectSlug,
      projectTitle,
      projectDescription,
    );
    yield put(actions.updateProjectSuccess());
  } catch (err) {
    yield put(actions.updateProjectFailure());
  }
}

function* addItemToProject(project, items, type) {
  try {
    yield put(actions.addItemToProjectPending());
    yield call(postAddItemToProject, project, items, type);
    yield put(actions.addItemToProjectSuccess());
  } catch (err) {
    yield put(actions.addItemToProjectFailure());
  }
}

function* toggleProject(projectSlug) {
  try {
    yield put(actions.postToggleProjectsPending());
    yield call(postStatusProject, projectSlug);
    yield put(actions.postToggleProjectsSuccess());
  } catch (err) {
    yield put(actions.postToggleProjectsFailure());
  }
}

function* deleteCompany(companyId, projectSlug) {
  try {
    yield put(actions.deleteCompanyPending());
    yield call(postDeleteCompany, companyId, projectSlug);
    yield put(actions.deleteCompanySuccess());
  } catch (err) {
    yield put(actions.deleteCompanyFailure());
  }
}

function* enrichCompany(cId, slug) {
  try {
    yield put(actions.enrichCompanyPending());
    yield call(postEnrichCompany, cId, slug);
    yield put(actions.enrichCompanySuccess());
  } catch (err) {
    yield put(actions.enrichCompanyFailure());
  }
}

function* checkProjectsTitles(project, projectTitle) {
  try {
    yield put(actions.checkProjectTitlePending());
    const response = yield call(checkProjectTitle);
    if (!project) {
      let projectNameExist = response.some(f => {
        return f.title.toLowerCase() === projectTitle.toLowerCase();
      });
      yield put(actions.setProjectTitle(projectNameExist));
    } else {
      let projectNameExist =
        response.filter(
          f =>
            f.title.toLowerCase() === projectTitle.toLowerCase() &&
            project.id !== f.id,
        ).length > 0;
      yield put(actions.setProjectTitle(projectNameExist));
    }
    yield put(actions.checkProjectTitleSuccess());
  } catch (err) {
    yield put(actions.checkProjectTitleFailure());
  }
}

function* checkProjects(companyId, type = "company") {
  try {
    yield put(actions.checkProjectsPending());
    const response = yield call(checkProjectsAPI, companyId, type);
    yield put(actions.setProjectsCheckState(response.data));
    yield put(actions.checkProjectsSuccess());
  } catch (err) {
    yield put(actions.checkProjectsFailure());
  }
}

function* deleteProject(slug) {
  try {
    yield put(actions.deleteProjectPending());
    yield call(removeProject, slug);
    yield put(actions.deleteProjectSuccess());
  } catch (err) {
    yield put(actions.deleteProjectFailure());
  }
}

function* fetchProjectCompaniesChart(id, type, grouped, filterNameValue) {
  try {
    yield put(actions.getProjectCompaniesChartPending());
    const response = yield call(
      getCompaniesChart,
      id,
      type,
      grouped,
      filterNameValue,
    );
    yield put(actions.getProjectCompaniesChartSuccess());
    yield put(actions.setProjectCompaniesChart(response.data));
  } catch (err) {
    yield put(actions.getProjectCompaniesChartFailure());
  }
}

function* fetchProjectCompaniesTeamSizeChart(
  id,
  type,
  grouped,
  filterNameValue,
) {
  try {
    yield put(actions.getProjectCompaniesTeamSizeChartPending());
    const response = yield call(getTeamSizeChart, id, type, filterNameValue);
    yield put(actions.getProjectCompaniesTeamSizeChartSuccess());
    yield put(actions.setProjectCompaniesTeamSizeChart(response));
  } catch (err) {
    yield put(actions.getProjectCompaniesTeamSizeChartFailure());
  }
}

export {
  fetchProjects,
  createProject,
  toggleProject,
  updateProject,
  addItemToProject,
  fetchProject,
  deleteCompany,
  enrichCompany,
  checkProjectsTitles,
  checkProjects,
  deleteProject,
  fetchProjectCompaniesChart,
  fetchProjectCompaniesTeamSizeChart,
};
