import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
//Redux
import { useDispatch, useSelector } from "react-redux";
import {
  getCreditsSelector,
  getUserSelector,
} from "../../../../store/auth/selectors";
import { checkProjectsRequest } from "../../../../store/projects/requests";
import {
  getCheckProjectsSelector,
  getCheckProjectsStatusSelector,
} from "../../../../store/projects/selectors";
//Hooks
import { useLocation } from "react-router";
import { useAuthHook } from "../../../../common/useAuthHook";
import { useRouterHook } from "../../../../common/useRouterHook";

export const useLockedPageHook = ({ company, type }) => {
  const { t } = useTranslation();
  const { isAuthenticated } = useAuthHook();
  let location = useLocation();
  let { pathname, search } = location;
  const { openPage } = useRouterHook();
  const dispatch = useDispatch();
  const getCredits = useSelector(getCreditsSelector());
  const getUser = useSelector(getUserSelector);
  const getCheckedProjects = useSelector(getCheckProjectsSelector);
  const getCheckedProjectsStatusSelector = useSelector(
    getCheckProjectsStatusSelector,
  );
  const [credits, setCredits] = useState(getCredits);
  const [checkedProjects, setCheckedProjects] = useState([]);
  const [createProjectModalShow, setCreateProjectModalShow] = useState(false);
  const [enrichRequestModalShow, setEnrichRequestModalShow] = useState(false);
  const [showOrderReportModal, setShowOrderReportModal] = useState(false);
  const [notEnoughCreditsModalShow, setNotEnoughCreditsModalShow] =
    useState(false);
  const [topUpCreditsModalShow, setTopUpCreditsModalShow] = useState(false);
  const [showEnrichSuccess, setShowEnrichSuccess] = useState(false);

  useEffect(() => {
    getCheckedProjects && setCheckedProjects(getCheckedProjects);
  }, [getCheckedProjects]);

  const handleClosingCreateProjectModal = () => {
    setCreateProjectModalShow(false);
  };
  const isEnrichRequestSent = () => {
    return company.enrichment_request && !company.ready_for_release;
  };
  const handleClosingTopUpCreditsDialog = () => {
    setTopUpCreditsModalShow(false);
  };
  const handleClosingNotEnoughCreditsDialog = () => {
    setNotEnoughCreditsModalShow(false);
  };
  const handleTopUp = () => {
    setNotEnoughCreditsModalShow(false);
    setTopUpCreditsModalShow(true);
  };
  const handleBuyingTopUpCreditsDialog = () => {
    setTopUpCreditsModalShow(false);
    setShowEnrichSuccess(true);
  };
  const handleClosingEnrichDialog = () => {
    setEnrichRequestModalShow(false);
    setShowOrderReportModal(false);
  };
  const showEnrichRequestModal = (e, selectedCompany) => {
    e && e.preventDefault();
    e && e.stopPropagation();
    if (credits === 0) {
      setNotEnoughCreditsModalShow(true);
    } else {
      // let companyAddedToProject = checkedProjects && checkedProjects.length > 0 && checkedProjects.filter(project => project.is_in_project).length > 0;
      type === "company"
        ? setEnrichRequestModalShow(true)
        : setShowOrderReportModal(true);
      // !companyAddedToProject ? setEnrichRequestModalShow(!enrichRequestModalShow) : dispatch(enrichCompanyRequest({ company }));
    }
  };
  const handleCreateProjectDialogOpened = () => {
    setEnrichRequestModalShow(false);
    setShowOrderReportModal(false);
    setCreateProjectModalShow(true);
  };
  const handleNewProjectCreated = () => {
    dispatch(checkProjectsRequest({ companyId: company.id }));
    setCreateProjectModalShow(false);
  };

  const handleEnrichSuccess = () => {
    setEnrichRequestModalShow(false);
    setShowEnrichSuccess(true);
  };
  const handleClosingSuccess = () => {
    setShowEnrichSuccess(false);
    window.location.reload();
  };

  return {
    t,
    isAuthenticated,
    openPage,
    getCredits,
    getUser,
    getCheckedProjectsStatusSelector,
    setCredits,
    setCheckedProjects,
    createProjectModalShow,
    enrichRequestModalShow,
    notEnoughCreditsModalShow,
    setNotEnoughCreditsModalShow,
    topUpCreditsModalShow,
    showEnrichSuccess,
    handleClosingCreateProjectModal,
    isEnrichRequestSent,
    handleClosingTopUpCreditsDialog,
    handleClosingNotEnoughCreditsDialog,
    handleTopUp,
    handleBuyingTopUpCreditsDialog,
    handleClosingEnrichDialog,
    showEnrichRequestModal,
    handleCreateProjectDialogOpened,
    handleNewProjectCreated,
    handleEnrichSuccess,
    handleClosingSuccess,
    pathname,
    search,
    credits,
    getCheckedProjects,
    checkedProjects,
    location,
    showOrderReportModal,
    setShowOrderReportModal,
  };
};
