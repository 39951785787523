import React, { useEffect, useRef, useState } from "react";
import { useStyles } from "../style.js";
import classnames from "classnames";
import Typewriter from "./Typewriter";
import { useAuthHook } from "../../../common/useAuthHook";
import useClickableCompanyNames from "../hooks/useClickableCompanyNames";
import PreviewModal from "../../../pages/Results/components/modals/PreviewModal";
import { ReactComponent as PumaIcon } from "../../../assets/images/chat/puma.svg";
import { ReactComponent as CopyIcon } from "../../../assets/images/chat/copyIcon.svg";
import { ReactComponent as CopyDoneIcon } from "../../../assets/images/chat/copyDoneIcon.svg";
import { useLockedPageHook } from "../../../pages/Company/LockedPage/hooks/useLockedPageHook";
import EnrichRequestModal from "../../../pages/Company/LockedPage/EnrichRequestModal/EnrichRequestModal.js";
import NotEnoughCreditsModal from "../../../pages/Company/LockedPage/NotEnoughCreditsModal/NotEnoughCreditsModal.js";
import CompanyService from "../../../api/company/companyService.js";
import { useRouterHook } from "../../../common/useRouterHook";
import { createCompanyData } from "../../../utils/companyUtils.js";

const MlContent = ({
  text,
  conversationItem,
  conversationIndex,
  contextObject,
  className,
  children,
  contentOptions = false,
}) => {
  const classes = useStyles();
  const { openPage } = useRouterHook();
  const [selectedCompanyName, setSelectedCompanyName] = useState(null);
  const [parsedText, setParsedText] = useState(null);
  const [isPreviewModalOpened, setIsPreviewModalOpened] = useState(false);
  const [copied, setCopied] = useState(false);
  const [fetchingCompany, setFetchingCompany] = useState(false);
  const mlTextRef = useRef(null);
  const { user } = useAuthHook(); //const { getCompany: { data: companyLinkData, isFetching, refetch: refetchCompany} } = useCompanies({sid: contextObject?.id, id: companyLinkSelectedId})
  const companyService = new CompanyService();
  const { parseCompanyNamesInLinks, fetchCompanies, fetchedCompanies } =
    useClickableCompanyNames();

  const {
    enrichRequestModalShow,
    handleClosingEnrichDialog,
    handleClosingNotEnoughCreditsDialog,
    handleTopUp,
    showEnrichRequestModal,
    notEnoughCreditsModalShow,
    handleCreateProjectDialogOpened,
    handleEnrichSuccess,
    credits,
  } = useLockedPageHook({ company: selectedCompanyName, type: "company" });

  useEffect(() => {
    if (text) {
      let parsedText = null;
      if (
        !conversationItem.typing &&
        conversationItem.characterPosition !== null
      ) {
        parsedText = text.substring(0, conversationItem.characterPosition);
      }
      parsedText = parsedText
        ? parsedText.replace(/\n/g, "<br>")
        : text.replace(/\n/g, "<br>");
      setParsedText(parsedText);
      /* parseCompanyNamesInLinks(
          parsedText,
          contextObject.company_links_data,
      ).then(({ resultText, companyMlIdsFromText }) => {
          setParsedText(resultText);
          if (
              !conversationItem.companiesPreviewData.length &&
              companyMlIdsFromText.length && 
              (!fetchedCompanies || !fetchedCompanies.length)
          ) {
              fetchCompanies(
                  companyMlIdsFromText,
                  contextObject.id,
                  conversationIndex,
              );
          }
      });  */
    }
  }, [text, conversationItem, contextObject]);

  const handleMlTextClick = event => {
    event.preventDefault();
    const companyId = event.target.getAttribute("data-company-id");
    if (companyId) {
      setFetchingCompany(true);
      setSelectedCompanyName({});
      companyService
        .getCompanyByMlId(contextObject.id, companyId)
        .then(data => {
          setFetchingCompany(false);
          const transformedData = createCompanyData(
            data.name,
            data.description,
            data.total_funding_amount_dollars,
            data.founded_date,
            data.number_of_employees,
            data.investment_stage,
            data.hq_country,
            (data.degree_of_fit * 100).toFixed(),
            data.slug,
            data.id,
            data.enrichment_request,
            data.ready_for_release,
            data.website,
            data.deck,
            data.sectors,
            data.comments_cnt,
            data.subsectors,
            data.projects,
          );
          setSelectedCompanyName(transformedData);
          setIsPreviewModalOpened(true);
        })
        .catch(error => {
          setIsPreviewModalOpened(false);
          if (!error.response) {
            openPage("/ml-timeout");
          }
          if (error.response.status === 404) {
            openPage("/ml-company-not-found");
          } else {
            openPage("/ml-failure");
          }
        });
    }
  };

  const handleCopyAnswer = () => {
    const textToCopy = mlTextRef.current.innerText;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 4000);
      })
      .catch(err => {
        console.error("Failed to copy: ", err);
      });
  };

  return (
    <div
      className={classnames(classes.mlContent, className)}
      is-summary-answer={`${conversationItem?.isSummaryAnswer}`}>
      <div className={classes.messageContainer}>
        <div className={classes.messageContent}>
          <div className={classes.mlImgWrapper}>
            <PumaIcon className={classes.mlImg} />
          </div>
          <div
            className={classes.mlText}
            onClick={handleMlTextClick}
            ref={mlTextRef}>
            {children ? (
              <>{children}</>
            ) : (
              <>
                {parsedText && (
                  <>
                    {conversationItem && (
                      <>
                        {/*
                                                Typewriter component is currently removed due to it's issues with rendering the effect with long texts and updates
                                                Component is still available in the codebase and needs to be fixed / refactored to be used again
                                                */}
                        {/* <Typewriter
                                                    text={parsedText}
                                                    conversationItem={conversationItem}
                                                    conversationIndex={conversationIndex}
                                                    contextObjectId={contextObject.id}
                                                /> */}
                        <div
                          dangerouslySetInnerHTML={{
                            __html: parsedText,
                          }}></div>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
        {contentOptions && !conversationItem.typing && (
          <div className={classes.contentOptions}>
            {copied ? (
              <>
                <CopyDoneIcon className={classes.copyDoneIcon} />
              </>
            ) : (
              <>
                <CopyIcon
                  onClick={handleCopyAnswer}
                  className={classes.copyIcon}
                />
              </>
            )}
          </div>
        )}
      </div>
      {isPreviewModalOpened && (
        <>
          <PreviewModal
            dialogOpened={isPreviewModalOpened}
            onChange={() => setIsPreviewModalOpened(false)}
            data={selectedCompanyName}
            user={user}
            type="company"
            showEnrichRequestModal={showEnrichRequestModal}
            loading={fetchingCompany}
          />
          <EnrichRequestModal
            source="company"
            credits={credits}
            company={selectedCompanyName}
            dialogOpened={enrichRequestModalShow}
            onChange={handleClosingEnrichDialog}
            createProjectDialogOpened={handleCreateProjectDialogOpened}
            enrichSuccess={handleEnrichSuccess}
            cId={selectedCompanyName && selectedCompanyName.id}
          />
          <NotEnoughCreditsModal
            credits={credits}
            dialogOpened={notEnoughCreditsModalShow}
            onChange={handleClosingNotEnoughCreditsDialog}
            onTopUp={handleTopUp}
          />
        </>
      )}
    </div>
  );
};

export default MlContent;
