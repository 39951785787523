import { call } from "redux-saga/effects";
import axios from "axios";
import {
  ADD_COMPANY_COMMENTS_URL,
  ADD_COMPANY_TAGS_URL,
  DELETE_COMPANY_COMMENT_URL,
  DELETE_COMPANY_TAGS_URL,
  GET_SIMILAR_COMPANIES_URL,
  GET_SUGGESTED_COMPANIES_URL,
  getCompanyCommentsUrl,
  getCompanyTagsUrl,
  getDeckClientPreviewUrl,
  POST_SHARED_LINK_URL,
  SELECT_COMPANY_TAGS_URL,
  UPDATE_COMPANY_COMMENTS_URL,
  VOTE_COMPANY_COMMENT_URL,
} from "../../../constants/endpoints";

function* postAddComment(data) {
  const response = yield call(axios, {
    method: "POST",
    url: `${ADD_COMPANY_COMMENTS_URL}`,
    withCredentials: true,
    data,
  });
  return response.data;
}

function* getComments(cid) {
  const response = yield call(axios, {
    method: "GET",
    url: `${getCompanyCommentsUrl}?cid=${cid}`,
    withCredentials: true,
  });
  return response.data;
}

function* postUpdateComment(data) {
  const response = yield call(axios, {
    method: "POST",
    url: `${UPDATE_COMPANY_COMMENTS_URL}`,
    withCredentials: true,
    data,
  });
  return response.data;
}

function* postDeleteComment(data) {
  const response = yield call(axios, {
    method: "POST",
    url: `${DELETE_COMPANY_COMMENT_URL}`,
    withCredentials: true,
    data,
  });
  return response.data;
}

function* postSharedLink(token) {
  const response = yield call(axios, {
    method: "POST",
    url: `${POST_SHARED_LINK_URL}`,
    withCredentials: true,
    data: {
      token,
    },
  });
  return response.data;
}

function* postVoteComment(data) {
  const response = yield call(axios, {
    method: "POST",
    url: `${VOTE_COMPANY_COMMENT_URL}`,
    withCredentials: true,
    data,
  });
  return response.data;
}

function* getCompanyTags(slug) {
  const response = yield call(axios, {
    method: "GET",
    url: `${getCompanyTagsUrl(slug)}`,
    withCredentials: true,
  });
  return response.data;
}

function* postAddCompanyTag(data) {
  const response = yield call(axios, {
    method: "POST",
    url: `${ADD_COMPANY_TAGS_URL}`,
    withCredentials: true,
    data,
  });
  return response.data;
}

function* postDeleteCompanyTag(data) {
  const response = yield call(axios, {
    method: "POST",
    url: `${DELETE_COMPANY_TAGS_URL}`,
    withCredentials: true,
    data,
  });
  return response.data;
}

function* postSelectCompanyTag(data) {
  const response = yield call(axios, {
    method: "POST",
    url: `${SELECT_COMPANY_TAGS_URL}`,
    withCredentials: true,
    data,
  });
  return response.data;
}

function* getSimilarCompaniesCall(data) {
  const response = yield call(axios, {
    method: "GET",
    url: `${GET_SIMILAR_COMPANIES_URL}?cid=${data.cid}`,
    withCredentials: true,
  });
  return response.data;
}

function* fetchSuggestedCompanies(data) {
  const response = yield call(axios, {
    method: "GET",
    url: GET_SUGGESTED_COMPANIES_URL,
    withCredentials: true,
  });

  return response.data;
}

function* getDeckClientPreview(slug) {
  const response = yield call(axios, {
    method: "GET",
    url: getDeckClientPreviewUrl(slug),
    withCredentials: true,
  });
  return response.data;
}

export {
  getComments,
  postAddComment,
  postUpdateComment,
  postDeleteComment,
  postSharedLink,
  postVoteComment,
  getCompanyTags,
  postAddCompanyTag,
  postDeleteCompanyTag,
  postSelectCompanyTag,
  getSimilarCompaniesCall,
  fetchSuggestedCompanies,
  getDeckClientPreview,
};
