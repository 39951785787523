import { createStyles, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(({ palette, spacing, breakpoint }) =>
  createStyles({
    root: {
      backgroundColor: palette.common.white,
      boxShadow: "0px 1px 4px #E5E9F2",
      borderRadius: spacing(3),
      padding: spacing(4),
      marginTop: spacing(3),
      marginBottom: spacing(2),
      position: "relative",
    },
    headTitle: {
      color: palette.text.main,
    },
    header: {
      marginTop: spacing(4),
      display: "flex",
      alignItems: "center",
    },
    avatarBox: {
      borderRadius: "50%",
      height: spacing(4),
      minWidth: spacing(4),
      width: spacing(4),
      background: palette.success.backgroundLight,
    },
    avatar: {
      maxWidth: 35,
    },
    newCommAvatarBox: {
      borderRadius: "50%",
      height: spacing(4),
      minWidth: spacing(4),
      width: spacing(4),
      background: palette.primary.backgroundLight,
      overflow: "hidden",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    textarea: {
      background: palette.common.white,
      border: "1px solid",
      borderColor: palette.text.backgroundMain,
      width: "100%",
      fontFamily: "GT Flexa, sans-serif",
      outline: "none",
      padding: spacing(1),
      resize: "none",
      borderRadius: spacing(1),
      marginLeft: spacing(2),
      color: palette.text.lighter,
    },
    sortingWrap: {
      marginTop: spacing(3),
      borderBottom: "1px solid rgba(107, 119, 140, 0.1);",
      paddingBottom: spacing(2),
      display: "flex",
      justifyContent: "flex-end",
    },
    noCommentsTitle: {
      color: palette.secondary.dark,
      marginTop: spacing(5),
    },
    noCommentsSubtitle: {
      color: palette.text.main,
      marginTop: spacing(1),
      marginBottom: spacing(4),
    },
    noComments: {
      marginTop: spacing(5),
    },
    commentTime: {
      color: palette.text.lighter,
    },
    nameTimeWrap: {
      marginLeft: spacing(2),
      display: "flex",
      flexDirection: "column",
    },
    commentsWrap: {
      marginTop: spacing(3),
      paddingBottom: spacing(2),
      "&:not(:last-child)": {
        borderBottom: "1px solid rgba(107, 119, 140, 0.1)",
      },
    },
    commentText: {
      marginLeft: 47,
      marginTop: spacing(1),
      color: palette.text.main,
    },
    replyText: {
      marginLeft: 51,
    },
    commentTextInput: {
      marginLeft: 47,
      color: palette.text.main,
      marginTop: spacing(1),
      borderRadius: spacing(1),
      "& input": {
        width: "100%",
      },
    },
    adjustTextFieldWidth: {
      width: "100%",
    },
    editedTag: {
      background: "transparent",
      color: palette.secondary.main,
    },
    editDeleteActions: {
      marginTop: spacing(1),
      display: "flex",
    },
    replyAction: {
      marginLeft: 0,
      marginTop: spacing(1),
      justifyContent: "center",
      display: "flex",
      flexDirection: "column",
    },
    action: {
      color: palette.text.secondary,
      paddingLeft: 0,
    },
    actionsWrap: {
      marginLeft: 47,
      [breakpoint.down("xs")]: {
        marginLeft: spacing(3),
      },
    },
    repliesWrap: {
      marginLeft: 48,
      borderTop: "1px solid rgba(107, 119, 140, 0.1)",
      marginTop: spacing(1),
      paddingTop: spacing(3),
      [breakpoint.down("sm")]: {
        "& > $actionsWrap": {
          marginLeft: spacing(3),
        },
      },
      [breakpoint.down("xs")]: {
        marginLeft: spacing(3),
      },
    },
    votes: {
      mariginRight: spacing(1),
      display: "flex",
      alignItems: "center",
    },
    voteIcon: {
      padding: `${spacing(1)}px ${spacing(1)}px`,
      "&:hover": {
        cursor: "pointer",
        color: palette.secondary.main,
      },
    },
    dislikeImg: {
      transform: "rotate(180deg)",
    },
    voteCounter: {
      color: palette.grey.main,
      minWidth: "20px",
      minHeight: "15px",
    },
    disabledClick: {
      cursor: "default !important",
    },
    withVote: {
      color: palette.primary.dark,
    },
    flexColumnCenter: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    flexCenter: {
      display: "flex",
      alignItems: "center",
    },
    flex: {
      display: "flex",
    },
    flexReply: {
      display: "flex",
      flexDirection: "column",
    },
    openDialogSection: {
      marginTop: spacing(3),
      padding: `${spacing(2)}px ${spacing(4)}px 0 0`,
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      width: "100%",
      borderTop: `1px solid ${palette.text.backgroundMain}`,
      "&:hover $mono": {
        color: palette.secondary.dark,
      },
    },
    mono: {
      fontFamily: "GT Flexa Mono, sans-serif",
    },
    questionMark: {
      marginRight: spacing(2),
    },
    lockIcon: {
      position: "absolute",
      top: spacing(3),
      right: spacing(4),
    },
  }),
);
