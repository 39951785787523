import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import classnames from "classnames";
// Hooks
import { useStyles } from "./style.js";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router";
import { useRouterHook } from "../../common/useRouterHook.js";
import { useAuthHook } from "../../common/useAuthHook.js";
import useFilters from "../../common/useFilters.js";
import useResults from "../../common/useResults.js";
import { useScrollTo } from "../../common/useScrollTo";
import { useAppEventTracking } from "../../common/useAppEventTracking";
//UI components
import Typography from "../../components/Typography/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
//Components
import Header from "../Technologies/ProfileCompanies/components/Header/Header";
import Card from "../../components/Card/Card";
import ContentLoader from "../../components/ContentLoader/ContentLoader.js";
import SignUpForm from "../Authentication/SignUpForm";
import SubscribeConfirmationModal from "../Results/components/modals/SubscribeConfirmationModal";
import Pagination from "../../components/Pagination/Pagination";
import ListView from "../Results/components/ListView/ListView";
//Constants
import {
  fundingOptionsMin,
  stageOptions,
  teamsizeOptions,
  valuerScoreOptionsMin,
} from "../../constants/filters";
import { objectHasEmptyValues } from "../../utils/objectHasEmptyValues";
// Assets
import { ReactComponent as BreadcrumbSeparator } from "../../assets/icons/BreadcrumbSeparator.svg";
import { ReactComponent as UpArrowResults } from "../../assets/icons/UpArrowResults.svg";
//Redux
import { useDispatch, useSelector } from "react-redux";
import {
  getSectorsSelector,
  getSubsectorsSelector,
} from "../../store/sectors/selectors";
import { fetchLikedCompaniesRequest } from "../../store/results/requests";
import {
  getActiveViewSelector,
  getLikedCompaniesSelector,
  getLikedCompaniesStatusSelector,
  getLikedCompaniesTotalCountSelector,
  getLikedCompaniesTotalPagesSelector,
  getLikedFilterStats,
} from "../../store/results/selectors";
import { actions as resultsActions } from "../../store/results/slice";
import { getCountriesSelector } from "../../store/countries/selectors";
import {
  getCheckOutUrlSelector,
  getSubscribeStatusSelector,
  getUserStatusSelector,
} from "../../store/auth/selectors";
//Shared
import { Shared } from "../../utils/shared.js";

const LikedCompanies = ({ items }) => {
  // const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let { search } = useLocation();
  const { slug, token } = useParams();
  const { openPage } = useRouterHook();
  const localStorageSlugPrefix = "likedCompanies";
  const urlParams = new URLSearchParams(search);
  const searchTypeLook = urlParams.get("searchTypeLook");
  // Selectors
  const likedCompanies = useSelector(getLikedCompaniesSelector);
  const getLikedCompaniesStatus = useSelector(getLikedCompaniesStatusSelector);
  const getLikedCompaniesTotalCount = useSelector(
    getLikedCompaniesTotalCountSelector,
  );
  const filterStats = useSelector(getLikedFilterStats);
  const getLikedCompaniesTotalPages = useSelector(
    getLikedCompaniesTotalPagesSelector,
  );
  const allSectors = useSelector(getSectorsSelector);
  const allResults = [];
  //State
  const { isAuthenticated, userLoading } = useAuthHook();
  const [timeout, setTimeoutVal] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState(likedCompanies);
  const [totalCount, setTotalCount] = useState(getLikedCompaniesTotalCount);
  const allCountries = useSelector(getCountriesSelector);
  const allSubsectors = useSelector(getSubsectorsSelector);
  const getMeStatus = useSelector(getUserStatusSelector);
  const checkOutUrl = useSelector(getCheckOutUrlSelector);
  const subscribeStatus = useSelector(getSubscribeStatusSelector);
  const [isAuthed, setIsAuthed] = useState(true);
  const { scrollToTop, upArrowVisible } = useScrollTo();
  const { mixpanelTrackEvent } = useAppEventTracking();
  //check this
  const [totalPages, setTotalPages] = useState(getLikedCompaniesTotalPages);
  const queryParams = new URLSearchParams(window.location.search);
  const activeView = useSelector(getActiveViewSelector);
  const cardsWrapperRef = useRef(null);
  const tableToolbarRef = useRef(null);
  const classes = useStyles({ activeView });
  const [selected, setSelected] = useState([]);
  const [selectedNames, setSelectedNames] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [tableToolbarClassName, setTableToolbarClassName] = useState(
    classes.header,
  );
  const { scrollTo } = Shared({});

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 768) {
        dispatch(resultsActions.setActiveView("card"));
      }
    }

    window.addEventListener("resize", handleResize);
  });

  const [selectedSubsectorArray, setSelectedSubsectorArray] = useState([]);

  // Filtering
  const {
    filters,
    selectedFilters,
    setDisplayedFilters,
    filterNameValue,
    resetFilters,
    handleSectorChange,
    handleFundingMinChange,
    handleYearMinChange,
    handleMatchingScoreMinChange,
    resetFilter,
    setModalFilters,
    setSelectedFilters,
    displayedFilters,
    fundingOptionsMax,
    valuerScoreOptionsMax,
    yearOptionsMax,
    handleFieldChange,
    handleFilterRemove,
    handleShowResults,
    handleShowFilters,
    setIsFilterFetching,
    isFilterFetching,
    setPage,
    page,
    handleSortChange,
    sortValue,
    sortDirection,
    handleSectorsClose,
    handleSubsectorsClose,
    preselectedSectors,
    setPreselectedSectors,
    preselectedSubsectors,
    setPreselectedSubsectors,
    filterMapping,
  } = useFilters({
    isAuthenticated,
    userLoading,
    allSectors,
    setTotalPages,
    getFilterResultsTotalPages: getLikedCompaniesTotalPages,
    getSearchResultsTotalPages: getLikedCompaniesTotalPages,
    isSharedPageToken: false,
    searchTypeLook: null,
    currentSearchSid: "likedCompanies",
    request: fetchLikedCompaniesRequest,
    countRequest: fetchLikedCompaniesRequest,
    token,
    localStorageSlugPrefix: "likedCompanies",
    slug: "likedCompanies",
    isIndustry: false,
    activeView,
    isLikedCompanies: true,
  });

  const {
    isFetching,
    pageLoading,
    isSubscribeDialogOpened,
    deselectAll,
    addItemToProjectModal,
    setIsSubscribeDialogOpened,
    confirmSubscription,
    handleSelectingCardIndex,
    selectedCardIndex,
    moreData,
  } = useResults({
    slug,
    totalCount,
    searchResults,
    isAuthenticated,
    getMeStatus,
    search,
    checkOutUrl,
    subscribeStatus,
    page,
    selectedCompanies,
    getFilteringState: likedCompanies,
    setSelectedCompanies,
    totalPages,
    filterNameValue,
    setPage,
    setIsAuthed,
    sortValue,
    sortDirection,
    subsectorsQuery: "",
    isIndustry: false,
    displayedFilters,
    request: fetchLikedCompaniesRequest,
    localStorageSlugPrefix,
    perPage: activeView === "card" ? 10 : 50,
    activeView,
  });

  const handleOnSearch = () => {
    setLoading(true);
    clearTimeout(timeout);
    setPage(1);
    setTimeoutVal(
      setTimeout(() => {
        setLoading(false);
      }, 500),
    );
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = searchResults.map(n => n.id);
      const newSelectedNames = searchResults.map(n => n.name);
      setSelected(newSelecteds);
      setSelectedCompanies(searchResults);
      setSelectedNames(newSelectedNames);
      return;
    }
    setSelected([]);
    setSelectedNames([]);
    setSelectedCompanies([]);
  };

  useEffect(() => {
    mixpanelTrackEvent("Liked Companies visited");
  }, []);

  useEffect(() => {
    const subsectorsString =
      selectedSubsectorArray &&
      selectedSubsectorArray.map(e => e.slug).join(",");
    setDisplayedFilters(prevDisplayedFilters => {
      return {
        ...prevDisplayedFilters,
        subsectors: subsectorsString,
      };
    });
    setSelectedFilters(prevSelectedFilters => {
      return {
        ...prevSelectedFilters,
        subsectors: subsectorsString,
      };
    });
  }, [selectedSubsectorArray]);

  useEffect(() => {
    totalCount === 0 || page === totalPages || !totalPages
      ? setIsFilterFetching(false)
      : setIsFilterFetching(isFetching);
  }, [isFetching, totalCount, totalPages, page]);

  useEffect(() => {
    if (!objectHasEmptyValues(selectedFilters) || likedCompanies.length > 0) {
      setSearchResults(likedCompanies);
      setTotalCount(getLikedCompaniesTotalCount);
      setTotalPages(getLikedCompaniesTotalPages);
    } else {
      setSearchResults(likedCompanies);
      setTotalCount(getLikedCompaniesTotalCount);
      setTotalPages(getLikedCompaniesTotalPages);
    }
  }, [likedCompanies, getLikedCompaniesTotalCount]);

  const [isLess768, setIsLess768] = useState(false);
  useEffect(() => {
    sessionStorage.setItem("history", "likedCompanies");
    setIsLess768(window.innerWidth < 768);
  }, []);

  const handleResetFilters = () => {
    resetFilters();
  };

  useEffect(() => {
    sessionStorage.setItem(
      `${localStorageSlugPrefix}-${slug}-${activeView}-FilterSortValue`,
      sortValue,
    );
  }, [sortValue]);

  useEffect(() => {
    sessionStorage.setItem(
      `${localStorageSlugPrefix}-${slug}-${activeView}-FilterSortDirection`,
      sortDirection,
    );
  }, [sortDirection]);

  const [loadingViewSwitch, setLoadingViewSwitch] = useState(false);

  useEffect(() => {
    !loading && setLoadingViewSwitch(false);
  }, [loading]);

  useLayoutEffect(() => {
    let scrollPosition = sessionStorage.getItem(
      `likedCompanies-ScrollPosition`,
    );
    setTimeout(() => {
      scrollPosition &&
        searchResults.length &&
        scrollTo(Number(scrollPosition), () =>
          sessionStorage.removeItem(`likedCompanies-ScrollPosition`),
        );
    }, 200);
  }, [searchResults]);

  const switchView = view => {
    setLoadingViewSwitch(true);
    setPage(1);
    dispatch(resultsActions.setActiveView(view));
  };

  const breadcrumbs = [
    <Typography
      key="1"
      color="textLight"
      variant="bodyButton"
      className={classnames([classes.breadcrumbLink, classes.mono])}
      onClick={() => openPage("/dashboard")}>
      {t("dashboard.mainTitle")}
    </Typography>,
    <Typography
      key="2"
      color="primaryDark"
      variant="bodyButton"
      className={classes.mono}>
      {t("industryProfile.likedCompaniesTitle")}
    </Typography>,
  ];

  return (
    <div ref={cardsWrapperRef} className={classes.resultsRoot}>
      <div className={classes.likedCompaniesPageWrapper}>
        <Breadcrumbs
          separator={<BreadcrumbSeparator />}
          aria-label="breadcrumb"
          classes={{
            ol: classes.breadcrumbsOl,
            li: classes.breadcrumbsLi,
          }}>
          {breadcrumbs}
        </Breadcrumbs>
      </div>
      <Header
        onSearch={handleOnSearch}
        onFilterSelected={handleShowFilters}
        searchResults={searchResults}
        searchResultsTotal={searchResults && searchResults.length}
        isAuthenticated={isAuthenticated}
        onSortChange={handleSortChange}
        filters={filters}
        allResults={allResults}
        total={totalCount}
        resetFilters={handleResetFilters}
        stageOptions={stageOptions}
        fundingOptionsMin={fundingOptionsMin}
        fundingOptionsMax={fundingOptionsMax}
        teamsizeOptions={teamsizeOptions}
        valuerScoreOptionsMin={valuerScoreOptionsMin}
        valuerScoreOptionsMax={valuerScoreOptionsMax}
        handleFieldChange={handleFieldChange}
        handleSectorChange={handleSectorChange}
        resetFilter={resetFilter}
        handleFundingMinChange={handleFundingMinChange}
        handleYearMinChange={handleYearMinChange}
        handleMatchingScoreMinChange={handleMatchingScoreMinChange}
        allSectors={allSectors}
        allSubsectors={allSubsectors}
        selectedFilters={selectedFilters}
        allCountries={allCountries}
        onShowResults={handleShowResults}
        displayedFilters={displayedFilters}
        setSelectedFilters={setSelectedFilters}
        setDisplayedFilters={setDisplayedFilters}
        setModalFilters={setModalFilters}
        isLess768={isLess768}
        isIndustry={false}
        isTechnology={false}
        slug="likedCompanies"
        isFilterFetching={isFilterFetching}
        request={fetchLikedCompaniesRequest}
        overviewVisibility={false}
        totalPages={totalPages}
        page={page}
        isLikedCompanies={true}
        filterStats={filterStats}
        handleSectorsClose={handleSectorsClose}
        handleSubsectorsClose={handleSubsectorsClose}
        preselectedSectors={preselectedSectors}
        setPreselectedSectors={setPreselectedSectors}
        preselectedSubsectors={preselectedSubsectors}
        setPreselectedSubsectors={setPreselectedSubsectors}
        activeView={activeView}
        setActiveView={switchView}
        filterMapping={filterMapping}
        setSelected={setSelected}
        setSelectedCompanies={setSelectedCompanies}
        setSelectedCompany={setSelectedCompany}
        setSelectedNames={setSelectedNames}
        selected={selected}
        selectedNames={selectedNames}
        selectedCompany={selectedCompany}
        selectedCompanies={selectedCompanies}
        tableToolbarRef={tableToolbarRef}
        handleSelectAllClick={handleSelectAllClick}
        tableToolbarClassName={tableToolbarClassName}
      />
      <div className={classes.resultsContent}>
        {activeView === "list" ? (
          <ListView
            companies={searchResults}
            isTechnology={false}
            isIndustry={false}
            searchResults={searchResults}
            onSortChange={handleSortChange}
            sortDirection={sortDirection}
            sortValue={sortValue}
            page={page}
            matchRemoved={true}
            source="likedCompanies"
            isLikedCompany={true}
            id={searchResults && searchResults[0] && searchResults[0].id}
            setSelected={setSelected}
            setSelectedCompanies={setSelectedCompanies}
            setSelectedCompany={setSelectedCompany}
            setSelectedNames={setSelectedNames}
            selected={selected}
            selectedNames={selectedNames}
            selectedCompany={selectedCompany}
            selectedCompanies={selectedCompanies}
            tableToolbar={tableToolbarRef}
            setTableToolbarClassName={setTableToolbarClassName}
          />
        ) : (
          searchResults &&
          searchResults.map((company, i) => (
            <Card
              isTechnology={false}
              isIndustry={false}
              projectSlug={slug}
              cardIndex={i}
              openPage={openPage}
              t={t}
              onClick={() => addItemToProjectModal(company)}
              result={company}
              isAuthenticated={isAuthenticated}
              deselectAll={() => deselectAll}
              key={`card-${i}-${company.id}`}
              setSelectedCompanyIndex={handleSelectingCardIndex}
              showPopUpForIndex={selectedCardIndex === i}
              source="likedCompanies"
              isIndustryProfile={false}
              isLikedCompany={true}
              type="company"
              setSelected={setSelected}
              setSelectedCompanies={setSelectedCompanies}
              setSelectedNames={setSelectedNames}
              selected={selected}
              selectedNames={selectedNames}
              selectedCompanies={selectedCompanies}
            />
          ))
        )}
        {totalPages > 1 && (
          <Pagination
            count={totalPages}
            onChangePage={moreData}
            page={Number(page)}
            scrollTopPosition={
              cardsWrapperRef &&
              cardsWrapperRef.current &&
              cardsWrapperRef.current.offsetTop
            }
          />
        )}
        {(pageLoading || loading) && (
          <div className={classes.resultsLoader}>
            <ContentLoader />
          </div>
        )}
      </div>
      {upArrowVisible && (
        <UpArrowResults onClick={scrollToTop} className={classes.arrowUp} />
      )}
      {/* Guest block */}
      {!isAuthed && totalCount > 0 && (
        <div className={classes.blurryBg}>
          <div className={classes.guestBlock}>
            <div className={classes.guestBlockContent}>
              <Typography variant="subtitle1" className={classes.readyText}>
                {t("results.readyForMore")}
              </Typography>
              <Typography variant="h4" className={classes.startTrialText}>
                <strong>{t("results.signUpForOur")}</strong>
              </Typography>
              <Typography variant="h4" className={classes.startTrialText}>
                <strong>{t("results.14DaysFreeTrial")}</strong>
              </Typography>
              <SignUpForm
                classNames={classes}
                ctaButtonCopy="signUp.createAccount"
                signUpForFreeTrial={true}
                openPage={openPage}
              />
            </div>
          </div>
        </div>
      )}
      {
        <SubscribeConfirmationModal
          dialogOpened={isSubscribeDialogOpened}
          onChange={() => setIsSubscribeDialogOpened(false)}
          onConfirm={confirmSubscription && confirmSubscription}
        />
      }
    </div>
  );
};

export default LikedCompanies;
