import { useTranslation } from "react-i18next";
import useStyles from "./style.js";
// Components
import InputBase from "@material-ui/core/InputBase";
import InputLabel from "@material-ui/core/InputLabel";
import Typography from "../Typography/Typography";
import InfoToolTip from "../InfoToolTip/InfoToolTip";
import classnames from "classnames";

const TextField = ({
  className,
  id,
  labelProps,
  inputProps,
  placeholder,
  label,
  labelClass,
  error,
  errorLabel,
  onChange,
  onClick,
  startAdornment,
  endAdornment,
  type,
  value,
  onKeyUp,
  inputClass,
  testId,
  fullWidth,
  infoToolTipText,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const textFieldClasses = classnames([
    classes.textField,
    inputClass,
    { [classes.fullWidth]: fullWidth },
  ]);
  const labelClasses = classnames(classes.inputLabel, labelClass);

  return (
    <div className={className}>
      {label && (
        <InputLabel
          disableAnimation={true}
          focused={false}
          shrink={true}
          error={!!error}
          {...labelProps}
          className={labelClasses}
          classes={{
            shrink: classes.inputLabel,
            root: classes.inputLabel,
            asterisk: classes.asterisk,
            error: classes.errorLabel,
          }}
          htmlFor={id}>
          {label}
          {infoToolTipText && (
            <InfoToolTip text={infoToolTipText}></InfoToolTip>
          )}
        </InputLabel>
      )}
      <InputBase
        {...inputProps}
        value={value}
        id={id}
        error={!!error}
        className={textFieldClasses}
        classes={{
          focused: classes.focusedInput,
          error: classes.errorInput,
          ...(inputProps && inputProps.classes),
        }}
        placeholder={placeholder}
        onChange={onChange}
        onClick={onClick}
        endAdornment={endAdornment}
        startAdornment={startAdornment}
        type={type}
        onKeyUp={onKeyUp}
        testid={testId}
      />
      {!!error && (
        <Typography variant="caption" color="error" component="div">
          {errorLabel ? errorLabel : t("signUp.errorMessage", { label })}
        </Typography>
      )}
    </div>
  );
};

TextField.defaultProps = {
  error: false,
};

export default TextField;
