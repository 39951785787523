import React, { useEffect, useState } from "react";
// Shared
import { mixpanelUtils } from "../../../../store/mixpanel/utils";
// Hooks
import { useTranslation } from "react-i18next";
import { useStyles } from "./style.js";
// UI components
import Typography from "../../../../components/Typography/Typography.js";
import IconButton from "@material-ui/core/IconButton";
//Assets
import DummyAvatar from "../../../../assets/icons/AvatarDefault.png";
import Link from "../../../../components/Link/Link.js";
import CloseIcon from "../../../../assets/icons/CloseIcon.svg";
import { ReactComponent as QuestionMark } from "../../../../assets/icons/QuestionMark.svg";
import { ReactComponent as SmallLock } from "../../../../assets/icons/SmallLock.svg";
import ArrowRightOrange from "../../../../assets/icons/ArrowRightOrange.svg";
import { Shared } from "../../../../utils/shared.js";
import classnames from "classnames";

const ExecutiveTeam = ({ company, user, isPreview = false }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [showDescription, setShowDescription] = useState(false);
  const [memberIndex, setMemberIndex] = useState(null);
  const showMemberInfo = index => {
    setMemberIndex(index);
    setShowDescription(true);
  };
  const { checkUserSubscription } = Shared({ getUser: user });

  const [requestModalTitle, setRequestModalTitle] = useState("");
  const [requestModalDesc, setRequestModalDesc] = useState("");
  const [requestModalIcon, setRequestModalIcon] = useState(<></>);
  const [showRequestModal, setShowRequestModal] = useState(false);

  const openRequestModal = (title, desc, icon) => {
    setRequestModalTitle(title);
    setRequestModalDesc(desc);
    setRequestModalIcon(icon);
    setShowRequestModal(true);
  };
  const closeRequestModal = () => {
    setShowRequestModal(false);
  };

  const [companyMembers, setCompanyMembers] = useState([]);

  useEffect(() => {
    setCompanyMembers(
      company && company.deck && company.deck.members
        ? company.deck.members
        : company.members,
    );
  }, [company]);

  return (
    <div className={classes.root} data-intercom-target="Executive team section">
      <div className={classes.flexWrap}>
        {company.ready_for_release || (companyMembers && isPreview) ? (
          <>
            {companyMembers.map((member, i) => {
              return showDescription && memberIndex === i ? (
                <div
                  className={classes.blockDescription}
                  key={`team-${member.name} - ${i}`}>
                  <IconButton
                    className={classes.closeButton}
                    onClick={() => setShowDescription(false)}>
                    <img
                      src={CloseIcon}
                      alt={t("scope.step2")}
                      className={classes.stepIcon}
                    />
                  </IconButton>
                  {company.ready_for_release || isPreview ? (
                    <div className={classes.description}>
                      <div className={classes.flexRow}>
                        <div className={classes.descriptionImgWrap}>
                          {member.avatar ? (
                            <img
                              className={classes.avatarSmall}
                              alt={member.name}
                              src={member.avatar}
                            />
                          ) : (
                            <img
                              className={classes.noAvatarSmall}
                              alt={member.name}
                              src={DummyAvatar}
                            />
                          )}
                        </div>
                        <Typography
                          variant="subtitle1"
                          className={classes.memberName}>
                          {member.name}
                        </Typography>
                      </div>
                      {/*<Typography variant="bodySmall" className={classes.profileInfo} component="div"><div dangerouslySetInnerHTML={{ __html: member.profile }}></div></Typography>*/}
                      <Typography
                        variant="subtitle2"
                        className={classes.execTeamTitle}>
                        Experience:
                      </Typography>
                      <Typography
                        variant="bodySmall"
                        className={classes.profileInfo}
                        component="div">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: member.profile,
                          }}></div>
                      </Typography>
                      {member.background && (
                        <div>
                          <Typography
                            variant="subtitle2"
                            className={classes.execTeamTitle}>
                            Academic Background:
                          </Typography>
                          <Typography
                            variant="bodySmall"
                            className={classes.profileInfo}
                            component="div">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: member.background,
                              }}></div>
                          </Typography>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className={classes.description}>
                      <div>
                        <div className={classes.descriptionImgWrap}>
                          <img
                            className={classes.noAvatarSmall}
                            alt={member.name}
                            src={DummyAvatar}
                          />
                        </div>
                        <Typography
                          variant="body"
                          className={classes.profileLockInfo}
                          component="div">
                          {t("companyProfile.executiveTeamLockDesc")}
                        </Typography>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div className={classes.blockUnlocked} key={member.id}>
                  {company.ready_for_release || isPreview ? (
                    <div className={classes.info}>
                      <div className={classes.imgWrap}>
                        {member.avatar ? (
                          <img
                            className={classes.avatar}
                            alt={member.name}
                            src={member.avatar}
                          />
                        ) : (
                          <img
                            alt={member.name}
                            className={classes.noAvatarIcon}
                            src={DummyAvatar}
                          />
                        )}
                      </div>
                      <Typography
                        className={classes.name}
                        component="span"
                        variant="subtitle1">
                        {member.name}
                      </Typography>
                      <Typography
                        className={classes.email}
                        component="span"
                        variant="body">
                        {member.email}
                      </Typography>
                      <Typography
                        color="textLight"
                        className={classes.title}
                        variant="body">
                        {member.title}
                      </Typography>
                      <div className={classes.titleWrap}>
                        <Link onClick={() => showMemberInfo(i)} iconRight>
                          <Typography
                            className={classes.seeMore}
                            color="primaryMain"
                            variant="bodySmall">
                            {t("companyProfile.seeMore")}
                          </Typography>
                          <img
                            src={ArrowRightOrange}
                            className={classes.seeMoreIcon}
                          />
                        </Link>
                      </div>
                    </div>
                  ) : (
                    <div className={classes.infoLocked}>
                      <div className={classes.infoLockedContent}>
                        <div className={classes.imgWrap}>
                          {member.avatar ? (
                            <img
                              className={classes.avatar}
                              alt={member.name}
                              src={member.avatar}
                            />
                          ) : (
                            <img
                              alt={member.name}
                              className={classes.noAvatarIcon}
                              src={DummyAvatar}
                            />
                          )}
                        </div>
                        <div className={classes.flex}>
                          <Typography
                            className={classes.name}
                            component="span"
                            variant="subtitle1">
                            {t("companyProfile.executiveTeam")}
                          </Typography>
                          <SmallLock className={classes.lockIcon} />
                        </div>
                      </div>
                      <div
                        onClick={() => showMemberInfo(i)}
                        className={classes.openDialogSection}>
                        <QuestionMark className={classes.questionMark} />
                        <Typography className={classes.mono} variant="bodyXs">
                          {t("companyProfile.whatWillIGet")}
                        </Typography>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </>
        ) : (
          <>
            <div className={classes.benefitsCardsWrapper}>
              <Typography
                className={classes.benefitsCardsWrapperTitle}
                variant="bodySmall">
                {t("companyProfile.whatWillIGet")}
              </Typography>
              <div className={classes.benefitsCard}>
                <div
                  className={classnames([
                    classes.imgBlock,
                    {
                      [classes.imgBlockReleased]: company.ready_for_release,
                    },
                  ])}>
                  <Typography
                    className={classes.blockTitle}
                    variant="subtitle1"
                    color="textPrimary"
                    component="div">
                    {t(`companyProfile.executiveEnrichmentBanner.title`)}
                  </Typography>
                  <div className={classes.companies}>
                    <div className={classes.descriptionBox}>
                      <Typography
                        variant="body"
                        color="TextSecondary"
                        component="div">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: mixpanelUtils().removeHTMLTags(
                              t(
                                `companyProfile.executiveEnrichmentBanner.description`,
                              ),
                            ),
                          }}
                        />
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
export default ExecutiveTeam;
