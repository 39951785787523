import { all, takeEvery } from "redux-saga/effects";
import {
  createUserConversationRequest,
  emptyStorageConversationRequest,
  fetchPumaSummaryRequest,
  getConversationHistoryRequest,
  getUserConversationRequest,
  saveUserConversationIdRequest,
  updateUserConversationRequest,
} from "../requests";
import {
  createUserConversation,
  emptyStorageConversation,
  fetchPumaSummary,
  getConversationHistory,
  getUserConversation,
  saveUserConversationId,
  updateUserConversation,
} from "./workers";

const convertTypeFromAction = actionCreator => actionCreator.toString();

function* watchGetUserConversation() {
  yield takeEvery(
    convertTypeFromAction(getUserConversationRequest),
    ({ payload: { data } }) => getUserConversation(data),
  );
}

function* watchCreateUserConversation() {
  yield takeEvery(
    convertTypeFromAction(createUserConversationRequest),
    ({ payload: { data, id } }) => createUserConversation(data, id),
  );
}

function* watchUpdateUserConversation() {
  yield takeEvery(
    convertTypeFromAction(updateUserConversationRequest),
    ({ payload: { data, id } }) => updateUserConversation(data, id),
  );
}

function* watchSaveUserConversationId() {
  yield takeEvery(
    convertTypeFromAction(saveUserConversationIdRequest),
    ({ payload: { data } }) => saveUserConversationId(data),
  );
}

function* watchEmptyStorageConversation() {
  yield takeEvery(convertTypeFromAction(emptyStorageConversationRequest), () =>
    emptyStorageConversation(),
  );
}

function* watchFetchPumaSummary() {
  yield takeEvery(
    convertTypeFromAction(fetchPumaSummaryRequest),
    ({ payload: { data, id } }) => fetchPumaSummary(data, id),
  );
}

function* watchGetConversationHistory() {
  yield takeEvery(
    convertTypeFromAction(getConversationHistoryRequest),
    ({ payload: { id } }) => getConversationHistory(id),
  );
}

function* saga() {
  yield all([
    watchCreateUserConversation(),
    watchGetUserConversation(),
    watchUpdateUserConversation(),
    watchSaveUserConversationId(),
    watchEmptyStorageConversation(),
    watchFetchPumaSummary(),
    watchGetConversationHistory(),
  ]);
}

export { saga };
