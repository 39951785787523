import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Toaster from "../../components/Toaster/Toaster";
import {
  getAddItemToProjectStatusSelector,
  getDeleteCompanyStatusSelector,
  getDeleteProjectStatusSelector,
  getEnrichCompanyStatusSelector,
  getPostProjectsErrorMessageSelector,
  getPostProjectsStatusSelector,
  getPostUpdateProjectsStatusSelector,
} from "../../store/projects/selectors";
import {
  getLeaveTeamStatusSelector,
  getRemoveInvitationStatusSelector,
  getRemoveMemberStatusSelector,
} from "../../store/auth/selectors";
import {
  emailChangeStatusSelector,
  emailVerificationSelector,
} from "../../store/freeTrial/selectors";
import { getSendInviteCoworkersStatusSelector } from "../../store/invites/selectors";
import { getPostSearchesStatusSelector } from "../../store/results/selectors";
import { getExportCsvStatusSelector } from "../../store/orders/selectors";
import { useTranslation } from "react-i18next";
import {
  REQUEST_FAILURE,
  REQUEST_PENDING,
  REQUEST_SUCCESS,
} from "../../constants/statuses";
import { actions as projectsActions } from "../../store/projects/slice";
import { actions as ordersActions } from "../../store/orders/slice";

const ToasterNotifications = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { search } = useLocation();

  const addItemToProjectStatus = useSelector(getAddItemToProjectStatusSelector);
  const getDeleteCompanyStatus = useSelector(getDeleteCompanyStatusSelector);
  const getEnrichCompanyStatus = useSelector(getEnrichCompanyStatusSelector);
  const getSendInviteCoworkersStatus = useSelector(
    getSendInviteCoworkersStatusSelector,
  );
  const getPostProjectsStatus = useSelector(getPostProjectsStatusSelector);
  const getDeleteProjectStatus = useSelector(getDeleteProjectStatusSelector);
  const getPostUpdateProjectsStatus = useSelector(
    getPostUpdateProjectsStatusSelector,
  );
  const postProjectsErrorMessage = useSelector(
    getPostProjectsErrorMessageSelector,
  );
  const getRemoveMemberStatus = useSelector(getRemoveMemberStatusSelector);
  const getRemoveInvitationStatus = useSelector(
    getRemoveInvitationStatusSelector,
  );
  const emailChangeStatus = useSelector(emailChangeStatusSelector);
  const getLeaveTeamStatus = useSelector(getLeaveTeamStatusSelector);
  const emailVerificationStatusSelector = useSelector(
    emailVerificationSelector,
  );
  const getExportCsvStatus = useSelector(getExportCsvStatusSelector);
  const getPostSearchesStatus = useSelector(getPostSearchesStatusSelector);

  const urlParams = new URLSearchParams(search);
  const freeTrialEmailVerification = urlParams.get(
    "freeTrialEmailVerification",
  );

  const [isOpenToaster, setIsOpenToaster] = useState(false);
  const [isConditionalOpenToaster, setIsConditionalOpenToaster] =
    useState(false);
  const [isErrorToaster, setIsErrorToaster] = useState(false);
  const [toasterMessage, setToasterMessage] = useState("");

  //Toaster notifications
  useEffect(() => {
    let timeoute = null;
    //Add company to project
    if (addItemToProjectStatus === REQUEST_SUCCESS) {
      const projectName = localStorage.getItem("addToProjectProject");
      const companyName = localStorage.getItem("addToProjectCompany");
      setToasterMessage(
        t("toasters.companyAddedToProject", {
          projectName,
          companyName,
        }),
      );
      setIsOpenToaster(true);
      timeoute = setTimeout(() => {
        dispatch(projectsActions.addItemToProjectUndefined());
      }, 1000);
    }

    //Remove company from project
    if (getDeleteCompanyStatus === REQUEST_SUCCESS) {
      console.log("delete company from project");
      const projectName = localStorage.getItem(
        "deleteCompanyFromProjectProject",
      );
      const companyName = localStorage.getItem(
        "deleteCompanyFromProjectCompany",
      );
      setToasterMessage(
        t("toasters.companyRemovedFromProject", {
          projectName,
          companyName,
        }),
      );
      setIsOpenToaster(true);
      timeoute = setTimeout(() => {
        dispatch(projectsActions.deleteCompanyUndefined());
      }, 1000);
    }
    //Project deleted
    if (getDeleteProjectStatus === REQUEST_SUCCESS) {
      const projectName = localStorage.getItem("deleteProjectName");
      setToasterMessage(t("toasters.projectDeleted", { projectName }));
      setIsOpenToaster(true);
    }
    //Enrichment status
    if (getEnrichCompanyStatus === REQUEST_SUCCESS) {
      setToasterMessage(t("toasters.reportOrdered"));
      setIsOpenToaster(true);
    }
    if (getSendInviteCoworkersStatus === REQUEST_SUCCESS) {
      setToasterMessage(t("toasters.teamMemberInvited"));
      setIsOpenToaster(true);
    }
    //Project created
    if (getPostProjectsStatus === REQUEST_SUCCESS) {
      const projectName = localStorage.getItem("createProjectName");
      setToasterMessage(t("toasters.projectCreated", { projectName }));
      setIsOpenToaster(true);
      timeoute = setTimeout(() => {
        dispatch(projectsActions.postProjectsUndefined());
      }, 1000);
    } else if (getPostProjectsStatus === REQUEST_FAILURE) {
      setToasterMessage(postProjectsErrorMessage);
      setIsOpenToaster(true);
      setIsErrorToaster(true);
      timeoute = setTimeout(() => {
        dispatch(projectsActions.postProjectsUndefined());
      }, 1000);
    }
    //Project updated successfully
    if (getPostUpdateProjectsStatus === REQUEST_SUCCESS) {
      const projectName = localStorage.getItem("updateProjectName");
      setToasterMessage(t("toasters.projectUpdated", { projectName }));
      setIsOpenToaster(true);
    }
    //Remove company from project
    if (getDeleteCompanyStatus === REQUEST_SUCCESS) {
      const projectName = localStorage.getItem(
        "deleteCompanyFromProjectProject",
      );
      const companyName = localStorage.getItem(
        "deleteCompanyFromProjectCompany",
      );
      setToasterMessage(
        t("toasters.companyRemovedFromProject", {
          projectName,
          companyName,
        }),
      );
      setIsOpenToaster(true);
      timeoute = setTimeout(() => {
        dispatch(projectsActions.deleteCompanyUndefined());
      }, 1000);
    }
    if (emailChangeStatus === REQUEST_SUCCESS) {
      setToasterMessage(t("toasters.emailChangeSuccess"));
      setIsOpenToaster(true);
    }
    if (emailVerificationStatusSelector === REQUEST_SUCCESS) {
      setToasterMessage(t("toasters.emailValidationSent"));
      setIsOpenToaster(true);
    }
    if (freeTrialEmailVerification === "success") {
      setToasterMessage(t("toasters.emailValidated"));
      setIsOpenToaster(true);
    }
    if (getLeaveTeamStatus === REQUEST_SUCCESS) {
      setToasterMessage(t("toasters.teamMemberLeft"));
      setIsOpenToaster(true);
      timeoute = setTimeout(() => {
        window.location.reload();
      }, 4000);
    }
    if (getExportCsvStatus === REQUEST_PENDING) {
      setToasterMessage(t("toasters.downloadingDocument"));
      setIsOpenToaster(true);
      setIsConditionalOpenToaster(true);
    }
    if (
      getExportCsvStatus === REQUEST_SUCCESS ||
      getExportCsvStatus === REQUEST_FAILURE
    ) {
      setIsOpenToaster(false);
      setIsConditionalOpenToaster(false);
      dispatch(ordersActions.exportCsvUndefined());
    }
    if (getPostSearchesStatus === REQUEST_SUCCESS) {
      setToasterMessage(t("results.nameTheSearchToaster"));
      setIsOpenToaster(true);
    }

    return () => {
      timeoute && clearTimeout(timeoute);
    };
  }, [
    addItemToProjectStatus,
    getEnrichCompanyStatus,
    getSendInviteCoworkersStatus,
    getDeleteProjectStatus,
    getPostUpdateProjectsStatus,
    getRemoveMemberStatus,
    getPostProjectsStatus,
    getDeleteCompanyStatus,
    emailChangeStatus,
    emailVerificationStatusSelector,
    getLeaveTeamStatus,
    getRemoveInvitationStatus,
    getExportCsvStatus,
    getPostSearchesStatus,
  ]);

  const handleClose = () => {
    setIsOpenToaster(false);
    setIsErrorToaster(false);
  };

  return (
    <Toaster
      open={isOpenToaster}
      setIsOpenToaster={setIsOpenToaster}
      conditionalOpen={isConditionalOpenToaster}
      onClose={handleClose}
      variant={
        isErrorToaster
          ? "error"
          : toasterMessage === t("toasters.downloadingDocument")
          ? "loading"
          : "success"
      }>
      {toasterMessage}
    </Toaster>
  );
};

export default ToasterNotifications;
