import React, { useEffect, useState } from "react";
import classnames from "classnames";
//Hooks
import { useTranslation } from "react-i18next";
import { useStyles } from "./style";
import { useRouterHook } from "../../../../common/useRouterHook";
import { useDispatch, useSelector } from "react-redux";
import useLocalStorage from "../../../../common/useLocalStorage";
import usePrevious from "../../../../common/usePrevious";
import { useAppEventTracking } from "../../../../common/useAppEventTracking";
//UI Components
//Assets
// Redux
import { mixpanelTrackRequest } from "../../../../store/mixpanel/requests";
import { actions as searchActions } from "../../../../store/search/slice";
import { actions as authActions } from "../../../../store/auth/slice";
import { actions as resultsActions } from "../../../../store/results/slice";
import { deleteSavedSearchesRequest } from "../../../../store/search/requests";
import {
  getPostSearchesStatusSelector,
  getRecentSearchesStatusSelector,
  lastSearchNamedSelector,
} from "../../../../store/results/selectors";
import { getSubscriptionSelector } from "../../../../store/auth/selectors";
import { REQUEST_SUCCESS } from "../../../../constants/statuses";
import RemoveFromRecentSearchesModal from "../modals/RemoveFromRecentSearchesModal/RemoveFromRecentSearchesModal";
import LinkBtn from "../../../../components/LinkBtn/LinkBtn";

const RecentSearches = ({
  user,
  activeView,
  currentSearch,
  currentSearchId,
  searches,
  filterNameValue,
  sortValue,
  sortDirection,
  page,
  perPage,
  slug,
  token,
  searchTypeLook,
}) => {
  const { t } = useTranslation();
  const classes = useStyles({ user });
  const dispatch = useDispatch();
  const { openPage, getUrlParam } = useRouterHook();
  const prevSearches = usePrevious(searches);
  const getSearchesStatus = useSelector(getRecentSearchesStatusSelector);
  const getPostSearchesStatus = useSelector(getPostSearchesStatusSelector);
  const lastSearchNamed = useSelector(lastSearchNamedSelector);
  const subscription = useSelector(getSubscriptionSelector());
  const { clearStorage: clearStagePage } = useLocalStorage("results", "page");
  const { clearStorage } = useLocalStorage("results", currentSearchId);
  const [isPopupOpened, setIsPopupOpened] = useState(false);
  // const [visibleSearches, setVisibleSearches] = useState([]);
  // const [visibleSearchesPending, setVisibleSearchesPending] = useState(true);
  const [invisibleSearches, setInvisibleSearches] = useState([]);
  const [usedSearches, setUsedSearches] = useState([]);
  const [renderSearches, setRenderSearches] = useState([]);
  const [updateRenderSearches, setUpdateRenderSearches] = useState(true);
  const [openRemoveSavedSearchModal, setOpenRemoveSavedSearchModal] =
    useState(false);
  const [searchToDelete, setSearchToDelete] = useState();
  const { mixpanelTrackEvent } = useAppEventTracking();
  const isMobile = document.body.offsetWidth <= 768;

  const handleClick = recentSearch => {
    if (renderSearches) {
      return handleDesktopSearchTabClick(recentSearch);
    }
  };

  useEffect(() => {
    if (searches) {
      setUsedSearches(searches);
    }
  }, [searches]);

  useEffect(() => {
    if (getPostSearchesStatus === REQUEST_SUCCESS && searches.length) {
      if (
        !!currentSearch &&
        searches &&
        searches.find(search => search.id === currentSearch.id)
      ) {
        setUsedSearches(prevUsedSearches => {
          return [
            {
              ...prevUsedSearches.find(
                search => search.id === currentSearch.id,
              ),
              search_name:
                searches &&
                searches.find(search => search.id === currentSearch.id)
                  .search_name,
            },
            ...prevUsedSearches.filter(
              search => search.id !== currentSearch.id,
            ),
          ];
        });
      }
    }
  }, [getPostSearchesStatus, lastSearchNamed, searches]);

  // If the page isn't shared, we redirect to the right url when the currentSearchSid changes
  useEffect(() => {
    !token &&
      currentSearchId &&
      openPage(`/results?sid=${currentSearchId}&page=1`);
  }, [token, currentSearchId]);

  // Setting the visible and invisible searches based on the currentSearchSid in the store
  useEffect(() => {
    if (isMobile) {
      if (usedSearches && usedSearches.length > 1) {
        setInvisibleSearches(Array(usedSearches).shift());
      }
    } else {
      if (usedSearches && usedSearches.length) {
        if (!!currentSearch) {
          const searchesWithoutCurrentSearch = usedSearches.filter(
            temp => temp.id != currentSearchId,
          );
          const visibleSearches = searchesWithoutCurrentSearch.filter(
            (temp, i) => i < 4,
          );
          isPopupOpened && setIsPopupOpened(false);
          if (!!updateRenderSearches) {
            setInvisibleSearches(
              searchesWithoutCurrentSearch.filter((temp, i) => i > 3),
            );
            setRenderSearches([currentSearch, ...visibleSearches]);
            setUpdateRenderSearches(false);
          } else {
            let prevRenderSearches = renderSearches;
            const newRenderSearches = prevRenderSearches.map(rs => {
              const newRs = rs && usedSearches.find(us => us.id === rs.id);
              return newRs;
            });
            setRenderSearches(newRenderSearches);
          }
        }
        if (usedSearches.length === 1) {
          setInvisibleSearches([]);
        }
      }
    }
  }, [usedSearches, currentSearch]);

  // Cleanup function
  useEffect(() => {
    return () => {
      dispatch(resultsActions.clearSearchesResults());
      setIsPopupOpened(false);
      // setCurrentSearch(null);
      setInvisibleSearches([]);
      setRenderSearches([]);
      setUpdateRenderSearches(true);
      setUsedSearches([]);
    };
  }, []);

  const handleSearchClick = (search, isInvisible) => {
    // console.log('search: ' + search.id)
    // openPage(`/results?sid=${search.id}&page=1`);
    // setIsPopupOpened(false);
    if (usedSearches && usedSearches.length) {
      if (isMobile) {
        if (usedSearches.length > 1) {
          setInvisibleSearches(
            usedSearches.filter((temp, i) => temp !== search),
          );
        }
        // setIsPopupOpened(false);
      }
    }
    openPage(`/results?sid=${search.id}&page=1`);
    dispatch(resultsActions.setCurrentSearch(currentSearch));
    dispatch(resultsActions.setCurrentSearchSid(Number(search.id)));
    let data;
    if (!!filterNameValue) {
      data = {
        sid: search.id,
        filterNameValue: filterNameValue,
        orderBy: sortValue,
        orderDirection: sortDirection,
        page,
        perPage,
      };
    } else {
      data = {
        sid: search.id,
        orderBy: sortValue,
        orderDirection: sortDirection,
        page,
        perPage,
      };
    }
    dispatch(searchActions.clearSearch());
    search.sectors && dispatch(searchActions.addSectors(search.sectors));
    search.location &&
      dispatch(searchActions.updateLocation(JSON.parse(search.location)));
    dispatch(searchActions.setIndustryDesc(search.description));
    dispatch(searchActions.setProduct(1));
    if (isInvisible) {
      setUpdateRenderSearches(true);
    }

    mixpanelTrackEvent("Recent search selected");
  };

  const startSearchBtnClick = () => {
    if (
      user &&
      !user.company &&
      subscription &&
      (subscription.name === "free" || subscription.free_trial) &&
      searches.length > 1
    ) {
      dispatch(authActions.completeProfileModalShowTrue());
    } else {
      dispatch(mixpanelTrackRequest({ eventName: "Start new search" }));
      dispatch(searchActions.clearSearch());
      dispatch(searchActions.clearLookalikeSearch());
      clearStorage();
      clearStagePage();
      sessionStorage.removeItem(
        `results-${currentSearchId}-${activeView}-FilterSortDirection`,
      );
      sessionStorage.removeItem(
        `results-${currentSearchId}-${activeView}-FilterSortValue`,
      );
      sessionStorage.removeItem(
        `results-page-${activeView}-FilterSortDirection`,
      );
      sessionStorage.removeItem(`results-page-${activeView}-FilterSortValue`);
      openPage("/search-type");
    }
  };

  const handleRemoveSearch = (e, search) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(resultsActions.removeSearchesResult({ sid: search.id }));
    !search.saved && dispatch(deleteSavedSearchesRequest({ sid: search.id }));
    if (invisibleSearches && invisibleSearches.length) {
      if (!isMobile) {
        let prevRenderSearches = renderSearches.filter(
          prevSearch => prevSearch.id !== search.id,
        );
        let prevInvisibleSearches = invisibleSearches;
        prevRenderSearches.push(prevInvisibleSearches[0]);
        prevInvisibleSearches.shift(prevInvisibleSearches[0]);
        setInvisibleSearches(prevInvisibleSearches);
        setRenderSearches(prevRenderSearches);
        currentSearchId === search.id &&
          prevRenderSearches &&
          prevRenderSearches.length &&
          dispatch(
            resultsActions.setCurrentSearchSid(
              Number(prevRenderSearches[0].id),
            ),
          );
      } else {
        const newInvisibleSearches = invisibleSearches.filter(
          prevSearch => prevSearch.id !== search.id,
        );
        setInvisibleSearches(newInvisibleSearches);
        currentSearchId === search.id &&
          invisibleSearches &&
          invisibleSearches.length &&
          dispatch(
            resultsActions.setCurrentSearchSid(Number(invisibleSearches[0].id)),
          );
      }
    } else {
      if (currentSearchId === search.id) {
        if (renderSearches && renderSearches.length > 1) {
          const newRenderSearches = renderSearches.filter(
            prevSearch => prevSearch.id !== search.id,
          );
          renderSearches &&
            renderSearches.length > 1 &&
            dispatch(
              resultsActions.setCurrentSearchSid(
                Number(newRenderSearches[0].id),
              ),
            );
          setRenderSearches(newRenderSearches);
        } else {
          setRenderSearches([]);
          dispatch(resultsActions.clearResults());
          dispatch(resultsActions.clearSearchesResults());
          openPage("results");
        }
      } else {
        const newRenderSearches = renderSearches.filter(
          prevSearch => prevSearch.id !== search.id,
        );
        setRenderSearches(newRenderSearches);
      }
    }
    let data;
    if (!!filterNameValue) {
      data = {
        sid: usedSearches[1] && usedSearches[1].id,
        filterNameValue: filterNameValue,
        orderBy: sortValue,
        orderDirection: sortDirection,
        page,
        perPage,
      };
    } else {
      data = {
        sid: usedSearches[1] && usedSearches[1].id,
        orderBy: sortValue,
        orderDirection: sortDirection,
        page,
        perPage,
      };
    }
  };
  const renderSearchName = (searchValue, order) => {
    if (searchValue?.saved) {
      return searchValue.search_name;
    } else {
      return searchValue.search_name || searchValue.created_ago;
    }
  };

  const handleDesktopSearchTabClick = visibleSearch => {
    if (visibleSearch.id !== currentSearch.id) {
      return handleSearchClick(visibleSearch);
    }
  };

  // const handleRemoveSearchClick = (e, search) => {
  //     e.preventDefault();
  //     e.stopPropagation();
  //     if (!search.saved) {
  //         setSearchToDelete(search);
  //         setOpenRemoveSavedSearchModal(true);
  //     } else {
  //         handleRemoveSearch(e, search);
  //     }
  // };

  return (
    <div className={classes.recentSearchesRoot}>
      {/* <div
                className={classes.recentSearchesHolder}
                data-intercom-target="Previous searches in the top">
                <Box sx={{ width: "fit-content" }}>
                    {renderSearches && renderSearches.length !== 0 && (
                        <RecentSearchesMenu
                            searches={renderSearches}
                            handleClick={handleClick}
                        />
                    )}
                </Box>
            </div> */}
      <LinkBtn
        path="search-type"
        className={classnames([
          classes.startSearchBtn,
          "non-tablet-inline-flex",
        ])}
        variant="primaryDarkContained"
        data-intercom-target="Start new search"
        test-id="start-new-search-button">
        {t("resultsHeader.startSearch")}
      </LinkBtn>
      {openRemoveSavedSearchModal && (
        <RemoveFromRecentSearchesModal
          onClose={() => {
            setOpenRemoveSavedSearchModal(false);
            setSearchToDelete();
          }}
          dialogOpened={openRemoveSavedSearchModal}
          deleteAction={handleRemoveSearch}
          search={searchToDelete}
        />
      )}
    </div>
  );
};

export default RecentSearches;
