import axiosInstance from "../axiosInstance";
import { getSearchUrl, SEARCHES_URL } from "../../constants/endpoints";

class SearchService {
  getRecentSearches = (sid = null) => {
    let urlParams = "";
    if (sid) {
      urlParams = `?sid=${sid}`;
    }
    return axiosInstance
      .get(`${SEARCHES_URL}${urlParams}`)
      .then(res => res.data.data);
  };

  getSearch = sid => {
    return axiosInstance.get(getSearchUrl(sid)).then(res => res.data.data);
  };

  deleteSearch = searchId => {
    return axiosInstance.delete(`${SEARCHES_URL}/${searchId}`);
  };
}

export default SearchService;
