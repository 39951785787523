import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { useHistory, useLocation } from "react-router-dom";
// UI components
import Typography from "../../components/Typography/Typography";
import Button from "../../components/Button/Button";
import Box from "@material-ui/core/Box";
import NavBar from "./components/NavBar";
// Hooks
import { useTranslation } from "react-i18next";
import { useStyles } from "./style.js";
import { useRouterHook } from "../../common/useRouterHook";
import { useDispatch, useSelector } from "react-redux";
import { actions as resultsActions } from "../../store/results/slice";
// Assets
import ThankYouIllustration from "../../assets/icons/ThankYouIllustration.png";
// Redux
import {
  checkSearchCountRequest,
  postSetSearchCountRequest,
  submitSearchRequest,
} from "../../store/search/requests";
import { mixpanelTrackRequest } from "../../store/mixpanel/requests";
import {
  getIndustryDescSelector,
  getPostSearchStatusSelector,
  getSearchCountResetStatusSelector,
  getSearchCountStateSelector,
  getSearchQuerySubsectorsSelector,
  getSearchSectorsSelector,
} from "../../store/search/selectors";
import { getActiveViewSelector } from "../../store/results/selectors";
// Constants
import {
  ML_REQUEST_FAILURE,
  ML_REQUEST_TIMEOUT,
  REQUEST_SUCCESS,
} from "../../constants/statuses";

const Summary = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { openPage } = useRouterHook();
  const dispatch = useDispatch();
  const { state: locationState, pathname } = useLocation();
  const history = useHistory();
  const searchSubsectorsList = useSelector(getSearchQuerySubsectorsSelector());
  const sectorsList = useSelector(getSearchSectorsSelector);
  const industryDescription = useSelector(getIndustryDescSelector);
  const postSearchStatus = useSelector(getPostSearchStatusSelector);
  // const [animateOut, setAnimateOut] = useState(false);
  // const [animateInBack, setAnimateInBack] = useState(false);
  // const [animateOutBack, setAnimateOutBack] = useState(false);
  const [backDialogOpened, setBackDialogOpened] = useState(false);
  const searchCountStateSelector = useSelector(getSearchCountStateSelector);
  const resetCountStatusSelector = useSelector(
    getSearchCountResetStatusSelector,
  );
  const [isSearchValid, setIsSearchValid] = useState(searchCountStateSelector);
  const viewType = useSelector(getActiveViewSelector);
  const handleToggleBackDialog = () => {
    setBackDialogOpened(!backDialogOpened);
  };

  const handleBackToValuer = () => {
    handleToggleBackDialog();
  };

  const handleSubmitSearch = () => {
    dispatch(postSetSearchCountRequest());
    dispatch(
      submitSearchRequest({
        description: industryDescription,
        searchSectors: sectorsList,
        scope: "Find companies that use specific technologies",
        searchType: 1,
        perPage: viewType === "card" ? 10 : 50,
      }),
    );
    setTimeout(() => {
      const path = "/search-results-loading";
      history.replace(path, { from: pathname, someOtherProp: "" });
    }, 200);
  };

  useEffect(() => {
    if (postSearchStatus === REQUEST_SUCCESS) {
      // setAnimateInBack(false);
      // setAnimateOut(true);
      setTimeout(() => {
        openPage("/results");
      }, 200);
    } else if (postSearchStatus === ML_REQUEST_FAILURE) {
      // setAnimateInBack(false);
      // setAnimateOut(true);
      setTimeout(() => {
        openPage("/ml-failure");
      }, 200);
    } else if (postSearchStatus === ML_REQUEST_TIMEOUT) {
      // setAnimateInBack(false);
      // setAnimateOut(true);
      setTimeout(() => {
        openPage("/ml-timeout");
      }, 200);
    }
  }, [postSearchStatus, openPage]);

  useEffect(() => {
    dispatch(
      mixpanelTrackRequest({
        eventName: "Summary page",
      }),
    );
    dispatch(resultsActions.getSearchesStatusUndefined());
  }, [dispatch]);

  const goToIndustryPage = () => {
    // setAnimateOutBack(true);
    setTimeout(() => {
      const hasSubsectors = searchSubsectorsList.length;
      const path = hasSubsectors ? "/subsectors" : "industry";
      history.replace(path, { from: pathname, someOtherProp: "" });
    }, 200);
  };

  // const handleScroll = e => {
  //     const currentScrollY = window.scrollY;
  //     e.deltaY < 0 && e.movementY === 0 && currentScrollY === 0 && pathname === "/summary" && goToIndustryPage();
  // }

  // useEffect(() => {
  //     window.addEventListener("wheel", handleScroll, { passive: false });
  //     return () => window.removeEventListener("wheel", handleScroll);
  // }, []);

  // useEffect(() => {
  //     setAnimateInBack(locationState && locationState.from === "/search-results-loading")
  // }, [locationState]);

  useEffect(() => {
    dispatch(checkSearchCountRequest());
  }, []);
  useEffect(() => {
    setIsSearchValid(
      searchCountStateSelector && searchCountStateSelector.sc_valid,
    );
  }, [searchCountStateSelector]);

  useEffect(() => {
    resetCountStatusSelector === REQUEST_SUCCESS && setIsSearchValid(true);
  }, [resetCountStatusSelector]);

  return (
    <div>
      <div className={classnames([classes.root, classes.roleRoot])}>
        <NavBar goBack={handleBackToValuer} />
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="space-between"
          className={classnames([classes.wrapper, classes.roleWrapper])}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            className={classes.contentWrapper}>
            <div>
              <img
                alt={t("summary.title")}
                className={classes.summaryIllustration}
                src={ThankYouIllustration}
              />
            </div>
            <Typography
              variant="h4"
              align="center"
              color="black"
              className={classes.summaryTitle}>
              <strong>{t("summary.title")}</strong>
            </Typography>
            <Typography
              variant="body"
              className={classes.summarySubtitle}
              align="center">
              {t("summary.subtitle")}
            </Typography>
            <Button
              variant="primary"
              className={classes.submitRequestCta}
              onClick={handleSubmitSearch}
              disabled={!isSearchValid}>
              {t("summary.submitRequest")}
            </Button>
            <Typography
              variant="body"
              className={classes.summarySubtitle}
              align="center">
              {t("summary.belowButton")}
            </Typography>
          </Box>
        </Box>
      </div>
      {/*<div className={classes.arrowsUpDown}>*/}
      {/*    <div className={classes.goUpBtn} onClick={goToIndustryPage}>*/}
      {/*        <img src={PageUpIcon} alt={t("companyName")} />*/}
      {/*    </div>*/}
      {/*    <div className={classnames(classes.goUpBtn, classes.goUpBtnDisabled)} onClick={handleSubmitSearch}>*/}
      {/*        <img src={PageDownIcon} alt={t("companyName")} />*/}
      {/*    </div>*/}
      {/*</div>*/}
    </div>
  );
};

export default Summary;
