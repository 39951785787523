import { call, put, select } from "redux-saga/effects";
import { actions } from "../slice";
import { getSectors, getSubsectors } from "./helpers";

const getSearchSectors = state => state.search.sectors;

function* fetchSectors() {
  try {
    yield put(actions.fetchSectorsPending());
    const sectors = yield call(getSectors);
    yield put(actions.setSectors(sectors));
    yield put(actions.fetchSectorsSuccess());
  } catch (err) {
    yield put(actions.fetchSectorsFailure());
  }
}

function* fetchSubsectors(sectorsSlug) {
  try {
    const searchSectors = yield select(getSearchSectors);
    yield put(actions.fetchSubsectorsPending());
    const response = yield call(getSubsectors, sectorsSlug);
    const { name, slug, subsectors } = response.data[0];
    let subsectorsArray = subsectors;
    const selectedSearchSector = searchSectors.find(
      ({ slug }) => slug === sectorsSlug,
    );
    const selectedSubsectors =
      selectedSearchSector && selectedSearchSector.subsectors;
    let selectedSector;
    if (!!selectedSubsectors) {
      selectedSubsectors.forEach(subsector => {
        !subsectors.filter(e => e.name === subsector.name).length > 0 &&
          subsectorsArray.push(subsector);
      });
      selectedSector = {
        name,
        slug,
        subsectors: subsectorsArray,
      };
    }
    yield put(
      actions.setSubsectors(
        selectedSubsectors ? selectedSector : response.data[0],
      ),
    );
    yield put(actions.getAllSubsectors(response));
    yield put(actions.fetchSubsectorsSuccess());
  } catch (err) {
    yield put(actions.fetchSubsectorsFailure());
  }
}

export { fetchSectors, fetchSubsectors };
