import { call } from "redux-saga/effects";
import axios from "axios";
import {
  ACCOUNT_URL,
  CHECK_RESET_LINK,
  GET_COOKIES,
  GET_ME,
  getDeleteMemberUrl,
  getHandleInvitationUrl,
  getInvitationUrl,
  getSubscribeUrl,
  LEAVE_TEAM_URL,
  OPEN_CLUSTER_MAP_URL,
  POST_LOGIN,
  POST_LOGOUT,
  POST_REGISTER,
  PROFILE_UPDATE,
  RESET_PASSWORD,
  SEND_RESET_LINK,
  TEAM_URL,
} from "../../../constants/endpoints";

function* login(data) {
  const response = yield call(axios, {
    method: "POST",
    url: `${POST_LOGIN}`,
    data,
    withCredentials: true,
  });
  if (response.data.data) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + response.data.data.access_token;
    axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
  }
  return response.data;
}

function* postResetPassword(data) {
  const response = yield call(axios, {
    method: "POST",
    url: `${RESET_PASSWORD}`,
    data,
    withCredentials: true,
  });
  return response.data;
}

function* postSendResetLink(data) {
  const response = yield call(axios, {
    method: "POST",
    url: `${SEND_RESET_LINK}`,
    data,
    withCredentials: true,
  });
  return response.data;
}

function* postCheckResetLink(data) {
  const response = yield call(axios, {
    method: "POST",
    url: `${CHECK_RESET_LINK}`,
    data,
    withCredentials: true,
  });
  return response.data;
}

function* logout() {
  const response = yield call(axios, {
    method: "POST",
    url: `${POST_LOGOUT}`,
    withCredentials: true,
  });
  return response.data;
}

function* postRegister(registerData) {
  const {
    fullName,
    email,
    password,
    repeatPassword,
    companyName,
    token,
    signUpForFreeTrial,
  } = registerData;
  let data = new FormData();
  data.append("name", fullName);
  data.append("email", email);
  data.append("password", password);
  data.append("password_confirmation", repeatPassword);
  companyName && data.append("company-name", companyName);
  data.append("sign_up_for_free_trial", signUpForFreeTrial);
  token && data.append("token", token);
  const response = yield call(axios, {
    method: "POST",
    url: `${POST_REGISTER}`,
    data,
    withCredentials: true,
  });
  return response.data;
}

function* getSubscribe(registerData) {
  const {
    // fullName,
    // email,
    // password,
    // repeatPassword,
    // companyName,
    plan,
    period,
    amount,
    currency,
  } = registerData;
  // let data = new FormData();
  // fullName && data.append("name", fullName);
  // email && data.append("email", email);
  // password && data.append("password", password);
  // repeatPassword && data.append("password_confirmation", repeatPassword);
  // companyName && data.append("company-name", companyName);
  const response = yield call(axios, {
    method: "GET",
    url: `${getSubscribeUrl(plan, period, amount, currency)}`,
    withCredentials: true,
  });
  return response.data;
}

function* getMe() {
  const response = yield call(axios, {
    method: "GET",
    url: `${GET_ME}`,
    withCredentials: true,
  });
  return response.data;
}

function* postUpdateProfile(data) {
  const response = yield call(axios, {
    method: "POST",
    url: `${PROFILE_UPDATE}`,
    data,
    withCredentials: true,
  });
  return response.data;
}

function* getCookie() {
  const response = yield call(axios, {
    method: "GET",
    url: `${GET_COOKIES}`,
    withCredentials: true,
  });
  return response.data;
}

function* getTeam(team) {
  const response = yield call(axios, {
    method: "GET",
    url: `${team ? `${TEAM_URL}?team=${team}` : TEAM_URL}`,
    withCredentials: true,
  });
  return response.data;
}

function* getLeaveTeam(team) {
  const response = yield call(axios, {
    method: "GET",
    url: `${LEAVE_TEAM_URL}?team=${team}`,
    withCredentials: true,
  });
  return response.data;
}

function* getAccount(team) {
  const response = yield call(axios, {
    method: "GET",
    url: `${ACCOUNT_URL}?team=${team}`,
    withCredentials: true,
  });
  return response.data;
}

function* postTeamMember(email) {
  const response = yield call(axios, {
    method: "POST",
    url: `${getDeleteMemberUrl(email)}`,
    withCredentials: true,
  });
  return response.data;
}

function* getInvitation(token) {
  const response = yield call(axios, {
    method: "GET",
    url: `${getInvitationUrl(token)}`,
    withCredentials: true,
  });
  return response.data;
}

function* getHandleInvitation(token) {
  const response = yield call(axios, {
    method: "GET",
    url: `${getHandleInvitationUrl(token)}`,
    withCredentials: true,
  });
  return response.data;
}

function* postOpenClusterMap() {
  const response = yield call(axios, {
    method: "POST",
    url: `${OPEN_CLUSTER_MAP_URL}`,
    withCredentials: true,
  });
  return response.data;
}

export {
  login,
  logout,
  getMe,
  postUpdateProfile,
  postRegister,
  postResetPassword,
  postSendResetLink,
  postCheckResetLink,
  getCookie,
  getSubscribe,
  getTeam,
  postTeamMember,
  getInvitation,
  getHandleInvitation,
  getAccount,
  getLeaveTeam,
  postOpenClusterMap,
};
