import { all, takeEvery } from "redux-saga/effects";
import {
  checkSearchCountRequest,
  deleteSavedSearchesRequest,
  getMlSummaryRequest,
  postSetSearchCountRequest,
  resetSearchCountRequest,
  submitSearchRequest,
} from "../requests";
import {
  checkSearchCount,
  fetchMlSummary,
  removeSavedSearches,
  resetSearchCount,
  setSearchCount,
  submitSearch,
} from "./workers";

const convertTypeFromAction = actionCreator => actionCreator.toString();

function* watchSubmitSearch() {
    yield takeEvery(
        convertTypeFromAction(submitSearchRequest),
        ({
            payload: {
                description,
                searchSectors,
                location,
                scope,
                searchType,
                lookAlikeName = null,
                perPage,
                resultsLimit,
                scope_guide_step_1,
                scope_guide_step_2,
                scope_guide_step_3,
                scope_guide_step_4,
                scope_guide_step_5,
                multi_tenant_entities,
            },
        }) =>
            submitSearch(
                description,
                searchSectors,
                location,
                scope,
                searchType,
                lookAlikeName,
                perPage,
                resultsLimit,
                scope_guide_step_1,
                scope_guide_step_2,
                scope_guide_step_3,
                scope_guide_step_4,
                scope_guide_step_5,
                multi_tenant_entities,
            ),
    );
}

function* watchCheckSearchCount() {
  yield takeEvery(convertTypeFromAction(checkSearchCountRequest), () =>
    checkSearchCount(),
  );
}

function* watchResetSearchCount() {
  yield takeEvery(convertTypeFromAction(resetSearchCountRequest), () =>
    resetSearchCount(),
  );
}

function* watchSetSearchCount() {
  yield takeEvery(convertTypeFromAction(postSetSearchCountRequest), () =>
    setSearchCount(),
  );
}

function* watchDeleteSavedSearches() {
  yield takeEvery(
    convertTypeFromAction(deleteSavedSearchesRequest),
    ({ payload }) => removeSavedSearches(payload),
  );
}

function* watchGetMlSummray() {
  const getSummaryTask = yield takeEvery(
    convertTypeFromAction(getMlSummaryRequest),
    ({ payload }) => fetchMlSummary(payload),
  );
  /* yield takeLatest(cancelGetSummaryRequest, function* () {
      yield cancel(getSummaryTask);
  }); */
}

function* saga() {
  yield all([
    watchSubmitSearch(),
    watchCheckSearchCount(),
    watchResetSearchCount(),
    watchSetSearchCount(),
    watchDeleteSavedSearches(),
    watchGetMlSummray(),
  ]);
}

export { saga };
