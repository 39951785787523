import React, { useEffect, useState } from "react";
import * as classnamesArray from "classnames";
// UI components
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import Grow from "@material-ui/core/Grow";
import IconButton from "@material-ui/core/IconButton";
// Hooks
import { useStyles } from "./style";
import { useTranslation } from "react-i18next";

const Modal = ({
  onClose,
  classnames,
  dialogOpened = false,
  children,
  background,
  maxWidth,
  borderRadius,
  closeButtonIcon,
  paddingSpace,
  persistent,
  maxHeight,
  minHeight,
  disableEscapeKeyDown,
  dialogContentClassName,
  dialogFilterClassName,
  dialogContentRef,
}) => {
  const classes = useStyles({
    background,
    maxWidth,
    borderRadius,
    paddingSpace,
    maxHeight,
    minHeight,
  });
  const { t } = useTranslation();

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(window.innerWidth <= 575);
  }, []);

  return (
    <Dialog
      fullScreen={isMobile}
      maxWidth="lg"
      disableEscapeKeyDown={disableEscapeKeyDown}
      TransitionComponent={Grow}
      classes={{
        root: classes.dialogRoot,
        paper: classnamesArray([classes.dialogFilter, dialogFilterClassName]),
        scrollPaper: classes.dialogScrollPaper,
        ...classnames,
      }}
      onClose={(event, reason) => {
        if (reason === "backdropClick" && !persistent) {
          onClose(event, reason);
        }
      }}
      open={dialogOpened}>
      {dialogOpened && !persistent ? (
        <>
          {closeButtonIcon && (
            <IconButton
              className={classes.closeButton}
              onClick={onClose}
              onTouchStart={onClose}>
              <img
                src={closeButtonIcon}
                alt={t("outcome.step2")}
                className={classes.stepIcon}
              />
            </IconButton>
          )}
        </>
      ) : null}
      <MuiDialogContent
        className={classnamesArray([
          classes.dialogContent,
          dialogContentClassName,
        ])}
        ref={dialogContentRef}>
        {children}
      </MuiDialogContent>
    </Dialog>
  );
};
export default Modal;
