import { createStyles, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(
  ({ palette, spacing, shape, typography, breakpoint }) =>
    createStyles({
      dialogRoot: {
        display: "flex",
        justifyContent: "center",
        paddingLeft: 0,
        paddingRight: 0,
        textAlign: "center",
        position: "relative",
        width: "100%",
      },
      dialog: {
        padding: spacing(4),
        background: "#FFFFFFF",
        borderRadius: spacing(2),
        minHeight: 368,
        width: 920,
        maxWidth: 920,
        [breakpoint.down("xxl")]: {
          width: 920,
        },
        [breakpoint.down("md")]: {
          width: 528,
        },
        [breakpoint.down("sm")]: {
          width: "100%!important",
          borderRadius: "unset",
          padding: spacing(2),
        },
      },
      dialogFilter: {
        padding: spacing(3),
        background: "#FFFFFFF",
        borderRadius: shape.borderRadius,
      },
      dialogScrollPaper: {
        maxWidth: "100%",
        width: "920px",
      },
      close: {
        position: "absolute",
        top: 20,
        right: 20,
        cursor: "pointer",
      },
      chip: {
        display: "flex",
        flexWrap: "wrap",
        border: "1px solid #999999",
        padding: "8px 12px",
        borderRadius: 4,
        lineHeight: "12px",
        transition: "all 03.s",
        cursor: "pointer",
        fontSize: "14px",
        color: "#666666",
      },
      activeChip: {
        display: "flex",
        cursor: "pointer",
        border: "1px solid #FD750D",
        padding: "8px 12px",
        borderRadius: 4,
        lineHeight: "12px",
        background: "#FD750D",
        color: "#FFFFFF",
        fontSize: "14px",
        transition: "all 03.s",
      },
      title: {
        fontSize: "28px",
        fontWeight: 400,
        textAlign: "left",
        marginBottom: 12,
      },
      description: {
        textAlign: "left",
        fontWeight: 300,
      },

      examples: {
        display: "flex",
        flexWrap: "wrap",
        columnGap: "8px",
        rowGap: "8px",
        margin: "16px 0",
      },
      divide: { borderBottom: "1px solid #EFEFEF" },
      selectedTitle: {
        fontSize: "20px",
        fontWeight: 500,
        margin: "20px 0",
        textAlign: "left",
      },
      selectedDescription: {
        fontWeight: 300,
        textAlign: "left",
        marginRight: 20,
      },
      button: {
        fontFamily: "GT Flexa",
        fontWeight: 500,
        width: "140px",
        "& button": {
          background: "#FD750D",
        },
      },
      selectedWrapper: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "end",
      },
    }),
);
