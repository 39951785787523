const userConversationSelector = state =>
  state.userConversation.userConversationData;
const getUserConversationStatusSelector = state =>
  state.userConversation.getUserConversationStatus;
const createUserConversationStatusSelector = state =>
  state.userConversation.createUserConversationStatus;
const mlConversationIdSelector = state =>
  state.userConversation.mlConversationId;
const updateUserConversationStatusSelector = state =>
  state.userConversation.updateUserConversationStatus;
const saveUserConversationIdStatusSelector = state =>
  state.userConversation.saveUserConversationIdStatus;
const getPumaSummaryStatusSelector = state =>
  state.userConversation.getPumaSummaryStatus;
const getConversationHistoryStatusSelector = state =>
  state.userConversation.getConversationHistoryStatus;

export {
  userConversationSelector,
  getUserConversationStatusSelector,
  mlConversationIdSelector,
  createUserConversationStatusSelector,
  updateUserConversationStatusSelector,
  saveUserConversationIdStatusSelector,
  getPumaSummaryStatusSelector,
  getConversationHistoryStatusSelector,
};
