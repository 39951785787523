import React, {createRef, useEffect, useRef, useState} from "react";
import classnames from "classnames";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router";
import findIndex from "lodash/findIndex";
import ClickAwayListener from "react-click-away-listener";
import {objectHasEmptyValues} from "../../../utils/objectHasEmptyValues";
import {industryFilterParamMapping} from "../../../constants/filters";
// Hooks
import useStyles from "./style";
import {useTranslation} from "react-i18next";
import {useRouterHook} from "../../../common/useRouterHook";
import {useAuthHook} from "../../../common/useAuthHook";
import {useHistory, useLocation} from "react-router-dom";
import {useAppEventTracking} from "../../../common/useAppEventTracking";
import useLocalStorage from "../../../common/useLocalStorage";
// Components
import Button from "../../../components/Button/Button";
import Typography from "../../../components/Typography/Typography";
import Menu from "../../../components/Menu/Menu";
import Tooltip from "@material-ui/core/Tooltip";
// import { Collapse } from '@material-ui/core';
import CreateProjectModal from "../../Results/components/modals/CreateProjectModal.js";
import TopUpCreditsModal from "../../Company/LockedPage/TopUpCreditsModal/TopUpCreditsModal.js";
import SubindustryDefinitionsModal from "../components/SubindustryDefinitionsModal/SubindustryDefinitionsModal.js";
import PreviewClusterModal from "../components/PreviewClusterModal/PreviewClusterModal.js";
import ClusterMapModal from "../components/ClusterMapModal/ClusterMapModal.js";
import ContentLoader from "../../../components/ContentLoader/ContentLoader.js";
import ProfileCompanies from "../../Technologies/ProfileCompanies/ProfileCompanies";
// Assets
import DefaultIndustryAvatar from "../../../assets/icons/Industry.svg";
import {ReactComponent as MoreIcon} from "../../../assets/icons/icon-more.svg";

import InfoLetter from "../../../assets/icons/InfoLetter.svg";
import InfoIconGreen from "../../../assets/icons/InfoIconGreen.svg";
import {ReactComponent as ArrowRight} from "../../../assets/icons/ArrowRight.svg";
import {addOpacity, mix_hexes} from "../../../utils/colorMixer";
import {ReactComponent as QuestionMark} from "../../../assets/icons/QuestionMark.svg";
// Redux
import {actions as resultsActions} from "../../../store/results/slice";
import {getActiveViewSelector, getDisplayFiltersBySidSelector,} from "../../../store/results/selectors";
import {fetchIndustryRequest} from "../../../store/industries/requests";
import {
  getIndustryCompaniesSelector,
  getIndustryCompaniesTotalCount,
  getIndustryCompaniesTotalPages,
  getIndustrySelector,
  getIndustryStatusSelector
} from "../../../store/industries/selectors";
import {actions as industriesActions} from "../../../store/industries/slice";
import {addItemToProjectRequest, checkProjectsRequest,} from "../../../store/projects/requests";
import {getMeStealthRequest, openClusterMapRequest,} from "../../../store/auth/requests";
import {getOpenClusterMapStatusSelector,} from "../../../store/auth/selectors";
import {
  getAddItemToProjectStatusSelector,
  getCheckProjectsSelector,
  getCheckProjectsStatusSelector,
} from "../../../store/projects/selectors";
import {mixpanelTrackRequest} from "../../../store/mixpanel/requests";
import {REQUEST_SUCCESS} from "../../../constants/statuses";
import {Shared} from "../../../utils/shared";
import UpgradeProjectsModal from "../components/UpgradeProjectsModal/UpgradeProjectsModal";

// const ExpandMore = styled((props) => {
//     const { expand, ...other } = props;
//     return <IconButton {...other} />;
// })(({ theme, expand }) => ({
//     transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
//     marginLeft: 'auto',
//     transition: theme.transitions.create('transform', {
//         duration: theme.transitions.duration.shortest,
//     }),
// }));

const subsectorColors = [
  "#EF617E",
  "#FFA694",
  "#6EAD6E",
  "#00A5D4",
  "#A286BD",
  "#D03F38",
  "#F5C343",
  "#007A5E",
  "#0B295F",
  "#9D5C0D",
  "#AA14F0",
  "#113CFC",
  "#CD5D7D",
  "#F7FD04",
  "#58391C",
];

const IndustryProfile = ({ className, profile, add }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { user, isAuthenticated } = useAuthHook();
  const { openPage, goBack } = useRouterHook();
  const { mixpanelTrackEvent, intercomTrackEvent } = useAppEventTracking();
  // state
  const [imageSrc, setImageSrc] = useState(DefaultIndustryAvatar);
  const [companyAvatarSrc, setCompanyAvatarSrc] = useState(
    DefaultIndustryAvatar,
  );
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [readMore, setReadMore] = useState(false);
  const [descReadMore, setDescReadMore] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState({});
  const { slug } = useParams();
  const getDisplayFilters =
    useSelector(getDisplayFiltersBySidSelector(slug)) || {};
  const [selectedSubsector, setSelectedSubsector] = useState([]);
  const [mappedSubsectors, setMappedSubsectors] = useState([]);
  const cardRef = createRef(null);
  const seeClusterModalRef = useRef(null);
  const sectorsTagsWrapperRef = createRef(null);
  const subindustriesTagsWrapperRef = createRef(null);
  const technologiesTagsWrapperRef = createRef(null);
  const dispatch = useDispatch();
  const industry = useSelector(getIndustrySelector);
  const industryStatus = useSelector(getIndustryStatusSelector);
  const history = useHistory();
  const { pathname, search } = useLocation();
  const descRef = createRef(null);
  const [descHeight, setDescHeight] = useState(0);
  const [location, setLocation] = useState("");
  const isFreeOrGuest =
    !user || (user && user.subscription && user.subscription.name === "free");
  const getCheckedProjects = useSelector(getCheckProjectsSelector);
  const getCheckedProjectsStatusSelector = useSelector(
    getCheckProjectsStatusSelector,
  );
  const getAddItemToProjectStatus = useSelector(
    getAddItemToProjectStatusSelector,
  );
  const openClusterMapStatus = useSelector(getOpenClusterMapStatusSelector);
  const [checkedProjects, setCheckedProjects] = useState(getCheckedProjects);
  const [showAddToProjectDialog, setShowAddToProjectDialog] = useState(false);
  const [dialogOpened, setDialogOpened] = useState(false);
  const { getSubscriptionStatus, getExpiredPlanMsg } = Shared({
    getUser: user,
    projects: checkedProjects,
  });
  const [topUpCreditsModalShow, setTopUpCreditsModalShow] = useState(false);
  const [companyIndustry, setCompanyIndustry] = useState(null);
  const [showUpgradeProjectsModal, setShowUpgradeProjectsModal] =
    useState(false);
  const [showSubindustryDefinitionsModal, setShowSubindustryDefinitionsModal] =
    useState(false);
  const [showPreviewClusterModal, setShowPreviewClusterModal] = useState(false);
  const [showClusterMapModal, setShowClusterMapModal] = useState(false);
  const [showClusterTooltip, setShowClusterTooltip] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [displayedFilters, setDisplayedFilters] = useState([]);
  const [sortValue, setSortValue] = useState("founded_date");
  const [sortDirection, setSortDirection] = useState("desc");
  const [page, setPage] = useState(1);
  //industry companies
  const getIndustryCompanies = useSelector(getIndustryCompaniesSelector);
  const [industryCompanies, setIndustryCompanies] =
    useState(getIndustryCompanies);
  const subscriptionStatus = getSubscriptionStatus();
  const { clearStorage } = useLocalStorage("industry", slug);

  const handleSetSelectedFilters = selectedFilters => {
    setSelectedFilters(selectedFilters);
  };

  const handleSetDisplayedFilters = displayedFilters => {
    setDisplayedFilters(displayedFilters);
  };

  const handleSetSortValue = sortValue => {
    setSortValue(sortValue);
  };

  const handleSetSortDirection = sortDirection => {
    setSortDirection(sortDirection);
  };

  const handleSetPage = page => {
    setPage(page);
  };

  useEffect(() => {
    return () => {
      dispatch(industriesActions.clearIndustry());
    };
  }, []);

  const activeView = useSelector(getActiveViewSelector);

  useEffect(() => {
    getIndustryCompanies && setIndustryCompanies(getIndustryCompanies);
  }, [getIndustryCompanies]);

  const openAddtoProject = e => {
    e.preventDefault();
    e.stopPropagation();
    if (!user) {
      openPage("/sign-in");
    } else if (isFreeOrGuest) {
      setShowUpgradeProjectsModal(true);
    } else {
      !showAddToProjectDialog &&
        dispatch(checkProjectsRequest({ companyId: selectedCompany.cId }));
      setShowAddToProjectDialog(!showAddToProjectDialog);
    }
  };
  const openCreateProjectDialog = e => {
    e.preventDefault();
    e.stopPropagation();
    setCompanyIndustry(selectedCompany);
    setDialogOpened(true);
  };
  const addSelectedCompanyToProject = (e, value) => {
    e.preventDefault();
    e.stopPropagation();
    if (value.is_in_project) {
      return;
    } else {
      let companies = selectedCompany.cId;
      localStorage.setItem("addToProjectCompany", selectedCompany.name);
      localStorage.setItem("addToProjectProject", value.project);
      dispatch(
        addItemToProjectRequest({
          project: value.slug,
          companies: [companies],
        }),
      );
    }
  };
  const handleToggleDialog = () => {
    setDialogOpened(false);
  };
  const handleNewProjectCreated = () => {
    setDialogOpened(false);
  };
  const handleShowTopUpModal = () => {
    setTopUpCreditsModalShow(true);
  };
  const handleBuyingTopUpCreditsDialog = () => {
    setTopUpCreditsModalShow(false);
  };
  const handleClosingTopUpCreditsDialog = () => {
    setTopUpCreditsModalShow(false);
  };
  const handleCloseSubindustryDefinitionsModal = () => {
    setShowSubindustryDefinitionsModal(false);
  };
  const handleClosePreviewClusterModal = () => {
    setShowPreviewClusterModal(false);
  };
  const handleCloseClusterMapModal = () => {
    setShowClusterMapModal(false);
  };

  useEffect(() => {
    const industryFilterSubsectorsArray =
      getDisplayFilters.subsectors &&
      typeof getDisplayFilters.subsectors === "string" &&
      getDisplayFilters.subsectors.split(",");
    if (mappedSubsectors && mappedSubsectors.length > 0) {
      let array = [];
      industryFilterSubsectorsArray &&
        industryFilterSubsectorsArray.forEach(filterSubsector =>
          array.push(
            mappedSubsectors.find(sub => sub.slug === filterSubsector),
          ),
        );
      setSelectedSubsector(array);
      const subsectorsString = array && array.map(e => e.slug).join(",");
      let value = "";
      Object.keys(selectedFilters).forEach((filter, key) => {
        if (
          selectedFilters[filter] !== "" &&
          selectedFilters[filter] &&
          selectedFilters[filter].length !== 0
        ) {
          value = !!industryFilterParamMapping[filter]
            ? value.concat(
                `${industryFilterParamMapping[filter]}=${
                  selectedFilters[filter] === "10001+"
                    ? "10001%2B"
                    : key === "subsectors"
                    ? subsectorsString
                    : selectedFilters[filter]
                }`,
              )
            : value;
        }
      });
    }
  }, [mappedSubsectors]);

  useEffect(() => {
    const query = new URLSearchParams(search);
    const subsectorSlug = query.get("subsectorSlug");
    const subsector =
      mappedSubsectors &&
      mappedSubsectors.length > 0 &&
      mappedSubsectors.find(subsector => subsector.slug === subsectorSlug);
    if (subsectorSlug && mappedSubsectors && mappedSubsectors.length > 0) {
      setSelectedSubsector([subsector]);
      let value = "";
      Object.keys(selectedFilters).forEach((filter, key) => {
        if (
          selectedFilters[filter] !== "" &&
          selectedFilters[filter] &&
          selectedFilters[filter].length !== 0
        ) {
          value = !!industryFilterParamMapping[filter]
            ? value.concat(
                `${industryFilterParamMapping[filter]}=${
                  selectedFilters[filter] === "10001+"
                    ? "10001%2B"
                    : key === "subsectors"
                    ? subsector
                    : selectedFilters[filter]
                }`,
              )
            : value;
        }
      });
      dispatch(
        resultsActions.setResultsDisplayFilters({
          sid: slug,
          displayedFilters: {
            filterSid: slug,
            sortValue: sortValue || [],
            sortDirection: sortDirection || [],
            sector: displayedFilters.sector || [],
            subsector: displayedFilters.subsector || [],
            subsectors: subsector,
            stage: displayedFilters.stage || [],
            fundingMin: displayedFilters.fundingMin || "",
            fundingMax: displayedFilters.fundingMax || "",
            teamsizeMin: displayedFilters.teamsizeMin || "",
            teamsizeMax: displayedFilters.teamsizeMax || "",
            location: displayedFilters.location || [],
            yearMin: displayedFilters.yearMin || "",
            yearMax: displayedFilters.yearMax || "",
            matchingScoreMin: displayedFilters.matchingScoreMin,
            matchingScoreMax: displayedFilters.matchingScoreMax || "",
            regions: displayedFilters.regions || [],
            search: displayedFilters.search || "",
            storageResultsFilterNameValue: value || "",
            storageResultsFilterPage: Number(page) || 1,
          },
        }),
      );
    }
    if (getAddItemToProjectStatus === REQUEST_SUCCESS) {
      setShowAddToProjectDialog(false);
    }
  }, [getAddItemToProjectStatus, mappedSubsectors]);

  useEffect(() => {
    getCheckedProjectsStatusSelector === REQUEST_SUCCESS &&
      setCheckedProjects(getCheckedProjects);
  }, [
    getCheckedProjectsStatusSelector,
    setCheckedProjects,
    getCheckedProjects,
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setLocation(window.location.pathname);
    sessionStorage.setItem("history", "industryProfile");
  }, []);

  useEffect(() => {
    if (!!sessionStorage.getItem("companyFrom")) {
      let company;
      !!isAuthenticated &&
        mappedSubsectors &&
        mappedSubsectors.forEach((subsector, index) => {
          company =
            subsector.companies &&
            subsector.companies.length > 0 &&
            subsector.companies.find(
              company =>
                company.cId.toString() ===
                sessionStorage.getItem("companyFrom"),
            );
          if (!!company) {
            setSelectedCompany(company);
            sessionStorage.removeItem("companyFrom");
          }
        });
    }
  }, [mappedSubsectors]);

  useEffect(() => {
    dispatch(fetchIndustryRequest({ slug: slug }));
  }, [dispatch, slug]);

  // const handleDelete = () => {
  //     setShowWarningModal(!showWarningModal);
  // };

  // const handleSuccess = () => {
  //     setShowSuccessModal(!showSuccessModal);
  // };

  useEffect(() => {
    if (industry && industry.image) {
      setImageSrc(industry.image);
    } else {
      setImageSrc(DefaultIndustryAvatar);
    }
    setMappedSubsectors(
      industry &&
        industry.sub_industries &&
        industry.sub_industries.map((subsector, i) => {
          return {
            ...subsector,
            color: subsectorColors[i],
          };
        }),
    );
  }, [industry]);

  useEffect(() => {
    if (selectedCompany && selectedCompany.icon) {
      setCompanyAvatarSrc(selectedCompany.icon);
    } else {
      setCompanyAvatarSrc(DefaultIndustryAvatar);
    }
    setDescReadMore(false);
  }, [selectedCompany]);

  useEffect(() => {
    setDescHeight(
      (descRef && descRef.current && descRef.current.offsetHeight) || 0,
    );
  }, [descRef]);

  const onImgError = () => {
    setImageSrc(DefaultIndustryAvatar);
  };

  const onCompanyAvatarError = () => {
    setCompanyAvatarSrc(DefaultIndustryAvatar);
  };

  const handleMenuClick = () => {
    setIsMenuOpened(prevIsMenuOpened => !prevIsMenuOpened);
  };

  const handleCompanyClick = (e, company, subsector) => {
    e.stopPropagation();
    e.preventDefault();
    setShowAddToProjectDialog(false);
    if (
      (selectedSubsector.length === 0 ||
        selectedSubsector.some(s => s.name === subsector.name)) &&
      pageWidth > 1024
    ) {
      selectedCompany === company
        ? setSelectedCompany({})
        : setSelectedCompany(company);
    }
  };

  const handleSubsectorSelect = subsector => {
    const selectedSubsectorArray = [...selectedSubsector];
    const subsectorIndex = findIndex(
      selectedSubsectorArray,
      selectedSubsectorArray => selectedSubsectorArray.slug === subsector.slug,
    );
    if (subsectorIndex !== -1) {
      selectedSubsectorArray.splice(subsectorIndex, 1);
    } else {
      selectedSubsectorArray.push(subsector);
    }
    setSelectedSubsector(selectedSubsectorArray);
    const subsectorsString =
      selectedSubsectorArray &&
      selectedSubsectorArray.map(e => e.slug).join(",");
    let value = "";
    Object.keys(selectedFilters).forEach((filter, key) => {
      if (
        selectedFilters[filter] !== "" &&
        selectedFilters[filter] &&
        selectedFilters[filter].length !== 0
      ) {
        value = !!industryFilterParamMapping[filter]
          ? value.concat(
              `${industryFilterParamMapping[filter]}=${
                selectedFilters[filter] === "10001+"
                  ? "10001%2B"
                  : key === "subsectors"
                  ? subsectorsString
                  : selectedFilters[filter]
              }`,
            )
          : value;
      }
    });
    dispatch(
      resultsActions.setResultsDisplayFilters({
        sid: slug,
        displayedFilters: {
          filterSid: slug,
          sortValue: sortValue || [],
          sortDirection: sortDirection || [],
          sector: displayedFilters.sector || [],
          subsector: displayedFilters.subsector || [],
          subsectors: subsectorsString,
          stage: displayedFilters.stage || [],
          fundingMin: displayedFilters.fundingMin || "",
          fundingMax: displayedFilters.fundingMax || "",
          teamsizeMin: displayedFilters.teamsizeMin || "",
          teamsizeMax: displayedFilters.teamsizeMax || "",
          location: displayedFilters.location || [],
          yearMin: displayedFilters.yearMin || "",
          yearMax: displayedFilters.yearMax || "",
          matchingScoreMin: displayedFilters.matchingScoreMin,
          matchingScoreMax: displayedFilters.matchingScoreMax || "",
          regions: displayedFilters.regions || [],
          search: displayedFilters.search || "",
          storageResultsFilterNameValue: value || "",
          storageResultsFilterPage: Number(page) || 1,
        },
      }),
    );
    clearStorage();
    // selectedSubsector === subsector ? setSelectedSubsector({}) : setSelectedSubsector(subsector);
  };

  // const handleExpandClick = () => {
  //     setExplanationsExpanded(!explanationsExpanded);
  // };

  const handleOpenCompany = (e, selectedCompany) => {
    e.preventDefault();
    e.stopPropagation();
    localStorage.setItem("industryCompanyFromRoute", location);
    dispatch(
      mixpanelTrackRequest({
        eventName: "Company profiles viewed under industry",
        data: { "Company name": selectedCompany.name },
      }),
    );
    sessionStorage.setItem("history", "industryProfile");
    localStorage.setItem("project_slug", selectedCompany.slug);
    openPage(
      `/companies/${selectedCompany.slug}?cid=${selectedCompany.cId}`,
      true,
    );
  };
  const handleClickAway = e => {
    setSelectedCompany({});
  };

  const backgroundColor = (company, colors, subsector) => {
    const noSubsectorsSelected = selectedSubsector.length === 0;
    if (Object.keys(selectedCompany).length > 0) {
      if (selectedCompany.cId === company.cId) {
        return "#FD750D";
      } else {
        return addOpacity(mix_hexes(...colors), 0.1);
      }
    } else {
      if (noSubsectorsSelected) {
        return addOpacity(mix_hexes(...colors), 0.5);
      } else if (selectedSubsector.some(s => s.color === subsector.color)) {
        return addOpacity(subsector.color, 0.6);
      } else {
        return addOpacity(mix_hexes(...colors), 0.1);
      }
    }
  };

  const border = (company, colors, subsector) => {
    const noSubsectorsSelected = Object.keys(selectedSubsector).length === 0;
    if (Object.keys(selectedCompany).length > 0) {
      if (selectedCompany === company) {
        return "#FD750D";
      } else {
        return addOpacity(mix_hexes(...colors), 0.1);
      }
    } else {
      if (noSubsectorsSelected) {
        return mix_hexes(...colors);
      } else if (selectedSubsector.some(s => s.color === subsector.color)) {
        return subsector.color;
      } else {
        return addOpacity(mix_hexes(...colors), 0.1);
      }
    }
  };

  const openSignInPage = () => {
    //dispatch(mixpanelTrackRequest({ eventName: 'Sign in', data: {"Industry name": industry.name} }));
    history.replace("/sign-in", {
      from: pathname,
      someOtherProp: "",
      mixpanelProps: {
        From: "Industry profile",
        "Industry Name": industry ? industry.name : null,
      },
    });
  };
  const openSignUpPage = () => {
    //dispatch(mixpanelTrackRequest({ eventName: 'Sign up', data: {"Industry name": industry.name} }));
    history.replace("/sign-up", {
      from: pathname,
      someOtherProp: "",
      mixpanelProps: {
        From: "Industry profile",
        "Industry Name": industry ? industry.name : null,
      },
    });
  };
  const handleCloseUpgradeProjectsModal = () => {
    setShowUpgradeProjectsModal(false);
  };

  const pageWidth = document.body.offsetWidth;
  const clusersMapWidth =
    pageWidth > 586 ? 480 : document.body.offsetWidth - 88;

  //industry companies
  const getTotalPagesSelector = useSelector(getIndustryCompaniesTotalPages);
  const getTotalCountSelector = useSelector(getIndustryCompaniesTotalCount);
  const handleOnSortFilterChange = () => {
    // const storageResultsFilterSlug = sessionStorage.getItem(`industry-${slug}-FilterSlug`);
    // const storageResultsFilterPage = sessionStorage.getItem(`industry-${slug}-FilterPage`);
    // const storageResultsFilterSortDirection = sessionStorage.getItem(`industry-${slug}-FilterSortDirection`);
    // const storageResultsFilterSortValue = sessionStorage.getItem(`industry-${slug}-FilterSortValue`);
    const storageResultsFilterSector = sessionStorage.getItem(
      `industry-${slug}-FilterSector`,
    );
    const storageResultsFilterSubsector = sessionStorage.getItem(
      `industry-${slug}-FilterSubsector`,
    );
    const storageResultsFilterStage = sessionStorage.getItem(
      `industry-${slug}-FilterStage`,
    );
    const storageResultsFilterFundingFrom = sessionStorage.getItem(
      `industry-${slug}-FilterFundingFrom`,
    );
    const storageResultsFilterFundingTo = sessionStorage.getItem(
      `industry-${slug}-FilterFundingTo`,
    );
    const storageResultsFilterTeamSizeMin = sessionStorage.getItem(
      `industry-${slug}-FilterTeamSizeMin`,
    );
    const storageResultsFilterTeamSizeMax = sessionStorage.getItem(
      `industry-${slug}-FilterTeamSizeMax`,
    );
    const storageResultsFilterCountry = sessionStorage.getItem(
      `industry-${slug}-FilterCountry`,
    );
    const storageResultsFilterFoundedFrom = sessionStorage.getItem(
      `industry-${slug}-FilterFoundedFrom`,
    );
    const storageResultsFilterFoundedTo = sessionStorage.getItem(
      `industry-${slug}-FilterFoundedTo`,
    );
    const storageResultsFilterValuerScoreFrom = sessionStorage.getItem(
      `industry-${slug}-FilterValuerScoreFrom`,
    );
    const storageResultsFilterValuerScoreTo = sessionStorage.getItem(
      `industry-${slug}-FilterValuerScoreTo`,
    );
    const storageResultsFilterValuerRegions = sessionStorage.getItem(
      `industry-${slug}-FilterValuerRegions`,
    );
    const storageResultsFilterSearch = sessionStorage.getItem(
      `industry-${slug}-FilterSearch`,
    );
    const storageResultsFilters = {
      sector: storageResultsFilterSector || "",
      subsector: storageResultsFilterSubsector || "",
      stage: storageResultsFilterStage || "",
      fundingMin: storageResultsFilterFundingFrom || "",
      fundingMax: storageResultsFilterFundingTo || "",
      teamsizeMin: storageResultsFilterTeamSizeMin || "",
      teamsizeMax: storageResultsFilterTeamSizeMax || "",
      location: storageResultsFilterCountry || "",
      regions: storageResultsFilterValuerRegions || "",
      yearMin: storageResultsFilterFoundedFrom || "",
      yearMax: storageResultsFilterFoundedTo || "",
      matchingScoreMin: storageResultsFilterValuerScoreFrom || "",
      matchingScoreMax: storageResultsFilterValuerScoreTo || "",
      search: storageResultsFilterSearch || "",
    };
    let storageResultsFilterNameValue;
    if (!objectHasEmptyValues(storageResultsFilters)) {
      let hasFilter = false;
      let value = "";
      Object.keys(storageResultsFilters).forEach(filter => {
        if (storageResultsFilters[filter] !== "") {
          hasFilter = true;
          value = value.concat(
            `${industryFilterParamMapping[filter]}=${storageResultsFilters[filter]}&`,
          );
          storageResultsFilterNameValue = value;
        }
      });
      if (!hasFilter) {
        storageResultsFilterNameValue = "";
      }
      hasFilter = false;
    }
  };
  const handleOnSortDirValueChange = () => {};

  const toggleDesc = (e, visibility) => {
    e.preventDefault();
    e.stopPropagation();
    setDescReadMore(visibility);
  };

  const seeDefinitions = () => {
    setShowClusterMapModal(true);
    setTimeout(() => {
      seeClusterModalRef.current.seeDefinition();
    }, 1000);
  };

  const exploreClusterMap = () => {
    mixpanelTrackEvent("Cluster map pressed");
    intercomTrackEvent("Cluster map pressed");
    if (user) {
      if (!user.cm) {
        setShowClusterTooltip(true);
        dispatch(openClusterMapRequest());
      } else {
        setShowClusterMapModal(true);
      }
    } else {
      setShowClusterMapModal(true);
    }
  };

  useEffect(() => {
    openClusterMapStatus === REQUEST_SUCCESS && dispatch(getMeStealthRequest());
  }, [openClusterMapStatus]);

  const switchView = view => {
    setPage(1);
    dispatch(resultsActions.setActiveView(view));
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div>
          <div className={classes.headerTitle}>
            <Typography
              onClick={goBack}
              variant="bodyButton"
              color="secondaryDark"
              component="span"
              className={classes.goBack}>
              <ArrowRight className={classes.goBackIcon} />
              {t("industryProfile.goBack")}
            </Typography>
          </div>
        </div>
        {/* <div>
                    <Button iconMarginLeft className={classes.startSearchBtn} variant="secondary" test-id="share-button">
                        {t("industryProfile.share")}<Share className={classes.shareIcon} />
                    </Button>
                </div> */}
      </div>
      {industryStatus !== REQUEST_SUCCESS ? (
        <ContentLoader />
      ) : (
        <>
          <div className={classnames(classes.cardRoot, className)}>
            <div data-intercom-target="Industry summary">
              <div className={classes.info}>
                <div className={classes.avatar}>
                  <img
                    width="56"
                    height="56"
                    src={imageSrc}
                    alt={industry && industry.name}
                    className={classes.avatar}
                    onError={onImgError}
                  />
                </div>
                <div>
                  <Typography variant="subtitle1" gutterBottom component="div">
                    {industry && industry.name}
                  </Typography>
                </div>
                {profile && !add && (
                  <Menu
                    btnClassName={classes.moreButton}
                    onChange={handleMenuClick}
                    menuItems={[
                      {
                        label: t("industryProfile.editBio"),
                        onClick: () => {},
                      },
                      {
                        label: t("industryProfile.deleteIndustry"),
                        onClick: () =>
                          console.log(t("industryProfile.deleteIndustry")),
                      },
                    ]}>
                    <MoreIcon
                      className={classnames([
                        classes.moreIcon,
                        {
                          [classes.moreIconOpened]: isMenuOpened,
                        },
                      ])}
                    />
                  </Menu>
                )}
              </div>
              <Typography
                variant="bodyButton"
                className={classes.description}
                component="div">
                {industry && industry.description}
              </Typography>
            </div>
            <div data-intercom-target="Subindustries">
              <div className={classes.subsectorsHeader}>
                <div>
                  <Typography
                    variant="body"
                    gutterBottom
                    color="black"
                    component="span"
                    className={classes.subindustriesTitle}>
                    {t("industryProfile.subindustries")}
                    <img
                      src={InfoLetter}
                      className={classes.infoLetterMobile}
                      onClick={() => setShowSubindustryDefinitionsModal(true)}
                    />
                  </Typography>
                  {selectedSubsector.length > 1 && (
                    <Typography
                      variant="body"
                      onClick={() => setSelectedSubsector([])}
                      gutterBottom
                      color="textLight"
                      component="span"
                      className={classes.unselectAll}>
                      {t("industryProfile.unselectAll")}
                    </Typography>
                  )}
                </div>
                <Typography
                  onClick={seeDefinitions}
                  variant="bodyXs"
                  color="textLight"
                  gutterBottom
                  component="div"
                  className={classnames([
                    classes.mono,
                    classes.readDefinitions,
                  ])}>
                  {t("industryProfile.readDefinitions")}
                  <img src={InfoLetter} className={classes.infoLetter} />
                </Typography>
              </div>
              <div className={classes.subsectorsWrapper}>
                {mappedSubsectors &&
                  mappedSubsectors.map((subsector, i) => {
                    return subsector && subsector.companies.length > 0 ? (
                      <Button
                        variant="pill"
                        key={`${i}-button`}
                        className={classnames([
                          classes.sector,
                          classes.selectedSubsector,
                          classes.subsectorColor,
                          {
                            [classes.activeSector]:
                              selectedSubsector &&
                              subsector &&
                              selectedSubsector.some(
                                s => s.name === subsector.name,
                              ),
                          },
                        ])}
                        onClick={() => handleSubsectorSelect(subsector)}
                        {...(selectedSubsector &&
                          selectedSubsector.some(
                            s => s.name === subsector.name,
                          ) && { active: true })}>
                        <div
                          className={classes.color}
                          style={{
                            background: subsector.color,
                          }}></div>
                        <Typography
                          variant="body"
                          color="secondaryDark"
                          component="span"
                          className={classes.subsectorPill}>
                          {subsector.name}
                        </Typography>
                        {/* {subsector.ready && <Ready className={classes.inProgressIcon} />} */}
                      </Button>
                    ) : (
                      <Tooltip
                        classes={{
                          tooltip: classes.tooltip,
                        }}
                        enterDelay={350}
                        key={subsector.slug}
                        title={
                          <Typography variant="body2" color="white">
                            {t(`industryProfile.noCompanies`)}
                          </Typography>
                        }
                        arrow>
                        <Button
                          variant="pill"
                          className={classnames([
                            classes.sector,
                            classes.disabledSector,
                            classes.selectedSubsector,
                            classes.subsectorColor,
                          ])}
                          {...(selectedSubsector &&
                            selectedSubsector.some(
                              s => s.name === subsector.name,
                            ) && { active: true })}>
                          <div
                            className={classes.color}
                            style={{
                              background: subsector.color,
                            }}></div>
                          <Typography
                            variant="body"
                            color="textLight"
                            gutterBottom
                            component="span">
                            {subsector.name}
                          </Typography>
                          {/* {subsector.ready && <Ready className={classes.inProgressIcon} />} */}
                        </Button>
                      </Tooltip>
                    );
                  })}
              </div>
            </div>
            <div className={classes.exploreClusterMapWrap}>
              <div
                className={classes.exploreClusterMapWrap}
                onMouseEnter={() => setShowClusterTooltip(true)}
                onMouseLeave={() => setShowClusterTooltip(false)}>
                <Tooltip
                  className={classes.tooltip1}
                  open={showClusterTooltip}
                  classes={{ tooltip: classes.tooltip }}
                  title={
                    <Typography variant="body2" color="white">
                      {t("industryProfile.seeClusterMapTooltip")}
                    </Typography>
                  }
                  arrow>
                  <img
                    src={InfoIconGreen}
                    className={classes.exploreClusterMapInfo}
                  />
                </Tooltip>
                <Typography
                  variant="bodySmall"
                  color="textLight"
                  className={classes.mono}
                  dangerouslySetInnerHTML={{
                    __html: t("industryProfile.clusterMapHelpText"),
                  }}
                />
              </div>
              <ClickAwayListener
                onClickAway={() => setShowClusterTooltip(false)}>
                <Typography
                  variant="bodyButton"
                  color="black"
                  gutterBottom
                  component="span"
                  onClick={exploreClusterMap}
                  className={classes.showCluserMapButton}>
                  {t("industryProfile.exploreClusterMap")}
                </Typography>
              </ClickAwayListener>
            </div>
            <Typography
              variant="bodyXs"
              color="primaryDark"
              onClick={exploreClusterMap}
              className={classnames([classes.preview, classes.mono])}>
              {t("industryProfile.previewCluster")}
              <QuestionMark className={classes.questionMark} />
            </Typography>
          </div>
          <div className={classes.companiesWrap}>
            <ProfileCompanies
              isIndustry={true}
              isTechnology={false}
              user={user}
              companies={industryCompanies}
              onSortFilterChange={handleOnSortFilterChange}
              onFilterNameValueChange={handleOnSortFilterChange}
              getTotalPages={getTotalPagesSelector}
              getTotalCount={getTotalCountSelector}
              subsectorsQuery={""}
              onSortDirValueChange={handleOnSortDirValueChange}
              selectedSubsectorArray={selectedSubsector}
              id={industry?.id}
              name={industry?.name}
              activeView={activeView}
              setActiveView={switchView}
              handleSetSelectedFilters={handleSetSelectedFilters}
              handleSetDisplayedFilters={handleSetDisplayedFilters}
              handleSetSortValue={handleSetSortValue}
              handleSetSortDirection={handleSetSortDirection}
              handleSetPage={handleSetPage}
            />
          </div>
          {/* <div className={classes.card2Root}>
                    <div className={classes.scrollTo} ref={explanationsRef} />
                    <ExpandMore
                        expand={explanationsExpanded}
                        onClick={handleExpandClick}
                        aria-expanded={explanationsExpanded}
                        aria-label="show more"
                        className={classes.expander}
                    >
                        <ArrowDown />
                    </ExpandMore>
                    <Typography variant="paragraph" color="black" component="div"><strong>{t("industryProfile.subIndustryDefinitions")}</strong></Typography>
                    <Typography variant="bodyXs" className={classes.mono} color="text" component="div">{t("industryProfile.subIndustryDefinitionsLabel")}</Typography>
                    <Collapse in={explanationsExpanded} timeout="auto" unmountOnExit>
                        <div className={classes.subsectorsExplanationsWrapper}>
                            {mappedSubsectors && mappedSubsectors.map(subsector => (
                                <div className={classes.subsectorTitleWrapper} key={`${subsector.slug}-subsector`}>
                                    <div
                                        className={classes.subsectorTitle}
                                        onClick={() => handleSubsectorSelect(subsector)}
                                        {...selectedSubsector === subsector && { active: true }}
                                    >
                                        <div className={classes.color} style={{ background: subsector.color }}></div>
                                        <Typography variant="body" color="secondaryDark" component="div">{subsector.name}</Typography>
                                    </div>
                                    <Typography variant="bodyXs" color="text" component="div">{subsector.description}</Typography>
                                </div>
                            ))}
                        </div>
                    </Collapse>
                </div> */}
        </>
      )}
      <CreateProjectModal
        dialogOpened={dialogOpened}
        companyIndustry={companyIndustry}
        onChange={handleToggleDialog}
        newProjectCreated={handleNewProjectCreated}
      />
      <TopUpCreditsModal
        dialogOpened={topUpCreditsModalShow}
        onBuy={handleBuyingTopUpCreditsDialog}
        onChange={handleClosingTopUpCreditsDialog}
      />
      <UpgradeProjectsModal
        user={user}
        dialogOpened={showUpgradeProjectsModal}
        onChange={handleCloseUpgradeProjectsModal}
      />
      <SubindustryDefinitionsModal
        selectedSubsector={selectedSubsector}
        mappedSubsectors={mappedSubsectors}
        dialogOpened={showSubindustryDefinitionsModal}
        onChange={handleCloseSubindustryDefinitionsModal}
      />
      <PreviewClusterModal
        dialogOpened={showPreviewClusterModal}
        onChange={handleClosePreviewClusterModal}
      />
      <ClusterMapModal
        dialogOpened={showClusterMapModal}
        onChange={handleCloseClusterMapModal}
        mappedSubsectors={mappedSubsectors}
        onClickAway={handleClickAway}
        selectedCompany={selectedCompany}
        selectedSubsector={selectedSubsector}
        pageWidth={pageWidth}
        handleCompanyClick={handleCompanyClick}
        clusersMapWidth={clusersMapWidth}
        border={border}
        backgroundColor={backgroundColor}
        cardRef={cardRef}
        companyAvatarSrc={companyAvatarSrc}
        onCompanyAvatarError={onCompanyAvatarError}
        sectorsTagsWrapperRef={sectorsTagsWrapperRef}
        subindustriesTagsWrapperRef={subindustriesTagsWrapperRef}
        technologiesTagsWrapperRef={technologiesTagsWrapperRef}
        descRef={descRef}
        descReadMore={descReadMore}
        descHeight={descHeight}
        toggleDesc={toggleDesc}
        subscriptionStatus={subscriptionStatus}
        getExpiredPlanMsg={getExpiredPlanMsg}
        openAddtoProject={openAddtoProject}
        showAddToProjectDialog={showAddToProjectDialog}
        setShowAddToProjectDialog={setShowAddToProjectDialog}
        openCreateProjectDialog={openCreateProjectDialog}
        checkedProjects={checkedProjects}
        addSelectedCompanyToProject={addSelectedCompanyToProject}
        handleOpenCompany={handleOpenCompany}
        openSignInPage={openSignInPage}
        openSignUpPage={openSignUpPage}
        readMore={readMore}
        setReadMore={setReadMore}
        classes={classes}
        onImgError={onImgError}
        industry={industry}
        imageSrc={imageSrc}
        handleSubsectorSelect={handleSubsectorSelect}
        setShowSubindustryDefinitionsModal={setShowSubindustryDefinitionsModal}
        setSelectedSubsector={setSelectedSubsector}
        user={user}
        ref={seeClusterModalRef}
        getCheckedProjectsStatusSelector={getCheckedProjectsStatusSelector}
      />
    </div>
  );
};
export default IndustryProfile;
