import React from "react";

export const Shovel = ({ strokeColor = "#C2C9D7" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.31043 13.3636L6.73576 10.9383C7.01545 10.6586 7.3948 10.5015 7.79034 10.5015C8.18589 10.5015 8.56523 10.6586 8.84492 10.9383L13.0632 15.1566C13.3429 15.4363 13.5 15.8156 13.5 16.2112C13.5 16.6067 13.3429 16.986 13.0632 17.2657L10.6378 19.691C8.89057 21.4383 6.05769 21.4383 4.31043 19.691V19.691C2.56317 17.9438 2.56317 15.1109 4.31043 13.3636L4.31043 13.3636Z"
      stroke={strokeColor}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.2176 4.31855L17.2427 3.29343C17.6332 2.9029 18.2664 2.9029 18.6569 3.29343L20.7072 5.34368C21.0977 5.73421 21.0977 6.36737 20.7072 6.75789L19.6821 7.78302C18.7254 8.7397 17.1743 8.7397 16.2176 7.78302V7.78302C15.7582 7.3236 15.5001 6.7005 15.5001 6.05078C15.5001 5.40107 15.7582 4.77796 16.2176 4.31855Z"
      stroke={strokeColor}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.2092 7.78906L8.5 15.4983"
      stroke={strokeColor}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
