import React, { useEffect, useState } from "react";
// Hooks
import { useTranslation } from "react-i18next";
import { useStyles } from "./style.js";
import { useDispatch } from "react-redux";
import { useRouterHook } from "../../../../common/useRouterHook";
//UI components
import Typography from "../../../../components/Typography/Typography";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import Grow from "@material-ui/core/Grow";
import IconButton from "@material-ui/core/IconButton";
//Assets
import CloseIconBlue from "../../../../assets/icons/CloseIconBlue.svg";
import Button from "../../../../components/Button/Button.js";
//Redux
import {
  deleteCompanyRequest,
  deleteProjectRequest,
} from "../../../../store/projects/requests";

const RemoveWarningModal = ({
  dialogOpened,
  onChange,
  result,
  projectSlug,
  project,
  isDeleteProject,
  isProjectPage = false,
  type = "company",
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { goBack } = useRouterHook();
  const deleteCompany = e => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(deleteCompanyRequest({ companyId: result.id, projectSlug }));
    result &&
      localStorage.setItem("deleteCompanyFromProjectCompany", result.name);
    project &&
      localStorage.setItem("deleteCompanyFromProjectProject", project.name);
    onChange();
  };

  const deleteProject = (project, e) => {
    e.preventDefault();
    e.stopPropagation();
    let slug = project.slug;
    localStorage.setItem("deleteProjectName", project.name);
    dispatch(deleteProjectRequest({ slug }));
    isProjectPage ? goBack() : window.location.reload();
    onChange();
  };
  const closeModal = e => {
    e.preventDefault();
    e.stopPropagation();
    onChange();
  };
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    setIsMobile(window.innerWidth <= 575);
  }, []);
  return (
    <Dialog
      fullScreen={isMobile}
      maxWidth="lg"
      TransitionComponent={Grow}
      classes={{
        root: classes.dialogRoot,
        paper: classes.dialog,
        scrollPaper: classes.dialogScrollPaper,
      }}
      onClose={(event, reason) => {
        if (reason === "backdropClick") {
          onChange(event, reason);
        }
      }}
      open={dialogOpened}>
      <IconButton className={classes.closeButton} onClick={e => closeModal(e)}>
        <img
          src={CloseIconBlue}
          alt={t("outcome.step2")}
          className={classes.stepIcon}
        />
      </IconButton>
      <MuiDialogContent className={classes.dialogContent}>
        <div className={classes.flexColumn}>
          {isDeleteProject ? (
            <Typography
              component="div"
              className={classes.sureText}
              variant="subtitle1">
              {t("results.areYouSureRemoveProject", {
                projectName: project && project.name,
              })}
            </Typography>
          ) : (
            <Typography
              component="div"
              className={classes.sureText}
              variant="subtitle1">
              {t("results.areYouSure", {
                type,
                companyName: result && result.name,
              })}
            </Typography>
          )}
          <Typography
            component="div"
            className={classes.actionText}
            variant="body">
            {t("results.actionIrreversible")}
          </Typography>
        </div>
        <div className={classes.flexCenter}>
          {isDeleteProject ? (
            <Button
              onClick={e => deleteProject(project, e)}
              variant="tertiary"
              className={classes.removeButton}>
              {t("results.yesRemoveProject")}
            </Button>
          ) : (
            <Button
              onClick={e => deleteCompany(e)}
              variant="tertiary"
              className={classes.removeButton}>
              {t("results.yesRemove", { type })}
            </Button>
          )}
          <Button
            onClick={e => closeModal(e)}
            variant="tertiary"
            className={classes.noButton}>
            {t("results.noTakeBack")}
          </Button>
        </div>
      </MuiDialogContent>
    </Dialog>
  );
};
export default RemoveWarningModal;
