import { all, takeEvery } from "redux-saga/effects";
import {
  emailValidationRequest,
  sendEmailVerificationRequest,
  startFreeTrialRequest,
  updateFreeTrialRequest,
} from "../requests";
import {
  postStartFreeTrial,
  sendEmailValidation,
  sendEmailVerification,
  updateFreeTrial,
} from "./workers";

const convertTypeFromAction = actionCreator => actionCreator.toString();

function* watchStartFreeTrial() {
  yield takeEvery(
    convertTypeFromAction(startFreeTrialRequest),
    ({ payload: { data } }) => postStartFreeTrial(data),
  );
}

function* watchUpdateFreeTrial() {
  yield takeEvery(
    convertTypeFromAction(updateFreeTrialRequest),
    ({ payload: { data } }) => updateFreeTrial(data),
  );
}

function* watchSendEmailVerification() {
  yield takeEvery(convertTypeFromAction(sendEmailVerificationRequest), () =>
    sendEmailVerification(),
  );
}

function* watchEmailValidation() {
  yield takeEvery(
    convertTypeFromAction(emailValidationRequest),
    ({ payload: { data } }) => sendEmailValidation(data),
  );
}

function* saga() {
  yield all([
    watchStartFreeTrial(),
    watchUpdateFreeTrial(),
    watchSendEmailVerification(),
    watchEmailValidation(),
  ]);
}

export { saga };
