import { createStyles, makeStyles } from "@material-ui/core/styles";

export default makeStyles(({ palette, spacing, breakpoint }) =>
  createStyles({
    root: {
      width: "100%",
      marginTop: spacing(1),
      marginBottom: bottomMarginAdjust => (bottomMarginAdjust ? spacing(3) : 0),
      padding: removePadding =>
        removePadding ? 0 : `${spacing(1)}px ${spacing(3)}px`,
      paddingBottom: removePadding => (removePadding ? 0 : `${spacing(4)}px`),
    },
    lockAlertWrap: {
      backgroundColor: palette.primary.backgroundMedium,
      width: "100%",
      display: "flex",
      alignItems: closeIconPosition => closeIconPosition || "center",
      justifyContent: "space-between",
      padding: `${spacing(1)}px ${spacing(5)}px`,
      border: `1px solid ${palette.primary.dark}`,
      borderRadius: `${spacing(2)}px`,
      boxSizing: "border-box",
      boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
      [breakpoint.down("md")]: {
        flexDirection: "column",
        padding: spacing(3),
      },
    },
    lockAlert: {
      display: "flex",
      alignItems: "center",
    },
    closeIcon: {
      cursor: "pointer",
    },
    content: {
      marginLeft: `${spacing(2)}px`,
      "& .lm-link-cta": {
        color: palette.primary.dark,
        textDecoration: "underline",
        cursor: "pointer",
        fontFamily: "GT Flexa Mono, sans-serif",
      },
      [breakpoint.down("md")]: {
        marginLeft: 0,
        textAlign: "center",
      },
    },
    img: {
      height: lockImg => (lockImg ? "32px" : "48px"),
      [breakpoint.down("md")]: {
        height: lockImg => (lockImg ? "48px" : "48px"),
        marginBottom: spacing(3),
      },
    },
  }),
);
