import { call, put } from "redux-saga/effects";
import { actions } from "../slice";
import { getInvoices } from "../saga/helpers";

function* fetchInvoices() {
  try {
    yield put(actions.getInvoicesPending());
    const response = yield call(getInvoices);
    yield put(actions.setInvoices(response.data));
    yield put(actions.getInvoicesSuccess());
  } catch (err) {
    yield put(actions.getInvoicesFailure());
  }
}

export { fetchInvoices };
