import { createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(
  ({ palette, spacing, typography, breakpoint }) =>
    createStyles({
      root: {
        marginTop: spacing(3),
        padding: spacing(4),
        background: palette.success.lighter,
        border: "none",
        display: "flex",
        flexDirection: "column",
        cursor: "pointer",
      },
      cardHeader: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "start",
      },
      mobileIconsMenu: {
        display: "block",
        [breakpoint.up("lg")]: {
          display: "none",
        },
      },
      companyDescription: {
        "-webkit-line-clamp": 2,
        "-webkit-box-orient": "vertical",
        display: "-webkit-box",
        overflowY: "hidden",
        width: "calc(100% - 16px)",
        minWidth: "fit-content",
        fontWeight: 300,
      },
      cardHeaderButtona: {
        display: "flex",
        alignItems: "flex-end",
        flexDirection: "column",
      },
      cardHeaderActions: {
        display: "none",
        [breakpoint.up("lg")]: {
          display: "flex",
        },
      },
      iconImgBase: {
        display: "flex",
      },
      iconImgHover: {
        display: "none",
      },
      iconButton: {
        padding: 0,
        margin: "0 auto",
        "&:hover": {
          "& $iconImgBase": {
            display: "none",
          },
          "& $iconImgHover": {
            display: "flex",
          },
        },
        [breakpoint.up("lg")]: {
          marginLeft: spacing(2),
        },
      },
      moreIcon: {
        fill: palette.secondary.dark,
      },
      moreIconOpened: {
        fill: palette.primary.dark,
      },
      cardHeaderTitle: {
        display: "flex",
        flexDirection: "column",
      },
      cardHeaderSubTitle: {
        marginTop: spacing(1),
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
      },
      cardHeaderSearchType: {
        background: palette.common.white,
        color: palette.common.black,
        border: `1px solid ${palette.common.black}`,
        borderRadius: spacing(1),
        padding: `4px ${spacing(2)}px`,
        marginRight: spacing(1),
        fontFamily: "GT Flexa Mono, sans-serif",
      },
      title: {
        color: palette.secondary.light,
      },
      results: {
        color: palette.secondary.light,
      },
      dateTime: {
        color: palette.text.lighter,
        fontFamily: "GT Flexa Mono, sans-serif",
      },
      tag: {
        marginRight: spacing(1),
        marginTop: spacing(1),
      },
      typographyTag: {
        alignItems: "center",
        background: palette.primary.backgroundLight,
        height: 20,
        color: palette.text.main,
        padding: "4px 12px",
        borderRadius: "8px",
        display: "inline-flex",
        marginTop: spacing(1),
        cursor: "pointer",
        ...typography.caption,
      },
      sectors: {
        marginTop: spacing(2),
        display: "flex",
        flexWrap: "wrap",
      },
      tagsWrap: {
        paddingTop: spacing(3),
        display: "flex",
        flexDirection: "column",
        minWidth: 288,
        "&:nth-child(n + 2)": {
          marginRight: spacing(5),
        },
      },
      tags: {
        display: "flex",
        flexWrap: "wrap",
        marginTop: spacing(1),
      },
      inputText: {
        maxWidth: 616,
        fontSize: 14,
        marginTop: spacing(1),
        color: palette.grey.main,
      },
      textActions: {
        background: palette.history,
        marginTop: spacing(4),
        marginLeft: 0,
        width: "100%",
        marginBottom: -spacing(3),
        paddingBottom: spacing(3),
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        [breakpoint.down("lg")]: {
          flexDirection: "column",
          justifyContent: "center",
        },
      },
      viewResults: {
        margin: `${spacing(3)}px auto 0 auto`,
        background: palette.primary.backgroundLight,
        color: palette.primary.dark,
        border: `1px solid ${palette.primary.dark}`,
        fontFamily: "GT Flexa Mono, sans-serif",
        "&:active": {
          border: `2px solid ${palette.primary.backgroundLight}`,
          background: palette.primary.dark,
          color: palette.primary.backgroundLight,
        },
      },
      sectorName: {
        color: palette.secondary.light,
      },
      searchDescription: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
      },
      searchText: {
        wordBreak: "break-all",
        "-webkit-line-clamp": 2,
        "-webkit-box-orient": "vertical",
        display: "-webkit-box",
        overflowY: "hidden",
        width: "100%",
        marginTop: spacing(1),
        fontWeight: 300,
      },
      companyName: {
        marginBottom: 4,
        fontWeight: "700",
      },
      companyInfo: {
        display: "flex",
        flexWrap: "wrap",
        marginTop: spacing(2),
      },
      companyInfoLabels: {
        display: "flex",
        flexDirection: "column",
        width: "calc(100% / 3)",
        [breakpoint.up("md")]: {
          width: "calc(100% / 6)",
        },
      },
      companyInfoLabel: {
        fontWeight: 500,
      },
      companyInfoValue: {
        fontFamily: "GT Flexa Mono, sans-serif",
        color: palette.text.light,
      },
      companyFundings: {
        color: palette.success.dark,
      },
      companyWebsite: {
        color: palette.secondary.dark,
        fontFamily: "GT Flexa, sans-serif",
        wordBreak: "break-all",
      },
      expander: {
        position: "absolute",
        top: 40,
        right: 32,
      },
      deleteActionModal: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
        padding: spacing(4),
        maxWidth: "420px",
        margin: "0 auto",
        textAlign: "center",
      },
      deleteSubtitle: {
        color: palette.text.light,
        marginBottom: spacing(2),
      },
      deleteActionButtons: {
        display: "flex",
        marginTop: spacing(6),
      },
      cancelButton: {
        color: palette.secondary.dark,
        background: palette.common.white,
        border: `1px solid ${palette.secondary.dark}`,
        marginRight: spacing(2),
      },
      deleteButton: {
        background: palette.error.main,
        color: palette.common.white,
        border: "none",
        fontFamily: "GT Flexa, sans-serif",
      },
      deleteButtonIcon: {
        stroke: "none !important",
        marginLeft: spacing(1),
        marginRight: `${spacing(0)} !important`,
      },
      searchName: {
        fontWeight: 500,
      },
      searchTextTitle: {
        fontWeight: 500,
      },
    }),
);
