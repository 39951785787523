import React, {useEffect} from "react";
import classnames from "classnames";
import {useDispatch} from "react-redux";
// UI components
import Typography from "../../components/Typography/Typography";
import Button from "../../components/Button/Button";
import Box from "@material-ui/core/Box";
import NavBar from "./components/NavBar";
import Tooltip from "@material-ui/core/Tooltip";
// Hooks
import {useTranslation} from "react-i18next";
import {useStyles} from "./style.js";
import {useRouterHook} from "../../common/useRouterHook";
import {useSectorsHook} from "./hooks/useSectorsHook";
import {useAppEventTracking} from "../../common/useAppEventTracking";
// Redux
import {fetchSectorsRequest} from "../../store/sectors/requests";
// Assets

const Industry = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { openPage } = useRouterHook();
  const dispatch = useDispatch();
  const { mixpanelTrackEvent } = useAppEventTracking();
  const { allSectors, handleSectorSelect, selectedSectors } = useSectorsHook();
  useEffect(() => {
    mixpanelTrackEvent("Select sectors");
    dispatch(fetchSectorsRequest());
  }, [dispatch]);

  const nextStepBtnClick = () => {
    //Make string representation of slected sectors and subsectors
    let selectedSectorString = "";
    if (selectedSectors.length) {
      for (let index in selectedSectors) {
        const sector = selectedSectors[index];
        selectedSectorString += sector.name;
        if (sector.subsectors && sector.subsectors.length) {
          selectedSectorString += "(Subsectors: ";
          for (let sub_index in sector.subsectors) {
            const subSector = sector.subsectors[sub_index];
            selectedSectorString += subSector.name + ", ";
          }
          selectedSectorString = selectedSectorString.slice(0, -2);
          selectedSectorString += ")";
        }
        selectedSectorString += ", ";
      }
      selectedSectorString = selectedSectorString.slice(0, -2);
    }
    mixpanelTrackEvent("Continue to subsectors", {
      "User input": selectedSectorString,
    });
    setTimeout(() => {
      selectedSectors.length ? openPage("subsectors") : openPage("summary");
    }, 200);
  };

  return (
    <div>
      <div className={classnames([classes.root, classes.roleRoot])}>
        <NavBar />
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="space-between"
          className={classnames([classes.wrapper, classes.roleWrapper])}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            className={classes.contentWrapper}>
            <Typography
              variant="subtitle1"
              color="black"
              className={classes.focusTitle}
              align="center">
              {t("focus.title")}
            </Typography>
            <Typography
              className={classes.focusSubtitle}
              variant="body"
              color="text">
              {t("focus.selectFive")}
            </Typography>
            <div className={classes.sectors}>
              {allSectors.map(sector => (
                <Tooltip
                  enterDelay={350}
                  key={sector.slug}
                  title={
                    <Typography variant="body2" color="white">
                      {t(
                        `industry.${
                          selectedSectors.some(i => i.slug === sector.slug)
                            ? "remove"
                            : "addIndustry"
                        }`,
                      )}
                    </Typography>
                  }
                  arrow>
                  <Button
                    variant="pill"
                    {...(selectedSectors.some(i => i.slug === sector.slug) && {
                      active: true,
                    })}
                    disabled={
                      selectedSectors.length === 5 &&
                      !selectedSectors.some(i => i.slug === sector.slug)
                    }
                    className={classes.sector}
                    onClick={() =>
                      handleSectorSelect(
                        sector,
                        selectedSectors.some(i => i.slug === sector.slug),
                      )
                    }>
                    {sector.name}
                  </Button>
                </Tooltip>
              ))}
            </div>
            <div>
              <Button
                variant="primary"
                className={classes.continueFocusCta}
                onClick={nextStepBtnClick}>
                {t("industry.continue")}
              </Button>
              <Button
                variant="tertiary"
                className={classes.skipFocusCta}
                onClick={nextStepBtnClick}>
                {t("industry.skip")}
              </Button>
            </div>
          </Box>
        </Box>
      </div>
      {/*<div className={classes.arrowsUpDown}>*/}
      {/*  <div className={classes.goUpBtn} onClick={goToPreviousPage}>*/}
      {/*    <img src={PageUpIcon} alt={t("companyName")} />*/}
      {/*  </div>*/}
      {/*  <div className={classes.goUpBtn} onClick={nextStepBtnClick}>*/}
      {/*    <img src={PageDownIcon} alt={t("companyName")} />*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>
  );
};

export default Industry;
