import React, { createRef, useEffect, useState } from "react";
import classnames from "classnames";
import { objectHasEmptyValues } from "../../../utils/objectHasEmptyValues";
import { filterParamMapping } from "../../../constants/filters";
// Hooks
import { useTranslation } from "react-i18next";
import { useStyles } from "./style.js";
import { useParams } from "react-router";
import { useAuthHook } from "../../../common/useAuthHook";
import { useFreeTrialHook } from "../../Layout/hooks/useFreeTrialHook";
import { useRouterHook } from "../../../common/useRouterHook";
import { useAppEventTracking } from "../../../common/useAppEventTracking";
//Components
import Header from "./Header/Header.js";
import Typography from "../../../components/Typography/Typography";
import Button from "../../../components/Button/Button";
import ContentLoader from "../../../components/ContentLoader/ContentLoader.js";
import TechnologyOverview from "../TechnologyOverview/TechnologyOverview.js";
import ProfileCompanies from "../ProfileCompanies/ProfileCompanies.js";
import LockAlert from "../../../components/LockAlert/LockAlert.js";
import OrderReportModal from "../Components/OrderReportModal/OrderReportModal.js";
import TopUpCreditsModal from "../../Company/LockedPage/TopUpCreditsModal/TopUpCreditsModal.js";
import EnrichRequestModal from "../../Company/LockedPage/EnrichRequestModal/EnrichRequestModal.js";
import OrderReceivedModal from "../../Company/LockedPage/OrderReceivedModal/OrderReceivedModal.js";
import SeeSectorsModal from "../../../components/SeeSectorsModal/SeeSectorsModal.js";
import Link from "../../../components/Link/Link";
import AreYouSureModal from "../../../components/AreYouSureModal/AreYouSureModal";
//Redux
import { useDispatch, useSelector } from "react-redux";
import { fetchSearchesRequest } from "../../../store/results/requests";
import {
  fetchTechnologyRequest,
  filterTechnologyCompaniesRequest,
} from "../../../store/technologies/requests.js";
import {
  getFilterTechnologyCompaniesTotalPagesSelector,
  getTechnologyCompaniesSelector,
  getTechnologyStateSelector,
  getTechnologyStatusSelector,
  getTotalCountSelector,
} from "../../../store/technologies/selectors.js";
import { actions as resultsActions } from "../../../store/results/slice";
import {
  REQUEST_SUCCESS,
  REQUEST_UNDEFINED,
} from "../../../constants/statuses.js";
import {
  getCreditsSelector,
  getSubscriptionSelector,
  getUpdateProfileStatusSelector,
  getUserSelector,
} from "../../../store/auth/selectors";
import { actions as industriesActions } from "../../../store/industries/slice";
import { actions as authActions } from "../../../store/auth/slice";
import { actions as technologiesActions } from "../../../store/technologies/slice";
import {
  getActiveViewSelector,
  getSearchesSelector,
} from "../../../store/results/selectors";
import ConfirmFreeTrialStartModal from "../../FreeTrial/ConfirmFreeTrialStartModal/ConfirmFreeTrialStartModal";
//Assets
import NotEnoughCreditsModal from "../../Company/LockedPage/NotEnoughCreditsModal/NotEnoughCreditsModal.js";
import { ReactComponent as DeepDive } from "../../../assets/icons/deepDive.svg";
//Constants
import { getPricingPage } from "../../../constants/endpoints";
import { ReactComponent as TooltipCheck } from "../../../assets/icons/TooltipCheck.svg";
import TextField from "../../../components/TextField/TextField";
import { exportCsvRequest } from "../../../store/orders/requests";
import { getMeRequest } from "../../../store/auth/requests";

const TechnologiesProfile = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  let { slug } = useParams();

  // if trying to use route for technology preview
  if (slug === "telemedicine") {
    // TODO@lex: Why was this implemented? It creates a bug with the technology preview link, sends slug as null
    // slug = null;
  }
  const {
    isAuthenticated,
    user,
    isGuestUser,
    isExpiredUser,
    isPayingUser,
    isFreeUser,
    getMeStatus,
  } = useAuthHook();
  const { openPage } = useRouterHook();
  //selectors
  const getTechnologySelector = useSelector(getTechnologyStateSelector);
  const getTechnologyStatus = useSelector(getTechnologyStatusSelector);
  const getTechnologyCompanies = useSelector(getTechnologyCompaniesSelector);
  const getUser = useSelector(getUserSelector);
  const getCredits = useSelector(getCreditsSelector());
  const searches = useSelector(getSearchesSelector);
  const getUpdateProfileStatus = useSelector(getUpdateProfileStatusSelector);
  //State
  const [isHandleRequestReportTriggered, setIsHandleRequestReportTriggered] =
    useState(false);
  const [technology, setTechnology] = useState(getTechnologySelector);
  const [technologyCompanies, setTechnologyCompanies] = useState(
    getTechnologyCompanies,
  );
  const [sectors, setSectors] = useState(technology && technology.sectors);
  const [subsectors, setSubsectors] = useState(
    technology && technology.subsectors,
  );
  const [activeTab, setActiveTab] = useState("technology_overview");
  const [showOrderReportModal, setShowOrderReportModal] = useState(false);
  const [showNotEnoughCreditsModal, setShowNotEnoughCreditsModal] =
    useState(false);
  const [showTopUpCreditsModal, setShowTopUpCreditsModal] = useState(false);
  const [credits, setCredits] = useState(0);
  const [enrichRequestModalShow, setEnrichRequestModalShow] = useState(false);
  const [showEnrichSuccess, setShowEnrichSuccess] = useState(false);
  const [isTechnologyEnriched, setIsTechnologyEnriched] = useState(false);
  const [isTechnologyReadyForRelease, setIsTechnologyReadyForRelease] =
    useState(false);
  const [isInitialization, setIsInitilaization] = useState(true);
  // const [technologyEnrichmentEndDate, setTechnologyEnrichmentEndDate] = useState(null);
  const [tracked, setTracked] = useState(false);
  const descRef = createRef(null);
  const [descHeight, setDescHeight] = useState(0);
  const [descExpanded, setDescExpanded] = useState(false);
  const getTotalPagesSelector = useSelector(
    getFilterTechnologyCompaniesTotalPagesSelector,
  );
  const totalCountSelector = useSelector(getTotalCountSelector);
  const subscription = useSelector(getSubscriptionSelector());
  const { intercomTrackEvent, mixpanelTrackEvent } = useAppEventTracking();
  const [showCompaniesTab, setShowCompaniesTab] = useState(true);
  const [confirmFreeTrialModalShow, setConfirmFreeTrialModalShow] =
    useState(false);
  const [copiedToClipboard, setCopiedToClipboard] = useState("");
  const [showAreYouSureModal, setShowAreYouSureModal] = useState(false);
  const [showDownloadsExceededModal, setShowDownloadsExceededModal] =
    useState(false);
  const [downloadProps, setDownloadProps] = useState();
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [displayedFilters, setDisplayedFilters] = useState([]);
  const [sortValue, setSortValue] = useState("");
  const [sortDirection, setSortDirection] = useState("");
  const [page, setPage] = useState(1);

  const handleSetSelectedFilters = selectedFilters => {
    setSelectedFilters(selectedFilters);
  };

  const handleSetDisplayedFilters = displayedFilters => {
    setDisplayedFilters(displayedFilters);
  };

  const handleSetSortValue = sortValue => {
    setSortValue(sortValue);
  };

  const handleSetSortDirection = sortDirection => {
    setSortDirection(sortDirection);
  };

  const handleSetPage = page => {
    setPage(page);
  };

  useEffect(() => {
    const data = {
      orderDirection: "desc",
      orderBy: "created-at",
      page: 1,
      savedSearches: true,
    };
    (!searches || false) && dispatch(fetchSearchesRequest({ data }));
  }, []);

  //free trial
  const { handleStartTrial } = useFreeTrialHook({ user: getUser });

  useEffect(() => {
    if (window.location.pathname === "/technologies/enriched/profile") {
      if (getMeStatus === REQUEST_UNDEFINED) {
        dispatch(getMeRequest());
      }
    }
  }, []);

  useEffect(() => {
    getTechnologyStatus === REQUEST_SUCCESS &&
      descRef &&
      descRef.current &&
      setDescHeight(descRef.current.offsetHeight);
  }, [getTechnologyStatus, descRef]);
  const handleShowMore = () => {
    setDescExpanded(!descExpanded);
  };

  useEffect(() => {
    return () => {
      dispatch(technologiesActions.clearTechnology());
    };
  }, []);

  const handleTabSelected = value => {
    setIsInitilaization(true);
    setActiveTab(value);
    let storageResultsFilterNameValue;
    // let storageResultsFilterSortValue;
    // let storageResultsFilterSortDirection;

    if (value === "companies_technologies") {
      // const storageResultsFilterSlug = sessionStorage.getItem(`technology-${slug}-FilterSlug`);
      // const storageResultsFilterPage = sessionStorage.getItem(`technology-${slug}-FilterPage`);
      // storageResultsFilterSortDirection = sessionStorage.getItem(`technology-${slug}-FilterSortDirection`);
      // storageResultsFilterSortValue = sessionStorage.getItem(`technology-${slug}-FilterSortValue`);
      const storageResultsFilterSector = sessionStorage.getItem(
        `technology-${slug}-FilterSector`,
      );
      const storageResultsFilterSubsector = sessionStorage.getItem(
        `technology-${slug}-FilterSubsector`,
      );
      const storageResultsFilterSubsectors = sessionStorage.getItem(
        `technology-${slug}-FilterSubsectors`,
      );
      const storageResultsFilterStage = sessionStorage.getItem(
        `technology-${slug}-FilterStage`,
      );
      const storageResultsFilterFundingFrom = sessionStorage.getItem(
        `technology-${slug}-FilterFundingFrom`,
      );
      const storageResultsFilterFundingTo = sessionStorage.getItem(
        `technology-${slug}-FilterFundingTo`,
      );
      const storageResultsFilterTeamSizeMin = sessionStorage.getItem(
        `technology-${slug}-FilterTeamSizeMin`,
      );
      const storageResultsFilterTeamSizeMax = sessionStorage.getItem(
        `technology-${slug}-FilterTeamSizeMax`,
      );
      const storageResultsFilterCountry = sessionStorage.getItem(
        `technology-${slug}-FilterCountry`,
      );
      const storageResultsFilterFoundedFrom = sessionStorage.getItem(
        `technology-${slug}-FilterFoundedFrom`,
      );
      const storageResultsFilterFoundedTo = sessionStorage.getItem(
        `technology-${slug}-FilterFoundedTo`,
      );
      const storageResultsFilterValuerScoreFrom = sessionStorage.getItem(
        `technology-${slug}-FilterValuerScoreFrom`,
      );
      const storageResultsFilterValuerScoreTo = sessionStorage.getItem(
        `technology-${slug}-FilterValuerScoreTo`,
      );
      const storageResultsFilterValuerRegions = sessionStorage.getItem(
        `technology-${slug}-FilterValuerRegions`,
      );
      const storageResultsFilterSearch = sessionStorage.getItem(
        `technology-${slug}-FilterSearch`,
      );
      const storageResultsFilters = {
        sector: storageResultsFilterSector || "",
        subsector: storageResultsFilterSubsector || "",
        subsectors: storageResultsFilterSubsectors || "",
        stage: storageResultsFilterStage || "",
        fundingMin: storageResultsFilterFundingFrom || "",
        fundingMax: storageResultsFilterFundingTo || "",
        teamsizeMin: storageResultsFilterTeamSizeMin || "",
        teamsizeMax: storageResultsFilterTeamSizeMax || "",
        location: storageResultsFilterCountry || "",
        regions: storageResultsFilterValuerRegions || "",
        yearMin: storageResultsFilterFoundedFrom || "",
        yearMax: storageResultsFilterFoundedTo || "",
        matchingScoreMin: storageResultsFilterValuerScoreFrom || "",
        matchingScoreMax: storageResultsFilterValuerScoreTo || "",
        search: storageResultsFilterSearch || "",
      };

      if (!objectHasEmptyValues(storageResultsFilters)) {
        let hasFilter = false;
        let value = "";

        Object.keys(storageResultsFilters).forEach(filter => {
          if (
            storageResultsFilters[filter] !== "" &&
            storageResultsFilters[filter] &&
            storageResultsFilters[filter].length !== 0
          ) {
            hasFilter = true;
            value = value.concat(
              `${filterParamMapping[filter]}=${storageResultsFilters[filter]}&`,
            );
            storageResultsFilterNameValue = value;
          }
        });

        if (!hasFilter) {
          storageResultsFilterNameValue = "";
        }

        hasFilter = false;
      }
    }
  };

  const handleRequestReport = () => {
    if (
      user &&
      !user.company &&
      subscription &&
      (subscription.name === "free" || subscription.free_trial) &&
      searches.length > 1
    ) {
      dispatch(authActions.completeProfileModalShowTrue());
      setIsHandleRequestReportTriggered(true);
    } else {
      setShowOrderReportModal(true);
    }
    //  value ? setEnrichRequestModalShow(true) : setShowNotEnoughCreditsModal(true);
  };

  useEffect(() => {
    if (
      getUpdateProfileStatus === REQUEST_SUCCESS &&
      isHandleRequestReportTriggered
    ) {
      setShowOrderReportModal(true);
    }
  }, [getUpdateProfileStatus]);

  const handleSubmitRequestReport = () => {
    // setShowOrderReportModal(true);
    user && user.credits < 1
      ? setShowNotEnoughCreditsModal(true)
      : setEnrichRequestModalShow(true);
  };
  const handleShare = () => {
    navigator.clipboard
      .writeText(document.getElementById("hiddenText").value)
      .then(res => {
        setCopiedToClipboard(true);
        setTimeout(() => {
          setCopiedToClipboard(false);
        }, 3000);
      });
  };
  const handleDownload = doc => {
    setDownloadProps({
      ids: technology.id,
      type: "technologies",
      doc,
      name: `valuer_${technology.name}_report`,
    });
    if (
      getUser.downloads === 1 &&
      (getUser.subscription.plan_type === "free_trial" ||
        getUser.subscription.plan_type === "free")
    ) {
      setShowDownloadsExceededModal(true);
    } else if (
      getUser.subscription.plan_type === "free_trial" &&
      getUser.subscription.is_active
    ) {
      setShowAreYouSureModal(true);
    } else if (
      getUser.subscription.plan_type === "free_trial" &&
      !getUser.subscription.is_active
    ) {
      window.location.href = getPricingPage(!!getUser);
    } else if (getUser.subscription.plan_type === "free") {
      setShowAreYouSureModal(true);
    } else {
      let payload = {
        ids: technology.id,
        type: "technologies",
        doc,
        name: `valuer_${technology.name}_report`,
        pageName: "technology",
      };

      dispatch(exportCsvRequest(payload));
      mixpanelTrackEvent(`Downloaded document`, {
        type: downloadProps.doc,
        page: "technology",
      });
    }
  };

  const download = () => {
    dispatch(exportCsvRequest(downloadProps));
    mixpanelTrackEvent(`Downloaded document`, {
      type: downloadProps.doc,
      page: "technology",
    });
  };

  const handleClosingOrderModal = () => {
    setShowOrderReportModal(false);
  };
  useEffect(() => {
    sessionStorage.setItem("history", "technologies");
    getTechnologyStatus === REQUEST_SUCCESS && setIsInitilaization(false);
  }, [getTechnologyStatus]);

  const activeView = useSelector(getActiveViewSelector);

  useEffect(() => {
    window.scrollTo(0, 0);
    // const storageResultsFilterSlug = sessionStorage.getItem(`technology-${slug}-FilterSlug`);
    // const storageResultsFilterPage = sessionStorage.getItem(`technology-${slug}-FilterPage`);
    // const storageResultsFilterSortDirection = sessionStorage.getItem(`technology-${slug}-FilterSortDirection`);
    // const storageResultsFilterSortValue = sessionStorage.getItem(`technology-${slug}-FilterSortValue`);
    const storageResultsFilterSector = sessionStorage.getItem(
      `technology-${slug}-FilterSector`,
    );
    const storageResultsFilterSubsector = sessionStorage.getItem(
      `technology-${slug}-FilterSubsector`,
    );
    const storageResultsFilterSubsectors = sessionStorage.getItem(
      `technology-${slug}-FilterSubsectors`,
    );
    const storageResultsFilterStage = sessionStorage.getItem(
      `technology-${slug}-FilterStage`,
    );
    const storageResultsFilterFundingFrom = sessionStorage.getItem(
      `technology-${slug}-FilterFundingFrom`,
    );
    const storageResultsFilterFundingTo = sessionStorage.getItem(
      `technology-${slug}-FilterFundingTo`,
    );
    const storageResultsFilterTeamSizeMin = sessionStorage.getItem(
      `technology-${slug}-FilterTeamSizeMin`,
    );
    const storageResultsFilterTeamSizeMax = sessionStorage.getItem(
      `technology-${slug}-FilterTeamSizeMax`,
    );
    const storageResultsFilterCountry = sessionStorage.getItem(
      `technology-${slug}-FilterCountry`,
    );
    const storageResultsFilterFoundedFrom = sessionStorage.getItem(
      `technology-${slug}-FilterFoundedFrom`,
    );
    const storageResultsFilterFoundedTo = sessionStorage.getItem(
      `technology-${slug}-FilterFoundedTo`,
    );
    const storageResultsFilterValuerScoreFrom = sessionStorage.getItem(
      `technology-${slug}-FilterValuerScoreFrom`,
    );
    const storageResultsFilterValuerScoreTo = sessionStorage.getItem(
      `technology-${slug}-FilterValuerScoreTo`,
    );
    const storageResultsFilterValuerRegions = sessionStorage.getItem(
      `technology-${slug}-FilterValuerRegions`,
    );
    const storageResultsFilterSearch = sessionStorage.getItem(
      `technology-${slug}-FilterSearch`,
    );
    const storageResultsFilters = {
      sector: storageResultsFilterSector || "",
      subsector: storageResultsFilterSubsector || "",
      subsectors: storageResultsFilterSubsectors || "",
      stage: storageResultsFilterStage || "",
      fundingMin: storageResultsFilterFundingFrom || "",
      fundingMax: storageResultsFilterFundingTo || "",
      teamsizeMin: storageResultsFilterTeamSizeMin || "",
      teamsizeMax: storageResultsFilterTeamSizeMax || "",
      location: storageResultsFilterCountry || "",
      regions: storageResultsFilterValuerRegions || "",
      yearMin: storageResultsFilterFoundedFrom || "",
      yearMax: storageResultsFilterFoundedTo || "",
      matchingScoreMin: storageResultsFilterValuerScoreFrom || "",
      matchingScoreMax: storageResultsFilterValuerScoreTo || "",
      search: storageResultsFilterSearch || "",
    };
    let storageResultsFilterNameValue;

    if (!objectHasEmptyValues(storageResultsFilters)) {
      let hasFilter = false;
      let value = "";

      Object.keys(storageResultsFilters).forEach(filter => {
        if (
          storageResultsFilters[filter] !== "" &&
          storageResultsFilters[filter] &&
          storageResultsFilters[filter].length !== 0
        ) {
          hasFilter = true;
          value = value.concat(
            `${filterParamMapping[filter]}=${storageResultsFilters[filter]}&`,
          );
          storageResultsFilterNameValue = value;
        }
      });

      if (!hasFilter) {
        storageResultsFilterNameValue = "";
      }

      hasFilter = false;
    }
    if (activeTab === "technology_overview") {
      if (slug) {
        dispatch(fetchTechnologyRequest({ slug }));
      } else {
        dispatch(
          filterTechnologyCompaniesRequest({
            slug: null,
            data: null,
            clearResults: true,
            isGuest: true,
          }),
        );
      }
    } else {
    }
    dispatch(industriesActions.clearIndustry());
  }, [dispatch, activeTab]);

  useEffect(() => {
    getTechnologySelector && setTechnology(getTechnologySelector);
    getTechnologyCompanies && setTechnologyCompanies(getTechnologyCompanies);
  }, [getTechnologySelector, getTechnologyCompanies]);

  useEffect(() => {
    setTechnology(null);
  }, []);
  useEffect(() => {
    technology && setSectors(technology.sectors);
    technology && setSubsectors(technology.subsectors);
    technology && setIsTechnologyEnriched(technology.enrichment_request);
    technology && setIsTechnologyReadyForRelease(technology.ready_for_release);
    // technology && setTechnologyEnrichmentEndDate(moment(technology.enrichment_request_created_at).add(7, 'd').format("MMM DD, YYYY"));
  }, [technology]);

  // const shouldLockAlertBoxShow = activeTab === 'technology_overview' && !isTechnologyReadyForRelease;

  const handleEnoughCredits = value => {
    setShowOrderReportModal(false);
    setShowNotEnoughCreditsModal(true);
  };

  const handleCloseNotEnoughCreditsModal = () => {
    setShowNotEnoughCreditsModal(false);
  };
  const handleOnTopUp = () => {
    setShowTopUpCreditsModal(true);
    setShowNotEnoughCreditsModal(false);
  };
  const handleClosingTopUpCreditsDialog = () => {
    setShowTopUpCreditsModal(false);
  };
  const handleOnSortFilterChange = data => {
    // const storageResultsFilterSlug = sessionStorage.getItem(`technology-${slug}-FilterSlug`);
    // const storageResultsFilterPage = sessionStorage.getItem(`technology-${slug}-FilterPage`);
    // const storageResultsFilterSortDirection = sessionStorage.getItem(`technology-${slug}-FilterSortDirection`);
    // const storageResultsFilterSortValue = sessionStorage.getItem(`technology-${slug}-FilterSortValue`);
    const storageResultsFilterSector = sessionStorage.getItem(
      `technology-${slug}-FilterSector`,
    );
    const storageResultsFilterSubsector = sessionStorage.getItem(
      `technology-${slug}-FilterSubsector`,
    );
    const storageResultsFilterSubsectors = sessionStorage.getItem(
      `technology-${slug}-FilterSubsectors`,
    );
    const storageResultsFilterStage = sessionStorage.getItem(
      `technology-${slug}-FilterStage`,
    );
    const storageResultsFilterFundingFrom = sessionStorage.getItem(
      `technology-${slug}-FilterFundingFrom`,
    );
    const storageResultsFilterFundingTo = sessionStorage.getItem(
      `technology-${slug}-FilterFundingTo`,
    );
    const storageResultsFilterTeamSizeMin = sessionStorage.getItem(
      `technology-${slug}-FilterTeamSizeMin`,
    );
    const storageResultsFilterTeamSizeMax = sessionStorage.getItem(
      `technology-${slug}-FilterTeamSizeMax`,
    );
    const storageResultsFilterCountry = sessionStorage.getItem(
      `technology-${slug}-FilterCountry`,
    );
    const storageResultsFilterFoundedFrom = sessionStorage.getItem(
      `technology-${slug}-FilterFoundedFrom`,
    );
    const storageResultsFilterFoundedTo = sessionStorage.getItem(
      `technology-${slug}-FilterFoundedTo`,
    );
    const storageResultsFilterValuerScoreFrom = sessionStorage.getItem(
      `technology-${slug}-FilterValuerScoreFrom`,
    );
    const storageResultsFilterValuerScoreTo = sessionStorage.getItem(
      `technology-${slug}-FilterValuerScoreTo`,
    );
    const storageResultsFilterValuerRegions = sessionStorage.getItem(
      `technology-${slug}-FilterValuerRegions`,
    );
    const storageResultsFilterSearch = sessionStorage.getItem(
      `technology-${slug}-FilterSearch`,
    );
    const storageResultsFilters = {
      sector: storageResultsFilterSector || "",
      subsector: storageResultsFilterSubsector || "",
      subsectors: storageResultsFilterSubsectors || "",
      stage: storageResultsFilterStage || "",
      fundingMin: storageResultsFilterFundingFrom || "",
      fundingMax: storageResultsFilterFundingTo || "",
      teamsizeMin: storageResultsFilterTeamSizeMin || "",
      teamsizeMax: storageResultsFilterTeamSizeMax || "",
      location: storageResultsFilterCountry || "",
      regions: storageResultsFilterValuerRegions || "",
      yearMin: storageResultsFilterFoundedFrom || "",
      yearMax: storageResultsFilterFoundedTo || "",
      matchingScoreMin: storageResultsFilterValuerScoreFrom || "",
      matchingScoreMax: storageResultsFilterValuerScoreTo || "",
      search: storageResultsFilterSearch || "",
    };
    let storageResultsFilterNameValue;

    if (!objectHasEmptyValues(storageResultsFilters)) {
      let hasFilter = false;
      let value = "";

      Object.keys(storageResultsFilters).forEach(filter => {
        if (
          storageResultsFilters[filter] !== "" &&
          storageResultsFilters[filter] &&
          storageResultsFilters[filter].length !== 0
        ) {
          hasFilter = true;
          value = value.concat(
            `${filterParamMapping[filter]}=${storageResultsFilters[filter]}&`,
          );
          storageResultsFilterNameValue = value;
        }
      });
      if (!hasFilter) {
        storageResultsFilterNameValue = "";
      }
      hasFilter = false;
    }

    if (!isInitialization) {
      if (storageResultsFilterNameValue) {
        dispatch(
          filterTechnologyCompaniesRequest({
            slug,
            data: {
              ...data.data,
              perPage: activeView === "card" ? 10 : 50,
              filterNameValue: storageResultsFilterNameValue,
            },
            clearResults: true,
            isGuest: !isAuthenticated,
          }),
        );
      } else {
        dispatch(
          filterTechnologyCompaniesRequest({
            slug,
            data: data.data,
            clearResults: true,
            isGuest: !isAuthenticated,
          }),
        );
      }
    }
  };
  const handleClosingEnrichDialog = () => {
    setEnrichRequestModalShow(false);
  };
  const handleCreateProjectDialogOpened = () => {
    setEnrichRequestModalShow(false);
  };
  const handleEnrichSuccess = () => {
    setShowOrderReportModal(false);
    setEnrichRequestModalShow(false);
  };
  const handleClosingSuccess = () => {
    setShowEnrichSuccess(false);
    window.location.reload();
  };
  const handleConfirmStartTrial = () => {
    handleStartTrial();
    setConfirmFreeTrialModalShow(false);
  };

  const handleStartFreeTrial = () => {
    if (!user) {
      openPage("/sign-up?signUpForFreeTrial=true");
    } else if (isFreeUser) {
      setConfirmFreeTrialModalShow(true);
    }
  };
  const handleOpenTabTechnologyDeepDive = () => {
    mixpanelTrackEvent("Technology deep-dive tab", {
      "Technology name": technology.name,
    });
    //Intercom event tracking
    if (
      process.env.REACT_APP_ENV === "prod" ||
      process.env.REACT_APP_ENV === "staging"
    ) {
      intercomTrackEvent("Technologies deep-dive tab clicks");
    }
    handleTabSelected("technology_overview");
  };
  const handleOpenTabCompaniesUsingThisTechnology = () => {
    mixpanelTrackEvent("Companies using this technology", {
      "Technology name": technology.name,
    });
    //Intercom event tracking
    if (
      process.env.REACT_APP_ENV === "prod" ||
      process.env.REACT_APP_ENV === "staging"
    ) {
      intercomTrackEvent("Companies using this technology tab clicks");
    }
    handleTabSelected("companies_technologies");
  };

  useEffect(() => {
    window.location.pathname.endsWith("/companies") &&
      setActiveTab("companies_technologies");
  }, [window.location.pathname]);

  useEffect(() => {
    getCredits
      ? setCredits(getCredits)
      : getUser &&
        getUser.subscription &&
        getUser.subscription.total_credits &&
        setCredits(getUser.subscription.total_credits);
  }, [getCredits, getUser, setCredits]);

  useEffect(() => {
    if (technology && technology.name && !tracked) {
      mixpanelTrackEvent("Specific technology card", {
        "Technology name": technology.name,
      });
      setTracked(true);
    }
  }, [dispatch, technology]);
  const handleOnSortDirValueChange = () => {};

  // const [showLimitAlert, setShowLimitAlert] = useState(false);
  // const [sessionCloseAlert, setSessionCloseAlert] = useState(false);
  // useEffect(() => {
  //     setShowLimitAlert(shouldLockAlertBoxShow && !sessionCloseAlert)
  // }, [shouldLockAlertBoxShow, sessionCloseAlert]);
  // useEffect(() => {
  //     let s = sessionStorage.getItem("closedBannerTP");
  //     setSessionCloseAlert(s === 'technologyProfile');
  // }, []);

  useEffect(() => {
    const minNumOfCompaniesToShow = 5;

    if (
      technology !== null &&
      technology.total_published_companies_count < minNumOfCompaniesToShow
    ) {
      setShowCompaniesTab(false);
      setActiveTab("technology_overview");
    }
  }, [technology]);

  const handleClickOnPreviewExample = () => {
    mixpanelTrackEvent("Full technology profile preview", {
      "From technology": technology.name,
    });
  };

  const switchView = view => {
    setPage(1);
    dispatch(resultsActions.setActiveView(view));
  };

  return getTechnologyStatus !== REQUEST_SUCCESS && !technology ? (
    <ContentLoader />
  ) : (
    <div className={classes.root}>
      <Header
        requestReport={handleRequestReport}
        isEnriched={technology && technology.ready_for_release}
        share={handleShare}
        download={handleDownload}
      />
      <div>
        <div className={classes.main} data-intercom-target="Technology summary">
          <div className={classes.wrap}>
            <Typography variant="subtitle1" color="secondaryDark">
              {technology && technology.name}
            </Typography>
          </div>
          {((sectors && sectors.length > 0) ||
            (subsectors && subsectors.length > 0)) && (
            <div className={classes.descriptionScores}>
              <div className={classes.flexColumn}>
                <div className={classes.mr80}>
                  <Typography variant="bodySmall" color="">
                    {t("technologyProfile.relevantIndustries")}
                  </Typography>
                  <div className={classes.flexWrap}>
                    <SeeSectorsModal sectors={sectors} />
                  </div>
                </div>
                <div>
                  <Typography variant="bodySmall" color="">
                    {t("technologyProfile.relevantSubindustries")}
                  </Typography>
                  <div className={classes.flexWrap}>
                    <SeeSectorsModal subsectors={subsectors} />
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className={classes.technologies}>
            <div className={classes.descriptionBox} ref={descRef}>
              <Typography variant="body" component="div">
                <div
                  className={classnames([
                    {
                      [classes.descriptionExpanded]: descExpanded,
                      [classes.description]: !descExpanded,
                    },
                  ])}
                  dangerouslySetInnerHTML={{
                    __html: technology && technology.ml_description,
                  }}></div>
              </Typography>
              {descHeight > 48 && (
                <div className={classnames([classes.showMore, "laptop"])}>
                  <Typography
                    variant="bodySmall"
                    color="textLighter"
                    onClick={handleShowMore}>
                    {descExpanded
                      ? t("companyProfile.showLess")
                      : t("companyProfile.showMore")}
                  </Typography>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className={classes.tabsWrapper}>
          <div className={classes.tabs}>
            <Button
              data-intercom-target="Technology deep dive element"
              className={classnames([
                classes.technologyDiveButton,
                {
                  [classes.activeTab]: activeTab === "technology_overview",
                },
              ])}
              variant="tertiary"
              onClick={handleOpenTabTechnologyDeepDive}>
              {t("technologyProfile.overview")}
            </Button>
            {showCompaniesTab && (
              <Button
                data-intercom-target="Companies using this technology element"
                className={classnames([
                  classes.companiesButton,
                  {
                    [classes.activeTab]: activeTab === "companies_technologies",
                  },
                ])}
                variant="tertiary"
                onClick={handleOpenTabCompaniesUsingThisTechnology}>
                {t("technologyProfile.companies")}(
                {technology && technology.total_published_companies_count})
              </Button>
            )}
          </div>
        </div>

        {activeTab === "technology_overview" &&
          technology &&
          !isTechnologyReadyForRelease && (
            <>
              <div className={classes.enrichmentBanner}>
                {isGuestUser && !isTechnologyEnriched && (
                  <>
                    <div>
                      <Typography
                        align="center"
                        variant="h6"
                        className={classes.enrichmentBannerTitle}>
                        {t("technologyProfile.enrichmentBanner.title.guest")}
                      </Typography>
                      <Typography
                        className={classes.enrichmentBannerDescription}>
                        {t(
                          "technologyProfile.enrichmentBanner.description.guest.text1",
                        )}
                        <Link onClick={handleStartFreeTrial}>
                          {t(
                            "technologyProfile.enrichmentBanner.description.guest.link",
                          )}
                        </Link>
                        {t(
                          "technologyProfile.enrichmentBanner.description.guest.text2",
                        )}
                      </Typography>
                      <div
                        className={classes.enrichmentBannerLink}
                        dangerouslySetInnerHTML={{
                          __html: t(
                            "technologyProfile.enrichmentBanner.link.guest",
                            {
                              href:
                                window.location.origin +
                                "/technologies/enriched/profile",
                            },
                          ),
                        }}
                        onClick={handleClickOnPreviewExample}
                      />
                    </div>
                  </>
                )}
                {isAuthenticated && isExpiredUser && !isTechnologyEnriched && (
                  <>
                    <div>
                      <Typography
                        variant="h6"
                        className={classes.enrichmentBannerTitle}>
                        {t("technologyProfile.enrichmentBanner.title.expired")}
                      </Typography>
                      <Typography
                        className={classes.enrichmentBannerDescription}
                        dangerouslySetInnerHTML={{
                          __html: t(
                            "technologyProfile.enrichmentBanner.description.expired",
                            {
                              href: getPricingPage(user.subscription.name),
                            },
                          ),
                        }}
                      />
                      <div
                        className={classes.enrichmentBannerLink}
                        dangerouslySetInnerHTML={{
                          __html: t(
                            "technologyProfile.enrichmentBanner.link.expired",
                            {
                              href:
                                window.location.origin +
                                "/technologies/enriched/profile",
                            },
                          ),
                        }}
                        onClick={handleClickOnPreviewExample}
                      />
                    </div>
                  </>
                )}
                {isAuthenticated && isPayingUser && !isTechnologyEnriched && (
                  <>
                    <div>
                      <Typography
                        variant="h6"
                        className={classes.enrichmentBannerTitle}>
                        {t("technologyProfile.enrichmentBanner.title.paying")}
                      </Typography>

                      <Button
                        onClick={handleRequestReport}
                        className={classes.enrichmentBannerButton}
                        variant="primary">
                        {t("technologyProfile.enrichmentBanner.button.paying")}
                        <DeepDive className={classes.deepDiveIcon} />
                      </Button>

                      <Typography
                        className={classes.enrichmentBannerDescription}
                        dangerouslySetInnerHTML={{
                          __html: t(
                            "technologyProfile.enrichmentBanner.description.paying",
                          ),
                        }}
                      />
                      <div
                        className={classes.enrichmentBannerLink}
                        dangerouslySetInnerHTML={{
                          __html: t(
                            "technologyProfile.enrichmentBanner.link.paying",
                            {
                              href:
                                window.location.origin +
                                "/technologies/enriched/profile",
                            },
                          ),
                        }}
                        onClick={handleClickOnPreviewExample}
                      />
                    </div>
                  </>
                )}
                {isAuthenticated &&
                  isTechnologyEnriched &&
                  !isTechnologyReadyForRelease && (
                    <>
                      <div>
                        <LockAlert removeImg removeCloseButton removePadding>
                          <Typography
                            className={classes.enrichmentBannerDescription}
                            dangerouslySetInnerHTML={{
                              __html: t(
                                "technologyProfile.enrichmentBanner.description.underEnrichment",
                                {
                                  href: window.location.origin + "/orders",
                                },
                              ),
                            }}
                          />
                        </LockAlert>
                      </div>
                    </>
                  )}
              </div>
            </>
          )}

        <div>
          {activeTab === "technology_overview" && technology && (
            <TechnologyOverview
              user={user}
              isAuthenticated={isAuthenticated}
              technology={technology}
              onOpenRequestModal={handleSubmitRequestReport}
            />
          )}
          {activeTab === "companies_technologies" && (
            <ProfileCompanies
              isTechnology={true}
              isIndustry={false}
              user={user}
              companies={technologyCompanies}
              onFilterNameValueChange={handleOnSortFilterChange}
              getTotalPages={getTotalPagesSelector}
              getTotalCount={totalCountSelector}
              onSortDirValueChange={handleOnSortDirValueChange}
              id={technology && technology.id}
              name={technology && technology.name}
              activeView={activeView}
              setActiveView={switchView}
              handleSetSelectedFilters={handleSetSelectedFilters}
              handleSetDisplayedFilters={handleSetDisplayedFilters}
              handleSetSortValue={handleSetSortValue}
              handleSetSortDirection={handleSetSortDirection}
              handleSetPage={handleSetPage}
            />
          )}
        </div>
      </div>
      <EnrichRequestModal
        source="technology"
        technologySlug={slug}
        credits={credits}
        dialogOpened={enrichRequestModalShow}
        onChange={handleClosingEnrichDialog}
        createProjectDialogOpened={handleCreateProjectDialogOpened}
        enrichSuccess={handleEnrichSuccess}
      />
      <OrderReportModal
        enrichSuccess={handleEnrichSuccess}
        technology={technology}
        user={user}
        dialogOpened={showOrderReportModal}
        onChange={handleClosingOrderModal}
        onNotEnoughCredits={handleEnoughCredits}
        type="technology"
      />
      <OrderReceivedModal
        source={"technology"}
        dialogOpened={showEnrichSuccess}
        onChange={handleClosingSuccess}
      />
      <NotEnoughCreditsModal
        orderReport={true}
        dialogOpened={showNotEnoughCreditsModal}
        onChange={handleCloseNotEnoughCreditsModal}
        credits={user && user.credits}
        onTopUp={handleOnTopUp}
      />
      <TopUpCreditsModal
        dialogOpened={showTopUpCreditsModal}
        onChange={handleClosingTopUpCreditsDialog}
      />
      <ConfirmFreeTrialStartModal
        user={getUser}
        onClose={() => setConfirmFreeTrialModalShow(false)}
        onConfirm={handleConfirmStartTrial}
        dialogOpened={confirmFreeTrialModalShow}
      />
      <AreYouSureModal
        dialogOpened={showAreYouSureModal}
        onChange={() => setShowAreYouSureModal(false)}
        title={t("download.areYouSureTitle")}
        subtitle={
          <>
            {t("download.subtitleFreeTrial1")}
            <Typography
              component="div"
              className={classes.actionText}
              variant="body"
              color="secondaryDark">
              {t("download.subtitleFreeTrial2")}
            </Typography>
          </>
        }
        actionButtonText={t("download.iWantToDownload")}
        cancelButtonText={t("download.letsTryLater")}
        onActionClick={download}
      />
      <AreYouSureModal
        dialogOpened={showDownloadsExceededModal}
        onChange={() => setShowDownloadsExceededModal(false)}
        title={t("download.downloadsExceededTitle")}
        subtitle={t("download.downloadsExceededSubtitle")}
        actionButtonText={t("download.upgradePlan")}
        cancelButtonText={t("download.upgradeLater")}
        onActionClick={() => (window.location.href = getPricingPage(!!getUser))}
      />
      <TextField
        className={classes.hiddenInput}
        id="hiddenText"
        value={
          window.location.origin +
          "/technologies/" +
          (slug && technology ? technology?.slug : "enriched/profile")
        }
        type="text"
      />
      {copiedToClipboard && (
        <div className={classes.tooltipCopy}>
          <TooltipCheck className={classes.checkImg} />
          <Typography color="white" variant="body2">
            {t("companyProfile.copiedToClipboard")}
          </Typography>
        </div>
      )}
    </div>
  );
};
export default TechnologiesProfile;
