import { useQuery } from "@tanstack/react-query";
import CompanyService from "../api/company/companyService";

export const useCompanies = (sid = null, id = null, page, filters = null) => {
  const companyService = new CompanyService();

  const getCompany = useQuery({
    queryKey: ["company", id],
    retry: 1,
    enabled: false,
    queryFn: () => companyService.getCompanyFromSearchByMlIdUrl(sid, id),
  });

  const getScopeCompanies = useQuery({
    queryKey: ["companies", page, filters],
    queryFn: () => companyService.getSearchResults(sid, page, filters),
    enabled: !!sid,
    keepPreviousData: true,
    retry: false,
    refetchOnWindowFocus: false,
  });

  return {
    getCompany,
    getScopeCompanies,
  };
};
