const useLocalStorage = (localStorageSlugPrefix, slug) => {
  const clearStorage = () => {
    sessionStorage.removeItem(`${localStorageSlugPrefix}-${slug}-FilterSid`);
    sessionStorage.removeItem(`${localStorageSlugPrefix}-${slug}-FilterSlug`);
    sessionStorage.removeItem(`${localStorageSlugPrefix}-${slug}-FilterPage`);
    sessionStorage.removeItem(`${localStorageSlugPrefix}-${slug}-FilterSector`);
    sessionStorage.removeItem(
      `${localStorageSlugPrefix}-${slug}-FilterSubsector`,
    );
    sessionStorage.removeItem(
      `${localStorageSlugPrefix}-${slug}-FilterSubsectors`,
    );
    sessionStorage.removeItem(`${localStorageSlugPrefix}-${slug}-FilterStage`);
    sessionStorage.removeItem(
      `${localStorageSlugPrefix}-${slug}-FilterFundingFrom`,
    );
    sessionStorage.removeItem(
      `${localStorageSlugPrefix}-${slug}-FilterFundingTo`,
    );
    sessionStorage.removeItem(
      `${localStorageSlugPrefix}-${slug}-FilterTeamSizeMin`,
    );
    sessionStorage.removeItem(
      `${localStorageSlugPrefix}-${slug}-FilterTeamSizeMax`,
    );
    sessionStorage.removeItem(
      `${localStorageSlugPrefix}-${slug}-FilterCountry`,
    );
    sessionStorage.removeItem(
      `${localStorageSlugPrefix}-${slug}-FilterFoundedFrom`,
    );
    sessionStorage.removeItem(
      `${localStorageSlugPrefix}-${slug}-FilterFoundedTo`,
    );
    sessionStorage.removeItem(
      `${localStorageSlugPrefix}-${slug}-FilterValuerScoreFrom`,
    );
    sessionStorage.removeItem(
      `${localStorageSlugPrefix}-${slug}-FilterValuerScoreTo`,
    );
    sessionStorage.removeItem(
      `${localStorageSlugPrefix}-${slug}-FilterValuerRegions`,
    );
    sessionStorage.removeItem(`${localStorageSlugPrefix}-${slug}-FilterSearch`);
    sessionStorage.removeItem(
      `${localStorageSlugPrefix}-${slug}-FilterNameValue`,
    );
  };

  return { clearStorage };
};

export default useLocalStorage;
