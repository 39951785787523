import { useState } from "react";
//Redux
import { useDispatch, useSelector } from "react-redux";
//Hooks
import { useTranslation } from "react-i18next";
import { useAuthHook } from "../../../common/useAuthHook";
import {
  getPostProjectsStatusSelector,
  getPostUpdateProjectsStatusSelector,
  getProjectsSelector,
  getProjectsStatusSelector,
  getToggleProjectsStatusSelector,
} from "../../../store/projects/selectors";
import { getLogoutStatusSelector } from "../../../store/auth/selectors";
import { Shared } from "../../../utils/shared";

export const useProjectsHook = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useAuthHook();
  const getProjectsStatus = useSelector(getProjectsStatusSelector);
  const projects = useSelector(getProjectsSelector);
  const [allProjects, setAllProjects] = useState(projects);
  const [createProjectModalShow, setCreateProjectModalShow] = useState(false);
  const [topUpCreditsModalShow, setTopUpCreditsModalShow] = useState(false);
  // const [projectsActive, setProjectsActive] = useState(allProjects.active);
  // const [projectsInactive, setProjectsInactive] = useState(allProjects.active);
  const postProjectsStatus = useSelector(getPostProjectsStatusSelector);
  const postToggleProjectStatus = useSelector(getToggleProjectsStatusSelector);
  const postUpdateProjectsStatus = useSelector(
    getPostUpdateProjectsStatusSelector,
  );
  const logoutStatus = useSelector(getLogoutStatusSelector);
  const [timeout, setTimeoutVal] = useState(null);
  const [loading, setLoading] = useState(true);
  const { checkUserSubscription, getSubscriptionStatus, getExpiredPlanMsg } =
    Shared({ getUser: user });

  const handleOnSearch = searchValue => {
    setLoading(true);
    clearTimeout(timeout);
    setTimeoutVal(
      setTimeout(() => {
        if (searchValue) {
          let temp = {};
          temp = allProjects.filter(f => checkSearchCriteria(searchValue, f));
          setAllProjects(temp);
          // temp = projectsActive.filter((f) =>
          //     checkSearchCriteria(searchValue, f)
          // );
          // setProjectsActive(temp);
          // temp = projectsInactive.filter((f) =>
          //     checkSearchCriteria(searchValue, f)
          // );
          // setProjectsInactive(temp);
        } else {
          setAllProjects(projects);
          // setProjectsActive(allProjects.active);
          // setProjectsInactive(allProjects.inactive);
        }
        setLoading(false);
      }, 900),
    );
  };
  const checkSearchCriteria = (criteria, project) => {
    let nameFound = false;
    let sectorFound = false;
    let averageScore = false;

    nameFound = project.name.toLowerCase().includes(criteria.toLowerCase());
    averageScore = project.average_valuer_score.toString() === criteria;
    sectorFound = project.sectors
      ? project.sectors.includes(criteria.toLowerCase())
      : false;

    return nameFound || averageScore || sectorFound;
  };
  const openCreateProjectModal = () => {
    setCreateProjectModalShow(true);
  };
  const handleShowTopUpModal = () => {
    setTopUpCreditsModalShow(true);
  };
  const handleClosingDialog = () => {
    setCreateProjectModalShow(false);
  };
  const handleClosingTopUpCreditsDialog = () => {
    setTopUpCreditsModalShow(false);
  };
  const handleBuyingTopUpCreditsDialog = () => {
    setTopUpCreditsModalShow(false);
  };

  return {
    t,
    dispatch,
    createProjectModalShow,
    handleClosingDialog,
    topUpCreditsModalShow,
    postProjectsStatus,
    postToggleProjectStatus,
    postUpdateProjectsStatus,
    loading,
    setLoading,
    checkUserSubscription,
    getSubscriptionStatus,
    getExpiredPlanMsg,
    handleOnSearch,
    openCreateProjectModal,
    handleShowTopUpModal,
    handleClosingTopUpCreditsDialog,
    handleBuyingTopUpCreditsDialog,
    allProjects,
    setAllProjects,
    projects,
    getProjectsStatus,
    logoutStatus,
  };
};
