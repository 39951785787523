import { createStyles, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(({ palette, spacing, shape, breakpoint }) =>
  createStyles({
    root: {
      padding: spacing(4),
      margin: "0 auto",
      [breakpoint.down("lg")]: {
        // padding: spacing(3)
      },
      [breakpoint.down("xs")]: {
        // padding: spacing(2)
      },
    },
    main: {
      background: palette.common.white,
      padding: spacing(4),
      marginTop: spacing(4),
      borderRadius: spacing(4),
      position: "relative",
    },
    wrap: {
      display: "flex",
      justifyContent: "space-between",
    },
    imgWrap: {
      width: 48,
      height: 48,
      border: `1px solid ${palette.text.backgroundMain}`,
      borderRadius: spacing(1),
      marginRight: spacing(2),
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    scores: {
      padding: `${spacing(4)}px 0 0 ${spacing(4)}px`,
      display: "flex",
      flexDirection: "column",
      flex: "50%",
    },
    imagePlaceholder: {
      cursor: "pointer",
      maxWidth: 36,
    },
    profileImage: {
      maxWidth: 36,
    },
    companyName: {
      color: palette.secondary.light,
    },
    matchScore: {
      color: palette.text.dark,
      display: "flex",
      alignItems: "center",
      marginTop: spacing(2),
      marginRight: 240,
    },
    infoIcon: {
      cursor: "pointer",
      marginLeft: spacing(2),
    },
    valuerScoreNum: {
      color: palette.secondary.light,
      fontWeight: "bold",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
    },
    basicInfo: {
      marginTop: spacing(3),
    },
    infoTitle: {
      fontWeight: "500",
      color: palette.text.main,
      width: 196,
    },
    infoValue: {
      color: palette.text.lighter,
      width: 196,
    },
    link: {
      color: palette.secondary.dark,
      maxWidth: 200,
      overflowWrap: "anywhere",
    },
    money: {
      color: palette.success.main,
    },
    description: {
      color: palette.text.main,
      overflowY: "hidden",
      textOverflow: "ellipsis",
      width: "100%",
      [breakpoint.down("lg")]: {
        maxHeight: 352,
      },
    },
    descriptionExpanded: {
      color: palette.text.main,
      maxHeight: "none",
    },
    showMore: {
      cursor: "pointer",
      textAlign: "center",
      position: "relative",
      marginTop: spacing(4),
      "&::before": {
        content: '""',
        position: "absolute",
        top: 11,
        left: 0,
        width: "42%",
        height: 1,
        background: palette.text.backgroundMain,
      },
      "&::after": {
        content: '""',
        position: "absolute",
        top: 11,
        right: 0,
        width: "42%",
        height: 1,
        background: palette.text.backgroundMain,
      },
    },
    technologies: {
      marginTop: spacing(3),
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      borderTop: "1px solid rgba(107, 119, 140, 0.1)",
      paddingTop: spacing(3),
    },
    tag: {
      marginRight: spacing(1),
      marginTop: spacing(1),
      "& span": {
        whiteSpace: "pre",
      },
    },
    sdgWrap: {
      marginTop: spacing(3),
      display: "flex",
      flexDirection: "column",
    },
    sdg: {
      marginRight: spacing(1),
      marginTop: spacing(1),
      cursor: "pointer",
    },
    sdgTooltip: {
      textAlign: "center",
    },
    sdgTooltipTitle: {
      fontWeight: "700",
    },
    sdgUnlockToAccess: {
      color: palette.grey.main,
      verticalAlign: "super",
    },
    dataTitle: {
      color: palette.secondary.light,
    },
    dataValue: {
      color: palette.primary.main,
      marginLeft: spacing(1),
    },
    dataCompleteness: {
      display: "flex",
      cursor: "pointer",
    },
    tabs: {
      minWidth: 445,
      display: "flex",
      justifyContent: "center",
    },
    tabsWrapper: {
      position: "relative",
      marginTop: spacing(4),
      maxWidth: "100vw",
      overflow: "auto",
      display: "flex",
      justifyContent: "center",
      [breakpoint.down("sm")]: {
        justifyContent: "flex-start",
        paddingLeft: spacing(2),
      },
      "&::-webkit-scrollbar": {
        display: "none",
        "-ms-overflow-style": "none",
        scrollbarWidth: "none",
      },
    },
    tab: {
      color: palette.text.light,
      minWidth: 76,
      marginRight: 58,
      cursor: "pointer",
      textAlign: "center",
      borderBottom: "2px solid transparent",
      paddingBottom: spacing(2),
      marginTop: spacing(2),
    },
    activeTab: {
      color: palette.common.white,
      // minWidth: 76,
      // marginRight: 58,
      background: `${palette.secondary.dark}!important`,
    },
    nameMenuWrap: {
      width: "100%",
    },

    valuerScoreBlock: {
      position: "absolute",
      top: 0,
      right: 0,
      background: palette.primary.backgroundMedium,
      boxShadow: `inset 1px 0px 0px #EFEFEE`,
      height: 164,
      width: 240,
      borderTopRightRadius: spacing(2),
    },
    valuerScore: {
      height: "100%",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
    },
    chart: {
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      marginTop: spacing(2),
    },
    scoreBreakdown: {
      marginTop: -spacing(1),
      marginBottom: spacing(2),
      cursor: "pointer",
    },
    infoIconChart: {
      // marginLeft: spacing(1)
      position: "absolute",
      top: 12,
      right: 12,
      cursor: "pointer",
    },
    score: {
      lineHeight: "40px",
      position: "absolute",
      top: 60,
    },
    descriptionScores: {
      borderTop: "1px solid rgba(107, 119, 140, 0.1)",
      marginTop: spacing(3),
      display: "flex",
      paddingTop: spacing(4),
      // borderBottom: "1px solid rgba(107, 119, 140, 0.1)",
      paddingBottom: spacing(4),
    },
    descriptionBox: {
      paddingRight: spacing(4),
    },
    communicationIcon: {
      padding: `${spacing(1)}px ${spacing(1)}px`,
    },
    numberOfEmployees: {
      minHeight: 18,
    },
    enrichedProfileTag: {
      color: palette.common.white,
      background: palette.primary.main,
      padding: `0 ${spacing(1)}px`,
      borderRadius: shape.borderRadius,
      height: spacing(3),
      display: "flex",
      alignItems: "center",
      width: 105,
    },
    emptyTag: {
      height: spacing(3),
    },
    companyVoteActions: {
      paddingTop: spacing(3),
      marginTop: spacing(3),
      borderTop: "1px solid #F0F1F3",
      display: "flex",
      flexDirection: "row",
      alignContent: "center",
      justifyContent: "space-between",
    },
    companyVote: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    questionMark: {
      marginRight: spacing(1),
    },
    recommendationUseful: {
      paddingRight: `${spacing(2)}px`,
    },
    voteIcon: {
      padding: `${spacing(1)}px ${spacing(2)}px`,
      "&:hover": {
        cursor: "pointer",
      },
    },
    dislikeImg: {
      transform: "rotate(180deg)",
    },
    flexSpaceStart: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-start",
    },
    flexColumn: {
      display: "flex",
      justifyContent: "space-between",
      [breakpoint.down("sm")]: {
        flexDirection: "column",
      },
    },
    flexCenter: {
      display: "flex",
      alignItems: "center",
    },
    flexWrap: {
      display: "flex",
      flexWrap: "wrap",
      width: "100%",
    },
    flexSpaceBetween: {
      display: "flex",
      justifyContent: "space-between",
    },
    moreIcon: {
      fill: palette.secondary.dark,
    },
    moreIconOpened: {
      fill: palette.primary.dark,
    },
    moreButton: {
      position: "absolute",
      top: spacing(4),
      right: spacing(4),
      cursor: "pointer",
    },
    mr80: {
      marginRight: 80,
      [breakpoint.down("sm")]: {
        marginBottom: spacing(2),
      },
    },
    upgradeCta: {
      marginLeft: spacing(1),
      marginRight: 4,
    },
    lockAlertWrap: {
      marginTop: spacing(5),
    },
    companiesButton: {
      minHeight: 40,
      border: "none",
      background: palette.text.backgroundDark,
      marginLeft: spacing(1),
      "&:hover": {
        border: "none",
      },
    },
    technologyDiveButton: {
      minHeight: 40,
      background: palette.text.backgroundDark,
      border: "none",
      "&:hover": {
        border: "none",
      },
    },
    enrichmentBanner: {
      textAlign: "center",
      marginTop: spacing(6),
      marginBottom: spacing(6),
    },
    enrichmentBannerTitle: {
      width: "100%",
      display: "block",
      marginBottom: spacing(2),
    },
    enrichmentBannerDescription: {
      width: "100%",
      display: "block",
      marginBottom: spacing(2),
    },
    enrichmentBannerButton: {
      marginBottom: spacing(2),
    },
    enrichmentBannerLink: {
      width: "100%",
      display: "block",
      marginBottom: spacing(2),
    },
    deepDiveIcon: {
      marginLeft: spacing(2),
    },
    tooltipCopy: {
      backgroundColor: palette.secondary.dark,
      boxShadow:
        "0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04)",
      borderRadius: spacing(1),
      color: palette.common.white,
      right: 20,
      top: 60,
      position: "fixed",
      height: 30,
      width: 180,
      padding: spacing(1),
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 9999,
    },
    checkImg: {
      marginRight: spacing(1),
    },
    hiddenInput: {
      color: "transparent",
      position: "absolute",
      background: "transparent",
      border: "none",
      zIndex: 1,
      left: 0,
      top: "-60px",
    },
  }),
);
