import React from "react";
// UI components
import Typography from "../../../../components/Typography/Typography";
import IconButton from "@material-ui/core/IconButton";
import Button from "../../../../components/Button/Button";
import Grow from "@material-ui/core/Grow";
// Hooks
import { useTranslation } from "react-i18next";
import { useStyles } from "./style";
//UI Components
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
//Assets
import CloseIcon from "../../../../assets/icons/CloseIcon.svg";

// Constants
import { getPricingPage } from "../../../../constants/endpoints";

const UpgradeProjectsModal = ({ user, dialogOpened, onChange }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const upgradeSubscription = () => {
    window.location.href = getPricingPage(user.subscription.name);
  };

  return (
    <Dialog
      maxWidth="lg"
      TransitionComponent={Grow}
      classes={{
        root: classes.dialogRoot,
        paper: classes.dialog,
        scrollPaper: classes.dialogScrollPaper,
      }}
      onClose={(event, reason) => {
        if (reason === "backdropClick") {
          onChange(event, reason);
        }
      }}
      open={dialogOpened}>
      <IconButton className={classes.closeButton} onClick={onChange}>
        <img
          src={CloseIcon}
          alt={t("scope.step2")}
          className={classes.stepIcon}
        />
      </IconButton>
      <MuiDialogContent className={classes.dialogContent}>
        <Typography
          component="div"
          variant="subtitle1"
          color="black"
          className={classes.title}>
          {t("upgradeProjects.readyForMore")}
        </Typography>
        <Typography component="div" variant="h4" color="black" align="center">
          {t("upgradeProjects.upgradeYourAccount")}
        </Typography>
      </MuiDialogContent>

      <div className={classes.buttons}>
        <Button onClick={upgradeSubscription} variant="primary">
          {t("upgradeProjects.upgradeSubscription")}
        </Button>
      </div>
    </Dialog>
  );
};
export default UpgradeProjectsModal;
