import { createStyles, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(({ palette, spacing, breakpoint }) =>
  createStyles({
    root: {
      marginTop: spacing(5),
    },
    funding: {
      background: palette.common.white,
      borderRadius: spacing(3),
      padding: spacing(4),
      flex: "65%",
      position: "relative",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    fundingUnlocked: {
      background: palette.common.white,
      borderRadius: spacing(2),
      padding: spacing(3),
      flex: "65%",
      position: "relative",
      display: "flex",
      flexDirection: "column",
    },
    fundingCustomers: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
    totalFunding: {
      textAlign: "left",
      display: "flex",
      flex: "35%",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "15px 24px",
    },
    framedLockIcon: {
      margin: "20px 0 24px 0",
      width: 48,
      height: 48,
    },
    customers: {
      background: palette.common.white,
      borderRadius: spacing(3),
      padding: spacing(4),
      marginTop: spacing(3),
      display: "flex",
      flexDirection: "column",
      position: "relative",
    },
    customersLocked: {
      background: palette.common.white,
      borderRadius: spacing(3),
      padding: spacing(4),
      marginTop: spacing(3),
      position: "relative",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    totalFundingCurrency: {
      minWidth: spacing(4),
    },
    tag: {
      backgroundColor: palette.secondary.light,
      marginLeft: spacing(1),
      color: palette.secondary.dark,
    },
    imgWrapper: {
      width: spacing(6),
      height: spacing(6),
      minWidth: spacing(6),
      border: "1px solid",
      borderColor: palette.text.backgroundMain,
      borderRadius: 4,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginRight: spacing(1),
    },
    customerImg: {
      maxWidth: spacing(6),
    },
    customerRow: {
      paddingBottom: spacing(3),
      marginTop: spacing(3),
      display: "flex",
      alignItems: "center",
      flex: "1 0 32%",
    },
    link: {
      color: palette.secondary.dark,
      overflowWrap: "anywhere",
      justifyContent: "flex-end",
    },
    fundingRow: {
      borderTop: "1px solid rgba(107, 119, 140, 0.1)",
      padding: "8px 24px",
      display: "flex",
      flexDirection: "column",
      alignItems: "baseline",
      background: palette.success.backgroundLight,
      borderRadius: spacing(2),
      marginTop: spacing(2),
    },
    fundingRowItem: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      padding: "16px 0",
      flexDirection: "column",
      flex: 2,
      wordBreak: "break-word",
      paddingLeft: 4,
      "&:last-child": {
        borderBottom: "none",
      },
    },
    roundName: {
      color: palette.common.black,
    },
    roundTag: {
      background: "transparent",
      paddingLeft: 0,
    },
    amountText: {
      color: palette.common.black,
    },
    investorsCount: {
      marginLeft: spacing(1),
      cursor: "pointer",
    },
    marginRight: {
      marginRight: spacing(1),
    },
    marginRight4: {
      marginRight: 4,
    },
    tagsWrap: {
      display: "flex",
      alignItems: "center",
    },
    tagName: {
      cursor: "pointer",
      marginLeft: spacing(1),
    },
    fundingAmountValue: {
      color: palette.success.dark,
    },
    customersRow: {
      marginTop: 0,
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
    },
    fundingTitle: {
      marginBottom: spacing(1),
    },
    fundingTitleUnlocked: {
      marginBottom: spacing(2),
    },
    shTitle: {
      marginTop: spacing(2),
      maxWidth: 727,
    },
    shSubtitle: {
      marginTop: spacing(2),
    },
    shButton: {
      marginTop: spacing(3),
      background: palette.primary.dark,
    },
    freeUserRoot: {
      background: palette.common.white,
      borderRadius: spacing(3),
      padding: "64px 0 78px 0",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    flex: {
      display: "flex",
    },
    flexColumn25: {
      display: "flex",
      flexDirection: "column",
      flex: "25%",
    },
    flexColumn60: {
      display: "flex",
      flex: "60%",
      flexDirection: "column",
    },
    flexColumn20: {
      display: "flex",
      flex: "20%",
      flexDirection: "column",
    },
    flexCenter: {
      display: "flex",
      alignItems: "center",
    },
    flexColumn: {
      display: "flex",
      flexDirection: "column",
    },
    roundNameWrap: {
      minHeight: 22,
    },
    openDialogSection: {
      marginTop: spacing(3),
      padding: `${spacing(3)}px ${spacing(4)}px 0 0`,
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      borderTop: `1px solid ${palette.text.backgroundMain}`,
      "&:hover $mono": {
        color: palette.secondary.dark,
      },
    },
    mono: {
      fontFamily: "GT Flexa Mono, sans-serif",
    },
    questionMark: {
      marginRight: spacing(2),
    },
    lockIcon: {
      position: "absolute",
      top: spacing(3),
      right: spacing(4),
    },
    benefitsCardsWrapper: {
      background: palette.common.white,
      borderRadius: spacing(3),
      marginBottom: spacing(6),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    benefitsCardsWrapperTitle: {
      height: "56px",
      background: palette.primary.backgroundLight,
      width: "100%",
      borderRadius: `${spacing(3)}px ${spacing(3)}px 0 0`,
      textAlign: "center",
      lineHeight: "56px",
    },
    benefitsCard: {
      width: "100%",
      borderBottom: `1px solid ${palette.text.lightest}`,
      maxHeight: "none",
      paddingTop: spacing(1),
      paddingLeft: spacing(2),
      paddingBottom: spacing(2),
      [breakpoint.down("md")]: {
        paddingTop: spacing(4),
      },
    },
    descriptionBox: {
      padding: spacing(2),
    },
    blockTitle: {
      fontSize: "20px",
      width: "100%",
      marginTop: spacing(3),
      paddingLeft: spacing(2),
      [breakpoint.down("sm")]: {
        margin: 0,
      },
    },
    tableRow: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      flex: "none",
      padding: `${spacing(2)}px 0`,
      borderBottom: `1px solid ${palette.text.lightest}`,
      "&:last-child": {
        borderBottom: "none",
      },
    },
    tableCell1: {
      flex: 2,
      wordBreak: "break-word",
      paddingLeft: 4,
    },
    investorsTooltip: {
      background: `${palette.common.white} !important`,
      color: `${palette.text.main} !important`,
      minWidth: 500,
      boxShadow:
        "0px 20px 25px 10px rgb(0 0 0 / 10%), 0px 10px 10px 10px rgb(0 0 0 / 4%) !important",
    },
    investorsArrow: {
      color: `${palette.common.white} !important`,
    },
    investorsNumberValue: {
      display: "inline-block",
    },
  }),
);
