import React, { useEffect, useState } from "react";
// Hooks
import { useStyles } from "./style.js";
import { useAuthHook } from "../../common/useAuthHook";
import { useHistory, useLocation } from "react-router-dom";
import { useRouterHook } from "../../common/useRouterHook.js";
// Assets
import AddNew from "../../assets/images/AddNew.png";
import AddPlusIconDrawn from "../../assets/icons/AddPlusIconDrawn.svg";
//UI components
import Layout from "../Layout/Layout";
import Card from "../../components/Card/Card";
import ProjectHeader from "./ProjectHeader/ProjectHeader";
import Typography from "../../components/Typography/Typography";
import Button from "../../components/Button/Button";
import WorkspaceEmptyPage from "../WorkspaceEmptyPage/WorkspaceEmptyPage";
// Redux
import { fetchProjectsRequest } from "../../store/projects/requests";
import {
  REQUEST_FAILURE,
  REQUEST_PENDING,
  REQUEST_SUCCESS,
} from "../../constants/statuses";
import ContentLoader from "../../components/ContentLoader/ContentLoader.js";
import ProjectsFreeUserIllustration from "../../assets/icons/ProjectsFreeUserIllustration.png";
import { getPricingPage } from "../../constants/endpoints";
import { useProjectsHook } from "./hooks/useProjectsHook.js";
import CreateProjectModal from "../Results/components/modals/CreateProjectModal.js";
import TopUpCreditsModal from "../Company/LockedPage/TopUpCreditsModal/TopUpCreditsModal.js";

const Projects = () => {
  const classes = useStyles();
  const { openPage } = useRouterHook();
  const {
    t,
    dispatch,
    postProjectsStatus,
    postToggleProjectStatus,
    postUpdateProjectsStatus,
    loading,
    setLoading,
    checkUserSubscription,
    getExpiredPlanMsg,
    handleOnSearch,
    openCreateProjectModal,
    projects,
    allProjects,
    setAllProjects,
    getProjectsStatus,
    createProjectModalShow,
    handleClosingDialog,
    topUpCreditsModalShow,
    handleShowTopUpModal,
    handleBuyingTopUpCreditsDialog,
    handleClosingTopUpCreditsDialog,
    logoutStatus,
  } = useProjectsHook();
  const { isAuthenticated, getMeStatus, user } = useAuthHook();
  const { pathname } = useLocation();
  const history = useHistory();
  const expiredPlanMsg = getExpiredPlanMsg();

  useEffect(() => {
    setLoading(getProjectsStatus === REQUEST_PENDING);
  }, [getProjectsStatus]);

  useEffect(() => {
    dispatch(fetchProjectsRequest());
  }, [dispatch]);

  useEffect(() => {
    !isAuthenticated &&
      logoutStatus !== REQUEST_SUCCESS &&
      logoutStatus !== REQUEST_PENDING &&
      (getMeStatus === REQUEST_SUCCESS || getMeStatus === REQUEST_FAILURE) &&
      history.replace("/sign-in", { from: pathname, someOtherProp: "" });
  }, [isAuthenticated, history, logoutStatus]);

  useEffect(() => {
    setAllProjects(projects);
  }, [projects]);

  useEffect(() => {
    (postToggleProjectStatus === REQUEST_SUCCESS ||
      postProjectsStatus === REQUEST_SUCCESS ||
      postUpdateProjectsStatus === REQUEST_SUCCESS) &&
      dispatch(fetchProjectsRequest());
  }, [
    postToggleProjectStatus,
    postProjectsStatus,
    postUpdateProjectsStatus,
    dispatch,
  ]);
  const [isLess768, setIsLess768] = useState(false);
  useEffect(() => {
    setIsLess768(window.innerWidth < 769);
  }, []);

  return (
    <Layout match="workspace">
      {!checkUserSubscription("free") ||
      process.env.REACT_APP_ENV === "local" ? (
        <div className={classes.main}>
          {allProjects !== null && getProjectsStatus !== REQUEST_PENDING && (
            <ProjectHeader
              isLess768={isLess768}
              projects={projects}
              onSearch={handleOnSearch}
              user={user}
              expiredPlanMsg={expiredPlanMsg}
            />
          )}
          {loading ? (
            <ContentLoader />
          ) : allProjects ? (
            // printing projects cards
            <div className={classes.blocks}>
              <div className={classes.addNew} onClick={openCreateProjectModal}>
                <img src={AddNew} alt={t("projectHeader.title")} />
                <Typography className={classes.addLabel} variant="bodyButton">
                  {t("projectHeader.createProject")}
                </Typography>
                <img src={AddPlusIconDrawn} alt="Add" />
              </div>
              {allProjects.length &&
                allProjects.map((project, i) => {
                  return (
                    <Card
                      isLess768={isLess768}
                      key={`active-${i}`}
                      index={i}
                      result={project}
                      isTechnology={false}
                      isIndustryProfile={false}
                      cardIndex={i}
                      openPage={openPage}
                      t={t}
                      isAuthenticated={isAuthenticated}
                      isProject={true}
                      source="projects"
                      type="project"
                    />
                  );
                })}
            </div>
          ) : (
            // printing no project info (when no project is created)
            <WorkspaceEmptyPage onCreate={openCreateProjectModal} />
          )}
        </div>
      ) : (
        // printing upgrade subscription info
        <div className={classes.root}>
          <Typography
            className={classes.shTitle}
            variant="h6"
            align="center"
            color="black">
            <strong>{t("projects.freeUserTitle")}</strong>
          </Typography>
          <Typography
            className={classes.shSubtitle}
            variant="body"
            color="textLight">
            {t("projects.freeUserSubtitle")}
          </Typography>
          <img
            className={classes.shImage}
            src={ProjectsFreeUserIllustration}
            alt={t("projects.freeUserTitle")}
          />
          <Button
            onClick={() =>
              (window.location.href = getPricingPage(
                !!isAuthenticated && "free",
              ))
            }
            size="large"
            variant="primary"
            className={classes.shButton}>
            {t("projects.upgradeSubscription")}
          </Button>
        </div>
      )}
      <CreateProjectModal
        source="projects"
        dialogOpened={createProjectModalShow}
        onChange={handleClosingDialog}
      />
      <TopUpCreditsModal
        dialogOpened={topUpCreditsModalShow}
        onBuy={handleBuyingTopUpCreditsDialog}
        onChange={handleClosingTopUpCreditsDialog}
      />
    </Layout>
  );
};

export default Projects;
