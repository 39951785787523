const freeTrialStateSelector = state => state.freeTrial.freeTrialState;
const freeTrialStatusSelector = state => state.freeTrial.freeTrialStatus;
const updateFreeTrialSelector = state => state.freeTrial.updateFreeTrialStatus;
const isEmailChangeSelector = state => state.freeTrial.emailChange;
const emailVerificationSelector = state =>
  state.freeTrial.emailVerificationStatus;
const emailChangeStatusSelector = state => state.freeTrial.emailChangeStatus;
const emailValidationStatusSelector = state =>
  state.freeTrial.emailValidationStatus;
const emailValidationStateSelector = state =>
  state.freeTrial.emailValidationState;

export {
  freeTrialStateSelector,
  freeTrialStatusSelector,
  updateFreeTrialSelector,
  isEmailChangeSelector,
  emailVerificationSelector,
  emailChangeStatusSelector,
  emailValidationStatusSelector,
  emailValidationStateSelector,
};
