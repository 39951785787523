import { createStyles, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(({ palette, spacing }) =>
  createStyles({
    root: {
      // marginRight: 20
    },
    nav: {
      display: "flex",
      justifyContent: "space-between",
    },
    goBack: {
      display: "inline-flex",
      alignItems: "center",
      "& img": {
        marginRight: spacing(1),
      },
      cursor: "pointer",
    },
    buttonStyle: {
      "&  > svg": {
        marginLeft: spacing(1),
        marginRight: "0!important",
      },
    },
    buttonStyleShare: {
      marginLeft: spacing(2),
      "&  > svg": {
        marginLeft: spacing(1),
        marginRight: "0!important",
      },
    },
    moreIcon: {
      fill: palette.secondary.dark,
    },
    moreIconOpened: {
      fill: palette.primary.dark,
    },
  }),
);
