import React, { memo } from "react";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";

const features = require("./features.json");

const MapChart = ({ topLocations, setTooltipContent }) => {
  const getColor = id => {
    const locationWithValue = topLocations.find(
      location =>
        location.name.replace(" ", "").toLowerCase() ===
        id.replace(" ", "").toLowerCase(),
    );

    return !!locationWithValue && locationWithValue.color
      ? locationWithValue.color
      : "#D6D6DA";
  };

  return (
    <div data-tip="">
      {topLocations && (
        <ComposableMap>
          <Geographies geography={features}>
            {({ geographies }) =>
              geographies.map(geo => (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  onMouseEnter={() => {
                    setTooltipContent(`${geo.properties.name}`);
                  }}
                  onMouseLeave={() => {
                    setTooltipContent("");
                  }}
                  style={{
                    default: {
                      fill: getColor(geo.id) || "#D6D6DA",
                      outline: "none",
                      transformOrigin: "center",
                      transformBox: "fill-box",
                    },
                    hover: {
                      fill: getColor(geo.id) || "#D6D6DA",
                      outline: "none",
                      cursor: "pointer",
                      transformOrigin: "center",
                      transformBox: "fill-box",
                      transform: "scale(1.09)",
                      transition: "all .2s",
                    },
                    pressed: {
                      fill: getColor(geo.id) || "#D6D6DA",
                      outline: "none",
                      cursor: "pointer",
                      transformOrigin: "center",
                      transformBox: "fill-box",
                      transform: "scale(1.09)",
                      transition: "all .2s",
                    },
                  }}
                />
              ))
            }
          </Geographies>
        </ComposableMap>
      )}
    </div>
  );
};

export default memo(MapChart);
