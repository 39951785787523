import { all, takeEvery } from "redux-saga/effects";
import {
  fetchCompaniesChartRequest,
  fetchCompanyRequest,
  fetchLikedCompaniesRequest,
  fetchResultsCountRequest,
  fetchResultsRequest,
  fetchSearchesRequest,
  fetchTeamSizeChartRequest,
  generateShareLinkRequest,
  postGenerateLinkRequest,
  postSearchesRequest,
  voteMlResultRequest,
} from "../requests";
import {
  fetchCompaniesChart,
  fetchCompany,
  fetchLikedCompanies,
  fetchResults,
  fetchResultsCount,
  fetchSearches,
  fetchTeamSizeChart,
  generateShareLink,
  postGenerateLink,
  submitSearches,
  voteMlResult,
} from "./workers";

const convertTypeFromAction = actionCreator => actionCreator.toString();

function* watchFetchResults() {
  yield takeEvery(
    convertTypeFromAction(fetchResultsRequest),
    ({ payload: { data, isSharedPageToken, searchTypeLook, filterStats } }) =>
      fetchResults(data, isSharedPageToken, searchTypeLook, filterStats),
  );
}

function* watchFetchResultsCount() {
  yield takeEvery(
    convertTypeFromAction(fetchResultsCountRequest),
    ({
      payload: {
        data,
        isSharedPageToken,
        searchTypeLook,
        lastFilteredField,
        lastFilteredFieldValue,
        filterStats,
      },
    }) =>
      fetchResultsCount(
        data,
        isSharedPageToken,
        searchTypeLook,
        lastFilteredField,
        lastFilteredFieldValue,
        filterStats,
      ),
  );
}

function* watchFetchCompaniesChart() {
  yield takeEvery(
    convertTypeFromAction(fetchCompaniesChartRequest),
    ({ payload: { id, type, grouped, filterNameValue } }) =>
      fetchCompaniesChart(id, type, grouped, filterNameValue),
  );
}

function* watchFetchTeamSizeChart() {
  yield takeEvery(
    convertTypeFromAction(fetchTeamSizeChartRequest),
    ({ payload: { id, type, filterNameValue } }) =>
      fetchTeamSizeChart(id, type, filterNameValue),
  );
}

function* watchFetchSearches() {
  yield takeEvery(
    convertTypeFromAction(fetchSearchesRequest),
    ({ payload: { data, isEditSearch } }) => fetchSearches(data, isEditSearch),
  );
}

function* watchPostSearches() {
  yield takeEvery(
    convertTypeFromAction(postSearchesRequest),
    ({ payload: { data } }) => submitSearches(data),
  );
}

function* watchFetchCompany() {
  yield takeEvery(
    convertTypeFromAction(fetchCompanyRequest),
    ({ payload: { data } }) => fetchCompany(data),
  );
}

function* watchPostGenerateLink() {
  yield takeEvery(
    convertTypeFromAction(postGenerateLinkRequest),
    ({ payload: { data } }) => postGenerateLink(data),
  );
}

function* watchVoteMlResult() {
  yield takeEvery(
    convertTypeFromAction(voteMlResultRequest),
    ({ payload: { data } }) => voteMlResult(data),
  );
}

function* watchGenerateShareLink() {
  yield takeEvery(
    convertTypeFromAction(generateShareLinkRequest),
    ({ payload: { data } }) => generateShareLink(data),
  );
}

function* watchGetLikedCompanies() {
  yield takeEvery(
    convertTypeFromAction(fetchLikedCompaniesRequest),
    ({ payload: { data } }) => fetchLikedCompanies(data),
  );
}

function* saga() {
  yield all([
    watchFetchResults(),
    watchFetchSearches(),
    watchPostSearches(),
    watchFetchCompaniesChart(),
    watchFetchCompany(),
    watchPostGenerateLink(),
    watchVoteMlResult(),
    watchGenerateShareLink(),
    watchFetchResultsCount(),
    watchFetchTeamSizeChart(),
    watchGetLikedCompanies(),
  ]);
}

export { saga };
