import React, { useEffect } from "react";
// Hooks
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useRouterHook } from "../../common/useRouterHook";
import {
  getPostSharedLinkStatusSelector,
  getSharedModelSelector,
} from "../../store/companyProfile/selectors";
import { getSharedModelRequest } from "../../store/companyProfile/requests";
import { REQUEST_PENDING, REQUEST_SUCCESS } from "../../constants/statuses";
import { getCookieStatusSelector } from "../../store/auth/selectors";

const MlResultShare = () => {
  const { t } = useTranslation();
  let { slug } = useParams();
  const dispatch = useDispatch();
  const { openPage } = useRouterHook();
  const sharedModel = useSelector(getSharedModelSelector);
  const postSharedLinkStatus = useSelector(getPostSharedLinkStatusSelector);
  const getCookieStatus = useSelector(getCookieStatusSelector);
  useEffect(() => {
    getCookieStatus === REQUEST_SUCCESS &&
      dispatch(getSharedModelRequest({ token: slug }));
  }, [dispatch, getCookieStatus, slug]);

  useEffect(() => {
    postSharedLinkStatus === REQUEST_SUCCESS &&
      openPage(`/companies/${sharedModel.slug}/?sharedLink=${slug}`);
  }, [postSharedLinkStatus, openPage, slug]);

  return postSharedLinkStatus === REQUEST_PENDING ? (
    t("common.loading")
  ) : (
    <div>{t("companyProfile.mlResultShareContent")}</div>
  );
};
export default MlResultShare;
