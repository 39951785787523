import React from "react";
import PropTypes from "prop-types";
import useStyles from "./style.js";
import { Checkbox as MuiCheckbox } from "@material-ui/core";
import { ReactComponent as UncheckedCheckbox } from "../../assets/icons/UncheckedCheckbox.svg";
import { ReactComponent as CheckedCheckbox } from "../../assets/icons/CheckedCheckbox.svg";
import classnames from "classnames";

const Checkbox = React.forwardRef(
  (
    {
      className,
      classNames,
      checked,
      color,
      disabled,
      disableRipple,
      icon,
      id,
      indeterminate,
      indeterminateIcon,
      inputProps,
      inputRef,
      onChange,
      required,
      size,
      sx,
      value,
      ...props
    },
    ref,
  ) => {
    const classes = useStyles();
    return (
      <MuiCheckbox
        icon={
          <UncheckedCheckbox
            className={classnames([
              { [classes.smallCheckbox]: size === "small" },
            ])}
          />
        }
        checkedIcon={
          <CheckedCheckbox
            className={classnames([
              { [classes.smallCheckbox]: size === "small" },
            ])}
          />
        }
        className={className}
        checked={checked}
        color={color}
        disabled={disabled}
        disableRipple={disableRipple}
        id={id}
        indeterminate={indeterminate}
        indeterminateIcon={indeterminateIcon}
        inputProps={inputProps}
        inputRef={inputRef}
        onChange={onChange}
        required={required}
        size={size}
        sx={sx}
        value={value}
        classes={{
          disabled: classes.disabled,
          indeterminate: classes.indeterminate,
        }}
        {...props}
      />
    );
  },
);

Checkbox.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "error",
    "info",
    "success",
    "warning",
    "default",
  ]),
};

export default Checkbox;
