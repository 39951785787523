import React, { useEffect, useState } from "react";
import { actions as searchActions } from "../../../store/search/slice";
import { useDispatch } from "react-redux";
import classnames from "classnames";
// Hooks
import { useTranslation } from "react-i18next";
import { useStyles } from "./style.js";
import { useRouterHook } from "../../../common/useRouterHook";

//UI Components
import Button from "../../../components/Button/Button.js";
import Typography from "../../../components/Typography/Typography.js";
import TextField from "../../../components/TextField/TextField";
import Multiselect from "../../../components/Multiselect/Multiselect";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
//Assets
import { ReactComponent as SearchPlaceholderIcon } from "../../../assets/icons/SearchPlaceholderIcon.svg";
import { ReactComponent as ArrowIcon } from "../../../assets/icons/ArrowDownBlue.svg";
import MoreIcon from "../../../assets/icons/MoreIcon.svg";
//Redux
import { fetchSearchesRequest } from "../../../store/results/requests";
import { getPricingPage } from "../../../constants/endpoints";
import LockAlert from "../../../components/LockAlert/LockAlert";
import { useFreeTrialHook } from "../../Layout/hooks/useFreeTrialHook";

const MoreComponent = ({ t, classes, onMenuItemClicked }) => {
  const [menuOpened, setMenuOpened] = useState(false);

  const openDialog = () => {
    setMenuOpened(false);
    onMenuItemClicked();
  };

  return (
    <>
      <ClickAwayListener onClickAway={() => setMenuOpened(false)}>
        <div className={classes.moreIconWrapper}>
          <img
            src={MoreIcon}
            onClick={() => setMenuOpened(true)}
            className={classes.moreIcon}
            alt={t("savedSearches.more")}
          />
          {menuOpened && (
            <div className={classes.moreIconMenu} onClick={() => openDialog()}>
              <Typography
                variant="bodyXs"
                className={classes.moreIconMenuItem}
                component="div"
                color="light">
                {t("savedSearches.createNewSearch")}
              </Typography>
            </div>
          )}
        </div>
      </ClickAwayListener>
    </>
  );
};

const Header = ({
  onSearch,
  totalPages,
  page,
  searches,
  onSortChange,
  totalCount,
  isLess1024,
  user,
  userSwitchedFromTrialToFree,
  searchValue,
  setSearchValue,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [sortByOpened, setSortByOpened] = useState(false);

  const sortOptions = [
    { id: 1, name: "search date", value: "created-at" },
    { id: 0, name: "number of results", value: "number-of-results" },
  ];
  const [sortValue, setSortValue] = useState(sortOptions[0].name);
  const [sortDirection, setSortDirection] = useState("desc");
  const [sessionCloseAlert, setSessionCloseAlert] = useState(false);
  const handleSearch = event => {
    setSearchValue(event.target.value);
    onSearch(event.target.value);
  };
  const handleSelectChange = event => {
    setSortValue(event.target.value);
    let sortVal =
      sortOptions &&
      sortOptions.filter(f => f.name === event.target.value)[0].value;
    const data = {
      orderBy: sortVal,
      orderDirection: sortDirection,
      page: 1,
      searchValue,
      isSortSearch: true,
      savedSearches: true,
    };
    dispatch(fetchSearchesRequest({ data }));
  };
  const changeSortDirection = () => {
    let direction = sortDirection === "asc" ? "desc" : "asc";
    setSortDirection(direction);
    let sortVal =
      sortOptions && sortOptions.filter(f => f.name === sortValue)[0].value;
    const data = {
      orderBy: sortVal,
      orderDirection: sortDirection === "asc" ? "desc" : "asc",
      page: 1,
      searchValue,
      isSortSearch: true,
      savedSearches: true,
    };
    dispatch(fetchSearchesRequest({ data }));
  };
  const { openPage } = useRouterHook();
  const goToSearch = () => {
    dispatch(searchActions.clearSearch());
    openPage("search-type");
  };

  useEffect(() => {
    let sortVal =
      sortOptions && sortOptions.filter(f => f.name === sortValue)[0].value;
    onSortChange(sortVal, sortDirection);
  }, [sortValue, sortDirection]);
  const [isLess768, setIsLess768] = useState(false);
  useEffect(() => {
    setIsLess768(window.innerWidth < 768);
  }, []);

  const { talkToSales } = useFreeTrialHook({ user: user });

  useEffect(() => {
    let s = sessionStorage.getItem("closedBannerSHFT");
    setSessionCloseAlert(s === "searchHistoryFT");
  }, []);
  const handleOnClose = () => {
    sessionStorage.setItem("closedBannerSHFT", "searchHistoryFT");
    let s = sessionStorage.getItem("closedBannerSHFT");
    setSessionCloseAlert(s === "searchHistoryFT");
  };

  return (
    <div className={classes.root}>
      {userSwitchedFromTrialToFree() && !sessionCloseAlert && (
        <LockAlert
          bottomMarginAdjust
          onClose={handleOnClose}
          removeImg={userSwitchedFromTrialToFree()}
          closeIconPosition={
            userSwitchedFromTrialToFree() ? "flex-start" : "center"
          }>
          <Typography
            component="div"
            className={classes.planEndedTitle}
            variant="bodySmall">
            {t("savedSearches.yourPlanEnded")}
          </Typography>
          <div className={classes.buttonsWrap}>
            <Button onClick={() => talkToSales()} variant="quaternary">
              {t("projects.talkToSales")}
            </Button>
            <Button
              className={classes.ml16}
              onClick={() =>
                (window.location.href = getPricingPage(!!user && "free"))
              }
              variant="primaryDarkContained">
              {t("projects.upgrade")}
            </Button>
          </div>
        </LockAlert>
      )}
      <div className={classes.flexCenter}>
        <div className={classes.flexColumn}>
          <Typography variant="subtitle1">
            {t("savedSearches.title")}
            <Typography variant="bodySmall" className={classes.resultsTotal}>
              {totalPages > 1
                ? t("resultsHeader.showingResultsPaginationLabel", {
                    count: totalCount,
                    page,
                    totalPages,
                  })
                : t("resultsHeader.showingResultsLabel", {
                    count: totalCount,
                  })}
            </Typography>
          </Typography>
        </div>
        <div className={classes.flex}>
          {isLess1024 ? (
            <MoreComponent
              t={t}
              classes={classes}
              onMenuItemClicked={goToSearch}
            />
          ) : (
            <Button
              className={classes.startNewSearch}
              data-intercom-target="Start new search"
              onClick={goToSearch}
              variant="primary">
              {t("savedSearches.createNewSearch")}
            </Button>
          )}
        </div>
      </div>
      <Typography>{t("savedSearches.description")}</Typography>
      <div
        className={classes.filterWrap}
        data-intercom-target="Search and sort bar">
        <TextField
          fullWidth={isLess768}
          endAdornment={
            <SearchPlaceholderIcon className={classes.startEndornment} />
          }
          value={searchValue}
          className={classes.headerInput}
          onChange={handleSearch}></TextField>
        <div className={classes.sortWrapper}>
          {/* <Typography color="secondaryDark" className={classes.sortedBy} variant="bodyButton">{t("resultsHeader.sortedBy")}</Typography> */}
          <Multiselect
            customClass={classes.multiselectAdjust}
            testId={sortDirection === "asc" ? "sort-asc" : "sort-desc"}
            iconArrow={
              <ArrowIcon
                test-id={
                  sortDirection === "asc" ? "sort-icon-asc" : "sort-icon-desc"
                }
                onClick={() => changeSortDirection()}
                className={classnames([
                  classes.arrowIcon,
                  {
                    [classes.rotateIcon]: sortDirection === "asc",
                  },
                ])}
              />
            }
            className={classnames([
              classes.sortBySelectorBox,
              sortByOpened && classes.sortBySelectorBoxOpened,
            ])}
            options={sortOptions}
            value={sortValue}
            onChange={handleSelectChange}
            label={<span>{t("resultsHeader.sortedBy")}&nbsp;</span>}
            labelClass={classes.sortByLabel}
            rootClass={classes.sortByRoot}
            onOpen={() => setSortByOpened(true)}
            onClose={() => setSortByOpened(false)}
          />
        </div>
      </div>
    </div>
  );
};
export default Header;
