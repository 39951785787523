import React, { useEffect, useState } from "react";
import { useStyles } from "../style.js";
import Button from "../../Button/Button";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "../../Typography/Typography";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { updateUserConversationRequest } from "../../../store/userConversation/requests";
import { getUserSelector } from "../../../store/auth/selectors";
// Constants
import { prompts } from "../../../constants/puma";

const SuggestedPromts = ({
  contextObject,
  selectedFiltersCount,
  showLoading,
  setShowLoading,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector(getUserSelector);
  const [randomPrompts, setRandomPrompts] = useState({});

  //randomly choose 4 suggestions
  useEffect(() => {
    if (contextObject && !randomPrompts[contextObject.id]) {
      const randomPromptsTmp = [];
      while (randomPromptsTmp.length < 4) {
        const randomIndex = Math.floor(Math.random() * prompts.length);
        const randomPrompt = prompts[randomIndex];
        if (
          !randomPromptsTmp.find(prompt => prompt.name === randomPrompt.name)
        ) {
          randomPromptsTmp.push({ selected: false, ...randomPrompt });
        }
      }
      const updatedPrompts = { ...randomPrompts };
      updatedPrompts[contextObject.id] = randomPromptsTmp;
      setRandomPrompts(updatedPrompts);
    }
  }, [contextObject]);

  //if showLoading is false the answer is received then stop loading
  useEffect(() => {
    if (contextObject && randomPrompts[contextObject.id] && !showLoading) {
      const updatedPrompts = { ...randomPrompts };
      updatedPrompts[contextObject.id].map(prompt => {
        prompt.loading = false;
      });
      setRandomPrompts(updatedPrompts);
    }
  }, [showLoading]);

  const selectPropmt = index => {
    //set selected prompt loading state
    const updatedPrompts = { ...randomPrompts };
    updatedPrompts[contextObject.id][index] = {
      ...updatedPrompts[contextObject.id][index],
      selected: !updatedPrompts[contextObject.id][index].selected,
      loading: true,
    };
    setRandomPrompts(updatedPrompts);

    //prepare message data
    let payload = {
      deep_dive_question: updatedPrompts[contextObject.id][index].content,
      conversation_context: contextObject.conversation_context,
      scope_description: contextObject.description,
      search_id: contextObject.id,
    };
    if (
      contextObject &&
      contextObject.conversation_id &&
      selectedFiltersCount === 0
    ) {
      payload.conversation_id = contextObject.conversation_id;
    }
    if (user && user.llm_region) {
      payload.llm_region = user.llm_region;
    }
    setShowLoading(true);
    //send prompt as message
    dispatch(
      updateUserConversationRequest({
        data: payload,
        id: contextObject.id,
      }),
    );
  };

  return (
    <div className={classes.suggestedPromts}>
      {contextObject &&
        randomPrompts[contextObject.id] &&
        randomPrompts[contextObject.id].map((prompt, index) => (
          <Tooltip
            key={index}
            title={
              <Typography
                color="white"
                variant="body2"
                className={classes.infoTooltipText}>
                {prompt.content}
              </Typography>
            }
            arrow>
            <div className={classes.chipWrapper}>
              <Button
                className={classes.chip}
                onClick={() => {
                  selectPropmt(index);
                }}
                loading={0}
                variant="quaternary"
                disabled={prompt.loading}>
                {prompt.name}
              </Button>
            </div>
          </Tooltip>
        ))}
    </div>
  );
};

export default SuggestedPromts;
