const getAllTechnologiesStatusSelector = state =>
  state.technologies.fetchAllTechnologiesStatus;
const getAllTechnologiesStateSelector = state =>
  state.technologies.allTechologies;
const getAllTechnologiesTotalPagesSelector = state =>
  state.technologies.allTechologiesTotalPages;
const getAllTechnologiesTotalCountSelector = state =>
  state.technologies.allTechologiesTotalCount;
const getAllTechnologiesCurrentPageSelector = state =>
  state.technologies.allTechologiesCurrentPage;
const getTechnologyStatusSelector = state =>
  state.technologies.fetchTechnologyStatus;
const getTechnologyStateSelector = state => state.technologies.technology;
const getTechnologyCompaniesSelector = state =>
  state.technologies.technologyCompanies;
const getTechnologyCompaniesCountSelector = state =>
  state.technologies.companiesCount;
const getTechnologyCompaniesCountStatus = state =>
  state.technologies.getTechnologyCompaniesCountStatus;
const getTotalCountSelector = state => state.technologies.totalCount;
const getFilterTechnologyCompaniesTotalPagesSelector = state =>
  state.technologies.totalPages;
const getFilteringTechnologyPaginationStatus = state =>
  state.technologies.getResultsPaginationStatus;
const getTechnologyCompanyStatusSelector = state =>
  state.technologies.getTechnologyCompanyStatus;
const getTechnologyCompanyStateSelector = state =>
  state.technologies.technologyCompany;
const enrichTechnologyCompanyStatusSelector = state =>
  state.technologies.enrichTechnologyCompanyStatus;
const enrichTechnologyStatusSelector = state =>
  state.technologies.enrichTechnologyStatus;
const getTechnologyCompaniesChartSelector = state =>
  state.technologies.technologyCompaniesChart;
const getTechnologyCompaniesChartStatusSelector = state =>
  state.technologies.getTechnologyCompaniesChartStatus;
const getTechnologyCompaniesTeamSizeChartSelector = state =>
  state.technologies.technologyCompaniesTeamSizeChart;
const getTechnologyCompaniesTeamSizeChartStatusSelector = state =>
  state.technologies.getTechnologyCompaniesTeamSizeChartStatus;
const getTechnologyCompaniesTeamSizeChartUnspecifiedAllSelector = state =>
  state.technologies.unspecifiedAll;
const getSuggestTechnologyStatusSelector = state =>
  state.technologies.suggestTechnologyStatus;
const getTechnologyCompaniesFilterStats = state =>
  state.technologies.filterStats;

export {
  getAllTechnologiesStatusSelector,
  getAllTechnologiesStateSelector,
  getTechnologyStatusSelector,
  getTechnologyStateSelector,
  getTechnologyCompaniesSelector,
  getTotalCountSelector,
  getFilterTechnologyCompaniesTotalPagesSelector,
  getTechnologyCompaniesCountSelector,
  getFilteringTechnologyPaginationStatus,
  getTechnologyCompanyStatusSelector,
  getTechnologyCompanyStateSelector,
  enrichTechnologyCompanyStatusSelector,
  enrichTechnologyStatusSelector,
  getTechnologyCompaniesChartSelector,
  getTechnologyCompaniesChartStatusSelector,
  getTechnologyCompaniesTeamSizeChartSelector,
  getTechnologyCompaniesTeamSizeChartStatusSelector,
  getTechnologyCompaniesTeamSizeChartUnspecifiedAllSelector,
  getAllTechnologiesTotalPagesSelector,
  getAllTechnologiesTotalCountSelector,
  getAllTechnologiesCurrentPageSelector,
  getSuggestTechnologyStatusSelector,
  getTechnologyCompaniesFilterStats,
  getTechnologyCompaniesCountStatus,
};
