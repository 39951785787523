import { call, put } from "redux-saga/effects";
import { actions } from "../slice";
import { getActivities, getDashboard, putActivitySeen } from "./helpers";

function* fetchDashboard() {
  try {
    yield put(actions.fetchDashboardPending());
    const response = yield call(getDashboard);
    yield put(actions.setDashboard(response));
    yield put(actions.fetchDashboardSuccess());
  } catch (err) {
    yield put(actions.fetchDashboardFailure());
  }
}

function* markActivitySeen(id) {
  try {
    yield put(actions.markActivitySeenPending());
    yield call(putActivitySeen, id);
    yield put(actions.markActivitySeenSuccess());
  } catch (err) {
    yield put(actions.markActivitySeenFailure());
  }
}

function* fetchActivities() {
  try {
    yield put(actions.getActivitiesPending());
    const response = yield call(getActivities);
    yield put(actions.setActivities(response.data.activities));
    yield put(actions.getActivitiesSuccess());
  } catch (err) {
    yield put(actions.getActivitiesFailure());
  }
}

export { fetchDashboard, markActivitySeen, fetchActivities };
