import { makeStyles } from "@material-ui/core";

export default makeStyles(({ palette, spacing, typography, breakpoint }) => ({
  root: {
    padding: `${spacing(4)}px ${spacing(4)}px`,
  },
  goBack: {
    marginBottom: spacing(4),
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    fontFamily: "GT Flexa Mono, sans-serif",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  card: {
    borderRadius: spacing(2),
    background: palette.common.white,
    padding: `${spacing(4)}px !important`,
  },
  goBackIcon: {
    stroke: palette.secondary.dark,
    transform: "rotate(180deg)",
    marginRight: spacing(1),
    width: 20,
    height: 24,
  },
  cardRoot: {
    borderRadius: spacing(3),
    padding: spacing(4),
    background: palette.common.white,
    position: "relative",
    margin: "0 auto",
    [breakpoint.down("xl")]: {
      padding: `${spacing(3)}px 0 ${spacing(3)}px ${spacing(3)}px`,
    },
    [breakpoint.down("lg")]: {
      padding: spacing(3),
    },
  },
  card2Root: {
    borderRadius: spacing(3),
    padding: spacing(4),
    background: palette.common.white,
    position: "relative",
    minHeight: 116,
    maxWidth: 976,
    margin: `${spacing(2)}px auto`,
  },
  scrollTo: {
    position: "absolute",
    top: -60,
  },
  avatar: {
    marginRight: spacing(3),
    width: 56,
    height: 56,
  },
  mono: {
    fontFamily: "GT Flexa Mono, sans-serif",
  },
  info: {
    display: "flex",
  },
  editInfo: {
    paddingTop: spacing(1),
  },
  sector: {
    marginRight: spacing(1),
    marginBottom: spacing(1),
    borderColor: `${palette.text.backgroundDark} !important`,
    boxShadow: "none !important",
    padding: "10px !important",
    display: "inline-flex !important",
  },
  disabledSector: {
    cursor: "default !important",
  },
  activeSector: {
    boxShadow:
      "0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04)",
    border: "1px solid #0B295F !important",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    maxWidth: 976,
    margin: "0 auto",
  },
  addCtas: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginTop: spacing(2),
  },
  editPreviewCtas: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: spacing(2),
  },
  edit: {
    marginLeft: spacing(3),
  },
  subindustriesTitle: {
    display: "inline-flex",
  },
  moreIcon: {
    fill: palette.secondary.dark,
  },
  moreIconOpened: {
    fill: palette.primary.dark,
  },
  moreButton: {
    position: "absolute",
    top: spacing(4),
    right: spacing(4),
    cursor: "pointer",
  },
  inProgressIcon: {
    marginRight: "0 !important",
    marginLeft: spacing(1),
  },
  inProgressPill: {
    backgroundColor: `${palette.text.backgroundLight} !important`,
    border: `1px solid ${palette.text.light} !important`,
    borderRadius: spacing(1),
    padding: "5px 14px",
    display: "inline-flex",
    color: `${palette.text.light} !important`,
    cursor: "default",
    marginRight: spacing(1),
  },
  enabledPill: {
    cursor: "pointer",
  },
  tooltip: {
    padding: `16px 24px !important`,
    borderRadius: "16px",
    fontFamily: "GT Flexa Mono, sans-serif",
  },
  mlRequestWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: spacing(6),
  },
  fullWidth: {
    width: "100%",
    marginLeft: 134,
  },
  halfWidth: {
    width: "70%",
    marginBottom: spacing(2),
    "& input": {
      // ...typography.subtitle1,
      color: palette.secondary.dark,
    },
  },
  editAvatar: {
    position: "absolute",
    top: spacing(3),
    left: spacing(3),
    width: 120,
    height: 120,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: `1px solid rgba(0, 0, 0, 0.5)`,
    borderRadius: "50%",
    background: palette.primary.backgroundMedium,
    "& img": {
      margin: 0,
    },
    "&:hover": {
      background: "rgba(0, 0, 0, 0.5)",
      cursor: "pointer",
      "& $avatar": {
        display: "none",
      },
      "& $cameraIcon": {
        display: "block",
      },
    },
  },
  cameraIcon: {
    display: "none",
  },
  addSubsector: {
    marginTop: spacing(4),
  },
  addSubsectorName: {
    marginBottom: spacing(2),
  },
  editSelectedSubsector: {
    marginTop: spacing(5),
  },
  marginTop: {
    marginTop: spacing(2),
  },
  lastUpdatedValue: {
    color: palette.common.black,
    fontFamily: "GT Flexa, sans-serif",
  },
  clusters: {
    width: 500,
    height: 500,
    position: "relative",
    display: "inline-block",
    "-webkit-transform": "translate3d(0,0,0)",
    "-moz-transform": "translate3d(0,0,0)",
    "-o-transform": "translate3d(0,0,0)",
    transform: "translate3d(0,0,0)",
    // borderRight: `1px solid ${palette.text.backgroundMain}`
    [breakpoint.down("lg")]: {
      alignSelf: "center",
    },
    [breakpoint.down("sm")]: {
      height: 420,
    },
    "@media only screen and (max-width: 420px)": {
      height: 350,
    },
    [breakpoint.down("xs")]: {
      height: 220,
    },
  },
  clusters3DWrapper: {
    [breakpoint.down("lg")]: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  company: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    position: "absolute",
    zIndex: 2,
  },
  clickableCompany: {
    cursor: "pointer",
  },
  subsectorColor: {
    padding: "5px 10px",
    borderRadius: 8,
    border: "1px solid black",
    cursor: "pointer",
    display: "inline-block",
    marginBottom: 8,
    marginRight: 8,
  },
  notSelectedCompany: {
    opacity: "0.3",
    cursor: "default",
    zIndex: 1,
  },
  companyInfo: {
    display: "inline-flex",
    padding: `${spacing(5)}px 0 ${spacing(5)}px ${spacing(4)}px`,
    flexDirection: "column",
    alignItems: "baseline",
    [breakpoint.down("xl")]: {
      padding: `${spacing(5)}px 0 ${spacing(5)}px ${spacing(2)}px`,
    },
  },
  color: {
    width: spacing(1),
    height: spacing(1),
    marginRight: 10,
    borderRadius: "50%",
    display: "inline-block",
  },
  clustersWrapper: {
    paddingTop: spacing(4),
    borderRight: `1px solid ${palette.text.backgroundMain}`,
    paddingRight: 48,
    [breakpoint.down("lg")]: {
      borderRight: "none",
      width: "100%",
    },
  },
  clustersSectionWrapper: {
    // borderTop: `1px solid ${palette.text.backgroundMain}`,
    display: "flex",
    [breakpoint.down("xl")]: {
      overflow: "hidden",
    },
  },
  companyLogo: {
    border: `1px solid ${palette.text.backgroundMain}`,
    borderRadius: 8,
    padding: 5,
    marginRight: spacing(2),
    marginBottom: spacing(3),
    [breakpoint.down("xl")]: {
      marginBottom: spacing(1),
    },
  },
  companyHeader: {
    display: "flex",
    position: "relative",
    width: "100%",
    [breakpoint.down("xl")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  companyName: {
    marginTop: spacing(1),
    width: "calc(100% - 100px)",
    [breakpoint.down("xl")]: {
      width: "100%",
      textAlign: "center",
      marginTop: 0,
    },
  },
  label: {
    marginBottom: spacing(1),
  },
  resizeIcon: {
    marginLeft: spacing(2),
  },
  whatsClusterIllustration: {
    maxWidth: 56,
    marginBottom: spacing(3),
    marginTop: spacing(5),
  },
  clusterTitle: {
    marginBottom: spacing(2),
  },
  subsectorsWrapper: {
    marginBottom: spacing(2),
  },
  subsectorTitleWrapper: {
    marginBottom: spacing(3),
  },
  subsectorTitle: {
    alignItems: "center",
    display: "flex",
    marginBottom: spacing(1),
  },
  expander: {
    position: "absolute",
    top: 40,
    right: 32,
  },
  subsectorsExplanationsWrapper: {
    marginTop: spacing(3),
  },
  infoLetter: {
    width: 24,
    height: 24,
    padding: 6,
    background: palette.text.backgroundLight,
    borderRadius: "50%",
    display: "inline-flex",
    marginLeft: spacing(1),
  },
  infoLetterMobile: {
    display: "none",
    width: 24,
    height: 24,
    padding: 6,
    background: palette.text.backgroundLight,
    borderRadius: "50%",
    marginLeft: spacing(2),
    [breakpoint.down("sm")]: {
      display: "inline-flex",
    },
  },
  readDefinitions: {
    display: "inline-flex",
    cursor: "pointer",
    "&:hover": {
      color: palette.secondary.dark,
    },
    [breakpoint.down("sm")]: {
      display: "none",
    },
  },
  subsectorsHeader: {
    display: "flex",
    marginTop: spacing(3),
    marginBottom: spacing(2),
    justifyContent: "space-between",
    [breakpoint.down("xl")]: {
      paddingRight: spacing(3),
    },
    [breakpoint.down("lg")]: {
      paddingRight: 0,
    },
  },
  shareIcon: {
    marginRight: "0 !important",
  },
  valuerScore: {
    position: "absolute",
    top: -25,
    right: 0,
    [breakpoint.down("xl")]: {
      position: "relative",
      top: "auto",
      right: "auto",
    },
  },
  sectors: {
    marginBottom: spacing(2),
  },
  greenBox: {
    background: palette.success.backgroundLight,
    padding: `${spacing(4)}px ${spacing(2)}px`,
    borderRadius: spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  greenBoxCtas: {
    display: "flex",
    flexDirection: "column",
    marginBottom: spacing(4),
    marginTop: spacing(3),
    [breakpoint.up("xl")]: {
      display: "flex",
      flexDirection: "row",
    },
  },
  signUpToGetTitle: {
    margin: `0 ${spacing(2)}px`,
  },
  wantToSeeLabel: {
    margin: `${spacing(4)}px 0`,
  },
  getAccessLabel: {
    margin: `${spacing(4)}px 0`,
  },
  signUpBtn: {
    marginLeft: 0,
    marginTop: spacing(2),
    color: palette.common.white,
    background: palette.success.dark,
    padding: `10px ${spacing(3)}px`,
    fontFamily: "GT Flexa Mono, sans-serif",
    borderRadius: spacing(5),
    cursor: "pointer",
    "&:active": {
      backgroundColor: palette.success.dark,
      border: `2px solid ${palette.success.dark}`,
    },
    [breakpoint.up("xl")]: {
      marginLeft: spacing(3),
      marginTop: 0,
    },
  },
  signInBtn: {
    color: palette.success.dark,
    borderRadius: spacing(5),
    border: `1px solid ${palette.success.dark}`,
    fontFamily: "GT Flexa Mono, sans-serif",
    cursor: "pointer",
    padding: `10px ${spacing(3)}px`,
    "&:hover": {
      border: `1px solid ${palette.success.dark}`,
      backgroundColor: palette.success.backgroundLight,
      boxShadow: "none",
    },
    "&:active": {
      backgroundColor: "transparent",
      border: `1px solid ${palette.success.main}`,
    },
    "&:disabled": {
      color: palette.success.main,
      backgroundColor: "transparent",
      border: `1px solid ${palette.success.main}`,
      cursor: "default",
    },
  },
  unselectAll: {
    cursor: "pointer",
    marginLeft: spacing(3),
    [breakpoint.down("sm")]: {
      display: "block",
      marginLeft: 0,
      marginTop: spacing(2),
    },
    "&:hover": {
      color: palette.primary.dark,
    },
  },
  readMore: {
    fontFamily: "GT Flexa Mono, sans-serif",
    cursor: "pointer",
    alignSelf: "flex-end",
    marginTop: 4,
  },
  clusterDescClosed: {
    maxHeight: 180,
    overflowY: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": 9,
  },
  descriptionBox: {
    paddingRight: spacing(2),
  },
  arrowImg: {
    marginLeft: spacing(1),
  },
  projectName: {
    width: "100%",
    padding: `${spacing(2)}px ${spacing(3)}px`,
    "&:hover": {
      background: palette.text.backgroundLight,
    },
    cursor: "pointer",
    display: "flex",
  },
  disabled: {
    opacity: 0.5,
  },
  rotateArrow: {
    marginLeft: 10,
    transform: "rotate(180deg)",
  },
  tagButtonWrap: {
    display: "flex",
    alignItems: "center",
    marginTop: spacing(6),
    position: "relative",
    [breakpoint.down("xl")]: {
      flexDirection: "column",
      marginTop: spacing(3),
    },
  },
  openCompanyButton: {
    padding: `${spacing(1)}px ${spacing(1)}px ${spacing(1)}px ${spacing(3)}px `,
    marginLeft: spacing(2),
    [breakpoint.down("xl")]: {
      marginLeft: 0,
      marginTop: spacing(2),
    },
  },
  openCompanyButtonFreeUser: {
    marginRight: spacing(2),
    [breakpoint.down("xl")]: {
      marginLeft: 0,
      marginTop: spacing(2),
    },
  },
  description: {
    marginTop: spacing(3),
  },
  preview: {
    display: "none",
    textDecoration: "underline",
    cursor: "pointer",
    [breakpoint.down("lg")]: {
      display: "flex",
    },
  },
  questionMark: {
    marginLeft: spacing(1),
  },
  companiesWrap: {
    marginTop: spacing(4),
    margin: "0 auto",
  },
  subsectorPill: {
    lineHeight: "21px",
  },
  showCluserMapButton: {
    fontFamily: "GT Flexa Mono, sans-serif",
    background: palette.success.backgroundLight,
    borderRadius: spacing(5),
    padding: `12px ${spacing(3)}px`,
    cursor: "pointer",
    "&:hover": {
      opacity: 0.9,
    },
  },
  exploreClusterMapWrap: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  exploreClusterMapInfo: {
    marginRight: spacing(1),
  },
  dialogScrollPaper: {
    maxWidth: 1162,
    [breakpoint.down("lg")]: {
      width: "100%",
    },
  },
  modalPaper: {
    padding: `${spacing(6)}px ${spacing(5)}px ${spacing(3)}px ${spacing(5)}px`,
  },
  blackProjectName: {
    color: palette.common.black,
    cursor: "default",
  },
  industryTitle: {
    display: "flex",
    alignItems: "center",
  },
  addedToProject: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    width: "100%",
  },
  addPopup: {
    zIndex: 2,
    width: 235,
    maxHeight: 202,
    background: palette.common.white,
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    borderRadius: spacing(2),
    border: "1px solid",
    borderColor: palette.text.backgroundDark,
    top: 40,
    right: -10,
    overflow: "auto",
    paddingTop: spacing(1),
    paddingBottom: spacing(1),
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    "&::-webkit-scrollbar": {
      width: 2,
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 5px transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      background: palette.text.main,
      borderRadius: 0,
    },
  },
  addToProject: {
    cursor: "pointer",
    background: "transparent",
    color: palette.text.main,
    alignItems: "center",
    position: "relative",
    marginLeft: spacing(3),
    paddingLeft: spacing(3),
    borderLeft: `1px solid ${palette.text.backgroundMain}`,
    "&:hover": {
      "& $checkboxTick": {
        display: "block",
      },
    },
  },
  dialogClosed: {
    display: "none",
  },
  addToProjectDisabled: {
    marginLeft: spacing(3),
    paddingLeft: spacing(3),
    borderLeft: `1px solid ${palette.text.backgroundMain}`,
  },
  rssButtonFreeUser: {
    background: palette.primary.backgroundLight,
    color: palette.common.black,
  },
  addToProjectWrapper: {
    position: "relative",
  },
  removeImg: {
    marginRight: spacing(1),
  },
  checkboxRectangle: {
    marginRight: spacing(1),
    height: spacing(3),
  },
  checkboxTick: {
    display: "none",
    position: "absolute",
    top: 8,
    left: 29,
  },
  projectNameWrapper: {
    width: "100%",
    display: "flex",
    padding: `${spacing(2)}px ${spacing(3)}px`,
    borderBottom: `1px solid ${palette.text.backgroundLight}`,
    color: palette.text.light,
    "&:hover": {
      background: palette.text.backgroundLight,
    },
  },
  clickable: {
    cursor: "pointer",
  },
  projectName: {
    display: "flex",
    alignItems: "center",
    color: palette.text.light,
  },
  createNewProject: {
    display: "flex",
    justifyContent: "center",
  },
  openCompanyButton: {
    border: `1px solid ${palette.primary.dark}`,
    borderRadius: spacing(5),
    cursor: "pointer",
    padding: `12px ${spacing(4)}px`,
    "&:hover": {
      background: palette.primary.dark,
      color: palette.common.white,
    },
  },
  reachOut: {
    color: palette.primary.main,
    textDecoration: "underline",
    cursor: "pointer",
  },
}));
