import { useEffect, useState } from "react";
//Redux
import { useDispatch, useSelector } from "react-redux";
import { actions as searchActions } from "../../../store/search/slice.js";
import {
  getSearchesSelector,
  getSearchesStatusSelector,
} from "../../../store/results/selectors";
import { fetchSearchesRequest } from "../../../store/results/requests";
//Hooks
import { useTranslation } from "react-i18next";
import { useAuthHook } from "../../../common/useAuthHook";
import { useRouterHook } from "../../../common/useRouterHook";
import { Shared } from "../../../utils/shared.js";

export const useSavedSearchesHook = ({
  page,
  setPage,
  totalPages,
  sortValue,
  sortDirection,
  totalCount,
  searchValue,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useAuthHook();
  const [upArrowVisible, setUpArrowVisible] = useState(false);
  const searches = useSelector(getSearchesSelector);
  const [allSearches, setAllSearches] = useState(null);
  const searchesStatusSelector = useSelector(getSearchesStatusSelector);
  const [noSearches, setNoSearches] = useState(true);
  const [showLoader, setShowLoader] = useState(true);
  const [timeout, setTimeoutVal] = useState(null);
  const { openPage } = useRouterHook();
  const [isSearching, setIsSearching] = useState(null);
  const { userSwitchedFromTrialToFree } = Shared({ getUser: user });

  useEffect(() => {
    setPage(1);
  }, [sortValue, sortDirection, searchValue]);

  const handleOnSearch = searchValue => {
    setIsSearching(searchValue !== "");
    // setShowLoader(true);
    const data = {
      orderBy: sortValue,
      orderDirection: sortDirection,
      page: 1,
      searchValue,
      isSortSearch: true,
      savedSearches: true,
    };
    clearTimeout(timeout);
    setTimeoutVal(
      setTimeout(() => {
        dispatch(fetchSearchesRequest({ data, isEditSearch: true }));
        // setShowLoader(false);
      }, 900),
    );
  };
  // const checkSearchCriteria = (criteria, search) => {
  //     let sectorFound = false;
  //     let subSectorFound = false;
  //     let textFound = false;
  //     let scopeFound = false;

  //     sectorFound = search.sectors && search.sectors.filter(f => f.name.toLowerCase().includes(criteria.toLowerCase())).length > 0;
  //     textFound = search.description.toLowerCase().includes(criteria.toLowerCase());
  //     scopeFound = search.scope.toLowerCase().includes(criteria.toLowerCase());

  //     search.sectors && search.sectors.forEach(sector => {
  //         sector.subsectors && sector.subsectors.forEach(subsector => {
  //             if (subsector.name.toLowerCase().includes(criteria.toLowerCase())) {
  //                 subSectorFound = true;
  //             }
  //         })
  //     })

  //     return sectorFound || textFound || subSectorFound || scopeFound;
  // };

  const handleScroll = () => {
    window.innerHeight < window.scrollY
      ? setUpArrowVisible(true)
      : setUpArrowVisible(false);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const goToSearch = () => {
    dispatch(searchActions.clearSearch());
    openPage("/search-type");
  };

  ////sort/pagination
  const moreData = newPage => {
    if (totalCount !== 0) {
      if (totalPages && page) {
        const data = {
          page: newPage,
          orderBy: sortValue,
          orderDirection: sortDirection,
          searchValue,
          savedSearches: true,
        };
        dispatch(fetchSearchesRequest({ data }));
      }
      setPage(newPage);
    }
    // setIsFetching(false);
  };
  // const [isFetching, setIsFetching] = useInfiniteScroll(debounce(moreData, 300), totalCount);

  return {
    t,
    dispatch,
    user,
    upArrowVisible,
    allSearches,
    searchesStatusSelector,
    setNoSearches,
    noSearches,
    showLoader,
    handleScroll,
    scrollToTop,
    searches,
    setAllSearches,
    setShowLoader,
    handleOnSearch,
    openPage,
    goToSearch,
    isSearching,
    setIsSearching,
    userSwitchedFromTrialToFree,
    moreData,
  };
};
