import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  contentWhite: {
    background: `${theme.palette.common.white} !important`,
  },
  paperAnchorLeft: {
    top: "56px !important",
  },
  wrapper: {
    padding: `${theme.spacing(3)}px`,
  },
  content: {
    flexGrow: 1,
    minHeight: "100vh",
    backgroundColor: theme.palette.grey.light,
    // position: "relative",
    width: "calc(100% - 68px)",
    marginLeft: 68,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // marginTop: theme.spacing(6),
    [theme.breakpoint.down("lg")]: {
      width: "100%",
      marginLeft: 0,
    },
  },
  resultsHeader: {
    display: "flex",
    flexDirection: "column",
    minHeight: "105px",
    justifyContent: "start",
    alignItems: "center",
    height: "auto",
    margin: `0 ${theme.spacing(4)}px`,
    [theme.breakpoint.down("lg")]: {
      position: "relative",
      zIndex: 0,
      margin: `0 ${theme.spacing(2)}px`,
    },
  },
  moreIcon: {
    fill: theme.palette.secondary.dark,
    width: theme.spacing(2),
  },
  moreIconOpened: {
    fill: theme.palette.primary.dark,
  },
  moreButton: {
    minWidth: 0,
    padding: 0,
    [theme.breakpoint.up("md")]: {
      display: "none",
    },
  },
  // headerLabel: {
  //     color: theme.palette.common.black,
  //     marginBottom: 8
  // },
  headerInput: {
    width: "100%",
    "& input": {
      width: 167,
    },
  },
  filterButton: {
    position: "relative",
    background: `${theme.palette.common.white} !important`,
    border: `1px solid ${theme.palette.text.main}`,
    padding: `11px 18px`,
    borderRadius: 24,
    marginLeft: `${theme.spacing(2)}px`,
    display: "flex",
    alignItems: "center",
    height: 40,
    "&:hover": {
      background: `${theme.palette.primary.dark}!important`,
      border: "1px solid white",
      color: "white",
      cursor: "pointer",
      "& svg path": {
        // fill: "white",
        stroke: `${theme.palette.common.white} !important`,
      },
    },
    [theme.breakpoint.down("lg")]: {
      marginLeft: theme.spacing(1),
    },
  },
  filterButtonFull: {
    border: `1px solid ${theme.palette.common.black}`,
  },
  displayedFiltersCount: {
    position: "absolute",
    top: -1,
    right: -1,
    background: theme.palette.primary.dark,
    borderRadius: "50%",
    width: 16,
    height: 16,
    color: theme.palette.common.white,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    ...theme.typography.bodyXs,
  },
  sortingButton: {
    background: theme.palette.common.white,
    borderTopLeftRadius: 24,
    borderBottomLeftRadius: 24,
    padding: "7px 8px",
    display: "flex",
    border: `1px solid ${theme.palette.text.lightest}`,
    borderRight: 0,
    cursor: "pointer",
  },
  sortingIcon: {
    width: 24,
    height: 24,
  },
  rotateSortingButton: {
    transform: "rotate(180deg)",
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: 24,
    borderBottomRightRadius: 24,
    borderLeft: 0,
    borderRight: `1px solid ${theme.palette.text.lightest}`,
  },
  filterIcon: {
    marginRight: "5px",
    "& > path": {
      stroke: theme.palette.text.main,
    },
  },
  filterIconFull: {
    "& > path": {
      stroke: theme.palette.primary.dark,
    },
  },
  resultsTotal: {
    color: theme.palette.text.light,
    marginLeft: theme.spacing(1),
    whiteSpace: "nowrap",
    "@media screen and (max-width: 1050px)": {
      marginLeft: 0,
    },
  },
  headerTitle: {
    width: "100%",
    marginBottom: "16px",
    marginTop: "16px",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  headerTitleResultsCount: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    "@media screen and (max-width: 1050px)": {
      flexDirection: "column",
      alignItems: "start",
    },
  },
  headerLeftPart: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  headerRightPart: {},
  headerWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
  filterWrap: {
    marginLeft: `${theme.spacing(2)}px`,
    display: "flex",
    alignItems: "center",
    [theme.breakpoint.down("md")]: {
      marginLeft: 0,
    },
  },
  headerTabIcon: {
    marginRight: 12,
  },
  headerTab: {
    borderRadius: "24px 24px 0px 0px",
    padding: "14px 29px 30px 19px",
    marginBottom: -30,
    alignItems: "center",
    display: "flex",
    cursor: "pointer",
  },
  headerTabActive: {
    background: theme.palette.success.backgroundLight,
  },
  headerSortAndFilter: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoint.down("md")]: {
      marginTop: theme.spacing(1),
    },
  },
  refineAndSearch: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
    maxWidth: 200,
    [theme.breakpoint.down("md")]: {
      width: "100%",
      maxWidth: "100%",
    },
  },
  sidebarRoot: {
    height: "calc(100% - 56px)",
    boxShadow: "1px 0px 1px rgba(0, 0, 0, 0.1)",
    borderRight: "1px solid rgba(0, 0, 0, 0.12)",
    display: "flex",
    flexDirection: "column",
    width: 236,
    backgroundColor: theme.palette.common.white,
    position: "fixed",
    left: 0,
    top: theme.spacing(6),
    bottom: 0,
    [theme.breakpoint.down("lg")]: {
      right: 0,
      width: "100%",
      zIndex: 3,
      display: "none",
    },
    "@media screen and (min-height: 830px)": {
      justifyContent: "space-between",
    },
  },
  mobileOpened: {
    display: "block",
  },
  additionalSidebarMenu: {
    [theme.breakpoint.down("sm")]: {
      backgroundColor: theme.palette.common.white,
      position: "fixed",
      left: 0,
      right: 0,
      top: theme.spacing(6),
      bottom: 0,
      width: "100%",
      zIndex: 4,
      height: "calc(100% - 56px)",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
    },
  },
  mainSidebar: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    paddingTop: 13,
    "@media screen and (max-height: 830px)": {
      paddingTop: 0,
    },
  },
  creditsSidebarMenu: {
    [theme.breakpoint.down("sm")]: {
      backgroundColor: theme.palette.common.white,
      position: "fixed",
      left: 0,
      right: 0,
      top: theme.spacing(6),
      bottom: 0,
      width: "100%",
      zIndex: 4,
      height: "calc(100% - 56px)",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
    },
  },
  fullIconLabel: {
    margin: 0,
  },
  shortIconLabel: {
    margin: 0,
    fontSize: `12px`,
    color: theme.palette.text.lighter,
    // "&:active": {
    // 	color: theme.palette.primary.dark
    // },
  },
  icons: {
    width: "30px",
  },
  arrowIcon2: {
    padding: "14px 12px",
  },
  startSearchWrapper: {
    padding: `${theme.spacing(3)}px ${theme.spacing(2)}px`,
  },
  navigationItems: {
    width: "100%",
    height: "calc(100vh - 99px)",
    marginTop: `${theme.spacing(5)}px`,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    "& > svg": {
      stroke: "none",
    },
  },
  iconFull: {},
  iconShort: {
    margin: 0,
  },
  navigationItemClosed: {
    display: "flex",
    flexDirection: "column",
    padding: "8px !important",
    lineHeight: "17px",
    "&.active": {
      "& > span": {
        color: theme.palette.primary.dark,
      },
    },
    "&:hover": {
      "& > span": {
        color: theme.palette.primary.dark,
      },
    },
    "& > svg": {
      stroke: theme.palette.text.lighter,
      margin: "0 !important",
    },
  },
  navigationItem: {
    display: "flex",
    color: theme.palette.text.main,
    padding: `16px ${theme.spacing(4)}px !important`,
    position: "relative",
    fontFamily: "GT Flexa, sans-serif",
    fontSize: 16,
    lineHeight: "22px",
    maxHeight: "54px !important",
    "& > svg": {
      stroke: theme.palette.text.lighter,
    },
    "&:disabled": {
      color: theme.palette.text.lighter,
      cursor: "default",
      "& > svg": {
        // stroke: theme.palette.text.lighter,
        marginRight: theme.spacing(2),
      },
    },
    "&.active": {
      color: theme.palette.primary.dark,
    },
    "&:hover": {
      "& > svg": {
        stroke: theme.palette.primary.dark,
        strokeWidth: 0.5,
      },
    },
    [theme.breakpoint.down("lg")]: {
      padding: `12px ${theme.spacing(4)}px`,
      height: "auto",
    },
    [theme.breakpoint.down("sm")]: {
      padding: `12px ${theme.spacing(4)}px`,
      height: "auto",
    },
    "@media screen and (max-height: 950px)": {
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
      height: "auto",
    },
    "@media screen and (max-height: 830px)": {
      padding: `10px ${theme.spacing(3)}px`,
      height: "auto",
    },
  },
  profileNavigation: {
    [theme.breakpoint.down("sm")]: {
      fontFamily: "GT Flexa Mono, sans-serif",
      ...theme.typography.bodyButton,
    },
  },
  isCreditsNavigation: {
    [theme.breakpoint.down("sm")]: {
      color: theme.palette.text.light,
      fontFamily: "GT Flexa Mono, sans-serif",
      ...theme.typography.bodyButton,
    },
  },
  logoutNavigation: {
    [theme.breakpoint.down("sm")]: {
      color: theme.palette.error.main,
      fontFamily: "GT Flexa Mono, sans-serif",
      ...theme.typography.bodyXs,
    },
  },
  linkResources: {
    color: theme.palette.text.main,
    // padding: `${theme.spacing(3)}px 34px`,
    fontFamily: "GT Flexa, sans-serif",
    fontSize: 16,
    lineHeight: "22px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    textDecoration: "none !important",
    "&:hover": {
      color: theme.palette.primary.dark,
      "& > svg": {
        stroke: theme.palette.primary.dark,
      },
    },
    "& div": {
      marginLeft: theme.spacing(2),
    },
    "& > svg": {
      //stroke: theme.palette.text.lighter,
    },
    [theme.breakpoint.down("lg")]: {
      padding: `0 ${theme.spacing(4)}px`,
      height: "auto",
    },
    "@media screen and (max-height: 950px)": {
      padding: `0 ${theme.spacing(3)}px`,
      height: "auto",
    },
    "@media screen and (max-height: 830px)": {
      paddingBottom: 0,
      paddingTop: 0,
    },
  },
  resourcesIcon: {
    marginLeft: 2,
  },

  topNav: {
    height: 56,
    width: "100%",
    background: theme.palette.common.white,
    padding: `9px ${theme.spacing(4)}px`,
    zIndex: 1202,
    boxShadow: "0px 1px 1px 0px rgb(0 0 0 / 12%)",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    position: "fixed",
    [theme.breakpoint.down("sm")]: {
      padding: `9px ${theme.spacing(2)}px`,
    },
  },
  avatarIcon: {
    position: "relative",
  },
  logoutMenuItem: {
    position: "absolute",
    zIndex: 1203,
    top: 37,
    right: 0,
    background: theme.palette.common.white,
    borderRadius: theme.spacing(1),
    border: `1px solid ${theme.palette.text.backgroundDark}`,
    fontFamily: "GT Flexa Mono, sans-serif",
    color: theme.palette.error.main,
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    ...theme.typography.bodyXs,
    "&:hover": {
      background: theme.palette.common.white,
      textDecoration: "underline",
    },
  },
  arrowDownBlack: {
    stroke: theme.palette.text.main,
    marginLeft: theme.spacing(1),
  },
  logoTopNav: {
    paddingTop: "6px",
    height: 32,
  },
  arrowImg: {
    stroke: theme.palette.text.main,
  },
  flexy: {
    display: "flex",
  },
  teamsMenu: {
    textTransform: "none",
  },
  topNavLeftSide: {
    display: "flex",
    flexDirection: "row",
  },
  teamsButton: {
    marginLeft: 59,
    borderLeft: `1px solid ${theme.palette.text.backgroundMain}`,
    marginTop: -theme.spacing(1),
    marginBottom: -theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
  },
  menuItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  accountSwitcher: {
    marginLeft: 37,
    justifyContent: "space-between !important",
  },
  accountSwitcherLaptop: {
    paddingLeft: theme.spacing(7),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    borderTop: `1px solid ${theme.palette.text.backgroundMain}`,
    borderBottom: `1px solid ${theme.palette.text.backgroundMain}`,
    justifyContent: "space-between !important",
    display: "flex",
  },
  divider: {
    background: theme.palette.text.backgroundMain,
    width: "100%",
    height: 1,
    borderRadius: 1,
    margin: `${theme.spacing(3)}px 0`,
    [theme.breakpoint.up("lg")]: {
      display: "none",
    },
  },
  dividerDesktop: {
    background: theme.palette.text.backgroundMain,
    width: "100%",
    height: 1,
    borderRadius: 1,
    margin: `${theme.spacing(3)}px 0`,
    display: "block",
    [theme.breakpoint.down("lg")]: {
      display: "none",
    },
  },
  avatar: {
    width: 32,
    height: 32,
    borderRadius: "50%",
    cursor: "pointer",
  },
  topNavItem: {
    marginLeft: theme.spacing(2),
    cursor: "pointer",
  },
  userSection: {
    padding: `${theme.spacing(2)}px 0`,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    [theme.breakpoint.down("sm")]: {
      padding: 0,
    },
  },
  logOut: {
    margin: `${theme.spacing(3)}px 0`,
  },
  startEndornment: {
    position: "absolute",
    right: `${theme.spacing(1)}px`,
  },
  arrowIcon: {
    marginRight: `${theme.spacing(1)}px`,
    cursor: "pointer",
    transform: "rotate(0deg) scale(1.4)",
  },
  rotateIcon: {
    transform: "rotate(180deg) scale(1.4)",
  },
  inviteIcon: {
    marginRight: theme.spacing(1),
    cursor: "pointer",
  },
  searchImage: {
    marginRight: theme.spacing(3),
  },
  editSearchBtn: {
    border: "none",
    background: "transparent",
    marginRight: theme.spacing(2),
    height: theme.spacing(5),
    "&:hover": {
      background: theme.palette.primary.backgroundLight,
      border: "none",
    },
  },
  startSearchBtn: {
    background: theme.palette.primary.dark,
  },
  multiselectAdjust: {
    width: 236,
    [theme.breakpoint.down("md")]: {
      width: `auto`,
    },
    // border: "none",
    // background: "transparent",
    // borderRadius: theme.spacing(3),
    // "&:hover": {
    //     background: theme.palette.primary.backgroundLight,
    //     border: "none"
    // }
  },
  sortedBy: {
    marginRight: theme.spacing(1),
  },
  creditsIcon: {
    width: 17,
    height: 14,
  },
  creditsIconWrapper: {
    width: 32,
    height: 32,
    background: theme.palette.success.backgroundLight,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  creditsWrapper: {
    display: "flex",
    alignItems: "center",
    fontFamily: "GT Flexa Mono, sans-serif",
  },
  creditsLeftText: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(3),
    cursor: "pointer",
  },
  creditsLeftTextDisabled: {
    cursor: "default",
  },
  creditsText: {
    margin: `0 ${theme.spacing(1)}px`,
  },
  arrowDown: {
    width: 12,
    position: "absolute",
    right: theme.spacing(2),
    zIndex: 2,
    cursor: "pointer",
  },
  creditsDropdown: {
    background: theme.palette.common.white,
    border: `1px solid ${theme.palette.text.backgroundDark}`,
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    borderRadius: theme.spacing(1),
    position: "absolute",
    top: 32,
    right: 16,
    minWidth: 200,
  },
  dropdown: {
    position: "relative",
  },
  creditsReplenishText: {
    textAlign: "center",
    borderBottom: `1px solid ${theme.palette.text.backgroundLight}`,
    padding: `${theme.spacing(3)}px ${theme.spacing(3)}px ${theme.spacing(
      2,
    )}px ${theme.spacing(3)}px`,
  },
  getMoreCreditsText: {
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      3,
    )}px ${theme.spacing(3)}px`,
  },
  getMoreCreditsTextWIthoutBilling: {
    padding: `${theme.spacing(3)}px`,
  },
  getMoreCredits: {
    textDecoration: "underline",
    cursor: "pointer",
  },
  avatarWrapper: {
    width: 32,
    height: 32,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: theme.palette.success.backgroundLight,
    marginLeft: theme.spacing(2),
  },
  avatarSidebar: {
    width: 24,
    height: 24,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: theme.palette.success.backgroundLight,
    marginRight: theme.spacing(2),
  },
  inviteUsers: {
    cursor: "pointer",
    marginLeft: theme.spacing(1),
  },
  tagWrapper: {
    marginTop: -12,
    padding: "0 12px",
    background: theme.palette.common.white,
  },
  disabledNavigationItem: {
    color: theme.palette.text.lighter,
    cursor: "default",
    padding: "24px 32px",
    width: "100%",
    height: 70,
    margin: 0,
    zIndex: 1,
    maxHeight: 70,
    lineHeight: "22px",
  },
  tooltipContent: {
    display: "flex",
    alignItems: "center",
    textTransform: "none",
    verticalAlign: "top",
    maxHeight: 24,
    minWidth: 160,
  },
  disabledNavigationItemIcon: {
    //stroke: theme.palette.text.lighter,
    marginRight: 16,
  },
  tagBorder: {
    borderTop: `1px solid ${theme.palette.text.backgroundMain}`,
    margin: "24px 12px 12px 12px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  upgradeBox: {
    background: theme.palette.primary.backgroundMedium,
    borderRadius: theme.spacing(3),
    padding: `${theme.spacing(4)}px ${theme.spacing(3)}px`,
    margin: `${theme.spacing(3)}px ${theme.spacing(3)}px ${theme.spacing(
      1,
    )}px ${theme.spacing(3)}px`,
    [theme.breakpoint.down("lg")]: {
      background: "transparent",
      padding: 0,
      marginBottom: 0,
      marginTop: 0,
    },
  },
  creditsBox: {
    marginBottom: theme.spacing(3),
    marginLeft: 20,
  },
  creditsSummaryBox: {
    marginBottom: theme.spacing(3),
    marginLeft: 20,
  },
  upgradeCta: {
    marginTop: theme.spacing(2),
    textDecoration: "underline",
    cursor: "pointer",
    textAlign: "center",
    fontFamily: "GT Flexa Mono, sans-serif",
    [theme.breakpoint.down("lg")]: {
      textAlign: "left",
      marginLeft: 50,
      marginTop: 0,
    },
    [theme.breakpoint.down("sm")]: {
      marginTop: theme.spacing(2),
    },
  },
  startTrial: {
    marginTop: theme.spacing(2),
    textDecoration: "underline",
    cursor: "pointer",
    textAlign: "center",
    fontFamily: "GT Flexa Mono, sans-serif",
    [theme.breakpoint.down("lg")]: {
      textAlign: "left",
      marginLeft: 50,
      marginTop: 0,
    },
    [theme.breakpoint.down("sm")]: {
      marginTop: theme.spacing(2),
      ...theme.typography.bodyButton,
    },
  },
  profileArrow: {
    position: "absolute",
    right: 40,
  },
  upgradeText: {
    [theme.breakpoint.down("lg")]: {
      display: "none",
    },
  },
  "@media screen and (max-height: 700px)": {
    removeOnSmallHeight: {
      display: "none",
    },
  },
  ml8: {
    marginLeft: theme.spacing(1),
  },
  burgerMenu: {
    alignSelf: "center",
    cursor: "pointer",
  },
  goBack: {
    padding: `${theme.spacing(3)}px`,
  },
  authenticationButtons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  signInButtonSidebar: {
    marginRight: theme.spacing(3),
  },
  rightSideMenu: {
    display: "flex",
    alignItems: "center",
  },
  topIcons: {
    position: "relative",
  },
  bottomIcons: {
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    marginBottom: theme.spacing(2),
  },
  sidebarBottomArea: {
    position: "fixed",
    bottom: 0,
    left: 0,
    width: 235,
    paddingBottom: theme.spacing(3),
    [theme.breakpoint.down("lg")]: {
      right: 0,
      width: "auto",
    },
    "@media only screen and (min-width:769px) and (max-width: 1024px)": {
      maxHeight: "calc(100vh - 290px)",
      overflow: "auto",
    },
  },
  shareButton: {
    background: theme.palette.secondary.dark,
    border: "none",
    color: theme.palette.common.white,
    height: 40,
    marginRight: theme.spacing(2),
    "&:hover": {
      background: theme.palette.secondary.dark,
      border: "none",
    },
    marginLeft: theme.spacing(2),
  },
  shareIcon: {
    marginLeft: theme.spacing(1),
  },
  buttonWrapper: {
    position: "relative",
  },
  copyClipboard: {
    position: "absolute",
    top: -45,
    background: theme.palette.secondary.dark,
    color: theme.palette.common.white,
    borderRadius: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    left: 0,
    zIndex: 9999,
    padding: theme.spacing(1),
    width: 160,
  },
  checkImg: {
    marginRight: theme.spacing(1),
  },
  hiddenInput: {
    position: "absolute",
    left: 0,
    top: -30,
    width: 300,
  },
  upgradeYourPlan: {
    color: theme.palette.primary.dark,
    cursor: "pointer",
    textDecoration: "underline",
    padding: `${theme.spacing(4)}px ${theme.spacing(3)}px`,
  },
  trialBannerWrap: {
    display: "flex",
    flexDirection: "column",
    background: theme.palette.primary.backgroundLight,
    borderRadius: theme.spacing(2),
    width: "100%",
    padding: `${theme.spacing(3)}px ${theme.spacing(2)}px`,
    textAlign: "center",
    "@media screen and (max-height: 830px)": {
      padding: `${theme.spacing(2)}px`,
    },
  },
  trialBannerWrapText: {
    display: "block",
    "@media screen and (max-height: 950px)": {
      display: "none",
    },
  },
  activeTrialBannerWrapText: {
    "@media screen and (max-height: 830px)": {
      lineHeight: "16px",
    },
  },
  trialBlock: {
    padding: `0 ${theme.spacing(3)}px ${theme.spacing(3)}px ${theme.spacing(
      3,
    )}px`,
    [theme.breakpoint.down("lg")]: {
      padding: `0 ${theme.spacing(3)}px`,
    },
    [theme.breakpoint.down("md")]: {
      marginBottom: -40,
      padding: theme.spacing(3),
    },
    [theme.breakpoint.down("sm")]: {
      marginBottom: 0,
      display: "none",
    },
  },
  trialBlockMobile: {
    display: "none",
    [theme.breakpoint.down("md")]: {
      display: "block",
    },
  },
  startTrialButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    fontSize: 13,
    "@media screen and (max-height: 830px)": {
      marginTop: theme.spacing(1),
      maxHeight: "30px",
      fontSize: "12px",
    },
  },
  trialTitle: {
    marginBottom: theme.spacing(1),
    "@media screen and (max-height: 830px)": {
      lineHeight: "16px",
    },
  },
  talkToSalesWrap: {
    display: "flex",
    marginTop: theme.spacing(1),
    justifyContent: "center",
  },
  customTrials: {
    color: theme.palette.text.lighter,
  },
  talkSales: {
    color: theme.palette.primary.dark,
    marginLeft: 4,
    cursor: "pointer",
    zIndex: 3,
  },
  noCreditCard: {
    color: theme.palette.text.lighter,
  },
  onboardingIcon: {
    cursor: "pointer",
    marginLeft: theme.spacing(2),
  },
  freeTrialRoot: {
    padding: `${theme.spacing(3)}px ${theme.spacing(3)}px ${theme.spacing(
      3,
    )}px ${theme.spacing(3)}px`,
  },
  freeTrialMain: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    alignItems: "center",
  },
  trialSubtitle: {
    margin: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
  },
  freeTrialTopNav: {
    [theme.breakpoint.down("md")]: {
      display: "none",
    },
  },
  tableToolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flex: "none",
    paddingBottom: 0,
    width: "auto",
    marginRight: 8,
    [theme.breakpoint.down("sm")]: {
      order: 2,
      width: "auto",
      flexDirection: "row",
    },
  },
  tableCellCheckbox: {
    flex: 1,
    alignSelf: "flex-start",
    wordBreak: "break-word",
    maxWidth: 56,
    minWidth: 56,
    margin: "auto",
  },
  tableCellName: {
    flex: 6,
    wordBreak: "break-word",
    paddingLeft: 4,
    userSelect: "none",
    height: 52,
    display: "flex",
    alignItems: "center",
    maxHeight: 52,
    // overflow: "hidden",
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": 2,
    textOverflow: "ellipsis",
  },
  selectedLabel: {
    display: "flex",
    alignItems: "center",
    whiteSpace: "nowrap",
  },
  tableCell2: {
    flex: 2,
    wordBreak: "break-word",
    paddingLeft: 4,
  },
  header: {
    position: "relative",
    // paddingLeft: theme.spacing(2)
  },
  headerFiltersWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "fit-content",
    flexWrap: "wrap",
    /*  height: '65px' */
  },
  toolbarRight: {
    display: "flex",
    alignItems: "center",
    // marginLeft: "auto",
    marginBottom: "5px",
    [theme.breakpoint.down("sm")]: {
      order: 3,
      marginLeft: 0,
    },
  },
  toolbarRightButtons: {
    display: "flex",
    alignItems: "center",
  },
  iconLabel: {
    marginBottom: 0,
  },
  iconEnabled: {
    cursor: "pointer",
    "&:hover": {
      background: theme.palette.text.backgroundLight,
      borderRadius: 4,
    },
    "& path": {
      fill: theme.palette.common.black,
    },
  },
  iconDownloadOpen: {
    "& path": {
      fill: theme.palette.primary.dark,
    },
  },
  settingsLinkClosed: {
    paddingBottom: "0 !important",
    maxHeight: "53px !important",
  },
  settingsLinkOpen: {
    display: "block",
    paddingBottom: "0 !important",
    maxHeight: "45px !important",
  },
  helpCenter: {
    paddingBottom: "0",
  },
  titleBoxWrapper: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
}));
