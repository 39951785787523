import React, { createRef, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useStyles } from "./style.js";
import classnames from "classnames";
import Typography from "../../../../components/Typography/Typography";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { ReactComponent as Plus } from "../../../../assets/icons/Plus.svg";
import { ReactComponent as MangaPuma } from "../../../../assets/icons/MangaPuma.svg";
import Button from "../../../../components/Button/Button";
import { AddToProjectMenu } from "../../../../components/AddToProjectMenu/AddToProjectMenu";
import MultiSelectSort from "../../../SearchCompanies/NewSearch/Select/Select.js";
import { useAuthHook } from "../../../../common/useAuthHook";
import { useQueryClient } from "@tanstack/react-query";
import ReCAPTCHA from "react-google-recaptcha";
import { useAppEventTracking } from "../../../../common/useAppEventTracking";
import { getAddItemToProjectStatusSelector } from "../../../../store/projects/selectors";
import { actions as searchActions } from "../../../../store/search/slice";
import {
  postSetSearchCountRequest,
  submitSearchRequest,
} from "../../../../store/search/requests";
import { REQUEST_SUCCESS } from "../../../../constants/statuses";

import { regionsOptions } from "../../../SearchCompanies/NewSearch/data.js";
import { getCountriesSelector } from "../../../../store/countries/selectors.js";
import {
  fetchSectorsRequest,
  fetchSubsectorsRequest,
} from "../../../../store/sectors/requests.js";
import usePrevious from "../../../../common/usePrevious.js";
import { getCountriesRequest } from "../../../../store/countries/requests.js";
import { useSectorsHook } from "../../../SearchCompanies/hooks/useSectorsHook.js";
import { getSubsectorsSelector } from "../../../../store/sectors/selectors.js";

export const EditSearch = ({ search }) => {
  const { allSectors } = useSectorsHook();
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { user } = useAuthHook();
  const userQuestionTextarea = useRef(null);
  const reCaptchaRef = createRef();
  const [isSaved, setIsSaved] = useState();
  const { mixpanelTrackEvent } = useAppEventTracking();
  const [description, setDescription] = useState(null);
  const [filtersExpanded, setFiltersExpanded] = useState(false);
  const [isSearchBtnActive, setIsSearchBtnActive] = useState(false);
  const addItemToProjectStatus = useSelector(getAddItemToProjectStatusSelector);
  const subsectors = useSelector(getSubsectorsSelector);

  const [regionsChanged, setRegionsChanged] = useState(false);
  const [countriesChanged, setCountriesChanged] = useState(false);
  const { pathname } = useLocation();
  const [errorMsg, setErrorMsgs] = useState(null);
  const [selectedIndustryHistory, setSelectedIndustryHistroy] = useState([]);
  const [activeScope, setActiveScope] = useState(1);
  const [scopeValue, setScopeValue] = useState("");
  const countryMenuItems = useSelector(getCountriesSelector);
  const [filteredRegionMenuItems, setFilteredRegionMenuItems] = useState();
  const [filteredIndustriesMenuItems, setFIlteredIndustriesMenuItems] =
    useState();
  const [selectedSubindustries, setSelectedSubindustries] = useState([]);
  const [subsectorsValue, setSubsectorsValue] = useState(subsectors);
  const [regionMenuItems, setRegionMenuItems] = useState(regionsOptions);
  const [industriesMenuItems, setIndustriesMenuItems] = useState(allSectors);
  const [regionsInputValue, setRegionsInputValue] = useState("");
  const [regionsMenuOpened, setRegionsMenuOpened] = useState(false);
  const [countriesInputValue, setCountriesInputValue] = useState("");
  const [filteredCountryMenuItems, setFilteredCountryMenuItems] = useState();
  const [countriesMenuOpened, setCountriesMenuOpened] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState(undefined);
  const [selectedIndustriesInfo, setSelectedIndustriesInfo] = useState([]);
  const [activeSubsectors, setActiveSubsectors] = useState([]);
  const [selectedRegions, setSelectedRegions] = useState([]);
  const transfromToLowerWithDashes = string => {
    if (typeof string === "string") {
      return string.toLowerCase().replaceAll(" ", "-");
    }
    return string;
  };
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedCountriesValue, setSelectedCountriesValue] = useState([]);

  const prevSelectedCountries = usePrevious(selectedCountries);
  const [selectedIndustries, setSelectedIndustries] = useState([]);
  const [selectedIndustriesValue, setSelectedIndustriesValue] = useState([]);

  //fill dropdown field values
  useEffect(() => {
    if (search) {
      let subindustries = [];
      setDescription(search.description);
      userQuestionTextarea.current.value = search.description;
      let location = search.location;
      if (search.location && typeof search.location === "string") {
        location = JSON.parse(search.location);
      }
      let industries = search.sectors;
      if (search.sectors && typeof search.sectors === "string") {
        industries = JSON.parse(search.sectors);
      }
      if (industries) {
        industries.forEach(industry => {
          if (industry.subsectors && industry.subsectors.length) {
            subindustries = [...subindustries, ...industry.subsectors];
          }
        });
        dispatch(
          fetchSubsectorsRequest(industries.map(industry => industry.slug)),
        );
      }
      setSelectedCountries(location?.countries);
      setSelectedRegions(location?.regions);
      setSelectedSubindustries(subindustries);
      setSelectedIndustries(industries);
    }
  }, [search]);

  useEffect(() => {
    setActiveSubsectors(
      subsectors.map(ss => {
        return {
          label: ss.name,
          value: ss.slug,
        };
      }),
    );
  }, [subsectors]);

  useEffect(() => {
    if (allSectors.length) {
      setIndustriesMenuItems(allSectors);
    }
  }, [industriesMenuItems, allSectors]);

  //Update 'Save to workspace' button
  useEffect(() => {
    if (addItemToProjectStatus === REQUEST_SUCCESS) {
      queryClient.invalidateQueries({ queryKey: ["searches"] });
    }
  }, [addItemToProjectStatus]);

  useEffect(() => {
    setSubsectorsValue(subsectors);
  }, [subsectors]);

  useEffect(() => {
    let newSubsectors = [];
    if (selectedIndustryHistory?.length < selectedIndustries?.length) {
      subsectors.map(ss => {
        newSubsectors.push({
          label: ss.name,
          value: ss.slug,
          industry: selectedIndustries[selectedIndustries.length - 1],
          industryLabel:
            selectedIndustriesInfo[selectedIndustriesInfo.length - 1]?.name,
        });
      });

      if (activeSubsectors.length === 0) {
        return setActiveSubsectors(newSubsectors);
      } else {
        return setActiveSubsectors([...newSubsectors, ...activeSubsectors]);
      }
    } else {
      const removedIndustry = selectedIndustryHistory?.filter(
        element => !selectedIndustries.includes(element),
      )[0];
      return setActiveSubsectors(
        activeSubsectors.filter(ele => ele.industry !== removedIndustry),
      );
    }
  }, [subsectors]);

  useEffect(() => {
    dispatch(
      getCountriesRequest({
        regions: selectedRegions || [],
      }),
    );
  }, [dispatch]);

  const handleFiltersClick = () => {
    setFiltersExpanded(!filtersExpanded);
  };

  const handleDescriptionChange = event => {
    const newTextValue = event.target.value;
    setIsSearchBtnActive(
      newTextValue !== "" &&
        newTextValue.toLocaleLowerCase().trim() !==
          description.toLocaleLowerCase().trim(),
    );
  };

  const handleSubmitSearch = e => {
    if (process.env.REACT_APP_ENV === "prod") {
      reCaptchaRef.current.execute();
    } else {
      handleCaptchaChange();
      dispatch(searchActions.setEditQueryClean());
    }
  };

  const handleCaptchaChange = () => {
    const data = {
      description: userQuestionTextarea.current.value,
      searchSectors: selectedIndustriesInfo,
      location: {
        regions: selectedRegions,
        countries: selectedCountriesValue,
      },
      scope: "Find companies that use specific technologies",
      searchType: activeScope,
      perPage: 100,
    };
    dispatch(postSetSearchCountRequest());
    dispatch(
      submitSearchRequest({
        description: data.description,
        searchSectors: data.searchSectors,
        location: {
          regions: data.location.regions,
          countries: data.location.countries,
        },
        scope: data.scope,
        searchType: data.searchType,
        perPage: 50,
      }),
    );
    setTimeout(() => {
      history.replace("/search-results-loading", {
        from: pathname,
        someOtherProp: "",
      });
    }, 200);
  };

  const handleMenuSearch = type => {
    setRegionsMenuOpened(false);
    setCountriesMenuOpened(false);
    typingTimeout !== undefined &&
      clearTimeout(typingTimeout) &&
      setTypingTimeout(undefined);
    setTypingTimeout(
      setTimeout(() => {
        filterMenu(type);
        setTypingTimeout(undefined);
      }, 750),
    );
  };

  const filterMenu = type => {
    if (type === "region") {
      regionsInputValue.length
        ? setFilteredRegionMenuItems(
            regionMenuItems.filter(item =>
              item.name
                .toLowerCase()
                .startsWith(regionsInputValue.toLowerCase()),
            ),
          )
        : setFilteredRegionMenuItems(null);
      setRegionsMenuOpened(true);
    }
    if (type === "country") {
      countriesInputValue.length
        ? setFilteredCountryMenuItems(
            countryMenuItems.filter(item =>
              item.name
                .toLowerCase()
                .startsWith(countriesInputValue.toLowerCase()),
            ),
          )
        : setFilteredCountryMenuItems(null);
      setCountriesMenuOpened(true);
    }
  };

  const handleRegionSelect = arrayOfRegion => {
    const parsedSelected = [];
    const selected = [];
    arrayOfRegion.filter(region => {
      selected.push(region.label);
      parsedSelected.push({
        label: region.label,
        value: transfromToLowerWithDashes(region.label),
      });
    });
    setSelectedRegions(selected);
    setRegionsChanged(true);
    dispatch(searchActions.updateRegions(selected));
    if (!selected.length) {
      setSelectedCountries([]);
      setFilteredCountryMenuItems(null);
      setCountriesInputValue("");
      setErrorMsgs(null);
    }
    setIsSearchBtnActive(true);
  };

  const handleCountrySelect = arrayOfCountries => {
    const parsedSelected = [];
    const selected = [];
    arrayOfCountries.filter(country => {
      selected.push(country.label);
      parsedSelected.push({
        label: country.label,
        value: transfromToLowerWithDashes(country.label),
      });
    });

    setSelectedCountries(selected);
    setSelectedCountriesValue(selected);
    setCountriesChanged(true);
    dispatch(searchActions.updateCountries(selected));
    setIsSearchBtnActive(true);
  };

  const handleIndustriesSelect = arrayOfIndustries => {
    let selected = [];
    let parseSelected = [];
    let objectedArray = [];
    let parseArray = [];
    arrayOfIndustries.filter(industry => {
      selected.push(industry.value);
      parseSelected.push(industry.value);
      objectedArray.push({ name: industry.label, slug: industry.value });
      parseArray.push({
        name: industry.label,
        slug: industry.value,
        subsectors: [],
      });
    });
    setSelectedIndustriesInfo(parseArray);
    setSelectedIndustries(objectedArray);
    setSelectedIndustriesValue(parseSelected);
    setSelectedIndustryHistroy(selectedIndustries);
    dispatch(fetchSubsectorsRequest(parseSelected[parseSelected.length - 1]));
    dispatch(searchActions.addSectors(parseSelected));
    if (!arrayOfIndustries.length) {
      setActiveSubsectors([]);
      setSelectedSubindustries([]);
      setSelectedIndustryHistroy();
      setSubsectorsValue([]);
    }
    setIsSearchBtnActive(true);
  };

  useEffect(() => {
    if (
      filteredRegionMenuItems &&
      filteredRegionMenuItems.length === 0 &&
      regionsInputValue.length !== 0
    ) {
      setErrorMsgs({ region: "No regions found by that name" });
    } else if (
      filteredCountryMenuItems &&
      filteredCountryMenuItems.length === 0 &&
      countriesInputValue.length !== 0
    ) {
      setErrorMsgs({ country: "No countries found by that name" });
    } else {
      setErrorMsgs(null);
    }
  }, [filteredRegionMenuItems, filteredCountryMenuItems]);

  useEffect(() => {
    if (selectedRegions && selectedRegions.length) {
      dispatch(
        getCountriesRequest({
          regions: selectedRegions,
        }),
      );
      // clear selected countries on region deselect
      regionsChanged &&
        setSelectedCountries(
          selectedCountries.filter(item =>
            countryMenuItems.map(x => x.name).includes(item),
          ),
        );
    }
  }, [selectedRegions]);

  useEffect(() => {
    // clear selected countries on region deselect
    regionsChanged &&
      setSelectedCountries(
        selectedCountries.filter(item =>
          countryMenuItems.map(x => x.name).includes(item),
        ),
      );
  }, [countryMenuItems]);

  useEffect(() => {
    countriesChanged &&
      JSON.stringify(prevSelectedCountries) !==
        JSON.stringify(selectedCountries) &&
      dispatch(searchActions.updateCountries(selectedCountries));
  }, [selectedCountries, prevSelectedCountries]);

  useEffect(() => {
    dispatch(fetchSectorsRequest());
  }, [dispatch]);

  useEffect(() => {
    if (selectedCountries && selectedCountries) {
      dispatch(searchActions.setEditQueryClean());
    } else {
      dispatch(searchActions.setEditQueryDirty());
    }
  }, [selectedRegions, selectedCountries]);

  useEffect(() => {
    setSubsectorsValue(subsectors);
  }, [subsectors]);

  const handleSubindustrySelect = arrayOfSubindustries => {
    const newSearchArray = [];
    arrayOfSubindustries.map(ele => {
      const findParent = selectedIndustriesInfo.find(
        ind => ind.name === ele.industryLabel,
      );
      if (findParent) {
        if (findParent.subsectors.length >= arrayOfSubindustries.length) {
          findParent.subsectors = [];
        }
        const isSubsectorInside = findParent.subsectors.find(
          sub => sub.name === ele.label,
        );
        if (!isSubsectorInside) {
          const newObj = {
            name: findParent.name,
            slug: findParent.slug,
            subsectors: findParent.subsectors.push({
              name: ele.label,
              slug: ele.value,
            }),
          };
          newSearchArray.push(newObj);
        }
      }
    });
    setSelectedSubindustries(arrayOfSubindustries);
    setIsSearchBtnActive(true);
  };

  return (
    <div
      className={classnames([
        classes.editSearch,
        user && !user.csp && classes.withoutPuma,
      ])}>
      <div className={classes.imgWrapper}>
        <MangaPuma className={classes.pumaLogo} />
      </div>
      <div className={classes.header}>
        <Typography variant="subtitleResult" className={classes.headerTitle}>
          PUMA <b>Scope</b>
        </Typography>
      </div>
      <div className={classes.description}>
        <TextareaAutosize
          className={classes.textareaField}
          minRows={3}
          onChange={handleDescriptionChange}
          placeholder="Search query..."
          ref={userQuestionTextarea}
        />
      </div>
      <div className={classes.options}>
        <div className={classes.optionsLeft}>
          <div className={classes.filter} onClick={handleFiltersClick}>
            <div className={classes.plusIconWrapper}>
              <Plus
                className={classnames([
                  classes.plusIcon,
                  { [classes.plusIconActive]: filtersExpanded },
                ])}
              />
            </div>
            <Typography
              variant="subtitleResult"
              className={classes.filterTitle}>
              Filter
            </Typography>
          </div>
          <div className={classes.selectedFilters}>
            {/*  Regions: a, b; Counties: a, b */}
          </div>
        </div>
        <div className={classes.optionsRight}>
          <div className={classes.saveWorkspace}>
            <div className={classes.saveSearchBtn} variant="quaternary">
              <AddToProjectMenu
                buttonType="search"
                type="search"
                sid={search.id}
                currentSearch={search}
                company={search}
                isSaved={search.saved}
                buttonLabel="Save to Workspace"
              />
            </div>
          </div>
          <div className={classes.updateSearchQuery}>
            <Button
              onClick={handleSubmitSearch}
              variant="quaternary"
              className={classnames([
                classes.updateSearchBtn,
                {
                  [classes.updateSearchBtnActive]: isSearchBtnActive,
                },
              ])}>
              Update Scope
            </Button>
          </div>
        </div>
      </div>
      <div
        className={classnames([
          classes.filterOptions,
          { [classes.filterOptionsOverflow]: filtersExpanded },
        ])}>
        <div
          className={classnames([
            classes.expandSection,
            { [classes.expanded]: filtersExpanded },
          ])}>
          <div className={classes.filterOptionsRow}>
            <div
              onKeyUp={() => handleMenuSearch("region")}
              style={{
                width: "100%",
                maxWidth: "330px",
              }}>
              <MultiSelectSort
                options={filteredRegionMenuItems || regionMenuItems}
                placeholder="Region"
                onChange={e => {
                  handleRegionSelect(e);
                }}
                defaultValue={selectedRegions}
              />
            </div>
            <div
              onKeyUp={() => handleMenuSearch("country")}
              style={{
                width: "100%",
                maxWidth: "330px",
              }}>
              <MultiSelectSort
                options={filteredCountryMenuItems || countryMenuItems}
                placeholder={
                  selectedRegions &&
                  selectedRegions[0] &&
                  selectedRegions[0].length === 0
                    ? "Select region first"
                    : "Country"
                }
                onChange={e => {
                  handleCountrySelect(e);
                }}
                isDisabled={
                  selectedRegions &&
                  selectedRegions[0] &&
                  selectedRegions[0].length === 0
                }
                defaultValue={selectedCountries}
              />
            </div>
          </div>
          <div className={classes.filterOptionsRow}>
            <div
              style={{
                width: "100%",
                maxWidth: "330px",
              }}>
              <MultiSelectSort
                options={filteredIndustriesMenuItems || industriesMenuItems}
                placeholder="Industries"
                onChange={e => {
                  handleIndustriesSelect(e);
                }}
                defaultValue={selectedIndustries}
              />
            </div>
            <div
              style={{
                width: "100%",
                maxWidth: "330px",
              }}>
              <MultiSelectSort
                options={
                  activeSubsectors
                  // filteredIndustriesSubsectorMenuItems ||
                  // industriesSubsectorMenuItems
                }
                placeholder="Sub-industries"
                onChange={e => {
                  handleSubindustrySelect(e);
                }}
                isDisabled={
                  selectedIndustries &&
                  selectedIndustries[0] &&
                  selectedIndustries[0].length === 0
                }
                defaultValue={selectedSubindustries}
              />
            </div>
          </div>
        </div>
      </div>
      {process.env.REACT_APP_ENV === "prod" && (
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_CAPTCHA_APP_KEY}
          size="invisible"
          badge="bottomleft"
          ref={reCaptchaRef}
          onChange={handleCaptchaChange}
        />
      )}
    </div>
  );
};

export default EditSearch;
