import { createAction } from "@reduxjs/toolkit";
import { REDUCER_PREFIX } from "./slice";

export const addCommentRequest = createAction(
  `${REDUCER_PREFIX}/addCommentRequest`,
);

export const fetchCommentsRequest = createAction(
  `${REDUCER_PREFIX}/fetchCommentsRequest`,
);

export const updateCommentRequest = createAction(
  `${REDUCER_PREFIX}/updateCommentRequest`,
);

export const deleteCommentRequest = createAction(
  `${REDUCER_PREFIX}/deleteCommentRequest`,
);

export const getSharedModelRequest = createAction(
  `${REDUCER_PREFIX}/getSharedModelRequest`,
);

export const voteCommentRequest = createAction(
  `${REDUCER_PREFIX}/voteCommentRequest`,
);

export const addCompanyTagRequest = createAction(
  `${REDUCER_PREFIX}/addCompanyTagRequest`,
);

export const getCompanyTagRequest = createAction(
  `${REDUCER_PREFIX}/getCompanyTagRequest`,
);

export const deleteCompanyTagRequest = createAction(
  `${REDUCER_PREFIX}/deleteCompanyTagRequest`,
);

export const selectCompanyTagRequest = createAction(
  `${REDUCER_PREFIX}/selectCompanyTagRequest`,
);

export const getSimilarCompaniesRequest = createAction(
  `${REDUCER_PREFIX}/getSimilarCompaniesRequest`,
);

export const getSuggestedCompaniesRequest = createAction(
  `${REDUCER_PREFIX}/getSuggestedCompaniesRequest`,
);

export const fetchDeckClientPreviewRequest = createAction(
  `${REDUCER_PREFIX}/fetchDeckClientPreviewRequest`,
);
