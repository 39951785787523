import { createSlice } from "@reduxjs/toolkit";
import {
  REQUEST_FAILURE,
  REQUEST_PENDING,
  REQUEST_SUCCESS,
  REQUEST_UNDEFINED,
} from "../../constants/statuses";

export const INITIAL_STATE = {
  pool: [],
  getCountriesStatus: REQUEST_UNDEFINED,
};

export const REDUCER_PREFIX = "countries";

const { reducer, actions } = createSlice({
  name: REDUCER_PREFIX,
  initialState: INITIAL_STATE,
  reducers: {
    setCountries: (state, { payload }) => {
      state.pool = payload.data;
    },
    getCountriesPending: state => {
      state.getCountriesStatus = REQUEST_PENDING;
    },
    getCountriesSuccess: state => {
      state.getCountriesStatus = REQUEST_SUCCESS;
    },
    getCountriesFailure: state => {
      state.getCountriesStatus = REQUEST_FAILURE;
    },
  },
});
export { reducer, actions };
