import { createAction } from "@reduxjs/toolkit";
import { REDUCER_PREFIX } from "./slice";

export const fetchSectorsRequest = createAction(
  `${REDUCER_PREFIX}/fetchSectorsRequest`,
);

export const fetchSubsectorsRequest = createAction(
  `${REDUCER_PREFIX}/fetchSubsectorsRequest`,
);
