import React, { useEffect, useState } from "react";
// UI components
import Typography from "../../components/Typography/Typography";
import Box from "@material-ui/core/Box";
import SignUpForm from "./SignUpForm";
import { UserProvider } from "../Layout/UserProvider";
// Hooks
import { useTranslation } from "react-i18next";
import { useStyles } from "./style.js";
import { useRouterHook } from "../../common/useRouterHook";
import { useAuthHook } from "../../common/useAuthHook";
import { useLocation } from "react-router-dom";
// Assets
import SignUpIllustration from "../../assets/icons/SignInManIllustration.svg";
import LogoNavbar from "../../assets/icons/LogoNavbar.svg";
import GetCompanyReport from "../../assets/icons/GetCompanyReport.svg";
import BlackTick from "../../assets/images/BlackTick.png";
import UseProjects from "../../assets/images/UseProjects.png";
import InviteTeamMembers from "../../assets/images/InviteTeamMembers.png";
import SeeHowCompanies from "../../assets/images/SeeHowCompanies.png";
// Redux
import {
  fetchInvitationRequest,
  getMeRequest,
} from "../../store/auth/requests";
import {
  getCheckOutUrlSelector,
  getFetchInvitationStatusSelector,
  getInvitationSelector,
  getLogoutStatusSelector,
  getRegisterStatusSelector,
  getSubscribeStatusSelector,
} from "../../store/auth/selectors";
import { useDispatch, useSelector } from "react-redux";
// Constants
import {
  REQUEST_FAILURE,
  REQUEST_SUCCESS,
  REQUEST_UNDEFINED,
} from "../../constants/statuses";

const SignUp = () => {
  const { state: locationState, search } = useLocation();
  const { t } = useTranslation();
  const classes = useStyles();
  const { openPage } = useRouterHook();
  const dispatch = useDispatch();
  const { isAuthenticated, getMeStatus, userLoading } = useAuthHook();
  const checkOutUrl = useSelector(getCheckOutUrlSelector);
  const subscribeStatus = useSelector(getSubscribeStatusSelector);
  const logoutStatus = useSelector(getLogoutStatusSelector);
  const registerStatus = useSelector(getRegisterStatusSelector);
  const getFetchInvitationStatus = useSelector(
    getFetchInvitationStatusSelector,
  );
  const getInvitation = useSelector(getInvitationSelector);
  const [emailValue, setEmailValue] = useState("");
  const [companyDisabled, setCompanyDisabled] = useState(false);
  const [emailDisabled, setEmailDisabled] = useState(false);
  const [companyNameValue, setCompanyNameValue] = useState("");
  const [ctaButtonCopy, setCtaButtonCopy] = useState("signUp.startFreeTrial");
  const [signInLink, setSignInLink] = useState("/sign-in");
  const [token, setToken] = useState("");
  const subscriptionAttempt = window.location.search.includes(
    "subscriptionAttempt",
  );

  const exploreValuer = () => {
    window.location.href = "https://www.valuer.ai/";
  };

  useEffect(() => {
    const query = new URLSearchParams(search);
    const storageData = JSON.parse(localStorage.getItem("subscriptionAttempt"));

    const subscribe = !!storageData
      ? storageData.subscribe
      : query.get("subscribe");
    const yearly = !!storageData ? storageData.yearly : query.get("yearly");
    const monthly = !!storageData ? storageData.monthly : query.get("monthly");
    const currency = !!storageData
      ? storageData.currency || ""
      : query.get("currency");
    const signUpForFreeTrial = !!storageData
      ? false
      : query.get("signUpForFreeTrial");
    if (getMeStatus === REQUEST_UNDEFINED) {
      dispatch(getMeRequest());
    }
    if (
      (getMeStatus === REQUEST_SUCCESS || getMeStatus === REQUEST_FAILURE) &&
      search.lastIndexOf("?token=") !== 0 &&
      search.lastIndexOf("?freeTrial=true") !== 0 &&
      !isAuthenticated
    ) {
      openPage("/sign-in");
    }

    if (!userLoading) {
      if (!!subscribe) {
        if (
          getMeStatus === REQUEST_SUCCESS ||
          getMeStatus === REQUEST_FAILURE
        ) {
          if (
            !!isAuthenticated &&
            !!storageData &&
            registerStatus === REQUEST_SUCCESS
          ) {
            const subscribeUrl = `?subscribe=${subscribe}&${
              !!yearly ? `yearly=${yearly}` : `monthly=${monthly}`
            }&currency=${currency}`;
            subscribe !== "free"
              ? openPage(`/search-type${subscribeUrl}`)
              : openPage(`/search-type`);
          } else {
            subscribe !== "free" && setCtaButtonCopy("signUp.goToPayment");
            setSignInLink(`/sign-in${search}`);
          }
        }
      } else {
        let startFreeTrialUrlParam = "";
        if (!!signUpForFreeTrial) {
          startFreeTrialUrlParam = "?startFreeTrial=true";
        }
        !!isAuthenticated &&
          (getMeStatus === REQUEST_SUCCESS ||
            getMeStatus === REQUEST_FAILURE) &&
          logoutStatus !== REQUEST_SUCCESS &&
          !!isAuthenticated &&
          openPage("dashboard" + startFreeTrialUrlParam);
      }
    }

    if (subscriptionAttempt) {
      setCtaButtonCopy("signUp.createAccount");
    }
  }, [
    logoutStatus,
    isAuthenticated,
    getMeStatus,
    openPage,
    search,
    userLoading,
    registerStatus,
  ]);

  useEffect(() => {
    const query = new URLSearchParams(search);
    const token = query.get("token");
    if (token) {
      setCtaButtonCopy("signUp.joinTheTeam");
      setToken(token);
      dispatch(fetchInvitationRequest({ token }));
    }
  }, [search, dispatch]);

  useEffect(() => {
    if (getFetchInvitationStatus === REQUEST_SUCCESS && getInvitation) {
      setEmailValue(getInvitation.email);
      setCompanyNameValue(getInvitation.company);
      setCompanyDisabled(true);
      setEmailDisabled(true);
    }
  }, [getFetchInvitationStatus, getInvitation]);

  useEffect(() => {
    if (subscribeStatus === REQUEST_SUCCESS) {
      if (checkOutUrl.length > 0) {
        window.location.href = checkOutUrl;
      }
    }
  }, [checkOutUrl, subscribeStatus]);

  const fromProjectsOrSH =
    locationState &&
    (locationState.from === "/projects" ||
      locationState.from === "/saved-searches");

  return (
    <UserProvider>
      {subscriptionAttempt || token ? (
        <>
          <Box display="flex" flexDirection="row" className={classes.root}>
            <Box
              display="flex"
              flex={1}
              flexDirection="column"
              className={classes.illustrationWrapper}>
              <div className={classes.logoWrapper}>
                <img
                  src={LogoNavbar}
                  className={classes.logo}
                  alt={t("companyName")}
                />
              </div>
              <img
                src={SignUpIllustration}
                className={classes.signInIllustration}
                alt={t("signUp.illustrationTitle")}
              />
            </Box>
            <div className={classes.formWrapperRoot}>
              <Box
                display="flex"
                flex={1}
                flexDirection="column"
                className={classes.formWrapper}>
                <Typography
                  className={classes.signUpTitle}
                  color="black"
                  variant="h6">
                  <strong>
                    {t(fromProjectsOrSH ? "signUp.title2" : "signUp.title")}
                  </strong>
                </Typography>
                <SignUpForm
                  classNames={classes}
                  locationState={locationState}
                  companyNameValueInvite={companyNameValue}
                  companyDisabledInvite={companyDisabled}
                  emailValueInvite={emailValue}
                  emailDisabledInvite={emailDisabled}
                  urlToken={token}
                  ctaButtonCopy={ctaButtonCopy}
                  showButtonLabel={false}
                  emailLabel="signUp.email2"
                  emailPlaceholder="signUp.emailPlaceholder2"
                  signInLink={signInLink}
                  openPage={openPage}
                />
              </Box>
            </div>
          </Box>
        </>
      ) : (
        <>
          <Box
            display="flex"
            flexDirection="row"
            className={classes.signUpRoot}>
            <Typography
              variant="body2"
              color="textSecondary"
              onClick={exploreValuer}
              className={classes.exploreValuer}>
              {t("signUp.exploreValuer")}
            </Typography>
            <Box
              display="flex"
              flex={1}
              flexDirection="column"
              className={classes.registerFormWrapperRootLeft}>
              <div className={classes.registerFormWrapperLeft}>
                <Typography
                  className={classes.signUpMainTitle}
                  color="black"
                  variant="h4"
                  component="div"
                  gutterBottom>
                  <strong>
                    {subscriptionAttempt
                      ? t("signUp.youGetTitle2")
                      : t("signUp.youGetTitle")}
                  </strong>
                </Typography>
                <Typography
                  className={classes.signUpTitleDesc}
                  color="black"
                  variant="body"
                  component="div"
                  gutterBottom>
                  {t("signUp.youGetDesc1")}
                </Typography>
                <Typography
                  className={classes.signUpTitleDesc}
                  color="black"
                  variant="body"
                  component="div">
                  {t("signUp.youGetDesc2")}
                </Typography>
                <div className={classes.benefit}>
                  <img src={GetCompanyReport} className={classes.benefitIcon} />
                  <img
                    src={BlackTick}
                    className={classes.benefitIconMobile}
                  />{" "}
                  <Typography
                    className={classes.signUpTitle}
                    color="black"
                    variant="body"
                    component="div">
                    {t("signUp.getCompleteReport")}
                  </Typography>
                </div>
                <div className={classes.benefit}>
                  <img src={UseProjects} className={classes.benefitIcon} />
                  <img
                    src={BlackTick}
                    className={classes.benefitIconMobile}
                  />{" "}
                  <Typography
                    className={classes.signUpTitle}
                    color="black"
                    variant="body"
                    component="div">
                    {t("signUp.useProjects")}
                  </Typography>
                </div>
                <div className={classes.benefit}>
                  <img
                    src={InviteTeamMembers}
                    className={classes.benefitIcon}
                  />
                  <img src={BlackTick} className={classes.benefitIconMobile} />{" "}
                  <Typography
                    className={classes.signUpTitle}
                    color="black"
                    variant="body"
                    component="div">
                    {t("signUp.inviteTeamMembers")}
                  </Typography>
                </div>
                <div className={classes.benefit}>
                  <img src={SeeHowCompanies} className={classes.benefitIcon} />
                  <img
                    src={BlackTick}
                    className={classes.benefitIconMobile}
                  />{" "}
                  <Typography
                    className={classes.signUpTitle}
                    color="black"
                    variant="body"
                    component="div">
                    {t("signUp.seeHowCompaniesInteract")}
                  </Typography>
                </div>
              </div>
            </Box>
            <div className={classes.registerFormWrapperRoot}>
              <Box
                display="flex"
                flex={1}
                flexDirection="column"
                className={classes.registerFormWrapper}>
                {/* <Typography className={classes.signUpSubtitle} variant="subtitle2">{t(fromProjectsOrSH ? 'signUp.subtitle2' : 'signUp.subtitle')}</Typography> */}
                <Typography
                  className={classes.signUpTitle}
                  color="black"
                  variant="subtitle1">
                  <strong>
                    {t(fromProjectsOrSH ? "signUp.title2" : "signUp.title")}
                  </strong>
                </Typography>
                <SignUpForm
                  classNames={classes}
                  locationState={locationState}
                  companyNameValueInvite={companyNameValue}
                  companyDisabledInvite={companyDisabled}
                  emailValueInvite={emailValue}
                  emailDisabledInvite={emailDisabled}
                  urlToken={token}
                  ctaButtonCopy={ctaButtonCopy}
                  signInLink={signInLink}
                  openPage={openPage}
                />
              </Box>
            </div>
          </Box>
        </>
      )}
    </UserProvider>
  );
};

export default SignUp;
