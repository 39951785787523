import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { actions as searchActions } from "../../store/search/slice";
import { useLocation } from "react-router-dom";
// UI components
import Typography from "../../components/Typography/Typography";
import Button from "../../components/Button/Button";
import Box from "@material-ui/core/Box";
import NavBar from "./components/NavBar";
// import Link from '../../components/Link/Link';
// Hooks
import { useTranslation } from "react-i18next";
import { useStyles } from "./style.js";
import { useRouterHook } from "../../common/useRouterHook";
import { useAuthHook } from "../../common/useAuthHook";
// Assets
import GetStartedIllustration from "../../assets/icons/GetStartedIllustration.png";
// Redux
import { mixpanelTrackRequest } from "../../store/mixpanel/requests";

const GetStarted = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { isAuthenticated } = useAuthHook();
  const { openPage } = useRouterHook();
  // const [animateOut, setAnimateOut] = useState(false);
  // const [animateInBack, setAnimateInBack] = useState(false);
  const { state: locationState, search } = useLocation();

  const getStartedBtnClick = () => {
    dispatch(mixpanelTrackRequest({ eventName: "Get started" }));
    dispatch(searchActions.clearSearch());
    dispatch(searchActions.clearLookalikeSearch());
    // setAnimateInBack(false);
    // setAnimateOut(true);
    setTimeout(() => {
      openPage("search-type");
    }, 200);
  };

  // useEffect(() => {
  //     setAnimateInBack(locationState && locationState.from === "/search-type")
  // }, [locationState]);

  useEffect(() => {
    const query = new URLSearchParams(search);
    const subscribe = query.get("subscribe");
    const yearly = query.get("yearly");
    const monthly = query.get("monthly");
    const currency = query.get("currency") || "";
    if (!!subscribe) {
      const subscribeUrl = `?subscribe=${subscribe}&${
        !!yearly ? `yearly=${yearly}` : `monthly=${monthly}`
      }&currency=${currency}`;
      isAuthenticated
        ? openPage(`/dashboard${subscribeUrl}`)
        : openPage(`/sign-up${subscribeUrl}`);
    }
  }, [search]);

  return (
    <div>
      <div className={classes.root}>
        <NavBar />
        <Box
          textAlign="center"
          flex="1"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center">
          <img
            src={GetStartedIllustration}
            className={classes.illustration}
            alt={t("getStarted.title")}
          />
          <Typography
            variant="h4"
            color="black"
            className={classes.getStartedTitle}>
            <strong>{t("getStarted.title")}</strong>
          </Typography>
          <Typography
            variant="body1"
            color="text"
            className={classes.description}>
            {t("getStarted.description")}
          </Typography>
          {/* <Typography variant="body1" color="text">
            {t('getStarted.descriptionHint')}
          </Typography> */}
          <Button
            variant="primary"
            className={classes.getStartedCta}
            onClick={getStartedBtnClick}
            size="large">
            {t("getStarted.getStartedCta")}
          </Button>
          {/* <Typography variant="body1" color="text" component="div">
                        {t('getStarted.skipCta')}
                        <Typography variant="body1" onClick={() => openPage('/sign-up')} color="text" className={classes.getStartedLink} component="span" inline>
                            {t('getStarted.signUpCta')}
                        </Typography>
                    </Typography> */}
          <Typography
            variant="body1"
            color="text"
            className={classes.already}
            component="div">
            {t("getStarted.haveProfile")}
            <Typography
              variant="body1"
              onClick={() => openPage("/sign-in")}
              color="text"
              className={classes.getStartedLink}
              component="span"
              inline>
              {t("getStarted.signIn")}
            </Typography>
          </Typography>
        </Box>
      </div>
      {/*<div className={classes.arrowsUpDown}>*/}
      {/*    <div className={classnames(classes.goUpBtn, classes.goUpBtnDisabled)}>*/}
      {/*        <img src={PageUpDisabledIcon} alt={t("companyName")} />*/}
      {/*    </div>*/}
      {/*    <div className={classes.goUpBtn} onClick={getStartedBtnClick}>*/}
      {/*        <img src={PageDownIcon} alt={t("companyName")} />*/}
      {/*    </div>*/}
      {/*</div>*/}
    </div>
  );
};

export default GetStarted;
