import React, { useState } from "react";
// Hooks
import { useTranslation } from "react-i18next";
import { useStyles } from "./style.js";
import { useRouterHook } from "../../../../common/useRouterHook";
import { useAuthHook } from "../../../../common/useAuthHook.js";
//Components
import Typography from "../../../../components/Typography/Typography";
//Assets
import ArrowLeft from "../../../../assets/icons/ArrowLeft.png";

const Header = ({ isEnriched, share, download }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { isAuthenticated } = useAuthHook();
  const { goBack } = useRouterHook();
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [menuItems, setMenuItems] = useState([]);

  const handleMenuClick = () => {
    setIsMenuOpened(prevIsMenuOpened => !prevIsMenuOpened);
    isEnriched
      ? setMenuItems([
          {
            label: (
              <Typography variant="bodyXs" className={classes.mono}>
                {t("technologies.headerMenu.share")}
              </Typography>
            ),
            onClick: share,
          },
          {
            label: (
              <Typography variant="bodyXs" className={classes.mono}>
                {t("common.downloadAsCSV")}
              </Typography>
            ),
            onClick: () => download("csv"),
          },
          {
            label: (
              <Typography variant="bodyXs" className={classes.mono}>
                {t("common.downloadAsPDF")}
              </Typography>
            ),
            onClick: () => download("pdf"),
          },
        ])
      : setMenuItems([
          {
            label: (
              <Typography variant="bodyXs" className={classes.mono}>
                {t("technologies.headerMenu.share")}
              </Typography>
            ),
            onClick: share,
          },
        ]);
  };

  return (
    <div className={classes.root}>
      <div className={classes.nav}>
        <div className={classes.goBack} onClick={goBack}>
          <img src={ArrowLeft} alt={t("technologies.goBack")} />
          <Typography color="secondaryDark" variant="bodyButton">
            {t("technologies.goBack")}
          </Typography>
        </div>
        {/*{isAuthenticated && <Menu*/}
        {/*    btnClassName={classes.moreButton}*/}
        {/*    onChange={handleMenuClick}*/}
        {/*    menuItems={menuItems}*/}
        {/*>*/}
        {/*    <MoreIconNoFill className={classnames([classes.moreIcon, { [classes.moreIconOpened]: isMenuOpened }])} />*/}
        {/*</Menu>}*/}
      </div>
    </div>
  );
};
export default Header;
