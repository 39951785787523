import styled from "styled-components";

export const ToggleSwitchStyle = styled.label`
  cursor: pointer;
  text-indent: -9999px;
  width: 40px;
  height: 20px;
  background: ${props => (props.checked ? "green" : "grey")};
  display: block;
  border-radius: 100px;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    left: ${props => (props.checked ? "calc(55% - 0px)" : "2px")};
    top: 2px;
    width: 16px;
    height: 16px;
    background: #fff;
    border-radius: 90px;
    transition: 0.3s;
  }
`;
