import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App.js";
import "./i18n";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { applyMiddleware, compose, createStore } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import rootReducer from "./store/rootReducer";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import createSagaMiddleware from "redux-saga";
import _saga from "./store/rootSaga";
import { IntercomProvider } from "react-use-intercom";
import { BrowserRouter } from "react-router-dom";

const composeEnhancers =
  (typeof window !== "undefined" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["search", "results"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const sagaMiddleware = createSagaMiddleware();
let store = createStore(
  persistedReducer,
  compose(applyMiddleware(sagaMiddleware), composeEnhancers()),
);
let persistor = persistStore(store);
sagaMiddleware.run(_saga);

const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_KEY;
let autoBootIntercom = false;
if (
  process.env.REACT_APP_ENV === "prod" ||
  process.env.REACT_APP_ENV === "staging"
) {
  autoBootIntercom = true;
}

ReactDOM.render(
  <IntercomProvider appId={INTERCOM_APP_ID} autoBoot={autoBootIntercom}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </IntercomProvider>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
