import React, { useEffect, useState } from "react";
// UI components
import Card from "./Card/Card";
import Typography from "../../components/Typography/Typography";
import Button from "../../components/Button/Button";
import Header from "./Header/Header";
import Layout from "../Layout/Layout";
import { ReactComponent as UpArrowResults } from "../../assets/icons/UpArrowResults.svg";
import ContentLoader from "../../components/ContentLoader/ContentLoader";
import Pagination from "../../components/Pagination/Pagination";
// Hooks
import { useAppEventTracking } from "../../common/useAppEventTracking";
import { useStyles } from "./style.js";
import { useSavedSearchesHook } from "./hooks/useSavedSearchesHook";
import { useScrollTo } from "../../common/useScrollTo";
import { useAuthHook } from "../../common/useAuthHook";
import { useHistory, useLocation } from "react-router-dom";
// Redux
import { useSelector } from "react-redux";
import { fetchSearchesRequest } from "../../store/results/requests";
import { actions as resultsActions } from "../../store/results/slice";
import {
  getSearchesEditSearchStatusSelector,
  getSearchesStatusSelector,
  getTotalSearchesCountSelector,
  getTotalSearchesPagesSelector,
} from "../../store/results/selectors";
import { mixpanelTrackRequest } from "../../store/mixpanel/requests";
import { getLogoutStatusSelector } from "../../store/auth/selectors";
//Assets
import NoSearchesIcon from "../../assets/icons/NoSearchesIcon.png";
import SearchHistoryFreeUserIllustration from "../../assets/icons/SearchHistoryFreeUserIllustration.png";
import { getPricingPage } from "../../constants/endpoints";
//common
import { Shared } from "../../utils/shared";
import {
  REQUEST_FAILURE,
  REQUEST_PENDING,
  REQUEST_SUCCESS,
  REQUEST_UNDEFINED,
} from "../../constants/statuses";

const SavedSearches = () => {
  const { scrollToTop, upArrowVisible } = useScrollTo();
  const classes = useStyles();
  const { isAuthenticated, getMeStatus } = useAuthHook();
  const { pathname } = useLocation();
  const history = useHistory();
  const { intercomTrackEvent, mixpanelTrackEvent } = useAppEventTracking();
  const getSearchesStatus = useSelector(getSearchesStatusSelector);
  const getTotalSearchesCount = useSelector(getTotalSearchesCountSelector);
  const getTotalSearchesPage = useSelector(getTotalSearchesPagesSelector);
  const [totalCount, setTotalCount] = useState(getTotalSearchesCount);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(getTotalSearchesPage);
  const [sortValue, setSortValue] = useState("created-at");
  const [sortDirection, setSortDirection] = useState("desc");
  const logoutStatus = useSelector(getLogoutStatusSelector);
  const searchInputStatusSelector = useSelector(
    getSearchesEditSearchStatusSelector,
  );
  const [showLoaderForFree, setShowLoaderForFree] = useState(true);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    !isAuthenticated &&
      logoutStatus !== REQUEST_SUCCESS &&
      logoutStatus !== REQUEST_PENDING &&
      (getMeStatus === REQUEST_SUCCESS || getMeStatus === REQUEST_FAILURE) &&
      history.replace("/sign-in", { from: pathname, someOtherProp: "" });
  }, [isAuthenticated, history]);

  const {
    t,
    dispatch,
    user,
    allSearches,
    searchesStatusSelector,
    setNoSearches,
    noSearches,
    setShowLoader,
    searches,
    setAllSearches,
    handleOnSearch,
    goToSearch,
    isSearching,
    userSwitchedFromTrialToFree,
    moreData,
  } = useSavedSearchesHook({
    page,
    setPage,
    totalPages,
    sortValue,
    sortDirection,
    totalCount,
    searchValue,
  });

  useEffect(() => {
    const data = {
      orderBy: sortValue,
      orderDirection: sortDirection,
      page: page,
      isSortSearch: true,
      savedSearches: true,
    };
    if (userNotFree()) {
      dispatch(fetchSearchesRequest({ data }));
    }
    dispatch(resultsActions.clearResults());
    dispatch(mixpanelTrackRequest({ eventName: "Search history" }));
  }, [dispatch]);

  useEffect(() => {
    intercomTrackEvent("Search history page visits");
  }, []);
  useEffect(() => {
    setNoSearches(searches && searches.length === 0);
  }, [searches]);
  useEffect(() => {
    setAllSearches(searches);
    setTotalCount(getTotalSearchesCount);
    setTotalPages(getTotalSearchesPage);
  }, [searches, setAllSearches, getTotalSearchesCount, getTotalSearchesPage]);
  useEffect(() => {
    setShowLoader(searchesStatusSelector === REQUEST_PENDING);
  }, [searchesStatusSelector, setShowLoader]);
  const handleSortChange = (sortValue, sortDirection) => {
    setSortValue(sortValue);
    setSortDirection(sortDirection);
    setPage(1);
  };

  const { checkUserSubscription, getSubscriptionStatus } = Shared({
    getUser: user,
  });

  // const [hideHeader, setHideHeader] = useState(false);
  // useEffect(() => {
  // let isUserFree = checkUserSubscription('free');
  // let notFreeUserNoSearchesNotSearched = !isUserFree && noSearches && !isSearching;
  // setHideHeader(isUserFree || notFreeUserNoSearchesNotSearched)
  // }, [searches, noSearches, isSearching, user]);
  const [isLess1024, setIsLess1024] = useState(false);
  useEffect(() => {
    setIsLess1024(window.innerWidth < 1024);
  }, []);
  const userNotFree = () => {
    if (userSwitchedFromTrialToFree()) {
      return true;
    } else {
      return (
        !checkUserSubscription("free") && getSubscriptionStatus() !== "expired"
      );
    }
  };
  const showNoSearchesPlaceholder = () => {
    return (
      noSearches &&
      userNotFree() &&
      (getSearchesStatus === REQUEST_SUCCESS ||
        getSearchesStatus === REQUEST_PENDING) &&
      !isSearching &&
      (searchInputStatusSelector === REQUEST_SUCCESS ||
        searchInputStatusSelector === REQUEST_UNDEFINED)
    );
  };

  useEffect(() => {
    if (!userNotFree()) {
      setShowLoaderForFree(true);
      setTimeout(() => {
        setShowLoaderForFree(false);
      }, 1200);
    } else {
      setShowLoaderForFree(false);
    }
  }, [user]);

  // Cleanup function
  useEffect(() => {
    return () => {
      dispatch(resultsActions.clearSearchesResults());
    };
  }, []);

  useEffect(() => {
    mixpanelTrackEvent("Saved Searches visited");
  }, []);

  return (
    <Layout match="saved-searches" color="white">
      <div className={classes.root}>
        {!showNoSearchesPlaceholder() && userNotFree() && (
          <Header
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            userSwitchedFromTrialToFree={userSwitchedFromTrialToFree}
            user={user}
            onSortChange={handleSortChange}
            searches={allSearches}
            onSearch={handleOnSearch}
            totalCount={totalCount}
            isLess1024={isLess1024}
            totalPages={totalPages}
            page={page}
          />
        )}
        <>
          {searchInputStatusSelector === REQUEST_PENDING ? (
            <div className={classes.resultsLoader}>
              <ContentLoader />
            </div>
          ) : userNotFree() ? (
            <div>
              {/* no searches results when searched through search input */}
              {noSearches && isSearching && (
                <div className={classes.noSearches}>
                  <Typography
                    className={classes.shTitle}
                    variant="h6"
                    align="center"
                    color="black">
                    <strong>{t("savedSearches.noResultsFound")}</strong>
                  </Typography>
                  <img
                    className={classes.image}
                    src={NoSearchesIcon}
                    alt={t("projects.noSearchesDone")}
                  />
                </div>
              )}
              {/* no searches results when searched through search input */}

              {allSearches && allSearches.length > 0 && (
                <div>
                  {allSearches.map((history, i) => {
                    return (
                      <Card
                        user={user}
                        isLess1024={isLess1024}
                        key={i}
                        history={history}></Card>
                    );
                  })}
                  {totalPages > 1 && (
                    <Pagination
                      count={totalPages}
                      onChangePage={moreData}
                      page={Number(page)}
                    />
                  )}
                </div>
              )}
            </div>
          ) : (
            <div className={classes.rootSubscription}>
              <Typography
                className={classes.shTitle}
                variant="h6"
                align="center"
                color="black">
                <strong>{t("savedSearches.freeUserTitle")}</strong>
              </Typography>
              <Typography
                className={classes.shSubtitle}
                variant="body"
                align="center"
                color="textLight">
                {t("savedSearches.freeUserSubtitle")}
              </Typography>
              <img
                alt={t("savedSearches.freeUserTitle")}
                src={SearchHistoryFreeUserIllustration}
                className={classes.shImage}
              />
              <Button
                onClick={() =>
                  (window.location.href = getPricingPage(!!user && "free"))
                }
                size="large"
                variant="primary"
                className={classes.shButton}>
                {t("savedSearches.upgradeSubscription")}
              </Button>
            </div>
          )}
          {(getSearchesStatus === REQUEST_PENDING || showLoaderForFree) && (
            <ContentLoader />
          )}
          {upArrowVisible && (
            <UpArrowResults onClick={scrollToTop} className={classes.arrowUp} />
          )}
          {/* no searches done by user */}
          {showNoSearchesPlaceholder() &&
            getSearchesStatus !== REQUEST_PENDING &&
            !showLoaderForFree && (
              <div className={classes.noSearches}>
                <Typography
                  className={classes.shTitle}
                  variant="h6"
                  align="center"
                  color="black">
                  <strong>{t("savedSearches.noSearchesDone")}</strong>
                </Typography>
                <Typography
                  className={classes.shSubtitle}
                  variant="body"
                  color="textLight">
                  {t("projects.noProjectsSubtitle")}
                </Typography>
                <img
                  src={NoSearchesIcon}
                  alt={t("projects.noSearchesDone")}
                  className={classes.image}
                />
                <Button
                  onClick={goToSearch}
                  size="large"
                  variant="primary"
                  className={classes.shButton}>
                  {t("savedSearches.startASearch")}
                </Button>
              </div>
            )}
          {/* no searches done by user */}
        </>
      </div>
    </Layout>
  );
};

export default SavedSearches;
