import React, { useEffect, useState } from "react";
import classnames from "classnames";
// Hooks
import { useTranslation } from "react-i18next";
import { useStyles } from "./style.js";
import { useRouterHook } from "../../../common/useRouterHook";
import { useDispatch, useSelector } from "react-redux";
import { useIntercom } from "react-use-intercom";
import useLocalStorage from "../../../common/useLocalStorage";
import { useAppEventTracking } from "../../../common/useAppEventTracking";
// UI components
import Typography from "../../../components/Typography/Typography";
import Link from "../../../components/Link/Link";
import Button from "../../../components/Button/Button";
import Modal from "../../../components/Modal/Modal";
import { default as UICard } from "../../../components/UICard/UICard";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import InviteUsersModal from "../../../components/InviteUsersModal/InviteUsersModal";
import Menu from "../../../components/Menu/Menu";

// Redux
import { actions } from "../../../store/search/slice";
import { actions as resultsActions } from "../../../store/results/slice";
import { deleteSavedSearchesRequest } from "../../../store/search/requests";
import { getRemoveSavedSearchesStatusSelector } from "../../../store/search/selectors";
//assets
import { ReactComponent as ShareArrow } from "../../../assets/icons/ShareArrow.svg";
import { ReactComponent as ShareArrowActive } from "../../../assets/icons/ShareArrowActive.svg";
import { ReactComponent as Delete } from "../../../assets/icons/Delete.svg";
import { ReactComponent as DeleteActive } from "../../../assets/icons/DeleteActive.svg";
import { ReactComponent as DeleteBasic } from "../../../assets/icons/DeleteBasic.svg";
import CloseIcon from "../../../assets/icons/CloseModal.svg";
import DeleteAction from "../../../assets/images/DeleteAction.png";
import { ReactComponent as MoreIconNoFill } from "../../../assets/icons/MoreIconNoFill.svg";
//Shared
import { Shared } from "../../../utils/shared.js";

const Card = ({ history, isLess1024, user }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { openPage } = useRouterHook();
  const dispatch = useDispatch();
  const removeSearchStatus = useSelector(getRemoveSavedSearchesStatusSelector);
  const { trackEvent } = useIntercom();
  const { intercomTrackEvent, mixpanelTrackEvent } = useAppEventTracking();
  const sessionSlug = sessionStorage.getItem(`results-slug`);
  const { clearStorage } = useLocalStorage("results", sessionSlug);
  const [showModal, setShowModal] = useState(false);
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const { clearStorage: clearStoragePage } = useLocalStorage("results", "page");
  const isTechnologySearch =
    history.scope === "Find companies that use specific technologies";
  const isPatentSearch = history.scope === "Find companies with similar patent";
  const [shareModalOpened, setShareModalOpened] = useState(false);

  useEffect(() => {
    if (removeSearchStatus === "REQUEST_SUCCESS") {
      setShowModal(false);
    }
  }, [removeSearchStatus]);

  const openShareModal = e => {
    e.stopPropagation();
    e.preventDefault();
    setShareModalOpened(true);
  };
  const openDeleteModal = e => {
    e.stopPropagation();
    e.preventDefault();
    setShowModal(true);
  };
  const closeShareModal = () => {
    setShareModalOpened(false);
  };
  const editSearch = () => {
    dispatch(actions.clearSearch());
    history.sectors && dispatch(actions.addSectors(history.sectors));
    history.location && dispatch(actions.updateLocation(history.location));
    dispatch(actions.setIndustryDesc(history.description));
    dispatch(actions.setProduct(1));
    localStorage.setItem("edit_search_id", history.id);

    if (
      process.env.REACT_APP_ENV === "prod" ||
      process.env.REACT_APP_ENV === "staging"
    ) {
      intercomTrackEvent("Edit search");
    }
    // if (isPatentSearch) {
    //     openPage(`/search-patent`)
    // }
    // if (isTechnologySearch) {
    //     openPage(`/search-type`)
    // }
  };
  const deleteSearch = () => {
    dispatch(
      deleteSavedSearchesRequest({ sid: history.id, savedSearch: true }),
    );
    mixpanelTrackEvent("Saved search deleted");
  };
  const viewResults = () => {
    dispatch(resultsActions.setSearchesEditSearchStatusUndefined());
    dispatch(resultsActions.setCurrentSearch(history));
    dispatch(resultsActions.setCurrentSearchSid(history.id));
    // clearStorage();
    clearStoragePage();
    sessionStorage.removeItem(`results-${sessionSlug}-FilterSortDirection`);
    sessionStorage.removeItem(`results-${sessionSlug}-FilterSortValue`);
    // new search
    editSearch();
    openPage(`/results?sid=${history.id}`);
  };

  const handleCloseModal = e => {
    e.preventDefault();
    e.stopPropagation();
    setShowModal(false);
  };

  const cornerMenuItems = [
    {
      label: (
        <IconButton className={classes.iconButton}>
          <ShareArrow className={classes.iconImgBase} />
          <ShareArrowActive className={classes.iconImgHover} />
        </IconButton>
      ),
      onClick: e => openShareModal(e),
      intercomId: "Share search button",
    },
    {
      label: (
        <IconButton className={classes.iconButton}>
          <Delete className={classes.iconImgBase} />
          <DeleteActive className={classes.iconImgHover} />
        </IconButton>
      ),
      onClick: e => openDeleteModal(e),
      intercomId: "Delete search button",
    },
  ];

  const { userSwitchedFromTrialToFree, formatNumber } = Shared({
    getUser: user,
  });

  return (
    <UICard
      className={classes.root}
      data-intercom-target="Search results summary">
      <div onClick={viewResults}>
        <div className={classes.cardHeader}>
          <div className={classes.cardHeaderTitle}>
            <Typography variant="subtitle1" className={classes.searchName}>
              {history.search_name}
            </Typography>
            <div className={classes.cardHeaderSubTitle}>
              {isTechnologySearch ? (
                <Typography
                  variant="bodyXs"
                  className={classes.cardHeaderSearchType}>
                  {t("savedSearches.findSpecificCompany")}
                </Typography>
              ) : (
                <Typography
                  variant="bodyXs"
                  className={classes.cardHeaderSearchType}>
                  {t("savedSearches.lookAlike")}
                </Typography>
              )}
              <Typography className={classes.dateTime} variant="bodyXs">
                {history.created_date} at {history.created_time}
              </Typography>
            </div>
          </div>
          <div className={classes.mobileIconsMenu}>
            <Menu
              menuItems={cornerMenuItems}
              onChange={() => setIsMenuOpened(!isMenuOpened)}>
              <MoreIconNoFill
                className={classnames([
                  classes.moreIcon,
                  { [classes.moreIconOpened]: isMenuOpened },
                ])}
              />
            </Menu>
          </div>
          <div className={classes.cardHeaderActions}>
            <Tooltip
              title={
                <Typography color="white" variant="body1">
                  {t("savedSearches.shareAction")}
                </Typography>
              }
              arrow>
              <IconButton
                className={classes.iconButton}
                onClick={e => openShareModal(e)}>
                <ShareArrow className={classes.iconImgBase} />
                <ShareArrowActive className={classes.iconImgHover} />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={
                <Typography color="white" variant="body1">
                  {t("savedSearches.deleteAction")}
                </Typography>
              }
              arrow>
              <IconButton
                className={classes.iconButton}
                onClick={e => openDeleteModal(e)}>
                <Delete className={classes.iconImgBase} />
                <DeleteActive className={classes.iconImgHover} />
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <div
          className={classes.textActions}
          data-intercom-target="Edit/view results">
          {isTechnologySearch ? (
            <div className={classes.searchDescription}>
              <Typography variant="body" className={classes.searchTextTitle}>
                {t("savedSearches.yourSearchText")}
              </Typography>
              <Typography
                variant="body"
                component="div"
                className={classes.searchText}>
                "{history.description}"
              </Typography>
            </div>
          ) : (
            !!history &&
            history.look_alike_company_data && (
              <div className={classes.searchDescription}>
                <Typography
                  variant="subtitle1"
                  color="title"
                  className={classes.companyName}>
                  {history.look_alike_company_name}
                </Typography>
                <Typography
                  variant="body"
                  component="div"
                  className={classes.companyDescription}>
                  {history.description}
                </Typography>
                <div className={classes.companyInfo}>
                  <div className={classes.companyInfoLabels}>
                    <Typography
                      className={classes.companyInfoLabel}
                      variant="bodySmall">
                      {t("savedSearches.yearOfInception")}
                    </Typography>
                    <Typography
                      className={classes.companyInfoValue}
                      variant="bodyXs">
                      {history.look_alike_company_data
                        .look_alike_company_founded ||
                        t("savedSearches.unspecified")}
                    </Typography>
                  </div>
                  <div className={classes.companyInfoLabels}>
                    <Typography
                      className={classes.companyInfoLabel}
                      variant="bodySmall">
                      {t("savedSearches.companyStage")}
                    </Typography>
                    <Typography
                      className={classes.companyInfoValue}
                      variant="bodyXs">
                      {history.look_alike_company_data
                        .look_alike_company_stage ||
                        t("savedSearches.unspecified")}
                    </Typography>
                  </div>
                  <div className={classes.companyInfoLabels}>
                    <Typography
                      className={classes.companyInfoLabel}
                      variant="bodySmall">
                      {t("savedSearches.fundings")}
                    </Typography>
                    <Typography
                      variant="bodySmall"
                      className={classes.companyFundings}>
                      {history.look_alike_company_data
                        .look_alike_company_funding
                        ? `${formatNumber(
                            history.look_alike_company_data
                              .look_alike_company_funding,
                          )} USD`
                        : t("savedSearches.unspecified")}{" "}
                    </Typography>
                  </div>
                  <div className={classes.companyInfoLabels}>
                    <Typography
                      className={classes.companyInfoLabel}
                      variant="bodySmall">
                      {t("savedSearches.website")}
                    </Typography>
                    <Typography
                      className={classes.companyInfoValue}
                      variant="bodySmall">
                      {history.look_alike_company_data
                        .look_alike_company_website ? (
                        <Link
                          target="_blank"
                          className={classes.companyWebsite}
                          href={
                            history.look_alike_company_data
                              .look_alike_company_website
                          }>
                          {
                            history.look_alike_company_data
                              .look_alike_company_website
                          }
                        </Link>
                      ) : (
                        t("savedSearches.unspecified")
                      )}
                    </Typography>
                  </div>
                  <div className={classes.companyInfoLabels}>
                    <Typography
                      className={classes.companyInfoLabel}
                      variant="bodySmall">
                      {t("savedSearches.teamSize")}
                    </Typography>
                    <Typography
                      className={classes.companyInfoValue}
                      variant="bodyXs">
                      {history.look_alike_company_data
                        .look_alike_company_size ||
                        t("savedSearches.unspecified")}
                    </Typography>
                  </div>
                  <div className={classes.companyInfoLabels}>
                    <Typography
                      className={classes.companyInfoLabel}
                      variant="bodySmall">
                      {t("savedSearches.region")}
                    </Typography>
                    <Typography
                      className={classes.companyInfoValue}
                      variant="bodyXs">
                      {history.look_alike_company_data
                        .look_alike_company_region ||
                        t("savedSearches.unspecified")}
                    </Typography>
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      </div>
      <Modal
        onClose={handleCloseModal}
        dialogOpened={showModal}
        closeButtonIcon={CloseIcon}>
        <div className={classes.deleteActionModal}>
          <Typography variant="subtitle1">
            {t("savedSearches.deleteTitle")}
          </Typography>
          <Typography variant="subtitle2" className={classes.deleteSubtitle}>
            {t("savedSearches.deleteSubtitle")}
          </Typography>
          <img src={DeleteAction} alt={t("savedSearches.deleteTitle")} />
          <div className={classes.deleteActionButtons}>
            <Button className={classes.cancelButton} onClick={handleCloseModal}>
              {t("savedSearches.cancel")}
            </Button>
            <Button
              className={classes.deleteButton}
              onClick={deleteSearch}
              disabled={removeSearchStatus === "REQUEST_PENDING"}>
              {t("savedSearches.deleteAction")}
              <DeleteBasic className={classes.deleteButtonIcon} />
            </Button>
          </div>
        </div>
      </Modal>
      <InviteUsersModal
        showShareSection={true}
        shareData={{
          sid: history.id,
          page: 1,
          view: "list",
        }}
        dialogOpened={shareModalOpened}
        onChange={closeShareModal}
        page="Saved Searches"
      />
    </UICard>
  );
};
export default Card;
