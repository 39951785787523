import React from "react";
import { useStyles } from "./style.js";
import Typography from "../Typography/Typography";
import Tooltip from "@material-ui/core/Tooltip";

const InfoToolTip = ({ text }) => {
  const classes = useStyles();

  return (
    <div className={classes.infoToolTip}>
      <Tooltip
        title={
          <Typography color="white" variant="body2" className={classes.mono}>
            {text}
          </Typography>
        }
        arrow>
        <div className={classes.icon}>!</div>
      </Tooltip>
    </div>
  );
};

export default InfoToolTip;
