import React, { useEffect, useState } from "react";
import capitalize from "lodash/capitalize";
import { useSelector } from "react-redux";
// UI components
import Typography from "../../components/Typography/Typography.js";
import IconButton from "@material-ui/core/IconButton";
import Grow from "@material-ui/core/Grow";
// Hooks
import { useTranslation } from "react-i18next";
import { useStyles } from "./style";
//UI Components
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
//Assets
import CloseIcon from "../../assets/icons/CloseIcon.svg";
// Redux
import { getSeatsAvailableSelector } from "../../store/auth/selectors";

const CostBreakdownModal = ({ dialogOpened, user, onChange, billings }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const seatsAvailable = useSelector(getSeatsAvailableSelector);
  const [isMobile, setIsMobile] = useState(false);
  const [planInterval, setPlanInterval] = useState(null);

  useEffect(() => {
    setIsMobile(window.innerWidth <= 575);
  }, []);

  useEffect(() => {
    if (user && user.subscription && user.subscription) {
      setPlanInterval(user.subscription.plan_interval);
    }
  }, [user]);

  return (
    <Dialog
      fullScreen={isMobile}
      onClose={onChange}
      maxWidth="lg"
      TransitionComponent={Grow}
      classes={{
        root: classes.dialogRoot,
        paper: classes.dialog,
        paperScrollPaper: classes.costBreakdownDialogRoot,
      }}
      onClose={(event, reason) => {
        if (reason === "backdropClick") {
          onChange(event, reason);
        }
      }}
      open={dialogOpened}>
      <IconButton className={classes.closeButton} onClick={onChange}>
        <img
          src={CloseIcon}
          alt={t("scope.step2")}
          className={classes.stepIcon}
        />
      </IconButton>
      <MuiDialogTitle disableTypography className={classes.dialogTitle}>
        <Typography variant="subtitle1" color="black">
          {t("profileSettings.costBreakdownTitle")}
        </Typography>
      </MuiDialogTitle>
      <MuiDialogContent className={classes.dialogContent}>
        <div className={classes.tableWrap}>
          <div className={classes.costInfoRow}>
            <Typography
              className={classes.dialogValue}
              color="textLight"
              align="center"
              variant="bodySmall"
              component="span">
              {t(
                "profileSettings." +
                  (planInterval == "month"
                    ? "costBreakdownPlanMonthly"
                    : "costBreakdownPlanYearly"),
              )}
            </Typography>
            <Typography
              className={classes.dialogValue}
              color="black"
              align="center"
              variant="bodyXl"
              component="span">
              {capitalize(user && user.subscription && user.subscription.name)}
            </Typography>
          </div>
          <div className={classes.costInfoRow}>
            <Typography
              className={classes.dialogValue}
              color="textLight"
              align="center"
              variant="bodySmall"
              component="span">
              {t("profileSettings.costBreakdownSeats")}
            </Typography>
            <Typography
              className={classes.dialogValue}
              color="black"
              align="center"
              variant="bodyXl"
              component="span">
              {seatsAvailable}
            </Typography>
          </div>
          <div className={classes.costInfoRow}>
            <Typography
              className={classes.dialogValue}
              color="textLight"
              align="center"
              variant="bodySmall"
              component="span">
              {t("profileSettings.costBreakdownCredits")}
            </Typography>
            <Typography
              className={classes.dialogValue}
              color="black"
              align="center"
              variant="bodyXl"
              component="span">
              {user && user.subscription && user.subscription.credits_available}
            </Typography>
          </div>
        </div>
        <div className={classes.costInfoRow}>
          <Typography
            className={classes.dialogValue}
            color="black"
            align="center"
            variant="bodySmall"
            component="span">
            {t(
              "profileSettings." +
                (planInterval == "month"
                  ? "costBreakdownPlanMonthly"
                  : "costBreakdownPlanYearly"),
            )}
          </Typography>
          <Typography
            className={classes.dialogValue}
            color="black"
            align="center"
            variant="bodyXl"
            component="span">
            {billings && billings.meta && billings.meta.next_billing_total}
          </Typography>
        </div>
      </MuiDialogContent>
    </Dialog>
  );
};
export default CostBreakdownModal;
