import React from "react";

// UI components
import Typography from "../../../../../components/Typography/Typography.js";
import IconButton from "@material-ui/core/IconButton";
import Grow from "@material-ui/core/Grow";
// Hooks
import { useTranslation } from "react-i18next";
import { useStyles } from "./style";
import { useRouterHook } from "../../../../../common/useRouterHook";
//UI Components
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import Button from "../../../../../components/Button/Button";
//Assets
import CloseIcon from "../../../../../assets/icons/CloseIcon.svg";
import { ReactComponent as SmallLock } from "../../../../../assets/icons/SmallLock.svg";
// Constants
import { getPricingPage } from "../../../../../constants/endpoints";

const RequestModal = ({
  dialogOpened,
  onChange,
  title,
  description,
  icon,
  user,
  showEnrichRequestModal,
  technology,
  enrichmentRequest,
  onSubmitRequest,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { openPage } = useRouterHook();
  const isFreeUserOrGuest =
    !user ||
    (user &&
      user.subscription &&
      (user.subscription.name === "free" || !user.subscription.is_active));

  const handleCtaClick = () => {
    if (isFreeUserOrGuest) {
      !!user
        ? (window.location.href = getPricingPage(user.subscription.name))
        : openPage("/sign-up");
    } else {
      showEnrichRequestModal(technology);
    }
  };
  const submitResearchRequest = () => {
    onSubmitRequest();
  };

  return (
    <Dialog
      onClose={onChange}
      maxWidth="lg"
      TransitionComponent={Grow}
      classes={{
        root: classes.dialogRoot,
        paper: classes.dialog,
        scrollPaper: classes.dialogScrollPaper,
      }}
      onClose={(event, reason) => {
        if (reason === "backdropClick") {
          onChange(event, reason);
        }
      }}
      open={dialogOpened}>
      <IconButton className={classes.closeButton} onClick={onChange}>
        <img
          src={CloseIcon}
          alt={t("scope.step2")}
          className={classes.stepIcon}
        />
      </IconButton>
      {/* {icon} */}
      <MuiDialogTitle disableTypography className={classes.dialogTitle}>
        <Typography variant="h4">{title}</Typography>
        <SmallLock className={classes.lockIcon} />
      </MuiDialogTitle>
      <MuiDialogContent className={classes.dialogContent}>
        <Typography
          className={classes.dialogValue}
          color="black"
          variant="body"
          component="div">
          {description}
        </Typography>
      </MuiDialogContent>
      {!enrichmentRequest && (
        <>
          {technology ? (
            <Button
              onClick={submitResearchRequest}
              className={classes.requestButton}
              variant="secondary"
              size="large">
              {t(
                `technologyOverview.${
                  isFreeUserOrGuest
                    ? "upgradeSubscription"
                    : "submitResearchRequest"
                }`,
              )}
            </Button>
          ) : (
            <Button
              onClick={handleCtaClick}
              className={classes.requestButton}
              variant="secondary"
              size="large">
              {t(
                `companyProfile.${
                  isFreeUserOrGuest
                    ? "upgradeSubscription"
                    : "requestFullProfile"
                }`,
              )}
            </Button>
          )}
        </>
      )}
    </Dialog>
  );
};
export default RequestModal;
